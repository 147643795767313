<template>
  <div :key="componentKey" class="croppper-component-container">
    <div v-if="isValid" class="inner">
      <div v-if="cropperRatios.length > 1" class="cropper-ratioList">
        <div>Please crop this video to one of the following ratio :</div>
        <div class="cropper-ratios">
          <div v-for="ratio of cropperRatios" :key="ratio.ratio">
            <input
              :id="ratio.ratio"
              v-model="activeRatio"
              type="radio"
              :value="ratio" />
            <label :for="ratio.ratio">{{ ratio.ratio }}</label>
          </div>
        </div>
      </div>
      <div class="cropper-container">
        <video
          ref="videoToCrop"
          controls
          muted
          :autoplay="autoplay"
          :src="src"
          @loadeddata="onVideoMetadataLoaded">
          Your browser does not support the video tag.
        </video>
        <div
          v-if="activeRatio"
          class="cropper"
          :style="cropperPosition"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"></div>
      </div>
    </div>
    <div v-else class="cropper-container">
      <span class="not-supported">Sorry... Video is not supported</span>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';

export default {
  name: 'CropperComponent',
  parameters: {
    docs: {
      description: {
        component: 'Some component _markdown_',
      },
    },
  },
  props: {
    /**
     * Absolute link of the video (not a file)
     */
    src: {
      type: String,
      default: null,
    },
    /**
     * Array of ratios available to crop
     */
    availableRatios: {
      type: Array,
      default: null,
    },
    /**
     * Value to allow the drag and drop of the cropper
     */
    cropperActive: {
      type: Boolean,
      default: true,
    },
    /**
     * Value to autoplay video preview
     */
    autoplay: Boolean,
  },

  data() {
    return {
      componentKey: 0,
      isValid: false,
      activeRatio: undefined,
      croppingPositionPercent: 0,
      videoHeight: undefined,
      videoWidth: undefined,
      realHeight: undefined,
      realWidth: undefined,
      moveStart: undefined,
      moveStartCroppingPositionPercent: undefined,
      // isOrientationForced: false,
    };
  },
  computed: {
    cropperRatios() {
      return this.availableRatios.map(ratio => ({
        ratio,
        calculatedRatio: this.isLandscape ? ratio.split('/')[1] / ratio.split('/')[0] : ratio.split('/')[0] / ratio.split('/')[1],
      }));
    },
    correctRatio() {
      let ratioFound = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const ratio of this.cropperRatios) {
        if (this.realWidth / this.realHeight === ratio.calculatedRatio) {
          ratioFound = ratio;
        }
      }
      return ratioFound;
    },
    isHorizontalCrop() {
      return this.videoWidth / this.videoHeight >= this.activeRatio.calculatedRatio;
    },
    cropperHeight() {
      return this.isHorizontalCrop ? Math.min(this.videoHeight, this.videoWidth / this.activeRatio.calculatedRatio) : this.cropperWidth / this.activeRatio.calculatedRatio;
      // return this.cropperWidth / this.activeRatio.calculatedRatio;
    },
    cropperWidth() {
      return this.isHorizontalCrop ? this.cropperHeight * this.activeRatio.calculatedRatio : Math.min(this.videoWidth, this.videoHeight * this.activeRatio.calculatedRatio);
    },
    cropperPosition() {
      if (this.isHorizontalCrop) {
        // Landscape
        return {
          left: `${this.croppingPositionPercent}%`,
          top: 0,
          width: `${this.cropperWidth}px`,
          height: `${this.cropperHeight}px`,
        };
      }
      // Portrait
      return {
        left: 0,
        top: `${this.croppingPositionPercent}%`,
        width: `${this.cropperWidth}px`,
        height: `${this.cropperHeight}px`,
      };
    },
  },
  watch: {
    croppingPositionPercent: {
      handler(value) {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) {
          if (this.getCropperInfos()) {
            this.cropEvent();
          }
        }
      },
      deep: true,
      immediate: true,
    },

    activeRatio: {
      handler() {
        this.componentKey += 1;
      },
      deep: true,
      immediate: true,
    },
    src: {
      async handler(value) {
        this.isValid = await tools.videoFormatIsValid(value);
      },
    },
    cropperRatios: {
      handler(cropperRatios) {
        // eslint-disable-next-line prefer-destructuring
        this.activeRatio = cloneDeep(cropperRatios[0]);
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.isValid = await tools.videoFormatIsValid(this.src);
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    async onResize() {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.componentKey += 1;
    },

    async onVideoMetadataLoaded(event) {
      if (event === null) {
        return;
      }
      const video = event.target;
      this.videoWidth = video.clientWidth;
      this.videoHeight = video.clientHeight;
      this.realWidth = video.videoWidth;
      this.realHeight = video.videoHeight;
      this.updateCroppingPositionPercentToInitialValue();
      this.$forceUpdate();
    },

    onMouseDown(event) {
      event.preventDefault();
      if (!this.cropperActive) {
        return;
      }

      const el = event.target;
      el.classList.add('grabbing');

      if (this.isHorizontalCrop) {
        this.moveStart = event.pageX;
      } else {
        this.moveStart = event.pageY;
      }
      this.moveStartCroppingPositionPercent = this.croppingPositionPercent;
      el.addEventListener('mousemove', this.onMouseMove);
    },

    cropEvent() {
      this.$emit('cropEvent', this.getCropperInfos());
    },

    onMouseUp(event) {
      event.preventDefault();
      if (!this.cropperActive) {
        return;
      }

      const el = event.target;
      el.classList.remove('grabbing');

      this.moveStart = undefined;
      el.removeEventListener('mousemove', this.onMouseMove);
      if (this.getCropperInfos()) {
        this.cropEvent();
      }
    },

    onMouseMove(event) {
      let positionPercent;
      let maxPositionPercent;

      if (this.isHorizontalCrop) {
        // eslint-disable-next-line no-multi-spaces
        positionPercent = this.moveStartCroppingPositionPercent + ((event.pageX - this.moveStart) * 100) / this.videoWidth;
        maxPositionPercent = ((this.videoWidth - this.cropperWidth) * 100) / this.videoWidth;
      } else {
        // eslint-disable-next-line no-multi-spaces
        positionPercent = this.moveStartCroppingPositionPercent + ((event.pageY - this.moveStart) * 100) / this.videoHeight;
        maxPositionPercent = ((this.videoHeight - this.cropperHeight) * 100) / this.videoHeight;
      }
      positionPercent = Math.max(0, positionPercent);
      positionPercent = Math.min(positionPercent, maxPositionPercent);

      this.croppingPositionPercent = Math.round(positionPercent * 100) / 100;
    },
    getCropperInfos() {
      if (!this.activeRatio) {
        return null;
      }
      return {
        croppingPositionPercent: this.croppingPositionPercent,
        ratio: this.activeRatio.ratio,
        calculatedRatio: this.correctRatio ? this.correctRatio.calculatedRatio : this.activeRatio.calculatedRatio,
        isHorizontalCrop: this.isHorizontalCrop,
        originalRatio: this.realWidth / this.realHeight,
      };
    },
    updateCroppingPositionPercentToInitialValue() {
      if (this.isHorizontalCrop) {
        this.croppingPositionPercent = Math.round((Math.round((this.videoWidth - this.cropperWidth) / 2) / this.videoWidth) * 100);
      } else {
        this.croppingPositionPercent = Math.round((Math.round((this.videoHeight - this.cropperHeight) / 2) / this.videoHeight) * 100);
      }
      if (this.croppingPositionPercent === 0) {
        this.croppingPositionPercent = 0.01;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.croppper-component-container {
  .inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.cropper-ratios {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.cropper-ratioList {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4f5c;
}
.cropper-container {
  position: relative;
  width: auto !important;
  height: auto !important;
  margin: 0 auto;
  max-width: 90vw;
  max-height: 50vh;
  .not-supported {
    font-size: 12px;
  }
}
.cropper-container video {
  max-width: 90vw;
  max-height: 50vh;
  width: auto !important;
  height: auto !important;
}
.cropper-container .cropper {
  position: absolute;
  border: 3px dashed white;
  background-color: $background-cropper;
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.bottom-margin {
  margin-bottom: 2em;
}
.gfs-form-input {
  margin: 5px auto;
  border-radius: 3px;
  border: solid 1px #82bdf7;
  padding: 5px 5px;
  font-size: 10px;
  font-weight: 600;
  max-width: max-content;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.gfs-form-input > img {
  height: 25px;
  width: 25px;
}
</style>

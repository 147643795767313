<template>
  <div data-test-id="actionButtonStoreQRCode" @click.stop="open">
    <el-popover placement="bottom" trigger="hover">
      <div
        data-test-id="gameInfosActionStoreQRCode"
      >
        <VueQrcode :value="game.storeUrl" :options="{ width: 200 }"></VueQrcode>
      </div>
      <i
        slot="reference"
        :class="[`vd-svg-${icon}`, 'icon']"
        data-test-id="gameInfosActionStoreUrl"
      ></i>
    </el-popover>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: 'StoreUrl',
  components: {
    VueQrcode,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  computed: {
    icon() {
      return this.game.platform === 'ios' ? 'app-store' : 'playstore';
    },
  },
  methods: {
    open() {
      window.open(this.game.storeUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 24px;
  height: 24px;
}

.icon:hover {
  cursor: pointer;
}
</style>

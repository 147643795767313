<template>
  <div class="mobile-menu">
    <div class="link">
      <ul>
        <li>
          <div class="logo">
            <!-- <el-image
              class="preview"
              style="max-width:200px;
                border-radius: 5px;
                min-width: 30px;"
              :src="logo"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>-->
          </div>
        </li>
        <li :class="{ active: sub === 'home' }" @click="setSub('home')">Home</li>
        <li :class="{ active: sub === 'mygames' && type === 'cpi' }" @click="setSub('mygames', 'cpi')">
          CPI Tests
        </li>
        <li v-if="myPermissions.indexOf('view_knowledge_sharing') > -1" :class="{ active: sub === 'ks' }" @click="setSub('ks')">
          Knowledge sharing
        </li>
        <li @click="goto('https://voodoo.zendesk.com/hc/en-us/categories/360001432800-HELP-CENTER')">
          {{ $t("views.StudioBoard.helpCenter") }}
        </li>
        <li @click="goto(eLearningAcademyInfos.link)">
          {{ eLearningAcademyInfos.label }}
        </li>
        <li>
          <CreateGameButton @game-created="closeMenu"></CreateGameButton>
        </li>

        <li @click="closeMenu">
          <div class="close">
            <el-image
              class="preview"
              style="width: 50px;
                border-radius: 5px;
"
              :src="close"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
            <span>Close</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CreateGameButton from '@/components/CreateGameButton.vue';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import UsersService from '@/services/users.services';
import logo from '../assets/images/logoVoodoo@3x.png';
import close from '../assets/images/closeButtonBlue@3x.png';

export default {
  name: 'MobileMenu',
  components: {
    CreateGameButton,
  },
  data() {
    return {
      logo,
      close,
      gameIcon,
      eLearningLink: 'https://voodoo.zendesk.com/hc/en-us/categories/360001432760-E-LEARNING',
      academyLink: '',
    };
  },
  computed: {
    sub() {
      return this.$route.params.sub || 'home';
    },
    type() {
      return this.$route.params.type || 'cpi';
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    isDedicatedAdAccountGame() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    academyAccess() {
      const permissions = Object.keys(this.$store.getters['users/me'].permissions);
      const academyPermissions = ['learn_upon_admin', 'learn_upon_instructor', 'learn_upon_learner', 'learn_upon_manager'];
      const hasPermission = academyPermissions.some(permission => permissions.includes(permission));
      return hasPermission;
    },
    eLearningAcademyInfos() {
      return this.academyAccess ? {
        link: this.academyLink,
        label: this.$t('views.StudioBoard.academy'),
      } : {
        link: this.eLearningLink,
        label: this.$t('views.StudioBoard.eLearning'),
      };
    },
  },
  mounted() {
    this.getAcademyLink();
  },
  methods: {
    goto(url) {
      window.open(url);
    },
    setSub(value, type) {
      if (value === 'ks') {
        this.$router.push({
          name: 'studioHome',
          params: {
            sub: value,
            type: type || 'cpi',
            filter: 'finished',
          },
        });
      } else if (this.$route.params.sub !== value || this.$route.params.type !== type) {
        if (value === 'home') {
          this.$router.push({
            name: 'studioHome',
            params: { sub: value },
          });
        } else {
          this.$router.push({
            name: 'studioHome',
            params: {
              sub: value,
              type: type || 'cpi',
              filter: this.$route.params.filter || 'in_progress',
            },
          });
        }
      }
      this.closeMenu();
    },

    closeMenu() {
      this.$emit('visibility');
    },
    async getAcademyLink() {
      if (!this.academyAccess) return;
      const linkResponse = await UsersService.getRedirectLink('learnupon');
      this.academyLink = linkResponse.redirectUrl;
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 150;
  // align-items: stretch;
  // height: 100%;

  .link,
  ul {
    height: 100%;
    font-weight: bold;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 30px;
    li {
      color: rgb(77, 79, 92);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      &:hover {
        color: rgb(6, 123, 240);
        cursor: pointer;
        font-weight: bold;
      }
      &.active {
        color: rgb(6, 123, 240);
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          width: 55px;
          height: 3px;
          margin-top: 30px;
          background-color: rgb(6, 123, 240);
        }
      }
      .logo {
        height: 100%;
        width: 150px;
        background-image: url("~@/assets/images/logoVoodoo@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .close {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        span {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>

/* eslint-disable no-param-reassign */
import Vue from 'vue';

export default Vue.directive('click-outside', {
  priority: 700,
  bind(el, binding, vnode) {
    // const self = binding;
    // eslint-disable-next-line func-names
    binding.event = function (event) {
      console.log('emitting event');
      vnode.context.$emit(binding, event);
    };
    el.addEventListener('click', binding.stopProp);
    document.body.addEventListener('click', binding.event);
  },

  unbind(el, binding) {
    console.log('unbind');
    el.removeEventListener('click', binding.stopProp);
    document.body.removeEventListener('click', binding.event);
  },
  stopProp(event) {
    event.stopPropagation();
  },
});

<template>
  <div class="page">
    <div class="card">
      <div>
        <div v-if="!noHeader" class="card-header">
          <div class="card-header-left">
            <slot name="left"></slot>
          </div>
          <div class="card-header-right">
            <slot name="right"></slot>
          </div>
        </div>
        <div class="card-page" :style="`max-height: ${maxHeight}px;`">
          <slot v-if="!loading" name="page"></slot>
          <div v-if="loading" class="loading">
            <i class="el-icon-loading loading-list"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="margin-header"></div> -->
  </div>
</template>

<script>
export default {
  name: 'CardPage',
  components: {},
  props: {
    // eslint-disable-next-line vue/require-default-prop
    noHeader: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 200;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.page {
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  .card {
    // border-radius: 10px;
    .loading {
      border-color: #ebeef5;
      min-height: 200px;
      width: 100%;
      // color: #409eff;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 20px;
    border-bottom: 1px solid #e9e9f0;
    overflow: auto;
    background-color: #fdfdfd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // position: fixed;
    display: flex;
    // width: calc(100% - 60px);
    z-index: 10;
    .card-header-left {
      padding: 20px;
    }
  }
  .card-page {
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 10px;
    // padding-top: 80px;
    overflow: auto;
  }
}
</style>

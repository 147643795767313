<template>
  <div class="video-list-mobile">
    <div v-for="(item, index) in videoList" :key="index+item" class="video-container">
      <div class="header-video">
        <span class="title">
          <span v-if="item.adNetwork" class="adnetwork-icon" :class="'icon-' + item.adNetwork.toLowerCase()"></span>
          <div>
            <span v-if="!item.adultContent">
              #{{item.perf}} Performing video
            </span>
            <span v-else class="rejected">
              Rejected video by the ad network
            </span>
          </div>
        </span>

        <span class="video-name">{{item.fileName || 'n/a'}}</span>

      </div>
      <div v-if="!item.adultContent" class="video-infos">
        <video
          v-if="item.video"
          slot="reference"
          class="video"
          width="44"
          :autoplay="false"
        >
          <source :src="item.video !== null ? item.video.replace('?', '%3F'): null" />
        </video>
        <div class="video-kpi">
          <div class="data">
            <div class="label">
              Spend
            </div>
            <div class="kpi">
              <div v-if="item.data && item.data.spend && !isNaN(item.data.spend)" class="kpi">
                ${{item.data.spend.toFixed(0).toLocaleString('en-US')}}
              </div>
              <div v-else class="kpi na">
                n/a
              </div>
            </div>
          </div>
          <div class="data">
            <div class="label">
              Impressions
            </div>
            <div v-if="item.data && item.data.impressions && !isNaN(item.data.impressions)" class="kpi">
              {{item.data.impressions.toLocaleString('en-US')}}
            </div>
            <div v-else class="kpi na">
              n/a
            </div>
          </div>
          <div class="data">
            <div class="label">
              Clicks
            </div>
            <div v-if="item.data && item.data.linkClicks && !isNaN(item.data.linkClicks)" class="kpi">
              {{item.data.linkClicks.toLocaleString('en-US')}}
            </div>
            <div v-else class="kpi na">
              n/a
            </div>
          </div>
          <div class="data">
            <div class="label">
              Installs
            </div>
            <div v-if="item.data && item.data.fbInstalls && !isNaN(item.data.fbInstalls)" class="kpi">
              {{item.data.fbInstalls.toLocaleString()}}
            </div>
            <div v-else class="kpi na">
              n/a
            </div>
          </div>
          <div class="data">
            <div class="label">
              CPI
            </div>
            <div v-if="item.data && item.data.cpi && !isNaN(item.data.cpi)" class="kpi">
              ${{item.data.cpi.toFixed(2).toLocaleString('en-US')}}
            </div>
            <div v-else class="kpi na">
              n/a
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoListMobile',
  components: {
  },
  props: {
    videoList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.video-list-mobile {
   .video-container {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $foggy-grey;
    text-align: left;
    padding: 10px;
  }
  .rejected {
    font-size: 14px;
    font-weight: 600;
    color: $grey;
  }
   .header-video {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      display:flex;

      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $slate;
    }
    .video-name {

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }
     .adnetwork-icon {
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0px;
      margin: 0px 9px 0px 0px;
      top: 0;
      right: 0;
      &.icon-facebook {
        background-image: url("~@/assets/images/icon-facebook.svg");
      }
      &.icon-snapchat {
        background-image: url("~@/assets/images/icon-snapchat.svg");
      }
    }
  }
  .video-infos {
    display: flex;
    gap: 20px;
    .video-kpi {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .data {
      .label {

        font-size: 14px;
        color: $grey;
      }
      .kpi {

        font-size: 18px;
        font-weight: 600;
        color: $soft-black;
      }
    }
    .video {
      width: 200px;
      margin-top: 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      height: -webkit-fill-available;
      background-color: $soft-black;
      .video-infos {
        display:flex;
      }
    }
  }
}
</style>

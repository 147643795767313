<template>
  <div class="list-items">
    <div v-if="!loading" class="grid-container">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        data-aos="fade-up"
        data-aos-once="true"
      >
        <span class="quote">
          {{ item.quote }}
        </span>
        <div class="author">
          {{ item.author }}
        </div>
      </div>
    </div>

    <div v-else v-loading="loading" style="width: 100%"></div>
    <InfiniteLoading
      ref="infinitLoading"
      slot="append"
      :distance="200"
      @infinite="infiniteHandler"
    >
      <div slot="spinner"><i class="el-icon-loading loading-list"></i></div>
      <div slot="no-more" class="no-more"></div>
      <div slot="no-results"><div class="no-results">No Data</div></div>
    </InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'ListItems',
  components: {
    InfiniteLoading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    list() {
      return this.$store.getters['quotes/quotesList'];
    },
    loading() {
      return this.$store.getters['quotes/loading'];
    },
  },
  methods: {
    async infiniteHandler($state) {
      await this.$store.dispatch('quotes/fetchList').then((response) => {
        if (response) {
          $state.loaded();
        } else if (response.length > 0) {
          $state.loaded();
          $state.complete();
        } else {
          $state.complete();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  margin-bottom: 30px;
  .item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    height: 200px;
    border: 1px solid $light-grey;
    padding: 10px;
    position: relative;
    color: $grey-bis;
    .quote {
      font-size: 22px;
      text-align: left;
    }
    .author {
      position: absolute;
      bottom: 0;
      padding: 10px;
      right: 0;
    }
  }
}
@media (max-width: 700px) {
  .grid-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    .item {
      border: 0;
      border-bottom: 1px solid $light-grey;
      margin-bottom: 10px;
      height: 100px;
      padding: 10px 0 10px 0;
    }
  }
}
</style>

<template>
  <!-- <Tweezing :to="200" :duration="100"> -->
  <transition name="slide-up">
    <div v-if="open" class="mobile-view">
      <div class="header-mobile-view">
        <i :class="`el-icon-${mobileIcon}`" />
        <div class="title">{{ title }}</div>
        <i class="el-icon-close close" @click="closeTag" />
      </div>

      <div class="container">
        <SortMobileAction
          v-if="$route.query.mobile === 'sort-my-games'"
          :filter="filter"
          :type="type"
          @close="closeTag"
        ></SortMobileAction>
        <DetailAndOptionsMobile
          v-if="$route.query.mobile === 'games-infos'"
          :game-id="gameId"
          @close="closeTag"
        ></DetailAndOptionsMobile>
        <StudioOptionMobile
          v-if="$route.query.mobile === 'studio-option'"
          @close="closeTag"
        />
      </div>
    </div>
  </transition>

  <!-- </Tweezing> -->
</template>

<script>
import SortMobileAction from '@/components/SortMobileAction.vue';
import DetailAndOptionsMobile from '@/components/DetailAndOptionsMobile.vue';
import StudioOptionMobile from '@/modules/Studio/components/StudioOptionMobile.vue';

export default {
  name: 'SlidingPage',
  components: {
    // GamePage,
    // StudioPage,
    SortMobileAction,
    DetailAndOptionsMobile,
    StudioOptionMobile,
  },
  props: {},
  data() {
    return {
      filter: 'in_progress',
      type: 'cpi',
      open: false,
    };
  },
  computed: {
    title() {
      return this.$route.query.mobileTitle || '';
    },
    mobileIcon() {
      return this.$route.query.mobileIcon || '';
    },
    gameId() {
      return `${this.$route.query.gameId}` || null;
    },
  },
  watch: {
    $route() {
      this.updateView();
    },
  },
  mounted() {
    this.updateView();
  },
  events: {},
  methods: {
    closeEvent() {
      console.log('close event called');
    },
    closeTag() {
      const query = {
        ...this.$route.query,
        mobile: undefined,
      };
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    updateView() {
      if (this.$route.query.mobile) {
        this.open = true;
        this.filter = this.$route.params.filter || 'in_progress';
        this.type = this.$route.params.type || 'cpi';
      } else {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss">
.mobile-view {
  width: 100%;
  background-color: var(--background-gamepage);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: -10px;
  z-index: 200;
  padding-left: 0px;
  will-change: transform;
  -moz-box-shadow: 2px 1px 32px -4px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 2px 1px 32px -4px rgba(0, 0, 0, 1);
  box-shadow: 2px 1px 32px -4px rgba(0, 0, 0, 1);
  border-radius: 10px;
  min-height: 100px;
  max-height: 98vh;
  transition: max-height 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);

  @media (min-width: 700px) {
    display: none;
  }
  .header-mobile-view {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    .title {
      font-size: 18px;
      font-weight: 700;
      margin-left:30px;
    }
  }
  .container {
    width: 100%;
    padding: 10px;
    margin-left: auto;
    height: 100%;
    background-color: var(--background-gamepage);
    overflow: auto;
    margin-bottom: 40px;
    border-bottom: 1px solid #e9e9f0;
  }

  .close {
    font-size: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}

.slide-up-enter,
.slide-up-leave-to {
  // right: -100%;
  transform: translateY(130%);

  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  // transform: translateX(0%);
  // transition-delay: 0.3s;
  // padding-left: 50px;
  .container {
    // transform: translateX(30%);
    transform: translateY(10vh);

    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
      -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
      -o-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
    transition-delay: 0.8s;
    // transform: translateX(13%);
    // transition: transform 0.3s ease-in;
  }
}
.slide-up-enter,
.slide-up-enter-active,
.slide-up-leave-active {
  // transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  // transform: translateX(0%);
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  // transition-delay: 0.3s;
  .container {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    // transition: transform 0.3s ease-out;
    // animation: tick-tock 0.3 steps(0.3,s end);
    // -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    // animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

// @-webkit-keyframes slide-right {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
//   50% {
//     -webkit-transform: translateX(100px);
//     transform: translateX(100px);
//   }
//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
// }
// @keyframes slide-right {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
//   50% {
//     -webkit-transform: translateX(100px);
//     transform: translateX(100px);
//   }
//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//   }
// }
</style>

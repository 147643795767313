<template>
  <component
    :is="type"
    :tag="tag"
    :css="false"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </component>
</template>

<script>
/* eslint-disable no-param-reassign */
import anime from 'animejs';

export default {
  name: 'CollapsorTransition',
  props: {
    duration: {
      type: Number,
      default: 500,
    },
    group: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    type() {
      return this.group ? 'transition-group' : 'transition';
    },
  },
  methods: {
    beforeEnter(el) {
      // Reseting the styles before the transition starts
      el.style.opacity = 0;
      el.style.height = 'auto';
    },
    enter(el, done) {
      const { width } = getComputedStyle(el);
      // Show the element in the Dom withtout affecting the flow
      el.style.width = width;
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.height = 'auto';
      // Getting the height of the hidden element
      const {
        height, paddingTop, paddingBottom, marginTop, marginBottom,
      } = getComputedStyle(el);
      // Hiding the element after getting its height
      el.style.width = null;
      el.style.position = null;
      el.style.visibility = null;
      el.style.height = 0;

      if (height === '0px') {
        el.style.transformOrigin = 'top center';
        anime({
          targets: el,
          opacity: {
            value: 1,
            duration: this.duration,
            delay: 0,
          },
          scaleY: [0, 1],
          easing: 'easeInOutQuart',
          duration: this.duration,
          begin() {
            el.style.height = 'auto';
          },
          complete() {
            done();
            el.style.height = 'auto';
          },
        });
        return;
      }

      anime({
        targets: el,
        opacity: 1,
        height,
        paddingTop: [0, paddingTop],
        paddingBottom: [0, paddingBottom],
        marginTop: [0, marginTop],
        marginBottom: [0, marginBottom],
        easing: 'easeInOutQuart',
        duration: this.duration,
        complete() {
          done();
          el.style.height = 'auto';
        },
      });
    },
    leave(el, done) {
      const { height } = getComputedStyle(el);
      el.style.height = height;
      anime({
        targets: el,
        opacity: 0,
        height: '0px',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        easing: 'easeInOutQuart',
        duration: this.duration,
        complete() {
          done();
        },
      });
    },
  },
};
</script>

<template>
  <div>{{ selectedRule }}</div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import deepClone from '@/components/QueryBuilder/utilities';
import QueryBuilderChildren from './QueryBuilderChildren.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    QueryBuilderChildren,
  },

  props: {
    ruleTypes: Object,
    type: {
      type: String,
      default: 'query-builder-group',
    },
    query: Object,
    rules: Array,
    index: Number,
    maxDepth: Number,
    depth: Number,
    labels: Object,
  },

  data() {
    return {
      selectedRule: '',
    };
  },

  methods: {
    ruleById(ruleId) {
      let rule = null;

      // eslint-disable-next-line consistent-return
      this.rules.forEach((value) => {
        if (value.id === ruleId) {
          rule = value;
          return false;
        }
      });

      return rule;
    },

    addRule() {
      // eslint-disable-next-line camelcase

      if (this.selectedRule) {
        const updatedQuery = deepClone(this.query);
        const child = {
          type: 'query-builder-rule',
          query: {
            rule: this.selectedRule.id,
            operator: this.selectedRule.operators[0],
            operand: typeof this.selectedRule.operands === 'undefined' ? this.selectedRule.label : this.selectedRule.operands[0],
            value: null,
          },
        };
        // A bit hacky, but `v-model` on `select` requires an array.
        if (this.ruleById(child.query.rule).type === 'multi-select') {
          child.query.value = [];
        }
        updatedQuery.children.push(child);
        this.$emit('update:query', updatedQuery);
      }
    },

    addGroup() {
      // eslint-disable-next-line camelcase
      const updatedQuery = deepClone(this.query);
      if (this.depth < this.maxDepth) {
        updatedQuery.children.push({
          type: 'query-builder-group',
          query: {
            logicalOperator: this.labels.matchTypes[0].id,
            children: [],
          },
        });
        this.$emit('update:query', updatedQuery);
      }
    },

    remove() {
      this.$emit('child-deletion-requested', this.index);
    },

    removeChild(index) {
      // eslint-disable-next-line camelcase
      const updatedQuery = deepClone(this.query);
      updatedQuery.children.splice(index, 1);
      this.$emit('update:query', updatedQuery);
    },
  },
};
</script>

export default [
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'gameplay',
    label: 'Gameplay',
  },
  {
    value: 'ui',
    label: 'Ui',
  },
  {
    value: 'controls',
    label: 'Controls',
  },
  {
    value: 'content_level_design',
    label: 'Content level design',
  },
  {
    value: 'core_mechanics',
    label: 'Core mechanics',
  },
  {
    value: 'bug',
    label: 'Bug',
  },
];

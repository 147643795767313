/* eslint-disable no-param-reassign */
import { cloneDeep, groupBy } from 'lodash';

import GamesService from '@/services/games.services';
import CookiesService from '@/tools/cookies';
import StudioService from '@/services/studios.services';

const sorted = CookiesService.getSortPref() ? CookiesService.getSortPref().sort : 'cpi';
const order = CookiesService.getSortPref() ? CookiesService.getSortPref().order : 'asc';
const filterDef = CookiesService.getFilterPref() ? CookiesService.getFilterPref() : null;
const bestDef = CookiesService.getBestPref() !== null ? CookiesService.getBestPref() === '1' : true;
const Games = {
  namespaced: true,
  state: {
    gamesList: [],
    loading: false,
    gameInfos: {
      isUpdated: false,
      disabled: false,
    },
    pmLoading: false,
    pm2Loading: false,
    loadingInfos: false,
    actionsAvailable: [],
    kpiLoading: false,
    actionLoading: false,
    kpiGame: null,
    gaId: null,
    genres: [],
    pageInfos: {
      limit: 20,
      offset: 0,
      sort: sorted,
      order,
      term: '',
      date: ['', ''],
      filter: filterDef,
      best: bestDef,
    },
  },
  getters: {
    actionLoading(state) {
      return state.actionLoading;
    },
    pmLoading(state) {
      return state.pmLoading;
    },
    pm2Loading(state) {
      return state.pm2Loading;
    },
    gameInfos(state) {
      return state.gameInfos;
    },
    gamesList(state) {
      return state.gamesList;
    },
    loading(state) {
      return state.loading;
    },
    pageInfo(state) {
      return state.pageInfos;
    },
    actionsAvailable(state) {
      return state.actionsAvailable;
    },
    searchInGameList(state) {
      return state.pageInfos.term;
    },
    filters(state) {
      return state.pageInfos.filter;
    },
    best(state) {
      return state.pageInfos.best;
    },
    date(state) {
      return state.pageInfos.date;
    },
    loadingInfos(state) {
      return state.loadingInfos;
    },
    kpiLoading(state) {
      return state.kpiLoading;
    },
    kpiGame(state) {
      return state.kpiGame;
    },
    gaIdGame(state) {
      return state.gaId;
    },
    gameGenres(state) {
      return state.genres;
    },
  },
  mutations: {
    add_games_list(state, listItem) {
      const list = state.gamesList.concat(listItem);
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const seriesGrpBy = groupBy(element.videos, 'iteration_id');
        element.videoIteration = seriesGrpBy;
      }

      state.gamesList = cloneDeep(list);
    },
    set_game_infos(state, infos) {
      let gameObject = {};
      if (state.gameInfos.id !== infos.id) {
        gameObject = {
          ...infos,
        };
      } else {
        gameObject = {
          ...state.gameInfos,
          ...infos,
        };
      }

      state.gameInfos = gameObject;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_infosLoading(state, loading) {
      state.loadingInfos = loading;
    },
    update_page_infos(state) {
      state.pageInfos.offset += 20;
    },
    check_operation(state, checkOperation) {
      state.gameInfos = {
        ...state.gameInfos,
        checkOperation,
      };
    },
    update_CoachedGameInlistGame(state, param) {
      for (let i = 0; i < state.gamesList.length; i += 1) {
        const element = state.gamesList[i];
        if (element.studioId === param.id) {
          element.pmName = `${param.p.firstName} ${param.p.lastName}`;
        }
      }
    },
    reset_list(state) {
      state.gamesList = [];
      state.pageInfos.offset = 0;
    },
    set_video_available_for_a_game(state, item) {
      const d = cloneDeep(item.value);
      for (let j = 0; j < d.iterations.length; j += 1) {
        const el = d.iterations[j];
        for (let p = 0; p < el.videos.length; p += 1) {
          const ele = el.videos[p];
          ele.loading = false;
        }
      }
      const gameobj = {
        ...state.gameInfos,
        iterationsVideos: d,
      };
      state.gameInfos = gameobj;
    },
    set_pm_infos(state, pm) {
      const p = {
        firstName: pm.label,
        lastName: '',
        id: pm.id,
      };
      if (state.gameInfos.studio) {
        state.gameInfos.studio.pm = p;
      }
    },
    set_pm_infos_v2(state, pm) {
      const p = {
        firstName: pm.firstName,
        lastName: pm.lastName,
        id: pm.id,
      };
      if (state.gameInfos.studio) {
        state.gameInfos.studio.pm = p;
      }
    },
    set_pm2_infos(state, pm) {
      const pm2 = {
        firstName: pm.label,
        lastName: '',
        id: pm.id,
      };
      if (state.gameInfos.studio) {
        state.gameInfos.studio.secondaryPm = pm2;
      }
    },
    set_updatePmLoading(state, loading) {
      state.pmLoading = loading;
    },
    set_updatePm2Loading(state, loading) {
      state.pm2Loading = loading;
    },
    set_kpiLoading(state, loading) {
      state.kpiLoading = loading;
    },
    set_kpiGame(state, kpi) {
      state.kpiGame = kpi;
    },
    set_V1_object(state, obj) {
      state.gameInfos = {
        ...state.gameInfos,
        V1: obj,
      };
    },
    set_updated_game(state, value) {
      state.gameInfos = {
        ...state.gameInfos,
        isUpdated: value,
      };
    },
    set_loadingDisable_game(state, value) {
      state.gameInfos = {
        ...state.gameInfos,
        disabled: value,
      };
    },
  },
  actions: {
    check_operation(context, checkOperation) {
      context.commit('check_operation', checkOperation);
    },
    async fetchList(context, view) {
      const params = {
        ...context.state.pageInfos,
        view,
      };
      return new Promise(async (resolve) => {
        context.commit('set_loading', true);
        const list = await GamesService.fetchGames(params);
        context.commit('set_loading', false);
        if (list && list.data && list.data.length > 0) {
          context.commit('update_page_infos');
          context.commit('add_games_list', list.data);
        }
        resolve(list);
      });
    },
    setDisabledGame(context, value) {
      context.commit('set_loadingDisable_game', value.value);
    },
    resetGameList(context) {
      context.commit('reset_list');
    },
    async refeshGameObject(context, id) {
      const result2 = await GamesService.getGamesInfos(id);
      context.commit('set_infosLoading', false);
      if (result2) {
        context.commit('set_game_infos', result2);
        context.commit('set_kpiGame', result2);
      } else {
        context.commit('set_game_infos', {});
        context.commit('set_kpiGame', {});
      }
    },
    async coachStudio(context, param) {
      context.commit('set_updatePmLoading', true);
      const result = await StudioService.coachStudio(param.studioId);
      context.commit('set_updatePmLoading', false);
      if (!result.code) {
        context.commit('set_pm_infos_v2', result);
        context.commit('update_CoachedGameInlistGame', {
          id: param.studioId,
          p: result,
        });
      }
    },
    async updatePm(context, { studioId, publishingManagerId }) {
      context.commit('set_updatePmLoading', true);
      await StudioService.updateStudioPm(studioId, publishingManagerId.value);
      context.commit('set_updatePmLoading', false);
      context.commit('set_pm_infos', publishingManagerId);
    },
    async updatePm2(context, { studioId, publishingManagerId }) {
      context.commit('set_updatePm2Loading', true);
      await StudioService.updateStudioPm2(studioId, publishingManagerId.value);
      context.commit('set_updatePm2Loading', false);
      context.commit('set_pm2_infos', publishingManagerId);
    },
    async getAvailableChoiceForASpecificAction(context, param) {
      return new Promise(async (resolve) => {
        const result = await GamesService.getSpecificActionById(param);

        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    },
    async getKpi(context, id) {
      context.commit('set_kpiLoading', true);
      const result = await GamesService.getKpiGame(id);
      context.commit('set_kpiLoading', false);
      if (result) {
        context.commit('set_kpiGame', result);
      }
    },
  },
};

export default Games;

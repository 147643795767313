/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
// import { Message } from 'element-ui';
import { Notification } from 'element-ui';
import gameActionService from '@/services/gameAction.services';
import GamesService from '@/services/games.services';

const ActionToDispatch = {
  namespaced: true,
  state: {
    loading: {
      nextStep: false,
      backStep: false,
      videos: false,
      actionButton: false,
      disabled: false,
      icon: false,
      genres: false,
    },
  },
  getters: {},
  mutations: {},
  actions: {
    getMessageErrorFromCodif(context, responseError) {
      const reason = responseError.error;

      if (context.rootState.users.codification.errorList[reason]) {
        return context.rootState.users.codification.errorList[reason].message;
      }
      return reason;
    },

    dispatchAction(context, param) {
      if (param.action.async) {
        return this.dispatch('ActionToDispatch/pushActionAsyncV1', param);
      }
      return this.dispatch('ActionToDispatch/pushActionV1', param);
    },
    async pushActionV1(context, param) {
      const p = {
        id: param.id,
        value: true,
      };
      this.dispatch('ActionToDispatch/dispatchDisabledGame', p);

      const response = await GamesService.setActionById(param);

      p.value = false;
      this.dispatch('ActionToDispatch/dispatchDisabledGame', p);
      if (!response.error) {
        await this.dispatch('ActionToDispatch/refreshGame', param.id);
        this.dispatch('gameOperations/setForGame', {
          gameId: param.id,
          operation: {
            gameId: param.id,
            status: 'success',
            content: param.action.name ? param.action.name : null,
            operationType: 'game-operation',
            operationId: null,
          },
        });
        return { isSuccess: true };
      }
      const { error } = response;
      // error.detail is undefined for migrated actions and replaced by errorList[]
      let reason = Array.isArray(error.errorList) && error.errorList.length > 0 ? error.errorList[0].code : null;
      if (!reason) {
        reason = error.detail && error.detail.response.data.detail ? error.detail.response.data.detail : error.detail;
      }
      this.dispatch('gameOperations/setForGame', {
        gameId: param.id,
        operation: {
          gameId: param.id,
          status: 'error',
          content: context.rootState.users.codification.errorList[reason],
          operationType: 'game-operation',
          operationId: error?.errorList[0]?.extra?.operationId,
        },
      });
      return {
        isSuccess: false,
        reason,
      };
    },

    async pushActionAsyncV1(context, param) {
      const p = {
        id: param.id,
        value: true,
      };
      this.dispatch('ActionToDispatch/dispatchDisabledGame', p);

      const response = await GamesService.setActionById(param);

      if (response.error) {
        p.value = false;
        this.dispatch('ActionToDispatch/dispatchDisabledGame', p);
      }
    },
    async dispatchDisabledGame(context, param) {
      if (context.rootState.submitGame.game) {
        if (context.rootState.submitGame.game.id === param.id) {
          this.dispatch('submitGame/setDisabledGame', param.value);
        }
      }
      if (context.rootState.games.gameInfos) {
        if (context.rootState.games.gameInfos.id === param.id) {
          this.dispatch('games/setDisabledGame', { value: param.value });
        }
      }

      if (context.rootState.Project.project) {
        context.commit('Project/set_disabled_game', param, { root: true });
      }

      this.dispatch('gameAction/disableGame', {
        id: param.id,
        value: param.value,
      });
    },
    async dispatchCheckOperationGame(context, param) {
      if (context.rootState.submitGame.game) {
        if (context.rootState.submitGame.game.id === param.game.id) {
          this.dispatch('submitGame/check_operation', param.value);
        }
      }
      if (context.rootState.games.gameInfos) {
        if (context.rootState.games.gameInfos.id === param.game.id) {
          this.dispatch('games/check_operation', param.value);
        }
      }
      if (context.rootState.Project.project) {
        context.commit('Project/check_operation', param, { root: true });
      }
      await this.dispatch('gameAction/check_operation', {
        game: param.game,
        value: param.value,
      });
    },
    // TODO: refacto
    async setRequestTesting(context, param) {
      this.dispatch('submitGame/setDisabledGame', true);
      const response = await gameActionService.setRequestTestingByGameId(param);

      if (response.detail) {
        this.dispatch('submitGame/setDisabledGame', false);
      }
    },
    // TODO: refacto
    async linkCampagn(context, param) {
      this.dispatch('submitGame/setDisabledGame', true);
      const response = await GamesService.setActionById(param);

      if (response.error) {
        this.dispatch('submitGame/setDisabledGame', false);
      }
    },

    async refreshGame(context, id) {
      const rep = await gameActionService.getStatsByGameId({
        id,
      });
      if (rep) {
        const game = {
          ...rep,
        };

        if (context.rootState.submitGame.game) {
          if (context.rootState.submitGame.game.id === id) {
            context.commit('submitGame/set_game', game, { root: true });
          }
        }

        context.commit('gameAction/update_game', game, { root: true });
        context.commit('Project/fetchGame', game, { root: true });

        this.dispatch('gameAction/getNumberGameInStatus', {
          myGamesOnly: true,
          isCtr: game.isCtr,
        });
      }
    },
    async updateGameProperty(context, param) {
      // param = {loading, property, id}
      return new Promise(async (resolve) => {
        // set loading
        if (param.loading === 'nextStep') {
          context.commit('submitGame/set_nextStepLoading', true, { root: true });
          if (param.substatus === 'sdk_facebook') {
            context.commit('submitGame/set_loading_property', true, { root: true });
          }
        }
        if (param.loading === 'backStep') {
          context.commit('submitGame/set_backStepLoading', true, { root: true });
        }
        if (param.loading === 'property') {
          context.commit('submitGame/set_loading_property', true, { root: true });
        }

        // call
        const item = cloneDeep(param);
        delete item.loading;
        const response = await gameActionService.updateGameByProperties(item);
        if (response.code === 403) {
          const message = response.msg === 'Forbidden' ? 'Invalid permission' : 'An error occured';
          Notification.error({
            title: 'Error',
            message,
            position: 'bottom-left',
          });
        }

        // dispatch Game
        if (response.detail) {
          // set loading
          if (param.loading === 'nextStep') {
            context.commit('submitGame/set_nextStepLoading', false, { root: true });
            if (param.substatus === 'sdk_facebook') {
              context.commit('submitGame/set_loading_property', false, { root: true });
            }
          }
          if (param.loading === 'backStep') {
            context.commit('submitGame/set_backStepLoading', false, { root: true });
          }
          if (param.loading === 'property') {
            context.commit('submitGame/set_loading_property', false, { root: true });
          }
          resolve(false);
        }
        const game = { ...response };

        if (param.loading === 'nextStep') {
          context.commit('submitGame/set_nextStepLoading', false, { root: true });
          if (param.substatus === 'sdk_facebook') {
            context.commit('submitGame/set_loading_property', false, { root: true });
          }
        }
        if (param.loading === 'backStep') {
          context.commit('submitGame/set_backStepLoading', false, { root: true });
        }
        if (param.loading === 'property') {
          context.commit('submitGame/set_loading_property', false, { root: true });
        }
        context.commit('submitGame/set_game', game, { root: true });
        context.commit('gameAction/update_game', game, { root: true });

        resolve(true);
      });
    },
  },
};

export default ActionToDispatch;

const simpleContent = {
  term: '',
  type: 'studios',
  filter: {
    logicalOperator: 'all',
    children: [],
  },
  columns: [
    {
      id: 'name',
      label: 'Name',
      width: 100,
      isSelected: true,
    },
    {
      id: 'pmName',
      label: 'PM',
      width: 120,
      isStaff: true,
      isSelected: true,
    },
    {
      id: 'gamesCpi3Months',
      label: '~ CPI',
      width: 70,
      isSelected: true,
    },
    {
      id: 'gamesDay13Months',
      label: '~ Day 1',
      width: 100,
      isStaff: true,
      isSelected: true,
    },
    {
      id: 'gamesDay73Months',
      label: '~ Day 7',
      width: 70,
      isStaff: true,
      isSelected: true,
    },

    {
      id: 'nbPrototypes',
      label: 'Nb Proto',
      width: 70,
      isSelected: true,
    },
    {
      id: 'sinceLastIterationDate',
      label: 'Last Iteration Date',
      width: 100,
      isSelected: true,
    },
    {
      id: 'gamesNbPrototypes6Months',
      label: 'Nb proto Last 6 months',
      width: 150,
      isSelected: true,
    },
    {
      id: 'activityState',
      label: 'Activity',
      width: 70,
      isSelected: false,
    },
    {
      id: 'gamesCtr3Months',
      label: '~ CTR',
      width: 70,
      isSelected: false,
    },
    {
      id: 'nbHits',
      label: 'Nb Hits',
      width: 70,
      isSelected: false,
    },
    {
      id: 'creationDate',
      label: 'Created',
      width: 120,
      isSelected: false,
    },
    {
      id: 'contract',
      label: 'Contract',
      width: 100,
      isSelected: false,
    },

    {
      id: 'secondaryPmName',
      label: '2nd PM',
      width: 100,
      isSelected: false,
    },
    {
      id: 'gamesPlaytime3Months',
      label: 'Playtime',
      width: 100,
      isSelected: false,
    },
  ],
  best: true,
  sort: {
    column: {
      order: 'ascending',
      id: 'el-table_1_column_7',
      type: 'default',
      property: 'gamesCpi3Months',
      align: null,
      headerAlign: null,
      showOverflowTooltip: false,
      filteredValue: [],
      filterPlacement: '',
      isColumnGroup: false,
      filterOpened: false,
      sortable: 'custom',
      label: 'Last iteration date',
      className: 'column-gamesCpi3Months column',
      fixed: false,
      resizable: true,
      sortOrders: ['ascending', 'descending', null],
      reserveSelection: false,
      filterMultiple: true,
      minWidth: 130,
      realWidth: 159,
      level: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    prop: 'gamesCpi3Months',
    order: 'asc',
  },
};
export default simpleContent;

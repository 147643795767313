<template>
  <div :class="$style.card">
    <div :class="$style.content">
      <div :class="$style.titleContainer">
        <div
          :class="$style.title"
          data-test-id="gameInfosKpiBigTitle"
        >
          {{ title }}
        </div>
        <el-tooltip placement="top">
          <div slot="content">{{ tooltip }}</div>
          <i class="icon vd-svg-kpismallicon" />
        </el-tooltip></div>
      <div
        :class="$style.value"
        data-test-id="gameInfosKpiBigValue"
      >
        <span v-if="unitBefore && isValueAvailable">{{unit}}</span>
        {{ valueDisplay.toLocaleString() }}
        <span v-if="!unitBefore && isValueAvailable">{{unit}}</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'KpiBig',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Number, String],
    },
    unit: {
      type: String,
      default: '',
    },
    unitBefore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isValueAvailable() {
      return this.value && !Number.isNaN(this.value) && this.value !== 'NaN';
    },
    valueDisplay() {
      return this.isValueAvailable
        ? this.value : '-';
    },
  },
};
</script>

<style lang="scss" module>
.card {
  background-color: $white;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  font-size: 14px;
  color: $grey;
  margin: 10px 0px;
  display: flex;
}

.title {
  margin-right: $spacing;
}

.value {
  font-size: 36px;
  font-weight: 600;
  color: $soft-black;
}
</style>

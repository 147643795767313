module.exports.recommendationMapping = {
  'high potential': {
    label: 'High potential',
    details: "Congrats! You've reached remarkable metrics on this prototype.<br/>"
      + 'Start planning your product roadmap and iterations together with your Publishing Manager as soon as possible.',
    icon: 'arrow-alt-up',
    class: 'high',
    displayScore: true,
  },
  'pending data': {
    label: 'Pending data',
    details: 'Your test is ongoing or finished recently, we are still gathering all data needed to provide a recommendation.',
    icon: 'hourglass-half',
    class: 'none',
    displayScore: false,
  },
  'low potential - low proto score': {
    label: 'Low potential',
    details: 'Unfortunately, your prototype has no potential to succeed.<br/><br/>'
      + "We have run thousands of tests in the past, and there wasn't a single hit among games that had similar KPIs at an early stage, even after multiple attempts to improve. Sometimes, even interesting games can fail to reach strong potential, but success comes from moving on to your next project.<br/><br/>"
      + 'Therefore, you will no longer be able to iterate this prototype through the publishing platform (even if you create a new submission).<br/><br/>'
      + 'We strongly encourage you to save time and move on to your next prototype.',
    icon: 'arrow-alt-down',
    class: 'low',
    displayScore: true,
  },
  'low potential - insufficient ga new users': {
    label: 'Low potential',
    details: 'Unfortunately, your prototype has no potential to succeed.<br/><br/>'
      + "We have run thousands of tests in the past, and there wasn't a single hit among games that had similar KPIs at an early stage, even after multiple attempts to improve. Sometimes, even interesting games can fail to reach strong potential, but success comes from moving on to your next project.<br/><br/>"
      + 'Therefore, you will no longer be able to iterate this prototype through the publishing platform (even if you create a new submission).<br/><br/>'
      + 'We strongly encourage you to save time and move on to your next prototype.',
    icon: 'arrow-alt-down',
    class: 'low',
    displayScore: false,
  },
  'no recommendation': {
    label: 'No recommendation',
    details: 'Your test has ended but we are not able to provide a recommendation.',
    icon: 'empty-set',
    class: 'none',
    displayScore: false,
  },
  'inconclusive - insufficient campaign spend': {
    label: 'Inconclusive',
    details: 'Your test has ended but your campaign did not spend enough for us to provide a recommendation.',
    icon: 'empty-set',
    class: 'none',
    displayScore: false,
  },
  'setup error - ga new users below network installs': {
    label: 'Setup error',
    details: 'The number of new users counted by GameAnalytics is abnormally low compared to the number of installs. Check your setup before iterating on your game.',
    icon: 'exclamation-triangle',
    class: 'none',
    displayScore: true,
  },
  'setup error - no recorded ga new users': {
    label: 'Setup error',
    details: 'No GameAnalytics data was recorded. Check your setup before iterating on your game.',
    icon: 'exclamation-triangle',
    class: 'none',
    displayScore: false,
  },
  'setup error - no recorded network installs': {
    label: 'Setup error',
    details: 'No installs were recorded. Check your setup before iterating on your game.',
    icon: 'exclamation-triangle',
    class: 'none',
    displayScore: false,
  },
  'cohorts not mature': {
    label: 'Cohorts Not Mature',
    details: 'Cohorts are not mature yet. Wait couple of more days for cohorts to reach D3.',
    icon: 'arrow-alt-down',
    class: 'low',
    displayScore: false,
  },
};

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="vqb-rule card">
    <div v-if="rule" class="form-inline">
      <label v-if="rule.field === 'sinceLastIterationDate'" class="mr-5">{{ rule.label + " (days)" }}</label>

      <label
        v-if="(rule.label.includes('Session') || rule.label.includes('Playtime')) && rule.label.includes('Human')"
        class="mr-5"
      >{{ rule.label + " (ex: 1m66s)" }}</label
      >

      <label
        v-if="(rule.label.includes('Session') || rule.label.includes('Playtime')) && !rule.label.includes('Human')"
        class="mr-5"
      >{{ rule.label + " (seconds)" }}</label
      >
      <label
        v-if="!rule.label.includes('Session') && !rule.label.includes('Playtime') && rule.field !== 'sinceLastIterationDate'"
        class="mr-5"
      >{{ rule.label }}</label
      >

      <!-- List of operands (optional) -->
      <select v-if="typeof rule.operands !== 'undefined'" v-model="query.operand" class="form-control mr-2">
        <option v-for="operand in rule.operands" :key="operand">{{ operand }}</option>
      </select>

      <!-- List of operators (e.g. =, !=, >, <) -->
      <select
        v-if="typeof rule.operators !== 'undefined' && rule.operators.length > 1"
        v-model="query.operator"
        class="form-control mr-2"
      >
        <option v-for="operator in rule.operators" :key="operator" :value="operator">
          {{ operator }}
        </option>
      </select>

      <!-- Basic text input -->
      <input
        v-if="rule.inputType === 'text'"
        v-model="query.value"
        class="form-control"
        type="text"
        :placeholder="labels.textInputPlaceholder"
      />

      <!-- Basic number input -->
      <input
        v-if="rule.inputType === 'number'"
        v-model="query.value"
        class="form-control"
        type="number" />
      <!-- Datepicker -->
      <div v-if="isCustomComponent && rule.kind === 'date'" class="vqb-custom-component-wrap">
        <component
          :is="rule.component"
          :value="query.value"
          type="date"
          :value-format="'yyyy-MM-dd'"
          @input="updateQuery" />
      </div>

      <!-- Custom component input -->
      <div v-if="isCustomComponent && !rule.kind" class="vqb-custom-component-wrap">
        <component
          :is="rule.component"
          :value="query.value"
          :max="5"
          :min="0"
          :step="1"
          @input="updateQuery" />
      </div>
      <div v-if="isCustomComponent && rule.kind === 'cpi'" class="vqb-custom-component-wrap">
        <component
          :is="rule.component"
          :value="query.value"
          :max="rule.max"
          :min="rule.min"
          :step="rule.step"
          :format-tooltip="formatTooltipCpi"
          @input="updateQuery"
        />
      </div>
      <div v-if="isCustomComponent && rule.kind === 'retention'" class="vqb-custom-component-wrap">
        <component
          :is="rule.component"
          :value="query.value"
          :max="rule.max"
          :min="rule.min"
          :step="rule.step"
          :format-tooltip="formatTooltipRet"
          @input="updateQuery"
        />
      </div>

      <!-- Checkbox input -->
      <template v-if="rule.inputType === 'checkbox'">
        <div v-for="choice in rule.choices" :key="choice.value" class="form-check form-check-inline">
          <input
            :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
            v-model="query.value"
            type="checkbox"
            :value="choice.value"
            class="form-check-input"
          />
          <label class="form-check-label" :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value">{{
            choice.label
          }}</label>
        </div>
      </template>

      <!-- Radio input -->
      <template v-if="rule.inputType === 'radio'">
        <div v-for="choice in rule.choices" :key="choice.value" class="form-check form-check-inline">
          <input
            :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
            v-model="query.value"
            :name="'depth' + depth + '-' + rule.id + '-' + index"
            type="radio"
            :value="choice.value"
            class="form-check-input"
          />
          <label class="form-check-label" :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value">{{
            choice.label
          }}</label>
        </div>
      </template>
      <!-- Select without groups -->
      <select
        v-if="
          rule.inputType === 'select' &&
            !hasOptionGroups &&
            (rule.field === 'pmName' || rule.field === 'secondaryPmName' || rule.field === 'assignedPmName') &&
            query.operator !== 'is null' &&
            query.operator !== 'is not null'
        "
        v-model="query.value"
        class="form-control"
        :multiple="rule.type === 'multi-select'"
      >
        <option v-for="option in selectOptions" :key="option.label" :value="option.label">
          {{ option.label }}
        </option>
      </select>
      <select
        v-if="
          rule.inputType === 'select' &&
            !hasOptionGroups &&
            !(rule.field === 'pmName' || rule.field === 'secondaryPmName' || rule.field === 'assignedPmName') &&
            query.operator !== 'is null' &&
            query.operator !== 'is not null'
        "
        v-model="query.value"
        class="form-control"
        :multiple="rule.type === 'multi-select'"
      >
        <option v-for="option in selectOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>

      <!-- Select with groups -->
      <select
        v-if="rule.inputType === 'select' && hasOptionGroups"
        v-model="query.value"
        class="form-control"
        :multiple="rule.type === 'multi-select'"
      >
        <optgroup v-for="(option, option_key) in selectOptions" :key="option_key" :label="option_key">
          <option v-for="sub_option in option" :key="sub_option.value" :value="sub_option.value">
            {{ sub_option.label }}
          </option>
        </optgroup>
      </select>

      <!-- Remove rule button -->
      <button
        type="button"
        class="close ml-auto"
        @click="remove"
        v-html="labels.removeRule"></button>
    </div>
  </div>
</template>
<style lang="scss">
.vue-query-builder {
  .el-date-editor {
    margin-right: 15px;
    input {
      padding-left: 30px !important;
    }
    .el-input__suffix {
      .el-input__icon {
        display: none !important;
      }
    }
  }
  .vqb-children {
    max-height: 500px;
    overflow: auto;
  }
}
</style>
<script>
import QueryBuilderRule from '@/components/QueryBuilder/QueryBuilderRules.vue';

export default {
  extends: QueryBuilderRule,
  methods: {
    formatTooltipRet(val) {
      return `${(val * 100).toFixed(2)} %`;
    },
    formatTooltipCpi(val) {
      return `${val} $`;
    },
  },
};
</script>

<template>
  <div
    v-if="text"
    class="custom-tooltip"
    @click="copyToClipboard(text)"
  >
    <div :class="$style.textWrapper">
      {{ text }}
      <font-awesome-icon icon="clone" />
    </div>
    <div v-if="tooltip" class="tooltip">
      Copied!
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyToClipboard',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltip: '',
    };
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.tooltip = 'Copied';
      setTimeout(() => {
        this.tooltip = '';
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.custom-tooltip {
  position: relative;
  cursor: pointer;
  color: #067bf0;
  display: block;

  .button-clipboard {
    display: none;

    position: absolute;
    padding: 5px !important;
  }
  &:hover {
    svg {
      color: $intense-blue;
    }
  }
  svg {
    color: $cloudy-blue;
  }

  &:hover {
    > .el-button {
      display: inline-block;
    }
  }
  .tooltip {
    background-color: #4c4f5e;
    position: absolute;
    right: 46px;
    height: 68%;
    top: 0%;
    margin: auto;
    padding: 10px;
    color: #FFF;
    font-size: 12px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    // width: 416px;
    height: 35px;
  }
}

</style>

<style lang="scss" module>
.textWrapper {
  border-radius: 8px;
  border: solid 1px $foggy-grey;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  white-space: break-spaces;
  word-break: break-all;
}
</style>

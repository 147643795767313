<template>
  <div id="hijack">
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('users/releaseHijack');
  },
};
</script>

<style lang="scss"></style>

<template>
  <div :class="$style.gameProjectIcon" data-test-id="gameInfosProjectIcon" @click.prevent="$emit('click', $event)">
    <font-awesome-icon :class="$style.icon" icon="folder" />
    <div data-test-id="gameInfosProjectCount" :class="$style.count">{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: 'GameProjectIcon',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.gameProjectIcon {
  position: relative;
  width: 21px;
  height: 21px;
  &:hover {
    cursor: pointer;
    .icon {
      color: $intense-blue;
    }
  }
}

.icon {
  color: $light-blue;
  width: auto;
  height: auto;
  font-size: 21px;
}

.count {
  position: absolute;
  width: 100%;
  top: 5px;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
</style>

<template>
  <div :class="$style.container">
    <h2 id="title-noAction">
      {{title}}
    </h2>
    <div :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <p :class="$style.text">
        <span>No action required here.</span><br>
        You just need to follow the tutorial on the right 👉</p>

      <el-button
        v-if="!steps[index].isValid"
        id="validate-without-action"
        data-test-id="gameSubmissionEmptyStepValidateButton"
        type="primary"
        size="small"
        :disabled="steps[index].isValid"
        @click="validateStep(index)">
        <span>Validate</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import tools from '@/tools/tools';
// import steps from '@/modules/ScrollStepper/data/steps';


export default {
  name: 'WithoutAction',
  props: {
    game: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'no name',
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isIntersecting: false,
      isvisible: false,
      isValid: false,
    };
  },
  computed: {
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
  },
  mounted() {
    tools.attachEvent(document.getElementById('container-scrollable'), 'scroll', this.update);
    tools.attachEvent(window, 'resize', this.update);
  },
  methods: {
    update() {
      const titleElement = document.getElementById('title-noAction');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    validateStep(index) {
      this.isValid = true;
      this.$emit('isValid', index);
    },
  },

};
</script>

<style lang="scss" module>

.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  position: relative;
   .layer{
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.adjustBtn {
    margin-top: $spacing;
}

.text {
    font-weight: 600;
    margin-bottom: $spacing;
}
</style>

<template>
  <div class="home">
    <Bar></Bar>
    <div class="main">
      <ListItems></ListItems>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Bar from '@/components/Bar.vue';
import ListItems from '@/components/ListItems.vue';

export default {
  name: 'Quotes',
  components: {
    Bar,
    ListItems,
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>


<style lang="scss">

</style>

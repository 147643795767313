<template>
  <div class="header-content-in-progress" data-test-id="myGamesGameItem">
    <div class="header-compact-game">
      <div class="infos-left">
        <GameIcon :game="item"></GameIcon>
        <div class="basic-infos-game">
          <div class="top">
            <div class="name game-name" data-test-id="myGamesGameName">{{ name }}</div>
            <el-tooltip placement="right" :visible-arrow="false">
              <div slot="content">
                This game is shared with other studios
                <br />in the Knowledge Sharing space
              </div>
              <el-image
                v-if="item.isInKnowledgeSharing"
                data-test-id="myGamesGameIcon"
                style="width: 30px;
                        height: 15px;
                        "
                :src="logoKs"
                :fit="'contain'"
              >
                <div slot="error" class="image-slot">
                  <span><img :src="gameIcon" :width="40"/></span>
                </div>
              </el-image>
            </el-tooltip>
          </div>
          <div class="createdAt">
            Creation:
            <span data-test-id="myGamesGameCreationDate">{{ item.createdAt | localeDate }}</span>
          </div>
          <div class="sub-infos">
            <div class="platform">
              <div v-if="item" class="icon-status">
                <div v-if="item.platform === 'ios'" class="text-container" data-test-id="myGamesGameOS"><i class="vd-svg-ios" /> iOS</div>
                <div v-if="item.platform === 'android'" class="text-container"><i class="vd-svg-android" data-test-id="myGamesGameOS"/> Android</div>
                <div v-if="!item.isManagedByVoodoo && canCreateAd" class="text-container"><i class="vd-svg-manual" data-test-id="myGamesGameSetup"/> Manual</div>
                <div v-if="item.isManagedByVoodoo && canCreateAd" class="text-container"><i class="vd-svg-auto" data-test-id="myGamesGameSetup"/> Auto</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="infos-right">
        <ActionButton v-if="item.actions" :game="item"></ActionButton>
        <div class="divider"></div>
      </div>
      <div class="infos-end">
        <i v-if="!item.iterations && !item.actions" class="el-icon-loading"/>
        <InProgressGaugeComponent v-else :game="item"></InProgressGaugeComponent>
      </div>
    </div>
  </div>
</template>

<script>
import iconCPI from '@/assets/images/cpi.png';
import ActionButton from '@/modules/ActionButton';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import InProgressGaugeComponent from '@/components/InProgressGaugeComponent.vue';
import gaImage from '@/assets/images/pic-5-abf-96-e-6-ca-9715-512-x-99048203@3x.png';
import fbImage from '@/assets/images/x-4-g-0-h-2-ly-400-x-400@2x.png';
import tagIcon from '@/assets/images/tag.svg';
import logoKs from '@/assets/images/knowledge-sharing@3x.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'HeaderCompactStudioGame',
  components: {
    ActionButton,
    InProgressGaugeComponent,
    GameIcon,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gameIcon,
      tagIcon,
      fbImage,
      gaImage,
      iconCPI,
      logoKs,
      headerHeight: 100,
    };
  },
  computed: {
    name() {
      return this.item.name.length > 25 ? `${this.item.name.substr(0, 25)}...` : this.item.name;
    },
    canCreateAd() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    type() {
      return this.$route.params.type || 'cpi';
    },
    filter() {
      return this.$route.params.filter || 'in_progress';
    },
    showKpiCpi() {
      if (this.type === 'cpi' && this.filter !== 'in_progress' && this.filter !== 'pending_approval') {
        return true;
      }
      return false;
    },
  },
  watch: {},
  async created() {
    await this.getActions();
  },
  methods: {
    async getActions() {
      await this.$store.dispatch('ActionToDispatch/refreshGame', this.item.id);
    },
    gotoGameView() {
      // this.$router.push({
      //   name: 'submitGame',
      //   params: { id: this.item.id },
      // });
      this.$router
        .push({
          query: {
            game: undefined,
            studio: undefined,
            view: undefined,
            gameid: this.item.id,
          },
        })
        .catch(() => null);
    },
    getTagsArray(item) {
      if (!item || item === '|') return null;
      if (typeof item === 'string') {
        return item.split('|');
      }
      return item;
    },
  },
};
</script>

<style lang="scss">
.divider {
  width: 1px;
  height: 30px;
  background-color: $foggy-grey;
}

.icon-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1px;
  margin-right: 10px;
  width: auto;
  padding-top: 2px;

  .vd-svg-manual,
  .vd-svg-auto {
    margin: 0px 4px;
  }

  .text-container {
  i {
      margin-right: 5px;
    }
    align-items: center;
    display: flex;
    font-size: 11px;
    margin-right: 5px;
    font-weight: 600;
  }


  img {
    // padding: 5px;
    margin-right: 5px;
  }
  .tag {
    display: flex;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    color: #067bf0;
    background-color: #e6f1fd;
    border: 1px solid #e6f1fd;

    padding: 1px 6px 2px 3px;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
      border: 1px solid #067bf0;
    }
  }
}
.header-content-in-progress {
  .mobile-kpi {
    display: none;
  }
  @media screen and (max-width: 700px) {
    .mobile-kpi {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      .mKPI {
        margin: 10px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label {
          font-size: 11px;
          font-weight: normal;
          margin-top: 0;
          color: #4d4f5c;
        }
        .na {
          font-size: 12px;
          color: #adadad;
        }
      }
    }
  }
}
.header-content-in-progress {
  width: 100%;
  position: sticky;
  top: 130px;
  z-index: 2;
  border-radius: 10px;
  background-color: #fff;
  @media screen and (max-width: 700px) {
    position: sticky;
    top: 185px;
  }
  .header-compact-game {
    width: 100%;
    height: 72px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    border-radius: 10px;
    z-index: 1;
    @media screen and (max-width: 1200px) {
      grid-template-columns: 2fr 2fr 2fr;
    }
    @media screen and (max-width: 850px) {
        padding: 5px 10px;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 3fr 1fr;
      height: 100%;
    }
    &.isCtr {
      grid-template-columns: 3fr 3fr;
    }
    // @media screen and (max-width: 700px) {
    //   grid-template-columns: 2fr 1fr 3fr;
    // }

    // box-shadow: 0 20px 20px -10px #ffffff;
    z-index: 2;
    .infos-end {
      width: 100%;
      @media (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    .preview {
      width: 42px;
      height: 42px;
      @media screen and (max-width: 700px) {
        width: 32px;
        height: 32px;
      }
    }

    .KPI {
      font-weight: bold;
      text-align: left;
      font-size: 12px;
      .label {
        font-size: 11px;
        font-weight: normal;
        margin-top: 0;
        color: #4d4f5c;
      }
      .na {
        font-size: 12px;
        color: #adadad;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .infos-left {
      // padding: 10px 10px 10px 30px;
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      overflow: hidden;
      .preview {
        &:hover {
          cursor: pointer;
        }
      }
      .basic-infos-game {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 100%;
        padding: 0px 2px;
        max-width: 100%;
        overflow: hidden;
        .top {
          display: grid;
          grid-template-columns: 1fr;
        }
        @media screen and (max-width: 700px) {
          padding: 5px 2px;
        }

        .name {
          color: #272727;
          font-weight: bold;
          font-size: 12px;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100%;
          padding: 2px 0;
          max-width: 170px;
          padding-right: 10px;
          img {
            margin-left: 10px;
          }
        }
        .createdAt {
          color: #adadad;
          font-size: 10px;
          text-align: left;
          // padding: 5px;
        }
        .sub-infos {
          display: flex;
          align-items: center;
          @media screen and (max-width: 700px) {
            display: none;
          }
        }
        .vd-svg-auto-tag {
          width: 35px !important;
          margin-right: 2px;
        }
        .vd-svg-manual-tag {
          width: 35px !important;
          margin-right: 2px;
        }
        .vd-svg-android-tag {
          width: 49px !important;
          margin-right: 2px;
        }
        .vd-svg-ios-tag {
          width: 30px !important;
          margin-right: 2px;
        }
      }
    }
    .infos-right {
      // padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // min-width: 390px;
      .action-game {
         width: auto;
      }
      .link {
        padding: 5px 5px;
        @media screen and (max-width: 900px) {
          padding: 5px 2px;
        }
      }
      .action {
        @media screen and (max-width: 900px) {
          padding: 5px 2px;
        }
      }
      > div {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="wrapper" data-test-id="tagInputWrapper">
    <div class="inputTags" :class="{'disabled':disabledInput}" @click="showInput">
      <el-tag
        v-for="tag in dynamicTags"
        :key="tag"
        :closable="canEdit"
        :disable-transitions="true"
        data-test-id="tagInputTagsTagChip"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        v-if="!disabledInput"
        :ref="reference"
        v-model="inputValue"
        :placeholder="cpPlaceholder"
        class="input-new-tag"
        size="small"
        :disabled="disabledInput"
        data-test-id="tagInputTagsInput"
        @keyup.enter.native="handleInputConfirm()"
        @blur="closeSearch"
        @input="searchTags"
        @focus="searchTags(inputValue)"

      >
      </el-input>
    </div>
    <div v-if="searchResult && isSearching && !disabledInput" class="searchResults" :style="searchPosition">
      <div
        v-for="item in searchResult"
        :key="item.id"
        class="searchResult"
        data-test-id="tagInputTagsSearchItem"
        @mousedown="handleInputConfirm(item.label)"
      >
        <div class="searchLabel">{{item.label}}</div>
        <div class="searchNumber">{{item.nb_tagged_games}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputTags',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    result: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    reference: {
      type: String,
      default: 'saveTagInput',
    },
    isSearchResultOnTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dynamicTags: this.value,
      inputVisible: false,
      inputValue: '',
      searchResult: this.result,
      isSearching: false,
    };
  },
  computed: {
    cpPlaceholder() {
      return this.dynamicTags && this.dynamicTags.length > 0 ? null : this.placeholder;
    },
    disabledInput() {
      return this.disabled || !this.canEdit;
    },
    searchPosition() {
      return this.isSearchResultOnTop ? {
        top: 'unset',
        bottom: '50px',
      } : {};
    },
  },
  watch: {
    result(value) {
      this.searchResult = value;
    },
  },
  methods: {
    closeSearch() {
      this.isSearching = false;
    },
    handleClose(tag) {
      if (this.canEdit) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        this.$emit('input', this.dynamicTags);
        if (this.errorCheck(tag)) {
          this.$emit('error');
          return;
        }
        this.$emit('delete', tag);
      }
    },
    showInput() {
      if (this.disabledInput) {
        return;
      }
      this.inputVisible = true;

      if (this.inputValue) {
        this.isSearching = true;
      }

      this.$nextTick(() => {
        this.$refs[this.reference].$refs.input.focus();
      });
    },
    handleInputConfirm(tagLabel) {
      let inputValue = tagLabel || this.inputValue;

      inputValue = inputValue.toLowerCase().trim();

      if (this.dynamicTags.includes(inputValue)) return;

      if (this.errorCheck(inputValue)) {
        this.$emit('error');
        return;
      }
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
      this.searchResult = null;
      this.$emit('input', this.dynamicTags);
      this.$emit('add', inputValue);
    },
    searchTags(tag) {
      if (!tag) {
        return;
      }
      this.isSearching = true;
      this.$emit('search', { tag });
    },
    errorCheck(tag) {
      const isAlphanumericWithSpace = /^[0-9a-zA-Z ]+$/.test(tag);
      const isInCharacterLimit = tag.length >= 3 && tag.length <= 15;
      const isTagNumberLimit = this.dynamicTags.length < 6;

      return !(
        isAlphanumericWithSpace
        && isInCharacterLimit
        && isTagNumberLimit
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.inputTags {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  border: $border-input;
  padding-bottom: 5px;
  padding-left: 5px;
  &.disabled {
    background-color: #f4f7fa !important;
  }
}

.el-tag {
  margin-top: 5px;
  padding: 4px 8px 4px 5px;
  line-height: 0px;
  font-weight: 600;
  border: none;
  height: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-tag + .el-tag {
  margin-left: 5px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  margin-left: 5px;
  flex-grow: 1;
  width: auto;
  max-width: 100px;
  margin-top: 5px;
}

.searchResults {
  position: absolute;
  background-color: $white;
  width: 100%;
  box-shadow: $shadow-depth-1;
  padding: 10px;
  z-index: 10;
  top:80%;
  left:20px;
}

.searchResult {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
  transition: all 300ms;
  margin-bottom: $spacing;


  &:hover {
    background-color: $cloudy-blue;
    cursor: pointer;

    .searchNumber {
      color: $slate;
    }

    .searchLabel {
      color: $intense-blue;
    }

  }
}

.searchLabel {
  font-size: $font-size-small;
}

.searchNumber {
  font-size: $font-size-smaller;
  color: $grey;
}
</style>

<style lang="scss">
.inputTags .el-input__inner {
  border: none;
  height: 24px;
  line-height: 28px;
  padding: 0;
}

.inputTags .el-tag .el-icon-close {
  line-height: 17px;
  top: 0px;
}

</style>

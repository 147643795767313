/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="game-card-pm" @click="gotoGame(game.id)">
    <div :id="`id-${game.id}`" class="name-container">
      <el-popover
        placement="right"
        width="400"
        trigger="hover"
        @after-enter="videoEnter(`video-${game.id}`)"
        @after-leave="videoLeave(`video-${game.id}`)">
        <video
          :id="`video-${game.id}`"
          width="100%"
          controls
          muted="true"
          loop>
          <source :src="game.video" type="video/mp4" />
        </video>
        <GameIcon
          slot="reference"
          :game="game"
          small
          class="game-logo"
          data-test-id="gamesListGameIconHover"></GameIcon>
      </el-popover>
      <div class="name">
        <div class="game">
          <b data-test-id="gamesListGameName">{{ `${game.name}` || "-" }}</b>
        </div>
        <div class="studio" data-test-id="gamesListStudioName"  @click.stop="gotoStudio($event,game.studioId)">
          {{ game.studioName }}
          <div :class="'activity' + ' ' + game.studioActivityState"  data-test-id="gamesListStudioActivity"></div>
        </div>
      </div>
    </div>
    <div class="pm-container container">
      <div class="name">
        Publishing manager:
      </div>
      <div class="name"  data-test-id="gamesListPMName">
        <b> {{ game.pmName }}</b>
      </div>
    </div>
    <div class="iteration-container container">
      <div class="nb">
        Iteration: <b  data-test-id="gamesListGameNumberOfIterations">{{ game.nbIterations }}</b>
      </div>
      <div class="date">
        Last iteration:
        <b  data-test-id="gamesListGameLastIterationDate">{{ game.lastIterationDate | localeDate }}</b>
      </div>
    </div>
    <div class="method-container container">
      <div class="link">
        <i v-if="game.platform === 'ios'" class="vd-svg-ios-tag"  data-test-id="gamesListGameOS"/>
        <i v-if="game.platform === 'android'" class="vd-svg-android-tag"  data-test-id="gamesListGameOS"/>
        <i v-if="game.isManagedByVoodoo" class="vd-svg-auto-tag"  data-test-id="gamesListGameSetup"/>
        <i v-if="!game.isManagedByVoodoo" class="vd-svg-manual-tag"  data-test-id="gamesListGameSetup"/>
      </div>
    </div>
    <div class="link-container container" @click.stop="noClick = true">
      <div class="link">
        <a :href="game.gameAnalyticsUrl" target="_blank"  data-test-id="gamesListGameAnalyticsLink">
          <el-image
            class="preview"
            style="width: 31px;
                        height: 31px;
                        margin: 0px;
                        border-radius: 5px;
                        min-width: 31px;"
            :src="gaImage"
            :fit="'cover'"
          >
            <div slot="error" class="image-slot">
              <span><img :src="gameIcon" :width="40"/></span>
            </div>
          </el-image>
        </a>
      </div>
      <div v-if="game.storeUrl" class="link">
        <el-popover
          placement="bottom"
          width="300"
          trigger="click">
          <div class="container-qrcode">
            <VueQrcode :value="game.storeUrl" :options="{ width: 200 }"></VueQrcode>
            <div style="font-size: 12px;">
              <a class="voodoo-link" target="_blank" :href="game.storeUrl">{{ game.storeUrl }}</a>
            </div>
          </div>
          <div
            slot="reference"
            class="img"
            :style="{
              'background-size': '28px 28px',
              'background-position': '0px center',
              'margin-top': '-2px',
              width: '28px',
              height: '28px',
              'background-image': `url(${qrcodeImage})`
            }"
            data-test-id="gamesListGameStoreUrl"
          ></div>
        </el-popover>
      </div>
      <div v-if="game.projectGameCount > 1" class="link">
        <GameProjectIcon :count="game.projectGameCount" data-test-id="gamesListGameProjectWrapper" @click="filterForGameProject(game.projectId)"/>
      </div>
    </div>
    <div class="link last-link">
      <div class="status-name" :class="game.status">
        <div v-if="game.status" data-test-id="gamesListGameStatus">
          {{ formatStatus(game.status) }}
        </div>
      </div>
      <ProtoScore
        class="proto-score"
        :recommendation="game.recommendation"
        :score-values="formattedProtoScore"
        :display-details="myPermissions.includes('view_proto_score')"
        :is-mini="true"
        data-test-id="gamesListGameProtoscoreWrapper"
      ></ProtoScore>
    </div>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import qrcodeImage from '@/assets/images/qrcode.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

import gaImage from '@/assets/images/pic-5-abf-96-e-6-ca-9715-512-x-99048203@3x.png';
import fbImage from '@/assets/images/icon-facebook.svg';

import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';
import GameProjectIcon from '@/components/GameProjectIcon';
import tools from '@/tools/tools';

export default {
  name: 'GameCardPm',
  components: {
    GameProjectIcon,
    ProtoScore,
    VueQrcode,
    GameIcon,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fbImage,
      isMobile: window.innerWidth < 742,
      noClick: true,
      qrcodeImage,
      gameIcon,
      gaImage,
      // fbImage,
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    formattedProtoScore() {
      if (!this.game.adnetworks) {
        return [];
      }
      return this.game.adnetworks.split('|').map(adNetwork => ({
        label: adNetwork,
        value: this.game[`score${adNetwork}`],
      }));
    },
  },
  methods: {
    videoEnter(value) {
      const video = document.getElementById(value);
      video.play();
    },
    videoLeave(value) {
      const video = document.getElementById(value);
      video.pause();
    },
    formatStatus(status) {
      return status[0].toUpperCase() + status.slice(1).replace(/_/g, ' ');
    },
    gotoGame(id) {
      if (!id) {
        return;
      }

      this.$router
        .push({
          query: {
            game: id,
            studio: undefined,
          },
        })
        .catch(() => {});
    },
    gotoStudio(event, id) {
      if (!id) {
        return;
      }
      tools.openUrl(this.$router, {
        name: 'studio',
        params: { id },
      }, event);
    },
    filterForGameProject(projectId) {
      if (!projectId) {
        return;
      }
      this.$router
        .push({
          name: 'home',
          query: {
            customView: 'projectId',
            customId: projectId,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.last-link  {
  .status-name {
    margin: 0px !important;
    padding-left: 0px !important
  }
}

.container-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.voodoo-link {
  color: $intense-blue;
}
.game-card-pm {
  background-color: var(--game-card-background-color);
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: grid;
  grid-template-columns: 25% 15% 15% 15% 15% 15%; //repeat(auto-fill, minmax(20%, 1fr));
  font-size: 12px;
  &:hover {
    background-color: var(--game-card-background-color-hover);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 44% 33% 23%;
  }
  &:hover {
    cursor: pointer;
  }
  .game-logo {
    margin-right: 10px;
  }
  .name-container {
    display: flex;
    align-items: center;
    text-align: left;

    .preview {
      &:hover {
        // cursor: help;
      }
    }
    .studio {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .activity {
        width: 8px;
        height: 8px;
        border-radius: 8px;

        margin-left: 5px;
        &.active {
          background-color: $activity-state-active;
        }
        &.inactive {
          background-color: $activity-state-inactive;
        }
        &.churned {
          background-color: $activity-state-churned;
        }
      }
      &:hover {
        cursor: pointer;
        color: $blue-label-expand;
      }
    }
    .name {
      display: block;
      text-align: left;
      overflow: auto;
      div {
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 5px;

        &.game {
          color: $intense-blue;
        }
        &.studio {
          font-size: 11px;
        }
      }
    }
  }

  .pm-container,
  .method-container,
  .iteration-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 11px;
    line-height: 13px;
    justify-content: space-around;
    text-align: left;

    img {
      margin-right: 3px;
    }
    &.container {
      display: block;
      text-align: left;
      overflow: auto;
      margin: auto 0;
      div {
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .pm-container,
  .method-container,
  .iteration-container {
    @media screen and (max-width: 700px) {
      display: none !important;
    }
  }
  .link-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 39px);
    @media screen and (max-width: 700px) {
      // grid-template-columns: 18% 21% 19% 40%;
    }

    :hover {
      cursor: auto;
    }
    .img {
      filter: var(--grayscale);
    }
  }
  .link {
    display: flex;
    align-items: center;
    .vd-svg-ios-tag,.vd-svg-android-tag,.vd-svg-auto-tag,.vd-svg-manual-tag {
      margin-right: 5px;
    }
    i.vd-svg-ios-tag {
      width: 46px !important;
      height: 22px !important;
    }
    i.vd-svg-android-tag {
      width: 70px !important;
      height: 22px !important;
    }
    i.vd-svg-auto-tag {
      width: 52px !important;
      height: 22px !important;
    }
    i.vd-svg-manual-tag {
      width: 63px !important;
      height: 22px !important;
    }


    :hover {
      cursor: pointer;
    }

    .img,
    img {
      border-radius: 20px;
      filter: var(--filter-image);

      // width: 28px;
      // height: 28px;
    }
    .status-name {
      margin-left: 5px;
      display: block;
      text-align: left;
      overflow: auto;
      margin: auto 0;
      margin-left: 5px;
      padding: 3px 10px;
      border-radius: 15px;
      font-size: 11px;
      margin: auto;
      text-align: center;
      &.in_testing {
        padding-left: 10px !important;
        background-color: #58c783;
        color: var(--main-font-basic-color-revert);
      }
      & .finished {
        padding-left: 10px !important;
        background-color: #232323;
        color: var(--main-font-basic-color-revert);
      }
      &.pending_review,
      &.pending_sdk_integration,
      & .pending_testing {
        padding-left: 10px !important;
        background-color: #ff8d00;
        color: var(--main-font-basic-color-revert);
      }
      div {
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .proto-score {
      margin-left: 9px;
    }
  }
}
//
</style>

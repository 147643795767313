/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import QuotesService from '@/services/quotes.services';

const Quotes = {
  namespaced: true,
  state: {
    quotesList: [],
    loading: false,
  },
  getters: {
    quotesList(state) {
      return state.quotesList;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    add_quotes_list(state, listItem) {
      const list = state.quotesList.concat(listItem);
      state.quotesList = cloneDeep(list);
    },
    set_pref_loading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async fetchList(context) {
      return new Promise(async (resolve) => {
        const list = await QuotesService.fetchQuotes();
        if (list.length > 0) {
          context.commit('add_quotes_list', list);
        }
        resolve(list);
      });
    },
  },
};

export default Quotes;

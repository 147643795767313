<template>
  <div class="container-maintenance">

    <div class="header">
      <img class="logo" src="@/assets/images/voodoo-black.svg" />
      <span class="sub-logo">
        PUBLISHING PLATFORM
      </span>
    </div>

    <img src="@/assets/images/illustration-maintenance@3x.png" />

    <h1>Oops! We have a problem</h1>
    <p>Our technical team is working (hard!) to solve the issues, <br>
      please retry in a few minutes...</p>

    <a
      class="el-button el-button--primary"
      type="button"
      href="/"
    >
      Retry
    </a>

    <span>Or visit our website <a href="https://www.voodoo.io">www.voodoo.io</a></span>
  </div>
</template>

<script>

export default {
  name: 'Maintenance',
};
</script>

<style lang="scss" scoped>
  .container-maintenance {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: auto;
    color: #82bdf7;
    font-weight: bold;

    .header {
      .logo {
        height: 30px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .sub-logo {
        color: #82bdf7;
        font-weight: bold;
        font-size: 0.8em;
      }
      margin-bottom: 70px;
    }

    img {
      width: 100%;
      max-width: 500px;
      display: block;
      margin: auto auto 60px;
    }

    h1 {
      font-size: 2.3em;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 40px;
      line-height: 35px;
    }

    .el-button {
      display: block;
      margin: auto;
      width: min-content;
      margin-bottom: 20px;
      padding: 15px 30px;
      text-decoration: none;
    }

    span {
      font-size: 0.9em;
      color: #adadad;

      a {
        text-decoration: none;
        color: #067bf0;
      }
    }
  }
</style>

const MAIN_BREAKPOINTS = {
  xxs: 400,
  isMobile: 700,
  xs: 768,
  sm: 992,
  md: 1200,
  lg: 1400,
  xl: Infinity,
};

const VIDEOLIST_BREAKPOINTS = {
  videoList960: 960,
  videoList1160: 1160,
};

export const BREAKPOINTS = {
  ...MAIN_BREAKPOINTS,
  ...VIDEOLIST_BREAKPOINTS,
};

// import Vue from 'vue';
// import { Button } from 'element-ui';
// import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default class Tools {
  static async getVideoDimensionsOf(fileURL) {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.addEventListener(
        'loadedmetadata',
        () => {
          const height = video.videoHeight;
          const width = video.videoWidth;
          let orientation;
          if (width === height) {
            orientation = 'square';
          } else {
            orientation = width < height ? 'vertical' : 'landscape';
          }
          const ratio = width / height;
          if (ratio > 0.7 && ratio < 0.9) {
            orientation = '4:5';
          }

          resolve({
            height,
            width,
            orientation,
          });
        },
        false,
      );
      video.src = fileURL;
    });
  }

  static findPos(obj, ks = false) {
    const w = window.innerWidth;
    let curtop = -130;

    if (ks) {
      curtop = -90;
    }
    if (w < 700 && !ks) {
      curtop = -180;
    }
    if (w < 700 && ks) {
      curtop = -150;
    }

    if (obj.offsetParent) {
      // eslint-disable-next-line no-cond-assign
      do {
        curtop += obj.offsetTop;
        // eslint-disable-next-line no-param-reassign
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
    return 0;
  }

  static calcTablesHeight() {
    let marginToReduce;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    if (windowWidth > 742) {
      marginToReduce = 180;
    } else {
      marginToReduce = 180;
    }
    return windowHeight - marginToReduce;
  }

  static getWhichFilterGameIs(game) {
    if (!game) {
      return '';
    }
    if (!game.isIterating && game.status === 'pending_sdk_integration') {
      return 'in_progress';
    }
    if (game.status === 'pending_testing') {
      return 'pending_approval';
    }
    if (game.status === 'in_testing') {
      return 'in_testing';
    }
    if (game.status === 'finished') {
      return 'finished';
    }
    if (game.status === 'archived') {
      return 'archived';
    }
    if (game.status === 'pending_sdk_integration' && game.isIterating) {
      return 'is_iterating';
    }
    return 'in_progress';
  }

  static isfacebookCampaign(game) {
    try {
      return game.adNetworks.facebook;
    } catch (error) {
      return false;
    }
  }

  static isSnapChatCampaign(game) {
    try {
      return game.adNetworks.snapchat;
    } catch (error) {
      return false;
    }
  }

  static getAllStepArrayByGame(game, isDisplayedOnStudioSide = false) {
    const stepArray = [];
    if (isDisplayedOnStudioSide) {
      stepArray.push({
        component: 'GameDetailsNew',
      });
      return stepArray;
    }

    const isFacebook = this.isfacebookCampaign(game);

    if (this.getWhichFilterGameIs(game) === 'in_progress') {
      stepArray.push({
        title: 'Create game',
        id: 'createGame',
        time: 'Less than 1 min',
        component: 'GameConfigStep',
      });

      if (!game.isCtr) {
        if (!game.isPublishedInStore && game.platform === 'ios') {
          stepArray.push({
            title: 'AppStore publishing',
            id: 'playstore_submission',
            time: '10 - 15 min',
            component: 'AppStoreSubmissionStepOnPage',
          });
        }

        stepArray.push({
          title: 'Link to store',
          id: 'link_store',
          time: '1 - 5 min',
          component: 'LinkStoreStepOnPage',
        });

        if (isFacebook) {
          stepArray.push({
            title: 'Facebook setup',
            id: 'sdk_facebook',
            time: '15 - 20 min',
            component: 'FacebookStepOnPage',
          });
        }

        stepArray.push({
          title: 'TinySauce integration',
          id: 'tinysauce_package',
          time: '5 - 10 min',
          component: 'TinySauceStepOnPage',
        });

        stepArray.push({
          title: 'Game building & Test',
          id: 'game_building',
          time: '5 - 10 min',
          component: 'IntegrationStepOnPage',
        });

        if (!game.isPublishedInStore && game.platform === 'android') {
          stepArray.push({
            title: 'Playstore publishing',
            id: 'playstore_submission',
            time: '10 - 15 min',
            component: 'PlaystoreSubmissionStep',
          });
        }
        if (game.platform === 'ios' || isFacebook) {
          stepArray.push({
            title: 'Finalize app config',
            id: 'request_testing',
            time: '10 min',
            component: 'FinalizeAppSetup',
          });
        }
      }

      const timeForCreateCampaign = game.isManagedByVoodoo ? '5 - 10 min' : '1 min';
      stepArray.push({
        title: 'Create campaign',
        id: 'upload_asset',
        time: timeForCreateCampaign,
        component: 'UploadCampaignAssetsStep',
      });
    }

    if (this.getWhichFilterGameIs(game) === 'is_iterating') {
      stepArray.push({
        title: 'Create campaign',
        id: 'upload_asset',
        time: '5 - 10 min',
        component: 'UploadCampaignAssetsStep',
      });
    }

    if (this.getWhichFilterGameIs(game) === 'pending_approval') {
      stepArray.push({
        component: 'GamePendingApproval',
      });
    }

    if (['in_testing', 'finished', 'archived'].includes(this.getWhichFilterGameIs(game))) {
      stepArray.push({
        component: 'GameDetailsNew',
      });
    }

    return stepArray;
  }

  static getCurrentStep(game, isDisplayedOnStudioSide = false) {
    const array = this.getAllStepArrayByGame(game, isDisplayedOnStudioSide);

    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element.id === game.substatus) {
        return element;
      }
    }
    return array[0];
  }

  static getLastStep(game) {
    const array = this.getAllStepArrayByGame(game);

    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element.id === game.substatus) {
        if (i === 0) {
          return null;
        }
        return array[i - 1];
      }
    }
    return null;
  }

  static getNextStep(game) {
    const array = this.getAllStepArrayByGame(game);
    if (game.substatus === null) {
      return array.length > 1 ? array[1] : null;
    }
    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element.id === game.substatus) {
        if (i === array.length) {
          return null;
        }
        return array[i + 1];
      }
    }
    return array.length > 1 ? array[1] : null;
  }

  static getNextStepId(game) {
    return this.getNextStep(game) ? this.getNextStep(game).id : null;
  }

  static getPreviousStepId(game) {
    return this.getLastStep(game) ? this.getLastStep(game).id : null;
  }

  static isGivenStepBeforeCurrentStep(game, givenStep) {
    const currentStep = this.getCurrentStep(game);
    const currentStepPosition = this.getAllStepArrayByGame(game).findIndex(step => step.id === currentStep.id);
    const givenStepPosition = this.getAllStepArrayByGame(game).findIndex(step => step.id === givenStep.id);

    return givenStepPosition < currentStepPosition;
  }

  static async videoFormatIsValid(videoUrl) {
    if (!videoUrl) {
      return false;
    }
    const videoToCheck = document.createElement('video');
    videoToCheck.src = videoUrl;
    videoToCheck.muted = true;
    videoToCheck.metadata = true;

    const load = videoToCheck.play();
    let errorvideo = false;

    const isValid = await load
      .catch(() => {
        errorvideo = true;
        return false;
      })
      .then(() => {
        videoToCheck.pause();
        if (errorvideo) {
          return false;
        }
        const height = videoToCheck.videoHeight;
        const width = videoToCheck.videoWidth;
        const duration = videoToCheck.duration;
        videoToCheck.removeAttribute('src'); // empty source
        videoToCheck.load();
        if (height && width && duration) {
          return true;
        }
        return false;
      });
    return isValid;
  }

  static decodeError(error) {
    const normalizedErrorList = [];
    let deserializedError = error;
    try {
      deserializedError = JSON.parse(deserializedError);
    } catch (e) {
      // do nothing, content is already desezialized
    }
    if (Array.isArray(deserializedError)) {
      deserializedError.forEach((element) => {
        let deserializedElement = element;
        try {
          deserializedElement = JSON.parse(deserializedElement);
        } catch (e) {
          // do nothing, content is already desezialized
        }
        normalizedErrorList.push(deserializedElement);
      });
    } else {
      normalizedErrorList.push(deserializedError);
    }

    return normalizedErrorList;
  }

  static async redirectUser(router, myPermission, userService, currentRoute = false) {
    const academyPermissions = ['learn_upon_admin', 'learn_upon_instructor', 'learn_upon_learner', 'learn_upon_manager'];
    const hasPermission = academyPermissions.some(permission => myPermission.includes(permission));
    if (myPermission.length === 0) {
      if (currentRoute?.query?.redirect) {
        router.push({
          name: 'login',
          query: {
            redirect: currentRoute?.query?.redirect,
          },
        });
      } else {
        router.push({
          name: 'root',
          params: {
            redirect: true,
          },
        });
      }
      return;
    }
    if (currentRoute?.query?.redirect === 'learnupon' && hasPermission) {
      const linkResponse = await userService.getRedirectLink('learnupon');
      if (linkResponse.redirectUrl) {
        window.location.href = linkResponse.redirectUrl;
      } else {
        window.location.href = 'https://academy.voodoo.io/dashboard';
      }
    }
    if (myPermission.includes('view_pm_interface')) {
      if (currentRoute?.params?.redirect) {
        router.go(-1);
      } else if (currentRoute.name !== 'home') {
        router.push({
          name: 'home',
        });
      }
    } else if (myPermission.includes('view_studio_interface')) {
      if (currentRoute?.params?.redirect) {
        router.go(-1);
      } else if (currentRoute.name !== 'studioHome') {
        router.push({
          name: 'studioHome',
        });
      }
    } else if (hasPermission) {
      const linkResponse = await userService.getRedirectLink('learnupon');
      if (linkResponse.redirectUrl) {
        window.location.href = linkResponse.redirectUrl;
      } else {
        window.location.href = 'https://academy.voodoo.io/dashboard';
      }
    } else {
      window.location.href = 'https://voodoo.zendesk.com/hc/en-us/categories/360001432800-HELP-CENTER';
    }
  }

  static attachEvent(element, event, callbackFunction) {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent(`on${event}`, callbackFunction);
    }
  }

  static visibleY(documentElement) {
    if (!documentElement) return {};
    let rect = documentElement.getBoundingClientRect(); const top = rect.top; const height = rect.height;
    let item = documentElement.parentNode;
    do {
      rect = item.getBoundingClientRect();
      if ((top <= rect.bottom) === false) return false;
      if ((top + height) <= rect.top) return false;
      item = item.parentNode;
    } while (item !== document.body);
    // Check its within the document viewport
    return top <= document.documentElement.clientHeight / 5;
  }

  static openUrl(router, params, eventClick, target) {
    if (eventClick) {
      eventClick.preventDefault();
    }
    if (eventClick.metaKey || target === '_blank') {
      const routeData = router.resolve(params);
      window.open(routeData.href, '_blank');
    } else {
      router
        .push(
          params,
        ).catch(() => {});
    }
  }
}

<template>
  <div ref="videos-table-list" class="videos-table-list">
    <div v-if="!isMobile" class="header-videos-list">

      <div :class="$style.titleSection">
        <div
          class="title"
          data-test-id="gameInfosVideosTableTitle"
        >
          {{section}} ad videos ({{videosSorted.length}})
        </div>
        <div :class="$style.toggle" @click="isCollapsed = !isCollapsed">
          <font-awesome-icon icon="eye-slash" :class="$style.hideIcon"/> <span
            data-test-id="gameInfosVideosTableToggle"
          >{{toggleText}} videos</span>
        </div>
      </div>
      <div class="buttons">
        <!-- <div class="export-videos" @click="exportCSV">
          <font-awesome-icon icon="download" class="icon"/>
          Export CSV
        </div> -->
        <!-- To be displayed when the feature is available -->
        <div v-if="false" class="approved-videos">
          Show only approved videos
          <el-switch
            v-model="showApprovedVideos">
          </el-switch>
        </div>
      </div>
    </div>

    <Collapsor v-model="isCollapsed">
      <div v-if="!isMobile" class="videos-table">
        <el-table
          class="el-table-videos"
          :data="videosSorted"
          fit
          :span-method="objectSpanMethod"
          @sort-change="sort">

          <el-table-column
            prop="date"
            label="Performance"
          >
            <template slot-scope="scope">
              <div class="cell-video">
                <VideoCard :video="scope.row" :is-managed-by-voodoo="game.isManagedByVoodoo"></VideoCard>
              </div>

            </template>
          </el-table-column>

          <el-table-column
            prop="spend"
            label="Spend"
            sortable
            :width="columnWidth">
            <template
              slot-scope="scope"
            >
              <div
                v-if="scope.row.data && scope.row.data.spend !== null && !isNaN(scope.row.data.spend)"
                class="kpi"
                data-test-id="gameInfosVideosTableSpend"

              >
                ${{scope.row.data.spend.toFixed(0).toLocaleString('en-US')}}
              </div>
              <div
                v-else
                class="kpi na"
                data-test-id="gameInfosVideosTableSpend"
              >
                -
              </div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            :width="columnWidth"
            prop="impressions"
            label="Impressions">
            <template slot-scope="scope">
              <div
                v-if="scope.row.data && scope.row.data.impressions !== null && !isNaN(scope.row.data.impressions)"
                class="kpi"
                data-test-id="gameInfosVideosTableImpressions"
              >
                {{scope.row.data.impressions.toLocaleString('en-US')}}
              </div>
              <div
                v-else
                class="kpi na"
                data-test-id="gameInfosVideosTableImpressions"
              >
                -
              </div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            :width="columnWidth"
            prop="linkClicks"
            :formatter="formatter"
            label="Clicks">
            <template slot-scope="scope">
              <div
                v-if="scope.row.data && scope.row.data.linkClicks !== null && !isNaN(scope.row.data.linkClicks)"
                class="kpi"
                data-test-id="gameInfosVideosTableClicks"
              >
                {{scope.row.data.linkClicks.toLocaleString('en-US')}}
              </div>
              <div
                v-else
                class="kpi na"
                data-test-id="gameInfosVideosTableClicks"
              >
                -
              </div>
            </template>
          </el-table-column>

          <el-table-column
            sortable
            :width="columnWidth"
            prop="fbInstalls"
            label="Installs">
            <template slot-scope="scope">
              <div
                v-if="scope.row.data && scope.row.data.fbInstalls !== null && !isNaN(scope.row.data.fbInstalls)"
                class="kpi"
                data-test-id="gameInfosVideosTableInstalls"
              >
                {{scope.row.data.fbInstalls.toLocaleString()}}
              </div>
              <div
                v-else
                class="kpi na"
                data-test-id="gameInfosVideosTableInstalls"
              >
                -
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="cpi"
            sortable
            :width="columnWidth"
            label="CPI">
            <template slot-scope="scope">
              <div
                v-if="scope.row.data && scope.row.data.cpi !== null && !isNaN(scope.row.data.cpi)"
                class="kpi"
                data-test-id="gameInfosVideosTableCPI"
              >
                ${{scope.row.data.cpi.toFixed(2).toLocaleString('en-US')}}
              </div>
              <div
                v-else
                class="kpi na"
                data-test-id="gameInfosVideosTableCPI"
              >
                -
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </Collapsor>

    <div v-if="isMobile" class="mobile-table">
      <VideoListMobile :video-list="videosSorted"></VideoListMobile>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import videoplaceholder from '@/assets/images/video-placeholder.svg';
import Collapsor from '@/components/Collapsor';
import VideoCard from './VideoCard/VideoCard.vue';
import VideoListMobile from './VideoListMobile/VideoListMobile.vue';

export default {
  name: 'VideosTableList',
  components: {
    VideoCard,
    VideoListMobile,
    Collapsor,
  },
  props: {
    videosList: {
      type: Array,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Object,
      default: null,
    },
    section: {
      type: String,
      default: 'global',
    },
  },
  data() {
    return {
      videoplaceholder,
      gamePmApi: {},
      showApprovedVideos: false,
      sortedList: this.videosList,
      isCollapsed: false,
      // isMobile: window.innerWidth < 960,
    };
  },
  computed: {
    videosSorted() {
      const videos = this.section === 'global' ? this.sortedList : this.sortedList.filter(video => video.adNetwork === this.section);
      videos.forEach((video, index) => {
        videos[index].perf = index + 1;
      });
      return videos;
    },
    columnWidth() {
      return this.$bp('videoList960-') ? 100 : 120;
    },
    toggleText() {
      return this.isCollapsed ? 'Show' : 'Hide';
    },
  },
  watch: {
    videosList(value, old) {
      if (value !== old) {
        this.sortedList = value;
        this.sortByProperty('spend', 'asc');
        this.orderedVideoBySpend();
      }
    },
  },
  mounted() {
    this.sortByProperty('spend', 'asc');
    this.orderedVideoBySpend();

    // window.onresize = () => {
    //   this.checkIfUnder960();
    // };
  },

  methods: {
    // eslint-disable-next-line func-names
    // checkIfUnder960: throttle(function () {
    //   this.isMobile = window.innerWidth < 960;
    // }, 300),
    // eslint-disable-next-line consistent-return
    objectSpanMethod({
      // eslint-disable-next-line no-unused-vars
      row, column, rowIndex, columnIndex,
    }) {
      if (row.adultContent) {
        return {
          rowspan: 1,
          colspan: 6,
        };
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    exportCSV() {
      console.log('call CSV');
    },
    formatter(row, column) {
      return row[column];
    },
    sort(col) {
      this.sortByProperty(col.prop);
    },
    toFloat(num) {
      return parseFloat(num.replace('.', '').replace(',', '.'));
    },
    compare(a, b, property, order) {
      if (!a.data || !b.data) {
        return 0;
      }
      const cpA = a.data[property];
      const cpB = b.data[property];
      if (cpA === b) {
        return 0;
      }
      // nulls sort after anything else
      if (typeof cpA === 'undefined') {
        return order === 'ascending' ? 1 : -1;
      }
      if (typeof cpB === 'undefined') {
        return order !== 'descending' ? -1 : 1;
      }

      if (a.data[property] < b.data[property]) {
        return order !== 'descending' ? -1 : 1;
      }
      if (a.data[property] > b.data[property]) {
        return order !== 'descending' ? 1 : -1;
      }
      return 0;
    },
    sortByProperty(property, order) {
      this.sortedList.sort((a, b) => this.compare(a, b, property, order));
    },
    orderedVideoBySpend() {
      this.sortedList = orderBy(this.sortedList, [o => (o.data ? o.data.spend : '' || '')], ['desc']);
      for (let i = 0; i < this.sortedList.length; i += 1) {
        const element = this.sortedList[i];
        element.perf = i + 1;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
::v-deep .el-table  {
  th.el-table__cell > .cell {

    font-size: 12px;
    font-weight: 600;
    color: $slate;
  }
  .kpi {

    font-size: 18px;
    font-weight: 600;
    color: $slate;
    &.na {
        color: $grey;
    }
  }
  thead tr {
    .el-table__cell {
      padding: 0 !important;
      background-color: $foggy-blue !important;
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:nth-last-child(1) {
        border-top-right-radius: 8px;
      }
    }
  }
}
.videos-table {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.videos-table-list{
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  .header-videos-list {
    display: flex;
    align-items: center;
    margin-bottom: $spacing * 3;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: 600;
    }
    .title:first-letter{
      text-transform: capitalize;
    }
    .export-videos {

      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $light-blue;
      &:hover {
        cursor: pointer;
      }
    }
    .approved-videos {

      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: $slate;
      padding-left: 32px;
    }
  }
  .buttons {
    display: flex;
  }
}
</style>

<style lang="scss" module>
.titleSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.toggle {
  color: $light-blue;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.hideIcon {
  margin-right: $spacing;
  font-size: 16px;
}
</style>

<template>
  <div>
    <el-input
      id="facebookAppId"
      v-model="facebookAppId"
      :disabled="disabled"
      size="small"
      type="number"
      data-test-id="gameSubmissionFacebookIdInput"
      @blur="updateFacebookAppId"
    ></el-input>
    <p
      v-if="!isValidFacebookAppId && !formIsFocus && update"
      :class="$style.error"
      data-test-id="gameSubmissionFacebookAppIdError"
    >
      Valid Facebook App Ids are between 15 and 17 digits long
    </p>
    <el-button
      id="facebook-appaccess"
      type="primary"
      size="medium"
      :disabled="!isValidFacebookAppId || disabled || formIsFocus"
      :loading="loadingRequestAccess"
      :class="$style.requestButton"
      data-test-id="gameSubmissionFacebookIdRequestAccess"
      @click="requestAppAccess()"
    >
      Request app access
    </el-button>
  </div>
</template>

<script>
import { Message } from 'element-ui';

export default {
  name: 'FacebookAppAccess',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formIsFocus: false,
      facebookAppId: '',
      updatingPromise: new Promise(resolve => resolve()),
      loadingRequestAccess: false,
      update: false,
    };
  },
  computed: {
    isValidFacebookAppId() {
      return this.facebookAppId && [15, 16, 17].includes(this.facebookAppId.length);
    },
  },
  watch: {
    // game: {
    //   handler(value) {
    //     if (value) {
    //       this.facebookAppId = value.facebookAppId;
    //       this.$emit('fb-id', value.facebookAppId);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // facebookAppId(value) {
    //   this.$emit('fb-id', value);
    // },
    isValidFacebookAppId(value) {
      this.$emit('isValid', value);
    },
  },
  mounted() {
    this.facebookAppId = this.game ? this.game.facebookAppId : null;
    this.$emit('fb-id', this.facebookAppId);
  },
  methods: {
    updateFormIsFocus() {
      this.formIsFocus = true;
      this.$emit('formIsFocus', true);
    },
    updateFacebookAppId() {
      this.formIsFocus = false;
      this.$emit('formIsFocus', false);
      this.update = true;

      if (
        this.game.adNetworks.facebook
        && this.facebookAppId !== this.game.adNetworks.facebook.appId
      ) {
        try {
          this.updatingPromise = this.$store.dispatch('ActionToDispatch/updateGameProperty', {
            id: this.game.id,
            adNetworks: { facebook: { appId: this.facebookAppId } },
            loading: 'property',
          });

          this.$store.dispatch('ActionToDispatch/refreshGame', this.game.id);
        } catch (e) {
          Notification.error({
            title: 'Error',
            message: 'An error occured',
            position: 'bottom-left',
          });
        }
      }
    },
    async requestAppAccess() {
      // We might want to remove this promise and better schedule the user actions, as this is the only place where we react on the blur event
      this.updatingPromise.then(async () => {
        this.loadingRequestAccess = true;
        const response = await this.$store.dispatch(
          'gameAction/requestAppAccess',
          {
            game: this.game,
            facebookAppId: this.facebookAppId,
          },
        );
        if (!response.error) {
          this.$emit('fb-id', this.facebookAppId);
          Message({
            message:
              '<strong>Action <i style="color:blue;">Request app access</i></strong> done!',
            type: 'success',
            dangerouslyUseHTMLString: true,
            duration: 5000,
            showClose: true,
          });
        } else {
          Message({
            message:
              '<strong>Action <i style="color:blue;">Request app access</i></strong> failed!',
            type: 'error',
            dangerouslyUseHTMLString: true,
            duration: 5000,
            showClose: true,
          });
        }
        this.loadingRequestAccess = false;
      });
    },
  },
};
</script>

<style lang="scss" module>
.requestButton {
  margin-top: $spacing * 3;
}

.error {
  font-size: 12px;
  color: $blood-normal;
}
</style>

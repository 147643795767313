<template>
  <div class="container-password-reset-sent">
    <font-awesome-icon icon="envelope" class="icon" />

    <h2>Email sent</h2>
    <p>We have just sent you an email to reset your password</p>
    <p>
      If you don't receive the email soon, <br>
      please check that the email address you entered is correct or check your spam.
    </p>
    <router-link :to="{name: 'login'}" class="back-link">Back</router-link>
  </div>
</template>

<script>

export default {
  name: 'PasswordResetSent',
};
</script>

<style lang="scss" scoped>
.container-password-reset-sent {
  h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
    font-weight: 600;
  }
  .icon {
    color: $intense-blue;
    width: 52px;
    height: 29px;
    margin: 40px 0;
  }
  .back-link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: $intense-blue;
  }
}
</style>

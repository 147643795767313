<template>
  <div :class="$style.wrapper">
    <ul v-if="!disabled" :class="$style.header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        :class="[
          $style.tab,
          index === selectedIndex ? $style.selected : $style.notSelected,
        ]"
        data-test-id="tabComponent"
        @click="selectTab(index)"
      >
        <div :class="$style.tabTitle">{{ tab.title }}</div>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [], // all of the tabs
    };
  },
  watch: {
    disabled(value) {
      if (value) {
        this.selectTab(0);
      }
    },
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;

      this.tabs.forEach((tab, index) => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = index === i;
      });
    },
  },
};
</script>

<style lang="scss" module>
.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: $foggy-blue;
  border-bottom: solid 1px $foggy-grey;
}

.tab {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab:nth-of-type(1) {
  border-right: solid 1px $foggy-grey;
  padding: 0 $spacing;
}
.tabTitle {
  padding: $spacing * 3 0;
  transition: all 300ms;
}

.selected {
  color: $soft-black;
  background-color: $white;

  .tabTitle {
    border-bottom: solid 4px $intense-blue;
  }
}

.notSelected {
  color: $slate;
  cursor: pointer;
}
</style>

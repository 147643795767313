<template>
  <div :class="$style.wrapper">
    <div :class="$style.totalText">Total</div>
    <div :class="$style.values">
      <div
        v-for="total in totals"
        :key="total.title"
        :class="$style.cell"
        data-test-id="gameInfosVideosTotal"
      >
        <div
          :class="$style.value"
          data-test-id="gameInfosVideosTotalValue"
        >
          <template
            v-if="isValueAvailable(total.value)"
          >
            <span v-if="total.unitBefore">{{ total.unit }}</span>
            {{ total.value.toLocaleString() }}
            <span v-if="!total.unitBefore">{{ total.unit }}</span>
          </template>
          <template v-else><span :class="$style.empty">-</span></template>
        </div>
        <el-tooltip v-if="total.tooltip" placement="top">
          <div
            slot="content"
            data-test-id="gameInfosVideosTotalTooltip"
          >{{ total.tooltip }}</div>
          <i class="icon vd-svg-kpismallicon" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameStatsTotal',
  props: {
    source: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totals() {
      const totals = [
        {
          title: `${this.section.title} spend`,
          value: parseFloat(this.source.spend).toFixed(0),
          tooltip:
            'Amount spent for this iteration. It will match the budget you chose when configuring the test unless manually stopping it early.',
          unit: '$',
          unitBefore: true,
        },
        {
          title: `${this.section.title} impressions`,
          value: this.source.impressions,
          tooltip:
            'Amount of impressions generated (how many times the ads were promoted to users). Depending on how the algorithm rates the ads, it can range from a few thousand to tens of thousands for the same spend.',
        },
        {
          title: this.isClickOrSwipeUp.action,
          value: this.source.linkClicks,
          tooltip: this.isClickOrSwipeUp.tooltip,
        },
        {
          value: this.source.fbInstalls,
          tooltip:
            'Amount of installs generated by paid users acquired during the test (excluding organic downloads). The higher the better.',
        },
        {
          value: parseFloat(this.source.cpi).toFixed(2),
          tooltip: 'Cost per acquired paid user (excluding organic downloads)',
          unit: '$',
          unitBefore: true,
        },
      ];
      return totals;
    },
    isClickOrSwipeUp() {
      let action = '';
      let tooltip = '';
      if (this.section.title === 'global') {
        action = 'Clicks';
        tooltip = 'Amount of clicks generated during this iteration';
      } else if (this.section.title === 'facebook') {
        action = 'Facebook click';
        tooltip = 'Amount of clicks generated during this iteration.';
      } else if (this.section.title === 'snapchat') {
        action = 'Snapchat swipe-up';
        tooltip = 'Amount of swipe-ups generated during this iteration. Swiping up redirects to the store page. It can be interpreted as clicks.';
      }
      return {
        action,
        tooltip,
      };
    },
  },
  methods: {
    isValueAvailable(value) {
      return value && !Number.isNaN(value) && value !== 'NaN';
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  text-align: left;
  display: flex;
  padding: 16px 20px;
  padding-left: 42px;

    @media (max-width: 760px) {
    padding: 0;
  }
}

.totalText {
  font-size: 12px;
  font-weight: 600;
  color: $slate;
  flex-grow: 1;

  @media (max-width: 760px) {
    display: none;
  }
}

.values {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  width: 600px;

  @media (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cell {
  display: flex;
  align-items: center;
  @media (max-width: 760px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing * 2 0;
    border-right: 1px solid $foggy-grey;
    border-bottom: 1px solid $foggy-grey;
  }
}

.cell:nth-of-type(2n+1) {
    @media (max-width: 760px) {
    border-left: 1px solid $foggy-grey;
    border-top: 1px solid $foggy-grey;
  }
}
.cell:nth-of-type(2) {
    @media (max-width: 760px) {
    border-top: 1px solid $foggy-grey;
  }
}

.value {
  padding-right: $spacing;
  font-size: 18px;
  font-weight: 600;
  color: $slate;
}

.empty {
  color: $grey;
}
</style>

<template>
  <div id="hijack">
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
  },
  mounted() {
    if (this.userId && this.me.id !== this.userId) {
      this.$store.dispatch('users/hijackUserId', this.userId);
    } else {
      this.$router.push({
        name: 'root',
      });
    }
  },
};
</script>

<style lang="scss"></style>

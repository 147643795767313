<template>
  <el-popover
    v-model="popFiltersVisible"
    popper-class="pop-filters"
    placement="bottom"
    width="300"
    trigger="click">
    <el-button
      slot="reference"
      size="mini">
      Filter by KPIs <i class="el-icon-arrow-down"></i>
    </el-button>
    <ul class="list-filter">
      <li v-if="type === 'ctr'" class="list-filter-item">
        <span class="label">CTR is between</span>
        <el-slider
          v-model="ctrValue"
          :min="0"
          :max="15"
          :step="1"
          input-size="mini"
          range
          :marks="marksCTR"> </el-slider>
      </li>
      <li v-if="type !== 'ctr'" class="list-filter-item">
        <span class="label">CPI is between</span>
        <el-slider
          v-model="cpiValue"
          :format-tooltip="formatTooltipCpi"
          :min="0"
          :max="300"
          :step="1"
          input-size="mini"
          range
          :marks="marksCpi"
        >
        </el-slider>
      </li>
      <!-- <li class="list-filter-item">
              <span class="label">CTR is between</span>
              <el-slider v-model="ctrValue" input-size="mini" range :marks="marksCtr"> </el-slider>
            </li> -->
      <li v-if="type !== 'ctr'" class="list-filter-item">
        <span class="label">D1 is between</span>
        <el-slider
          v-model="D1Value"
          :min="0"
          :max="70"
          input-size="mini"
          range
          :marks="marksD1"> </el-slider>
      </li>
      <li v-if="type !== 'ctr'" class="list-filter-item">
        <span class="label">D7 is between</span>
        <el-slider
          v-model="D7Value"
          :min="0"
          :max="30"
          input-size="mini"
          range
          :marks="marksD7"> </el-slider>
      </li>
      <li class="list-filter-item checkbox">
        <el-checkbox v-model="myFavoriteChecked">Show my favorite</el-checkbox>
      </li>
      <li class="list-filter-item button-submit" @click="setFilters()">
        <span>Apply </span>
      </li>
    </ul>
  </el-popover>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'FilterComponentKS',
  props: {
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      popFiltersVisible: false,
      filtersArray: [],
      sorting: 'createdAt',
      cpiValue: [0, 300],
      ctrValue: [0, 15],
      D1Value: [0, 70],
      D7Value: [0, 30],
      myFavoriteChecked: false,
      marksCtr: {
        0: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'start' },
            },
            '0%',
          ),
        },

        15: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'end' },
            },
            '15%',
          ),
        },
      },
      marksCpi: {
        0: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'start' },
            },
            '$0',
          ),
        },

        300: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'end' },
            },
            '$3.00',
          ),
        },
      },
      marksCTR: {
        0: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'start' },
            },
            '0%',
          ),
        },

        15: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'end' },
            },
            '15%',
          ),
        },
      },
      marksD1: {
        0: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'start' },
            },
            '0%',
          ),
        },

        70: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'end' },
            },
            '70%',
          ),
        },
      },
      marksD7: {
        0: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'start' },
            },
            '0%',
          ),
        },

        30: {
          style: {
            color: '#1989FA',
          },
          label: this.$createElement(
            'strong',
            {
              attrs: { class: 'end' },
            },
            '30%',
          ),
        },
      },
    };
  },
  computed: {
    query() {
      const type = this.type || 'cpi';
      return this.$store.getters['gameAction/queryKs'][type];
    },
  },
  methods: {
    formatTooltip(val) {
      return val / 10;
    },
    formatTooltipCpi(val) {
      return (val / 100).toFixed(2);
    },
    setFilters() {
      this.popFiltersVisible = false;
      const query = cloneDeep(this.query);
      let eleToDelete = null;
      for (let i = 0; i < query.children.length; i += 1) {
        const element = query.children[i];
        if (element.query.children) {
          for (let j = 0; j < element.query.children.length; j += 1) {
            const el = element.query.children[j];
            if (el.query.rule === 'lastCpi' && el.query.operator === '>=') {
              el.query.value = this.cpiValue[0] / 100;
            }

            if (el.query.rule === 'lastCpi' && el.query.operator === '<=') {
              el.query.value = this.cpiValue[1] / 100;
            }
            if (el.query.rule === 'lastCtr' && el.query.operator === '>=') {
              el.query.value = this.ctrValue[0] / 100;
            }
            if (el.query.rule === 'lastCtr' && el.query.operator === '<=') {
              el.query.value = this.ctrValue[1] / 100;
            }
            if (el.query.rule === 'lastD1' && el.query.operator === '>=') {
              el.query.value = this.D1Value[0] / 100;
            }
            if (el.query.rule === 'lastD1' && el.query.operator === '<=') {
              el.query.value = this.D1Value[1] / 100;
            }
            if (el.query.rule === 'lastD7' && el.query.operator === '>=') {
              el.query.value = this.D7Value[0] / 100;
            }
            if (el.query.rule === 'lastD7' && el.query.operator === '<=') {
              el.query.value = this.D7Value[1] / 100;
            }
          }
        } else if (element.query.rule === 'isPinned') {
          eleToDelete = i;
        }
      }
      if (eleToDelete) {
        query.children.splice(eleToDelete, 1);
      }
      if (this.myFavoriteChecked) {
        query.children.push({
          type: 'query-builder-rule',
          query: {
            rule: 'isPinned',
            operand: 'Is favorite',
            value: true,
          },
        });
      }
      this.$store.dispatch('gameAction/set_query_ks', {
        type: this.type || 'cpi',
        query,
      });
    },
  },
};
</script>

<style lang="scss">
.pop-filters {
  padding: 0;
  border: 0px;
  @media screen and (max-width: 700px) {
    width: 100% !important;
    left: 0 !important;
  }
  .list-filter {
    .list-filter-item {
      padding: 10px;
      border-bottom: 1px solid $foggy-grey;
      &.checkbox {
        padding: 20px 10px;
      }
      &.button-submit {
        &:hover {
          cursor: pointer;
        }
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: $intense-blue;
        // margin-top: 10px;
        color: var(--background-color);
      }
      .label {
        font-size: 12px;
        font-weight: 700;
      }
      .el-slider {
        padding: 0 40px 0 30px;
        .el-slider__stop {
          width: 0;
        }
        .el-slider__marks-text {
          font-size: 10px;
          position: absolute;
          top: -19px;
          width: 40px;
        }

        .el-slider__marks-text {
          .start {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: -9px;
            margin-top: -1px;
            width: 38px;
          }
          .end {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div :class="$style.container">
    <div :class="$style.iterationLeft">
      <div :class="$style.iteration">#{{ iterationNb }} iteration</div>
      <div
        v-if="endDate"
        :class="$style.date"
        data-test-id="gameInfosIterationDate"
      >
        From {{ startDate | localeDate }} to {{ endDate | localeDate }}
      </div>
      <div
        v-else
        :class="$style.date"
        data-test-id="gameInfosIterationDate"
      >
        Created on {{ startDate | localeDate }}
      </div>
    </div>
    <div :class="$style.iterationRight">
      <div><slot /></div>
      <div
        v-if="dateIsWithin7DaysAfterFinished"
        :class="[$style.status, $style.specialStatus, $style.finished]"
      >
        <el-tooltip placement="left">
          <div slot="content">
            <!-- eslint-disable-next-line max-len -->
            Your campaign has now ended, and we're gathering all the latest
            stats from the data sources. You can already choose to iterate or
            kill your test. If you don't, we will automatically move your test
            to "Finished" seven days after the campaign's end date.
          </div>
          <div :class="[$style.specialStatusContainer, $style.in7daysContainer]">
            <font-awesome-icon icon="cogs" />
          </div>
        </el-tooltip>
        <div
          data-test-id="gameInfosIterationStatus"
        >Finished</div>
      </div>
      <div
        v-else-if="startDateIsInFuture"
        :class="[$style.status, $style.specialStatus, $style.scheduled]"
      >
        <el-tooltip v-if="schedulingDetails" placement="left">
          <div slot="content">
            {{ schedulingDetails }}
          </div>
          <div :class="[$style.specialStatusContainer, $style.scheduledContainer]">
            <font-awesome-icon icon="clock" />
          </div>
        </el-tooltip>
        <div v-else :class="[$style.specialStatusContainer, $style.scheduledContainer]">
          <font-awesome-icon icon="clock" />
        </div>
        <div
          data-test-id="gameInfosIterationStatus"
        >Scheduled</div>
      </div>
      <div v-else :class="[$style.status, $style[statusToDisplay]]">
        <span
          data-test-id="gameInfosIterationStatus"
          :class="$style.statusText">{{ formattedStatusToDisplay }}</span>
      </div>
      <font-awesome-icon :icon="collapsorIcon" :class="$style.collapsorIcon" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'IterationHeader',
  props: {
    iterationNb: {
      type: Number,
      default: 1,
    },
    startDate: {
      type: [Date],
      default: null,
    },
    endDate: {
      type: [Date],
      default: null,
    },
    score: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
    collapsorIcon: {
      type: String,
      default: 'angle-down',
    },
    schedulingDetails: {
      type: String,
      default: null,
    },
  },
  computed: {
    dateIsInThePast() {
      const date = new Date(this.endDate);
      return !this.endDate ? false : moment(date).isBefore(moment());
    },
    dateIs7OverDaysAfterFinished() {
      const endDate = new Date(this.endDate);
      const datePlus7Days = endDate.setDate(endDate.getDate() + 7);
      const currentDate = new Date();
      return currentDate > datePlus7Days;
    },
    dateIsWithin7DaysAfterFinished() {
      return (
        this.dateIsInThePast
        && !this.dateIs7OverDaysAfterFinished
        && this.status === 'in_testing'
      );
    },
    startDateIsInFuture() {
      const date = new Date(this.startDate);
      return moment(date).isAfter(moment());
    },
    formattedStatusToDisplay() {
      return this.statusToDisplay.replace(/_/g, ' ');
    },
    statusToDisplay() {
      return this.index === 0 ? this.status : 'finished';
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 4;
}

.container:hover .collapsorIcon{
  color: $soft-black;
}

.iterationLeft {
  display: flex;
  align-items: flex-end;
}

.iterationRight {
  display: flex;
  align-items: center;
}

.iteration {
  font-size: 18px;
  font-weight: 600;
  margin-right: $spacing;
}

.status {
  border-radius: 14px;
  padding: $spacing 10px;
  height: 22px;
  margin-left: $spacing * 4;
  font-size: 10px;
  font-weight: 600;
  color: $white;
  display: flex;
  align-items: center;
}

.statusText::first-letter {
  text-transform: capitalize;
}

.specialStatus {
  padding: 0;
  padding-right: 10px;
}

.finished {
  background-color: $grey;
}

.scheduled {
  background-color: $orange-alert;
}

.inTesting {
  background-color: $green-alert;
}

.in_testing {
  background-color: $green-alert;
}
.finished,
.archived {
  background-color: $grey;
}
.pending_review,
.pending_sdk_integration,
.pending_testing {
  background-color: $orange;
}

.date {
  font-size: 12px;
  font-weight: 600;
  color: $grey;
}

.specialStatusContainer {
  font-size: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: solid 2px $white;
  margin-right: $spacing;
}

.in7daysContainer {
  background-color: $green-alert;
}

.scheduledContainer {
  color: $orange-alert;
  font-size: 22px;
  border-width: 13px;
}

.collapsorIcon {
  margin-left: $spacing * 4;
  color: $grey;
  font-size: 20px;
  transition: color 0.3s;
}
</style>

<template>
  <div class="mygames">
    <div class="header-mobile-ks">
      <div class="title">
        <div class="left">
          <h2>Knowledge sharing</h2>
        </div>

        <el-image
          class="preview"
          style="
                        width: 40px;
                        height:18px;
                        margin: 5px;
                        border-radius: 5px;
                        "
          :src="beta"
          :fit="'cover'"
        >
          <div slot="error" class="image-slot">
            <span><img :src="gameIcon" :width="40"/></span>
          </div>
        </el-image>
      </div>
      <div v-if="KnowledgeSharingGames !== null" class="filters-mobile-ks">
        <div class="item-filter">
          <div class="filters-name" :class="{ active: type === 'cpi' }" @click="setSub('ks', 'cpi')">
            CPI tests
          </div>
        </div>
        <br />
        <FilterBarKS :type="type"></FilterBarKS>
        <br />
      </div>
    </div>
    <div class="margin-mobile-ks" :class="{ 'no-games': KnowledgeSharingGames !== null }"></div>
    <div class="header-ks">
      <div class="top">
        <div class="left">
          <span class="title-section">
            Knowledge Sharing
          </span>
          <el-image
            class="preview"
            style="
                        width: 40px;
                        height:18px;
                        margin: 5px;
                        border-radius: 5px;
                        "
            :src="beta"
            :fit="'cover'"
          >
            <div slot="error" class="image-slot">
              <span><img :src="gameIcon" :width="40"/></span>
            </div>
          </el-image>

          <div class="infos">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                <div class="tooltipKS">
                  The Knowledge Sharing Program allows studios to share testing results and learn from others.<br />
                  If you opted-in the Knowledge Sharing Program, your old games (at least three months old) are automatically
                  shared.<br />
                  If you'd like to stop sharing, please go to your
                  <span class="link" @click="gotoStudioAdmin()">Studio Admin page</span> and opt-out.<br />
                </div>
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </div>
        <div v-if="KnowledgeSharingGames !== null" class="right">
          <FilterBarKS :type="type"></FilterBarKS>
        </div>
      </div>
    </div>

    <div class="games-list">
      <GameInKS></GameInKS>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import GameInKS from '@/components/GameInKS.vue';
import FilterBarKS from '@/components/KnowledgeSharing/FilterBarKS.vue';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import illu from '@/assets/images/illustration-knowledge-sharing.svg';
import beta from '@/assets/images/beta-mobile@3x.png';

export default {
  name: 'StudioMyGames',
  components: {
    GameInKS,
    FilterBarKS,
  },
  props: {
    page: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      illu,
      gameIcon,
      beta,
      filters: {
        status: 'in_progress',
      },
    };
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    KnowledgeSharingGames() {
      return cloneDeep(this.$store.getters['gameAction/KnowledgeSharingGames']);
    },
    sortName() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      if (params.knowledgeSharing.sort === 'createdAt') return 'date';
      if (params.knowledgeSharing.sort === 'lastCpi') return 'cpi';
      if (params.knowledgeSharing.sort === 'lastD1') return 'D1';
      if (params.knowledgeSharing.sort === 'lastD7') return 'D7';
      if (params.knowledgeSharing.sort === 'lastCtr') return 'ctr';
      if (params.knowledgeSharing.sort === 'lastCpm') return 'cpm';
      return 'D7';
    },
  },
  watch: {
    filterValue(value) {
      this.filters.status = value;
    },
  },
  mounted() {
    this.filters.status = this.filterValue;
  },
  methods: {
    gotoStudioAdmin() {
      let routeData = '';

      routeData = this.$router.resolve({
        name: 'studioAdmin',
      });
      window.open(routeData.href, '_blank');
    },
    updateUrl(value) {
      this.$router.push({
        name: 'studioHome',
        params: {
          page: 'board',
          sub: 'mygames',
          filter: value,
        },
      });
      this.$store.dispatch(`gameAction/reset_gameList_${this.filterValue}`);
    },
    openMobileView() {
      const query = {
        ...this.$route.query,
        mobile: 'sort-my-games',
        mobileTitle: 'Sort',
        mobileIcon: 'sort',
      };
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    setSub(value, type) {
      if (this.$route.params.sub !== value || this.$route.params.type !== type) {
        this.$router.push({
          name: 'studioHome',
          params: {
            sub: value,
            type: type || 'cpi',
            filter: 'finished',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  justify-content: center;
}
.tooltipKS {
  width: 300px;
  .link {
    color: $intense-blue;
    &:hover {
      cursor: pointer;
    }
  }
}
.placeholder-ks {
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    color: #82bdf7;
    font-size: 20px;
    padding: 20px;
  }
  .description {
    color: #82bdf7;
    padding: 20px;
    line-height: 20px;
  }
}
.mygames {
  width: 100%;
  position: relative;
  display: block;
  .header-ks {
    width: 100%;
    display: flex;
    background-color: $foggy-blue;
    z-index: 5;
    padding: 0 10px 0px 10px;
    flex-direction: column;
    // height: 220px;
    margin-bottom: 0px;
    box-shadow: 0 12px 20px -22px #6c6c6c;
    position: sticky;
    top: 50px;

    .top {
      width: 100%;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #dcdcdc;
      grid-template-columns: auto 1fr;
      height: 60px;

      .el-switch__label * {
        font-size: 12px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title-section {
          font-weight: bold;
          font-size: 18px;
          margin: 10px 0px;
          display: flex;
          align-items: center;
          margin-right: 10px;

          justify-content: flex-start;
        }
        .infos {
          color: #82bdf7;
          font-size: 18px;
        }
        .title-switch {
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
      .right {
        justify-content: flex-end;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .header-mobile-ks {
    display: none;

    @media screen and (max-width: 700px) {
      display: flex;
      position: sticky;
      z-index: 10;
      background-color: $foggy-blue;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-top: -20px;
      left: 0px;
      top: -1px;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      .title {
        color: #272727;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .button-mobile {
        border-radius: 5px;
        border: solid 1px #82bdf7;
        background-color: #f7fbff;
        padding: 10px;
        margin: 10px 20px 10px 20px;
        display: flex;
        justify-content: space-around;
        font-size: 12px;
        &:hover {
          background-color: #82bdf7;
          .el-icon {
            color: #fff;
          }
          span {
            color: #fff;
          }
        }
        .el-icon {
          position: absolute;
          left: 40px;
          color: #82bdf7;
        }
        span {
          margin-left: 10px;
          color: #82bdf7;
        }
      }
      .filters-mobile-ks {
        width: 100%;
        overflow: auto;

        .item-filter {
          display: grid;
          // min-width: 160%;
          overflow: auto;
          white-space: nowrap;
          grid-template-columns: 1fr 1fr;
          position: relative;

          .filters-name {
            padding: 20px 20px;
            word-wrap: none;
            color: #82bdf7;
            // left: 0;
            font-weight: bold;

            position: relative;
            &:hover {
              cursor: pointer;
            }
            &::after {
              content: "";
              width: 0%;
              height: 3px;
              position: absolute;
              bottom: 0px;
              background-color: #067bf0;
              -webkit-transition: all 0.35s ease;
              transition: all 0.35s ease;
              left: 0;
            }
            &.active {
              color: rgb(6, 123, 240);
              font-weight: bold;
              &::after {
                content: "";
                width: 100%;
                height: 3px;
                margin-top: 30px;
                background-color: rgb(6, 123, 240);
              }
            }
            // padding: 0px 5px;
          }
        }
      }
    }
  }
  .margin-mobile-ks {
    display: none;
    &.no-games {
      margin-top: 50px;
    }
    @media screen and (max-width: 700px) {
      // display: flex;
      // margin-top: 135px;
    }
  }
  .spinner {
    font-size: 30px;
    color: grey;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .disabled-game {
    background-color: #6c6c6c;
  }
}
</style>

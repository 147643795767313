<template>
  <div class="bar-simple">
    <el-header class="bar-simple-header">
      <el-row
        type="flex"
        class="row-bg"
        justify="space-between"
        align="middle">
        <div class="container">
          <div class="left">
            <slot name="left"></slot>
          </div>
          <div class="right">
            <slot name="right"></slot>
          </div>
        </div>
      </el-row>
    </el-header>
    <!-- <div class="margin-header"></div> -->
  </div>
</template>

<script>
export default {
  name: 'SimpleBar',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.bar-simple {
  overflow: auto;
  .el-header {
    line-height: 55px;
    padding: 0px 10px;
  }
}
.bar-simple-header {
  color: $font-basic-color;
  // background-color:#f7fbff;
  padding: 0 30px;
  // position: fixed;
  width: 100%;
  z-index: 100;
  .container {
    height: 60px;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(330px, 1fr) minmax(48%, 1fr);
    .right {
      justify-content: flex-end;

    }
  }
}
.view-bar-games, .view-bar {
  i {
    color: #b9bcc8 !important;
    margin-right:5px;
  }
}
.container .right {
  .bar-simple {
    justify-content: flex-end;
  }
}
</style>

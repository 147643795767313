<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="$style.titleContainer">
        <div :class="$style.type">{{ type }}</div>
        <el-tooltip placement="top">
          <div slot="content">{{ tooltipInfo }}</div>
          <i class="icon vd-svg-kpismallicon" />
        </el-tooltip>
      </div>

      <div :class="$style.average">{{ average }}</div>
    </div>
    <highcharts :options="chartOptions"></highcharts>
    <div :class="$style.variation">
      <slot />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { cloneDeep } from 'lodash';
import chartSetup from './chartOptions';

export default {
  name: 'GameStatsCharts',
  components: {
    highcharts: Chart,
  },
  props: {
    type: {
      type: String,
      default: 'type',
    },
    average: {
      type: [Number, String],
      default: 'average',
    },
    data: {
      type: Array,
      default: () => [1, 2, 3],
    },
    categories: {
      type: Array,
      default: () => ['07/08', '08/08', '09/08'],
    },
    color: {
      type: String,
      default: '#9506f0',
    },
    crosshairColor: {
      type: String,
      default: '#F4E5FD',
    },
    tooltipContent: {
      type: String,
      default: '',
    },
    chartType: {
      type: String,
      default: 'linear',
    },
    timeFormat: {
      type: Object,
      default: null,
    },
    tooltipFormatType: {
      type: String,
      default: '',
    },
    tooltipInfo: {
      type: String,
      default: 'Tooltip',
    },
  },
  computed: {
    chartOptions() {
      const chartOptions = cloneDeep(chartSetup);
      chartOptions.series = [
        {
          data: this.data,
          color: this.color,
        },
      ];

      const thisComponent = this;
      // eslint-disable-next-line func-names
      const formatter = function () {
        let format = `${this.x}<br>${this.y}`;
        if (thisComponent.tooltipFormatType === 'cpi') {
          format = `${this.x}<br>CPI: $${this.y.toFixed(2)}`;
        }
        if (thisComponent.tooltipFormatType === 'cpnu') {
          format = `${this.x}<br>CPNU: $${this.y.toFixed(2)}`;
        }
        if (thisComponent.tooltipFormatType === 'd1') {
          format = `${this.x}<br>D1: ${(this.y * 100).toFixed(0)}%`;
        }
        if (thisComponent.tooltipFormatType === 'd3playtime') {
          format = `${
            this.x
          }<br>D3 playtime: ${thisComponent.timeFormatter(this.y)}`;
        }
        return format;
      };
      chartOptions.tooltip.formatter = formatter;

      chartOptions.xAxis.categories = this.categories;
      chartOptions.xAxis.crosshair.color = this.crosshairColor;
      chartOptions.xAxis.type = this.chartType;

      // eslint-disable-next-line func-names
      const labelFormatter = function () {
        let format = `${this.value}`;
        if (thisComponent.tooltipFormatType === 'cpi') {
          format = `$${this.value.toFixed(2)}`;
        }
        if (thisComponent.tooltipFormatType === 'cpnu') {
          format = `$${this.value.toFixed(2)}`;
        }
        if (thisComponent.tooltipFormatType === 'd1') {
          format = `${(this.value * 100).toFixed(0)}%`;
        }
        if (thisComponent.tooltipFormatType === 'd3playtime') {
          format = thisComponent.timeFormatter(this.value);
        }
        return format;
      };
      chartOptions.yAxis.labels.formatter = labelFormatter;

      if (this.timeFormat) {
        chartOptions.yAxis.dateTimeLabelFormats = this.timeFormat;
      }

      return chartOptions;
    },
  },
  methods: {
    timeFormatter(time) {
      let totalSeconds = Math.abs(time);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = (totalSeconds % 60).toFixed(0);

      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      seconds = String(seconds).padStart(2, '0');
      return `${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? `${minutes}m` : ''}${seconds}s`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing * 6;
  align-items: center;
}

.type {
  color: $grey;
  font-size: 14px;
  margin-right: $spacing;
}

.average {
  font-size: 14px;
  font-weight: 600;
}

.variation {
  margin-top: $spacing * 4;
}

.titleContainer {
  font-size: 14px;
  color: $grey;
  margin: 10px 0px;
  display: flex;
}
</style>

<style lang="scss">
.highcharts-credits {
  display: none;
}
</style>

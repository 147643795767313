import { render, staticRenderFns } from "./SortGamesButtons.vue?vue&type=template&id=85b8e094&scoped=true"
import script from "./SortGamesButtons.vue?vue&type=script&lang=js"
export * from "./SortGamesButtons.vue?vue&type=script&lang=js"
import style0 from "./SortGamesButtons.vue?vue&type=style&index=0&id=85b8e094&prod&scoped=true&lang=scss"
import style1 from "./SortGamesButtons.vue?vue&type=style&index=1&id=85b8e094&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "85b8e094",
  null
  
)

export default component.exports
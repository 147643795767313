<template>
  <div class="header-content" data-test-id="myGamesGameItem" @click="$emit('click')">
    <div class="header-compact-game">
      <div class="infos-left">
        <GameIcon :game="item"></GameIcon>
        <div class="basic-infos-game">
          <div class="top">
            <div class="name game-name" data-test-id="myGamesGameName" >{{ name }}</div>
            <el-tooltip placement="right" :visible-arrow="false">
              <div slot="content">
                This game is shared with other studios
                <br />in the Knowledge Sharing space
              </div>
              <el-image
                v-if="item.isInKnowledgeSharing"
                style="width: 30px;
                        height: 15px;
                        "
                :src="logoKs"
                :fit="'contain'"
              >
                <div slot="error" class="image-slot">
                  <span><img :src="gameIcon" :width="40" data-test-id="myGamesGameIcon"/></span>
                </div>
              </el-image>
            </el-tooltip>
          </div>
          <div class="createdAt">
            Creation:
            <span data-test-id="myGamesGameCreationDate">{{ item.createdAt | localeDate }}</span>
          </div>
          <div class="sub-infos">
            <ProtoScore
              :recommendation="item.recommendation || ''"
              :score-values="formattedProtoScore"
              :display-details="myPermissions.includes('view_proto_score')"
              data-test-id="myGamesGameProtoscoreWrapper"
            ></ProtoScore>
          </div>
        </div>
      </div>
      <div v-if="showAdnetworks" class="adnetwork">
        <img v-if="!item.adnetworks" :src="getAdnetworkIcon('Facebook')" />
        <img v-for="adnetworkLabel in splitStringInArray(item.adnetworks)" :key="adnetworkLabel" :src="getAdnetworkIcon(adnetworkLabel)" />
      </div>
      <div v-if="!showAdnetworks"></div>
      <div v-if="type === 'cpi' && showKpiCpi" class="KPI">
        <span v-if="showValue(item.lastCpi)" data-test-id="myGamesGameCPI">${{ item.lastCpi.toFixed(2) }}</span>
        <span v-else class="na" data-test-id="myGamesGameCPI">N/A</span>
        <div v-if="!item.isCtr && item.platform === 'ios' && item.status === 'in_testing'" class="ios14-warning">
          <el-tooltip placement="top" :visible-arrow="true">
            <div slot="content">
              Warning: expect up to 3 days delay
              <br />
              of install data with the release of iOS 14.5
            </div>
            <span> <i class="vd-svg-alert icon-alert"/></span>
          </el-tooltip>
        </div>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi" class="KPI"></div>

      <div v-if="type === 'cpi' && showKpiCpi" class="KPI">
        <span v-if="showValue(item.lastD1)" data-test-id="myGamesGameD1">{{ Math.round((item.lastD1 * 100)) }}%</span>
        <span v-else class="na" data-test-id="myGamesGameD1">N/A</span>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi" class="KPI"></div>

      <div v-if="type === 'cpi' && showKpiCpi" class="KPI">
        <span v-if="showValue(item.lastD3PlaytimeHumanReadable)" data-test-id="myGamesGameD3Playtime">{{ item.lastD3PlaytimeHumanReadable }}</span>
        <span v-else class="na" data-test-id="myGamesGameD3Playtime">N/A</span>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi" class="KPI"></div>

      <div class="infos-right">
        <VideoButton v-if="filter === 'pending_approval'" :game="item"></VideoButton>
        <ActionButton :game="item"></ActionButton>
      </div>
    </div>
    <div class="mobile-kpi">
      <div v-if="showAdnetworks" class="adnetwork-mobile">
        <img v-if="!item.adnetworks" :src="getAdnetworkIcon('Facebook')" />
        <img v-for="adnetworkLabel in splitStringInArray(item.adnetworks)" :key="adnetworkLabel" :src="getAdnetworkIcon(adnetworkLabel)" />
      </div>
      <div v-if="type === 'cpi' && showKpiCpi" class="mKPI">
        <span v-if="showValue(item.lastCpi)" data-test-id="myGamesGameCPI">${{ item.lastCpi.toFixed(2) }}</span>
        <span v-else class="na" data-test-id="myGamesGameCPI">N/A</span>
        <div v-if="!item.isCtr && item.platform === 'ios'" class="ios14-warning">
          <el-tooltip placement="top" :visible-arrow="true">
            <div slot="content">
              Warning: expect up to 3 days delay
              <br />
              of install data with the release of iOS 14.5
            </div>
            <span> <i class="vd-svg-alert icon-alert"/></span>
          </el-tooltip>
        </div>
        <span class="label">CPI</span>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi"></div>
      <div v-if="type === 'cpi' && showKpiCpi" class="mKPI">
        <span v-if="showValue(item.lastD1)" data-test-id="myGamesGameD1">{{ Math.round((item.lastD1 * 100)) }}%</span>
        <span v-else class="na" data-test-id="myGamesGameD1">N/A</span>
        <span class="label">D1</span>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi"></div>

      <div v-if="type === 'cpi' && showKpiCpi" class="mKPI">
        <span v-if="showValue(item.lastD3PlaytimeHumanReadable)" data-test-id="myGamesGameD3Playtime">{{ item.lastD3PlaytimeHumanReadable }}</span>
        <span v-else class="na" data-test-id="myGamesGameD3Playtime">N/A</span>
        <span class="label">Cumulative D3 playtime</span>
      </div>
      <div v-if="type === 'cpi' && !showKpiCpi"></div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/modules/ActionButton';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import VideoButton from '@/components/VideoButton.vue';
import iconFacebook from '@/assets/images/icon-facebook.svg';
import iconSnapchat from '@/assets/images/icon-snapchat.svg';
import tagIcon from '@/assets/images/tag.svg';
import logoKs from '@/assets/images/knowledge-sharing@3x.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import warningIcon from '@/assets/images/warning.svg';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';

export default {
  name: 'HeaderCompactStudioGameCard',
  components: {
    ProtoScore,
    VideoButton,
    ActionButton,
    GameIcon,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gameIcon,
      tagIcon,
      iconFacebook,
      iconSnapchat,
      logoKs,
      headerHeight: 100,
      warningIcon,
    };
  },
  computed: {
    name() {
      return this.item.name.length > 25 ? `${this.item.name.substr(0, 25)}...` : this.item.name;
    },
    canCreateAd() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    isArchivedGame() {
      return this.item.status === 'archived';
    },
    type() {
      return this.$route.params.type || 'cpi';
    },
    filter() {
      return this.$route.params.filter || 'in_progress';
    },
    showAdnetworks() {
      return this.filter !== 'in_progress' && this.filter !== 'pending_approval';
    },
    showKpiCpi() {
      if (this.type === 'cpi' && this.filter !== 'in_progress' && this.filter !== 'pending_approval') {
        return true;
      }
      return false;
    },
    formattedProtoScore() {
      return this.item.adnetworks ? this.item.adnetworks.split('|').map(adNetwork => ({
        label: adNetwork,
        value: this.item[`score${adNetwork}`],
      })) : null;
    },
  },
  watch: {},

  methods: {
    gotoGameView() {
      this.$router
        .push({
          query: {
            game: undefined,
            studio: undefined,
            view: undefined,
            gameid: this.item.id,
          },
        })
        .catch(() => null);
    },
    getAdnetworkIcon(adnetworkName) {
      const adnetworkMapping = {
        Facebook: iconFacebook,
        Snapchat: iconSnapchat,
      };
      return typeof adnetworkMapping[adnetworkName] === 'undefined' ? null : adnetworkMapping[adnetworkName];
    },
    isIcon(url) {
      if (url) {
        if (url === 'https://original-assets.publidash.com/') {
          return false;
        }
        return true;
      }
      return false;
    },
    showValue(kpi) {
      if (kpi == null) {
        return false;
      }
      return true;
    },
    splitStringInArray(item, separator = '|') {
      if (!item || item === separator) return [];
      if (typeof item === 'string') {
        return item.split(separator);
      }
      return item;
    },
    decodeEntity(string) {
      const elem = document.createElement('textarea');
      elem.innerHTML = string;
      const decoded = elem.value;
      return decoded;
    },
    getheaderHeight() {
      const el = document.querySelector('.header.mygames');
      if (el) {
        return el.clientHeight + 50;
      }
      return 100;
    },
  },
};
</script>

<style lang="scss">
.icon-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1px;
  margin-right: 10px;
  width: auto;
  padding-top: 2px;


    .text-container {
    i {
      margin-right: 5px;
    }
    align-items: center;
    display: flex;
    font-size: 11px;
    margin-right: 5px;
    font-weight: 600;
  }

  img {
    // padding: 5px;
    margin-right: 5px;
  }
  .tag {
    display: flex;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    color: #067bf0;
    background-color: #e6f1fd;
    border: 1px solid #e6f1fd;

    padding: 1px 6px 2px 3px;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
      border: 1px solid #067bf0;
    }
  }
}
.header-content {
  .mobile-kpi {
    display: none;
  }
  @media screen and (max-width: 700px) {
    .mobile-kpi {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      .adnetwork-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-left: -5px;
        }
      }
      .mKPI {
        margin: 10px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label {
          font-size: 11px;
          font-weight: normal;
          margin-top: 0;
          color: #4d4f5c;
        }
        .na {
          font-size: 12px;
          color: #adadad;
        }
      }
    }
  }
}
.header-content {
  width: 100%;
  position: sticky;
  top: 130px;
  z-index: 2;
  border-radius: 10px;
  background-color: #fff;
  @media screen and (max-width: 700px) {
    position: sticky;
    top: 185px;
  }
  .header-compact-game {
    width: 100%;
    height: 72px;
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 1fr 1fr 1fr 2fr;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    border-radius: 10px;
    z-index: 1;
    @media screen and (max-width: 700px) {
      height: 100%;
      padding: 5px;
    }

    // box-shadow: 0 20px 20px -10px #ffffff;
    z-index: 2;
    .preview {
      width: 42px;
      height: 42px;
      @media screen and (max-width: 700px) {
        width: 32px;
        height: 32px;
      }
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 3fr 1fr;
    }
    .adnetwork {
      display: flex;
      img {
        margin-left: -5px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .KPI {
      font-weight: bold;
      text-align: left;
      font-size: 12px;
      display: flex;
      align-items: center;
      .icon-alert {
        margin-left: 5px;
      }
      .label {
        font-size: 11px;
        font-weight: normal;
        margin-top: 0;
        color: #4d4f5c;
      }
      .na {
        font-size: 12px;
        color: #adadad;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .infos-left {
      // padding: 10px 10px 10px 30px;
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      overflow: hidden;
      .basic-infos-game {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 100%;
        padding: 0px 2px;
        max-width: 100%;
        overflow: hidden;
        .top {
          display: grid;
          grid-template-columns: 1fr;
        }
        @media screen and (max-width: 700px) {
          padding: 5px 2px;
        }

        .name {
          color: $intense-blue;
          font-weight: bold;
          font-size: 12px;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100%;
          padding: 2px 0;
          max-width: 170px;
          padding-right: 10px;
          img {
            margin-left: 10px;
          }
        }
        .createdAt {
          color: #adadad;
          font-size: 10px;
          text-align: left;
          // padding: 5px;
        }
        .sub-infos {
          display: flex;
          align-items: center;
          margin-top: 2px;
          @media screen and (max-width: 700px) {
            display: none;
          }
          .vd-svg-auto-tag {
            width: 35px !important;
            margin-right: 2px;
          }
          .vd-svg-manual-tag {
            width: 45px !important;
            margin-right: 2px;
          }
          .vd-svg-android-tag {
            width: 49px !important;
            margin-right: 2px;
          }
          .vd-svg-ios-tag {
            width: 30px !important;
            margin-right: 2px;
          }
        }
      }
      .preview {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .infos-right {
      // padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // min-width: 390px;

      > div {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="gauge-content">
    <div class="gauge">
      <div v-if="nextStep" class="label">
        <span class="step-page">Step {{ nextStepNumber }}/{{ nbStep + " " }}</span>
        <span class="step-label">- Next: {{ nextStep.name || nextStep.title+ " " }}</span>
      </div>
      <div class="gauge-component">
        <el-progress
          type="line"
          :width="100"
          :show-text="false"
          :percentage="percentage"
          class="progress-gauge"
          data-test-id="myGamesSubmissionProgressGauge"
        ></el-progress>
      </div>
    </div>
    <div class="continue">
      <div v-if="hasError">
        <div class="error-title"> ⚠️ Ad network error</div>
        <div class="error-details" data-test-id="myGamesGotoSubmitFlowFromErrorButton" @click="gotoSubmitFlow(game.id)">See more details</div>
      </div>

      <el-button
        v-else
        type="primary"
        size="small"
        data-test-id="myGamesContinueSubmissionButton"
        @click="gotoSubmitFlow(game.id)">
        Continue
      </el-button>
    </div>
  </div>
</template>

<script>
import steps from '@/modules/ScrollStepper/data/steps';

export default {
  name: 'HelloWorld',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    hasError() {
      if (this.game && this.game.iterations && this.game.iterations.length) {
        return Object.keys(this.game.iterations[0].adNetworks)
          .filter(network => this.game.iterations[0].adNetworks[network].status === 'campaign_creation_failed').length;
      }
      return false;
    },
    nbStep() {
      return this.stepArray.length;
    },
    nextStep() {
      if (this.game) {
        let constCurrentStep;
        try {
          constCurrentStep = JSON.parse(this.game.substatus).done;
        } catch (e) {
          constCurrentStep = this.game.substatus || 'link_store';
        }
        for (let i = 0; i < this.stepArray.length; i += 1) {
          const element = this.stepArray[i];
          constCurrentStep = constCurrentStep === 'createGame' ? 'create' : constCurrentStep;
          if (element.id === constCurrentStep) {
            return element;
          }
        }
      }
      return null;
    },
    nextStepNumber() {
      if (this.nextStep) {
        for (let i = 0; i < this.stepArray.length; i += 1) {
          const element = this.stepArray[i];
          if (element.id === this.nextStep.id) {
            return i + 1;
          }
        }
      }
      return 1;
    },
    percentage() {
      return (this.nextStepNumber * 100) / this.nbStep;
    },
    stepArray() {
      return steps.getAllStepArrayByGame(this.game);
    },
  },
  methods: {
    gotoSubmitFlow(gameId) {
      this.$router.push({
        query: {
          game: undefined,
          studio: undefined,
          view: undefined,
          gameid: gameId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.error-title {
  color: $color-danger-plain-color;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 3px;
}

.error-details {
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
}
.el-button--primary {
  height: 34px !important;
}
.gauge-content {
  display: flex;
  height: 100%;
  .gauge {
    display: grid;
    grid-template-rows: 2fr 1fr;
    width: 200px;
    // justify-content: center;
    // align-items: flex-start;
    // align-content: flex-start;
    .label {
      text-align: left;
      font-size: 10px;
      padding: 5px 0px;
      .step-page {
        font-size: 11px;
        color: #4d4f5c;
      }
      .step-label {
        font-size: 10px;
        color: #adadad;
      }
    }
    .gauge-component {
      margin: auto 0;
    }
  }
  @media screen and (max-width: 1200px) {
    .gauge {
      width: 100%;
    }
  }
  .continue {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2px;
  }
}
</style>

import axios from '@/tools/axios';


async function putTags(
  context,
  props,
) {
  const { tags, gameId } = props;
  return axios({
    url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${gameId}/tags`,
    method: 'put',
    data: JSON.stringify({ tags }),
    headers: {
      'content-type': 'application/json',
    },
  });
}

async function patchTags(
  context,
  props,
) {
  const { tag, gameId } = props;
  return axios({
    url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${gameId}/tags`,
    method: 'patch',
    data: JSON.stringify(tag),
    headers: {
      'content-type': 'application/json',
    },
  });
}


async function deleteTags(
  context,
  props,
) {
  const { tag, gameId } = props;
  return axios({
    url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${gameId}/tags`,
    method: 'delete',
    data: JSON.stringify(tag),
    headers: {
      'content-type': 'application/json',
    },
  });
}

async function searchTags(
  context,
  props,
) {
  const { label, category } = props;
  return axios({
    url: `${process.env.VUE_APP_PUBLIC_API_URL}/tags/search?q=${label}&category=${category}`,
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  });
}

export default {
  putTags,
  patchTags,
  deleteTags,
  searchTags,
};

<template>
  <div :class="$style.container">
    <h2 id="title-tinnySauce">
      {{ title }}
    </h2>
    <div
      :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]"
      class="tiny-sauce-step-wrapper"
    >
      <div v-if="!isAccessible" :class="$style.layer"></div>

      <p :class="$style.title">Download the TinySauce Package</p>
      <p :class="$style.subtext" v-html="version"></p>
      <a class="package-button" href="https://voodoosauce-sdk.voodoo-tech.io/unity/TinySauce-Latest.unitypackage">
        <el-button
          class="el-button-package"
          size="small"
          type="primary"
          plain
          data-test-id="gameSubmissionTinySauceDownloadButton"
        ><i>
           <font-awesome-icon
             :class="$style.packageIcon"
             icon="cloud-download-alt"
           />
         </i>

          <span :class="$style.packageText" style="text-decoration: none !important;">Tiny Sauce Package</span>
        </el-button>
      </a>
      <p :class="$style.title">GameAnalytics Game Key</p>
      <p :class="$style.subtext">
        {{game.gameAnalyticsGameKey ? 'Add your GameAnalytics Game Key to the TinySauce.' : 'N/A'}}
      </p>
      <CopyToClipboard v-if="game" :text="game.gameAnalyticsGameKey" data-test-id="gameSubmissionGAGameKey"/>
      <p :class="$style.title">GameAnalytics Secret Key</p>
      <p  :class="$style.subtext">{{game.gameAnalyticsSecretKey ? 'Add your GameAnalytics Secret Key to the TinySauce.' : 'N/A'}}</p>
      <CopyToClipboard v-if="game" :text="game.gameAnalyticsSecretKey" data-test-id="gameSubmissionGASecretKey"/>
      <p :class="$style.title">Adjust Token</p>
      <p v-if="!adjustLoading || game.adjustAppToken" :class="$style.subtext">
        {{ game.adjustAppToken ? 'Add your Adjust Token to the TinySauce settings.' :  '' }}
        <span v-if="!adjustLoading && !game.adjustAppToken">[No Adjust token found - <a
          :class="$style.adjust"
          data-test-id="gameSubmissionGetAdjustTokenButton"
          @click="getAdjustToken()">try again</a>
          ] </span>
      </p>
      <p v-else>
        <i class="el-icon-loading"></i>
      </p>
      <CopyToClipboard v-if="game" :text="game.adjustAppToken" data-test-id="gameSubmissionAdjustToken"/>
      <ImportantNotice
        v-if="warningContent && warningContent.show"
        :class="$style.notice"
        :content="warningContent && warningContent.text"/>
      <div :class="$style.terms">
        <el-checkbox
          id="confirm-tinySauce"
          v-model="isConfirmed"
          :disabled="isConfirmed"
          :class="$style.tinysauceCheckbox"
          data-test-id="gameSubmissionTinySauceCheckbox"
        >
          <span :class="$style.labelConfirm">
            I confirm that I have integrated the latest TinySauce version.</span
          >
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import CopyToClipboard from '@/components/CopyToClipboard/CopyToClipboard.vue';
import ImportantNotice from '@/components/Generic/ImportantNotice/ImportantNotice.vue';
import ContentfulService from '@/services/contentful.services';
import tools from '@/tools/tools';

export default {
  components: {
    CopyToClipboard,
    ImportantNotice,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'no name',
    },
    step: {
      type: Object,
      default: null,
    },
    steps: {
      // alternative: provide / inject
      type: Array,
      default: null,
    },
    warning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isConfirmed: this.steps[this.index].isValid || false,
      isIntersecting: false,
      version: null,
    };
  },
  computed: {
    warningContent() {
      if (!this.warning) {
        return {
          show: false,
          text: '',
        };
      }
      return {
        text: this.warning.tinysauce,
        show: this.warning.tinysauceWarningIsVisible,
      };
    },
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
    adjustLoading() {
      return this.$store.getters['submitGame/adjustLoading'] || null;
    },
    me() {
      return this.$store.getters['users/me'] || null;
    },
  },
  watch: {
    isConfirmed() {
      this.$emit('isValid', this.index);
    },
  },
  mounted() {
    tools.attachEvent(
      document.getElementById('container-scrollable'),
      'scroll',
      this.update,
    );
    tools.attachEvent(window, 'resize', this.update);
    this.getVersion();
  },
  methods: {
    getAdjustToken() {
      this.$store.dispatch('submitGame/getAdjustToken', this.game);
    },
    update() {
      const titleElement = document.getElementById('title-tinnySauce');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    downloadPackage() {
      window.location.href = 'https://voodoosauce-sdk.voodoo-tech.io/unity/TinySauce-Latest.unitypackage';
    },
    async getVersion() {
      const html = await ContentfulService.getEntry('5y4w9PgmGGicH9qcbld9ah', '', this.game);
      this.version = html.tinysauceVersion;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.1);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  padding-top: 0;
  position: relative;

  .layer {
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.labelConfirm {
  // .el-checkbox__input.is-disabled + span.el-checkbox__label{
  white-space: break-spaces !important;
  // }
}

.title {
  margin-top: $spacing * 3;
  font-weight: 600;
}

.subtext {
  color: $grey;
  line-height: 1;
  margin-bottom: $spacing;
}

.terms {
  padding: $spacing * 3;
  margin-top: $spacing * 3;
  background-color: $foggy-blue;
}

.packageIcon {
  font-size: 13px;
  margin-right: $spacing;
}

.packageText {
  font-weight: 600;
  text-decoration: none !important;
}

.tinysauceCheckbox {
  display: flex;
  align-items: center;
}

.notice {
  margin-top : $spacing * 3;
}

.packageWrapper {
  > span {
    display: flex;
  }
}

a.adjust {
  cursor: pointer;
  color: $intense-blue;
  font-weight: bold;
  font-size: 12px;
}
</style>

<style lang="scss">
.tiny-sauce-step-wrapper .el-checkbox__label {
  font-size: 12px;
  font-weight: 600;
}
</style>

/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="game-card-stats-pm">
    <div class="global-stats">
      <div
        class="kpi"
        :class="{ isClickable: isClickable(stats.adnetworks) }"
        data-test-id="gamesListGameAdNetworkWrapper"
        @click="gotoAdnetwork(stats.adnetworks)">
        <div class="name">
          <div class="adnetworks">
            <div v-for="ad in stats.adnetworks" :key="ad" class="icon-adnetwork">
              <img :width="18" :height="18" :src="icon(ad)" />
            </div>
          </div>
          <span v-if="stats.adnetworks.length > 1" class="no-mobile">Global</span>
          <span v-else class="no-mobile ad-alone">{{ stats.adnetworks[0] }}</span>
        </div>
      </div>
      <div class="kpi">
        <div class="last">
          <div class="prop">CPI:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">spend:</span>
              <span class="value" data-test-id="gamesListGameLastCPISpend">${{ stats.global.lastCpiSpend || 0 }}</span
              ><br />
              <span class="name-kpi">installs:</span>
              <span class="value" data-test-id="gamesListGameLastCPIInstalls">{{ stats.global.lastCpiNewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.lastCpiIsRelevant }">
              <b
              ><span v-if="stats.global.lastCpi" class="unit">$</span>
                <span v-if="stats.global.lastCpi || stats.global.lastCpi === 0" data-test-id="gamesListGameLastCPI">{{ +stats.global.lastCpi }}</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
        <div class="best">
          <div class="prop">Best:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">spend:</span>
              <span class="value" data-test-id="gamesListGameBestCPISpend">${{ stats.global.bestCpiSpend || 0 }}</span
              ><br />
              <span class="name-kpi">installs:</span>
              <span class="value" data-test-id="gamesListGameBestCPIInstalls">{{ stats.global.bestCpiNewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.bestCpiIsRelevant }">
              <b
              ><span v-if="stats.global.cpi" class="unit">$</span>
                <span v-if="stats.global.cpi || stats.global.cpi === 0" data-test-id="gamesListGameBestCPI">{{ +stats.global.cpi }}</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
      </div>

      <div v-if="canViewCPNU" class="kpi">
        <div class="last">
          <div class="prop">CPNU:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">spend:</span>
              <span class="value" data-test-id="gamesListGameLastCPNUSpend">${{ stats.global.lastCpnuSpend || 0 }}</span
              ><br />
              <span class="name-kpi">new users:</span>
              <span class="value" data-test-id="gamesListGameLastCPNUInstalls">{{ stats.global.lastCpnuNewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.lastCpnuIsRelevant }">
              <b
              ><span v-if="stats.global.lastCpnu" class="unit">$</span>
                <span v-if="stats.global.lastCpnu || stats.global.lastCpnu === 0" data-test-id="gamesListGameLastCPNU">{{ +stats.global.lastCpnu }}</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
        <div class="best">
          <div class="prop">Best:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">spend:</span>
              <span class="value" data-test-id="gamesListGameBestCPNUSpend">${{ stats.global.bestCpnuSpend || 0 }}</span
              ><br />
              <span class="name-kpi">new users:</span>
              <span class="value" data-test-id="gamesListGameBestCPNUInstalls">{{ stats.global.bestCpnuNewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.bestCpnuIsRelevant }">
              <b
              ><span v-if="stats.global.cpnu" class="unit">$</span>
                <span v-if="stats.global.cpnu || stats.global.cpnu === 0" data-test-id="gamesListGameBestCPNU">{{ +stats.global.cpnu }}</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="kpi">
        <div class="last">
          <div class="prop">D1:</div>

          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameLastD1NewUsers">{{ stats.global.lastD1NewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.lastD1IsRelevant }">
              <b>
                <span v-if="stats.global.lastD1 || stats.global.lastD1 === 0" data-test-id="gamesListGameLastD1">{{ +stats.global.lastD1 }}</span>
                <span v-if="stats.global.lastD1" class="unit">%</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
        <div class="best">
          <div class="prop">Best:</div>

          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameBestD1NewUsers">{{ stats.global.bestD1NewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.bestD1IsRelevant }">
              <b>
                <span v-if="stats.global.d1 || stats.global.d1 === 0" data-test-id="gamesListGameBestD1">{{ +stats.global.d1 }}</span>
                <span v-if="stats.global.d1" class="unit">%</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="kpi">
        <div class="last">
          <div class="prop">D7:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameLastD7NewUsers">{{ stats.global.lastD7NewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.lastD7IsRelevant }">
              <b>
                <span v-if="stats.global.lastD7 || stats.global.lastD7 === 0" data-test-id="gamesListGameLastD7">{{ +stats.global.lastD7 }}</span>
                <span v-if="stats.global.lastD7" class="unit">%</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>

        <div class="best">
          <div class="prop">Best:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameBestD7NewUsers">{{ stats.global.bestD7NewUsers || 0 }}</span
              ><br />
            </div>
            <div class="value" :class="{ relevant: stats.global.bestD7IsRelevant }">
              <b>
                <span v-if="stats.global.d7 || stats.global.d7 === 0" data-test-id="gamesListGameBestD7">{{ +stats.global.d7 }}</span>
                <span v-if="stats.global.d7" class="unit">%</span>
                <span v-else>-</span></b
              >
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="kpi">
        <div class="last">
          <div class="prop">Cumulative D3 Playtime:</div>

          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameLastD3PlaytimeNewUsers">{{ stats.global.lastD1NewUsers || 0 }}</span
              ><br />
            </div>
            <div v-if="stats.global.lastD3Playtime" class="value" :class="{ relevant: stats.global.lastD3PlaytimeIsRelevant }">
              <b data-test-id="gamesListGameLastD3Playtime">{{ stats.global.lastD3Playtime }}</b>
            </div>
            <div v-else class="value">-</div>
          </el-tooltip>
        </div>
        <div class="best">
          <div class="prop">Best:</div>
          <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
            <div slot="content">
              <span class="name-kpi">New users:</span>
              <span class="value" data-test-id="gamesListGameBestD3PlaytimeNewUsers">{{ stats.global.bestD1NewUsers || 0 }}</span
              ><br />
            </div>
            <div v-if="stats.global.d3Playtime" class="value" :class="{ relevant: stats.global.bestD3PlaytimeIsRelevant }">
              <b data-test-id="gamesListGameBestD3Playtime">{{ stats.global.d3Playtime }}</b>
            </div>
            <div v-else class="value">-</div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div v-if="stats.adnetworks.length > 1" class="sub-stats">
      <div v-if="expanded">
        <div v-for="item in stats.adnetworks" :key="item" class="individual-stats">
          <div
            class="kpi"
            :class="{ isClickable: isClickable(item) }"
            data-test-id="gamesListGameAdNetworkWrapper"
            @click="gotoAdnetwork(item)">
            <div class="name">
              <div class="adnetworks">
                <div class="icon-adnetwork">
                  <img :width="18" :height="18" :src="icon(item)" />
                </div>
              </div>
              <span v-if="stats.adnetworks.length > 1" class="no-mobile">{{ `${item[0].toUpperCase() + item.slice(1)}` }}</span>
              <span v-else class="ad-alone no-mobile">{{ stats.adnetworks[0] }}</span>
            </div>
          </div>
          <div class="kpi">
            <div class="last">
              <div class="prop">CPI:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">spend:</span>
                  <span class="value" data-test-id="gamesListGameLastCPISpend">${{ stats[item].lastCpiSpend || 0 }}</span
                  ><br />
                  <span class="name-kpi">installs:</span>
                  <span class="value" data-test-id="gamesListGameLastCPIInstalls">{{ stats[item].lastCpiNewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].lastCpiIsRelevant }">
                  <b
                  ><span v-if="stats[item].lastCpi" class="unit">$</span>
                    <span v-if="stats[item].lastCpi || stats[item].lastCpi === 0" data-test-id="gamesListGameLastCPI">{{ +stats[item].lastCpi }}</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
            <div class="best">
              <div class="prop">Best:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">spend:</span>
                  <span class="value" data-test-id="gamesListGameBestCPISpend">${{ stats[item].bestCpiSpend || 0 }}</span
                  ><br />
                  <span class="name-kpi">installs:</span>
                  <span class="value" data-test-id="gamesListGameBestCPIInstalls">{{ stats[item].bestCpiNewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].bestCpiIsRelevant }">
                  <b
                  ><span v-if="stats[item].cpi" class="unit">$</span>
                    <span v-if="stats[item].cpi || stats[item].cpi === 0" data-test-id="gamesListGameBestCPI">{{ +stats[item].cpi }}</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
          </div>

          <div v-if="canViewCPNU" class="kpi">
            <div class="last">
              <div class="prop">CPNU:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">spend:</span>
                  <span class="value" data-test-id="gamesListGameLastCPNUSpend">${{ stats[item].lastCpnuSpend || 0 }}</span
                  ><br />
                  <span class="name-kpi">new users:</span>
                  <span class="value" data-test-id="gamesListGameLastCPNUInstalls">{{ stats[item].lastCpnuNewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].lastCpnuIsRelevant }">
                  <b
                  ><span v-if="stats[item].lastCpnu" class="unit">$</span>
                    <span v-if="stats[item].lastCpnu || stats[item].lastCpnu === 0" data-test-id="gamesListGameLastCPNU">{{ +stats[item].lastCpnu }}</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
            <div v-if="canViewCPNU" class="best">
              <div class="prop">Best:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">spend:</span>
                  <span class="value" data-test-id="gamesListGameBestCPNUSpend">${{ stats[item].bestCpnuSpend || 0 }}</span
                  ><br />
                  <span class="name-kpi">new users:</span>
                  <span class="value" data-test-id="gamesListGameBestCPNUInstalls">{{ stats[item].bestCpnuNewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].bestCpnuIsRelevant }">
                  <b
                  ><span v-if="stats[item].cpnu" class="unit">$</span>
                    <span v-if="stats[item].cpnu || stats[item].cpnu === 0" data-test-id="gamesListGameBestCPNU">{{ +stats[item].cpnu }}</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="kpi">
            <div class="last">
              <div class="prop">D1:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">New users:</span>
                  <span class="value" data-test-id="gamesListGameLastD1NewUsers">{{ stats[item].lastD1NewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].lastD1IsRelevant }">
                  <b>
                    <span v-if="stats[item].lastD1 || stats[item].lastD1 === 0" data-test-id="gamesListGameLastD1">{{ +stats[item].lastD1 }}</span>
                    <span v-if="stats[item].lastD1" class="unit">%</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
            <div class="best">
              <div class="prop">Best:</div>
              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">New users:</span>
                  <span class="value" data-test-id="gamesListGameBestD1NewUsers">{{ stats[item].bestD1NewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].bestD1IsRelevant }">
                  <b>
                    <span v-if="stats[item].d1 || stats[item].d1 === 0" data-test-id="gamesListGameBestD1">{{ +stats[item].d1 }}</span>
                    <span v-if="stats[item].d1" class="unit">%</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>

            <!-- <div v-if="stats[item].d1" class="value">
              <b>{{ stats[item].d1 }}%</b>
            </div>
            <div v-else class="value">-</div> -->
          </div>
          <div class="kpi">
            <div class="last">
              <div class="prop">D7:</div>

              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">New users:</span>
                  <span class="value" data-test-id="gamesListGameLastD7NewUsers">{{ stats[item].lastD7NewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].lastD7IsRelevant }">
                  <b>
                    <span v-if="stats[item].lastD7 || stats[item].lastD7 === 0" data-test-id="gamesListGameLastD7">{{ +stats[item].lastD7 }}</span>
                    <span v-if="stats[item].lastD7" class="unit">%</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>
            <div class="best">
              <div class="prop">Best:</div>

              <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
                <div slot="content">
                  <span class="name-kpi">New users:</span>
                  <span class="value" data-test-id="gamesListGameBestD7NewUsers">{{ stats[item].bestD7NewUsers || 0 }}</span
                  ><br />
                </div>
                <div class="value" :class="{ relevant: stats[item].bestD7IsRelevant }">
                  <b>
                    <span v-if="stats[item].d7 || stats[item].d7 === 0" data-test-id="gamesListGameBestD7">{{ +stats[item].d7 }}</span>
                    <span v-if="stats[item].d7" class="unit">%</span>
                    <span v-else>-</span></b
                  >
                </div>
              </el-tooltip>
            </div>

            <!-- <div v-if="stats[item].d7" class="value">
              <b>{{ stats[item].d7 }}%</b>
            </div>
            <div v-else class="value">-</div> -->
          </div>
          <div class="kpi">
            <div class="last">
              <div class="prop">Cumulative D3 Playtime:</div>

              <div
                v-if="stats[item].lastD3Playtime"
                class="value"
                :class="{ relevant: stats[item].lastD3PlaytimeIsRelevant }"
                data-test-id="gamesListGameLastD3Playtime">
                <b>{{ stats[item].lastD3Playtime }}</b>
              </div>
              <div v-else class="value">-</div>
            </div>
            <div class="best">
              <div class="prop">Best:</div>
              <div
                v-if="stats[item].d3Playtime"
                class="value"
                :class="{ relevant: stats[item].bestD3PlaytimeIsRelevant }"
                data-test-id="gamesListGameBestD3Playtime">
                <b>{{ stats[item].d3Playtime }}</b>
              </div>
              <div v-else class="value">-</div>
            </div>

            <!-- <el-tooltip popper-class="tooltip-kpi" placement="bottom" effect="light">
              <div slot="content">
                <span class="name-kpi">New users:</span>
                <span class="value">{{ stats[item].bestPlaytimeNewUsers || 0 }}</span
                ><br />
              </div> -->

            <!-- </el-tooltip> -->
          </div>
        </div>
      </div>
      <div class="expand-stats" @click="expanded = !expanded">
        <i v-if="!expanded" class="el-icon-arrow-down"></i>
        <i v-if="expanded" class="el-icon-arrow-up"></i>
        <span>Ad network details</span>
        <i v-if="!expanded" class="el-icon-arrow-down"></i>
        <i v-if="expanded" class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
import fbImage from '@/assets/images/icon-facebook.svg';
import snapImage from '@/assets/images/icon-snapchat.svg';

export default {
  name: 'GameCardStats',
  components: {},
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fbImage,
      snapImage,
      expanded: false,
    };
  },
  computed: {
    canViewCPNU() {
      return this.myPermissions.indexOf('view_cpnu') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    stats() {
      if (!this.game.adnetworks) {
        return {
          adnetworks: ['facebook'],
          global: {
            cpnu: this.game.bestCpnu ? this.game.bestCpnu.toFixed(2) : null,
            cpi: this.game.bestCpi ? this.game.bestCpi.toFixed(2) : null,
            d1: this.game.bestD1 ? (+this.game.bestD1 * 100).toFixed(1) : null,
            d7: this.game.bestD7 ? (+this.game.bestD7 * 100).toFixed(1) : null,
            ctr: this.game.bestCtr ? (+this.game.bestCtr * 100).toFixed(1) : null,
            playtime: this.game.bestPlaytimeHumanReadable ? this.game.bestPlaytimeHumanReadable : null,
            d3Playtime: this.game.bestD3PlaytimeHumanReadable ? this.game.bestD3PlaytimeHumanReadable : null,

            lastCpi: this.game.lastCpi ? this.game.lastCpi.toFixed(2) : null,
            lastCpnu: this.game.lastCpnu ? this.game.lastCpnu.toFixed(2) : null,
            lastD1: this.game.lastD1 ? (+this.game.lastD1 * 100).toFixed(1) : null,
            lastD7: this.game.lastD7 ? (+this.game.lastD7 * 100).toFixed(1) : null,
            lastCtr: this.game.lastCtr ? (+this.game.lastCtr * 100).toFixed(1) : null,
            lastPlaytime: this.game.lastPlaytimeHumanReadable ? this.game.lastPlaytimeHumanReadable : null,
            lastD3Playtime: this.game.lastD3PlaytimeHumanReadable ? this.game.lastD3PlaytimeHumanReadable : null,

            bestCpiNewUsers: this.game.bestCpiNewUsers ? this.game.bestCpiNewUsers : null,
            bestCpiIsRelevant: this.game.bestCpiIsRelevant ? this.game.bestCpiIsRelevant : null,
            bestCpiSpend: this.game.bestCpiSpend ? this.game.bestCpiSpend.toFixed(2) : null,
            bestCpnuNewUsers: this.game.bestCpnuNewUsers ? this.game.bestCpnuNewUsers : null,
            bestCpnuIsRelevant: this.game.bestCpnuIsRelevant ? this.game.bestCpnuIsRelevant : null,
            bestCpnuSpend: this.game.bestCpnuSpend ? this.game.bestCpnuSpend.toFixed(2) : null,
            bestD1NewUsers: this.game.bestD1NewUsers ? this.game.bestD1NewUsers : null,
            bestD1IsRelevant: this.game.bestD1IsRelevant ? this.game.bestD1IsRelevant : null,
            bestD7NewUsers: this.game.bestD7NewUsers ? this.game.bestD7NewUsers : null,
            bestD7IsRelevant: this.game.bestD7IsRelevant ? this.game.bestD7IsRelevant : null,
            bestCtrIsRelevant: this.game.bestCtrIsRelevant ? this.game.bestCtrIsRelevant : null,
            bestCtrNewUsers: this.game.bestCtrNewUsers ? this.game.bestCtrNewUsers : null,
            bestPlaytimeIsRelevant: this.game.bestPlaytimeIsRelevant ? this.game.bestPlaytimeIsRelevant : null,
            bestD3PlaytimeIsRelevant: this.game.bestD3PlaytimeIsRelevant ? this.game.bestD3PlaytimeIsRelevant : null,
            bestPlaytimeNewUsers: this.game.bestPlaytimeNewUsers ? this.game.bestPlaytimeNewUsers : null,
            bestD3PlaytimeNewUsers: this.game.bestD3PlaytimeNewUsers ? this.game.bestD3PlaytimeNewUsers : null,
            lastCpiNewUsers: this.game.lastCpiNewUsers ? this.game.lastCpiNewUsers : null,
            lastCpiIsRelevant: this.game.lastCpiIsRelevant ? this.game.lastCpiIsRelevant : null,
            lastCpiSpend: this.game.lastCpiSpend ? this.game.lastCpiSpend.toFixed(2) : null,
            lastCpnuNewUsers: this.game.lastCpnuNewUsers ? this.game.lastCpnuNewUsers : null,
            lastCpnuIsRelevant: this.game.lastCpnuIsRelevant ? this.game.lastCpnuIsRelevant : null,
            lastCpnuSpend: this.game.lastCpnuSpend ? this.game.lastCpnuSpend.toFixed(2) : null,
            lastD1NewUsers: this.game.lastD1NewUsers ? this.game.lastD1NewUsers : null,
            lastD1IsRelevant: this.game.lastD1IsRelevant ? this.game.lastD1IsRelevant : null,
            lastD7NewUsers: this.game.lastD7NewUsers ? this.game.lastD7NewUsers : null,
            lastD7IsRelevant: this.game.lastD7IsRelevant ? this.game.lastD7IsRelevant : null,
            lastCtrIsRelevant: this.game.lastCtrIsRelevant ? this.game.lastCtrIsRelevant : null,
            lastCtrNewUsers: this.game.lastCtrNewUsers ? this.game.lastCtrNewUsers : null,
            lastPlaytimeIsRelevant: this.game.lastPlaytimeIsRelevant ? this.game.lastPlaytimeIsRelevant : null,
            lastD3PlaytimeIsRelevant: this.game.lastD3PlaytimeIsRelevant ? this.game.lastD3PlaytimeIsRelevant : null,
            lastPlaytimeNewUsers: this.game.lastPlaytimeNewUsers ? this.game.lastPlaytimeNewUsers : null,
            lastD3PlaytimeNewUsers: this.game.lastD3PlaytimeNewUsers ? this.game.lastD3PlaytimeNewUsers : null,
          },
        };
      }

      const adnetworks = this.game.adnetworks.split('|');
      const adnetworkStats = {};

      for (let i = 0; i < adnetworks.length; i += 1) {
        const element = adnetworks[i];
        const labelAdnetwork = `${element[0].toUpperCase() + element.slice(1)}`;
        adnetworkStats[element] = {
          cpnu: this.game[`bestCpnu${labelAdnetwork}`] ? this.game[`bestCpnu${labelAdnetwork}`].toFixed(2) : null,
          cpi: this.game[`bestCpi${labelAdnetwork}`] ? this.game[`bestCpi${labelAdnetwork}`].toFixed(2) : null,
          d1: this.game[`bestD1${labelAdnetwork}`] ? (+this.game[`bestD1${labelAdnetwork}`] * 100).toFixed(1) : null,
          d7: this.game[`bestD7${labelAdnetwork}`] ? (+this.game[`bestD7${labelAdnetwork}`] * 100).toFixed(1) : null,
          playtime: this.game[`bestPlaytimeHumanReadable${labelAdnetwork}`] ? this.game[`bestPlaytimeHumanReadable${labelAdnetwork}`] : null,
          d3Playtime: this.game[`bestD3PlaytimeHumanReadable${labelAdnetwork}`] ? this.game[`bestD3PlaytimeHumanReadable${labelAdnetwork}`] : null,
          ctr: this.game[`bestCtr${labelAdnetwork}`] ? (+this.game[`bestCtr${labelAdnetwork}`] * 100).toFixed(1) : null,

          lastCpnu: this.game[`lastCpnu${labelAdnetwork}`] ? this.game[`lastCpnu${labelAdnetwork}`].toFixed(2) : null,
          lastCpi: this.game[`lastCpi${labelAdnetwork}`] ? this.game[`lastCpi${labelAdnetwork}`].toFixed(2) : null,
          lastD1: this.game[`lastD1${labelAdnetwork}`] ? (+this.game[`lastD1${labelAdnetwork}`] * 100).toFixed(1) : null,
          lastD7: this.game[`lastD7${labelAdnetwork}`] ? (+this.game[`lastD7${labelAdnetwork}`] * 100).toFixed(1) : null,
          lastPlaytime: this.game[`lastPlaytimeHumanReadable${labelAdnetwork}`] ? this.game[`lastPlaytimeHumanReadable${labelAdnetwork}`] : null,
          lastD3Playtime: this.game[`lastD3PlaytimeHumanReadable${labelAdnetwork}`] ? this.game[`lastD3PlaytimeHumanReadable${labelAdnetwork}`] : null,
          lastCtr: this.game[`lastCtr${labelAdnetwork}`] ? (+this.game[`lastCtr${labelAdnetwork}`] * 100).toFixed(1) : null,

          bestCpnuNewUsers: this.game[`bestCpnuNewUsers${labelAdnetwork}`] ? this.game[`bestCpnuNewUsers${labelAdnetwork}`] : null,
          bestCpnuIsRelevant: this.game[`bestCpnuIsRelevant${labelAdnetwork}`] ? this.game[`bestCpnuIsRelevant${labelAdnetwork}`] : null,
          bestCpnuSpend: this.game[`bestCpnuSpend${labelAdnetwork}`] ? this.game[`bestCpnuSpend${labelAdnetwork}`].toFixed(2) : null,

          bestCpiNewUsers: this.game[`bestCpiNewUsers${labelAdnetwork}`] ? this.game[`bestCpiNewUsers${labelAdnetwork}`] : null,
          bestCpiIsRelevant: this.game[`bestCpiIsRelevant${labelAdnetwork}`] ? this.game[`bestCpiIsRelevant${labelAdnetwork}`] : null,
          bestCpiSpend: this.game[`bestCpiSpend${labelAdnetwork}`] ? this.game[`bestCpiSpend${labelAdnetwork}`].toFixed(2) : null,
          bestD1NewUsers: this.game[`bestD1NewUsers${labelAdnetwork}`] ? this.game[`bestD1NewUsers${labelAdnetwork}`] : null,
          bestD7NewUsers: this.game[`bestD7NewUsers${labelAdnetwork}`] ? this.game[`bestD7NewUsers${labelAdnetwork}`] : null,
          bestCtrNewUsers: this.game[`bestCtrNewUsers${labelAdnetwork}`] ? this.game[`bestCtrNewUsers${labelAdnetwork}`] : null,
          bestD1IsRelevant: this.game[`bestD1IsRelevant${labelAdnetwork}`] ? this.game[`bestD1IsRelevant${labelAdnetwork}`] : null,
          bestD7IsRelevant: this.game[`bestD7IsRelevant${labelAdnetwork}`] ? this.game[`bestD7IsRelevant${labelAdnetwork}`] : null,
          bestCtrIsRelevant: this.game[`bestCtrIsRelevant${labelAdnetwork}`] ? this.game[`bestCtrIsRelevant${labelAdnetwork}`] : null,
          bestPlaytimeIsRelevant: this.game[`bestPlaytimeIsRelevant${labelAdnetwork}`] ? this.game[`bestPlaytimeIsRelevant${labelAdnetwork}`] : null,
          bestD3PlaytimeIsRelevant: this.game[`bestD3PlaytimeIsRelevant${labelAdnetwork}`] ? this.game[`bestD3PlaytimeIsRelevant${labelAdnetwork}`] : null,
          bestPlaytimeNewUsers: this.game[`bestPlaytimeNewUsers${labelAdnetwork}`] ? this.game[`bestPlaytimeNewUsers${labelAdnetwork}`] : null,
          bestD3PlaytimeNewUsers: this.game[`bestD3PlaytimeNewUsers${labelAdnetwork}`] ? this.game[`bestD3PlaytimeNewUsers${labelAdnetwork}`] : null,

          lastCpnuNewUsers: this.game[`lastCpnuNewUsers${labelAdnetwork}`] ? this.game[`lastCpnuNewUsers${labelAdnetwork}`] : null,
          lastCpnuIsRelevant: this.game[`lastCpnuIsRelevant${labelAdnetwork}`] ? this.game[`lastCpnuIsRelevant${labelAdnetwork}`] : null,
          lastCpnuSpend: this.game[`lastCpnuSpend${labelAdnetwork}`] ? this.game[`lastCpnuSpend${labelAdnetwork}`].toFixed(2) : null,

          lastCpiNewUsers: this.game[`lastCpiNewUsers${labelAdnetwork}`] ? this.game[`lastCpiNewUsers${labelAdnetwork}`] : null,
          lastCpiIsRelevant: this.game[`lastCpiIsRelevant${labelAdnetwork}`] ? this.game[`lastCpiIsRelevant${labelAdnetwork}`] : null,
          lastCpiSpend: this.game[`lastCpiSpend${labelAdnetwork}`] ? this.game[`lastCpiSpend${labelAdnetwork}`].toFixed(2) : null,
          lastD1NewUsers: this.game[`lastD1NewUsers${labelAdnetwork}`] ? this.game[`lastD1NewUsers${labelAdnetwork}`] : null,
          lastD7NewUsers: this.game[`lastD7NewUsers${labelAdnetwork}`] ? this.game[`lastD7NewUsers${labelAdnetwork}`] : null,
          lastCtrNewUsers: this.game[`lastCtrNewUsers${labelAdnetwork}`] ? this.game[`lastCtrNewUsers${labelAdnetwork}`] : null,
          lastD1IsRelevant: this.game[`lastD1IsRelevant${labelAdnetwork}`] ? this.game[`lastD1IsRelevant${labelAdnetwork}`] : null,
          lastD7IsRelevant: this.game[`lastD7IsRelevant${labelAdnetwork}`] ? this.game[`lastD7IsRelevant${labelAdnetwork}`] : null,
          lastCtrIsRelevant: this.game[`lastCtrIsRelevant${labelAdnetwork}`] ? this.game[`lastCtrIsRelevant${labelAdnetwork}`] : null,
          lastPlaytimeIsRelevant: this.game[`lastPlaytimeIsRelevant${labelAdnetwork}`] ? this.game[`lastPlaytimeIsRelevant${labelAdnetwork}`] : null,
          lastD3PlaytimeIsRelevant: this.game[`lastD3PlaytimeIsRelevant${labelAdnetwork}`] ? this.game[`lastD3PlaytimeIsRelevant${labelAdnetwork}`] : null,
          lastPlaytimeNewUsers: this.game[`lastPlaytimeNewUsers${labelAdnetwork}`] ? this.game[`lastPlaytimeNewUsers${labelAdnetwork}`] : null,
          lastD3PlaytimeNewUsers: this.game[`lastD3PlaytimeNewUsers${labelAdnetwork}`] ? this.game[`lastD3PlaytimeNewUsers${labelAdnetwork}`] : null,
        };
      }
      const obj = {
        adnetworks,
        global: {
          cpnu: this.game.bestCpnu ? this.game.bestCpnu.toFixed(2) : null,
          cpi: this.game.bestCpi ? this.game.bestCpi.toFixed(2) : null,
          d1: this.game.bestD1 ? (+this.game.bestD1 * 100).toFixed(1) : null,
          d7: this.game.bestD7 ? (+this.game.bestD7 * 100).toFixed(1) : null,
          ctr: this.game.bestCtr ? (+this.game.bestCtr * 100).toFixed(1) : null,
          playtime: this.game.bestPlaytimeHumanReadable ? this.game.bestPlaytimeHumanReadable : null,
          d3Playtime: this.game.bestD3PlaytimeHumanReadable ? this.game.bestD3PlaytimeHumanReadable : null,

          lastCpnu: this.game.lastCpnu ? this.game.lastCpnu.toFixed(2) : null,
          lastCpi: this.game.lastCpi ? this.game.lastCpi.toFixed(2) : null,
          lastD1: this.game.lastD1 ? (+this.game.lastD1 * 100).toFixed(1) : null,
          lastD7: this.game.lastD7 ? (+this.game.lastD7 * 100).toFixed(1) : null,
          lastCtr: this.game.lastCtr ? (+this.game.lastCtr * 100).toFixed(1) : null,
          lastPlaytime: this.game.lastPlaytimeHumanReadable ? this.game.lastPlaytimeHumanReadable : null,
          lastD3Playtime: this.game.lastD3PlaytimeHumanReadable ? this.game.lastD3PlaytimeHumanReadable : null,

          bestCpnuNewUsers: this.game.bestCpnuNewUsers ? this.game.bestCpnuNewUsers : null,
          bestCpnuIsRelevant: this.game.bestCpnuIsRelevant ? this.game.bestCpnuIsRelevant : null,
          bestCpnuSpend: this.game.bestCpnuSpend ? this.game.bestCpnuSpend.toFixed(2) : null,

          bestCpiNewUsers: this.game.bestCpiNewUsers ? this.game.bestCpiNewUsers : null,
          bestCpiIsRelevant: this.game.bestCpiIsRelevant ? this.game.bestCpiIsRelevant : null,
          bestCpiSpend: this.game.bestCpiSpend ? this.game.bestCpiSpend.toFixed(2) : null,
          bestD1NewUsers: this.game.bestD1NewUsers ? this.game.bestD1NewUsers : null,
          bestD1IsRelevant: this.game.bestD1IsRelevant ? this.game.bestD1IsRelevant : null,
          bestD7NewUsers: this.game.bestD7NewUsers ? this.game.bestD7NewUsers : null,
          bestD7IsRelevant: this.game.bestD7IsRelevant ? this.game.bestD7IsRelevant : null,
          bestCtrNewUsers: this.game.bestCtrNewUsers ? this.game.bestCtrNewUsers : null,
          bestCtrIsRelevant: this.game.bestCtrIsRelevant ? this.game.bestCtrIsRelevant : null,
          bestPlaytimeIsRelevant: this.game.bestPlaytimeIsRelevant ? this.game.bestPlaytimeIsRelevant : null,
          bestD3PlaytimeIsRelevant: this.game.bestD3PlaytimeIsRelevant ? this.game.bestD3PlaytimeIsRelevant : null,
          bestPlaytimeNewUsers: this.game.bestPlaytimeNewUsers ? this.game.bestPlaytimeNewUsers : null,
          bestD3PlaytimeNewUsers: this.game.bestD3PlaytimeNewUsers ? this.game.bestD3PlaytimeNewUsers : null,

          lastCpnuNewUsers: this.game.lastCpnuNewUsers ? this.game.lastCpnuNewUsers : null,
          lastCpnuIsRelevant: this.game.lastCpnuIsRelevant ? this.game.lastCpnuIsRelevant : null,
          lastCpnuSpend: this.game.lastCpnuSpend ? this.game.lastCpnuSpend.toFixed(2) : null,

          lastCpiNewUsers: this.game.lastCpiNewUsers ? this.game.lastCpiNewUsers : null,
          lastCpiIsRelevant: this.game.lastCpiIsRelevant ? this.game.lastCpiIsRelevant : null,
          lastCpiSpend: this.game.lastCpiSpend ? this.game.lastCpiSpend.toFixed(2) : null,
          lastD1NewUsers: this.game.lastD1NewUsers ? this.game.lastD1NewUsers : null,
          lastD1IsRelevant: this.game.lastD1IsRelevant ? this.game.lastD1IsRelevant : null,
          lastD7NewUsers: this.game.lastD7NewUsers ? this.game.lastD7NewUsers : null,
          lastD7IsRelevant: this.game.lastD7IsRelevant ? this.game.lastD7IsRelevant : null,
          lastCtrNewUsers: this.game.lastCtrNewUsers ? this.game.lastCtrNewUsers : null,
          lastCtrIsRelevant: this.game.lastCtrIsRelevant ? this.game.lastCtrIsRelevant : null,
          lastPlaytimeIsRelevant: this.game.lastPlaytimeIsRelevant ? this.game.lastPlaytimeIsRelevant : null,
          lastD3PlaytimeIsRelevant: this.game.lastD3PlaytimeIsRelevant ? this.game.lastD3PlaytimeIsRelevant : null,
          lastPlaytimeNewUsers: this.game.lastPlaytimeNewUsers ? this.game.lastPlaytimeNewUsers : null,
          lastD3PlaytimeNewUsers: this.game.lastD3PlaytimeNewUsers ? this.game.lastD3PlaytimeNewUsers : null,
        },
        ...adnetworkStats,
      };
      return obj;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    gotoAdnetwork(value) {
      if (this.isFacebookAdNetwork(value) && this.game.facebookUrl) {
        window.open(this.game.facebookUrl);
      }
      if (this.isSnapchatAdNetwork(value) && this.game.snapchatUrl) {
        window.open(this.game.snapchatUrl);
      }
    },
    isClickable(value) {
      const isFacebook = this.isFacebookAdNetwork(value) && this.game.facebookUrl;
      const isSnap = this.isSnapchatAdNetwork(value) && this.game.snapchatUrl;
      return isFacebook || isSnap;
    },
    isFacebookAdNetwork(value) {
      return (Array.isArray(value) && value.length === 1 && value.includes('Facebook')) || value === 'Facebook';
    },
    isSnapchatAdNetwork(value) {
      return (Array.isArray(value) && value.length === 1 && value.includes('Snapchat')) || value === 'Snapchat';
    },
    icon(ad) {
      if (ad === 'Facebook') {
        return fbImage;
      }
      if (ad === 'Snapchat') {
        return snapImage;
      }
      return fbImage;
    },
  },
};
</script>

<style lang="scss">

.center {
  margin: auto;
}
.tooltip-kpi {
  text-align: left;
  .name-kpi {
    color: var(--font-color-tooltip-label);
    margin-right: 5px;
  }
  .value {
    color: var(--font-color-tooltip-value);
  }
}
.game-card-stats-pm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--game-card-background-color);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: solid 1px var(--filter-border-bottom-color);
  .no-mobile {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .global-stats,
  .individual-stats {
    display: grid;
    width: 100%;
    grid-template-columns: 25% 15% 15% 15% 15% 15%;
    @media screen and (max-width: 700px) {
      grid-template-columns: 10% 18% 18% 18% 18% 18%;
    }
    .kpi {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      overflow: auto;
      &.isClickable {
        &:hover {
          cursor: pointer;
        }
      }
      .name {
        display: flex;
        align-items: center;
      }
      .last,
      .best {
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      .prop {
        color: #adadad;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .value {
        margin-left: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--color-kpi-gamecard);
        &:hover {
          color: var(--color-kpi-gamecard-hover);
          &.relevant {
            color: $is-relevent-color;
          }
        }
        &.relevant {
          color: $is-relevent-color;
        }
      }
      .adnetworks {
        display: flex;
        padding-left: 10px;

        filter: var(--grayscale);

        & + span {
          margin-left: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon-adnetwork {
          & + .icon-adnetwork {
            margin-left: -5px;
          }
        }
      }
      .ad-alone {
        color: $intense-blue;
      }
    }
  }
  .sub-stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    .expand-stats {
      background-color: var(--bg-notif-more);
      color: $blue-label-expand;
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 5px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      span {
        font-size: 11px;
        padding: 0px 10px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

//
</style>

/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import StudioAdminServices from '@/services/studioAdmin.services';

const adminstudio = {
  namespaced: true,
  state: {
    studioInfos: null,
    studios: null,
    loading: false,
    loading_studio_infos: false,
    loading_user_create: false,
    loading_studio_contract: false,
    contracts: [],
    users: [],
  },
  getters: {
    studio(state) {
      return state.studioInfos;
    },
    loadingUserCreate(state) {
      return state.loading_user_create;
    },
    studios(state) {
      return state.studios;
    },
    loading(state) {
      return state.loading;
    },
    loading_studio_infos(state) {
      return state.loading_studio_infos;
    },
    loading_studio_contract(state) {
      return state.loading_studio_contract;
    },
    contracts(state) {
      return state.contracts;
    },
    users(state) {
      return state.users;
    },
  },
  mutations: {
    set_studios(state, studios) {
      state.studios = studios;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_loading_studio_infos(state, loading) {
      state.loading_studio_infos = loading;
    },
    set_loading_user_create(state, loading) {
      state.loading_user_create = loading;
    },
    set_loading_studio_contract(state, loading) {
      state.loading_studio_contract = loading;
    },
    set_studios_infos(state, studioInfos) {
      state.studioInfos = studioInfos;
    },
    update_studios_permissions(state, permissions) {
      state.studioInfos.permissions = permissions;
    },
    set_loading_studio_contract_loading(state, loading) {
      const con = cloneDeep(state.contracts);
      for (let i = 0; i < con.length; i += 1) {
        const element = con[i];

        if (element.id === loading.id) {
          element.loading = loading.value;
        } else {
          element.loading = false;
        }
      }
      state.contracts = con;
    },
    update_studios_infos(state, studioInfos) {
      state.studioInfos = {
        ...state.studioInfos,
        ...studioInfos,
      };
    },
    set_studios_contracts(state, contracts) {
      state.contracts = contracts;
    },
    set_studios_users(state, users) {
      for (let i = 0; i < users.length; i += 1) {
        const element = users[i];

        if (element.accessId === 5) {
          element.isActive = false;
        } else {
          element.isActive = true;
        }
      }
      state.users = users;
    },
    add_new_user(state, d) {
      const us = cloneDeep(state.users);
      us.push(d.user);
      state.users = us;
    },
    set_loading_studio_user(state, loading) {
      const users = cloneDeep(state.users);
      for (let i = 0; i < users.length; i += 1) {
        const element = users[i];
        if (element.user_id === loading.id) {
          element.loading = loading.loading;
        }
      }
      state.users = users;
    },
    update_studios_user_active(state, data) {
      const users = cloneDeep(state.users);
      for (let i = 0; i < users.length; i += 1) {
        const element = users[i];
        if (element.id === data.id) {
          if (data.accessId === 5) {
            element.isActive = false;
            element.accessId = 5;
          } else {
            element.isActive = true;
            element.accessId = 4;
          }
        }
      }
      state.users = users;
    },
    update_knowledgeSharing(state, value) {
      if (state.studioInfos) {
        state.studioInfos.knowledgeSharingStudioStatus = !value;
      }
    },
    update_studios_user_role(state, data) {
      const users = cloneDeep(state.users);
      for (let i = 0; i < users.length; i += 1) {
        const element = users[i];
        if (element.id === data.id) {
          element.userRole = data.value;
        }
      }
      state.users = users;
    },
    update_studios_user_permission(state, data) {
      const users = cloneDeep(state.users);
      for (let i = 0; i < users.length; i += 1) {
        const element = users[i];
        if (element.id === data.id) {
          element.accessId = data.value;
          element.isActive = data.value !== 5;
        }
      }

      state.users = users;
    },
  },
  actions: {
    async getStudios(context) {
      context.commit('set_loading', true);
      const response = await StudioAdminServices.getStudios();
      context.commit('set_loading', false);
      if (response) {
        context.commit('set_studios', response);
      }
    },
    async getStudioInfos(context, id) {
      context.commit('set_loading_studio_infos', true);
      const response = await StudioAdminServices.getStudioInfos(id);
      const permissionsResponse = await StudioAdminServices.getStudioPermissions(id);
      context.commit('set_loading_studio_infos', false);
      if (response) {
        context.commit('set_studios_infos', response);
      }
      if (permissionsResponse) {
        context.commit('update_studios_permissions', permissionsResponse);
      }
    },
    async getStudioContracts(context, id) {
      context.commit('set_loading_studio_contract', true);
      const response = await StudioAdminServices.getStudioContracts(id);
      context.commit('set_loading_studio_contract', false);
      if (response) {
        context.commit('set_studios_contracts', response);
      }
    },
    async getStudioUsers(context, id) {
      context.commit('set_loading_studio_infos', true);
      const response = await StudioAdminServices.getStudioUsers(id);
      context.commit('set_loading_studio_infos', false);
      if (response) {
        context.commit('set_studios_users', response);
      }
    },
    async getStudioUsersAsync(context, id) {
      return new Promise(async (resolve) => {
        context.commit('set_loading_studio_infos', true);
        const list = await StudioAdminServices.getStudioUsers(id);
        context.commit('set_loading_studio_infos', false);
        if (list) {
          resolve(list);
        }
      });
    },
    async updateStudioInfos(context, data) {
      context.commit('set_loading_studio_infos', true);
      const response = await StudioAdminServices.updateStudiosInfos(data);
      context.commit('set_loading_studio_infos', false);
      if (response) {
        context.commit('update_studios_infos', data);
      }

      return response;
    },
    async getContractById(context, id) {
      context.commit('set_loading_studio_contract_loading', {
        id: id.id,
        value: true,
      });
      const rep = await StudioAdminServices.getContract(id);

      if (rep) {
        context.commit('set_loading_studio_contract_loading', {
          id: id.id,
          value: false,
        });
      }
    },
    async createNewUser(context, d) {
      const data = d;
      context.commit('set_loading_user_create', true);
      const response = await StudioAdminServices.createNewUser(data);
      context.commit('set_loading_user_create', false);
      if (response) {
        context.dispatch('getStudioUsers', d.studioId);
        return new Promise(async (resolve) => {
          resolve(true);
        });
      }
      return new Promise(async (resolve) => {
        resolve(false);
      });
    },
    async updateStudioUserActive(context, d) {
      const data = d;
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: true,
      });
      if (data.value === true) {
        delete data.value;
        data.accessId = 5;
      } else {
        delete data.value;
        data.accessId = 4;
      }
      const response = await StudioAdminServices.updateStudiosUserActive(data);
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: false,
      });
      if (response) {
        context.commit('update_studios_user_active', d);
      }
    },
    async updateStudioUserRole(context, data) {
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: true,
      });
      const response = await StudioAdminServices.updateStudiosUserRole(data);
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: false,
      });
      if (!response.code) {
        context.commit('update_studios_user_role', data);
      }
    },
    async updateStudioUserPermission(context, data) {
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: true,
      });
      const response = await StudioAdminServices.updateStudiosUserPermission(data);
      context.commit('set_loading_studio_user', {
        id: data.id,
        loading: false,
      });
      if (response) {
        context.commit('update_studios_user_permission', data);
      }
    },

    async updateKnowledgeSharingByStudioId(context, d) {
      const data = d;
      const response = await StudioAdminServices.updateKnowledgeSharingByStudioId(data);
      if (response) {
        context.commit('update_knowledgeSharing', data.value);
        context.commit('studios/update_knowledgeSharing', data.value, { root: true });
      }
    },
  },
};

export default adminstudio;

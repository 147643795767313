<template>
  <div class="videos-and-stats">
    <div v-for="(currentIteration, currentIterationIndex) in iterations" :key="currentIterationIndex" class="iteration-bloc">
      <div class="iteration-header">
        <div class="iteration-header-right">
          <ProtoScore
            :recommendation="getRecommendation(currentIteration, selectedAdNetwork)"
            :score-values="formatProtoScore(currentIteration, selectedAdNetwork)"
            :display-details="myPermissions.includes('view_proto_score')"
          ></ProtoScore>
          <div v-if="currentIterationIndex !== 0 && iterations.length > 1" class="status finished">
            Finished
          </div>
          <div v-if="currentIterationIndex === 0 && !dateIsInThePast(currentIteration.iterationEnd)" class="status" :class="game.status">
            {{formatStatus(game.status)}}
          </div>
          <div v-if="currentIterationIndex === 0 && dateIsInThePast(currentIteration.iterationEnd)"  class="status finished">
            Finished
          </div>
        </div>
        <div class="iteration-header-left">
          <div class="iteration-number">
            <span>#{{ +game.iterations.length - currentIterationIndex }} iteration</span>
          </div>
          <div
            v-if="currentIteration.iterationStart"
            class="iteration-date">
            <span>
              Campaign date:
              <span>{{ currentIteration.iterationStart | localeDate }}</span>
              to
              <span>{{ currentIteration.iterationEnd | localeDate }}</span>
            </span>
          </div>
          <div v-else class="iteration-date">
            Creation date: {{ currentIteration.createdAt| localeDate }}
          </div>
        </div>
      </div>
      <TabsSlider :tabs="sliders" @activeTabChanged="switchView(currentIterationIndex, $event)"></TabsSlider>
      <div class="content-slider">
        <div v-if="typeof sectionSelected[currentIterationIndex] === 'undefined' || sectionSelected[currentIterationIndex] === 'videos'">
          <IterationVideos
            :tab="tab"
            :selected-ad-network="selectedAdNetwork"
            :iteration="getIterationDataForSelectedAdNetwork(currentIteration)"
            :game="game"></IterationVideos>
        </div>
        <div v-if="sectionSelected[currentIterationIndex] === 'graphs'" class="graphs-component">
          <IterationGraphs
            :selected-ad-network="selectedAdNetwork"
            :iteration="getIterationDataForSelectedAdNetwork(currentIteration)"
            :is-ctr="game.isCtr"
            :os="game.platform"
          ></IterationGraphs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import IterationVideos from '@/components/Game/IterationVideos.vue';
import IterationGraphs from '@/components/Game/IterationGraphs.vue';
import TabsSlider from '@/components/Generic/TabsSlider/TabsSlider.vue';
import tools from '@/tools/tools';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';

export default {
  name: 'IterationGlobal',
  components: {
    ProtoScore,
    IterationVideos,
    IterationGraphs,
    TabsSlider,
  },
  props: {
    tab: {
      type: String,
      default: null,
    },
    game: {
      type: Object,
      default: null,
    },
    selectedAdNetwork: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      containerNode: null,
      loading: false,
      sectionSelected: [],
      sliders: [
        {
          label: 'Videos',
          icon: 'on-videos',
          id: 'videos',
        },
        {
          label: 'Graphs',
          icon: 'on-graphs',
          id: 'graphs',
        },
      ],
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    iterations() {
      return this.game.iterations.slice().reverse();
    },
    isDisplayedOnStudioSide() {
      return this.$route.meta ? this.$route.meta.category === 'Publishing' : false;
    },
  },
  methods: {
    formatStatus(status) {
      let st = status;
      if (!this.isDisplayedOnStudioSide) {
        st = tools.getWhichFilterGameIs(this.game);
      }
      return st[0].toUpperCase() + st.slice(1).replace(/_/g, ' ');
    },
    getRecommendation(iteration, adNetwork) {
      return adNetwork ? iteration.adNetworks[adNetwork].recommendation : iteration.recommendation;
    },
    formatProtoScore(iteration, adNetwork) {
      if (adNetwork) {
        return [{
          label: adNetwork,
          value: iteration.adNetworks[adNetwork].score,
        }];
      }
      return Object.entries(iteration.adNetworks).map(adNetworkEntry => ({
        label: adNetworkEntry[0],
        value: adNetworkEntry[1].score,
      }));
    },
    switchView(iterationIndex, value) {
      this.$set(this.sectionSelected, iterationIndex, value.id);
    },
    dateIsInThePast(date) {
      return moment(date).isBefore(moment());
    },
    getIterationDataForSelectedAdNetwork(currentIteration) {
      return this.selectedAdNetwork === null ? currentIteration : currentIteration.adNetworks[this.selectedAdNetwork];
    },
  },
};
</script>

<style lang="scss">
.videos-and-stats {
  display: flex;
  flex-direction: column;
  .iteration-bloc {
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    margin: 10px 0;
    .iteration-header {
      width: 100%;
      text-align: left;
      padding: 15px 10px;
      .iteration-header-left {
        display: flex;
        flex-direction: column;
      }
      .iteration-header-right {
        float: right;
        font-size: 10px;
        color: #fff;
        margin-top: 8px;
        display: flex;
        align-items: center;
        .status {
          border-radius: 10px;
          padding: 5px 10px;
          margin-left: 20px;
          &.in_testing {
            padding-left: 10px !important;
            background-color: $green-alert;
            color: var(--main-font-basic-color-revert);
          }
          &.finished {
            padding-left: 10px !important;
            background-color: $grey;
            color: var(--main-font-basic-color-revert);
          }
          &.archived{
            color: var(--main-font-basic-color);
          }
          &.pending_review,
          &.pending_sdk_integration,
          &.pending_testing {
            padding-left: 10px !important;
            background-color: $orange;
            color: var(--main-font-basic-color-revert);
          }
        }

      }
      .iteration-number {
        padding-bottom: 8px;
        font-size: 20px;
        font-weight: bold;
      }
      .iteration-date {
        font-size: 12px;
      }
    }

    .content-slider {
      padding: 10px;
      .graphs-component {
      }
    }
  }
}
</style>

<template>
  <div class="container-login">
    <div class="login">
      <div class="header">
        <img class="logo" :src="logoVoodoo" />
        <span>Log in to your account</span>
      </div>
      <div class="form-login" @keydown.enter="submitForm('loginForm')">
        <el-form
          ref="loginForm"
          :hide-required-asterisk="true"
          :rules="rulesLoginForm"
          :model="loginForm"
          label-width="120px"
          label-position="top"
        >
          <el-form-item label="Email" prop="email">
            <el-input
              ref="email"
              v-model="loginForm.email"
              type="email"
              placeholder="Enter your email"
              @blur="forceFormAutofillFalse"
              @focus="forceFormAutofillFalse"
              @input="forceFormAutofillFalse"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password" :error="errors[0]">
            <el-input
              ref="password"
              v-model="loginForm.password"
              type="password"
              placeholder="Enter your password"
              @blur="forceFormAutofillFalse"
              @focus="forceFormAutofillFalse"
              @input="forceFormAutofillFalse"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="submit">
        <el-button
          type="primary"
          :disabled="disableSubmit"
          :loading="loading"
          @click="submitForm('loginForm')">
          Log in
        </el-button>
        <router-link
          :to="{ name: 'passwordReset' }"
          class="forgot"
        >Forgot your password?</router-link
        >
        <br>
        <br>
        <a class="signup" href="https://www.voodoo.io/publishing/sign-up">No account yet?
          Sign up on <span class="website">voodoo.io</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
// eslint-disable-next-line import/no-cycle
import UsersService from '@/services/users.services';
import tool from '@/tools/tools';
import logoVoodoo from '../assets/images/voodoo-black.svg';

export default {
  name: 'Login',
  components: {},
  props: {},
  data() {
    return {
      isFormAutofilled: false,
      token: '',
      swiped: false,
      logoVoodoo,
      loginForm: {
        email: '',
        password: '',
      },
      rulesLoginForm: {
        email: [
          {
            required: true,
            message: 'Please input your email',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please input your password',
            trigger: 'blur',
          },
        ],
      },
      errors: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters['users/loadingLogin'];
    },
    me() {
      return this.$store.getters['users/me'] || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    disableSubmit() {
      return (!this.loginForm.email || !this.loginForm.password || this.loginForm.password.length < 3) && !this.isFormAutofilled;
    },
  },
  watch: {
    me(value, old) {
      if (value !== old) {
        this.redirectLoggedUser();
      }
    },
  },
  mounted() {
    this.redirectLoggedUser();
    setTimeout(() => {
      this.checkFormAutofilled();
    }, 500);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.errors = [];
          const body = cloneDeep(this.loginForm);
          if (this.$route.query.redirect) {
            body.includeRedirectUrlFor = this.$route.query.redirect || 'learnupon';
            this.$gtag.event('login', { method: 'learnupon' });
          } else {
            this.$gtag.event('login', { method: 'normal' });
          }
          try {
            const response = await this.$store.dispatch('users/login', body);
            if (response.redirectUrl) {
              window.location.href = response.redirectUrl;
            }
          } catch (err) {
            const errors = ['Wrong password'];
            this.errors = cloneDeep(errors);
          }
        }
      });
    },
    async redirectLoggedUser() {
      if (!this.me) {
        this.$store.dispatch('users/getUser');
      } else if (this.me) {
        tool.redirectUser(this.$router, this.myPermissions, UsersService, this.$route);
      }
    },
    checkFormAutofilled() {
      const email = this.$refs.email.$el;
      const password = this.$refs.password.$el;
      const isEmailAutofilled = email.querySelector('input:-webkit-autofill');
      const isPasswordAutofilled = password.querySelector('input:-webkit-autofill');
      this.isFormAutofilled = !!isEmailAutofilled && !!isPasswordAutofilled;
    },
    forceFormAutofillFalse() {
      this.isFormAutofilled = false;
    },
  },
};
</script>

<style lang="scss">

.container-login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-container {
    color: $red-alert;
    font-size: 10px;
    text-align:left;
    margin-bottom: $spacing;
    position: fixed;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .login .form-login .el-form--label-top .el-form-item__label{
    text-align: left !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 10px !important;
    margin-bottom: 5px !important;
  }


  .login {
    width: 360px;
    margin: 100px;
    padding: 20px;
    @media (max-width: 700px) {
      margin: 20px;
    }
    .el-form-item__error {
      color: $red-alert;
    }
    h1 {
      font-size: 22px;
      color: var(--grey) !important;
      font-weight: 300;
      padding: 10px;
    }
    h2 {
      font-size: 18px;
      font-weight: 300;
      padding: 10px;
    }
    .logo {
      width: 150px;
      padding: 20px;
    }
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 16px;
      span {
        font-weight: 600 !important;
        color: var(--grey) !important;
      }
    }
    .form-login {
      margin-top: 30px;
      text-align: left;
      .el-form--label-top .el-form-item__label {
        padding: 0 !important;
        font-weight: 600 !important;
        color: var(--grey) !important;
      }
    }
    .submit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      flex-direction: column;
      font-size: 14px;
      button {
        font-size: 14px;
        margin: 20px;
      }
      .forgot {
        color: $intense-blue;
        font-size: 10px;
        text-decoration: none;
        font-weight: 600;
      }
      .signup {
        font-size: 14px;
        font-weight: 600;
        color: $soft-black;
        text-decoration: none;
        width: 141px;
        line-height: normal;
      }
      .website {
        color: $intense-blue;
      }
    }
  }
}
</style>

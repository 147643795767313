/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';
import NotificationService from '@/services/notifications.services';

const Notification = {
  namespaced: true,
  state: {
    notifList: null,
    latestList: null,
    connected: false,
    loading: false,
    disconnected: true,
    updated: false,
  },
  getters: {
    updated(state) {
      return state.updated;
    },
    notifList(state) {
      return state.notifList;
    },
    latestList(state) {
      return state.latestList;
    },
    connected(state) {
      return state.connected;
    },
    disconnected(state) {
      return state.disconnected;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    set_updated(state) {
      state.updated = true;
    },
    add_notif_list(state, item) {
      if (state.notifList) {
        state.notifList.unshift(item);
      }
      if (state.latestList) {
        state.latestList.unshift(item);
      }
    },
    set_all_notif_list(state, items) {
      const list = items;
      state.notifList = cloneDeep(list);
    },
    set_latest_notif_list(state, items) {
      const list = items;
      state.latestList = cloneDeep(list);
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_loading_notif(state, param) {
      for (let i = 0; i < state.notifList.length; i += 1) {
        const element = state.notifList[i];
        if (element.id === param.notifId) {
          element.loading = param.loading;
        }
      }
      for (let i = 0; i < state.latestList.length; i += 1) {
        const element = state.latestList[i];
        if (element.id === param.notifId) {
          element.loading = param.loading;
        }
      }
    },
    set_readed_notif(state, notifId) {
      for (let i = 0; i < state.notifList.length; i += 1) {
        const element = state.notifList[i];
        if (element.id === notifId) {
          element.readAt = true;
        }
      }
      for (let i = 0; i < state.latestList.length; i += 1) {
        const element = state.latestList[i];
        if (element.id === notifId) {
          element.readAt = true;
        }
      }
    },
  },
  actions: {
    async fetchNotifications(context) {
      context.commit('set_loading', true);
      const response = await NotificationService.getAllNotifications();
      context.commit('set_loading', false);
      if (response) {
        context.commit('set_loading', false);
        context.commit('set_all_notif_list', response);
      }
    },

    async getLatestNotifications(context) {
      context.commit('set_loading', true);
      context.commit('set_updated');

      const response = await NotificationService.getLatestNotifications();
      context.commit('set_loading', false);
      if (response) {
        context.commit('set_loading', false);
        context.commit('set_latest_notif_list', response);
      }
    },

    async setReadedNotifications(context, notifId) {
      context.commit('set_loading_notif', {
        notifId,
        loading: true,
      });
      const response = await NotificationService.setReadedNotifications(notifId);
      context.commit('set_loading_notif', {
        notifId,
        loading: false,
      });
      if (response === null) {
        context.commit('set_readed_notif', notifId);
      }
    },

    async sendNotifications(context, param) {
      const response = await NotificationService.sendNotification(param);
      if (param.confirm) {
        if (response && !response.code) {
          Message({
            showClose: true,
            message: 'Message sent',
            type: 'success',
            duration: 5000,
          });
        } else {
          Message({
            showClose: true,
            message: 'Oups, message failed.',
            type: 'warning',
            duration: 5000,
          });
        }
      }
    },

    addNotifList({ commit }, param) {
      commit('add_notif_list', param);
    },
  },
};

export default Notification;

/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <el-input
    v-model="search"
    class="search-games"
    size="mini"
    prefix-icon="el-icon-search"
    placeholder="Search in list"
    clearable
    data-test-id="gamesListSearchInput"
  ></el-input>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'ViewBar',
  components: {},
  data() {
    return {};
  },
  computed: {
    search: {
      get() {
        return cloneDeep(this.$store.getters['views/term']) || '';
      },
      set(value) {
        this.$store.dispatch('views/setTerm', value);
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.search-games {
  width: auto;
  min-width: 80px;
  // .el-input--suffix .el-input__inner {
  //   padding-right: 10px !important;
  // }
  // .input {
  //   padding-right: 10px !important;
  // }
}
//
</style>

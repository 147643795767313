<template>
  <div class="video-card">
    <el-popover
      v-if="displayVideo"
      placement="right"
      width="400"
      trigger="hover"
      @after-enter="videoEnter(`video-${video.id}`)"
      @after-leave="videoLeave(`video-${video.id}`)">
      <video
        :id="`video-${video.id}`"
        width="100%"
        controls
        muted="true"
        loop>
        <source :src="video.video" type="video/mp4" />
      </video>
      <video
        v-if="video.video"
        slot="reference"
        class="video"
        width="44"
        :autoplay="false"
        data-test-id="gameInfosVideo"
      >
        <source :src="video.video !== null ? video.video.replace('?', '%3F'): null" />
      </video>
    </el-popover>

    <div class="video-infos">
      <span class="title">
        <span v-if="video.adNetwork && video.adNetwork !== 'global'" class="adnetwork-icon" :class="'icon-' + video.adNetwork.toLowerCase()"></span>
        #{{video.perf}} Performing video</span>
      <div class="video-bottom">
        <span
          v-if="videoName"
          class="video-name"
          data-test-id="gameInfosVideoName"
        >{{videoName}}</span>
        <span
          class="video-download"
          data-test-id="gameInfosVideoDownload"
          @click="goto(!!isManagedByVoodoo ? `${video.video}?original` : `${video.video}`)"
        >
          <font-awesome-icon icon="cloud-download" class="icon"/>
          Download</span>
      </div>
    </div>
    <div v-if="video.adultContent" class="overlay">
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <div class="tooltipKS">
            Your ad content violates Facebook Ad Guidelines. Ads are not allowed to promote the sale or use of adult products or services,
            including toys, videos, publications, live shows or sexual enhancement products.
            Ads for family planning and contraception are allowed if they follow our targeting requirements.Before resubmitting your ad,
            please visit the Help Center to learn more and see examples of ads that meet our guidelines.
            If you’ve read the guidelines in the Help Center and think your ad follows the rules and should have been approved, please let us know.
          </div>
        </div>
        <i class="el-icon-info"></i>
      </el-tooltip> Adult content
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoCard',
  components: {
  },
  props: {
    video: {
      type: Object,
      default: null,
    },
    isManagedByVoodoo: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      displayVideo: true,
    };
  },
  computed: {
    videoName() {
      return this.video.fileName ? this.video.fileName.replace(/\[(.*?)\]/g, '') : '-';
    },
  },
  watch: {
    video() {
      this.displayVideo = false;
      this.$nextTick(() => {
        this.displayVideo = true;
      });
    },
  },
  methods: {
    videoEnter(value) {
      const video = document.getElementById(value);
      video.play();
    },
    videoLeave(value) {
      const video = document.getElementById(value);
      video.pause();
    },
    goto(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  display:flex;
  gap: 10px;
  .video {
    border-radius: 5px;
    height: 100%;
    background: $soft-black;
    &:hover {
      cursor:pointer;
    }
  }
  .overlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: -20px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: $slate;
    background-color: $transparent-white;
    .el-icon-info {
    color: $cloudy-blue;
    margin-right: 5px;
    }
  }
  .video-infos {
    display:flex;
    gap: 20px;
    flex-direction:column;
    justify-content: space-between;
     .data {
      .label {

        font-size: 14px;
        color: $grey;
      }
      .kpi {

        font-size: 18px;
        font-weight: 600;
        color: $soft-black;
      }
    }
    .video {
      width: 200px;
      margin-top: 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      height: -webkit-fill-available;
      background-color: $soft-black;
      .video-infos {
        display:flex;
      }
    }
    .title {

      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $slate;
      display: flex;
      .adnetwork-icon {
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px;
        margin: 0px 9px 0px 0px;
        top: 0;
        right: 0;
        &.icon-facebook {
          background-image: url("~@/assets/images/icon-facebook.svg");
        }
        &.icon-snapchat {
          background-image: url("~@/assets/images/icon-snapchat.svg");
        }
      }
    }
    .video-name {

      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }
    .video-download {

      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $light-blue;
      &:hover {
        cursor: pointer;
      }
    }
    .video-bottom {
      display:flex;
      flex-direction: column;
    }
  }
}
</style>

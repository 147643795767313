<template>
  <el-tooltip
    v-if="recommendationMapping[recommendationKey]"
    popper-class="tooltip-proto-score"
    placement="top">
    <label
      :class="[recommendationMapping[recommendationKey].class, small?'small':'']"
      class="protoLabel"
      data-test-id="gameInfosScoreLabel"
    >
      <font-awesome-icon :icon="recommendationMapping[recommendationKey].icon" class="icon" :class="isMini ? '' : 'with-text'" />
      <template
        v-if="!isMini"
      >{{ recommendationMapping[recommendationKey].label }}</template>
    </label>
    <div slot="content">
      <div v-if="displayDetails" class="tooltip">
        <h1 data-test-id="gameInfosRecommendation" :class="recommendationMapping[recommendationKey].class">{{ recommendation }}</h1>
        <template v-if="recommendationMapping[recommendationKey].displayScore">
          <p v-for="score in scoreValues" :key="score.label" data-test-id="gameInfosScore">
            <label>{{ score.label }}</label>: {{ score.value ? (+score.value).toFixed(1) : 'N/A' }}
          </p>
        </template>
      </div>
      <div
        v-else
        class="tooltip"
        data-test-id="gameInfosScoreTooltip"
        v-html="recommendationMapping[recommendationKey].details">
      </div>
    </div>
  </el-tooltip>
</template>

<script>
const { recommendationMapping } = require('./data');

export default {
  name: 'ProtoScore',
  props: {
    recommendation: {
      type: String,
      required: true,
    },
    displayDetails: {
      type: Boolean,
      default: false,
    },
    scoreValues: {
      type: Array,
      default() { return []; },
    },
    isMini: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recommendationMapping,
    };
  },
  computed: {
    recommendationKey() {
      return this.recommendation.toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.el-tooltip__popper.tooltip-proto-score {
  background: $tooltip-background-dark;
  padding: 15px;
  color: $white;
  font-weight: bold;
  max-width: 350px;
  border-radius: 5px;

  &[x-placement^="top"] .popper__arrow::after {
    border-top-color: $tooltip-background-dark;
  }

  &[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: $tooltip-background-dark;
  }

  .tooltip p {
    line-height: 18px;
  }
}
</style>

<style lang="scss" scoped>
label,h1 {
  font-weight: 800;
  font-size: 12px;

  &.high {
    color: $green-alert;
  }
  &.low {
    color: $orange-alert;
  }
  &.none {
    color: $grey;
  }
}

label {
  cursor: pointer;
  line-height: 12px;

  svg.with-text {
    margin-right: 4px;
  }

  &.small {
    font-size: 10px;
  }
}

h1 {
  text-align: left;
  margin: 0;
}

p {
  text-align: left;
  margin: 0;
  color:$white;

  &:first-of-type {
    margin-top: 15px;
  }

  label {
    text-transform: capitalize;
    font-size: 12px;
  }
}

.protoLabel {
  display: flex;
  align-items: center;
}
</style>

<template>
  <el-input
    v-model="search"
    :class="$style.inputText"
    size="mini"
    prefix-icon="el-icon-search"
    placeholder="Search in list"
    clearable
    data-test-id="gamesListFilterInput"
  ></el-input>
</template>

<script>

export default {
  name: 'SearchTerm',
  computed: {
    search: {
      get() {
        return this.$store.getters['gamesList/params'].term || '';
      },
      set(value) {
        this.$store.commit('gamesList/setTerm', value);
      },
    },
  },
};
</script>

<style lang="scss" module>
.inputText {
  width: auto;
  min-width: 80px;
}
</style>

/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import GamesService from '@/modules/GamesList/store/games.services';

const GamesList = {
  namespaced: true,
  state: {
    games: [],
    loading: false,
    params: {
      limit: 20,
      offset: 0,
      sort: 'lastIterationDate',
      order: 'desc',
      term: '',
      query: {
        logicalOperator: 'all',
        children: [{
          type: 'query-builder-rule',
          query: {
            rule: 'studioId',
            operator: 'equals',
            value: null,
          },
        }],
      },
    },
  },
  getters: {
    games(state) {
      return state.games;
    },
    loading(state) {
      return state.loading;
    },
    params(state) {
      return state.params;
    },
  },
  mutations: {
    addGames(state, games) {
      const oldGames = cloneDeep(state.games);
      const newGames = oldGames.concat(games);
      state.games = newGames;
    },
    resetList(state) {
      state.games = [];
      state.params = {
        ...state.params,
        offset: 0,
      };
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSort(state, sort) {
      state.params = {
        ...state.params,
        order: sort.order,
        sort: sort.prop,
      };
    },
    setTerm(state, term) {
      state.params = {
        ...state.params,
        term,
      };
    },
    setStudioId(state, studioId) {
      const params = state.params;
      params.query.children[0].query.value = studioId;
      state.params = params;
    },
    setOffset(state) {
      const params = state.params;
      params.offset += params.limit;
      state.params = params;
    },
  },
  actions: {
    async fetchGames(context, studioId) {
      context.commit('setLoading', true);
      context.commit('setStudioId', studioId);
      const games = await GamesService.fetchGames(context.state.params);
      context.commit('setOffset');
      context.commit('setLoading', false);

      if (games) {
        context.commit('addGames', games);
      }

      return games;
    },
  },
};

export default GamesList;

/**
 * Inspired by vue-mq, but mostly rewritten
 * Single method to know if a given rule matches a breakpoint
 *
 * @see https://github.com/AlexandreBonaventure/vue-mq
 */
import { BREAKPOINTS } from '@/tools/breakpoints';

const BP_NAMES = Object.keys(BREAKPOINTS);

/**
  *
  * @param {String} bp
  * @param {String} bp.modifier  - A sign : + include bigger bps, - include lower bps
  * @return {Array}              - An array of matching breakpoint names
  */
const getBreakpoints = ({ bp, modifier }) => {
  if (!modifier) return [bp];

  const index = BP_NAMES.indexOf(bp);

  return modifier === '+'
    ? BP_NAMES.slice(index)
    : BP_NAMES.slice(0, index + 1);
};

const ruleToBreakpoints = (rule) => {
  if (Array.isArray(rule)) return rule;

  const modifier = rule.slice(-1);

  if (['+', '-'].includes(modifier)) {
    return getBreakpoints({
      modifier,
      bp: rule.slice(0, -1),
    });
  }

  return getBreakpoints({ bp: rule });
};

/**
  * Return true if `bp` matches `rules`
  *
  * @param {String} bp           - Current bp, or bp to test
  *
  * @param {String|Array} rules  - Rule according to syntax below
  *    Array : Limit render to these breakpoints only
  *    String: single breakpoint : Limit render to this breakpoint only
  *    String: single breakpoint with modifier `+` or `-` : Limit to breakpoint equal or larger / smaller
  *
  * @return {Bool}
  */
export default function bpMatch(bp, rules) {
  return ruleToBreakpoints(rules).includes(bp);
}

<template>
  <div v-if="project && !loading" :class="$style.wrapper">
    <ProjectHeader :project="project" :class="$style.header"/>
    <div :class="$style.tableHeader">
      <div>Game</div>
      <div>Ad Network</div>
      <div>CPI</div>
      <div>D1</div>
      <div>Cumulative D3 playtime</div>
    </div>
    <div
      v-if="isMobile"
      :class="$style.tableBody"
    >

      <HeaderCompactStudioGameCard
        v-for="(game, index) in project.games"
        :key="index"
        :item="game"></HeaderCompactStudioGameCard>


    </div>
    <div
      v-else
      :class="$style.tableBody"
    >
      <ListItem
        v-for="(game, index) in project.games"
        :key="index"
        :game="game"
        :status="game.status"/>
    </div>
  </div>
  <div v-else>
    <div :class="$style.loading">
      <span>
        <i class="el-icon-loading"></i>
      </span>
    </div>
  </div>
</template>

<script>
import HeaderCompactStudioGameCard from '@/components/HeaderCompactGameCard.vue';
import ListItem from './GameProjectListItem.vue';
import ProjectHeader from './GameProjectHeader.vue';

export default {
  name: 'GameProjectList',
  components: {
    ListItem,
    ProjectHeader,
    HeaderCompactStudioGameCard,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/me'] || null;
    },
    project() {
      return this.$store.getters['Project/project'] || null;
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
  },
  watch: {
    id() {
      this.getProject();
    },
  },
  mounted() {
    this.getProject();

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    async getProject() {
      this.loading = true;
      await this.$store.dispatch('Project/getProject', this.id).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  text-align: left;
}

.tableHeader {
  font-family: OpenSans;
  display: grid;
  grid-template-columns: 370px repeat(3, 0.7fr) 1fr 1.5fr;
  width: 100%;
  background-color: $cloudy-blue;
  padding: $spacing 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: left;
  font-weight: 800;
  font-size: 12px;
  @media screen and (max-width: 700px) {
  grid-template-columns: 100px repeat(3, 0.7fr) 1fr 1.5fr
  }
}

.tableHeader > div:first-of-type {
    padding-left: $spacing * 2;
}

.tableBody {
  width: 100%;
  background-color: $white;
}

.header {
    margin-bottom: $spacing * 3;
}
</style>

<template>
  <div :class="$style.container">
    <h2 id="title-facebookSetup">
      {{title}}
    </h2>
    <div :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <p :class="$style.title">Facebook Ad Account ID</p>
      <p :class="$style.subtext">
        In the Advertising Accounts section, add your Facebook Ad Account ID.
      </p>
      <CopyToClipboard v-if="game" data-test-id="gameSubmissionFacebookAdAccountId" :text="gameAdAccount" />
      <template v-if="game.isManagedByVoodoo">
        <p :class="$style.title">Request Access to your Facebook App</p>
        <p :class="$style.subtext">
          Enter your App ID below, then request access.
        </p>
        <FacebookAppAccess
          :game="game"
          :disabled="!canRequestAppAccess"
          @fb-id="updateID"
          @isValid="setFacebookAppAccessValidation"
        />
      </template>
      <ImportantNotice
        v-if="warningContent && warningContent.show"
        :class="$style.notice"
        :content="warningContent && warningContent.text"/>
      <el-button
        id="validate-facebook"
        type="primary"
        size="small"
        :disabled="!canRequestAppAccess || !!(game.isManagedByVoodoo && !isValidFacebookAppId)"
        :class="$style.validateBtn"
        data-test-id="gameSubmissionFacebookSetupValidate"
        @click="validateStep(index)"
      >
        <span>Validate</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from '@/components/CopyToClipboard/CopyToClipboard.vue';
import ImportantNotice from '@/components/Generic/ImportantNotice/ImportantNotice.vue';
import tools from '@/tools/tools';
import FacebookAppAccess from './FacebookAppAccess.vue';

export default {
  components: {
    CopyToClipboard,
    FacebookAppAccess,
    ImportantNotice,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'no name',
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
    warning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      facebookAppId: this.game ? this.game.facebookAppId : null,
      isIntersecting: false,
      isvisible: false,
      isFacebookAppIdValid: false,
    };
  },
  computed: {
    noticeContent() {
      return 'Warning: Facebook introduced new requirements to release your Facebook Apps. Carefully follow the steps to your right.';
    },
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
    canRequestAppAccess() {
      return (
        this.gamePermissions.includes('request_application_access')
        || this.myPermissions.includes('request_application_access')
      );
    },
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    gameAdAccount() {
      if (
        this.game?.adNetworks?.facebook
        && !this.game?.adNetworks?.facebook?.adAccountId
      ) {
        return '';
      }
      return this.game.adNetworks.facebook.adAccountId;
    },
    isValidFacebookAppId() {
      return this.facebookAppId && [15, 16, 17].includes(this.facebookAppId.length);
    },
    warningContent() {
      if (!this.warning) {
        return {
          show: false,
          text: '',
        };
      }
      return {
        text: this.warning.facebookSetup,
        show: this.warning.facebookSetupWarningIsVisible,
      };
    },
  },
  mounted() {
    tools.attachEvent(document.getElementById('container-scrollable'), 'scroll', this.update);
    tools.attachEvent(window, 'resize', this.update);
  },
  methods: {
    update() {
      const titleElement = document.getElementById('title-facebookSetup');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    updateID(value) {
      if (value) {
        this.facebookAppId = value;
      }
    },
    validateStep(index) {
      this.$emit('isValid', index);
    },
    setFacebookAppAccessValidation(value) {
      this.isFacebookAppIdValid = value;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  padding-top: 0;
  position: relative;
   .layer{
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.title {
  margin-top: $spacing * 3;
  font-weight: 600;
}

.subtext {
  color: $grey;
  line-height: 1;
  margin-bottom: $spacing;
}

.validateBtn {
  margin-top : $spacing * 3;
}

.notice {
  margin-top : $spacing * 3;
}
</style>

import Vue from 'vue';
import { Message } from 'element-ui';
import tools from '@/tools/tools';
import MessageError from '@/plugins/ErrorMessage';

const displayErrorMessage = (errorList, gameId, operation, content) => {
  const error = {};
  let message = `${operation.operationType} failed - ${content}`;
  error.message = message;
  if (content && content.code) {
    const currentError = errorList[content.code];
    message = currentError ? currentError.message : `${operation.operationType} failed - ${content.code}`;
    error.message = message;
    for (const [extraKey, extraValue] of Object.entries(content.extra || {})) {
      error.message = error.message.replace(new RegExp(`\\{\\{ ?${extraKey} ?\\}\\}`, 'gi'), extraValue);
    }
    if (content.actionable_link) {
      error.actionable_link = content.actionable_link;
    }
  }
  if (gameId) {
    error.resolveProperties = {
      type: 'game',
      id: gameId,
    };
  }

  MessageError.add(error);
};

const GameOperations = {
  namespaced: true,
  state: {
    list: {},
  },
  getters: {
    list(state) {
      return state.list;
    },
    getGameOperation(state) {
      return gameId => state.list[gameId];
    },
  },
  mutations: {
    set_for_game(state, { gameId, operation, errorList }) {
      Vue.set(state.list, gameId, { ...operation });
      if (['error', 'rejected'].includes(operation.status)) {
        tools.decodeError(operation.content).map(err => displayErrorMessage(errorList, gameId, operation, err));
      }
      if (operation.status === 'success') {
        Message({
          message: `<strong>Action <i style="color:blue;"></i></strong> done! ${operation.content}`,
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 5000,
          showClose: true,
        });
      }
    },
  },
  actions: {
    setForGame(context, { gameId, operation }) {
      const errorList = context.rootState.users.codification.errorList;
      context.commit('set_for_game', {
        gameId,
        operation,
        errorList,
      });
      if (operation.operationType === 'Request Testing') {
        context.dispatch('submitGame/getCampaignCreationStatus', gameId, { root: true });
      }
    },
  },
};

export default GameOperations;

<template>
  <div
    :class="[$style.container, $style[`variation-${color}`]]"
    data-test-id="gameInfosKpiBigVariation"
  >
    <font-awesome-icon
      :class="
        $style.icon"
      :icon="icon"
      :style="{ transform: `rotateZ(${variationMapping[type].angle}deg)` }"
    />
    <span v-if="unitBefore && !displayValue">{{unitToDisplay}}</span>
    {{ valueToDisplay.toLocaleString() }}
    <span v-if="!unitBefore && !displayValue">{{unitToDisplay}}</span> since the last iteration
  </div>
</template>

<script>
export default {
  name: 'Variations',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    variation: {
      type: [String, Number],
    },
    isBetter: {
      type: Boolean,
      default: null,
    },
    unit: {
      type: String,
      default: '',
    },
    unitBefore: {
      type: Boolean,
      default: false,
    },
    displayValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      variationMapping: {
        increase: {
          angle: '45',
          icon: 'arrow-up',
          sign: '+',
        },
        decrease: {
          angle: '135',
          icon: 'arrow-up',
          sign: '-',
        },
        equals: {
          angle: '0',
          icon: 'equals',
          sign: '',
        },
      },
    };
  },
  computed: {
    valueToDisplay() {
      let valueToDisplay = '';
      if (this.variation === 0) {
        valueToDisplay = 'Equal';
      } else {
        valueToDisplay = this.displayValue ? this.variationMapping[this.type].sign + this.displayValue : this.variationMapping[this.type].sign + Math.abs(this.variation);
      }
      return valueToDisplay;
    },
    unitToDisplay() {
      return this.variation === 0 ? '' : this.unit;
    },
    type() {
      // eslint-disable-next-line no-nested-ternary
      return this.variation > 0
        ? 'increase'
        : this.variation < 0
          ? 'decrease'
          : 'equals';
    },
    color() {
      // eslint-disable-next-line no-nested-ternary
      return this.isBetter === true
        ? 'better'
        : this.isBetter === false
          ? 'worse'
          : 'equals';
    },
    icon() {
      // eslint-disable-next-line no-nested-ternary
      return this.isBetter ? 'arrow-up' : this.type === 'equals' ? 'equals' : 'arrow-up';
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: inline-block;
  padding: $spacing;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}

.icon {
  margin-right: $spacing;
}

.variation-better {
  background-color: $light-green;
  color: $green-alert;
}
.variation-worse {
  background-color: $light-red;
  color: $red-alert;
}

.variation-equals {
  background-color: $foggy-grey;
  color: $slate;
}
</style>

/* eslint-disable no-undef */
import Vue from 'vue';

export default class CookiesService {
  static setToken(value) {
    $cookies.set('game_stats_page_token', value);
    // $cookies.set('game_stats_page_token', value);
    Vue.localStorage.set('game_stats_page_token', value);
  }

  static setRefreshToken(value) {
    $cookies.set('game_stats_page_refreshToken', value);
    Vue.localStorage.set('game_stats_page_refreshToken', value);
  }

  static deleteToken() {
    $cookies.remove('game_stats_page_token');
    Vue.localStorage.remove('game_stats_page_token');
  }

  static deleteRefreshToken() {
    $cookies.remove('game_stats_page_refreshToken');
    Vue.localStorage.remove('game_stats_page_refreshToken');

    if (window.location.pathname !== '/login') {
      window.location = '/login';
    }
  }

  static getToken() {
    return (
      $cookies.get('game_stats_page_token') || Vue.localStorage.get('game_stats_page_token') || null
    );
  }

  static getRefreshToken() {
    return (
      $cookies.get('game_stats_page_refreshToken')
      || Vue.localStorage.get('game_stats_page_refreshToken')
      || null
    );
  }

  static setSortPref(value) {
    $cookies.set('sorted_pref', value);
  }

  static getSortPref() {
    return $cookies.get('sorted_pref');
  }

  static setBestPref(value) {
    if (value === true) {
      $cookies.set('best_pref', '1');
    } else {
      $cookies.set('best_pref', '0');
    }
  }

  static getBestPref() {
    return $cookies.get('best_pref');
  }

  static setFilterPref(value) {
    $cookies.set('filter_pref', JSON.stringify(value));
  }

  static getFilterPref() {
    const r = JSON.parse(`${$cookies.get('filter_pref')}`);
    return r;
  }

  static async logout() {
    this.deleteToken();
    this.deleteRefreshToken();
    // await new Promise(resolve => setTimeout(resolve, 2900));
  }
}

<template>
  <div>
    <CardPage v-if="me">
      <template v-slot:left>
        <div class="user-main-infos">
          <div class="user-picture">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="user-name">
            <span class="name" data-test-id="profilePageName">{{ me.firstName }} {{ me.lastName }}</span>
            <span
              class="since"
            >Created on <span data-test-id="profilePageCreatedOn">{{ me.lastLogin | localeDate }}</span></span
            >
          </div>
        </div>
      </template>
      <template v-slot:right>
        <el-alert
          class="alert-info"
          title="For internal use only. Your personal information will not be shared with anyone."
          type="info"
          :closable="false"
          show-icon
        ></el-alert>
      </template>
      <template v-slot:page>
        <div class="page-content">
          <div class="section">
            <h2>Personal information</h2>
            <div class="sub-section">
              <div class="field">
                <span class="label">Firstname</span>
                <el-input v-model="firstName" data-test-id="profilePageFirstNameInput"></el-input>
              </div>
              <div class="field">
                <span class="label">Lastname</span>
                <el-input v-model="lastName" data-test-id="profilePageLastNameInput"></el-input>
              </div>
            </div>
          </div>
          <div class="section">
            <h2>Account information</h2>
            <div class="sub-section">
              <div class="field">
                <span class="label">Email</span>
                <el-input v-model="email" data-test-id="profilePageEmailInput"></el-input>
              </div>
              <div class="field">
                <span class="label">Password</span>
                <el-button
                  type="primary"
                  plain
                  data-test-id="profilePageChangePasswordButton"
                  @click="passwordModal = !passwordModal">
                  <i class="el-icon-edit"></i>Change password
                </el-button>
              </div>
            </div>
          </div>
          <div class="section save">
            <el-button
              type="primary"
              icon="el-icon-edit"
              :loading="loading"
              :disabled="!!!canSave"
              data-test-id="profilePageSaveChangesButton"
              @click="saveProfile">Save changes
            </el-button>
          </div>
        </div>
      </template>
    </CardPage>
    <el-dialog
      title="Edit your password"
      :modal-append-to-body="true"
      :visible.sync="passwordModal"
      width="760px"
      data-test-id="profilePagePasswordResetModal"
      @close="resetForm"
    >
      <div class="edit-password">
        <form>
          <div class="field">
            <span class="label">Current password:</span>
            <el-input
              v-model="password.current"
              placeholder="Current password"
              type="password"
              data-test-id="profilePageCurrentPasswordInput"
            ></el-input>
          </div>
          <div class="field">
            <span class="label">New password:</span>
            <el-input
              v-model="password.new"
              placeholder="New password"
              type="password"
              data-test-id="profilePageNewPasswordInput"
            ></el-input>
          </div>
          <div class="gaug">
            <el-progress
              :show-text="false"
              :percentage="passwordScore"
              :color="customColors"
              data-test-id="profilePagePasswordSafetyProgress"
            ></el-progress>
          </div>
          <div class="field">
            <div slot="content">
              <p v-if="!passwordContainsAtLeast8Chars" class="password-requirement-row">
                Must be minimum 8 characters long
              </p>
              <p v-if="!passwordContainsLowercaseLetter" class="password-requirement-row">
                Must contain at least 1 lowercase letter
              </p>
              <p v-if="!passwordContainsUppercaseLetter" class="password-requirement-row">
                Must contain at least 1 uppercase letter
              </p>
              <p v-if="!passwordContainsDigit" class="password-requirement-row">
                Must contain at least 1 digit
              </p>
            </div>
          </div>
          <div class="field">
            <span class="label">Confirm password:</span>
            <el-input
              v-model="password.confirm"
              placeholder="Confirm password"
              type="password"
              data-test-id="profilePageConfirmPasswordInput"
            ></el-input>
          </div>
        </form>
        <el-alert
          v-if="errorMessage"
          :title="errorMessage"
          type="error"
          :closable="false"
          show-icon
          data-test-id="profilePageChangePasswordErrorMessage"
        ></el-alert>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button data-test-id="profilePageChangePasswordCancelButton" @click="passwordModal = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          skin="blue"
          :loading="loadingPass"
          :disabled="!!!canUpdatePassword"
          data-test-id="profilePageChangePasswordSaveButton"
          @click="savePassword()">
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import strength from 'strength';
import staticParam from '@/../json/static.json';
import CardPage from '@/components/CardPage.vue';
import { getErrorDisplay } from '@/tools/errors';

export default {
  name: 'StudioService',
  components: {
    CardPage,
  },
  props: {},
  data() {
    return {
      staticParam,
      canSave: false,
      passwordModal: false,
      password: {
        current: '',
        new: '',
        confirm: '',
      },
      customColors: [
        {
          color: '#FC4E49',
          percentage: 20,
        },
        {
          color: '#FCB549',
          percentage: 40,
        },
        {
          color: '#41B149',
          percentage: 60,
        },
        {
          color: '#41B149',
          percentage: 80,
        },
        {
          color: '#49BEFC',
          percentage: 100,
        },
      ],
    };
  },
  computed: {
    passwordScore() {
      const howStrong = strength(this.password.new);
      const final = (howStrong * 100) / 4;
      return final > 80 ? 100 : final;
    },
    passwordContainsAtLeast8Chars() {
      return this.password.new.length >= 8;
    },
    passwordContainsLowercaseLetter() {
      return /[a-z]/.test(this.password.new);
    },
    passwordContainsUppercaseLetter() {
      return /[A-Z]/.test(this.password.new);
    },
    passwordContainsDigit() {
      return /\d/.test(this.password.new);
    },
    errorMessage() {
      if (
        this.password.confirm
        && this.password.new
        && this.password.confirm !== this.password.new
      ) {
        return 'New and confirm password are not the same :/';
      }
      return '';
    },
    canUpdatePassword() {
      if (
        this.password.current
        && this.password.new
        && this.password.confirm
        && this.password.new === this.password.confirm
        && this.passwordContainsAtLeast8Chars
        && this.passwordContainsLowercaseLetter
        && this.passwordContainsUppercaseLetter
        && this.passwordContainsDigit
      ) {
        return true;
      }
      return false;
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    meForm() {
      return cloneDeep(this.$store.getters['users/meForm']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['users/loading']) || null;
    },
    loadingPass() {
      return cloneDeep(this.$store.getters['users/loadingPass']) || null;
    },
    firstName: {
      get() {
        return cloneDeep(this.$store.getters['users/meForm']).firstName;
      },
      set(value) {
        const firstName = {
          firstName: value,
        };
        this.$store.dispatch('users/updateMeForm', firstName);
      },
    },
    lastName: {
      get() {
        return cloneDeep(this.$store.getters['users/meForm']).lastName;
      },
      set(value) {
        const lastName = {
          lastName: value,
        };
        this.$store.dispatch('users/updateMeForm', lastName);
      },
    },
    email: {
      get() {
        return cloneDeep(this.$store.getters['users/meForm']).email;
      },
      set(value) {
        const email = {
          email: value,
        };
        this.$store.dispatch('users/updateMeForm', email);
      },
    },
  },
  watch: {
    meForm() {
      const me = JSON.stringify(this.me);
      const meForm = JSON.stringify(this.meForm);
      this.canSave = me !== meForm;
    },
  },
  methods: {
    resetForm() {
      this.password = {
        current: '',
        new: '',
        confirm: '',
      };
    },
    saveProfile() {
      const user = {
        firstName: this.meForm.firstName,
        lastName: this.meForm.lastName,
        email: this.meForm.email,
        profile: this.meForm.profile,
      };
      this.$store.dispatch('users/updateUser', user);
    },
    async savePassword() {
      await this.$store.dispatch('users/changePassword', this.password).then((response) => {
        if (response === null) {
          this.passwordModal = false;
          this.$message({
            message: 'your password has been successfully changed',
            type: 'success',
          });
        } else {
          const errorMessages = {
            403: 'error.login.wrongpassword',
          };

          this.$message({
            message: getErrorDisplay({ reason: errorMessages[response.code] }),
            type: 'error',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.main {
  justify-content: center;
}
.card-header-right {
  margin-right: 20px;
}

.edit-password {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    margin-bottom: 10px;
  }
  .error {
    color: $blood;
  }
  form {
    width: 100%;
  }
  .field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;
    .password-requirement-row {
      color: $darker-grey;
    }
    .label {
      margin-bottom: 10px;
    }
    .el-input {
      margin-left: 0;
      margin-right: 10px;
      width: 100%;
    }
  }
  .gaug {
    padding: 10px 0;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

.user-main-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-picture {
    background-color: #e9e9f0;
    padding: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
  }
  .user-name {
    display: flex;
    flex-direction: column;
    text-align: left;
    .name {
      margin-bottom: 5px;
    }
    .since {
      font-size: 13px;
      color: #adadad;
    }
  }
}

.page-content {
  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    &.save {
      margin-top: 30px;
      align-items: flex-end;
    }
    h2 {
      margin-bottom: 20px;
    }
    .sub-section {
      display: flex;
      width: 70%;
      margin-bottom: 10px;
      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 10px;
        .label {
          margin-bottom: 10px;
        }
        .el-input {
          margin-left: 0;
          margin-right: 10px;
          width: 100%;
        }
      }
    }
  }
}

.studio-service {
  .main {
    margin: 30px;
    padding: 0;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .page-content {
    padding: 30px;
  }
  .left {
    // max-width: 260px;
    display: flex;
    align-items: center;

    input {
      &.start-date,
      &.end-date {
        margin-right: 5px;
        height: 40px;
        border: 1px solid #dcdfe6;
        color: dimgrey;
        border-radius: 3px;
        background-color: #fff;
      }
    }
  }
  .right {
    .el-input {
      width: 120px;
      .el-input--suffix .el-input__inner {
        padding-right: 5px;
      }
    }
  }
}
</style>

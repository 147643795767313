<template>
  <div class="studio-creation">
    <Form
      :form-configuration="formStudioCreation"
      :form-data="formData"
      :is-form-submission-successfull="isFormSubmissionSuccessfull"
      :forced-current-step="forcedCurrentStep"
      @submit-form="submitForm">
      <template v-slot:formTitle>
        <h1 class="title">Studios creation</h1>
        <p class="subtitle">* Mandatory</p>
      </template>
      <template v-slot:formSubmitted>
        <p v-if="submissionStatus === 'success'">
          The studio have been successfully created.<br /><br />
          <el-button
            size="small"
            @click="submitAnotherOne">
            Submit another one
          </el-button>
        </p>
        <p v-if="submissionStatus === 'failure'">An error occured, please try again.<br />
          {{ errorMessage }}<br /><br />
          <el-button
            size="small"
            @click="returnToTheForm">
            Back to the form
          </el-button>
        </p>
      </template>
    </Form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axios from '@/tools/axios';
import Form from '@/components/Generic/Form.vue';
import formStudioCreation from '@/../json/formStudioCreation.json';

export default {
  name: 'StudioCreationForm',
  components: { Form },
  data() {
    return {
      formStudioCreation,
      formData: {},
      forcedCurrentStep: -1,
      isFormSubmissionSuccessfull: false,
      submissionStatus: '',
      errorMessage: '',
    };
  },
  computed: {
    permissions() {
      return this.$store.getters['users/myPermissions'];
    },
  },
  mounted() {
    if (!this.permissions || !this.permissions.includes('add_studio')) {
      this.$router.push({ name: 'root' });
    }
  },
  methods: {
    returnToTheForm() {
      this.isFormSubmissionSuccessfull = false;
      this.forcedCurrentStep = 0;
    },
    submitAnotherOne() {
      this.isFormSubmissionSuccessfull = false;
      this.forcedCurrentStep = 0;
      this.formData = {};
    },
    async submitForm() {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/outbound`,
        method: 'post',
        data: JSON.stringify(this.formData),
        headers: {
          'content-type': 'application/json',
        },
      }).then(() => {
        this.isFormSubmissionSuccessfull = true;
        this.submissionStatus = 'success';
      }).catch((e) => {
        this.isFormSubmissionSuccessfull = true;
        this.submissionStatus = 'failure';
        this.errorMessage = `${e.response.data.errorList.map(err => `Invalid value for entry: ${err.path}.`)}`;
      });
    },
  },
};
</script>

<style lang="scss">
.studio-creation {
  .subtitle {
    color:red;
  }
}
</style>

<template>
  <div class="important-notice">
    ⚠️ <span class="notice-title">Important notice:</span><br/>
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: 'ImportantNotice',
  props: {
    content: {
      default: null,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.important-notice {
  background-color: $background-important-notice;
  margin-bottom: 19px;
  padding: 15px;
  border-radius: 5px;
  line-height: normal;
  color: #000 !important;
  border: solid 1px $yellow-alert;
  b {
    color: #000 !important;
  }

  .notice-title {
    font-weight: 600;
    margin-left: 5px;
  }
}
</style>

import Vue from 'vue';
import VueMq from 'vue-mq';
import { BREAKPOINTS } from '@/tools/breakpoints';
import Bp from './bp';

Vue.use(VueMq, {
  breakpoints: BREAKPOINTS,
});

Vue.use(Bp);

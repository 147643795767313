import { Array } from 'core-js';

export default {
  data() {
    return {
      tags: [],
      tagsCategories: [
        {
          id: 'mechanic',
          label: 'Mechanic',
          tooltip: 'The overall action that the user has to do to play.',
          infos: ['You must add between 1 and 5 tags.', 'Each tag must be 3 to 15 alphanumeric characters long, and English only.'],
          placeholder: 'Enter mechanic tags',
          examples: ['jump', 'stack', 'collect', 'push', 'multiplier'],
          maxTags: 5,
          tags: this.game.tags.filter(tag => tag.category === 'mechanic').map(tag => tag.label),
          required: true,
        },
        {
          id: 'theme',
          label: 'Theme',
          tooltip: 'The subject on what the game concept is based on.',
          infos: ['You must add between 1 and 3 tags.', 'Each tag must be 3 to 15 alphanumeric characters long, and English only.'],
          placeholder: 'Enter theme tags',
          examples: ['sport', 'water', 'city', 'snow', 'physics'],
          maxTags: 3,
          tags: this.game.tags.filter(tag => tag.category === 'theme').map(tag => tag.label),
          required: true,
        },
        {
          id: 'toy',
          label: 'Toy',
          tooltip: 'The object with which the gamer interacts.',
          infos: ['You must add between 1 and 3 tags.', 'Each tag must be 3 to 15 alphanumeric characters long, and English only.'],
          placeholder: 'Enter toy tags',
          examples: ['crowd', 'ball', 'car', 'gun', 'stick', 'knife'],
          maxTags: 3,
          tags: this.game.tags.filter(tag => tag.category === 'toy').map(tag => tag.label),
          required: true,
          isSearchResultOnTop: true,
        },
        {
          id: 'other',
          label: 'Other',
          tooltip: 'If you want to add some info about your game. Not required.',
          infos: ['Optional, you can add 3 tags max.', 'Each tag must be 3 to 15 alphanumeric characters long, and English only.'],
          placeholder: 'Enter theme tags',
          examples: ['sport', 'water', 'city', 'snow', 'physics'],
          maxTags: 3,
          tags: this.game.tags.filter(tag => tag.category === 'other').map(tag => tag.label),
          required: false,
          isSearchResultOnTop: true,
        },
      ],
    };
  },
  computed: {
    disabled() {
      const inputDisabled = {};
      this.tagsCategories.map(a => a.id).forEach((element, ind) => {
        inputDisabled[element] = this.tagsCategories[ind].tags.length >= this.tagsCategories[ind].maxTags;
      });
      return inputDisabled;
    },
    isTagsReadyToSubmit() {
      let isready = true;
      // eslint-disable-next-line consistent-return
      this.tagsCategories.filter(a => a.required).forEach((element) => {
        if (this.tagsCategories.find(el => el.id === element.id).tags.length === 0) {
          isready = false;
        }
      });
      return isready;
    },
    tagsModel() {
      const obj = {};
      this.tagsCategories.map(a => a.id).forEach((element) => {
        obj[element] = this.filtersTagsByCategory(element);
      });
      return obj;
    },
  },
  methods: {
    filtersTagsByCategory(category) {
      if (!this.game || !Array.isArray(this.game.tags)) return [];
      return this.tagsCategories.find(el => (el.id === category)).tags;
    },
  },
};

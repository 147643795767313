import { cloneDeep } from 'lodash';
import axiosCancel from 'axios-cancel';
import axios from '@/tools/axios';

axiosCancel(axios, {
  debug: false, // default
});
export default class StudiosService {
  static async fetchStudios(params) {
    const p = cloneDeep(params);

    p.query = p.view.content.filter;
    p.term = p.view.content.term;

    delete p.startDate;
    delete p.endDate;
    delete p.status;
    delete p.date;
    if (p.view.content.sort) {
      p.sort = p.view.content.sort.prop;
      p.order = p.view.content.sort.order;
    }
    delete p.best;
    delete p.studios;
    delete p.gls;
    delete p.filter;
    delete p.view;
    const requestId = 'my_sample_request';

    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/studios`,
      data: p,
      // headers: {
      //   'Cache-Control': 'no-cache',
      // },
      method: 'post',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('request cancelled');
        } else {
          return { data: [] };
        }
      }); // .catch(() => ({ data: [] }));

    axios.cancel(requestId);
    return call;
  }

  static async getStudiosInfos(id) {
    const requestId = 'getStudiosInfos';
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/studio/${id}`,
      method: 'get',
      requestId,
      // headers: {
      //   'Cache-Control': 'no-cache',
      // },
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async updateStudioInfos(param) {
    const requestId = 'updateStudiosInfos';
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/studios/${param.id}`,
      method: 'put',
      requestId,
      data: param.data,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return null;
      })
      .catch(e => e.response);
    return call;
  }

  static async coachStudio(id) {
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/studio/${id}/attToCoached`,
      method: 'post',
      data: {},
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async setActivityState({ studioId, ...data }) {
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${studioId}/activity_state`,
      method: 'put',
      data,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async starStudio(param) {
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${param.id}/star`,
      method: 'put',
      data: {
        is_starred: param.value,
      },
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async coachedToAtt(param) {
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/studio/${param.studioId}/coachedToAtt`,
      method: 'post',
      data: {},
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async updateStudioPm(studioId, publishingManagerId) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${studioId}/publishing_manager`,
        method: 'put',
        data: { publishingManagerId },
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async updateStudioPm2(studioId, publishingManagerId) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${studioId}/secondary_publishing_manager`,
        method: 'put',
        data: { publishingManagerId },
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async getStudioEvents(id) {
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/studios/${id}/history`,
      method: 'get',
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return null;
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }
}

<template>
  <div class="game-view-detail">
    <div v-if="game" class="submit-game-form">
      <div class="main-content">
        <AsyncAction v-if="game" :game="game"></AsyncAction>
        <div v-if="showOnGoingOperation && isLoading" class="disabled-game">
          <div class="container-text">
            <span>There are ongoing operations</span>
            <span>
              <i class="el-icon-loading"></i>
            </span>
          </div>
        </div>
        <HeaderGameSubmit v-if="isSubmitflow && !isDisplayedOnStudioSide" :game="game"></HeaderGameSubmit>

        <div v-if="(isSubmitflow || isIterating) && !isDisplayedOnStudioSide" class="content-container submitflow">
          <FirstStep v-if="!game.id || substatus === 'create'" :game="game"></FirstStep>
          <Stepper v-if="game.id && substatus !== 'upload_asset' && substatus !== 'create'" :game="game"></Stepper>
          <LastStep  v-if="(substatus === 'upload_asset' || isIterating) && game.id " :game="game"></LastStep>
        </div>
        <div v-if="isPending && !isDisplayedOnStudioSide" class="content-container">
          <GamePendingApproval
            :id="'' + game.id"
            :game="game"
            :is-publishing="isPublishing"></GamePendingApproval>
        </div>
        <div v-if="(!isSubmitflow && !isIterating && !isPending) || isDisplayedOnStudioSide" class="content-container">
          <GameDetailsNew
            :id="'' + game.id"
            :game="game"
            :is-publishing="isPublishing"></GameDetailsNew>
        </div>

      </div>
    </div>
    <i v-if="loading" class="loading el-icon-loading"></i>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapMutations } from 'vuex';
import HeaderGameSubmit from '@/components/GameSubmissionSteps/HeaderGameSubmit.vue';
import AsyncAction from '@/components/AsyncAction.vue';
import tools from '@/tools/tools';
import FirstStep from '@/modules/GameSubmission/components/FirstStep.vue';
import LastStep from '@/modules/GameSubmission/components/LastStep.vue';
import Stepper from '@/modules/ScrollStepper';
import GameDetailsNew from '@/components/Game/GameDetailsNew.vue';
import GamePendingApproval from '@/components/GameSubmissionSteps/GamePendingApprovalOnPage.vue';

export default {
  name: 'SubmitGame',
  components: {
    HeaderGameSubmit,
    GamePendingApproval,
    AsyncAction,
    FirstStep,
    Stepper,
    LastStep,
    GameDetailsNew,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return { tools };
  },
  computed: {
    game() {
      return cloneDeep(this.$store.getters['submitGame/game']);
    },
    loading() {
      return cloneDeep(this.$store.getters['submitGame/loading']);
    },
    loadingProperty() {
      return cloneDeep(this.$store.getters['submitGame/loadingProperty']);
    },
    isDisplayedOnStudioSide() {
      console.log(this.$route.meta);
      return this.$route.meta ? this.$route.meta.category === 'Publishing' : false;
    },
    routeMeta() {
      return this.$route.meta;
    },
    isPublishing() {
      return this.$route.meta ? this.$route.meta.category === 'Publishing' : false;
    },
    isSubmitflow() {
      return !['in_testing', 'finished', 'archived', 'pending_approval'].includes(tools.getWhichFilterGameIs(this.game));
    },
    isIterating() {
      return tools.getWhichFilterGameIs(this.game) === 'is_iterating';
    },
    isPending() {
      return tools.getWhichFilterGameIs(this.game) === 'pending_approval';
    },
    inProgress() {
      return tools.getWhichFilterGameIs(this.game) === 'in_progress';
    },
    showHeader() {
      return this.game && !this.loading && !this.isDisplayedOnStudioSide && (this.isSubmitflow || this.isIterating);
    },
    substatus() {
      let current = null;
      try {
        current = JSON.parse(this.game.substatus)?.current || 'create';
      } catch (error) {
        current = this.game.substatus === 'createGame' ? 'create' : this.game.substatus;
      }
      return current;
    },
    isLoading() {
      return this.game && this.game.id && this.game.loadingRequest;
    },
    showCoffeCup() {
      return ((this.substatus === 'upload_asset' && this.inProgress) || this.isIterating);
    },
    showOnGoingOperation() {
      return !this.showCoffeCup;
    },
  },
  watch: {
    id() {
      this.getGameInfos();
    },
  },
  beforeDestroy() {
    this.$store.dispatch('submitGame/clean');
  },
  mounted() {
    this.getGameInfos();
  },
  methods: {
    ...mapMutations([
      'submitGame/set_game',
    ]),
    getGameInfos() {
      if (this.id && this.id !== 'new') {
        this.$store.dispatch('submitGame/getGame', this.id);
      } else {
        const newGame = {
          name: '',
          platform: 'android',
          isCtr: false,
          isManagedByVoodoo: true,
          isPublishedInStore: false,
          adNetworks: [
            {
              id: 1,
              label: 'Facebook',
            },
            {
              id: 2,
              label: 'Snapchat',
            },
          ],
        };
        this.$store.commit('submitGame/set_clean');
        this.$store.commit('submitGame/set_game', newGame);
      }
    },
    goto() {
      window.location = '/board/mygames/in_progress';
    },
  },
};
</script>

<style lang="scss">
.game-view-detail {
  width: 100%;
  .disabled-game {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    border-radius: 10px;
    background-color: rgba(201, 224, 246, 0.89);
    // background-color: rgba(130, 189, 247, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #067bf0;
    font-weight: bold;
    .container-text {
      margin-top: 40px;
      position: fixed;
      top: 0;
    }

    span {
      margin-right: 10px;
    }
  }
  .submit-game-form {
    display: grid;
    margin-top: 0px;
    margin-bottom: 0px !important;
    @media screen and (max-width: 700px) {
      .no-mobile {
        display: none;
      }
    }
    .header {
      margin-top: 0px;
      display: grid;
      align-items: center;
      grid-template-columns: 50px 1fr;
      justify-content: flex-start;
      font-size: 18px;
      color: #272727;
      padding: 0 20px;
      position: relative;
      top: 0px;
      background-color: #f7fbff;
      z-index: 10;
      .image {
        width: 100%;
        height: 100%;
        padding: 10px 0;
        &:hover {
          cursor: pointer;
        }
      }
      .backto {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
      }
      .title {
        text-align: left;
        padding: 10px 0px;
      }
    }

    .main-content {
      border-radius: 10px;
      background-color: #ffffff;
      // margin-top: 10px;
      position: relative;
      margin: 0 0px;
      .header-submit-game-page {
        padding: 10px;
        border-bottom: 1px solid #e9e9f0;
        position: sticky;
        top: 0px;
      }
      .video-graph-slider {
        position: relative;
        top: 0;
      }
      .no-game {
        font-size: 12px;
        color: $darker-grey;
        padding: 50px;
      }
      .content-container {
        display: grid;
          grid-template-columns: 1fr;
        height: calc(100vh - 63px);
        @media (max-width: 700px) {
          grid-template-columns: 1fr;
          .el-step__title,
          .el-step__description {
            display: none;
          }
        }
      }
    }

    @media (max-width: 700px) {
    }
  }
}
</style>

<template>
  <div>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'View feedback'"
      :visible="isVisible"
      width="60%"
      data-test-id="myGamesSeeFeedbackModal"
      @close="$emit('close')"
    >
      <Feedback :game="game" @close-pop-in="$emit('close')"></Feedback>
    </el-dialog>
  </div>
</template>

<script>
import Feedback from '@/components/Dialogs/Feedback.vue';

export default {
  name: 'FeedbackSeeModal',
  components: {
    Feedback,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 24px;
  height: 24px;
}

.icon:hover {
  cursor: pointer;
}
</style>

<template>
  <div :class="{'pmSide' : isPublishing}">
    <InfosGameTop
      v-if="game"
      :key="`${game.status} ${game.id}`"
      :gameid="`${game.id}`"
      :game="game"
    ></InfosGameTop>
    <div
      class="container-iteration"
      data-test-id="gameInfosIterationContainer"
      :class="$style.container"
      :style="{
        paddingTop: isMobile ? '32px' : isPublishing ? '220px' : '100px',
      }"
    >
      <Iteration
        v-for="(iteration, index) in game.iterations.slice().reverse()"
        :key="iteration.iterationId"
        :game="game"
        :iteration="iteration"
        :index="index"
        :class="$style.iteration"
        :ismobile="isMobile"
      />
    </div>
  </div>
</template>

<script>
import Iteration from '@/modules/GameStats/components/Iteration';
import InfosGameTop from '@/Layouts/InfosGameTop/InfosGameTop.vue';

export default {
  name: 'GameDetails',
  components: {
    InfosGameTop,
    Iteration,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    isPublishing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$mq === 'isMobile' || this.$mq === 'xxs';
    },
  },
  methods: {
    changeSection(section) {
      this.section = section;
    },
    chartsDisplay(index) {
      this.chartsDisplayIndex = index;
      this.isChartsDisplay = true;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  padding-top: 100px;

  @media (max-width: 700px) {
    padding: 0;
    height: calc(100vh - 100px);
  }
  overflow: auto;
  height: 100vh;
}

.iteration {
  margin-bottom: $spacing * 4;
}
</style>

<style scoped lang="scss">
.container-iteration {
  padding-bottom: 150px;
}
.pmSide {
  .container-iteration {
    @media (max-width: 700px) {
      height: calc(100vh - 400px);
    }
  }
}
</style>

<template>
  <div class="container-list">
    <el-table
      id="studio-table"
      ref="studiotable"
      :max-height="tableHeight"
      :show-header="true"
      :stripe="true"
      :data="studioList"
      :sort-method="sortMethod"
      style="width: 100%"
      data-test-id="studiosPageListWrapper"
      @sort-change="sortBy"
      @cell-click="goto"
    >
      <div slot="empty">
        <div v-if="!loading && studioList.length === 0" />
        <div v-else>loading...</div>
      </div>

      <el-table-column
        v-for="(header, index) of column"
        :key="index"
        :sortable="header.id !== 'studioName' && header.id !== 'videos' ? 'custom' : false"
        :prop="header.id"
        :label="header.label"
        :min-width="header.width"
        :class-name="`column-${header.id} column`"
      >
        <template slot-scope="scope">
          <div v-if="header.id === 'name'" :id="`id-${scope.row.id}`">
            <i v-if="scope.row.isStarred" class="el-icon-star-on star"></i>

            <div class="container-studioname">
              <span class="studio-name">{{ `${scope.row.name}` || "-" }}</span>
              <span
                v-if="isAdmin || isStaff || canImpersonate"
                class="impersonate"
                data-test-id="studiosPageImpersonateButton"
                @click.stop="openImpersonate(scope.row.id)"
              >impersonate</span
              >
            </div>
          </div>

          <div v-if="header.id === 'studioName'">
            <div class="studio-col">
              <span class="studio-name">{{ scope.row.studioName }}</span>
              <span v-if="scope.row.studioLeadName" class="gl" data-test-id="studiosPageStudioLeadName">
                GL:
                {{ scope.row.studioLeadName }}
              </span>
            </div>
          </div>

          <div v-if="header.id === 'pmName'">
            <div class="pm-col">
              <span v-if="scope.row.pmName === 'Loic Moisan' || scope.row.pmName === 'Chafik Naceri'" class="pm-name">ATT</span>
              <span v-else class="pm-name" data-test-id="studiosPagePmName">{{ scope.row.pmName }}</span>
            </div>
          </div>

          <div v-if="header.id === 'secondaryPmName'">
            <div class="pm-col">
              <span
                v-if="scope.row.secondaryPmName === 'Loic Moisan' || scope.row.secondaryPmName === 'Chafik Naceri'"
                class="pm-name"
              >ATT</span
              >
              <span v-else class="pm-name" data-test-id="studiosPagePmName">{{ scope.row.secondaryPmName }}</span>
            </div>
          </div>

          <div v-if="header.id === 'gamesCpi3Months'">
            <div class="new-kpi">
              <div class="best" data-test-id="studiosPageGamesCPIValue">
                <span v-if="scope.row.gamesCpi3Months" class="unit">$</span>
                <span v-if="scope.row.gamesCpi3Months || scope.row.gamesCpi3Months === 0">{{
                  (+scope.row.gamesCpi3Months).toFixed(2)
                }}</span>
                <span v-else>-</span>
              </div>
              <el-tooltip v-if="scope.row.gamesCpi6To3Months"  placement="left" effect="light">
                <div slot="content">
                  <span>
                    {{
                      `6 months ago:
                    $${scope.row.gamesCpi6To3Months.toFixed(2) || 0}`
                    }}
                  </span>
                  <br />
                  <span>
                    {{ `Improvement: ${scope.row.gamesCpiImprovement ? `$${scope.row.gamesCpiImprovement.toFixed(2)}` : ""}` }}
                  </span>
                </div>
                <div
                  v-if="scope.row.gamesCpiPercentage"
                  class="last"
                  :class="{
                    relevant: scope.row.gamesCpiGreen,
                    noRelevant: !scope.row.gamesCpiGreen
                  }"
                >
                  <span data-test-id="studiosPageGamesCPIPercentage">{{ `(${(+scope.row.gamesCpiPercentage * 100).toFixed(1)} %)` }}</span>
                </div>
                <span v-else>-</span>
              </el-tooltip>
              <div
                v-else
                class="last"
                :class="{
                  relevant: scope.row.gamesCpiGreen,
                  noRelevant: !scope.row.gamesCpiGreen && scope.row.gamesCpiPercentage !== null
                }"
                data-test-id="studiosPageGamesCPIPercentage"
              >
                <span v-if="scope.row.gamesCpiPercentage">{{ `(${(+scope.row.gamesCpiPercentage * 100).toFixed(1)} %)` }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <div v-if="header.id === 'gamesCtr3Months'">
            <div class="new-kpi">
              <div class="best" data-test-id="studiosPageGamesCTRValue">
                <span v-if="scope.row.gamesCtr3Months || scope.row.gamesCtr3Months === 0">
                  {{ (+scope.row.gamesCtr3Months * 100).toFixed(2) }}
                  <span v-if="scope.row.gamesCtr3Months" class="unit">%</span>
                </span>
                <span v-else>-</span>
              </div>
              <el-tooltip v-if="scope.row.gamesCtr6To3Months" placement="left" effect="light">
                <div slot="content">
                  <span>
                    {{
                      `6 months ago:
                    ${(scope.row.gamesCtr6To3Months * 100).toFixed(2) || 0}%`
                    }}
                  </span>
                  <br />
                  <span>
                    {{
                      `Improvement: ${scope.row.gamesCtrImprovement ? `${(scope.row.gamesCtrImprovement * 100).toFixed(2)}` : ""}`
                    }}
                  </span>
                </div>
                <div
                  v-if="scope.row.gamesCtrPercentage"
                  class="last"
                  :class="{
                    relevant: scope.row.gamesCtrGreen,
                    noRelevant: !scope.row.gamesCtrGreen
                  }"
                  data-test-id="studiosPageGamesCTRPercentage"
                >
                  <span>{{ `(${(+scope.row.gamesCtrPercentage * 100).toFixed(1)} %)` }}</span>
                </div>
                <span v-else>-</span>
              </el-tooltip>
              <div
                v-else
                class="last"
                :class="{
                  relevant: scope.row.gamesCtrGreen,
                  noRelevant: !scope.row.gamesCtrGreen && scope.row.gamesCtrPercentage !== null
                }"
                data-test-id="studiosPageGamesCTRPercentage"
              >
                <span v-if="scope.row.gamesCtrPercentage">{{ `(${(+scope.row.gamesCtrPercentage * 100).toFixed(1)} %)` }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <div v-if="header.id === 'gamesDay13Months'">
            <div class="new-kpi">
              <div class="best" data-test-id="studiosPageGamesDay1Value">
                <span v-if="scope.row.gamesDay13Months || scope.row.gamesDay13Months === 0">
                  {{ (+scope.row.gamesDay13Months * 100).toFixed(2) }}
                  <span v-if="scope.row.gamesDay13Months" class="unit">%</span>
                </span>
                <span v-else>-</span>
              </div>
              <el-tooltip v-if="scope.row.gamesDay16To3Months" placement="left" effect="light">
                <div slot="content">
                  <span>
                    {{
                      `6 months ago:
                    ${(scope.row.gamesDay16To3Months * 100).toFixed(2) || 0}%`
                    }}
                  </span>
                  <br />
                  <span data-test-id="studiosPageGamesDay1ImprovementValue">
                    {{
                      `Improvement: ${
                        scope.row.gamesDay1Improvement ? `${(scope.row.gamesDay1Improvement * 100).toFixed(2)}` : ""
                      }`
                    }}
                  </span>
                </div>
                <div
                  v-if="scope.row.gamesDay1Percentage"
                  class="last"
                  :class="{
                    relevant: scope.row.gamesDay1Green,
                    noRelevant: !scope.row.gamesDay1Green
                  }"
                  data-test-id="studiosPageGamesDay1Percentage"
                >
                  <span>{{ `(${(+scope.row.gamesDay1Percentage * 100).toFixed(1)} %)` }}</span>
                </div>
                <span v-else>-</span>
              </el-tooltip>
              <div
                v-else
                class="last"
                :class="{
                  relevant: scope.row.gamesDay1Green,
                  noRelevant: !scope.row.gamesDay1Green && scope.row.gamesDay1Percentage !== null
                }"
                data-test-id="studiosPageGamesDay1Percentage"
              >
                <span v-if="scope.row.gamesDay1Percentage">{{ `(${(+scope.row.gamesDay1Percentage * 100).toFixed(1)} %)` }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <div v-if="header.id === 'gamesDay73Months'">
            <div class="new-kpi">
              <div class="best" data-test-id="studiosPageGamesDay7Value">
                <span v-if="scope.row.gamesDay73Months || scope.row.gamesDay73Months === 0">
                  {{ (+scope.row.gamesDay73Months * 100).toFixed(2) }}
                  <span v-if="scope.row.gamesDay73Months" class="unit">%</span>
                </span>
                <span v-else>-</span>
              </div>
              <el-tooltip v-if="scope.row.gamesDay76To3Months" placement="left" effect="light">
                <div slot="content">
                  <span>
                    {{
                      `6 months ago:
                    ${(scope.row.gamesDay76To3Months * 100).toFixed(2) || 0}%`
                    }}
                  </span>
                  <br />
                  <span>
                    {{
                      `Improvement: ${
                        scope.row.gamesDay7Improvement ? `${(scope.row.gamesDay7Improvement * 100).toFixed(2)}` : ""
                      }`
                    }}
                  </span>
                </div>
                <div
                  v-if="scope.row.gamesDay7Percentage"
                  class="last"
                  :class="{
                    relevant: scope.row.gamesDay7Green,
                    noRelevant: !scope.row.gamesDay7Green
                  }"
                  data-test-id="studiosPageGamesDay7Percentage"
                >
                  <span>{{ `(${(+scope.row.gamesDay7Percentage * 100).toFixed(1)} %)` }}</span>
                </div>
                <span v-else>-</span>
              </el-tooltip>
              <div
                v-else
                class="last"
                :class="{
                  relevant: scope.row.gamesDay7Green,
                  noRelevant: !scope.row.gamesDay7Green && scope.row.gamesDay7Percentage !== null
                }"
                data-test-id="studiosPageGamesDay7Percentage"
              >
                <span v-if="scope.row.gamesDay7Percentage">{{ `(${(+scope.row.gamesDay7Percentage * 100).toFixed(1)} %)` }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <!-- <div v-if="header.id === 'gamesCtr3Months'">
            <div v-if="!scope.row.gamesCtr3Months" class="none">-</div>

            <div v-else class="new-kpi">
              <el-tooltip :content="``" placement="right" effect="light">
                <div class="best" :class="{ relevant: scope.row.gamesCtrGreen }">
                  <span v-if="scope.row.gamesCtr3Months || scope.row.gamesCtr3Months === 0">{{
                    (+scope.row.gamesCtr3Months * 100).toFixed(1)
                  }}</span>
                  <span v-else>-</span>
                  <span v-if="!isNaN(scope.row.gamesCtr3Months)" class="unit">%</span>
                </div>
              </el-tooltip>
              <el-tooltip
                :content="`New users: ${scope.row.lastD7NewUsers || 0}`"
                placement="right"
                effect="light"
              >
                <div class="last" :class="{ relevant: scope.row.lastD7IsRelevant }">
                  <span v-if="scope.row.lastD7 || scope.row.lastD7 === 0">{{
                    (+scope.row.lastD7 * 100).toFixed(1)
                  }}</span>
                  <span v-else>-</span>
                  <span v-if="!isNaN(scope.row.lastD7)" class="unit">%</span>
                </div>
              </el-tooltip>
            </div>
          </div>-->

          <!-- <div v-if="header.id === 'gamesPlaytime3MonthsHumanReadable'">
            <div v-if="!scope.row.gamesPlaytime3MonthsHumanReadable" class="none">-</div>

            <div v-else class="new-kpi">
              <el-tooltip :content="``" placement="right" effect="light">
                <div class="best" :class="{ relevant: scope.row.gamesPlaytimeGreen }">
                  <span v-if="scope.row.gamesPlaytime3MonthsHumanReadable">
                    {{ scope.row.gamesPlaytime3MonthsHumanReadable }}
                  </span>
                  <span v-else>-</span>
                </div>
              </el-tooltip>
              <el-tooltip
                :content="
                  `New users:
                  ${scope.row.lastPlaytimeNewUsers || 0}`
                "
                placement="right"
                effect="light"
              >
                <div class="last" :class="{ relevant: scope.row.lastPlaytimeIsRelevant }">
                  <span v-if="scope.row.lastPlaytimeHumanReadable">
                    {{ scope.row.lastPlaytimeHumanReadable }}
                  </span>
                  <span v-else>-</span>
                </div>
              </el-tooltip>
            </div>
          </div>-->

          <div v-if="header.id === 'gamesPlaytime3Months'">
            <div class="new-kpi">
              <div
                class="best"
                data-test-id="studiosPageGamesPlaytimeValue"
              >
                <span v-if="scope.row.gamesPlaytime3Months || scope.row.gamesPlaytime3Months === 0">{{
                  scope.row.gamesPlaytime3MonthsHumanReadable
                }}</span>
                <span v-else>-</span>
              </div>
              <el-tooltip v-if="scope.row.gamesPlaytime6To3Months" placement="left" effect="light">
                <div slot="content">
                  <span>
                    {{ `6 months ago: ${scope.row.gamesPlaytime6To3MonthsHumanReadable || 0}` }}
                  </span>
                  <br />
                  <span>
                    {{ `Improvement: ${scope.row.gamesPlaytimeImprovement ? `${scope.row.gamesPlaytimeImprovement}s` : ""}` }}
                  </span>
                </div>
                <div
                  v-if="scope.row.gamesPlaytimePercentage"
                  class="last"
                  :class="{
                    relevant: scope.row.gamesPlaytimeGreen,
                    noRelevant: !scope.row.gamesPlaytimeGreen
                  }"
                  data-test-id="studiosPageGamesPlaytimePercentage"
                >
                  <span>{{ `(${(+scope.row.gamesPlaytimePercentage * 100).toFixed(1)} %)` }}</span>
                </div>
                <span v-else>-</span>
              </el-tooltip>
              <div
                v-else
                class="last"
                :class="{
                  relevant: scope.row.gamesPlaytimeGreen,
                  noRelevant: !scope.row.gamesPlaytimeGreen && scope.row.gamesPlaytimePercentage !== null
                }"
                data-test-id="studiosPageGamesPlaytimePercentage"
              >
                <span v-if="scope.row.gamesPlaytimePercentage">{{
                  `(${(+scope.row.gamesPlaytimePercentage * 100).toFixed(1)} %)`
                }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <div
            v-if="header.id === 'creationDate'"
            data-test-id="studiosPageCreationDate"
          >
            <span v-if="scope.row.creationDate">{{ formatDate(scope.row.creationDate) }}</span>
            <span v-else>-</span>
          </div>

          <div
            v-if="header.id === 'sinceLastIterationDate'"
            data-test-id="studiosPageSinceLastIterationDate"
          >
            {{
              0 >= scope.row.sinceLastIterationDate
                ? "Today"
                : scope.row.sinceLastIterationDate > 1
                  ? `${scope.row.sinceLastIterationDate} Days ago`
                  : `${scope.row.sinceLastIterationDate} Day ago`
            }}
          </div>

          <div
            v-if="header.id === 'contract'"
            data-test-id="studiosPageContract"
          >
            <span v-if="scope.row.contract">{{ scope.row.contract }}</span>
            <span v-else>-</span>
          </div>

          <div
            v-if="header.id === 'activityState'"
            data-test-id="studiosPageActivityState"
          >
            <el-tooltip
              v-if="scope.row.activityState"
              class="item"
              effect="dark"
              :content="tooltipInfos(scope.row)"
              placement="bottom"
            >
              <span
                :class="{
                  active: scope.row.activityState === 'active',
                  inactive: scope.row.activityState === 'inactive',
                  churned: scope.row.activityState === 'churned'
                }"
              ></span>
            </el-tooltip>
            <span v-else>-</span>
          </div>

          <div
            v-if="header.id === 'nbPrototypes'"
            data-test-id="studiosPageNbPrototypes"
          >
            <span v-if="scope.row.nbPrototypes">
              {{ scope.row.nbPrototypes }}
              <span @click="gotoGamefromStudioId(scope.row.id)">
                <i class="el-icon-search"></i>
              </span>
            </span>

            <span v-else>-</span>
          </div>

          <div
            v-if="header.id === 'gamesNbPrototypes6Months'"
            data-test-id="studiosPageNbPrototypes6Months"
          >
            <span v-if="scope.row.gamesNbPrototypes6Months">
              {{ scope.row.gamesNbPrototypes6Months }}
              <span @click="gotoGamefromStudioId(scope.row.id)">
                <i class="el-icon-search"></i>
              </span>
            </span>

            <span v-else>-</span>
          </div>

          <div
            v-if="header.id === 'nbHits'"
            data-test-id="studiosPageNbHits"
          >
            <span v-if="scope.row.nbHits">{{ scope.row.nbHits }}</span>
            <span v-else>-</span>
          </div>
        </template>
      </el-table-column>

      <InfiniteLoading
        slot="append"
        ref="InfiniteLoading"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        @infinite="infiniteHandler"
      >
        <div slot="spinner" class="spinner">
          <i class="el-icon-loading loading-list" />
        </div>
        <div slot="no-more" class="no-more">No more studio</div>
        <div slot="no-results" class="no-more">
          <div class="no-results">No more studio</div>
        </div>
      </InfiniteLoading>
    </el-table>
    <ImpersonateDialog
      v-if="modalImpersonate"
      :studio-id="`${studioId}`"
      :open="modalImpersonate"
      @close="modalImpersonate = false"></ImpersonateDialog>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';
import { ImpersonateDialog } from '@/modules/Impersonate';

let timer;

export default {
  name: 'StudioTable',
  components: {
    InfiniteLoading,
    ImpersonateDialog,
  },
  props: {
    prevId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tableHeight: 200,
      n: 0,
      timeout: null,
      isMobile: window.innerWidth < 742,
      item: null,
      userList: [],
      modalImpersonate: false,
      studioId: null,
    };
  },
  computed: {
    currentView() {
      return this.$store.getters['viewsStudio/currentView'];
    },
    canRequest() {
      return this.$store.getters['viewsStudio/canRequest'];
    },
    isStaff() {
      return this.$store.getters['users/isStaff'];
    },
    isAdmin() {
      return this.$store.getters['users/isAdmin'];
    },
    column() {
      return this.currentView.content.columns.filter(el => el.isSelected);
    },
    studioList() {
      return this.$store.getters['studios/studioList'];
    },
    loading() {
      return this.$store.getters['studios/loading'];
    },
    sort() {
      if (this.$store.getters['viewsStudio/sort'] === null) {
        return {};
      }

      return {
        prop: this.$store.getters['viewsStudio/sort'].prop,
        order: this.$store.getters['viewsStudio/sort'].order === 'desc' ? 'descending' : 'ascending',
      };
    },
    best() {
      return cloneDeep(this.$store.getters['studios/best']);
    },
    canImpersonate() {
      return this.$store.getters['users/myPermissions'].includes('impersonate_studio_member');
    },
  },
  watch: {
    currentView(oldValue, newValue) {
      const paramsLast = {
        term: oldValue.content.term,
        filter: oldValue.content.filter,
      };
      const paramsNew = {
        term: newValue.content.term,
        filter: newValue.content.filter,
      };
      if (JSON.stringify(paramsLast) !== JSON.stringify(paramsNew)) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$store.dispatch('studios/resetStudiosList');
          this.$refs.InfiniteLoading.stateChanger.reset();
        }, 800);
      }
    },
    best() {
      this.$store.dispatch('studios/resetStudiosList');
      this.$refs.InfiniteLoading.stateChanger.reset();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.tableHeight = tools.calcTablesHeight();
      window.addEventListener('resize', () => {
        this.tableHeight = tools.calcTablesHeight();
      });
      await new Promise(resolve => setTimeout(resolve, 1200));
      if (this.prevId) {
        this.scrollIntoView(this.prevId);
      }
    });
  },
  methods: {
    tooltipInfos(studio) {
      const activityState = studio.activityState;
      const isForced = studio.isActivityStateForced;
      let msg = '';

      if (activityState === 'active') {
        msg = 'Studio is Active';
      } else if (activityState === 'inactive') {
        msg = `Studio is Inactive (${isForced ? 'manually' : 'auto'} set)`;
      } else if (activityState === 'churned') {
        if (isForced) {
          msg = `Studio has Churned (${studio.deactivateReason})`;
        } else {
          msg = 'Studio has Churned (auto set)';
        }
      }

      return msg || 'N/A';
    },
    async openImpersonate(id) {
      this.studioId = id;
      this.modalImpersonate = true;
    },
    gotoGamefromStudioId(studioId) {
      this.$router.push({
        name: 'home',
        query: {
          customView: 'studioId',
          customId: studioId,
        },
      });
    },
    async infiniteHandler($state) {
      await this.$store.dispatch('studios/fetchList', this.currentView).then((response) => {
        if (response.data.length === 0 && this.studioList.length > 0) {
          $state.complete();
        } else if (response.data.length > 0) {
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      });
    },
    sortMethod() {
      return null;
    },
    formatDate(value) {
      return moment(value)
        .utc()
        .format('DD-MM-YYYY');
    },
    sortBy(value) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (value.prop !== this.sort.prop || value.order !== this.sort.order) {
          const sort = cloneDeep(value);
          sort.order = value.order === 'ascending' ? 'asc' : 'desc';
          this.$store.dispatch('viewsStudio/setSort', sort);
          this.$store.dispatch('studios/resetStudiosList');
          this.$refs.InfiniteLoading.stateChanger.reset();
        }
      }, 500);
    },
    goto(row, column, index, event) {
      if (
        column.label === 'Name'
        || column.label === 'CPI'
        || column.label === 'Day 1'
        || column.label === 'Day 7'
        || column.label === 'AVG Session'
      ) {
        if (this.$route.query.studio !== row.id) {
          tools.openUrl(this.$router, {
            name: 'studio',
            params: { id: row.id },
          }, event);
        }
      }
    },
    scrollIntoView(element) {
      if (document.getElementById(`id-${element}`)) {
        document.getElementById(`id-${element}`).scrollIntoView(true);
        document
          .getElementById(`id-${element}`)
          .parentElement.parentElement.parentElement.querySelectorAll('td')
          .forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.className += ' hight-light';
          });
      }
    },
  },
};
</script>

<style lang="scss">
@keyframes highlights {
  from {
    background-color: rgba(0, 128, 0, 0.486);
  }
  to {
    background-color: initial;
  }
}

.studios {
  .main {
    padding: 0px 40px 0 40px;
  }
}

.user-to-hijack {
  color: #067bf0;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}
.container-list {
  .hight-light {
    animation: highlights 2000ms ease-out;
  }
  .el-table--scrollable-y .el-table__body-wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  td.column {
    padding: 0px 0 !important;
    border: 0;
  }
  td.column {
    padding: 5px 0;
  }
  .no-more {
    border: 1px solid $light-grey;
    font-size: 15px;
    padding: 5px;
    background-color: $lighter-grey;
    border-top: 1px solid #e6e6e6;
    color: #cecece;
  }
  .spinner {
    padding: 10px;
  }
  .column.column-name {
    button {
    }
  }

  .column-status {
    .dot {
      margin-right: 5px;
    }
    .in_testing {
      color: #58c783;
    }
    .finished {
      color: #232323;
    }
    .pending_review,
    .pending_sdk_integration,
    .pending_testing {
      color: #ff8d00;
    }
  }

  .column-cpi,
  .column-day_1,
  .column-day_7,
  .column-name,
  .column-playtime {
    .cell:hover {
      cursor: pointer;
    }
  }

  .column-gamesCpi3Months,
  .column-gamesDay13Months,
  .column-gamesDay73Months,
  .column-qrcode {
    .cell {
      text-align: center;
      justify-content: center;
      div {
        justify-content: center;
      }
      .qrcode-col {
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  td.column-statsCompact {
    .cell {
      font-size: 9px;
    }
  }
  .cell {
    .churned {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #f56c6c;
    }
    .active {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #67c23a;
    }
    .inactive {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #e6a23c;
    }
    .star {
      color: rgb(221, 209, 46);
      font-size: 20px;
      margin-right: 5px;
    }
    .studio-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .gl {
        color: grey;
        font-size: 12px;
      }
    }
    .isManagedByVoodoo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .qrcode-col {
      justify-content: center;
    }
    span.iteration {
      margin-left: 5px;
    }
    .new-kpi {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      width: 100%;
      span {
        white-space: nowrap;
        overflow: auto;
      }
    }
    .separator {
      margin: 0px 5px 0px 5px;
    }
    .container-studioname {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;

      span {
        white-space: nowrap;
        > .el-icon-search {
          &:hover {
            cursor: pointer;
          }
        }
        > .el-icon-search:before {
          color: #067bf0;
          border: 1px solid #067bf0;
          border-radius: 3px;
          font-size: 10px;
          padding: 2px;
          margin-left: 5px;
        }

        &.impersonate {
          color: #adadad;
          color: #adadad;
          font-size: 9px;
          line-height: 6px;
          &:hover {
            color: #82bdf7;
            cursor: pointer;
          }
        }
        &.studio-name {
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        &.studio-name {
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        &.pm-name {
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        &.gl {
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
          align-self: stretch;
        }
        @media (max-width: 700px) {
          white-space: nowrap;
          &.studio-name {
            text-overflow: ellipsis;
            width: 50px;
            overflow: hidden;
            align-self: stretch;
          }
          &.studio-name {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            align-self: stretch;
          }
          &.pm-name {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            align-self: stretch;
          }
          &.gl {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            align-self: stretch;
          }
        }
      }
    }
    .best {
      &.relevant {
        span {
          color: $tea-dark;
        }
      }
      span {
        color: inherit;
      }
    }
    // .last {
    //   span {
    //     color: $blood-normal;
    //   }
    //   &.relevant {
    //     span {
    //       color: $tea-dark;
    //     }
    //   }
    //   span {
    //     color: inherit;
    //   }
    // }
    .last {
      span {
        color: inherit;
      }
      &.relevant {
        span {
          color: $tea-dark;
        }
      }
      &.noRelevant {
        span {
          color: $blood-normal;
        }
      }
    }
    .el-button--mini {
      padding: 5px 5px;
      margin: 0;
    }
    .video-preview {
      padding: 5px;
      display: flex;
      overflow: auto;
      @media (max-width: 700px) {
        max-width: 100px;
      }
      button {
        padding: 5px 5px 5px 0px;
      }
      .el-dropdown {
        display: block;
        width: 40px;
      }
    }
    .stats-compact {
      overflow: auto;
      white-space: nowrap;
    }
    .status-case {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 700px) {
    }
  }
}
.el-dialog {
  .el-form-item {
    margin-bottom: 5px;
  }
  .el-dialog__body {
    padding: 20px;
  }
}
@media (max-width: 700px) {
  .el-dialog {
    width: 100% !important;
    margin: 0;
  }
  .el-table {
    // position: absolute;
    left: 0;
  }
  .el-tag {
    max-width: 100px;
    white-space: normal;
  }
}
</style>

// states: done, current, disabled, accessible, accessed
// done: when a step was validated and is not active
// current: the active state, valid or not
// disabled: wasn't accessed and isn't accessible yet
// accessible: a state that can be accessed without conditions
// accessed: was accessed once, but wasn't completed, therefore it can still be accessed
export default {
  isfacebookCampaign(game) {
    if (game.selectedAdNetworkIdList) {
      return game.selectedAdNetworkIdList.includes(1);
    }
    return (game
        && (!game.isManagedByVoodoo
          || (game.iterations && game.iterations[0] && game.iterations[0].adNetworks && game.iterations[0].adNetworks.facebook)
          || (game.adnetworks && game.adnetworks.indexOf('Facebook') > -1))
    );
  },
  isSnapchatCampaign(game) {
    if (game.selectedAdNetworkIdList) {
      return game.selectedAdNetworkIdList.includes(2);
    }
    return (game
        && (!game.isManagedByVoodoo
          || (game.iterations && game.iterations[0] && game.iterations[0].adNetworks && game.iterations[0].adNetworks.snapchat)
          || (game.adnetworks && game.adnetworks.indexOf('Snapchat') > -1))
    );
  },
  getAllStepArrayByGame(game, isDisplayedOnStudioSide = false) {
    let stepArray = [];
    if (isDisplayedOnStudioSide) {
      stepArray.push({
        component: 'GameDetailsNew',
      });
      return stepArray;
    }
    const isFacebook = this.isfacebookCampaign(game);

    if (this.getWhichFilterGameIs(game) === 'in_progress') {
      if (!game.id) {
        stepArray.push({
          name: 'Create a test',
          id: 'create',
          duration: 'Less than 1 min',
          component: null,
          isValid: true,
          color: '#533E85',
          tutorial: null,
          state: 'current',
        });
      } else {
        stepArray.push({
          name: 'Create a test',
          id: 'create',
          duration: 'Less than 1 min',
          component: null,
          isValid: true,
          color: '#533E85',
          tutorial: null,
          state: 'done',
        });
      }


      if (!game.isCtr) {
        if (!game.isPublishedInStore && game.platform === 'ios') {
          stepArray.push({
            name: 'AppStore publishing',
            id: 'appstore_submission',
            duration: '10 - 15 min',
            component: 'WithoutAction',
            isValid: false,
            color: '#533E85',
            tutorial: 'APPSTORE_PUBLISHING',
            state: null,
            title: 'Create the AppStore draft for your app',
          });
        }
        stepArray.push({
          name: 'Link to store',
          id: 'link_store',
          duration: '1 - 5 min',
          component: 'LinkStoreStepOnPage',
          isValid: false,
          color: '#488FB1',
          tutorial: 'LINKTOSTORE',
          state: 'disabled',
          title: game.platform === 'ios' ? 'Link AppStore to the Publishing Platform' : 'Link PlayStore to the Publishing Platform',
        });

        if (isFacebook) {
          stepArray.push({
            name: 'Facebook setup',
            id: 'sdk_facebook',
            duration: '15 - 20 min',
            component: 'FacebookStepOnPage',
            isValid: false,
            color: '#4FD3C4',
            tutorial: 'FACEBOOK_SETUP',
            state: 'disabled',
            title: 'Facebook setup',
          });
        }

        stepArray.push({
          name: 'TinySauce integration',
          id: 'tinysauce_package',
          duration: '5 - 10 min',
          component: 'TinySauceStepOnPage',
          isValid: false,
          color: '#C1F8CF',
          tutorial: 'TINY_SAUCE',
          state: 'disabled',
          title: 'TinySauce integration',
        });

        stepArray.push({
          name: 'Game building & Test',
          id: 'game_building',
          duration: '5 - 10 min',
          component: 'IntegrationStepOnPage',
          isValid: false,
          color: '#FFB2A6',
          tutorial: 'GAME_BUILDING',
          state: 'disabled',
          title: 'Game building & Test',
        });

        if (!game.isPublishedInStore && game.platform === 'android') {
          stepArray.push({
            name: 'Playstore publishing',
            id: 'playstore_submission',
            duration: '10 - 15 min',
            component: 'WithoutAction',
            isValid: false,
            color: '#533E85',
            tutorial: 'PLAYSTORE_PUBLISHING',
            state: 'disabled',
            title: 'Playstore publishing',
          });
        }
        if (game.platform === 'ios' || isFacebook) {
          stepArray.push({
            name: 'Finalize app config',
            id: 'request_testing',
            duration: '10 min',
            component: 'FinalizeAppSetup',
            isValid: false,
            color: '#4FD3C4',
            tutorial: 'FINALIZE_APP_SETUP',
            state: 'disabled',
            title: 'Finalize app config',
          });
        }
      }

      stepArray.push({
        name: 'Game categories & Tags',
        id: 'categories',
        duration: '1 min',
        component: 'Categories',
        isValid: false,
        color: '#C1F8CF',
        tutorial: null,
        state: 'disabled',
        title: 'Game categories & Tags',
      });
      stepArray.push({
        name: 'Create campaign',
        id: 'upload_asset',
        duration: '5 - 10 min',
        component: null,
        isValid: false,
        color: '#C1F8CF',
        tutorial: null,
        state: 'disabled',
      });
    }

    if (this.getWhichFilterGameIs(game) === 'is_iterating') {
      stepArray.push({
        title: 'Create campaign',
        id: 'upload_asset',
        time: '5 - 10 min',
        component: 'UploadCampaignAssetsStep',
      });
    }

    if (this.getWhichFilterGameIs(game) === 'pending_approval') {
      stepArray.push({
        component: 'GamePendingApproval',
      });
    }

    if (['in_testing', 'finished', 'archived'].includes(this.getWhichFilterGameIs(game))) {
      stepArray.push({
        component: 'GameDetailsNew',
      });
    }
    const substatus = game.substatus;

    if (substatus && this.isjson(substatus)) {
      const stepIdDone = JSON.parse(substatus)?.done || null;
      const stepIdCurrent = JSON.parse(substatus)?.current || null;
      stepArray = this.setStepDone(stepArray, stepIdDone);
      stepArray = this.setStepCurrent(stepArray, stepIdCurrent);
    }

    return stepArray;
  },
  getWhichFilterGameIs(game) {
    if (!game) {
      return '';
    }
    if (!game.isIterating && game.status === 'pending_sdk_integration') {
      return 'in_progress';
    }
    if (game.status === 'pending_testing') {
      return 'pending_approval';
    }
    if (game.status === 'in_testing') {
      return 'in_testing';
    }
    if (game.status === 'finished') {
      return 'finished';
    }
    if (game.status === 'archived') {
      return 'archived';
    }
    if (game.status === 'pending_sdk_integration' && game.isIterating) {
      return 'is_iterating';
    }
    return 'in_progress';
  },
  isjson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  },
  stepIdIsInArraySteps(array, stepId) {
    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element.id === stepId) {
        return true;
      }
    }
    return false;
  },
  setStepDone(array, stepId) {
    const stepDoneIsInStepsArray = this.stepIdIsInArraySteps(array, stepId);
    if (stepDoneIsInStepsArray) {
      let setStepDone = false;

      for (let i = 0; i < array.length; i += 1) {
        const element = array[i];
        if (element.id !== stepId && !setStepDone) {
          element.isValid = true;
          element.state = 'done';
        }
        if (element.id === stepId) {
          setStepDone = true;
          element.isValid = true;
          element.state = 'done';
        } else if (setStepDone) {
          element.state = 'disable';
        }
      }
    } else {
      for (let i = 0; i < array.length; i += 1) {
        const element = array[i];
        if (element.id === 'create') {
          element.isValid = true;
          element.state = 'done';
        } else {
          element.state = 'disable';
        }
      }
    }

    return array;
  },
  setStepCurrent(array, stepId) {
    const stepCurrentIsInStepsArray = this.stepIdIsInArraySteps(array, stepId);
    if (stepCurrentIsInStepsArray) {
      for (let i = 0; i < array.length; i += 1) {
        const element = array[i];
        if (element.id === stepId) {
          element.state = 'current';
        }
      }
    } else {
      for (let i = 0; i < array.length; i += 1) {
        const element = array[i];
        if (i === 1) {
          element.state = 'current';
        }
      }
    }

    return array;
  },
};

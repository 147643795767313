<template>
  <div class="sort-component">
    <div class="column-infos">
      <div class="sort-item">
        <span class="label">Campaigns date</span>
        <div class="sorter" data-test-id="myGamesSortByDateButton">
          <span @click="sortBy('createdAt', 'asc')">
            <i
              :class="{ active: sort === 'createdAt' && order === 'asc' }"
              class="el-icon el-icon-caret-top"
            ></i>
          </span>
          <span @click="sortBy('createdAt', 'desc')">
            <i
              :class="{ active: sort === 'createdAt' && order === 'desc' }"
              class="el-icon el-icon-caret-bottom"
            ></i>
          </span>
        </div>
      </div>
      <div v-if="showAdnetworks" class="sort-item">
        <span class="label">Ad Network</span>
        <div class="sorter" data-test-id="myGamesSortByAdnetworkButton">
          <span @click="sortBy('adnetworks', 'asc')">
            <i
              :class="{ active: sort === 'adnetworks' && order === 'asc' }"
              class="el-icon el-icon-caret-top"
            ></i>
          </span>
          <span @click="sortBy('adnetworks', 'desc')">
            <i
              :class="{ active: sort === 'adnetworks' && order === 'desc' }"
              class="el-icon el-icon-caret-bottom"
            ></i>
          </span>
        </div>
      </div>
      <div v-if="!showAdnetworks"></div>
      <div v-if="showKpiCpi" class="sort-item">
        <span class="label">CPI</span>
        <div class="sorter" data-test-id="myGamesSortByCPIButton">
          <span @click="sortBy('lastCpi', 'asc')">
            <i
              :class="{ active: sort === 'lastCpi' && order === 'asc' }"
              class="el-icon el-icon-caret-top"
            ></i>
          </span>
          <span @click="sortBy('lastCpi', 'desc')">
            <i
              :class="{ active: sort === 'lastCpi' && order === 'desc' }"
              class="el-icon el-icon-caret-bottom"
            ></i>
          </span>
        </div>
      </div>
      <div v-if="showPlaceholderKpiCpi"></div>
      <div v-if="showKpiCpi" class="sort-item">
        <span class="label">D1</span>
        <div class="sorter" data-test-id="myGamesSortByD1Button">
          <span @click="sortBy('lastD1', 'asc')">
            <i
              :class="{ active: sort === 'lastD1' && order === 'asc' }"
              class="el-icon el-icon-caret-top"
            ></i>
          </span>
          <span @click="sortBy('lastD1', 'desc')">
            <i
              :class="{ active: sort === 'lastD1' && order === 'desc' }"
              class="el-icon el-icon-caret-bottom"
            ></i>
          </span>
        </div>
      </div>
      <div v-if="showPlaceholderKpiCpi" ></div>
      <div v-if="showKpiCpi" class="sort-item">
        <span class="label">Cumulative D3 playtime</span>
        <div class="sorter" data-test-id="myGamesSortByD3PlaytimeButton">
          <span @click="sortBy('lastD3Playtime', 'asc')">
            <i
              :class="{ active: sort === 'lastD3Playtime' && order === 'asc' }"
              class="el-icon el-icon-caret-top"
            ></i>
          </span>
          <span @click="sortBy('lastD3Playtime', 'desc')">
            <i
              :class="{ active: sort === 'lastD3Playtime' && order === 'desc' }"
              class="el-icon el-icon-caret-bottom"
            ></i>
          </span>
        </div>
      </div>
      <div v-if="showPlaceholderKpiCpi"></div>

      <span class="more-options">
        <div></div>
      </span>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'SortComponent',
  components: {},
  props: {
    filter: {
      type: String,
      default: 'in_progress',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {};
  },
  computed: {
    showKpiCampaign() {
      return true;
    },
    showKpiCpi() {
      return this.type === 'cpi'
        && this.filter !== 'in_progress'
        && this.filter !== 'pending_approval'
        && this.filter !== 'is_iterating';
    },
    showPlaceholderKpiCpi() {
      return this.type === 'cpi'
        && (this.filter === 'in_progress' || this.filter === 'pending_approval' || this.filter === 'is_iterating');
    },
    showAdnetworks() {
      return this.filter !== 'in_progress' && this.filter !== 'pending_approval' && this.filter !== 'is_iterating';
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.order;
    },
  },
  watch: {
    type(value) {
      if (
        value === 'cpi'
        && (this.sort === 'lastCtr'
          || this.sort === 'lastCpc'
          || this.sort === 'lasCpm')
      ) {
        this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
          filter: this.filter,
          sort: 'createdAt',
          order: 'desc',
        });
      }
    },
  },
  methods: {
    sortBy(sort, order) {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort,
        order,
      });
    },
  },
};
</script>

<style lang="scss">
.sort-component {
  .column-infos {
    border-top: 1px solid #efefef;
    padding: 10px 0 10px 0;
    // margin-top: 10px;
    color: #4d4f5c;
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 1fr 1fr 1fr 2fr;
    align-items: center;
    justify-content: space-between;
    .sort-item {
      display: flex;
      align-items: center;
      .label {
        margin: 0;
      }
      .sorter {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: #adadad;
        span {
          &:hover {
            cursor: pointer;
            color: #4d4f5c;
          }
        }
        .el-icon-caret-top {
          transform: translate(0, 20%);
          &.active {
            color: #272727;
          }
        }
        .el-icon-caret-bottom {
          transform: translate(0, -20%);
          &.active {
            color: #4d4f5c;
          }
        }
      }
    }
    span {
      text-align: left;
      font-size: 12px;
    }
    .more-options {
      // text-align: center;
      display: flex;
      justify-content: flex-end;
      div {
        width: 100%;
        max-width: 200px;
      }
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}
</style>

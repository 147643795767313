<template>
  <el-dialog
    custom-class="action-modal"
    :append-to-body="true"
    :title="'Set existing campaign'"
    :visible.sync="isVisible"
    width="30%"
    data-test-id="myGamesSetCampaignModal"
    @close="close"
  >
    <div class="formAction">
      <ul>
        <li v-for="(item, ind) in choicesCampaign" :key="ind">
          <el-radio
            v-model="campaignId"
            :label="item.id"
            @input="changeGenreValue($event, item)"
          >{{ item.label }}</el-radio
          >
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">Cancel</el-button>
      <el-button
        type="primary"
        data-test-id="myGamesSetCampaignConfirm"
        @click="
          updateValueBeforeSendAssocCampaign(campaignId);
          close();
        "
      >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';

export default {
  name: 'SetCampaignModal',
  props: {
    game: {
      type: Object,
      default: null,
    },
    genreObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      campaignId: '',
      choicesCampaign: [],
      isVisible: false,
      actionList,
    };
  },
  mounted() {
    this.setStudioCampaign();
  },
  methods: {
    async setStudioCampaign() {
      const param = {
        id: this.game.id,
        action: 'update_studio_campaign',
      };
      // eslint-disable-next-line no-await-in-loop
      const rep = await this.$store.dispatch(
        'games/getAvailableChoiceForASpecificAction',
        param,
      );

      // eslint-disable-next-line max-len
      this.campaignId = rep.currentid && rep.current_id.facebook
        ? rep.current_id.facebook
        : rep.current_id;

      if (this.campaignId.facebook) {
        this.campaignId = this.campaignId.facebook;
      }
      this.$emit('campaignIdUpdate', this.campaignId);
      // eslint-disable-next-line max-len
      this.choicesCampaign = rep.choices && rep.choices.facebook
        ? rep.choices.facebook
        : rep.choices;
      this.isVisible = true;
    },
    changeGenreValue(param, el) {
      const obj = cloneDeep(this.genreObj);
      obj[el.category] = param;
      //   this.genreObj = obj;
      this.$emit('updateGenre', obj);
    },
    updateValueBeforeSendAssocCampaign(campaignId) {
      if (this.actionList) {
        let action = this.actionList.filter(
          el => el.id === 'update_studio_campaign',
        )[0];
        const post = {
          post: {
            format: 'application/json',
            field: [
              {
                type: 'Object',
                param: 'choice',
                deepParam: ['facebook'],
                value: campaignId,
              },
            ],
          },
        };
        action = {
          ...action,
          ...post,
        };
        const param = {
          id: this.game.id,
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
      }
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
  },
};
</script>

import { Array } from 'core-js';

export default {
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    currentTags: {
      get() {
        // As of now, games.tags is a string of tags separated by a | that is then made into an Array
        if (!this.game || !Array.isArray(this.game.tags)) return [];

        return this.tags;
      },
      set(newValue) {
        this.tags = newValue;
      },
    },
  },
};

<template>
  <div class="upload-image-component">
    <div v-if="iconUrl" class="gfs-rows gfs-preview">
      <GameIcon v-if="!loading" :game="game" :icon-url="iconUrl"></GameIcon>
      <i v-else class="el-icon-loading"></i>

      <div class="gfs-columns gfs-form-input-details">
        <div data-test-id="uploadImageGameIconName">{{ iconName }}</div>

        <button
          v-if="!loading"
          class="gfs-form-input-btn gfs-delete"
          data-test-id="uploadImageRemoveGameIcon"
          @click="removeIcon"><img src="@/assets/images/garbage-popin.svg" />Delete</button>
      </div>
    </div>
    <div v-else>
      <div class="gfs-form-input" @click="openInput('game-icon')"><img src="@/assets/images/uploadblue.svg" data-test-id="uploadImageUploadGameIconButton"/>Upload file</div>
      <input
        id="game-icon"
        ref="game-icon"
        hidden
        type="file"
        accept="image/*"
        class="form-control"
        data-test-id="uploadImageGameIcon"
        @change="iconSelected" />
    </div>
    <div class="errors-messages">
      <ul>
        <li v-for="(error, index) in errors" :key="index" v-html="error"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';

export default {
  name: 'UploadImage',
  components: { GameIcon },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      loading: false,
      iconName: '',
      iconUrl: '',
    };
  },
  computed: {},
  mounted() {
    if (this.game.icon) {
      this.setState(true);
      this.iconUrl = this.game.iconUrl;
    }
  },

  methods: {
    setState(value) {
      this.$emit('icon-state', value);
    },
    openInput(ref) {
      if (Array.isArray(this.$refs[ref])) {
        this.$refs[ref][0].click();
      } else {
        this.$refs[ref].click();
      }
    },
    removeIcon() {
      this.iconUrl = undefined;
      this.setState(false);

      this.$forceUpdate();
    },
    async iconSelected(e) {
      e.preventDefault();
      this.errors = [];
      const file = e.target.files[0];
      if (file.size > 2000000) {
        this.errors.push('Icon size is no valid. 2MB max.');
      }
      if (this.errors.length === 0 && file) {
        const response = await this.addImageProcess(file);
        if (response !== false) {
          this.setState('loading');
          const responseIcon = await this.updateIcon(response);
          if (responseIcon === true) {
            this.setState(true);
          } else {
            this.iconName = '';
          }
        }
      }
    },
    updateIcon(value) {
      return new Promise(async (resolve) => {
        const form = new FormData();
        form.append('attached', value);
        this.loading = true;
        const response = await this.$store.dispatch('submitGame/updateIconGame', {
          id: this.game.id,
          value: form,
        });
        this.loading = false;

        if (response.error) {
          this.errors.push(response.error);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    },
    addImageProcess(file) {
      const cpFiel = file;
      return new Promise((resolve) => {
        const img = new Image();
        img.src = window.URL.createObjectURL(cpFiel);

        cpFiel.url = img.src;
        this.iconUrl = cpFiel.url;
        this.iconName = cpFiel.name;
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          if (width !== height) {
            this.errors.push('Icon must be square.');
            resolve(false);
          }
          if (width < 200 || height < 200) {
            this.errors.push('Icon width/heigth is not valid. 200px min.');
            resolve(false);
          }
          if (width > 2000 || height > 2000) {
            this.errors.push('Icon width/heigth is not valid. 2000px max.');
            resolve(false);
          }
          resolve(cpFiel);
        };
      });
    },
  },
};
</script>

<style lang="scss">
.upload-image-component {
  .errors-messages {
    ul {
      li {
        color: $blood-normal;
        font-size: 11px;
        margin-top: 10px;
      }
    }
  }
  .gfs-rows {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
  .gfs-preview {
    background-color: #f7fbff;
  }
}
</style>

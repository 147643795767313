<template>
  <div v-if="game" class="gs-content">
    <div class="learn-more">
      <img width="16" height="16" src="@/assets/images/info-icon.svg">
      <span class="learn-more-text">Learn more about controls & genres in this <a data-test-id="genreSelectorCategoryLearnMoreArticleLink" href="https://voodoo.zendesk.com/hc/en-us/articles/6231949254044-Game-controls-and-genres">article</a></span>
    </div>
    <div class="gs-rows gs-category-parent mt-20" :style="isColumn ? columnContainerStyle : {}">
      <div
        v-for="category of genres"
        :key="category.category"
        class="gs-columns gs-category"
        :style="isColumn ? columnStyle : {}"
        data-test-id="genreSelectorCategory">
        <div class="gs-columns gs-cat-title">{{ cleanCategoryName(category.category) }}</div>
        <div class="gs-columns">
          <label
            v-for="genre of category.options"
            :key="genre.id"
            :class="{ 'hasCompetition': genresForComp.includes(genre.id) }">
            <el-radio
              :disabled="isDisabled[category.category] || disabled"
              :value="isSelected[category.category]"
              :label="genre.id"
              data-test-id="genreSelectorRadio"
              @input="selectGenre(category, genre)"
            >{{ genre.label }}</el-radio>
            <div class="gs-checkmark-comp-container">
              <span v-if="genresForComp.includes(genre.id)" class="gs-checkmark-comp">
                Eligible for the
                <a v-if="competition.eventUrl" :href="competition.eventUrl" target="_blank">
                  {{ competition.name }}
                  <i class="el-icon-top-right"></i>
                </a>
                <template v-else>
                  {{ competition.name }}
                </template>
              </span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';

export default {
  name: 'GenresSelector',
  props: {
    game: {
      type: Object,
      default() {
        return {};
      },
    },
    genresForComp: {
      type: Array,
      default: () => [],
    },
    competition: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelected: [],
      isDisabled: [],
      selectedGenres: {},
    };
  },
  computed: {
    genres() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].genres : [];
    },
    columnContainerStyle() {
      return {
        flexDirection: 'column',
      };
    },
    columnStyle() {
      return {
        marginRight: 0,
        marginTop: '24px',
      };
    },
  },
  created() {
    if (this.game) {
      this.game.genres.forEach((genre) => {
        this.selectedGenres[genre.category] = genre;
        this.isSelected[genre.category] = genre.id;
      });
      if (this.game.genres.length >= this.genres.length) {
        this.setState(true);
        this.$emit('selectedGenres', this.selectedGenres);
      }
    } else {
      this.setState(false);
    }
  },
  methods: {
    async selectGenre({ category }, genre) {
      this.isDisabled[category] = true;
      this.$forceUpdate();
      this.selectedGenres[category] = genre;
      const rep = await this.sendGenresGame(this.selectedGenres);
      if (rep) {
        if (Object.keys(this.selectedGenres).length >= this.genres.length) {
          this.setState(true);
        } else {
          this.setState(false);
        }
      }

      this.isDisabled[category] = false;
      this.isSelected[category] = genre.id;
      this.$forceUpdate();
      this.$emit('selectedGenres', this.selectedGenres);
    },
    setState(value) {
      this.$emit('genres-state', value);
    },
    async sendGenresGame(choice) {
      const fchoice = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key in choice) {
        if (choice[key] !== null) {
          fchoice.push(choice[key].id);
        }
      }
      if (actionList) {
        const action = actionList.filter(el => el.id === 'set_game_genres')[0];
        action.post.field[0].value = fchoice;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        await this.$store.dispatch('submitGame/setGameGenre', param);
      }
      return new Promise(async (resolve) => {
        resolve(true);
      });
    },
    cleanCategoryName(name) {
      return name.replace(/_/g, ' ');
    },
  },
};
</script>
<style scoped lang="scss">
.hasCompetition {
  margin-bottom: 15px !important;
  margin-top: 7px;
}

.gs-cat-title {
  text-transform: capitalize;
}

.gs-content {
  font-size: 12px;
  font-weight: 600;
  color: #4d4f5c;
  width: 100%;
  margin-bottom: $spacing * 3;
  margin-top: $spacing * 2
}
.gs-rows {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}
.gs-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  label {
    margin: math.div($spacing, 2) 0;
  }

  label:hover {
    color: $intense-blue;
  }
}
.gs-category {
  flex-grow: 1;
  flex-basis: 0;
  border-radius: 3px;
  background-color: $foggy-blue;
  padding: 15px;
  margin-right: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 700px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.gs-category:last-child {
  margin-right: 0;
  margin-top: 5px;
}
.gs-category > div {
  margin-bottom: 15px;
}
.gs-content > .gs-rows {
  margin-top: 5px;
  @media (max-width: 700px) {
    margin-top: 5px !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
}

/* Customize the label (the container) */
.gs-container {
  display: flex;
  position: relative;
  align-items: center;
  padding: 3px 0;
  // padding-left: 27px;
  font-weight: normal;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-radio {
  margin-right: 5px !important;
}

.gs-checkmark-comp-container {
  display: inline;
}

.gs-checkmark-comp {
  color: #adadad;

  a {
    color: #adadad;
  }
}
.learn-more {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: normal;

  .learn-more-text {
    padding:5px;

  }
}
</style>

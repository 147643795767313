<template>
  <div :class="$style.container">
    <h2 id="title-appConfig">
      {{title}}
    </h2>
    <div :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <ContentfulFacebookDecryptionKey
        v-if="isFacebook && isAndroid"
        :decryption-key="game.adNetworks.facebook.decryptionKey"
        :game-id="game.id"
        @decryptionKeyIsValid="(e) => {decryptionKeyIsValid = e}"
      />
      <TestSdk
        v-if="game.isManagedByVoodoo && isFacebook"
        title="Test my Facebook App configuration"
        :test-url="getFacebookConfigurationTestUrl()"
        :error-list="codification.errorList"
        data-test-id="gameSubmissionTestSDKFacebookAppConfig"
      />
      <br>
      <p v-if="!(game.isManagedByVoodoo && isFacebook) && !(isFacebook && isAndroid)" :class="$style.text">
        <span>No action required here.</span><br>
        You just need to follow the tutorial on the right 👉</p><br>
      <ImportantNotice
        v-if="warningContent && warningContent.show"
        :class="$style.notice"
        :content="warningContent && warningContent.text"/>
      <el-button
        v-if="!steps[index].isValid"
        id="validate-appconfig"
        type="primary"
        size="small"
        :disabled="steps[index].isValid"
        data-test-id="gameSubmissionAppConfigValidate"
        @click="validateStep(index)" >
        <span>Validate</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import tools from '@/tools/tools';
import ContentfulFacebookDecryptionKey from '@/components/GameSubmissionSteps/components/ContentfulFacebookDecryptionKey.vue';
import steps from '@/modules/ScrollStepper/data/steps';
import ImportantNotice from '@/components/Generic/ImportantNotice/ImportantNotice.vue';
import TestSdk from './TestSdk.vue';

export default {
  components: {
    TestSdk,
    ContentfulFacebookDecryptionKey,
    ImportantNotice,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: 'no name',
    },
    current: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
    warning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isIntersecting: false,
      isvisible: false,
      decryptionKeyIsValid: false,
    };
  },
  computed: {
    noticeContent() {
      return 'Content to fetch from Contentful';
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
    isFacebook() {
      return steps.isfacebookCampaign(this.game);
    },
    isSnapChat() {
      return steps.isSnapchatCampaign(this.game);
    },
    isAndroid() {
      return this.game.platform === 'android';
    },
    isIos() {
      return this.game.platform === 'ios';
    },
    warningContent() {
      if (!this.warning) {
        return {
          show: false,
          text: '',
        };
      }
      return {
        text: !this.warning ? '' : this.warning.finalizeAppConfig,
        show: !this.warning ? false : this.warning.finalizeAppConfigWarningIsVisible,
      };
    },
  },
  mounted() {
    tools.attachEvent(document.getElementById('container-scrollable'), 'scroll', this.update);
    tools.attachEvent(window, 'resize', this.update);
  },
  methods: {
    getFacebookConfigurationTestUrl() {
      return `${process.env.VUE_APP_PUBLIC_API_URL}/game/${this.game.id}/check_facebook_configuration`;
    },
    update() {
      const titleElement = document.getElementById('title-appConfig');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    validateStep(index) {
      this.$emit('isValid', index);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}

.text {
  font-weight: 600;
  margin-bottom: $spacing;
}

.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  position: relative;
   .layer{
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}
</style>

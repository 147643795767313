<template>
  <div class="wrapper" :class="game.status">
    {{ formatStatus(game) }}
  </div>
</template>

<script>
import Tools from '@/tools/tools';

export default {
  name: 'GameStatus',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  methods: {
    formatStatus(game) {
      const st = Tools.getWhichFilterGameIs(game);
      return st[0].toUpperCase() + st.slice(1).replace(/_/g, ' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: $spacing/2 12px;
  background-color: $grey;
  color: $white;
  border-radius: 15px;
  font-size: 10px;
  white-space: nowrap;
  font-weight: 600;
}

.in_testing {
  padding-left: 10px !important;
  background-color: #58c783;
}
.finished {
  padding-left: 10px !important;
}
.pending_review,
.pending_sdk_integration,
.pending_testing {
  padding-left: 10px !important;
  background-color: #ff8d00;
  color: var(--main-font-basic-color-revert);
}
</style>

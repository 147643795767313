/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="query-builder-selector">
    <el-popover
      ref="querybuilder-popover"
      placement="bottom"
      min-width="600"
      trigger="click"
      @hide="isHiding"
      @show="isShowing">
      <div>
        <VueQueryBuilder v-model="filter" :rules="gameColumn"></VueQueryBuilder>
      </div>
      <el-button
        slot="reference"
        size="mini"
        class="querybuilder-button vd-icon">
        <div class="inner-button-selector">
          <i class="vd-svg-create-filters-dark"></i>
          <span>Create filters </span>
        </div>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </el-popover>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import VueQueryBuilder from '@/components/QueryBuilder/VueQueryBuilder.vue';

export default {
  name: 'QueryBuilderSelector',
  components: { VueQueryBuilder },
  data() {
    return {
      show: false,
      isScrolling: false,
    };
  },
  computed: {
    filter: {
      get() {
        return cloneDeep(this.$store.getters['views/filter']) || '';
      },
      set(value) {
        this.$store.dispatch('views/setFilter', value);
      },
    },
    gameColumn() {
      if (this.$store.getters['users/codification'] !== null) {
        return this.$store.getters['users/codification'].columns.games;
      }
      return [];
    },
  },
  watch: {},
  mounted() {},
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.show) {
        this.$refs['querybuilder-popover'].doClose();
        window.clearTimeout(this.isScrolling);

        this.isScrolling = setTimeout(() => {
          this.$refs['querybuilder-popover'].doShow();
        }, 66);
      }
    },
    isShowing() {
      this.show = true;
    },
    isHiding() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
body[data-theme="dark"] {
  .vue-query-builder .card {
    background-color: var(--el-button-background-color);
  }
  .vue-query-builder .card .btn-secondary {
    color: $color-info-plain-color-dark;
    background-color: $bg-info-plain-color-dark;
    border-color: $border-info-plain-color-dark;
  }
  .vue-query-builder .card select {
    color: $color-info-plain-color-dark;
    background-color: $bg-info-plain-color-dark;
    border-color: $border-info-plain-color-dark;
  }
  .vue-query-builder .card .close {
    color: $color-danger-plain-color-dark;
    background-color: $bg-danger-plain-color-dark;
    border-color: $border-danger-plain-color-dark;
  }
  .vue-query-builder .card {
    border: 1px solid var(--el-button-border-color);
  }
  .vue-query-builder .card .card-body {
    border-left: 1px solid var(--el-button-border-color);
  }
  .vue-query-builder .card .rule-actions {
    border-bottom: 1px solid var(--el-button-border-color);
  }
  .vue-query-builder .card .btn-secondary:not(.mr-2):after {
    background-color: $border-info-plain-color-dark;
  }
  .vue-query-builder .vqb-group.depth-1 .vqb-rule,
  .vue-query-builder .vqb-group.depth-2 {
    border-left: 2px solid #000d1b;
  }
  input::-webkit-inner-spin-button {
    background-color: $border-info-plain-color-dark;
  }
  .vue-query-builder .card button,
  .vue-query-builder .card select,
  .vue-query-builder .card input[type="text"],
  .vue-query-builder .card input[type="date"],
  .vue-query-builder .card input[type="number"] {
    color: $color-info-plain-color-dark;
    background-color: $bg-info-plain-color-dark;
    border-color: $border-info-plain-color-dark;
    &::placeholder {
      color: #383d4b;
    }
  }
}

.query-builder-selector {
  min-width: 30%;
  overflow: hidden;
  margin-right: 5px;
  .querybuilder-button {
    width: 100%;
    span {
      overflow: hidden;
      width:100%;
      text-align: left;
    }
    ::v-deep .inner-button-selector{
      justify-content: flex-start;
      gap: 10px;
    }
  }
  .el-button--mini {
    padding: 6px 7px 5px 7px;
    border-radius: 3px;
    width: 100%;
  }
  .inner-button-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    span {
      overflow: hidden;
    }
  }
}
.vue-query-builder {
  @media (max-width: 700px) {
    max-height: 400px;
    overflow: auto;
  }
  label {
    // white-space: nowrap !important;
  }
  .el-slider__runway {
    width: 250px;
    margin-right: 20px;
  }

  .card {
    border: 1px solid #f7fbff;
    padding: 4px;
    padding: 5px 10px;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    border-left: 2px solid #3293ff63;
    position: relative;
    overflow: hidden;

    @media (max-width: 700px) {
      display: grid !important;
      grid-template-columns: 100% !important;
      .match-type-container {
        flex-direction: row !important;
        min-width: 90px !important;
        justify-content: flex-start !important;
        padding-bottom: 10px !important;
      }
      .card-body {
        border-top: 1px solid var(--el-button-border-color);
        border-left: 0px !important;
        padding: 0px !important;
        width: 100% !important;
      }
      .match-type-container {
        &.form-inline {
          display: flex !important;
          gap: 5px !important;
          align-items: center !important;
        }
      }
      .form-inline {
        display: grid !important;
        // grid-template-columns: 3fr 1fr 1fr !important;
        // grid-template-rows: 1fr !important;

        gap: 5px !important;
      }
      select {
        max-width: 100px !important;
      }
    }
    // &:hover {
    //   .close {
    //     display: block;
    //   }
    // }
    &.depth-2 {
      // border: 1px solid #8aadff;
    }

    .card-body {
      padding: 10px;
      border-left: 1px solid #dcdfe6;
      width: 10%;
    }
    .card-body {
      padding: 0 10px 10px 10px;
      border-left: 1px solid #dcdfe6;
      width: 90%;
    }
    .form-inline {
      padding: 10px;
      display: flex;
      .close {
      }
    }
    .match-type-container {
      flex-direction: column;
      min-width: 90px;
      label {
        font-size: 10px;
        color: #c2c2c2;
        padding-bottom: 2px;
      }
      .close {
        margin-top: 4px;
      }
    }
    .query-builder-group {
      &:hover {
        .close {
          display: block;
        }
      }
    }
    .close {
      color: #f9a7a7;
      background-color: #fef0f0;
      border-color: #fde2e2;
    }
    .btn-secondary {
      color: #8cc5ff;
      background-color: #ecf5ff;
      border-color: #d9ecff;
      &:not(.mr-2) {
        margin-left: 20px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 30px;
          margin-left: -30px;
          margin-top: -20px;
          background-color: #dcdfe6;
        }
      }
    }
    .rule-actions {
      padding: 0;
      border-bottom: 1px solid #dcdfe6;
      margin-bottom: 10px;
      margin-top: 5px;
      .form-group {
        min-width: 100px;
      }
    }
    .form-inline {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .form-group {
      margin-bottom: 10px;
    }

    button,
    select,
    input[type="text"],
    input[type="date"],
    input[type="number"] {
      line-height: 1;
      // white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      color: #606266;
      -webkit-appearance: none;
      // text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      padding: 7px 15px;
      font-size: 12px;
      border-radius: 3px;
      margin-right: 10px;
      &::placeholder {
        color: #dcdfe6;
      }
    }
    label {
      margin-right: 10px;
      // white-space: nowrap;
    }
    select {
      background: url("http://www.stackoverflow.com/favicon.ico") calc(100% - 5px) / 12px no-repeat #eee;
      background-image: url("https://lh3.googleusercontent.com/proxy/yBuo6BdwXJssQIlxFS7Xh1R6JKungnozJH8yRnVGWNuHkJABP_7g5zFJSVEDruHaA_8B5Ka2t0vDPRl0_1w-yxuow_OBY2hiaGiFVvykUthZOdKMsJoDKD2jiPP8Ooenyg");
      padding: 7px 20px 7px 7px;
      &:before {
        content: "\e79a";
      }
    }
  }
}
//
</style>

<template>
  <div class="footer">
    <el-button
      v-if="!isFirstStep"
      type="primary"
      size="small"
      @click="goToPreviousStep">
      <i class="el-icon-back"></i>
      Back
    </el-button>

    <el-button
      v-if="!isLastStep"
      type="primary"
      size="small"
      :disabled="!canGoToNextStep"
      @click="goToNextStep">
      Next
      <i class="el-icon-right"></i>
    </el-button>

    <el-button
      v-if="isLastStep"
      type="primary"
      size="small"
      :disabled="!canGoToNextStep"
      @click="submitForm">
      Submit
      <i class="el-icon-right"></i>
    </el-button>
  </div>
</template>

<script>

export default {
  name: 'FormNavigation',
  components: { },
  props: {
    currentStepIndex: {
      type: Number,
      default: 0,
    },
    nbSteps: {
      type: Number,
      default: 1,
    },
    canGoToNextStep: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFirstStep: this.currentStepIndex === 0,
      isLastStep: this.nbSteps === this.currentStepIndex + 1,
    };
  },
  computed: {
  },
  methods: {
    goToPreviousStep() {
      const previousStepIndex = this.currentStepIndex - 1;
      this.$emit('go-to-step', previousStepIndex);

      this.isLastStep = false;
      if (previousStepIndex === 0) {
        this.isFirstStep = true;
      }
    },
    goToNextStep() {
      if (!this.canGoToNextStep) {
        return;
      }

      const nextStepIndex = this.currentStepIndex + 1;
      this.$emit('go-to-step', nextStepIndex);

      this.isFirstStep = false;
      if (nextStepIndex === this.nbSteps - 1) {
        this.isLastStep = true;
      }
    },
    async submitForm() {
      if (this.canGoToNextStep) {
        this.$emit('submit-form');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: block;
  width: 640px;
  margin: 0 auto;
  margin-bottom: 12px;
  padding: 0;
  color: #202124;
  overflow-wrap: break-word;
}
</style>

/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import ProjectService from '@/modules/GameProject/project.service';
import gameActionService from '@/services/gameAction.services';

const Project = {
  namespaced: true,
  state: {
    project: {},
    loading: false,
  },
  getters: {
    project(state) {
      return state.project;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    set_project(state, project) {
      state.project = cloneDeep(project);
    },
    set_project_name(state, name) {
      state.project.name = name;
      state.project = cloneDeep(state.project);
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    fetchGame(state, game) {
      if (!state.project?.games) return;
      for (let i = 0; i < state.project.games.length; i += 1) {
        const element = state.project.games[i];
        if (element.id === game.id) {
          state.project.games[i] = {
            ...state.project.games[i],
            ...game,
          };
          state.project = cloneDeep(state.project);
          break;
        }
      }
    },
    check_operation(state, checkOperation) {
      if (!state.project?.games) return;
      for (let i = 0; i < state.project.games.length; i += 1) {
        const element = state.project.games[i];
        if (element.id === checkOperation.game.id) {
          state.project.games[i] = {
            ...element,
            checkOperation: checkOperation.value,
          };
          state.project = cloneDeep(state.project);
          break;
        }
      }
    },
    set_disabled_game(state, param) {
      if (!state.project?.games) return;
      for (let i = 0; i < state.project.games.length; i += 1) {
        const element = state.project.games[i];
        if (element.id === param.id) {
          state.project.games[i] = {
            ...element,
            disabled: param.value,
          };
          state.project = cloneDeep(state.project);
          break;
        }
      }
    },
    removeGameFromProject(state, gameId) {
      if (!state.project?.games) return;
      for (let i = 0; i < state.project.games.length; i += 1) {
        const element = state.project.games[i];
        if (element.id === gameId) {
          state.project.games.splice(i, 1);
          state.project = cloneDeep(state.project);
          break;
        }
      }
    },
  },
  actions: {
    async getAllProjects(context, studioId) {
      return new Promise((resolve, reject) => {
        ProjectService.getAllProjects(studioId)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
      });
    },
    async getProject(context, id) {
      return new Promise(async (resolve) => {
        const project = await ProjectService.getProject(id);
        if (project) {
          context.commit('set_project', project);
          const gameList = project.games;
          for (let i = 0; i < gameList.length; i += 1) {
            const element = gameList[i];
            this.dispatch('Project/getGameById', element);
          }
        }
        resolve(project);
      });
    },
    async getProjectObject(context, id) {
      return new Promise(async (resolve) => {
        const project = await ProjectService.getProjectObject(id);
        if (project) {
          context.commit('set_project', project);
        }
        resolve(project);
      });
    },
    async editProject(context, data) {
      return new Promise(async (resolve) => {
        const project = await ProjectService.editProject(data);
        if (project === true) {
          context.commit('set_project_name', data.name);
        } else {
          const errorList = context.rootState.users.codification.errorList;
          let errorMessage = '';
          try {
            errorMessage = errorList[project.message].message;
          } catch (error) {
            errorMessage = 'error message not found';
          }
          resolve(errorMessage);
        }
        resolve(true);
      });
    },
    async getGameById(context, gameObject) {
      return new Promise(async (resolve) => {
        const game = await gameActionService.getStatsByGameId(gameObject);
        if (game) {
          context.commit('fetchGame', game);
        }
        resolve(game);
      });
    },
    removeGameFromProject(context, gameId) {
      context.commit('removeGameFromProject', gameId);
    },
  },
};

export default Project;

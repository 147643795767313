import i18n from '@/i18n';

export const customRules = [
  {
    label: 'minmax', // usage <ValidationProvider rules="minmax:3,15">
    rules: {
      validate: (value, args) => {
        const length = value.length;
        return length >= args.min && length <= args.max;
      },
      params: ['min', 'max'],
    },
  },
];

export const customMessages = {
  messages: {
    required: () => i18n.t('common.errors.required'),
    email: () => i18n.t('common.errors.email'),
  },
  custom: {
    minmax: () => i18n.t('common.errors.characters'),
  },
};

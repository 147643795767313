<template>
  <div class="container-password-reset-form">
    <h2>Create new password</h2>
    <el-tooltip
      class="item"
      effect="dark"
      placement="right">
      <div slot="content">
        <p class="password-requirement-row">Must be between 8 and 100 characters</p>
        <p class="password-requirement-row">Must contain at least 1 lowercase letter</p>
        <p class="password-requirement-row">Must contain at least 1 uppercase letter</p>
        <p class="password-requirement-row">Must contain at least 1 digit</p>
      </div>
      <el-input
        v-model="newPwd1"
        type="password"
        prefix-icon="el-icon-lock"
        placeholder="New password"
        auto-complete="new-password"
        @blur="errorCheck"
      ></el-input>
    </el-tooltip>
    <div v-if="errorDisplay" class="error-container">Your two password fields don’t match</div>
    <div class="password-confirm-container">
      <el-input
        v-model="newPwd2"
        type="password"
        prefix-icon="el-icon-lock"
        placeholder="Confirm new password"
        auto-complete="new-password"
        @input="errorCheck"
      ></el-input>
      <font-awesome-icon v-if="passwordMatch" icon="check" class="password-icon" />
    </div>
    <el-button
      style="display: revert;"
      type="primary"
      :disabled="loading || !passwordMatch"
      @click="submit"
    >
      Confirm
    </el-button>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import { getErrorDisplay } from '@/tools/errors';

export default {
  name: 'PasswordResetForm',
  props: {
    username: {
      type: String,
      required: true,
    },
    resetToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPwd1: '',
      newPwd2: '',
      loading: false,
      errorDisplay: false,
    };
  },
  computed: {
    passwordMatch() {
      return !!this.newPwd1 && !!this.newPwd2 && this.newPwd1 === this.newPwd2;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      const rawError = await this.$store.dispatch('users/resetPassword', {
        username: this.username,
        resetToken: this.resetToken,
        newPwd1: this.newPwd1,
        newPwd2: this.newPwd2,
      });

      if (rawError) {
        Notification.error({
          title: 'Error',
          message: getErrorDisplay(rawError),
          position: 'bottom-left',
        });
      }

      this.loading = false;
    },
    errorCheck() {
      this.errorDisplay = !this.passwordMatch;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-password-reset-form {
  h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  .el-input {
    margin-bottom: 20px;
  }

  .password-confirm-container {
    position: relative;
  }

  .password-icon {
    position: absolute;
    top: 25%;
    right: $spacing * 2;
    color: $green-alert;
  }

  .error-container {
    color: $red-alert;
    font-size: 10px;
    text-align:left;
    margin-bottom: $spacing;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  p.password-requirement-row {
    font-size: 1.2em;
    margin: 3px;
  }
}
</style>

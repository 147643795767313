import { cloneDeep } from 'lodash';
import axiosCancel from 'axios-cancel';
import axios from '@/tools/axios';

axiosCancel(axios, {
  debug: false, // default
});
export default class GamesService {
  static async fetchGames(params) {
    const p = cloneDeep(params);

    const requestId = `my_sample_request${JSON.stringify(p)}`;

    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/games`,
      data: p,
      method: 'post',
      requestId,
    })
      .then(r => r.data)
      .catch(() => ({ data: null }));

    axios.cancel(requestId);
    return call;
  }
}

<template>
  <div v-if="studioInfos && !loading" :class="$style.studioPage" class="studioPage">
    <Header :studio-infos="studioInfos" />
    <SubHeader
      :id="id"
      :studio-infos="studioInfos"
      @open-settings="isFormVisible = true"
    />
    <transition @enter="enterForm" @leave="leaveForm">
      <StudioForm
        v-if="codification && myPermissions && isFormVisible && events"
        v-model="isFormVisible"
        :class="$style.form"
        :studio="studioInfos"
        @open-history="isHistoryVisible = true"
      />
    </transition>
    <FilterGameList :studio="studioInfos" />
    <GamesList v-if="studioInfos.id" :studio-id="studioInfos.id" />
    <div
      :class="[$style.overlay, isFormVisible ? $style.displayOverlay : '']"
      @click="isFormVisible = false"
    ></div>
    <el-dialog
      v-if="id && events"
      :append-to-body="true"
      :title="studioInfos.studioName + ' history'"
      :visible.sync="isHistoryVisible"
      width="500px"
      class="studioDialog"
    >
      <EventList v-if="isHistoryVisible" :id="id"/>
    </el-dialog>

  </div>
  <div v-else>
    <div :class="$style.loading">
      <i class="el-icon el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import GamesList from '@/modules/GamesList/components/GamesList.vue';
import FilterGameList from '@/modules/GamesList/components/Filter.vue';
import Header from './components/Header.vue';
import SubHeader from './components/SubHeader.vue';
import StudioForm from './components/StudioForm.vue';
import EventList from './components/Events/EventList.vue';

export default {
  name: 'StudioModule',
  components: {
    Header,
    SubHeader,
    StudioForm,
    GamesList,
    FilterGameList,
    EventList,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isFormVisible: false,
      isHistoryVisible: false,
    };
  },
  computed: {
    studioInfos() {
      return this.$store.getters['studios/studioInfos'] || {};
    },
    loading() {
      return this.$store.getters['studios/loadingInfos'];
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    events() {
      return this.$store.getters['studios/studioEvents'] || null;
    },
    creation() {
      return this.events?.creation;
    },
    updates() {
      return this.events?.updates;
    },
  },
  mounted() {
    this.getStudio();
  },
  methods: {
    async getStudio() {
      await this.$store.dispatch('studios/getInfosStudioWithStudioId', this.id);
      await this.$store.dispatch('studios/getStudioEvents', this.id);
    },
    enterForm(element, done) {
      anime({
        targets: element,
        opacity: 1,
        translateX: [element.getBoundingClientRect().width, 0],
        duration: 500,
        easing: 'easeOutExpo',
        complete() {
          done();
        },
      });
    },
    leaveForm(element, done) {
      anime({
        targets: element,
        opacity: 0,
        translateX: [0, element.getBoundingClientRect().width],
        duration: 500,
        easing: 'easeOutExpo',
        complete() {
          done();
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.studioPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  margin-top: -20px;
  position: relative;
}

.form {
  position: fixed;
  top: 0;
  width: 420px;
  right: 0;
  z-index: 200;
  height: 100vh;
  opacity: 0;
  overflow: auto;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  z-index: 199;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
  display: none;
  transition: all 300ms;
}

.displayOverlay {
  opacity: 0.3;
  display: block;
}
</style>

<style lang="scss">
.studioDialog .el-dialog__body {
  padding: 0 !important;
}
</style>

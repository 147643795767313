<template>
  <div class="slider" :style="'grid-template-columns:' + ' 1fr'.repeat(tabs.length)">
    <div
      v-for="(currentElement, index) in tabs"
      :key="index"
      class="section"
      :class="{ active: sectionSelected === index }"
      @click="switchView(index)">
      <span class="slider-icon" :class="'vd-svg-' + currentElement.icon.toLowerCase()"></span>
      <span class="slider-name">{{ currentElement.label }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabsSlider',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sectionSelected: 0,
    };
  },
  mounted() {
    this.$emit('activeTabChanged', this.tabs[0]);
  },
  methods: {
    switchView(index) {
      this.sectionSelected = index;
      this.$emit('activeTabChanged', this.tabs[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  display: grid;
  width: 100%;
  border-bottom: 1px solid $foggy-grey;
  top: 190px;
  z-index: 1;
  border-top: 1px solid $light-grey;
  @media screen and (max-width: 700px) {
    top: 280px;
  }
  .section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: $slate;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 3px;
      bottom: 0px;
      background-color: $intense-blue;
      transition: all 0.35s ease;
    }
    &:hover {
      cursor: pointer;
    }
    &.active,
    &:hover {
      color: $intense-blue;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0px;
        background-color: $intense-blue;
      }
    }
    .slider-name {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-transform: capitalize;
      line-height: normal;
    }
    .slider-icon {
      min-width: 15px;
      min-height: 15px;
      margin: 2px 5px 2px 2px;
    }
  }
}
</style>

<template>
  <el-dialog
    :key="componentKey"
    custom-class="action-modal"
    :append-to-body="true"
    :title="'Edit game tags'"
    :visible.sync="visible"
    :width="dialogSize"
    @close="closeDialog"
  >
    <div class="formAction">
      <div :class="$style.genresSection">
        <ul
          v-for="(genre, index) in genresList"
          :key="index"
          :class="$style.genreSection"
        >
          <div>
            <h3 :class="$style.genreName">
              {{ genre.category.replace(/_/g, " ") }}
            </h3>
          </div>
          <li
            v-for="(item, ind) in genre.options"
            :key="ind"
            :class="$style.genreOption"
          >
            <el-radio
              :value="genres[genre.category]"
              :label="item.id"
              @input="changeGenreValue($event, genre)"
            >{{ item.label }}</el-radio
            >
          </li>
        </ul>
      </div>
      <GameTagsInputs
        :game="game"
        :result="searchResult"
        @search="searchTags"
        @error="handleTagsError"
        @ready="isTagsReady"
        @tags="setTags"
      ></GameTagsInputs>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button data-test-id="editTagsModalCancelButton" @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        data-test-id="editTagsModalConfirmButton"
        :disabled="!ready || Object.keys(genres).length < genresList.length"
        @click="confirm(genres)">
        Confirm
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import GameTagsInputs from '@/components/GameSubmissionForm/GameTagsInputs/GameTagsInputs.vue';
import tagsMixin from '@/components/GameSubmissionForm/GameTagsInputs/mixin';

export default {
  name: 'GameGenreDialog',
  components: {
    GameTagsInputs,
  },
  mixins: [tagsMixin],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    genreObj: {
      type: Object,
      default: () => {},
    },
    game: {
      type: Object,
      required: true,
    },
    actionList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchResult: [],
      isTagsError: false,
      displayed: this.isDisplayed,
      ready: false,
      genres: this.genreObj,
      visible: this.isVisible,
      arrayOfTags: this.game.tags || [],
      componentKey: 0,
    };
  },
  computed: {
    dialogSize() {
      let size = '70%';
      if (this.$bp('md+')) {
        size = '770px'; // template size
      } else if (this.$bp('md-')) {
        size = '90%';
      }
      return size;
    },
    genresList() {
      return this.$store.getters['users/codification']
        ? this.$store.getters['users/codification'].genres
        : [];
    },
  },
  watch: {
    isVisible(value) {
      this.visible = value;
      this.componentKey += 1;
      this.genres = cloneDeep(this.genreObj);
    },
    genreObj(value) {
      this.genres = value;
    },
  },
  methods: {
    isTagsReady(value) {
      this.ready = value;
    },
    async refreshGame() {
      await this.$store.dispatch(
        'ActionToDispatch/refreshGame',
        this.game.id,
      );
    },
    searchTags(tag) {
      this.$store.dispatch('genreTags/searchTags', tag)
        .then((response) => {
          this.searchResult = response.data;
        }).catch(() => {
          this.searchResult = null;
        });
    },
    changeGenreValue(param, el) {
      const obj = cloneDeep(this.genres);
      obj[el.category] = param;
      this.genres = obj;
    },
    closeDialog() {
      this.$emit('close');
      this.nb += 1;
    },
    cancel() {
      this.closeDialog();
    },
    confirm(genreObj) {
      if (!this.ready) {
        this.isTagsError = true;
        return;
      }
      this.isTagsError = false;
      this.sendGamegenreActionWithparam(genreObj);

      this.closeDialog();
    },
    async sendTags() {
      const tagParams = {
        tags: this.arrayOfTags,
        gameId: this.game.id,
      };
      await this.$store.dispatch('genreTags/putTags', tagParams);
    },
    async sendGamegenreActionWithparam(choice) {
      const fchoice = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in choice) {
        if (choice[key] !== null) {
          fchoice.push(choice[key]);
        }
      }
      if (this.actionList) {
        const action = this.actionList.filter(
          el => el.id === 'set_game_genres',
        )[0];
        action.post.field[0].value = fchoice;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
      }
      await this.sendTags();

      await this.refreshGame();
    },
    handleTagsError() {
      this.isTagsError = true;
    },
    setTags(tagsArray) {
      this.arrayOfTags = tagsArray;
    },
  },
};
</script>

<style lang="scss" module>
.genresSection {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;

  @include bp(xxs up) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @include bp(xs up) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @include bp(sm up) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.genreSection {
  background-color: $foggy-blue;
  padding: 10px 20px;
}

.genreName {
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.genreOption + .genreOption {
  margin-top: 10px;
}

.tagsSection {
  padding: 30px 0px 20px 0px;

  * {
    line-height: initial;
    font-size: $font-size-small;
  }
}

.tagsInfo {
  color: $grey;
  margin-bottom: 20px;
}

.tagsExemple {
  color: $grey;
  font-style: italic;
  word-break: break-word;
}

.tagsError {
  color: $red-alert;
}
</style>

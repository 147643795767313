<template>
  <div class="games-in-progress">
    <div
      v-for="(item, ind) in myGamesInProgress"
      :id="`game-${item.id}`"
      :key="`${item.id}-${ind}`"
      class="game">
      <AsyncAction :game="item"></AsyncAction>
      <div v-if="item.disabled" class="disabled-game">
        <span>There are ongoing operations</span>
        <span>
          <i class="el-icon-loading"></i>
        </span>
      </div>
      <HeaderCompactStudioGameCardInProgress :item="item"></HeaderCompactStudioGameCardInProgress>

      <div class="middle-content" :class="{ cpi: !item.isCtr }"></div>
    </div>

    <div v-if="myGamesInProgress.length === 0 && !loading" class="no-data">
      <el-image
        class="preview"
        style="
                        width: 40%;
                        padding: 20px;
                        margin: 5px;
                        border-radius: 5px;
                        max-width: 700px;"
        :src="desert"
        :fit="'cover'"
      >
        <div slot="error" class="image-slot">
          <span><img :src="gameIcon" :width="40"/></span>
        </div>
      </el-image>
      <span class="title-no-game">You haven’t submit a game yet!</span>
      <span class="quote-no-game">Start by submitting a game and follow the process here</span>
      <CreateGameButton></CreateGameButton>
    </div>
    <InfiniteLoading ref="InfiniteLoadingInprogress" @infinite="getMyGames">
      <div slot="spinner" class="spinner">
        <i class="el-icon-loading loading-list" />
      </div>
      <div slot="no-more" class="no-more">No more games</div>
      <div slot="no-results" class="no-more">
        <div class="no-results"></div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';
import AsyncAction from '@/components/AsyncAction.vue';
import CreateGameButton from '@/components/CreateGameButton.vue';
import HeaderCompactStudioGameCardInProgress from '@/components/HeaderCompactGameCardInProgress.vue';
import tools from '@/tools/tools';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import desert from '../assets/images/desert.svg';

export default {
  name: 'GameInProgress',
  components: {
    CreateGameButton,
    InfiniteLoading,
    AsyncAction,
    HeaderCompactStudioGameCardInProgress,
  },
  props: {},
  data() {
    return {
      desert,
      gameIcon,
    };
  },
  computed: {
    showGameOnly() {
      return cloneDeep(this.$store.getters['gameAction/showGameOnly']);
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    myGamesInProgress() {
      return cloneDeep(this.$store.getters['gameAction/myGames']);
    },
    loading() {
      return cloneDeep(this.$store.getters['gameAction/myGamesLoading']) || false;
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.order;
    },
  },
  watch: {
    $route(value, old) {
      if (value.path !== old.path) {
        this.$store.dispatch('gameAction/reset_gameList');
        this.$refs.InfiniteLoadingInprogress.stateChanger.reset();
      }
    },
    showGameOnly() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInprogress.stateChanger.reset();
    },
    sort() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInprogress.stateChanger.reset();
    },
    order() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInprogress.stateChanger.reset();
    },
  },
  methods: {
    isManoCtr(item) {
      return item.isCtr;
    },
    set(game) {
      this.$store.dispatch('gameAction/setNextStep', {
        game,
        status: 'playstore_submission',
      });
    },
    getRange(value) {
      const nbStep = 8;
      const range = 5;

      const arrayRnage = [];
      let start = value - range / 2;
      start = Math.ceil(start);
      if (start + range > nbStep) {
        start = nbStep - range;
      }
      if (start < 1) {
        start = 1;
      }

      for (let i = 0; i < range; i += 1) {
        arrayRnage.push(start);
        start += 1;
      }
      return arrayRnage;
    },
    getMyGames($state) {
      this.$store.dispatch('gameAction/getMyGames', {
        loader: $state,
        status: 'in_progress',
        term: this.$route.query.term || '',
        type: this.$route.params.type || 'cpi',
      });
    },
    show(item) {
      this.$store.dispatch('gameAction/showDetail', item);
      if (item.show === true) {
        window.scroll(0, tools.findPos(document.getElementById(`game-${item.id}`), true));
      }
    },
    getTagsArray(item) {
      return item.split('|');
    },
  },
};
</script>

<style lang="scss">
.error-popover {
  color: #f56c6c;
  font-size: 12px;
  line-height: 15px;
  ul {
    list-style-type: disc;
    padding: 0 16px;
    white-space: break-spaces;
    word-break: break-word;
    text-align: left;
    li {
      margin-bottom: 5px;
    }
  }
}
.load {
  font-size: 30px;
  color: grey;
  margin-top: 70px;
}
.games-list {
  padding: 0px 14px;
  @media (max-width: 700px) {
    padding: 10px 10px;
  }
  .games-in-progress {
    .game {
      // height: 100px;
      display: flex;
      // position: sticky;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      margin-bottom: 10px;
      position: relative;
      .disabled-game {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
        border-radius: 10px;
        background-color: rgba(201, 224, 246, 0.89);
        // background-color: rgba(130, 189, 247, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #067bf0;
        font-weight: bold;
        span {
          margin-right: 10px;
        }
      }
      .middle-content {
        width: 100%;
        border-radius: 10px;

        // padding: 10px 10px 10px 10px;
        overflow: hidden;

        @media screen and (max-width: 800px) {
          .el-step {
            &.invisible {
              display: none;
            }
          }
        }
        .success {
          display: flex;
          justify-content: center;
          padding: 0px;
          color: #58c783;
          i {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        &.cpi {
          .el-steps {
            @media screen and (max-width: 800px) {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              .el-step {
                max-width: 100% !important;
              }
            }
          }
        }
        .el-step__head {
          &.is-process {
            color: #ffffff;
            border-color: #c5c5c5;
            color: #067bf0;
            border-color: #067bf0;

            .el-step__icon {
              color: #fff;
              background-color: #067bf0;
            }
          }

          .el-step__line {
            height: 1px;
          }
          .el-step__icon {
            border-width: 1px;
          }
        }
        .el-step__head.is-success {
          color: #067bf0;
          border-color: #067bf0;

          .el-step__icon {
            color: #fff;
            background-color: #067bf0;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .el-step__title {
          color: #adadad;
          font-size: 11px;
          line-height: 13px;
          &.is-success {
            color: #067bf0;
          }
          &.is-process {
            color: #067bf0;
          }
        }
      }
      .content-page-step {
        width: 100%;
      }
      .bottom-content {
        background-color: #e6f1fd;
        color: #067bf0;
        width: 100%;
        padding: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &:hover {
          cursor: pointer;
          background-color: #e0eefc;
        }
        span {
          padding: 5px;
          font-size: 10px;
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-no-game {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #82bdf7;
        line-height: 40px;
      }
      .quote-no-game {
        font-size: 15px;
        text-align: center;
        color: #82bdf7;
        margin-bottom: 30px;
      }
    }
  }
}
</style>

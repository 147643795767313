<template>
  <div class="campaign-setting-component">
    <div v-if="settings" class="container">
      <div v-for="(adnetwork, key) in settings.adnetworks" :key="key" class="adnetwork">
        <div class="budget">
          <div class="infos">
            <span class="icon"><i :class="`vd-svg-icon-${key}`"></i></span>
            <div class="container-text">
              <div class="title">
                <b v-html="text[key].title"></b>
              </div>
              <div class="desc">
                <div class="desc">
                  You can spend up to ${{ settings.maxDailyBudget }} per day.
                  <template v-if="!loading && !settings.isBudgetReadOnly">
                    <br />
                    Use the slider below to adjust the daily budget you want.
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="gauge">
            <CustomSlider
              v-model="settings.dailyBudgetPerAdNetwork[key].budget"
              :step="10"
              :min="0"
              :min-lock="settings.minDailyBudget"
              :max="settings.maxDailyBudget"
              :disabled="loading || settings.isBudgetReadOnly || campaignIsFinished"
              @change="
                manuallySetBudget({
                  newBudget: settings.dailyBudgetPerAdNetwork[key].budget,
                  adNetwork: key
                })
              "
            >
              <template v-slot:tooltip>$ {{ settings.dailyBudgetPerAdNetwork[key].budget }}</template>
              <template v-slot:maxValue>$ {{ settings.maxDailyBudget }}</template>
            </CustomSlider>
          </div>
          <div class="divider"></div>
        </div>
      </div>

      <div class="campaign-duration">
        <div class="duration-container">
          <div class="text">
            <div class="title" v-html="text['duration'].title"></div>
            <div class="desc">
              Your campaign duration has been set for {{ settings.currentCampaignDuration }} days.
              <template v-if="!loading && !settings.isDurationReadOnly && !campaignIsFinished">
                <br />
                Use the slider below to increase the campaign up to {{ settings.maxCampaignDuration }} days.
              </template>
            </div>
          </div>
          <div class="gauge">
            <CustomSlider
              v-model="settings.currentCampaignDuration"
              :min="0"
              :min-lock="settings.minCampaignDuration"
              :max="settings.maxCampaignDuration"
              :disabled="loading || settings.isDurationReadOnly || campaignIsFinished"
              @change="manuallySetDuration(settings.currentCampaignDuration)"
            >
              <template v-slot:tooltip>{{ settings.currentCampaignDuration }} days</template>
              <template v-slot:maxValue>{{ settings.maxCampaignDuration }} days</template>
            </CustomSlider>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="campaign-endDate">
        <div class="text">
          <div class="title">
            <b>{{ text["endDate"].title }} {{ endDate | localeNumericDate }}</b>
          </div>
          <div class="desc">
            {{ text["endDate"].desc }}
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="submit">
        <el-button
          v-if="canEditDuration"
          type="primary"
          :disabled="!isChanged"
          :loading="loading"
          @click="submitSettings()">
          Validate
        </el-button>
      </div>
    </div>
    <div v-if="load" class="loader"><i class="el-icon-loading"></i></div>
  </div>
</template>

<script>
/* eslint-disable no-extend-native */
import { Notification } from 'element-ui';
import { cloneDeep } from 'lodash';
import CustomSlider from '@/components/Generic/CustomSlider.vue';
import actionList from '@/static/actionList';
import gameActionService from '@/services/gameAction.services';

export default {
  name: 'CampaignSettingComponent',
  components: { CustomSlider },
  props: {
    gameObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      load: false,
      settings: null,
      game: null,
      loadingUpdate: false,
      text: {
        facebook: {
          title: 'Daily budget on Facebook',
        },
        snapchat: {
          title: 'Daily budget on Snapchat',
        },
        duration: {
          title: 'Campaign duration',
        },
        endDate: {
          title: 'End date:',
          desc: ' If you want to stop the campaign you can just archive the game.',
        },
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters['submitGame/nextStepLoading'];
    },
    endDate() {
      if (this.settings && this.settings.lastIt) {
        const iterationStart = this.settings.lastIt.iterationStart;
        const newDate = this.addDays(iterationStart, this.settings.currentCampaignDuration);
        return newDate;
      }

      const lastIt = this.game.iterations[this.game.iterations.length - 1];
      const iterationStart = lastIt.iterationStart;
      const duration = this.game.currentCampaignDuration ? this.game.currentCampaignDuration : this.game.defaultCampaignDuration;
      const newDate = this.addDays(iterationStart, duration);
      return newDate;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canEditDuration() {
      if (this.gameObject && this.gameObject) {
        const perm = this.gameObject.permissions;
        // eslint-disable-next-line no-prototype-builtins
        const level1 = perm.hasOwnProperty('advanced_campaign_settings_level1');
        // eslint-disable-next-line no-prototype-builtins
        const level2 = perm.hasOwnProperty('advanced_campaign_settings_level2');
        return level1 || level2;
      }
      return false;
    },
    campaignIsFinished() {
      if (this.endDate) {
        const date = new Date(this.endDate);
        const now = new Date();
        return now > date;
      }
      return true;
    },
    isChanged() {
      if (this.settings && this.game) {
        let gameSettings = {
          adnetworks: cloneDeep(this.game.adNetworks),
          dailyBudgetPerAdNetwork: cloneDeep(this.game.dailyBudgetPerAdNetwork),
          currentCampaignDuration: cloneDeep(this.game.currentCampaignDuration) || cloneDeep(this.game.defaultCampaignDuration),
          defaultCampaignDuration: cloneDeep(this.game.defaultCampaignDuration),
          minCampaignDuration: cloneDeep(this.game.minCampaignDuration),
          maxCampaignDuration: cloneDeep(this.game.maxCampaignDuration),
          maxDailyBudget: cloneDeep(this.game.maxDailyBudget),
          minDailyBudget: cloneDeep(this.game.minDailyBudget),
          isBudgetReadOnly: true, // cloneDeep(this.game.isBudgetReadOnly),
          isDurationReadOnly: !this.canEditDuration,
        };
        if (this.game.iterations) {
          const lastIt = this.game.iterations[this.game.iterations.length - 1];
          gameSettings.lastIt = lastIt;
        }

        gameSettings = JSON.stringify(gameSettings);
        const updatedSettings = JSON.stringify(this.settings);

        if (gameSettings !== updatedSettings) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  watch: {
    gameObject: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.settings = null;
  },
  methods: {
    async init() {
      if (this.gameObject) {
        if (this.gameObject.iterations) {
          this.game = cloneDeep(this.gameObject);
        } else {
          this.load = true;
          this.game = await gameActionService
            .getStatsByGameId({
              id: this.gameObject.id,
            })
            .then(rep => rep);
          this.load = false;
        }
        if (this.game) {
          this.settings = {
            adnetworks: cloneDeep(this.game.adNetworks),
            dailyBudgetPerAdNetwork: cloneDeep(this.game.dailyBudgetPerAdNetwork),
            currentCampaignDuration: cloneDeep(this.game.currentCampaignDuration) || cloneDeep(this.game.defaultCampaignDuration),
            defaultCampaignDuration: cloneDeep(this.game.defaultCampaignDuration),
            minCampaignDuration: cloneDeep(this.game.minCampaignDuration),
            maxCampaignDuration: cloneDeep(this.game.maxCampaignDuration),
            maxDailyBudget: cloneDeep(this.game.maxDailyBudget),
            minDailyBudget: cloneDeep(this.game.minDailyBudget),
            isBudgetReadOnly: true, // cloneDeep(this.game.isBudgetReadOnly),
            isDurationReadOnly: !this.canEditDuration,
          };
          if (this.game.iterations) {
            const lastIt = this.game.iterations[this.game.iterations.length - 1];
            this.settings.lastIt = lastIt;
          }
        } else {
          this.setting = {};
        }
      }
    },
    manuallySetBudget(value) {
      const setting = cloneDeep(this.settings);
      setting.dailyBudgetPerAdNetwork[value.adNetwork].budget = value.newBudget;
      this.setting = setting;
    },
    manuallySetDuration(value) {
      this.settings.currentCampaignDuration = value;
    },
    async updateBudget({ newBudget, adNetwork }) {
      return new Promise(async (resolve) => {
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
        try {
          await this.$store.dispatch('submitGame/updateGameBudget', {
            id: this.game.id,
            newBudget,
            adNetwork,
          });
          resolve(true);
        } catch (e) {
          const message = e.message || 'An error occurred, please refresh or contact support (studio-support@voodoo.io)';
          Notification.error({
            title: 'Error',
            message,
            position: 'bottom-left',
          });
          resolve(false);
        }
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
      });
    },
    async updateCampaignDuration(campaignDuration) {
      return new Promise(async (resolve) => {
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
        try {
          await this.$store.dispatch('submitGame/updateGameCampaignDuration', {
            id: this.game.id,
            campaignDuration,
          });
          resolve(true);
        } catch (e) {
          const message = e.message || 'An error occurred, please refresh or contact support (studio-support@voodoo.io)';
          Notification.error({
            title: 'Error',
            message,
            position: 'bottom-left',
          });
          resolve(false);
        }
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
      });
    },
    async submitSettings() {
      this.loadingUpdate = true;
      if (this.settings.currentCampaignDuration !== this.game.currentCampaignDuration) {
        const reponseSetDuration = await this.updateCampaignDuration(this.settings.currentCampaignDuration);
        if (reponseSetDuration) {
          await this.setActionWithoutParams('extend_campaign');
        }
      }
      await this.$store.dispatch('ActionToDispatch/refreshGame', this.game.id);
      this.$emit('close-pop-in');
      this.loadingUpdate = false;
    },
    async setActionWithoutParams(actionValue) {
      if (actionList) {
        const action = actionList.filter(el => el.id === actionValue)[0];
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        await this.$store.dispatch('ActionToDispatch/dispatchAction', param);
      }
    },
    addDays(startDate, days) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + days);
      return date;
    },
  },
};
</script>

<style lang="scss">
.campaign-setting-component {
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    .budget {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .infos {
      display: flex;
      align-items: center;
      .icon {
        i {
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
      }
    }
    .adnetwork {
      display: flex;
      width: 100%;
    }
    .title {
      font-size: 12px;
      color: $slate;
      margin-bottom: 5px;
      line-height: 14px;
    }
    .desc {
      font-size: 12px;
      color: $grey;
      line-height: 14px;
    }
    .divider {
      width: 100%;
      height: 1px;
      margin: 30px 0 20px;
      background-color: $foggy-grey;
    }
    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

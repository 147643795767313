<template>
  <div class="last-step-container" :class="{'no-stepper':isIterating}">
    <Stepper
      v-if="!isIterating"
      class="stepper"
      :steps="stepsCp"
      :current="steps.length"
      @change="gotoStep"
    />
    <div class="step-content">
      <CampaignSetup :game="game" :genres="game.genres"></CampaignSetup>
    </div>
  </div>
</template>

<script>
import steps from '@/modules/ScrollStepper/data/steps';
import Stepper from '@/modules/ScrollStepper/components/Stepper.vue';
import CampaignSetup from '@/modules/GameSubmission/components/CampaignSetup.vue';
import tools from '@/tools/tools';

export default {
  name: 'LastStep',
  components: {
    Stepper,
    CampaignSetup,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      steps: steps.getAllStepArrayByGame(this.game),
      loading: false,
    };
  },
  computed: {
    genres() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].genres : undefined;
    },
    stepsCp() {
      const array = [];
      // eslint-disable-next-line array-callback-return
      steps.getAllStepArrayByGame(this.game).map((el) => {
        const element = el;
        element.isValid = true;
        element.state = 'done';
        array.push(element);
      });
      array[array.length - 1].state = 'current';
      return array;
    },
    isIterating() {
      return tools.getWhichFilterGameIs(this.game) === 'is_iterating';
    },
  },
  async mounted() {
    await this.$store.dispatch('ActionToDispatch/refreshGame', this.game.id);
  },
  methods: {
    gotoStep(index) {
      const substatus = {
        current: this.stepsCp[index].id,
        done: 'upload_asset',
      };
      this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.last-step-container {
  display:grid;
  grid-template-columns: repeat(12, 1fr);
  &.no-stepper{
    grid-template-columns: 1px 1fr;
  }

  h1, h2, h3 {
    font-weight: 700  !important;
    line-height: 100%  !important;
    letter-spacing: 0em  !important;
    color: var(--main-font-basic-color) !important;
  }
  p {
    height: 20px !important;
  }
  .footer{
    display: flex;
    gap: 20px;
    align-items: baseline;
    .footer-button{
      display: flex;
      align-items: center;
    }
  }
  .stepper {
    padding: 10px 25px;
    grid-column: 1;
    width: 180px;
    overflow-y: auto;
    height: calc(100vh - 63px) !important;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .step-content {
    grid-column: 2 / 14;
    height: calc(100vh - 63px);
    overflow-y: auto;
    padding: 10px;
    padding-top: 25px;
    h2 {
      font-size: 12px;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;
    }
    span {
      font-size: 13px;
    }
    a {
      color: #067bf0;
    }
  }
}
</style>

export default [
  'id',
  // 'name',
  // 'pmName',
  'pmId',
  'secondaryPmId',
  'isActivityStateForced',
  // 'secondaryPmName',
  // 'nbHits',
  // 'creationDate',
  // 'contract',
  'deactivateDate',
  'deactivateReason',
  'deactivateSince',
  'gamesCpi6To3Months',
  'gamesCpi3Months',
  // 'gamesCpiImprovement',
  'gamesCpiPercentage',
  'gamesCpiGreen',
  'gamesCtr6To3Months',
  'gamesCtr3Months',
  // 'gamesCtrImprovement',
  'gamesCtrPercentage',
  'gamesCtrGreen',
  'gamesDay16To3Months',
  'gamesDay13Months',
  // 'gamesDay1Improvement',
  'gamesDay1Percentage',
  'gamesDay1Green',
  'gamesDay76To3Months',
  'gamesDay73Months',
  // 'gamesDay7Improvement',
  'gamesDay7Percentage',
  'gamesDay7Green',
  // 'gamesDay1412To6Months',
  'gamesDay143Months',
  'gamesDay146To3Months',
  // 'gamesDay146Months',
  // 'gamesDay14Improvement',
  'gamesDay14Percentage',
  'gamesDay14Green',
  // 'gamesDay2812To6Months',
  'gamesDay283Months',
  'gamesDay286To3Months',
  // 'gamesDay286Months',
  // 'gamesDay28Improvement',
  'gamesDay28Percentage',
  'gamesDay28Green',
  // 'gamesDay9012To6Months',
  'gamesDay903Months',
  'gamesDay906To3Months',
  // 'gamesDay906Months',
  // 'gamesDay90Improvement',
  'gamesDay90Percentage',
  'gamesDay90Green',
  'gamesSessionLength6To3Months',
  'gamesSessionLength6To3MonthsHumanReadable',
  'gamesSessionLength3Months',
  'gamesSessionLength3MonthsHumanReadable',
  // 'gamesSessionLengthImprovement',
  'gamesSessionLengthPercentage',
  'gamesSessionLengthGreen',
  'gamesPlaytime6To3Months',
  'gamesPlaytime6To3MonthsHumanReadable',
  'gamesPlaytime3Months',
  'gamesPlaytime3MonthsHumanReadable',
  // 'gamesPlaytimeImprovement',
  'gamesPlaytimePercentage',
  'gamesPlaytimeGreen',
  'gamesNbPrototypes6To3Months',
  // 'gamesNbPrototypes12To6Months',
  // 'gamesNbPrototypes3Months',
  'gamesNbPrototypes6Months',
  'gamesNbPrototypesImprovement',
  'gamesNbPrototypesPercentage',
  'gamesNbPrototypesGreen',
];

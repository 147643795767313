<template>
  <div class="game-view-detail">
    <div class="submit-game-form">
      <div class="main-content-ks">
        <HeaderGameKS v-if="game && !loading" :item="game"></HeaderGameKS>
        <div v-if="game && !loading" class="adnetwork-slider">
          <TabsSlider :tabs="getAdnetworkSliderData()" @activeTabChanged="updateIteration($event)"></TabsSlider>
        </div>
        <div v-if="game && !loading" class="content-container-ks">
          <IterationGlobal :game="game" :selected-ad-network="selectedAdNetwork"></IterationGlobal>
        </div>
        <div v-if="loading" style="padding:30px;">
          <i class="el-icon el-icon-loading"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import backArrow from '@/assets/images/back-arrow.svg';
import idea from '@/assets/images/idea.svg';
import HeaderGameKS from '@/components/KnowledgeSharing/HeaderGameKS.vue';
import roket from '@/assets/images/roket.png';
import actionList from '@/static/actionList';
import tools from '@/tools/tools';
import gameActionService from '@/services/gameAction.services';
import IterationGlobal from '@/components/Game/IterationGlobal.vue';
import TabsSlider from '@/components/Generic/TabsSlider/TabsSlider.vue';

export default {
  name: 'SubmitGame',
  components: {
    IterationGlobal,
    HeaderGameKS,
    TabsSlider,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      backArrow,
      roket,
      actionList,
      idea,
      loading: false,
      game: null,
      selectedAdNetwork: null,
    };
  },
  computed: {
    loadingProperty() {
      return cloneDeep(this.$store.getters['submitGame/loadingProperty']);
    },
    stepsArray() {
      return tools.getAllStepArrayByGame(this.game);
    },
    isStepper() {
      const isFirstStep = this.stepsArray[0] ? this.stepsArray[0].id === 'createGame' : false;
      return this.stepsArray.length > 1 || isFirstStep;
    },
  },
  watch: {
    game(value) {
      if (!value) {
        this.getGameInfos();
      }
    },
    id() {
      this.getGameInfos();
    },
  },
  beforeDestroy() {
    this.$store.dispatch('submitGame/clean');
  },
  mounted() {
    this.getGameInfos();
  },
  methods: {
    getAdnetworkSliderData() {
      if (!this.game) {
        return [];
      }
      const adNetworks = Object.keys(this.game.adNetworks).map(a => this.game.adNetworks[a].name);
      const adNetworkConfigMap = {
        facebook: {
          label: 'Facebook',
          icon: 'facebook',
          id: (adNetworks.length <= 1) ? 'global' : 'facebook',
        },
        snapchat: {
          label: 'Snapchat',
          icon: 'snapchat',
          id: (adNetworks.length <= 1) ? 'global' : 'snapchat',
        },
      };
      const adnetworkLists = adNetworks.map(el => (adNetworkConfigMap[el] ? adNetworkConfigMap[el] : el));

      if (adNetworks.length > 1) {
        adnetworkLists.unshift({
          label: 'Global',
          icon: 'global',
          id: 'global',
        });
      }
      return adnetworkLists;
    },
    updateIteration(value) {
      this.selectedAdNetwork = value.id !== 'global' ? value.id : null;
    },
    async getGameInfos() {
      this.loading = true;
      await gameActionService
        .getStatsKSByGameId({
          id: this.id,
        })
        .then((rep) => {
          this.loading = false;
          if (rep.id) {
            this.game = rep;
          } else {
            this.game = {};
          }
        });
    },
  },
};
</script>

<style lang="scss">
.game-view-detail {
  width: 100%;
  .disabled-game {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 10px;
    background-color: rgba(201, 224, 246, 0.89);
    // background-color: rgba(130, 189, 247, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #067bf0;
    font-weight: bold;
    .container-text {
      margin-top: 40px;
      position: fixed;
      top: 0;
    }

    span {
      margin-right: 10px;
    }
  }
  .submit-game-form {
    display: grid;
    margin-top: 0px;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      .no-mobile {
        display: none;
      }
    }
    .header {
      margin-top: 0px;
      display: grid;
      align-items: center;
      grid-template-columns: 50px 1fr;
      justify-content: flex-start;
      font-size: 18px;
      color: #272727;
      padding: 0 20px;
      position: relative;
      top: 0px;
      background-color: #f7fbff;
      z-index: 10;
      .image {
        width: 100%;
        height: 100%;
        padding: 10px 0;
        &:hover {
          cursor: pointer;
        }
      }
      .backto {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
      }
      .title {
        text-align: left;
        padding: 10px 0px;
      }
    }

    .main-content-ks {
      padding: 0 20px;
      border-radius: 10px;
      background-color: #ffffff;
      // margin-top: 10px;
      position: relative;
      overflow: scroll;
      height: 100vh;
      &.no-stepper {
        margin: 0 0px;
      }
      @media (max-width: 700px) {
        margin: 0;
      }
      .header-submit-game-page {
        padding: 10px;
        border-bottom: 1px solid #e9e9f0;
        position: sticky;
        top: 0px;
      }
      .video-graph-slider {
        position: relative;
        top: 0;
      }
      .no-game {
        font-size: 12px;
        color: $darker-grey;
        padding: 50px;
      }
      .content-container-ks {
        display: grid;
        grid-template-columns: 1fr;
        .game-view-detail .submit-game-form .main-content {
          box-shadow: none !important;
        }

        @media (max-width: 700px) {
          grid-template-columns: 1fr;
        }
      }
    }

    @media (max-width: 700px) {
    }
  }
}
</style>

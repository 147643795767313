<template>
  <div class="select-projects-list-container" :class="{'selected':newProject}">
    <el-select
      v-model="newProject"
      value-key="name"
      placeholder="Select a project"
      popper-class="select-projects-list"
      filterable
      :disabled="disabled"
      data-test-id="projectsSelector"
      @change="projectChanged"
    >
      <el-option
        v-for="project in projectsList"
        :key="project.id"
        data-test-id="projectsOptions"
        :label="project.name"
        :value="project">
        <ProjectIcon :project="project"></ProjectIcon>
        <span>{{ project.name }}</span>
      </el-option>
    </el-select>
    <div v-if="newProject" class="icon-container">
      <ProjectIcon :project="newProject"></ProjectIcon>
    </div>
  </div>
</template>

<script>
import ProjectIcon from './ProjectIcon.vue';

export default {
  name: 'SelectProjectsList',
  components: {
    ProjectIcon,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      newProject: null,
      projectsList: [],
    };
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    async getProjects() {
      const studioId = this.game?.studio?.id
        ? this.game.studio.id
        : this.game.studioId;
      const projects = await this.$store.dispatch(
        'Project/getAllProjects',
        studioId,
      );
      this.projectsList = projects.filter(project => project.id !== this.game.projectId);
    },
    projectChanged(project) {
      this.$emit('change', project);

      // const projectInfos = this.projectsList.find(
      //   projectSelection => projectSelection.id === project.id,
      // );
      const bundleId = project?.platforms?.ios?.bundleId || project.platforms.android.bundleId;
      const storeId = project?.platforms?.ios?.storeId || project.platforms.android.storeId;

      this.$emit('projectInfos', {
        projectId: project.id,
        bundleId,
        storeId,
      });
    },
  },
};
</script>

<style lang="scss">
.select-projects-list-container {
    &.selected {
        .el-select {
            .el-input__inner {
                    padding-left: 40px;
            }
        }
    }
    .icon-container {
        position: absolute;
        margin-top: -32px;
        margin-left: 8px;
    }
    .el-select {
        width: 100%;
    }
}
.select-projects-list {
  .el-scrollbar li {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
</style>

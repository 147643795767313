<template>
  <div class="edit-videos">
    <div v-if="videosListUploaded.length > 0" class="video-list">
      <div v-for="itemAd in videosListUploaded" :key="itemAd.name" class="video-items">
        <span v-if="itemAd.videos.length > 0">{{ itemAd.name }} </span>
        <div class="video-infos">
          <div v-for="item in itemAd.videos" :key="item.id">
            <video
              :id="`videoIte-${item.id}`"
              :key="item.id"
              width="100%"
              muted="true"
              controls
              loop
              playsinline
              @mouseover="play(`videoIte-${item.id}`)"
              @mouseleave="pause(`videoIte-${item.id}`)"
            >
              <source :src="item.video" />
            </video>
            <div class="infos-video">
              <div class="video-name">
                <span></span>
              </div>
              <div class="button-video">
                <el-button
                  v-if="game.status !== 'pending_testing'"
                  :loading="item.loading"
                  type="danger"
                  plain
                  @click="deleteVideo(item.id)">
                  Delete
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="game.loadingAction" class="load">
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateVideosComponent',
  components: {},
  props: {
    game: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      fileList: null,
      crop: null,
      acceptedTypesFile: 'video/mp4, video/quicktime',
    };
  },
  computed: {
    videosListUploaded() {
      try {
        const last = [];
        Object.keys(this.game.iterations[this.game.iterations.length - 1].adNetworks).forEach((network) => {
          const data = this.game.iterations[this.game.iterations.length - 1].adNetworks[network];
          last.push({
            name: network,
            videos: data.videos,
          });
        });
        return last;
      } catch (error) {
        // this.$store.dispatch('gameAction/getVideoAvailable', this.game);
        return [];
      }
      // return [];
    },
  },
  watch: {
    fileList() {},
  },
  methods: {
    submitVideo() {},
    addAttachment() {},
    mute(value) {
      const video = document.getElementById(value);
      video.muted = true;
    },
    unMute(value) {
      const video = document.getElementById(value);
      video.muted = false;
    },
    play(value) {
      const video = document.getElementById(value);
      video.play();
      // this.unMute(value);
    },
    pause(value) {
      const video = document.getElementById(value);
      video.pause();
      this.mute(value);
    },
    deleteVideo(id) {
      this.$store.dispatch('gameAction/deleteVideoGameId', {
        gameId: this.game.id,
        id,
      });
    },
  },
};
</script>

<style lang="scss">
.video-items {
  display: flex;
  flex-direction: column;
  .video-infos {
    display: flex;
    div + div {
      margin-left: 10px;
    }
  }
}
.edit-videos {
  padding: 10px 10px 10px 10px;
  color: #adadad;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;

  .load {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 100%;
  }
  .video-list {
    display: flex;
    flex-direction: column;
    // flex-direction: row-reverse;
    width: 100%;
  }
  video {
    width: 150px;
    height: 220px;
    border-radius: 3px;
    background-color: #232323;
  }
  .el-upload {
    display: flex;
  }
  .el-upload-dragger {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f9ff;
  }
  .new-video {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #067bf0;
    font-size: 15px;
  }
  .inner {
    color: #c2c2c2;
  }
  .el-upload-dragger .el-icon-upload {
    font-size: 25px;
    color: #067bf0;
    margin: 10px;
    line-height: 20px;
    font-weight: 300;
  }
  .video-infos {
    padding: 10px 10px 10px 0px;
    display: flex;
    .infos-video {
      padding: 10px 10px 0px 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    // video {
    //   padding: 10px 10px 0px 10px;
    // }
    .video-name {
      margin-bottom: auto;
    }

    .button-video {
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }
  }
}
</style>

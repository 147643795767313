/**
 * Returns a depply cloned object without reference.
 * Copied from Vue MultiSelect and Vuex.
 * @type {Object}
 */
// eslint-disable-next-line func-names
const deepClone = function (obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  if (obj && typeof obj === 'object') {
    const cloned = {};
    const keys = Object.keys(obj);
    for (let i = 0, l = keys.length; i < l; i += 1) {
      const key = keys[i];
      cloned[key] = deepClone(obj[key]);
    }
    return cloned;
  }
  return obj;
};

export default deepClone;

<template>
  <div class="first-step-container">
    <Stepper
      class="stepper"
      :steps="steps"
      :current="0"
      @change="gotoStep"
    />
    <div class="step-content">
      <div class="field">
        <h2>Test name</h2>
        <div class="details">
          Name length must be between 3 and 25 characters.
        </div>
        <el-input
          v-model="gameCp.name"
          :disabled="!!gameCp.id"
          :minlength="3"
          :maxlength="25"
          class="name"
          placeholder="Enter the name of your test"
          data-test-id="gameSubmissionTestName"></el-input>
        <div class="error-name">
          <ul>
            <li v-for="(error, index) in errorName" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isDedicatedAdAccountGame" class="field" :class="{ disabled: !isDedicatedAdAccountGame }">
        <h2>Select your game setup</h2>
        <div class="container">
          <div
            class="choice"
            :class="{ selected: gameCp.isManagedByVoodoo }"
            data-test-id="gameSubmissionAutoOption"
            @click="setAuto(true)">
            <h3>Automatic</h3>
            <div class="details">
              This means that you can do every step of the process directly in the Voodoo Publishing Platform. Provide us with your game assets, and we’ll take care of the rest.
            </div>
            <div class="recommended">
              <span data-test-id="gameSubmissionRecommendedSetup">Recommended</span>
            </div>
          </div>
          <div
            class="choice"
            :class="{ selected: !gameCp.isManagedByVoodoo }"
            data-test-id="gameSubmissionManualOption"
            @click="setAuto(false)">
            <h3>Manual</h3>
            <div class="details">
              Set up your Facebook campaign and ads by yourself and link it back to the Voodoo Publishing Platform manually.
            </div>
          </div>
        </div>
      </div>
      <div class="field" :class="{ disabled: !canSwitchCtrOrPlatformOrAdNetwork }">
        <h2>Select the platform of your game</h2>
        <div class="container">
          <div
            class="choice"
            :class="{ selected: gameCp.platform === 'android' }"
            data-test-id="gameSubmissionAndroidOption"
            @click="setPlatform('android')">
            <h3>Android</h3>
            <div class="details">Test your game on Android users</div>
            <div v-if="currentCompetition && currentCompetition.allowedPlatforms.includes('android')" class="note-competition">
              Eligible for the
              <a v-if="currentCompetition && currentCompetition.eventUrl" :href="currentCompetition.eventUrl" target="_blank">
                {{ currentCompetition.name }}
                <i class="el-icon-top-right"></i>
              </a>
              <template v-else>
                {{ currentCompetition.name }}
              </template>
            </div>
            <div class="recommended">
              <span>Recommended</span>
            </div>
          </div>
          <div
            class="choice"
            :class="{ selected: gameCp.platform === 'ios' }"
            data-test-id="gameSubmissioniOSOption"
            @click="setPlatform('ios')">
            <h3>iOS</h3>
            <div class="details">Test your game on iOS users.</div>
            <div v-if="currentCompetition && currentCompetition.allowedPlatforms.includes('ios')" class="note-competition">
              Eligible for the
              <a v-if="currentCompetition.eventUrl" :href="currentCompetition.eventUrl" target="_blank">
                {{ currentCompetition.name }}
                <i class="el-icon-top-right"></i>
              </a>
              <template v-else>
                {{ currentCompetition.name }}
              </template>
            </div>
          </div>
          <div></div>
          <div v-if="!gameCp.isCtr && gameCp.platform === 'ios'" class="form-warning">
            <img :src="warningIcon" /> Warning: expect up to 3 days delay of install data with the release of iOS 14.5
          </div>
        </div>
      </div>
      <div v-if="!gameCp.isCtr" class="field">
        <h2>Is your game already published?</h2>
        <div class="container">
          <div
            class="choice"
            :class="{ selected: gameCp.isPublishedInStore }"
            data-test-id="gameSubmissionPublishedOption"
            @click="setPublished(true)">
            <h3>Yes, I created the game on the store</h3>
            <div class="details">
              If you’ve already created your game on the store, you don’t need to submit your game to the
              <span v-if="gameCp.platform === 'ios'">AppStore</span>
              <span v-else>Google PlayStore</span>, just fill in the primary information. If you’ve already done this, you should have the
              <span v-if="gameCp.platform === 'ios'">Store ID</span>

              <span v-else>Bundle ID</span>
              needed for the next steps.
            </div>
          </div>
          <div
            class="choice"
            :class="{ selected: !gameCp.isPublishedInStore }"
            data-test-id="gameSubmissionNotPublishedOption"
            @click="setPublished(false)">
            <h3>Not yet</h3>
            <div class="details">
              If you haven’t done this yet, don’t worry! There’s a tutorial waiting for you in the next step.
              <span v-if="gameCp.platform === 'ios'">
                Connect to your iTunes Developer Account (or create one) in preparation.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="adNetworkList.length > 1 && gameCp.isManagedByVoodoo && !gameCp.isCtr"
        class="field"
        :class="{ disabled: !canSwitchCtrOrPlatformOrAdNetwork }">
        <h2>Select the ad platform</h2>
        <el-checkbox-group v-model="selectedAdNetworkIdList" class="container multiple-choice" :disabled="!canSwitchCtrOrPlatformOrAdNetwork">
          <div v-for="adNetwork in adNetworkList" :key="adNetwork.id" @click="toggleAdNetwork(adNetwork.id)">
            <div class="choice">
              <el-checkbox
                :disabled="!canCreateNetworks"
                :label="adNetwork.id"
                data-test-id="gameSubmissionAdNetworkOption"
                @change="toggleAdNetwork(adNetwork.id)">
                {{ adNetwork.label }}
              </el-checkbox>
              <div v-if="getTagForNetwork(adNetwork.id) !== null" :class="getTagForNetwork(adNetwork.id).class">
                <span data-test-id="gameSubmissionRecommendedNetwork">{{ getTagForNetwork(adNetwork.id).label }}</span>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div v-else-if="!gameCp.isCtr && adNetworkList.length === 0 && gameCp.isManagedByVoodoo" class="loader"><i class="el-icon-loading"></i> Loading ad networks</div>
      <div class="footer">
        <div class="footer-button">
          <el-button
            v-if="!game.id"
            size="small"
            :disabled="!canSubmitGame"
            :loading="loading"
            type="primary"
            data-test-id="gameSubmissionFirstStepCreateButton"
            @click="createGame"
          >
            Create a test
          </el-button>
          <el-button
            v-if="game.id && !isChangedGame"
            size="small"
            :disabled="!canSubmitGame"
            :loading="loading"
            type="primary"
            data-test-id="gameSubmissionFirstStepNextStepButton"
            @click="goToNextStep">
            Next step
          </el-button>
          <el-button
            v-if="game.id && isChangedGame"
            size="small"
            :disabled="!canSubmitGame"
            type="primary"
            :loading="loading"
            data-test-id="gameSubmissionFirstStepUpdateButton"
            @click="saveChange">
            Update game
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import warningIcon from '@/assets/images/warning.svg';
import steps from '@/modules/ScrollStepper/data/steps';
import Stepper from '@/modules/ScrollStepper/components/Stepper.vue';
import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';

export default {
  name: 'FirstStep',
  components: {
    Stepper,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gameCp: this.game,
      warningIcon,
      isRegisteredForCompetition: false,
      current: 1,
      steps: [],
      loading: false,
      errorName: null,
      selectedAdNetworkIdList: [],
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/me'] || null;
    },
    canCreateNetworks() {
      return this.myPermissions.indexOf('use_snapchat_network') > -1
      || this.myPermissions.indexOf('use_facebook_network') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    currentCompetition() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].currentCompetition : undefined;
    },
    canSwitchCtrOrPlatformOrAdNetwork() {
      if (!this.gameCp.appServiceAppKey) {
        return true;
      }
      return false;
    },
    isDedicatedAdAccountGame() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    adNetworkList() {
      const studio = this.$store.getters['studios/studioInfos'];
      return studio.adNetworks ? studio.adNetworks : [];
    },
    canSubmitGame() {
      if (this.gameCp.name
        && this.gameCp.name.length >= 3
        && this.gameCp.name.length <= 25
        && (this.gameCp.selectedAdNetworkIdList?.length >= 1
        || this.game.isCtr || !this.game.isManagedByVoodoo)) {
        return true;
      }
      return false;
    },
    isChangedGame() {
      const copy = cloneDeep(this.gameCp);
      const original = cloneDeep(this.game);
      const originalAdnetwork = this.getSelectedNetworkIdList(this.game);
      const copyAdnetwork = this.gameCp.selectedAdNetworkIdList;
      delete original.loadingRequest;
      delete original.disabled;
      delete copy.disabled;
      // delete copy.selectedAdNetworkIdList;
      delete copy.loadingRequest;
      if (JSON.stringify(original) !== JSON.stringify(copy) || originalAdnetwork !== copyAdnetwork) {
        return true;
      }
      return false;
    },
  },
  watch: {
    adNetworkList() {
      this.setDefaultsForAdNetworkChoice();
    },
    gameCp: {
      handler(value) {
        if (value.selectedAdNetworkIdList?.length > 0) {
          this.steps = steps.getAllStepArrayByGame(value);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('studios/getInfosStudioWithStudioId', this.me.studios[0].id);
    const adnetworks = this.getAdnetwork();
    this.gameCp = {
      ...this.gameCp,
      selectedAdNetworkIdList: this.game.id ? adnetworks : this.getSelectedNetworkIdList(this.game),
    };
  },
  methods: {
    gotoStep(index) {
      const substatus = {
        current: this.steps[index].id,
        done: JSON.parse(this.game.substatus)?.done || 'create',
      };
      this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
    },
    getAdnetwork() {
      const isFacebook = tools.isfacebookCampaign();
      const isSnapChat = tools.isSnapChatCampaign();
      const adnetwork = [];
      if (isFacebook) {
        adnetwork.push(1);
      }
      if (isSnapChat) {
        adnetwork.push(2);
      }
      return adnetwork;
    },
    setAuto(value) {
      this.gameCp.isManagedByVoodoo = value ? 1 : 0;
      this.selectedAdNetworkIdList = [1]; // Manual game are Facebook only. 1 is Facebook's Ad Network id.
    },
    setPlatform(value) {
      if (this.gameCp.appServiceAppKey === undefined || this.gameCp.appServiceAppKey === null) {
        this.gameCp.platform = value;
      }
    },
    setPublished(value) {
      this.gameCp.isPublishedInStore = value;
    },
    setRegisteredCompetition(value) {
      if (value) {
        this.gameCp.competitionId = this.currentCompetition.id;
      } else {
        delete this.gameCp.competitionId;
      }
    },
    setDefaultsForAdNetworkChoice() {
      if (this.gameCp) {
        this.selectedAdNetworkIdList = this.getSelectedNetworkIdList(this.game).length > 0
          ? this.getSelectedNetworkIdList(this.game) : this.adNetworkList.map(adNetwork => adNetwork.id);
        this.gameCp.selectedAdNetworkIdList = this.selectedAdNetworkIdList;
      }
    },
    getTagForNetwork(id) {
      const AdNetworkTags = {
        1: {
          class: 'recommended',
          label: 'Recommended',
        },
      };

      return AdNetworkTags[id] ? AdNetworkTags[id] : null;
    },
    toggleAdNetwork(id) {
      if (!this.canSwitchCtrOrPlatformOrAdNetwork) {
        return;
      }
      const index = this.selectedAdNetworkIdList.indexOf(id);

      if (index > -1) {
        this.selectedAdNetworkIdList.splice(index, 1);
      } else {
        this.selectedAdNetworkIdList.push(id);
      }
      this.gameCp = {
        ...this.gameCp,
        selectedAdNetworkIdList: this.selectedAdNetworkIdList,
      };
    },
    getSelectedNetworkIdList(game) {
      let currentChoice = [];
      if (tools.isfacebookCampaign(game)) currentChoice.push(1);
      if (tools.isSnapChatCampaign(game)) currentChoice.push(2);
      currentChoice = currentChoice.length > 0 ? currentChoice : this.adNetworkList.map(adNetwork => adNetwork.id);
      return game.isCtr || !game.isManagedByVoodoo ? [1] : currentChoice;
    },
    createGame() {
      this.$nextTick().then(() => {
        this.loading = true;
      });
      this.gameCp.selectedAdNetworkIdList = this.$route.query.gameid === 'new' ? this.selectedAdNetworkIdList : this.getSelectedNetworkIdList(this.gameCp);
      delete this.gameCp.adNetworks;
      let status = null;

      try {
        status = JSON.parse(this.game.substatus)?.done || 'create';
      } catch (error) {
        status = 'create';
      }
      const substatus = {
        current: this.steps[1].id,
        done: status,
      };
      this.gameCp.substatus = JSON.stringify(substatus);

      this.$store.dispatch('submitGame/createGame', this.gameCp).then((response) => {
        if (response.detail) {
          this.errorName = response.detail?.response?.data?.name || null;
        } else {
          this.$router.push({
            query: { gameid: response.id },
          });
        }
        this.$nextTick().then(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.$nextTick().then(() => {
          this.loading = false;
        });
      });
    },
    goToNextStep() {
      let status = null;
      try {
        status = JSON.parse(this.game.substatus)?.done || 'create';
      } catch (error) {
        status = 'create';
      }
      const substatus = {
        current: this.steps[1].id,
        done: status,
      };
      this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
    },
    async saveChange() {
      this.loading = true;

      const test = {
        id: this.gameCp.id,
        isManagedByVoodoo: this.gameCp.isManagedByVoodoo,
        isPublishedInStore: this.gameCp.isPublishedInStore,
      };
      if (this.canSwitchCtrOrPlatformOrAdNetwork) {
        test.selectedAdNetworkIdList = this.selectedAdNetworkIdList;
        test.platform = this.gameCp.platform;
        test.isCtr = this.gameCp.isCtr;
      }

      await this.$store.dispatch('ActionToDispatch/updateGameProperty', {
        ...test,
        loading: 'property',
      });
      this.goToNextStep();
      this.$nextTick().then(() => {
        this.loading = false;
      });
    },
    isPreEligibleForCompetition() {
      // Returns true if the options selected in previous screens match the requirements of the competition
      if (!this.currentCompetition) {
        return false;
      }

      if (!this.currentCompetition.allowedPlatforms.includes(this.game.platform)) {
        return false;
      }

      if (this.game.isCtr && !this.currentCompetition.allowedTestingMethods.includes('ctr')) {
        return false;
      }

      if (!this.game.isCtr && !this.currentCompetition.allowedTestingMethods.includes('cpi')) {
        return false;
      }

      return true;
    },
    isEligibleForCompetition() {
      if (!this.isPreEligibleForCompetition) {
        return false;
      }

      if (!this.cpGenres) {
        return false;
      }

      for (const genre of Object.values(this.cpGenres)) {
        if (this.currentCompetition.allowedGenres.includes(genre.id)) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.first-step-container {
  display:grid;
  grid-template-columns: repeat(12, 1fr);
  // height: 90vh;

  h1, h2, h3 {
    font-weight: 700  !important;
    line-height: 100%  !important;
    letter-spacing: 0em  !important;
    color: var(--main-font-basic-color) !important;
  }
  p {
    height: 20px !important;
  }
  .footer{
    display: flex;
    gap: 20px;
    align-items: baseline;
    .footer-button{
      display: flex;
      align-items: center;
    }
  }
  .error-name {
    color: $color-danger-plain-color;
    font-size: 10px;
    margin-top: 5px;
    text-align: left;
  }
  .field {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0;
    .details {
      color: #adadad;
      font-size: 12px;
    }
    .name {
      width: 50%;
      margin-top: 10px;
    }
    &.disabled {
      .container {
        .choice {
          color: $slate;
          font-size: 12px;
          font-weight: 700;
          &.selected {
            cursor: not-allowed;
            border: solid 1px $light-blue;
            background-color: $cloudy-blue;
            .details {
              color: $slate;
            }
            &:hover {
              cursor: not-allowed;
              border: solid 1px $light-blue;
              background-color: $cloudy-blue;
              .details {
                color: $slate;
              }
            }
          }
          &:hover {
            cursor: not-allowed;
            border: solid 1px $cloudy-blue;
            background-color: #ffffff;
            .details {
              color: #adadad;
            }
          }
        }
      }
    }
    .note-competition {
      color: #adadad;
      text-align: center;
      font-size: 12px;

      a {
        color: #adadad;
      }
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      line-height: 15px;

      .recommended {
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url("~@/assets/images/recommended.svg");
        line-height: 11px;
        height: 19px;
        span {
          padding-left: 4px;
          font-size: 10px;
          color: #232323;
          font-weight: bold;
          text-align: center;
        }
        @media screen and (max-width: 700px) {
          position: relative;
          right: auto;
        }
      }

      .choice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border-radius: 10px;
        border: solid 1px $cloudy-blue;
        background-color: #ffffff;
        position: relative;
        ::v-deep .el-checkbox__label {
          color: $slate !important;
          font-size: 12px;
          font-weight: 700;
        }
        &:hover,
        &.selected {
          cursor: pointer;
          border: solid 1px $light-blue;
          background-color: $cloudy-blue;
          .details {
            color: $slate;
          }
        }
        h3 {
          margin-bottom: 10px;
          text-align: center;
        }
        .details {
          color: #adadad;
          font-size: 10px;
          text-align: center;
          span {
            font-size: 10px;
          }
        }
        .recommended {
          padding: 2px 5px 4px 5px;
        }
      }
      .form-warning {
        color: $color-danger-plain-color;
        font-size: 10px;
        text-align: center;
        img {
          padding-right: 2px;
        }
      }
      &.multiple-choice {
        padding: 0;
        margin: 0;
        .choice {
          display: flex;
          flex-direction: row;
          justify-content: left;
          padding: 10px;
          border-radius: 10px;
          border: solid 1px $cloudy-blue;
          background-color: #f7fbff;
          position: relative;
          &:hover {
            cursor: pointer;
            border: solid 1px $light-blue;
            background-color: $cloudy-blue;
          }
          .el-checkbox__input {
            border-radius: 10px;
          }
          .el-checkbox__label {
            color: $slate;
            font-size: 12px;
            font-weight: bold;
            padding-left: 8px;
          }
          .recommended {
            padding: 4px 6px 1px 6px;
          }
          .beta {
            background-color: $light-purple;
            margin-left: 10px;
            padding: 4px 6px 1px 6px;
            line-height: 10px;
            border-radius: 10px;
            text-align: center;
            span {
              font-size: 8px;
              color: $purple-alert;
              font-weight: bold;
              text-align: center;
            }
          }
        }
        .form-warning {
          margin-top: 10px;
        }
      }
    }
  }
  .stepper {
    padding: 10px 25px;
    grid-column: 1;
    width: 180px;
    overflow-y: auto;
    height: calc(100vh - 63px) !important;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .step-content {
    grid-column: 2 / 14;
    padding: 10px;
    padding-top: 25px;
    height: calc(100vh - 63px);
    overflow-y: auto;
    h2 {
      font-size: 12px;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;
    }
    span {
      font-size: 13px;
    }
    a {
      color: #067bf0;
    }
  }
}
</style>

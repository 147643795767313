<template>
  <div class="card">
    <div class="content">
      <div class="icon-container" :style="{ backgroundColor: color }">
        <font-awesome-icon :icon="icon" />
      </div>
      <div
        class="title"
        data-test-id="gameInfosKpiSmallTitle"
      >{{ title }}</div>
      <div
        class="value"
        data-test-id="gameInfosKpiSmallValue"
      >
        <span v-if="unitBefore && isValueAvailable">{{unit}}</span>
        {{ valueDisplay.toLocaleString() }}
        <span v-if="!unitBefore && isValueAvailable">{{unit}}</span>
      </div>
    </div>
    <el-tooltip v-if='tooltip' placement="top">
      <div
        slot="content"
        data-test-id="gameInfosKpiSmallTooltip"
      >{{ tooltip }}</div>
      <i class="icon vd-svg-kpismallicon" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'KpiSmall',
  props: {
    icon: {
      type: String,
      default: 'address-card',
    },
    // eslint-disable-next-line vue/require-default-prop
    tooltip: {
      type: String,
    },
    title: {
      type: String,
      default: 'Title',
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number],
    },
    // eslint-disable-next-line vue/require-default-prop
    unit: {
      type: String,
    },
    unitBefore: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#adadad',
    },
  },
  computed: {
    isValueAvailable() {
      return this.value && !Number.isNaN(this.value) && this.value !== 'NaN';
    },
    valueDisplay() {
      return this.isValueAvailable
        ? this.value : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  position: relative;
  text-align: left;
  font-family: OpenSans;

  .icon {
    position: absolute;
    right: $spacing * 3;
    top: $spacing * 3;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;

    .icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      svg {
        color: #fff;
      }
    }

    .title {
      font-size: 14px;
      color: $grey;
      margin: 10px 0px;
      &:first-letter{
        text-transform: capitalize
      }
    }

    .value {
      font-size: 18px;
      font-weight: 600;
      color: $soft-black;
    }
  }
}
</style>

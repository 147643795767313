<template>
  <div ref="CPIGraph" class="cpi-graph">
    <div class="title">
      {{ title }}
      <el-tooltip v-if="isCpiWarningVisible" placement="bottom" class="cpi-warning">
        <div slot="content">
          Warning: expect up to 3 days delay
          <br />
          of install data with the release of iOS 14.5
        </div>
        <span><i class="vd-svg-alert icon-alert"/></span>
      </el-tooltip>
    </div>
    <div class="container-graph" :class="{ 'no-data-graph': !data }">
      <highcharts ref="CPIGraph" :options="option"></highcharts>
    </div>
    <div v-if="!data" class="graph-placeholder">
      <div class="load-data-graph">
        <el-image class="engrenage1" :src="engrenage" :fit="'cover'">
          <div slot="error" class="image-slot">
            <span><img :src="gameIcon" :width="40"/></span>
          </div>
        </el-image>
        <el-image class="engrenage2" :src="engrenage" :fit="'contain'">
          <div slot="error" class="image-slot">
            <span><img :src="gameIcon" :width="40"/></span>
          </div>
        </el-image>
      </div>
      <span>
        No data available yet.
        <br />You need to wait a few hours to see the CPI.
      </span>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import moment from 'moment';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import engrenage from '../assets/images/engrenage.svg';

export default {
  name: 'GraphCPIIntesting',
  components: {
    highcharts: Chart,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    os: {
      type: String,
      default: 'ios',
    },
    title: {
      type: String,
      default: 'CPI',
    },
  },
  data() {
    return {
      engrenage,
      gameIcon,
    };
  },
  computed: {
    isKs() {
      return this.$route.params.sub === 'ks';
    },
    option() {
      const date = [];
      const data = [];
      if (this.data) {
        for (let i = 0; i < this.data.daily.length; i += 1) {
          const element = this.data.daily[i];

          date.push(moment(element.date).format('MM-DD'));
          element.y = element.value ? +element.value.toFixed(2) : null;
          data.push(element);
        }
      }

      return {
        chart: {
          type: 'line',
          height: 200,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: date,
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            // format: `${value}`,
          },
          min: 0,
          // max: maxCpi,
          // maxPadding: 1,
          // padding: 0.2,
          tickPixelInterval: 1,
          tickAmount: 5,
          plotLines: [
            // {
            //   value: minRate,
            //   color: 'green',
            //   dashStyle: 'shortdash',
            //   width: 2,
            //   label: {
            //     text: 'Last quarter minimum',
            //   },
            // },
          ],
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: true,
          },
          series: {
            pointStart: 0,
            max: 2.5,
            tickAmount: 1,
          },
        },
        tooltip: {
          // backgroundColor: '#FCFFC5',
          borderColor: '#58c783',
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: true,
          // shared: true,
          // <span class="infos-tooltip">Spend: <b>$${
          //   this.point.spend
          // }</b>
          formatter() {
            return `<span class="infos-tooltip date" style="color:#58c783;">${moment(this.point.date).format('MMMM Do YYYY')}</span><br/><b>CPI: $${this.point.y}</b><br/>
                        <br/>
                        </b><br/><span class="infos-tooltip">Installs: <b>${this.point.installs}</b></span>`;
          },
        },
        series: [
          {
            data,
            color: '#58c783',
            connectNulls: true,
          },
        ],
      };
    },
    isCpiWarningVisible() {
      return this.os === 'ios';
    },
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
.cpi-graph {
  display: flex;
  align-content: flex-start;
  flex-direction: column;

  position: relative;
  width: 100%;
  .container-graph {
    padding: 10px;
    width: 100%;
    &.no-data-graph {
      filter: opacity(0.2);
      // width: 33%;
    }
    // filter: blur(2px);
  }

  .highcharts-credits {
    display: none;
  }
  .highcharts-area {
    fill: rgba(225, 146, 85, 0.25);
  }
  .title {
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    padding-left: 20px;

    .cpi-warning {
      margin-left: 5px;
      z-index: 1;
    }
  }
  .no-data {
    padding: 20px;
    color: #adadad;
    font-size: 12px;
  }
  .graph-placeholder {
    // background-color: #00000033;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 54px;
    line-height: 15px;
    // margin: 20px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    // margin-top: -100%;
  }
  .infos-tooltip {
    font-size: 10px;
  }
  .load-data-graph {
    position: absolute;
    // margin-left: -20px;
    margin-top: -130px;
  }
  .engrenage1,
  .engrenage2 {
    // position: absolute;
  }
  .engrenage1 {
    width: 25px;
    height: 25px;

    top: 0;
    // right: 32%;
    animation: engrenage 3s linear infinite;
  }
  .engrenage2 {
    width: 40px;
    height: 40px;

    // top: 10px;
    // right: 36%;
    animation: engrenage1 3s linear infinite;
  }
  // height: 100px;
}
@keyframes engrenage {
  from {
    // background-size: cover;
    transform: rotate(-90deg);
    // background-position-x: 0px;
  }
  to {
    // background-size: cover;
    transform: rotate(90deg);
    // transform: 40px;
  }
}
@keyframes engrenage1 {
  from {
    // background-size: cover;
    transform: rotate(90deg);
    // background-position-x: 0px;
  }
  to {
    // background-size: cover;
    transform: rotate(-90deg);
    // transform: 40px;
  }
}
</style>

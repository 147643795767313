<template>
  <div :class="$style.wrapper">
    <div :class="$style.label">Bundle ID</div>
    <div :class="$style.explanation">
      Enter a new Bundle ID or reuse an existing one if you already submitted
      this game. Make sure to register the correct Bundle ID, you will not be
      able to edit it later on.
    </div>
    <div class="input">
      <el-input
        id="bundleId"
        :value="value"
        size="small"
        placeholder="e.g. com.voodoo.helixjump"
        :disabled="!!game.bundleId || !canCreateGaSlot"
        type="text"
        data-test-id="gameSubmissionBundleIdInput"
        @change="displayError"
        @input="changeValue"
        @blur="(e)=>changeValue(value)"
      ></el-input>
    </div>
    <div
      v-if="game.platform === 'android' && !game.isPublishedInStore"
      :class="$style.infos"
    >
      <span>⚠️ The Package Name structure must follow the below rules:</span>
      <ul>
        <li>
          <span>Be unique.</span>
        </li>
        <li>
          <span>Can't be changed.</span>
        </li>
        <li>
          <span>It must have at least two segments (one or more dots).</span>
        </li>
        <li>
          <span>Each segment must start with a letter.</span>
        </li>
        <li>
          <span>
            All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
          </span>
        </li>
      </ul>
    </div>
    <p v-if="errorDisplay" :class="$style.error">
      Valid Bundle IDs should look like com.voodoo.helixjump
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'BundleIdInput',
  props: {
    game: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isValidCpBundleId: true,
      errorDisplay: false,
    };
  },
  computed: {
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canCreateGaSlot() {
      return (
        this.gamePermissions.includes('create_ga_slot')
        || this.myPermissions.includes('create_ga_slot')
      );
    },
  },
  methods: {
    displayError(value) {
      this.changeValue(value);
      if (!this.isValidCpBundleId) {
        this.errorDisplay = true;
      } else {
        this.errorDisplay = false;
      }
    },
    validateBundleId() {
      const regexAndroid = /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/;
      const regexIos = /^([A-Za-z\d]{1}[A-Za-z\d-]*\.)+[A-Za-z\d][A-Za-z\d-]*$/;
      let regex = regexIos;
      if (this.game.platform === 'android') {
        regex = regexAndroid;
      }
      if (this.value) {
        this.isValidCpBundleId = regex.test(this.value);
      } else {
        this.isValidCpBundleId = false;
      }
      this.$emit('isValid', this.isValidCpBundleId);
    },
    changeValue(value) {
      this.validateBundleId();
      this.$emit('input', value);
      this.searchEmit(value);
    },
    // eslint-disable-next-line func-names
    searchEmit: debounce(function (value) {
      this.$emit('search', value);
    }, 300),
  },
};
</script>

<style lang="scss" module>
.wrapper {
  text-align: left;
  input {
    border-radius: 8px;
  }
}

.label {
  color: $slate;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}
.infos {
  font-size: 11px;
  line-height: 18px;
  color: $grey;
  margin: 10px 0;
  ul {
    list-style-type: decimal;
    margin-left: 20px;
  }
}
.explanation {
  color: $grey;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 15px;
}

.error {
  padding: $spacing 0;
  font-size: 12px;
  color: $blood-normal;
}
</style>

<template>
  <div>
    <div
      :class="$style.wrapper"
      data-test-id="studioPageImpersonateButton"
      @click="modalImpersonate = true">
      <font-awesome-icon :class="$style.icon" icon="user-secret" />
      <div :class="$style.text">Impersonate</div>
    </div>
    <ImpersonateDialog
      :studio-id="studioId"
      :open="modalImpersonate"
      @close="modalImpersonate = false"
    />
  </div>
</template>
<script>
import { ImpersonateDialog } from '@/modules/Impersonate';

export default {
  name: 'ImpersonateButton',
  components: {
    ImpersonateDialog,
  },
  props: {
    studioId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalImpersonate: false,
    };
  },
};
</script>

<style lang="scss" module>
.wrapper {
  color: $orange-alert;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: $spacing;
  font-size: 16px;
}

.text {
  font-size: 10px;
  font-weight: 600;
}
</style>

<template>
  <div
    v-if="form && !userLoading"
    ref="studio-infos-page"
    class="studio-infos-page"
    data-test-id="studioAdminFormWrapper"
    @keydown.enter="submitForm('studioInfoForm')"
  >
    <el-form
      ref="studioInfoForm"
      :hide-required-asterisk="true"
      :rules="rulesStudioInfoForm"
      :model="form"
      label-width="120px"
      label-position="top"
    >
      <div class="section">
        <h2>Studio Infos</h2>
        <div class="studio-infos">
          <div class="studio-infos-sub basic">
            <div class="field">
              <span class="label">Studio name</span>
              <el-input :value="form.studioName" disabled data-test-id="studioAdminStudioNameInput"></el-input>
            </div>
            <div class="field">
              <span class="label">Facebook Ad Account</span>
              <el-input :value="form.adAccount" disabled data-test-id="studioAdminFacebookAdAccountInput"></el-input>
            </div>
          </div>
          <div class="studio-infos-sub static">
            <span>
              In accordance with the applicable rules, you have the right to access, modify, delete any data concerning you and to
              request restriction of processing and portability of your data.
              <br />
              <br />To exercise your rights, please address us an e-mail at:
              <a href="mailto:dpo@voodoo.io">dpo@voodoo.io</a>
              <br />
              <br />These rights and the manner in which they can be exercised are detailed in our <a target="_blank" href="https://www.voodoo.io/terms-platform/">data policy.</a>
            </span>
          </div>
        </div>
      </div>
      <div class="section">
        <h2>Studio country</h2>
        <div class="studio-infos-sub address">
          <div class="field">
            <el-form-item label="Country" prop="country">
              <el-select
                v-model="form.country"
                filterable
                :disabled="!canChangeStudio"
                data-test-id="studioAdminCountrySelectWrapper">
                <el-option
                  v-for="item in countries"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  data-test-id="studioAdminCountrySelectOption">
                  <span>{{ item.icon }} {{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="myPermissions.indexOf('view_knowledge_sharing') > -1" class="section">
        <h2>Knowledge sharing program</h2>
        <div class="KS-infos">
          <div class="switch-ks">
            <el-switch
              :value="infos.knowledgeSharingStudioStatus"
              :disabled="!cantEditAccessMyself()"
              active-text="Accept to participate"
              data-test-id="studioAdminKnowledgeSharingSwitch"
              @change="setKS(infos.id, infos.knowledgeSharingStudioStatus)"
            ></el-switch>
            <!-- <span>Accept to participate</span> -->
          </div>
          <span
            class="static"
          >Enabling the knowledge sharing feature will allow you to view other studios’ game concepts in the form of videos and
            hopefully spark some new ideas for you and your team. Reminder: if you choose to participate, your videos will also be
            made available for viewing to other studios.</span
          >
        </div>
      </div>
      <div ref="saveButton" class="section save" :style="`top: ${yButton() || 22}px;`">
        <el-button
          v-if="canSave"
          type="primary"
          :disabled="!!!canSave"
          :loading="loading"
          data-test-id="studioAdminSaveChangesButton"
          @click="submitForm('studioInfoForm')">
          Save changes
        </el-button>
      </div>
    </el-form>
  </div>
  <div v-else>
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import countries from '@/../json/countries.json';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'StudioAdminSubPage',
  components: {
    // SlidingPage,
  },
  props: {
    infos: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gameIcon,
      form: {},
      rulesStudioInfoForm: {
        country: [
          {
            required: true,
            message: 'Please select your country',
            trigger: 'blur',
          },
        ],
      },
      countries,
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['adminstudio/loading_studio_infos']) || null;
    },
    userLoading() {
      return cloneDeep(this.$store.getters['users/loading']) || null;
    },
    canChangeStudio() {
      return this.infos.permissions && this.infos.permissions.change_studio;
    },
    canSave() {
      if (!this.canChangeStudio) {
        return false;
      }
      const infos = JSON.stringify(this.infos);
      const form = JSON.stringify(this.form);
      if (infos !== form) {
        return true;
      }
      return false;
    },
  },
  watch: {
    infos(value) {
      this.form = cloneDeep(value);
    },
  },
  mounted() {
    this.form = cloneDeep(this.infos);
  },
  methods: {
    setKS(id, value) {
      if (!value) {
        this.$store.dispatch('adminstudio/updateKnowledgeSharingByStudioId', {
          id,
          value,
        });
      }
    },
    cantEditAccessMyself() {
      return true;
    },
    yButton() {
      return document.querySelector('.card-page') ? document.querySelector('.card-page').clientHeight + 60 : 22;
    },
    prepareStudioInfos() {
      // eslint-disable-next-line no-restricted-syntax
      const difference = Object.keys(this.infos).filter(k => this.infos[k] !== this.form[k]);
      const data = {};
      data.id = this.infos.id;
      for (let i = 0; i < difference.length; i += 1) {
        const element = difference[i];
        if (element !== 'permissions') {
          data[element] = this.form[element];
          if (typeof data[element] === 'string') {
            data[element] = data[element].trim();
          }
        }
      }

      return cloneDeep(data);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const changes = this.prepareStudioInfos();
          await this.$store.dispatch('adminstudio/updateStudioInfos', changes).then((response) => {
            if (response) {
              this.$notify.success({
                title: 'Changes saved',
                position: 'bottom-right',
              });
            } else {
              this.$notify.error({
                title: 'Saving failed',
                message: 'Please check that all fields are populated.',
                position: 'bottom-right',
              });
            }
          });
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss">
.studio-infos-page {
  position: relative;
  display: flex;
  flex-direction: column;
  .section.save {
    position: fixed;
    // width: 100%;

    right: 60px;
    // padding-bottom: 0;
  }
  .KS-infos {
    display: flex;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    .switch-ks {
      min-width: 250px;
    }

    .static {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      align-items: flex-start;

      padding: 20px;
      font-size: 12px;
      line-height: 17px;
    }
  }

  .studio-infos {
    display: flex;
    justify-content: space-between;
    color: #232323;
    // flex: 1 2 auto;
  }
  .studio-infos-sub {
    text-align: left;

    &.logo {
      width: 20%;
    }
    &.basic {
      width: 25%;
    }
    &.static {
      width: 40%;
    }
    &.address {
      width: 40%;
      .el-select {
        width: 100%;
      }
    }

    .el-form-item__label {
      padding: 0 0 5px;
      color: #4d4f5c;
      font-size: 12px;
      line-height: 12px;
    }

    .label,
    span {
      color: #4d4f5c;
      font-size: 12px;
    }
    .field {
      margin-bottom: 10px;
      .label {
        padding-bottom: 5px;
        display: flex;
      }
    }
  }
}
</style>

<template>
  <div class="action-game">
    <div
      v-if="actionIsFetched"
      class="action-mobile"
      @click="openMobileView"
      @click.stop
    >
      <i class="vd-svg-hover-options"></i>
    </div>

    <div class="action-button-icons">
      <div v-if="game.projectGameCount > 1 && showProject" class="link">
        <GameProjectIcon :count="game.projectGameCount" data-test-id="actionButtonProjectButton" @click="goToProjectPage(game.projectId)"/>
      </div>
      <i
        v-if="game.gameAnalyticsUrl"
        :class="[`vd-svg-ga`, 'action-button-icon']"
        data-test-id="actionButtonGALink"
        @click.stop="open(game.gameAnalyticsUrl)"></i>
      <StoreUrl
        v-if="game.storeUrl"
        :game="game"
      />
      <i
        v-if="game.facebookUrl"
        :class="[`vd-svg-facebook`, 'action-button-icon']"
        data-test-id="actionButtonFacebookLink"
        @click.stop="open(game.facebookUrl)"></i>
      <div v-if="!game.isCtr" class="button clickable link">
        <i
          class="vd-svg-game-id-icon action-button-icon"
          data-test-id="actionButtonGameIDs"
          @click="modalIdsShow = true"
          @click.stop />
      </div>
      <el-dialog
        custom-class="ids-modal"
        :append-to-body="true"
        :title="'Game IDs'"
        :visible.sync="modalIdsShow"
      >
        <GameIds
          :game-object="game"
          @close-pop-in="modalIdsShow = false"
        ></GameIds>
      </el-dialog>
      <LinksActions
        class="action-desktop"
        :actions="actionsAvailable.secondary"
        @action="pushAction"
      ></LinksActions>
    </div>

    <component
      :is="componentToDisplay"
      v-if="componentIsVisible"
      :game="game"
      :is-visible="componentIsVisible"
      :action-list="actionList"
      :genre-obj="genreObj"
      :campaign-id="campaignId"
      @updateGenre="genreObj = $event"
      @campaignIdUpdate="campaignId = $event"
      @close="close"
    ></component>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';
import LinksActions from '@/components/Generic/LinksAndActions/LinksAndActions.vue';
import { GameGenreDialog } from '@/modules/GenreTags';
import GameProjectIcon from '@/components/GameProjectIcon';
import GameIds from '@/components/Dialogs/GameIds.vue';
import FeedbackAddModal from './components/FeedbackAddModal.vue';
import FeedbackSeeModal from './components/FeedbackSeeModal.vue';
import ChangeGASlotModal from './components/ChangeGASlotModal.vue';
import GameIdsModal from './components/GameIdsModal.vue';
import IterationTutorialModal from './components/IterationTutorialModal.vue';
import SetCampaignModal from './components/SetCampaignModal.vue';
import LinkCampaignModal from './components/LinkCampaignModal.vue';
import CampaignSettingsModal from './components/CampaignSettingsModal.vue';
import RequestTestingModal from './components/RequestTestingModal.vue';
import LinkProjectModal from './components/LinkProjectModal.vue';
import UnlinkProjectModal from './components/UnlinkProjectModal.vue';
import StoreUrl from './components/StoreUrl.vue';

export default {
  name: 'ActionButton',
  components: {
    LinksActions,
    GameGenreDialog,
    FeedbackAddModal,
    FeedbackSeeModal,
    ChangeGASlotModal,
    GameIds,
    GameIdsModal,
    IterationTutorialModal,
    SetCampaignModal,
    LinkCampaignModal,
    CampaignSettingsModal,
    RequestTestingModal,
    StoreUrl,
    GameProjectIcon,
    LinkProjectModal,
    UnlinkProjectModal,
  },
  props: {
    showPrimary: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Object,
      default: null,
    },
    showProject: {
      type: Boolean,
      default: true,
    },
    gameId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      actionList,
      genreObj: {},
      campaignId: '',
      actions: this.game.actions,
      componentToDisplay: null,
      componentIsVisible: false,
      modalIdsShow: false,
    };
  },
  computed: {
    actionIsFetched() {
      return !!this.game;
    },
    gameAction() {
      if (this.game) {
        const list = this.game.actions;
        return list;
      }
      return [];
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    actionsAvailable() {
      if (!this.game || !this.actions) {
        return {
          secondary: [],
          primary: [],
        };
      }

      let actions = [];

      // const mypermission = this.myPermissions;
      for (let i = 0; i < this.actionList.length; i += 1) {
        const element = this.actionList[i];

        for (let j = 0; j < this.actions.filter(el => !el.unavailableReason).length; j += 1) {
          const ele = this.actions.filter(el => !el.unavailableReason)[j];
          if (
            element.id === ele.id
            // && (mypermission.some(r => element.permissions.indexOf(r) >= 0)
            //   || element.permissions.length === 0)
            && element.used
          ) {
            actions.push(element);
          }
        }
      }

      const primary = actions.filter(el => el.primary);
      let secondary = actions.filter(el => !el.primary);
      /*       if (!this.game.isCtr) {
        secondary.unshift({
          id: 'game_ids',
          name: 'Game IDs',
        });
      } */
      secondary.push({
        id: 'see_feedbacks',
        name: 'View feedback',
      });
      if (
        !this.game.isCtr
        && !this.game.isManagedByVoodoo
        && (this.game.status === 'in_testing' || this.game.status === 'finished')
      ) {
        secondary.push({
          id: 'tuto',
          name: 'How to iterate?',
        });
      }
      if (this.game.status === 'in_testing' && this.game.isManagedByVoodoo) {
        secondary.unshift({
          id: 'settings',
          name: 'Campaign settings',
        });
      }
      if (!this.showPrimary) {
        secondary = secondary.concat(primary);
      }
      actions = {
        primary,
        secondary,
      };
      return actions;
    },
    gameGenres() {
      if (this.gameAction.length !== 0) {
        return this.game.genres;
      }
      return [];
    },
  },
  watch: {
    game: {
      handler(newVal, oldval) {
        if (newVal.actions !== oldval.actions) {
          this.getActions();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.game.actions) {
      this.getActions();
    }
  },
  methods: {
    async getActions() {
      if (!this.game.actions) {
        await this.$store.dispatch(
          'ActionToDispatch/refreshGame',
          this.game.id,
        );
      }
      this.actions = this.game.actions;
    },
    goto(url) {
      if (url) window.open(url);
    },
    goToProjectPage(projectId) {
      if (this.$route.name === 'home') {
        this.$router
          .push({
            query: {
              customView: 'projectId',
              customId: projectId,
            },
          })
          .catch(() => {});
        return;
      }
      const route = {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      };
      localStorage.routeBeforeProject = JSON.stringify(route);
      this.$router.push({
        name: 'studioHome',
        params: {
          page: 'board',
          sub: 'project',
          filter: `${projectId}`,
        },
      });
    },
    open(url) {
      window.open(url);
    },
    openMobileView() {
      const query = {
        ...this.$route.query,
        mobile: 'games-infos',
        mobileTitle: 'Details and options',
        mobileIcon: false,
        gameId: this.game.id,
      };
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    pushAction(action) {
      switch (action) {
      case 'tuto':
        this.displayComponent('IterationTutorialModal');
        break;
      case 'reject_game_iteration':
        this.setActionWithoutParams('reject_game_iteration');
        break;
      case 'recreate_campaign_facebook':
        this.setActionWithoutParams('recreate_campaign_facebook');
        break;
      case 'request_application_access':
        this.updateValueBeforeRequestAppAccess();
        break;
      case 'reject_changelog':
        this.setActionWithoutParams('reject_changelog');
        break;
      case 'decline_testing':
        this.setActionWithoutParams('decline_testing');
        break;
      case 'create_ga_slot':
        this.setActionWithoutParams('create_ga_slot');
        break;
      case 'set_game_genres':
        this.setGameGenre();
        break;
      case 'test_studio_campaign':
        this.displayComponent('LinkCampaignModal');
        break;
      case 'request_testing':
        this.displayComponent('RequestTestingModal');
        break;
      case 'add_game_feedback':
        this.setGameFeedbacks();
        break;
      case 'assign_ga_slot':
        this.displayComponent('ChangeGASlotModal');
        break;
      case 'accept_testing':
        this.setActionWithoutParams('accept_testing');
        break;
      case 'validate_changelog':
        this.setActionWithoutParams('validate_changelog');
        break;
      case 'kill_game':
        this.confirmBeforeSend(
          'Are you sure you want to terminate this game?',
          'If still running, your campaign will be stopped and your game will move to the Finished section.',
          'kill_game',
        );
        break;
      case 'archive_game':
        this.setActionWithoutParams('archive_game');
        break;
      case 'extend_campaign':
        this.setActionWithoutParams('extend_campaign');
        break;
      case 'iterate_pause_campaign':
        this.confirmBeforeSend(
          'Are you sure to want to iterate on this game?',
          'If still running, your campaign will be stopped and your game will move to the Is iterating section.',
          'iterate_pause_campaign',
        );
        break;
      case 'validate_game':
        this.confirmBeforeSend(
          'Are you sure you want to launch this game?',
          'Any further marketing will be handled by the UA team for this game.',
          'validate_game',
        );
        break;
      case 'update_studio_campaign':
        this.displayComponent('SetCampaignModal');
        break;
      case 'iterate_from_history':
        this.confirmBeforeSend(
          'Are you sure to want to iterate on this game?',
          'If still running, your campaign will be stopped and your game will move to the Is iterating section.',
          'iterate_from_history',
        );
        break;
      case 'settings':
        this.displayComponent('CampaignSettingsModal');
        break;
      case 'see_feedbacks':
        this.displayComponent('FeedbackSeeModal');
        break;
      case 'game_ids':
        this.displayComponent('GameIdsModal');
        break;
      case 'change_game_project':
        this.displayComponent('LinkProjectModal');
        break;
      case 'unlink_game_project':
        this.displayComponent('UnlinkProjectModal');
        break;


      default:
        break;
      }
    },
    confirmBeforeSend(title, content, action) {
      this.$confirm(content, title, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        center: true,
      })
        .then(() => {
          this.setActionWithoutParams(action);
        })
        .catch(() => {});
    },
    setActionWithoutParams(actionValue) {
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === actionValue)[0];
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
      }
    },
    setGameGenre() {
      for (let index = 0; index < this.gameGenres.length; index += 1) {
        const element = this.gameGenres[index];
        this.genreObj[element.category] = element.id;
      }
      this.displayComponent('GameGenreDialog');
    },
    setGameFeedbacks() {
      for (let index = 0; index < this.gameGenres.length; index += 1) {
        const element = this.gameGenres[index];
        this.genreObj[element.category] = element.id;
      }

      this.displayComponent('FeedbackAddModal');
    },
    async updateValueBeforeRequestAppAccess() {
      if (this.actionList) {
        await this.$store.dispatch('gameAction/requestAppAccess', {
          game: this.game,
          facebookAppId: this.game.facebookAppId,
        });
      }
    },
    close() {
      this.componentIsVisible = false;
    },
    displayComponent(component) {
      this.componentToDisplay = component;
      this.componentIsVisible = true;
    },
  },
};
</script>

<style lang="scss">
.el-message-box__close {
  display: none;
}
.el-message-box__title {
  text-align: center;
}
.ids-modal {
  .el-dialog__header {
    background-color: $foggy-blue;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
}
.action-game {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  @media screen and (max-width: 1000px) {
    padding: 0px;
  }
  @media screen and (max-width: 700px) {
    justify-content: flex-end;
  }
  .action-desktop {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .action-mobile {
    display: none;
    .options {
      width: 25px;
      height: 25px;
      transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
      &:hover {
        cursor: pointer;
      }
    }
    @media screen and (max-width: 700px) {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}

.field {
  text-align: left;
  margin-right: 10px;
  .label {
    font-size: 12px;
    line-height: 25px;
    color: #c2c2c2;
  }
}
.label {
  margin-top: 10px;
}

.action-button-icon {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 24px;
  height: 24px;
}

.action-button-icon:hover {
  cursor: pointer;
}

.action-button-icons {
  display: flex;
  align-items: center;

  > * {
    margin-left: $spacing * 3;
  }
}
</style>

/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="view-bar-games">
    <el-dialog
      title="Save as"
      :visible.sync="dialogSaveAsVisible"
      :append-to-body="true">
      <div class="save-view-dialog">
        <span>Name:</span>
        <el-input
          ref="newName"
          v-model="newName"
          size="mini"
          autocomplete="off"
          @keyup.enter.native="isUniqName(newName) ? saveView() : null"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="dialogSaveAsVisible = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="!!!isUniqName(newName)"
          @click="saveView()">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <div class="left">
      <SortGameComponent></SortGameComponent>
    </div>

    <div class="right">
      <div class="content">
        <SearchInputGames></SearchInputGames>

        <QueryBuilderSelector></QueryBuilderSelector><ViewSelector></ViewSelector>
        <div v-if="(canSave && !viewExist) || (canSave && viewExist)" class="buttons-save-view">
          <el-button
            v-if="canSave && !viewExist"
            type="primary"
            size="mini"
            class="can-save-button"
            :loading="loading"
            @click="saveViewCommand('saveAs')">
            Save view
          </el-button>

          <el-button-group v-if="canSave && viewExist" class="save-button">
            <el-button
              v-if="!loading && !currentView.isDefault && currentView.creator.id === userId"
              type="primary"
              size="mini"
              @click="updateCurrentView">
              <i class="el-icon-refresh"></i>
            </el-button>

            <el-button
              v-if="!loading"
              type="primary"
              size="mini"
              @click="saveViewCommand('saveAs')">
              Save as ...
            </el-button>

            <el-button
              v-if="loading"
              type="primary"
              size="mini">
              <i class="el-icon-loading"></i>
            </el-button>
          </el-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/valid-v-for */

import { cloneDeep } from 'lodash';
// import draggable from 'vuedraggable';
// import VueQueryBuilder from 'vue-query-builder';
// import VueQueryBuilder from '@/components/QueryBuilder/VueQueryBuilder.vue';
import SearchInputGames from '@/components/ViewBar/SearchInputGames.vue';
import SortGameComponent from '@/components/ViewBar/SortGameComponent.vue';
import ViewSelector from '@/components/ViewBar/ViewSelector.vue';
import QueryBuilderSelector from '@/components/ViewBar/QueryBuilderSelector.vue';
// import SimpleBar from '@/components/SimpleBar.vue';
// import simpleViewContent from '@/../json/simpleContent';

// let timer;

export default {
  name: 'ViewBar',
  components: {
    SearchInputGames,
    SortGameComponent,
    ViewSelector,
    QueryBuilderSelector,
    // SimpleBar,
    // draggable,
    // VueQueryBuilder,
  },
  data() {
    return {
      dialogSaveAsVisible: false,
      newName: '',
    };
  },
  computed: {
    search: {
      get() {
        return cloneDeep(this.$store.getters['views/term']) || '';
      },
      set(value) {
        this.$store.dispatch('views/setTerm', value);
      },
    },
    canSave() {
      return this.$store.getters['views/canSave'];
    },
    viewExist() {
      return this.$store.getters['views/viewExist'];
    },
    currentView() {
      const current = cloneDeep(this.$store.getters['views/currentView']);
      if (current.id === 'my-games') {
        let contentString = JSON.stringify(current.content);
        contentString = contentString.replace(/PMNAMETEMPLATE/g, 'Theophile Tabary');
        current.content = JSON.parse(contentString);
      }
      return current;
    },
    loading() {
      return this.$store.getters['views/loading'];
    },
    userId() {
      return this.$store.getters['users/id'];
    },
    views() {
      const myViews = [];
      const othersViews = [];
      const listView = this.$store.getters['views/views'];
      const final = [];

      for (let i = 0; i < listView.length; i += 1) {
        const element = listView[i];
        if (element.creator.id === this.userId) {
          myViews.push(element);
        } else {
          othersViews.push(element);
        }
      }

      const presaved = this.preSavedViews;
      const preSaved = {
        label: 'Default views',
        options: presaved,
      };

      const myOption = {
        label: 'My views',
        options: myViews,
      };
      const pmOption = {
        label: 'PM views',
        options: this.pmViews,
      };

      // if (this.isPm) {
      final.push(pmOption);
      // }

      final.push(preSaved);
      final.push(myOption);
      if (this.userId === 2839) {
        const othersOption = {
          label: 'Others views',
          options: othersViews,
        };
        final.push(othersOption);
      }
      return final;
    },
  },
  mounted() {
    // this.updateView();
  },
  methods: {
    async saveViewCommand(command) {
      if (command === 'saveAs') {
        this.newName = '';
        this.dialogSaveAsVisible = true;
        await new Promise(resolve => setTimeout(resolve, 500));
        this.$refs.newName.focus();
      }
    },
    updateCurrentView() {
      this.$store.dispatch('views/updateView', this.currentView);
    },
    isUniqName(name) {
      if (name) {
        for (let i = 0; i < this.views.length; i += 1) {
          const element = this.views[i];
          if (name === element.name) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    preSavedViews() {
      const defaultViews = cloneDeep(this.$store.getters['views/preSavedViews']);
      for (let i = 0; i < defaultViews.length; i += 1) {
        const element = defaultViews[i];
        if (element.id === 'my-games') {
          defaultViews.splice(i, 1);
          i -= 1;
        }
      }
      return defaultViews;
    },
    saveView() {
      const view = cloneDeep(this.currentView);
      view.name = this.newName;
      view.id = null;
      this.$store.dispatch('views/saveNewView', view);
      this.dialogSaveAsVisible = false;
    },
  },
};
</script>

<style lang="scss">
.dialog-footer {
  display:flex;
  gap: 10px;
  justify-content:flex-end;
}
.save-view-dialog {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  align-items: center;
}
.save-button {
  display: flex;
  gap: 2px;
}
.view-bar-games {
  z-index: 10;
  display: grid;
  // grid-template-columns: 1fr 330px;
  grid-template-columns: minmax(330px, 1fr) minmax(48%, 1fr);
  padding: 10px 30px;
  margin-bottom: 20px;
  gap: 5px;
  overflow: auto;

  position: sticky;
  top: 60px;
  background-color: var(--background-color);

  box-shadow: 0px 0px 35px 25px var(--background-color);

  @media (max-width: 700px) {
    padding: 10px;
    grid-template-columns: 1fr;
  }
  .buttons-save-view {
    margin-left: 5px;
    // position: fixed;
    // min-width: 300px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    min-height: 30px;

    overflow: auto;
    @media (max-width: 700px) {
      justify-content: space-between;
    }
  }
  .right {
    // width: 100px;
    // display: flex;
    overflow: auto;
    // grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    // align-items: center;
    // justify-content: flex-end;
    display: flex;
          justify-content: flex-end;
          .content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            overflow: hidden;
          }
    // gap: 5px;
    .el-input {
      margin-right: 5px;
    }
    @media (max-width: 700px) {
      justify-content: space-between;
    }
    // display: grid;
    // grid-template-columns: 1fr 1fr;

    .el-button-group {
      display: flex;
    }
  }
}
body[data-theme="dark"] {
  .el-button-group .el-button--primary:last-child {
    border-left-color: var(--el-button-border-color);
  }
  .el-button-group .el-button--primary:not(:first-child):not(:last-child) {
    border-left-color: var(--el-button-border-color);
    border-right-color: var(--el-button-border-color);
  }
  .el-button-group .el-button--primary:first-child {
    border-right-color: var(--el-button-border-color);
  }
}
//
</style>

<template>
  <div class="studios studios-page-list">
    <div class="main">
      <div class="studios-table">
        <ViewBarStudios></ViewBarStudios>
        <StudiosTable></StudiosTable>
      </div>
    </div>
  </div>
</template>

<script>
import StudiosTable from '@/components/StudioTable.vue';
import staticParam from '@/../json/static.json';
import ViewBarStudios from '@/components/ViewBarStudio.vue';

export default {
  name: 'Studios',
  components: {
    StudiosTable,
    ViewBarStudios,
  },
  data() {
    return {
      staticParam,
      prevRoute: null,
      prevId: null,
      filtered: [],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const regex = /^\/stats\/(\d+)/gm;
      if (from.path.match(regex)) {
        // eslint-disable-next-line no-param-reassign
        vm.prevId = from.params.gameId;
      }
    });
  },
};
</script>

<style lang="scss">
.main {
  justify-content: center;
}

.studios {
  .main {
    .el-cascader {
      .el-input {
        margin-left: 0;
      }
      .el-cascader__tags {
        flex-wrap: initial;
        overflow: auto;
        span {
          white-space: nowrap;
        }
      }
      input {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 0px 0px;
    }
    span.el-switch__label:not(.is-active) {
      color: $darker-grey;
    }
    .el-switch {
      flex-direction: row-reverse;
      .el-switch__label--right {
        margin-right: 10px;
      }
    }
  }
  .el-input {
    width: auto;
  }
  .el-date-editor {
    max-width: 250px;
    @media screen and (max-width: 1200px) {
      max-width: 220px;
    }
  }
  .studios-table {
    box-shadow: 0 3px 10px 0 rgba(198, 219, 240, 0.25);
    @media screen and (max-width: 700px) {
      width: 100%;
      position: fixed;
    }
  }
  .mobile-date {
    display: flex;
    overflow: auto;
    .el-input {
      width: 140px;
    }
  }
  .left {
    // max-width: 260px;
    display: flex;
    align-items: center;

    input {
      &.start-date,
      &.end-date {
        margin-right: 5px;
        height: 40px;
        border: 1px solid #dcdfe6;
        color: dimgrey;
        border-radius: 3px;
        background-color: #fff;
      }
    }
  }
  .right {
    .el-input {
      width: 120px;
      .el-input--suffix .el-input__inner {
        padding-right: 5px;
      }
    }
  }
}
</style>

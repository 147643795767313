<template>
  <div :class="$style.wrapper">
    <div :class="colorClass + ' cursor'"></div>

    <GameIcon :class="$style.icon" :game="game"></GameIcon>
    <div :class="$style.infos">
      <div>
        <div :class="$style.name">{{game.name}}</div>
        <div :class="$style.date">Creation: {{ game.createdAt | localeDate }}</div>
        <div>
          <ProtoScore
            v-if="!isInProgress"
            :small="true"
            :recommendation="recommendation"
            :score-values="formattedProtoScore"
            :display-details="myPermissions.includes('view_proto_score')"
          ></ProtoScore></div>
      </div>
      <GameStatus v-if="!isInProgress" :game="game"/>
    </div>
  </div>
</template>

<script>
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';
import Tools from '@/tools/tools';
import GameStatus from './GameStatus.vue';

const { recommendationMapping } = require('@/components/ProtoScore/data');

export default {
  name: 'GameInfos',
  components: {
    GameStatus,
    GameIcon,
    ProtoScore,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      recommendationMapping,
    };
  },
  computed: {
    source() {
      let source = this.iteration;
      const section = this.section.title;

      if (section !== 'global') {
        source = this.iteration.adNetworks[section];
      }
      return source;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    recommendation() {
      return this.game?.prototypeRecommendation?.recommendation || '';
    },
    recommendationKey() {
      return this.recommendation.toLowerCase();
    },
    formattedProtoScore() {
      return this.game.adnetworks ? this.game.adnetworks.split('|').map(adNetwork => ({
        label: adNetwork,
        value: this.game[`score${adNetwork}`],
      })) : null;
    },
    colorClass() {
      try {
        return `${this.recommendationMapping[this.recommendationKey].class}-cursor`;
      } catch (e) {
        return '';
      }
    },
    isInProgress() {
      return Tools.getWhichFilterGameIs(this.game) === 'in_progress';
    },
  },
};
</script>
<style lang="scss">
.cursor {
    height: 42px;
    position: absolute;
    left: 0;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}
.high-cursor {
  border-left: $green-alert solid 4px !important;
}
.low-cursor {
  border-left: $orange-alert solid 4px !important;
}
.none-cursor {
  border-left: $grey solid 4px !important;
}
</style>
<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  padding-right: $spacing * 2;
  padding-left: $spacing * 2;
  border-radius: 2px;
}

.icon {
  width: 42px;
  border-radius: 8px;
  margin-right: $spacing * 2;
}

.infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  line-height: 14px;
}

.name {
  font-size: 12px;
  font-weight: 600;
  color: $intense-blue;
}

.date {
  font-size: 10px;
  color: $grey;
}


</style>

<template>
  <div v-if="adNetworks" :class="$style.wrapper">
    <img
      v-for="adNetwork in adNetworks"
      :key="adNetwork"
      :src="getIcon(adNetwork)"/>
  </div>
</template>

<script>
import facebook from '@/assets/images/facebook.svg';
import snapchat from '@/assets/images/snapchat.svg';

export default {
  name: 'AdNetworks',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      facebook,
      snapchat,
    };
  },
  computed: {
    adNetworks() {
      return this.game?.adNetworks ? Object.keys(this.game.adNetworks) : null;
    },
  },
  methods: {
    getIcon(adNetwork) {
      return this[adNetwork];
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  img {
    height: 16px;
    margin-right: $spacing / 4;
  }
  img:last-of-type {
    margin-right: 0;
  }
}
</style>

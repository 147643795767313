<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="'Give feedback'"
      :visible="isVisible"
      width="60%"
      data-test-id="myGamesFeedbackModal"
      @close="$emit('close')"
    >
      <Feedback
        :game="game"
        display-send-form
        @close-pop-in="$emit('close')"
      ></Feedback>
    </el-dialog>
  </div>
</template>

<script>
import Feedback from '@/components/Dialogs/Feedback.vue';

export default {
  name: 'FeedbackAddModal',
  components: {
    Feedback,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

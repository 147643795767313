import Vue from 'vue';
import Router from 'vue-router';
import CookiesService from '@/tools/cookies';
// eslint-disable-next-line import/no-cycle
import axios from '@/tools/axios';
import HijackComponent from './views/HijackComponent.vue';
import HijackResetComponent from './views/HijackResetComponent.vue';
import Home from './views/Home.vue';
import RedirectComponent from './views/RedirectComponent.vue';
import StudioService from './views/StudioService.vue';
// import StudioHome from './views/StudioHome.vue';
import PageNotFound from './views/PageNotFound.vue';
import Studios from './views/Studios.vue';
import StudioCreationForm from './components/PmPages/StudioCreationForm.vue';
import LegalInformation from './views/LegalInformation.vue';
import Quotes from './views/Quotes.vue';
import Maintenance from './views/Maintenance.vue';
import Login from './views/Login.vue';
import Logout from './views/Logout.vue';
import PasswordReset from './components/PasswordReset/PasswordReset.vue';
import PasswordResetInit from './components/PasswordReset/PasswordResetInit.vue';
import PasswordResetSent from './components/PasswordReset/PasswordResetSent.vue';
import PasswordResetForm from './components/PasswordReset/PasswordResetForm.vue';
import Stepper from './modules/ScrollStepper';
import GameProject from './views/GameProject';
import StudioDetails from './views/StudioDetails';

// import store from '@/vuex';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/gameproject',
      name: 'gameproject',
      component: GameProject,
    },
    {
      path: '/studio/:id',
      name: 'studio',
      component: StudioDetails,
      meta: {
        category: 'Publishing',
      },
    },
    {
      path: '*',
      name: '404',
      component: PageNotFound,
    },
    {
      path: '/',
      name: 'root',
      component: RedirectComponent,
    },
    {
      path: '/stepper',
      name: 'stepper',
      component: Stepper,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        category: 'Publishing',
      },
    },
    {
      path: '/studios',
      name: 'studios',
      component: Studios,
      meta: {
        category: 'Publishing',
      },
    },
    {
      path: '/studios/create',
      name: 'studioCreationForm',
      component: StudioCreationForm,
      meta: {
        category: 'Publishing',
      },
    },
    {
      path: '/quotes',
      name: 'quotes',
      component: Quotes,
    },
    {
      path: '/profile/me',
      name: 'meAdmin',
      component: StudioService,
      props: { page: 'me' },
      meta: {
        category: 'User profile',
        getAnalyticsName: 'Viewed User profile',
      },
    },
    {
      path: '/profile/studio/:id?/:sub?',
      name: 'studioAdmin',
      component: StudioService,
      props: true,
      meta: {
        category: 'Studio admin',
        getAnalyticsName: (params) => {
          let name = 'Viewed Studio';

          if (!params.id) {
            name = `${name} admin`;
          } else if (params.sub) {
            name = `${name} ${params.sub}`;
          } else {
            name = `${name} details`;
          }

          return name;
        },
      },
    },
    {
      path: '/board/:sub?/:filter?/:type?',
      name: 'studioHome',
      component: StudioService,
      props: route => ({
        page: 'board',
        sub: route.params.sub || 'home',
        filter: route.params.filter || 'in_progress',
        type: route.params.type || 'cpi',
      }),
      meta: {
        category: 'Studio',
        getAnalyticsName: (params) => {
          let name = 'Viewed';

          if (!params.sub) {
            name = `${name} studio home page`;
          } else {
            if (params.sub === 'ks') {
              name = `${name} knowledge sharing`;
            } else if (params.sub === 'home') {
              name = `${name} studio home`;
            } else {
              name = `${name} studio`;
            }

            if (params.type) {
              name = `${name} ${params.type.toUpperCase()} tests`;
            }
          }

          return name;
        },
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: true,
      meta: {
        category: 'Login',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      props: true,
      meta: {
        category: 'Login',
      },
    },
    {
      path: '/legal_information',
      name: 'legalInformation',
      component: LegalInformation,
      props: true,
      meta: {
        category: 'Unlogged',
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
    },
    {
      path: '/hijack/:userId',
      name: 'hijack',
      component: HijackComponent,
      props: true,
    },
    {
      path: '/hijack-reset',
      name: 'hijackReset',
      component: HijackResetComponent,
      props: true,
    },
    {
      path: '/resetpwd',
      component: PasswordReset,
      children: [
        {
          path: '',
          name: 'passwordReset',
          component: PasswordResetInit,
        },
        {
          path: 'emailsent',
          name: 'passwordResetEmailSent',
          component: PasswordResetSent,
        },
        {
          path: ':username/:resetToken',
          name: 'passwordResetForm',
          component: PasswordResetForm,
          props: true,
        },
      ],
    },
  ],
});
export const LOGIN_FLOW_ROUTES = ['login', 'redirect', '404', 'maintenance', 'passwordReset', 'passwordResetEmailSent', 'passwordResetForm'];
export const UNLOGGED_ROUTES = ['legalInformation'];
export const STUDIO_ROUTES = ['studioService', 'studioAdmin', 'meAdmin', 'studioHome'];

export const PM_ROUTES = ['home', 'studios', 'studio'];
router.beforeEach((to, from, next) => {
  const token = CookiesService.getToken();
  if (token) {
    axios.defaults.headers.common.authorization = token;
  }
  if (LOGIN_FLOW_ROUTES.includes(to.name) || UNLOGGED_ROUTES.includes(to.name)) {
    return next();
  }

  if (token) {
    return next();
  }
  return next('/login');
});

/**
 *
 * @param {Route} route
 */
const getAnalyticsCategory = (route) => {
  let category = null;

  // get the route's or its closest parent's category
  route.matched.some((record) => {
    if (record.meta.category) {
      category = record.meta.category;
      return true;
    }

    return false;
  });

  return category;
};

/**
 *
 * @param {Route} route
 */
const sendAnalyticsForPageView = (route) => {
  const category = getAnalyticsCategory(route);

  let name = `Viewed ${route.name} page`;

  if (route.meta.getAnalyticsName) {
    name = typeof route.meta.getAnalyticsName === 'function' ? route.meta.getAnalyticsName(route.params) : route.meta.getAnalyticsName;
  }

  analytics.page(category, name);
};

router.afterEach((to) => {
  sendAnalyticsForPageView(to);
});
export default router;

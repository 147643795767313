import tools from '@/tools/tools';

/**
 * Maps wrapper tags added in Contentful in this way {{tag}}{{/tag}}
 * to a condition and a template when relevant
 * @param {Object} game - Game object
 * @return {Array} A mapping between tags, conditions and templates
 */

export default function (game) {
  return ([
    {
      tag: '{{vd-is-comment}}',
      condition: false,
    },
    {
      tag: '{{vd-important}}',
      condition: true,
      template: {
        start: '<div class="important-notice" style="background-color: #fff7d9; color: #4d4f5c !important; margin-bottom: 19px;padding: 15px;border-radius: 5px;line-height: normal;border: solid 1px #ffcb00"> ⚠️ <span class="notice-title" style="color: #4d4f5c; font-weight: 600;margin-left: 5px">Important notice:</span><br/>',
        end: '</div>',
      },
    },
    {
      tag: '{{vd-is-ios}}',
      condition: game ? game.platform === 'ios' : true,
    },
    {
      tag: '{{vd-is-android}}',
      condition: game ? game.platform === 'android' : true,
    },
    {
      tag: '{{vd-is-facebook}}',
      condition: game ? tools.isfacebookCampaign(game) : true,
    },
    {
      tag: '{{vd-is-snapchat}}',
      condition: game ? tools.isSnapChatCampaign(game) : true,
    },
    {
      tag: '{{vd-is-facebook-android}}',
      condition: game ? tools.isfacebookCampaign(game) && game.platform === 'android' : true,
    },
    {
      tag: '{{vd-is-facebook-ios}}',
      condition: game ? tools.isfacebookCampaign(game) && game.platform === 'ios' : true,
    },
    {
      tag: '{{vd-is-facebook-managedbyvoodoo}}',
      condition: game ? tools.isfacebookCampaign(game) && game.isManagedByVoodoo : true,
    },
    {
      tag: '{{vd-is-facebook-notmanagedbyvoodoo}}',
      condition: game ? tools.isfacebookCampaign(game) && !game.isManagedByVoodoo : true,
    },
    {
      tag: '{{vd-is-published}}',
      condition: game.isPublishedInStore,
    },
    {
      tag: '{{vd-is-not-published}}',
      condition: !game.isPublishedInStore,
    },
  ]);
}

<template>
  <div class="question">
    <label>
      <div class="question-title">{{ question.label }}<span v-if="displayMandatoryAsterix" class="mandatory-asterix">*</span></div>
      <div v-if="question.description" class="question-description">{{ question.description }}</div>
      <el-input
        v-if="question.type === 'text'"
        :disabled="disabled"
        :value="value"
        :name="question.id"
        type="text"
        class="free-text"
        @change="cleanValue"
        @input="updateValue" />
      <el-input
        v-if="question.type === 'url'"
        :disabled="disabled"
        :value="value"
        :name="question.id"
        type="url"
        class="free-text"
        placeholder="https://..."
        @change="validateHttpUrl"
        @input="updateValue" />
      <el-date-picker
        v-if="question.type === 'date'"
        :value="value"
        :name="question.id"
        type="date"
        value-format="dd[ ]MMM[ ]yyyy"
        @input="updateValue" />
      <el-checkbox
        v-if="question.type === 'checkbox'"
        :value="value"
        :name="question.id"
        type="checkbox"
        @input="updateValue">
        {{ question.checkboxLabel }}
      </el-checkbox>
      <ul v-if="question.type === 'radio'" class="radio-button" >
        <li v-for="currentChoice in question.choices" :key="currentChoice.id">
          <el-radio :value="value" :label="currentChoice.id" @input="updateValue">{{ currentChoice.label }}</el-radio>
        </li>
      </ul>
      <el-select
        v-if="question.type ==='dropdown'"
        :disabled="disabled"
        :value="value"
        class="dropdown"
        @input="updateValue">
        <el-option v-for="currentChoice in question.choices" :key="currentChoice.id" :value="currentChoice.id">{{ currentChoice.label }}</el-option>
      </el-select>
    </label>
    <p v-if="errorMessage" class="error" v-html="errorMessage"></p>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  components: {
  },
  props: {
    /**
     * Expected properties:
     * - id: String, required
     * - label: String, required
     * - type: Enum['text', 'date', 'checkbox', 'radio', 'dropdown'], required
     * - optional: boolean, default: false
     * - displayMandatoryAsterix: boolean, default: false,
     * - description: String
     * - checkboxLabel: String, required if type === checkbox
     * - choices: Array of object { id: String, label: String }, required if type IN (radio, dropdown) ,
     */
    question: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  computed: {
    displayMandatoryAsterix() {
      return this.question.displayMandatoryAsterix !== undefined ? this.question.displayMandatoryAsterix : !this.question.optional;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    cleanValue(value) {
      this.$emit('input', value.trim());
    },
    validateHttpUrl(string) {
      this.errorMessage = '';
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        this.errorMessage = 'Please enter a valid URL';
        return false;
      }

      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        this.errorMessage = 'A valid URL starts with http or https';
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  .question-title {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 24px;
    .mandatory-asterix {
      padding-left: 2px;
      color:red;
    }
  }
  .free-text {
    width: 295px;
    height: 24px;
    border: none;
    border-bottom: solid 1px $grey;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
  }
  .radio-button li {
    width: 582px;
    min-height: 24px;
    padding: 8px;
  }
  .error {
    display: block;
    color: $color-danger-plain-color;
  }
}
</style>

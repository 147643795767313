<template>
  <div v-if="sub === 'mygames'" class="studio-search-bar">
    <el-autocomplete
      v-model="game"
      class="autocomplete-search-input"
      size="small"
      :fetch-suggestions="querySearchAsync"
      :placeholder="$t('components.StudioSearchBar.placeholderLookingForAGame')"
      :maxlength="50"
      :clearable="true"
      :trigger-on-focus="false"
      prefix-icon="el-icon-search"
      @clear="reset"
      @select="goToGame"
    >
      <template slot-scope="{ item }">
        <div v-if="item.nodata" style="color:grey;">
          {{ $t("components.StudioSearchBar.noGamesMatching") }}
        </div>
        <div v-else class="item-search">
          <div class="left">
            <GameIcon :game="item" very-small></GameIcon>
            <span class="name">{{ item.name }}</span>
          </div>

          <div class="status">
            <span>{{ getStatus(item).label }}</span>
          </div>
        </div>
      </template>
    </el-autocomplete>
    <el-autocomplete
      v-model="game"
      class="autocomplete-search-input-mobile"
      size="small"
      :fetch-suggestions="querySearchAsync"
      :placeholder="$t('components.StudioSearchBar.placeholderSearchForAGame')"
      :maxlength="50"
      :clearable="true"
      :trigger-on-focus="false"
      prefix-icon="el-icon-search"
      @clear="reset"
      @select="goToGame"
    >
      <template slot-scope="{ item }">
        <div v-if="item.nodata" style="color:grey;">
          {{ $t("components.StudioSearchBar.noGamesMatching") }}
        </div>
        <div v-else class="item-search">
          <div class="left">
            <GameIcon :game="item" very-small></GameIcon>
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="status">
            <span>{{ getStatus(item).label }}</span>
          </div>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import tools from '@/tools/tools';

export default {
  name: 'StudioSearchBar',
  components: { GameIcon },
  props: {},
  data() {
    return {
      game: undefined,
      gameIcon,
    };
  },
  computed: {
    sub() {
      return this.$route.params.sub || 'home';
    },
  },
  watch: {
    game(value) {
      if (!value) {
        this.reset();
      }
    },
    $route(value) {
      if (!value.query.term) {
        this.game = undefined;
      }
    },
  },
  mounted() {
    this.game = this.$route.query.term || undefined;
  },
  methods: {
    getStatus(item) {
      let status = tools.getWhichFilterGameIs(item);
      const statusId = status;
      status = status.charAt(0).toUpperCase() + status.slice(1);
      status = status.replace('_', ' ');

      return {
        label: status,
        value: statusId,
      };
    },
    goToGame(value) {
      if (!value.nodata) {
        this.$store.dispatch('gameAction/myGamesOnly', false);
        this.game = value.name;
        const status = this.getStatus(value).value;
        this.$router.push({
          name: 'studioHome',
          params: {
            page: 'board',
            sub: 'mygames',
            type: value.isCtr ? 'ctr' : 'cpi',
            filter: status,
          },
          query: { term: value.name },
        });
      }
    },
    reset() {
      this.$router
        .push({
          name: 'studioHome',
          page: 'board',
          params: {
            sub: 'mygames',
            type: this.$route.type || 'cpi',
            filter: this.$route.params.filter || 'in_progress',
          },
        })
        .catch(() => {});
    },
    async querySearchAsync(queryString, cb) {
      const results = [{ nodata: true }];
      this.term = queryString;
      // this.$store.dispatch('gameAction/getVideoAvailable', this.game);
      await this.$store.dispatch('gameAction/searchForAgame', queryString).then((response) => {
        if (response === null || response.length === 0) {
          cb(results);
        } else {
          cb(response);
        }
      });
    },
    handleSelect() {},
  },
};
</script>

<style lang="scss">
.studio-search-bar {
  padding: 10px;

  .autocomplete-search-input {
    min-width: 40px;
    // transition: all 400ms linear;
    position: relative;
    @media screen and (min-width: 700px) {
      &:focus-within {
        min-width: 330px;
        position: absolute;

        z-index: 1;
        top: 13px;
      }
    }
    @media screen and (max-width: 700px) {
      display: none;

      &:focus-within {
        width: calc(100%);
        padding: 0 20px;
        min-width: 200px;
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 13px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: 100px;
    }

    input {
      height: 35px;
      line-height: 35px;
    }

    .el-input__icon {
      padding-top: 2px;
      color: #4d4f5c;
    }
  }
  .autocomplete-search-input-mobile {
    min-width: 40px;
    // transition: all 400ms linear;
    position: relative;
    @media screen and (min-width: 700px) {
      display: none;
    }
    @media screen and (max-width: 700px) {
      &:focus-within {
        width: calc(100%);
        padding: 0 20px;
        min-width: 200px;
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 13px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: 100px;
    }

    input {
      height: 35px;
      line-height: 35px;
    }

    .el-input__icon {
      padding-top: 2px;
      color: #4d4f5c;
    }
  }
}
.item-search {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .name {
      margin-left: 5px;
    }
  }
  .logo {
    display: flex;
    .value {
      margin-left: 10px;
    }
  }

  .status {
    span {
      font-size: 12px;
      padding: 5px;
      line-height: 13px;
      background-color: #ecf5ff;
      border-radius: 10px;
    }
  }
}
</style>

<template>
  <div>
    <div :class="$style.descriptionWrapper">
      <p :class="$style.subtitle">
        Indicate the identified churn reason below.
      </p>
      <p>
        The studio will keep its access to the platform. If you want to
        deactivate studio access, click on the Deactivate platform access in the
        studio settings planel.
      </p>
    </div>
    <div :class="$style.reasons">
      <el-radio
        v-model="churnReason"
        label="Studio moved to competitor"
        data-test-id="studioPageChurnReasonRadioButton"
      >
        Studio moved to competitor
      </el-radio>
      <el-radio
        v-model="churnReason"
        label="Studio went to self-publish"
        data-test-id="studioPageChurnReasonRadioButton"
      >
        Studio went to self-publish
      </el-radio>
      <el-radio
        v-model="churnReason"
        label="Studio moved out of hypercasual"
        data-test-id="studioPageChurnReasonRadioButton"
      >
        Studio moved out of hypercasual
      </el-radio>
      <el-radio
        v-model="churnReason"
        label="Churned by Voodoo's initiative"
        data-test-id="studioPageChurnReasonRadioButton"
      >
        Churned by Voodoo's initiative
      </el-radio>
    </div>

    <div :class="$style.regrettedWrapper">
      <div>Is it a regretted churn?</div>
      <div>
        <el-radio
          v-model="isRegretted"
          :label="true"
          data-test-id="studioPageRegrettedChurnRadioButton"
        >
          Yes
        </el-radio>
        <el-radio
          v-model="isRegretted"
          :label="false"
          data-test-id="studioPageRegrettedChurnRadioButton"
        >
          No
        </el-radio>
      </div>
    </div>

    <div slot="footer" :class="$style.footer">
      <el-button size="medium" data-test-id="studioPageChurnCancelButton" @click="cancel"> Cancel </el-button>

      <el-button
        type="primary"
        size="medium"
        :disabled="!churnReason || isRegretted === null"
        data-test-id="studioPageChurnConfirmButton"
        @click="confirm"
      >
        Confirm
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChurnReason',
  components: {},
  data() {
    return {
      churnReason: '',
      dialogVisible: true,
      isRegretted: null,
    };
  },
  computed: {},
  methods: {
    cancel() {
      this.$emit('close');
    },
    async confirm() {
      this.$emit('change', {
        isChurned: true,
        churnReason: this.churnReason,
        isRegrettedChurn: this.isRegretted,
      });
      this.$emit('close', true);
    },
  },
};
</script>

<style lang="scss" module>
.reasons {
  padding: 0 20px;
}

.reasons > * {
  background-color: $foggy-blue;
  padding: $spacing * 3;
  display: block;
  border-radius: 4px;
  margin-bottom: $spacing;
  transition: all 300ms;
  margin-right: 0;
}

.reasons > *:hover {
  background-color: $cloudy-blue;
}

.subtitle {
  font-weight: 600;
}

.descriptionWrapper {
  font-size: 12px;
  color: $slate;
  padding: 0 20px;
  margin: $spacing * 4 0;

  > p {
    line-height: 17px;
    word-break: normal;
  }
}

.regrettedWrapper {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin: $spacing * 4 0;
  font-size: 12px;
  font-weight: 600;
  color: $slate;
}
[class*="regrettedWrapper"] {
  .el-radio__label {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}

.footer {
  padding: 0 20px;
  padding-bottom: $spacing * 4;
  display: flex;
  justify-content: flex-end;
}
</style>

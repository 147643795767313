<template>
  <div class="notFound">
    <div class="main">
      <CardPage>
        <template v-slot:left></template>
        <template v-slot:right></template>
        <template v-slot:page>
          <div class="page-content">
            <div class="section">
              <h2>Page not found</h2>
              <a href @click="refresh">Refresh</a>
            </div>
          </div>
        </template>
      </CardPage>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
// import { cloneDeep } from 'lodash';
import CardPage from '@/components/CardPage.vue';

export default {
  name: 'StudioAdmin',
  components: {
    // SlidingPage,
    CardPage,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    // Redirect outside the app using plain old javascript
    // window.location.href = '/not-found';
  },
  methods: {
    refresh() {
      this.$router.push({
        name: 'studioHome',
      });
    },
  },
};
</script>

<style lang="scss">
.notFound {
  .main {
    margin: 30px;
    padding: 0;
    // background-color: #ffffff;
    border-radius: 10px;
    justify-content: center;
    .page-content,
    .section {
      padding: 30px;
      align-items: center;
    }
  }
}
</style>

<template>
  <div class="confirm-deactivation">
    <el-dialog :visible.sync="dialogVisible" center @close="cancel">

      <h1>Why do you want to deactivate this studio?</h1>
      <h2>This will remove publishing platform access to all the studio members.</h2>

      <div class="selector">
        <el-radio v-model="deactivateReason" label="Studio moved to competitor">
          Studio moved to competitor
        </el-radio>
        <el-radio v-model="deactivateReason" label="Studio went to self-publish">
          Studio went to self-publish
        </el-radio>
        <el-radio v-model="deactivateReason" label="Studio moved out of hypercasual">
          Studio moved out of hypercasual
        </el-radio>
        <el-radio v-model="deactivateReason" label="Churned by Voodoo's initiative">
          Churned by Voodoo's initiative
        </el-radio>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :disabled="!deactivateReason"
          @click="confirm">
          Confirm
        </el-button>
        <el-button
          class="cancel"
          @click="cancel">
          Cancel
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDeactivation',
  components: {},
  props: {
    resolve: {
      type: Function,
      required: true,
    },
    reject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      deactivateReason: '',
      dialogVisible: true,
    };
  },
  computed: {
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.reject();
    },
    confirm() {
      this.dialogVisible = false;
      this.resolve(this.deactivateReason);
    },
  },
};
</script>

<style lang="scss">
  .confirm-deactivation {
    .el-dialog {
      width: 600px;
      padding: 40px 40px 0;

      h1 {
        text-align: center;
        color: #232323;
        font-size: 16px;
        font-weight: bold;

        margin-bottom: 20px;
        margin-top: -30px;
      }

      h2 {
        text-align: center;
        color: #adadad;
        font-size: 14px;

        margin-bottom: 30px;
      }

      .selector {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 30px;
        }

        .el-input {
          margin-bottom: 30px;
          input {
            padding-right: 60px;
          }
        }
      }

      .dialog-footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: 120px;
          margin-bottom: 10px;
        }

        .cancel {
          border: none;
          margin: 0;
        }
      }
    }
  }
</style>

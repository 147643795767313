<template>
  <div>
    <span :class="$style.label">Project name</span>
    <el-input
      ref="createGameInputName"
      v-model="namecp"
      :class="$style.input"
      size="small"
      :minlength="3"
      :maxlength="25"
      @keyup.enter.native="sendProject">
    </el-input>
    <span v-if="!error" :class="$style.infos">Name length must be between 3 and 25 characters.</span><br>
    <span :class="$style.error">{{error}}</span>
  </div>
</template>

<script>
export default {
  name: 'EditProjectField',
  props: {
    name: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      namecp: this.name,
    };
  },
  watch: {
    namecp() {
      this.editProjectName();
    },
  },
  mounted() {
    this.$refs.createGameInputName.focus();
  },
  methods: {
    sendProject() {
      this.editProjectName();
      this.$emit('send-project-name', this.namecp);
    },
    editProjectName() {
      this.$emit('edit-project-name', this.namecp);
    },
  },
};
</script>

<style lang="scss" module>
    .label {
        color:$slate;
    }
    .infos {
        color: $grey;
        margin-top: 10px;
    }
    .input {
        width: 100%;
        margin: 10px 0;
    }
    .error {
      color: $color-danger-plain-color;
      margin-top: 5px;
      text-align: left;
    }
</style>

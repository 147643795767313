<template>
  <div v-if="events" :class="$style.wrapper">
    <EventItem
      v-for="(update, index) in updates.reverse()"
      :key="update.timestamp + index"
      :date="update.timestamp"
      :updated-by="update.updatedBy"
      :title-label="update.field"
      :title-value="update.value"
    />
    <EventItem
      :key="creation.timestamp"
      :date="creation.timestamp"
      :title-value="creation.value"
      is-creation
    />
  </div>
</template>

<script>
import EventItem from './EventItem.vue';

export default {
  name: 'EventList',
  components: {
    EventItem,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    events() {
      return this.$store.getters['studios/studioEvents'] || {};
    },
    loading() {
      return this.$store.getters['studios/eventsLoading'];
    },
    creation() {
      return this.events?.creation;
    },
    updates() {
      return this.events?.updates ? [...this.events?.updates] : [];
    },
  },
  async mounted() {
    await this.$store.dispatch('studios/getStudioEvents', this.id);
  },
};
</script>

<style lang="scss" module>
.wrapper {
  max-height: 500px;
  overflow-y: auto;
}
</style>

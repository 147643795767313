<template>
  <div class="bar">
    <el-header class="bar-header">
      <el-row
        type="flex"
        class="row-bg"
        justify="space-between"
        align="middle">
        <div class="container">
          <h1 class="list">{{` Dashboard `}}</h1>
          <div v-if="showButtonRight" class="right">
            <el-button type="success" @click="clickOnAddButton">
              + new item
            </el-button>
          </div>
        </div>
      </el-row>
    </el-header>
    <div class="margin-header"></div>
  </div>
</template>

<script>
export default {
  name: 'Bar',
  components: {},
  props: {
    showButtonRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickOnAddButton() {
      this.$emit('click-button-right');
    },
  },
};
</script>

<style lang="scss">
.el-header {
  line-height: 55px;
}
.bar-header {
  border-bottom: 1px solid $header-backgroud;
  color: $header-font-color;
  background-color: #000;
  padding: 0 30px;
  position: fixed;
  width: 100%;
  z-index: 100;
  .container {
    display: inline-flex;
    height: 60px;
    justify-content: space-between;
    width: 100%;
  }
  h1.name {
    margin-left: 100px;
    @media screen and (max-width: 1200px) {
      width: 332px;
    }
    span {
      padding: 0 15px 0 0;
    }
    .icon-app {
      width: 30px;
      height: 30px;
    }
  }
}
</style>

const column = [
  {
    id: 'studio_name',
    label: 'Studio name',
    width: 100,
    align: 'left',
  },
  // {
  //   id: 'pm',
  //   label: 'Publishing manager',
  //   width: 100,
  // },
  // {
  //   id: 'created_at',
  //   label: 'Created at',
  //   width: 100,
  //  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   width: 100,
  // },
  // {
  //   id: 'staff',
  //   label: 'Staff',
  //   width: 100,
  // },
  // {
  //   id: 'country',
  //   label: 'Country',
  //   width: 100,
  // },
  // {
  //   id: 'role',
  //   label: 'Status',
  //   width: 100,
  //   align: 'center',
  // },
  //  {
  //    id: 'number_employees',
  //    label: 'Number employees',
  //    width: 100,
  //    align: 'center',
  //  },
  // {
  //   id: 'games',
  //   label: 'Submitted games',
  //   width: 100,
  // },
  // {
  //   id: 'contract',
  //   label: 'Contract',
  //   width: 100,
  // },
];

export default column;

<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch('users/logout').then(() => {
      this.$router.push('login');
    });
  },
};
</script>

<style lang="scss">
</style>

<template>
  <div class="tab">
    <div
      v-for="(section, index) in sections"
      :key="index"
      :class="{ active: currentSection === index }"
      class="section"
      @click="changeSection(index)"

    >
      <div>
        <div class="title-content">
          <span
            v-if="section.title !== 'global'"
            class="adnetwork-icon"
            :class="'icon-' + section.title.toLowerCase()"
          ></span>
          <div
            class="title"
            data-test-id="gameInfosIterationTabTitle"
          >
            {{ section.title }}
          </div>
        </div>
      </div>
      <div class="bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    sections: {
      type: Array,
      default() {
        return [
          {
            title: 'global',
          },
          {
            title: 'snapchat',
          },
          {
            title: 'facebook',
          },
        ];
      },
    },
  },
  data() {
    return {
      currentSection: 0,
    };
  },
  methods: {
    changeSection(index) {
      if (index === this.currentSection) return;
      this.currentSection = index;
      this.$emit('change', this.sections[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid $foggy-grey;
}
.section {
  display: block;
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 15px 30px 0px 30px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 32px;
  background-color: $foggy-blue;
  border-right: 1px solid $foggy-grey;
  &.active {
    background-color: #fff;
    .bar {
      margin-top: 15px;
      background-color: #067bf0;
      height: 4px;
      max-width: 45px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.section:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.section:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px;
}

.section:hover {
  background-color: $cloudy-blue;
}

.section.active:hover {
  background-color: $white;
  cursor: auto;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-weight: 600;
}

.title:first-letter {
  text-transform: capitalize;
}

.title-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adnetwork-icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px;
  margin: 0px 9px 0px 0px;
  top: 0;
  right: 0;
  &.icon-facebook {
    background-image: url("~@/assets/images/icon-facebook.svg");
  }
  &.icon-snapchat {
    background-image: url("~@/assets/images/icon-snapchat.svg");
  }
}
</style>

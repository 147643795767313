
import axios from '@/tools/axios';

const ProjectSearch = {
  namespaced: true,
  actions: {
    search(context, data) {
      return axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/projects/search`,
        method: 'post',
        data,
      });
    },
  },
};

export default ProjectSearch;

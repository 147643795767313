<template>
  <div
    v-if="game"
    ref="wrapper"
    :class="$style.wrapper"
    class="voodoo-scroller">
    <div :class="$style.side">
      <Stepper
        :class="$style.stepper"
        :steps="steps"
        :current="current"
        @change="scrollStep"
      />
    </div>
    <div :class="$style.containerContent">
      <div
        id="container-scrollable"
        ref="content"
        :class="$style.content"
        class="voodoo-scroller-container"
        @mouseover="scroll = true"
        @mouseout="scroll = false">
        <div
          v-for="(item, index) in steps"
          :key="item.name"
        >
          <component
            :is="item.component"
            :id="'' + game.id"
            :ref="'step' + index"
            data-test-id="gameSubmissionStep"
            :warning="contentfulWarning"
            :index="index"
            :step="item"
            :steps="steps"
            :class="$style.step"
            :title="index+1 +'. '+ item.title"
            :game="game"
            :current="current"
            @change="changeStep"
            @isValid="validateStep(index)"
          />
        </div>
      </div>
      <Tutorial
        :class="$style.tutorial"
        :index="current"
        :game="game"
        :step="step"
        :steps="steps"
        :name="steps[current].tutorial"
        :initial-size="isMobile ? '100%' : '10%'"
        :direction="isMobile ? 'y': 'x'"
      ></Tutorial>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-duplicates */

import LinkStoreStepOnPage from '@/modules/GameSubmission/components/LinkToStore.vue';
import TinySauceStepOnPage from '@/modules/GameSubmission/components/TinySauce.vue';
import AppStoreSubmissionStepOnPage from '@/modules/GameSubmission/components/AppStoreInput.vue';
import IntegrationStepOnPage from '@/modules/GameSubmission/components/TestBuilding.vue';
import WithoutAction from '@/modules/GameSubmission/components/WithoutAction.vue';
import FacebookStepOnPage from '@/modules/GameSubmission/components/FacebookSetup.vue';
import Categories from '@/modules/GameSubmission/components/Categories.vue';
import FinalizeAppSetup from '@/modules/GameSubmission/components/AppConfig.vue';
import GameDetailsNew from '@/components/Game/GameDetailsNew.vue';
import Tutorial from '@/modules/GameSubmission/components/Tutorial.vue';
import ContentfulService from '@/services/contentful.services';
import Stepper from './components/Stepper.vue';
import Step from './components/Step.vue';
import steps from './data/steps';
import GameDetails from './components/Step.vue';

export default {
  components: {
    Stepper,
    Step,
    Tutorial,
    LinkStoreStepOnPage,
    WithoutAction,
    IntegrationStepOnPage,
    GameDetails,
    AppStoreSubmissionStepOnPage,
    FacebookStepOnPage,
    TinySauceStepOnPage,
    FinalizeAppSetup,
    Categories,
    GameDetailsNew,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stepSelected: null,
      steps: steps.getAllStepArrayByGame(this.game),
      step: null,
      scrollX: null,
      scrollY: null,
      lastScrollTop: 0,
      y: 0,
      wait: false,
      scroll: false,
      scrollByStep: false,
      contentfulWarning: null,
    };
  },
  computed: {
    current() {
      return this.steps.map(step => step.state).indexOf('current') !== -1 ? this.steps.map(step => step.state).indexOf('current') : 0;
    },
    tutorial(index) {
      if (this.steps[index]?.tutorial) {
        return this.steps[index].tutorial;
      }
      return null;
    },
    isMobile() {
      return this.$bp('xs-');
    },
  },
  async mounted() {
    this.$refs.content.style.overflowY = 'auto';
    if (this.getCurrentStepId()) {
      this.scrollStep(this.getCurrentStepId());
    }

    const contentful = await ContentfulService.fetch('WARNINGS');
    if (contentful) {
      this.contentfulWarning = contentful;
    }
  },
  methods: {
    getCurrentStepId() {
      try {
        const constCurrentStep = JSON.parse(this.game.substatus)?.current || null;
        for (let i = 0; i < this.steps.length; i += 1) {
          const element = this.steps[i];
          if (element.id === constCurrentStep) {
            return i;
          }
        }
      } catch (e) {
        return null;
      }
      return null;
    },
    scrollStep(index) {
      if (index === 0) {
        this.gotoFirstStep();
      }
      if (this.$refs[`step${index}`] && this.$refs[`step${index}`].length > 0) {
        const stepTarget = this.$refs[`step${index}`][0].$el;
        stepTarget.scrollIntoView({ behavior: 'smooth' });
      }
    },
    gotoFirstStep() {
      let status = null;
      try {
        status = JSON.parse(this.game.substatus)?.done || 'create';
      } catch (error) {
        status = 'create';
      }
      const substatus = {
        current: this.steps[0].id,
        done: status,
      };
      this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
    },
    async changeStep(index) {
      this.stepSelected = this.steps[index];
      this.steps[this.current].state = 'done';
      this.steps[index].state = 'current';

      const substatus = {
        current: this.steps[index].id,
        done: JSON.parse(this.game.substatus)?.done || 'create',
      };

      await this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
    },
    async validateStep(index) {
      this.steps[index].isValid = true;
      const nextStepIsValid = this.steps[index + 1].isValid;
      const substatus = {
        current: this.steps[index].id,
        done: nextStepIsValid ? JSON.parse(this.game.substatus)?.done : this.steps[index].id,
      };


      await this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: JSON.stringify(substatus),
      });
      this.scrollStep(index + 1);
    },
  },
};
</script>

<style lang="scss" module scoped>
.wrapper {
  display: grid;
  grid-template-columns: 16% 84%;
  overflow-y: auto;
  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;
  }
  .containerContent{
    display: flex !important;
    height: calc(100vh - 63px);
  }
}
.stepper{
  padding: 10px 25px;
  grid-column: 1;
  width: 180px;
  overflow-y: auto;
  height: calc(100vh - 63px) !important;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.voodoo-scroller-container{

}


.content {
  height: 100%;
  overflow-y: hidden;
  // scroll-snap-type: y mandatory;
  overflow: hidden;
  width: 90% !important;
  @media screen and (max-width: 700px) {
    width: 100% !important;
  }
}

.tutorial {
  // grid-column: col-start 12 / span 2;
  height: calc(100vh - 63px);
  overflow: auto;
  width: 10%;
  @media screen and (max-width: 700px) {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

.step {
  scroll-snap-align: start;
}
</style>

import axios from '@/tools/axios';

export default class NotificationService {
  static async getAllNotifications() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/notification/list`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getLatestNotifications() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/notification/latest`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async setReadedNotifications(notifId) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/notification/${notifId}/read`,
      method: 'put',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async sendNotification(param) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/notification`,
      method: 'post',
      data: {
        type: 'info',
        content: param.message,
        recipientId: param.id,
        relatedContentId: null,
      },
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return r;
      })
      .catch(() => null);
  }
}

<template>
  <div :class="$style.container">
    <h2 id="title-categories">
      {{title}}
    </h2>
    <div :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <GenresSelector
        ref="genresSelector"
        class="gamegenre"
        :game="game"
        :disabled="!canEditGameGenre"
        :genres-for-comp="isPreEligibleForCompetition ? currentCompetition.allowedGenres : []"
        :competition="currentCompetition"
        is-column
        @selectedGenres="updateGenres"
        @genres-state="setGenresValid"
      ></GenresSelector>
      <GameTagsInputs
        v-if="!game.isIterating"
        ref="tagsInstructions"
        :game="game"
        :result="searchResult"
        :can-edit-game-tags="canEditGameGenre"
        @add="addTag"
        @search="searchTags"
        @delete="deleteTag"
        @error="displayError"
        @ready="ready"
      ></GameTagsInputs>
    </div>
    <br>
    <el-button
      id="validate-categories"
      size="small"
      type="primary"
      :disabled="!isStepValid"
      :class="$style.validate"
      data-test-id="gameSubmissionCategoriesAndTagsValidate"
      @click="gotoCreateCamapign">
      Next step
      <i
        class="el-icon-right"
      ></i>
    </el-button>
  </div>
</template>

<script>
import GameTagsInputs from '@/components/GameSubmissionForm/GameTagsInputs/GameTagsInputs.vue';
import GenresSelector from '@/components/GameSubmissionForm/GenresSelector.vue';

import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';

export default {
  components: {
    GameTagsInputs,
    GenresSelector,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    current: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'no name',
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      cpGenres: {},
      genreIsValid: this.game && this.game.genres.length > 0,
      searchResult: [],
      tagsIsValid: false,
      isIntersecting: false,
      isvisible: false,
    };
  },
  computed: {
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
    isStepValid() {
      return this.genreIsValid && this.tagsIsValid;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    gamePermissionsUser() {
      return Object.keys(this.game.permissions);
    },
    canEditGameGenre() {
      return this.gamePermissionsUser.indexOf('edit_game_genre') > -1
      || this.myPermissions.indexOf('edit_game_genre') > -1;
    },
    isPreEligibleForCompetition() {
      // Returns true if the options selected in previous screens match the requirements of the competition
      if (!this.currentCompetition) {
        return false;
      }

      if (!this.currentCompetition.allowedPlatforms.includes(this.game.platform)) {
        return false;
      }

      if (this.game.isCtr && !this.currentCompetition.allowedTestingMethods.includes('ctr')) {
        return false;
      }

      if (!this.game.isCtr && !this.currentCompetition.allowedTestingMethods.includes('cpi')) {
        return false;
      }

      return true;
    },
    currentCompetition() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].currentCompetition : undefined;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
  },
  watch: {
    isStepValid(validity) {
      this.$emit('isValid', validity);
    },
  },
  mounted() {
    tools.attachEvent(document.getElementById('container-scrollable'), 'scroll', this.update);
    tools.attachEvent(window, 'resize', this.update);
  },
  methods: {
    update() {
      const titleElement = document.getElementById('title-categories');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    updateGenres(value) {
      this.cpGenres = cloneDeep(value);
    },
    setGenresValid(value) {
      this.genreIsValid = value;
    },
    async addTag(tag) {
      try {
        await this.$store.dispatch('genreTags/patchTags', {
          tag,
          gameId: this.game.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async deleteTag(tag) {
      try {
        await this.$store.dispatch('genreTags/deleteTags', {
          tag,
          gameId: this.game.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    displayError(error) {
      return tools.decodeError(error).map((err) => {
        if (!err.code) {
          return err;
        }
        return this.codification.errorList[err.code] ? this.codification.errorList[err.code].message : err.code;
      }).join('<br />');
    },
    searchTags(tag) {
      this.$store.dispatch('genreTags/searchTags', tag)
        .then((response) => {
          this.searchResult = response.data;
        }).catch(() => {
          this.searchResult = null;
        });
    },
    ready(value) {
      this.tagsIsValid = value;
    },
    async gotoCreateCamapign() {
      this.$store.dispatch('submitGame/setNextStep', {
        game: this.game,
        status: 'upload_asset',
      });
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  padding-top: 0;
  position: relative;

  .layer{
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.validate {
  display: flex;
}
</style>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.label">AppStore ID</div>
    <div :class="$style.explanation">
      Make sure to register the correct AppStore ID.
      <br />
      You will not be able to edit it later on.
    </div>
    <el-input
      id="appStoreInput"
      :value="value"
      placeholder="e.g 1234567890"
      :disabled="!!game.storeId || !canCreateGaSlot"
      min="1"
      size="small"
      maxlength="10"
      type="number"
      data-test-id="gameSubmissionAppstoreInput"
      @input="changeValue"
      @change="validateCpAppStoreId"
    ></el-input>
    <p v-if="!isValidCpAppStoreId" :class="$style.error">
      Valid AppStore IDs are 9-10 digits long and start with a non-zero digit
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    game: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isValidCpAppStoreId: true,
    };
  },
  computed: {
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canCreateGaSlot() {
      return (
        this.gamePermissions.includes('create_ga_slot')
        || this.myPermissions.includes('create_ga_slot')
      );
    },
  },
  methods: {
    validateCpAppStoreId() {
      this.isValidCpAppStoreId = this.value && this.value.match(/^[1-9][0-9]{8,9}$/) !== null;
      this.$emit('isValid', this.isValidCpAppStoreId);
    },
    changeValue(value) {
      this.$emit('input', value);
      this.searchEmit(value);
    },
    // eslint-disable-next-line func-names
    searchEmit: debounce(function (value) {
      this.$emit('search', value);
    }, 300),
  },
};
</script>

<style lang="scss" module>
.wrapper {
  text-align: left;

  input {
    border-radius: 8px;
  }
}

.label {
  color: $slate;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.explanation {
  color: $grey;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 15px;
}

.error {
  padding: $spacing 0;
  font-size: 12px;
  color: $blood-normal;
}
</style>

<template>
  <div>
    <CardPage v-if="me" :no-header="true">
      <template v-slot:page>
        <div class="page-content-studio-list">
          <!-- <div class="section"> -->
          <el-table
            id="studio-admin-table"
            ref="studiotable"
            :max-height="tableHeight"
            :show-header="true"
            :stripe="true"
            :data="studioList"
            :sort-method="sortMethod"
            style="width: 100%"
            @sort-change="sortBy"
            @cell-click="goto"
          >
            <div slot="empty">
              <div v-if="loading">
                <i class="el-icon-loading loading-list"></i>
              </div>
              <div v-else>We didn't find any studio attached to you, check on PM side.</div>
            </div>

            <el-table-column
              v-for="(header, index) of column"
              :key="index"
              :sortable="false /*header.id !== 'studioName' && header.id !== 'videos' ? 'custom' : false*/"
              :prop="header.id"
              :label="header.label"
              :align="header.align"
              :min-width="header.width"
              :class-name="`column-${header.id} column`"
            >
              <template slot-scope="scope">
                <div v-if="header.id === 'studio_name'" :id="`id-${scope.row.id}`">
                  <div class="studio-name">
                    <span class="name">{{ `${scope.row.studioName}` || "-" }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- </div> -->
        </div>
      </template>
    </CardPage>
    <!-- <div v-else class="loading">
      <i class="el-icon-loading loading-list"></i>
    </div>-->
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import CardPage from '@/components/CardPage.vue';
import column from '@/../json/adminStudioListColumn';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'StudioAdmin',
  components: {
    // SlidingPage,
    CardPage,
  },
  props: {
    sub: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableHeight: 500,
      gameIcon,
    };
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return (
        cloneDeep(this.$store.getters['adminstudio/loading'])
        || cloneDeep(this.$store.getters['adminstudio/loading_studio_infos'])
        || false
      );
    },
    studioList() {
      return cloneDeep(this.$store.getters['adminstudio/studios']) || [];
    },
    column() {
      return column;
    },
  },
  watch: {
    meForm() {
      const me = JSON.stringify(this.me);
      const meForm = JSON.stringify(this.meForm);
      this.canSave = me !== meForm;
    },
  },
  mounted() {
    if (this.studioList.length === 0) {
      this.$store.dispatch('adminstudio/getStudios');
    }
  },
  methods: {
    formatIdForLabel(value) {
      let r = null;
      if (value) {
        r = value.replace('_', ' ');
        r = r.charAt(0).toUpperCase() + r.slice(1);
      }

      return r;
    },
    sortMethod() {
      return null;
    },
    sortBy() {},
    goto(row) {
      // this.$router
      //   .push({ query: { studio: row.id, view: this.$route.query.view } })
      //   .catch(() => {});
      this.$router.push({
        name: 'studioAdmin',
        params: { id: `${row.id}` },
      });
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('DD MMM YYYY');
      }
      return 'n/a';
    },
    resetForm() {
      this.password = {
        current: '',
        new: '',
        confirm: '',
      };
    },
    saveProfile() {
      const user = {
        firstName: this.meForm.firstName,
        lastName: this.meForm.lastName,
        email: this.meForm.email,
        profile: this.meForm.profile,
      };
      this.$store.dispatch('users/updateUser', user);
    },
  },
};
</script>

<style lang="scss">
.main {
  justify-content: center;
}
.no-studio,
.loading {
  border-color: #ebeef5;
  min-height: 200px;
  width: 100%;
  // color: #409eff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-studio-list {
  padding: 0;
  .el-table__row {
    &:hover {
      cursor: pointer;
    }
  }
  .el-table {
    border-radius: 30px;
  }
  #studio-admin-table {
    th {
      &.column-studio_name {
        .cell {
          // display: flex;
          // align-items: center;
          // justify-content: flex-end;
          margin-left: 10px;
        }
      }
    }

    .studio-name {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}
</style>

<template>
  <div class="pm-and-lead-input">
    <div class="input">
      <span class="label">Primary PM <i v-if="pmLoading" class="el-icon-loading"></i></span>
      <el-select
        v-if="myPermissions.indexOf('change_publishing_manager') > -1"
        v-model="primaryPm"
        :disabled="pmLoading"
        data-test-id="gameInfosInputPrimaryPm"
        size="mini"
        placeholder="Select">
        <el-option
          v-for="item in publishingManagers"
          :key="item.value"
          :label="item.label"
          :value="item.value"> </el-option>
      </el-select>
      <span v-else class="pm-and-lead-value">{{ primaryPm || '--' }}</span>
    </div>

    <div class="input">
      <span class="label">Secondary PM <i v-if="secondaryPmLoading" class="el-icon-loading"></i></span>
      <el-select
        v-if="myPermissions.indexOf('change_publishing_manager') > -1"
        v-model="secondaryPm"
        :disabled="secondaryPmLoading"
        data-test-id="gameInfosInputSecondaryPm"
        size="mini"
        placeholder="Select">
        <el-option
          v-for="item in publishingManagers"
          :key="item.value"
          :label="item.label"
          :value="item.value"> </el-option>
      </el-select>
      <span v-else class="pm-and-lead-value">{{ secondaryPm || '--' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PmAndLeadInput',
  components: {},
  props: {
    /**
     * Game object
     */
    game: {
      type: Object,
      default: () => ({}),
    },
    /**
     * loading value
     */
    pmLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * loading value
     */
    secondaryPmLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of options in select
     */
    publishingManagers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    primaryPm: {
      get() {
        if (this.game && this.game.studio && this.game.studio.pm) {
          return `${this.game.studio.pm.firstName} ${this.game.studio.pm.lastName}`;
        }
        return null;
      },
      set(newValue) {
        this.$emit('update_pm', newValue);
      },
    },
    secondaryPm: {
      get() {
        if (this.game && this.game.studio && this.game.studio.secondaryPm) {
          return `${this.game.studio.secondaryPm.firstName} ${this.game.studio.secondaryPm.lastName}`;
        }
        return null;
      },
      set(newValue) {
        this.$emit('update_secondaryPm', newValue);
      },
    },
  },
  methods: {
    pushAction(action, value) {
      this.$emit(action, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.pm-and-lead-input {
  display: flex;
  justify-content: space-between;
  .input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    .label {
      color: $slate;
      font-size: 10px;
      line-height: normal;
      margin-bottom: 5px;
      text-align: left;
    }
    .pm-and-lead-value {
      text-align:left;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    .input {
      display: flex;
      flex-direction: column;
      margin: 3px 0;
    }
  }
}
</style>

<style lang="scss">
.pm-and-lead-input {
  .el-input--mini .el-input__inner {
    height: 35px;
    line-height: 28px;
    border-radius: 8px;
  }

  .input .label {
    font-weight: 600;
  }
}
</style>

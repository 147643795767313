<template>
  <div>
    <el-button
      type="primary"
      plain
      skin="blue"
      data-test-id="gameSubmissionCropperOpenButton"
      @click="openDialog = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#067BF0" fill-rule="evenodd" d="M4.185 8.34l3.61-3.587V8.34h-3.61zm2.52-4.574L3.248 7.2V3.766h3.457zm4.19 4.574H9.32V3.238l1.609-1.598c.252-.25.316-.59.146-.76l-.423-.42c-.171-.17-.514-.106-.765.145L8.23 2.25H3.248V.496a.498.498 0 00-.5-.496h-.524c-.276 0-.5.222-.5.496v1.755H.459c-.253 0-.459.223-.459.498v.52c0 .275.206.497.46.497h1.264V9.37c0 .241.224.436.5.436h5.572v1.362c0 .255.224.463.5.463h.524c.276 0 .5-.208.5-.463V9.805h1.574c.276 0 .5-.194.5-.435v-.595c0-.24-.224-.435-.5-.435z"/></svg>
    </el-button>
    <el-dialog
      custom-class="cropping-modal"
      :append-to-body="true"
      :title="croppingValue ? 'Cropping tool' : ''"
      :visible.sync="openDialog"
      width="80%"
      @close="openDialog = false"
    >
      <div class="warning">
        <div class="content">⚠️ If you've added text (not recommended), <br>make sure not to cut it or the ad will be rejected by the ad platform.</div>
      </div>
      <CropperComponent
        ref="cropElement"
        :src="videoToCrop.src"
        :available-ratios="videoToCrop.availableRatios"
        :autoplay="videoToCrop.autoplay"
        class="gfs-big-bottom-margin"
        @cropEvent="setCroppingValue"
      ></CropperComponent>
      <div v-if="croppingValue" class="footer">
        <el-button type="primary" data-test-id="gameSubmissionCropperValidateButton" @click="validateCropping()">
          Validate
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CropperComponent from '@/components/CropperComponent/CropperComponent.vue';

export default {
  name: 'CroppingDialog',
  components: {
    CropperComponent,
  },
  props: {
    /**
     * Object with video url and Cropper component options
     */
    videoToCrop: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      openDialog: false,
      croppingValue: null,
    };
  },

  methods: {
    setCroppingValue(value) {
      this.croppingValue = value;
    },

    validateCropping() {
      this.$emit('setCrop', this.croppingValue);
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss">
.warning {
  height: 34px;
  margin: 15px 64px 50px 64px;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  .content {
    width: 440px;
    margin-left: auto;
    margin-right: auto;
  }
}

.cropping-modal {
  margin-top: 6vh !important;
  .el-dialog__header {
    background-color: $foggy-blue;
    padding: 30px;
  }

  .el-dialog__headerbtn {
    position: absolute;
    top: 25px;
    right: 20px;
    padding: 0;
    cursor: pointer;
    font-size: 16px;
    background-color: $light-blue;
    border-radius: 50%;
    fill: white;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 3px
  }

  .el-dialog__headerbtn .el-dialog__close:hover {
    color: white;
  }

  .el-dialog__title {
    line-height: 24px;
    font-size: 18px;
  }
  .el-dialog__body {
    width: 100%;
    margin: 0;
    .footer {
      display: flex;
      padding-top: 20px;
      justify-content: center;
    }
  }
}
</style>

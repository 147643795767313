<template>
  <div class="contain">
    <div class="infos-game-component" :class="{ 'dark-mode': darkMode }">
      <AsyncAction
        v-if="gameInfos"
        :key="gameInfos.status + gameInfos.id"
        :game="gameInfos"
      ></AsyncAction>
      <div v-if="gameInfos.loadingRequest" class="disabled-game">
        <span>There are ongoing operations</span>
        <span>
          <i class="el-icon-loading"></i>
        </span>
      </div>
      <div v-if="!loading" class="content">
        <div v-if="gameInfos.name" class="infos-top">
          <div class="infos-left">
            <GameInfos :game="gameInfos" :is-pm-view="isPublishing">
              <ProtoScore
                v-if="!isPublishing"
                :recommendation="game.prototypeRecommendation.recommendation"
                :score-values="formattedProtoScore"
                :display-details="myPermissions.includes('view_proto_score')"
              ></ProtoScore>
            </GameInfos>
          </div>

          <div class="button-right">
            <div class="inline">
              <ProtoScore
                v-if="isPublishing"
                :recommendation="game.prototypeRecommendation.recommendation"
                :score-values="formattedProtoScore"
                :display-details="myPermissions.includes('view_proto_score')"
              ></ProtoScore>
              <VideoButton v-if="isPublishing" :game="gameInfos"></VideoButton>
              <ActionButton :game="gameInfos"></ActionButton>
            </div>
          </div>
        </div>
        <div v-if="isPublishing" class="infos-bottom">
          <div class="tags">
            <div class="categoryNames">Categories:</div>
            <div>
              <span v-for="tag in game.genres" :key="tag.genre" class="tag">{{ tag.genre }}</span>
            </div>
          </div>
          <div class="tags">
            <div class="categoryNames">Tags:</div>
            <div>
              <span v-for="tag in game.tags" :key="tag.genre" class="tag">{{ tag.genre }}</span>
            </div>
          </div>
          <div class="button-right">
            <PmAndLeadInput
              v-if="gameInfos.name && isPublishing"
              :game="gameInfos"
              :publishing-managers="publishingManager"
              :pm-loading="pmloading"
              :secondary-pm-loading="pm2loading"
              @update_pm="updatePublishingManager($event, 'updatePm')"
              @update_secondaryPm="updatePublishingManager($event, 'updatePm2')"
            ></PmAndLeadInput>
          </div>
        </div>
      </div>
      <div v-else class="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';
import ActionButton from '@/modules/ActionButton';
import AsyncAction from '@/components/AsyncAction.vue';
import VideoButton from '@/components/VideoButton.vue';
import GameInfos from '@/Layouts/GameInfos/GameInfos.vue';
import PmAndLeadInput from '@/Layouts/PmAndLeadInput/PmAndLeadInput.vue';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';
import GamesService from '@/services/games.services';
import StudioService from '@/services/studios.services';

export default {
  name: 'InfosGameTop',
  components: {
    ProtoScore,
    ActionButton,
    AsyncAction,
    VideoButton,
    GameInfos,
    PmAndLeadInput,
  },
  props: {
    gameid: {
      default: null,
      type: String,
    },
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      actionList,
      currentAction: null,
      modalActionShow: false,
      modalChangeAssocCampaignShow: false,
      modalGamegenreShow: false,
      genreObj: {},
      isOpenFeedbacks: false,
      gamePmApi: {},
    };
  },
  computed: {
    darkMode() {
      return this.$store.getters['users/mode'] === 'dark';
    },
    isPublishing() {
      return this.$route.meta
        ? this.$route.meta.category === 'Publishing'
        : false;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    gameInfos() {
      if (this.gamePmApi) {
        return {
          ...this.game,
          studio: this.gamePmApi,
          gameLead: this.gamePmApi.gameLead,
          activityState: this.gamePmApi.activityState,
        };
      }
      return { ...this.game };
    },
    status() {
      return this.$store.getters['users/statusList'];
    },
    loading() {
      return this.$store.getters['games/loadingInfos'];
    },
    pmloading() {
      return this.$store.getters['games/pmLoading'];
    },
    pm2loading() {
      return this.$store.getters['games/pm2Loading'];
    },
    publishingManager() {
      if (this.codification) {
        return cloneDeep(this.codification.publishingManagers)
          .sort((a, b) => (a.label < b.label ? -1 : 1))
          .filter(el => el.value !== null);
      }
      return [];
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    formattedProtoScore() {
      return Object.entries(this.game.prototypeRecommendation.adNetworks).map(
        entry => ({
          label: entry[0],
          value: entry[1].score,
        }),
      );
    },
  },
  mounted() {
    if (this.isPublishing) {
      this.getGameFromPm();
    }
  },
  methods: {
    async getGameFromPm(refresh) {
      let gameApi = this.game;
      if (!this.game || refresh === 'game') {
        gameApi = await GamesService.getGamesInfos(this.game.id);
      }
      let studioApi = null;
      if (gameApi) {
        studioApi = await StudioService.getStudiosInfos(gameApi.studio.id);
      }
      this.gamePmApi = {
        ...gameApi,
        activityState: studioApi ? studioApi.activityState : null,
        ...studioApi,
      };
    },
    async updatePublishingManager(pmid, storeRoute) {
      const pm = this.publishingManager.find(el => el.value === pmid);
      const param = {
        studioId: this.gameInfos.studio.id,
        publishingManagerId: pm,
      };
      await this.$store.dispatch(`games/${storeRoute}`, param);
      this.getGameFromPm();
    },
  },
};
</script>

<style lang="scss">
.el-popper {
  span.danger {
    color: $blood-normal;
  }
}
.contain {
  background-color: $foggy-blue;
  box-shadow: 0 0 10px 5px rgba(6, 123, 240, 0.1);
  position: fixed;
  z-index: 1;
  max-width: 1200px;
  width: calc(100% - 10.2rem);

    @media (max-width: 700px) {
      width: 100%;
      position: relative;
    }
}
.infos-game-component {
  position: relative;
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  .disabled-game {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 10px;
    background-color: $transparent-cerulean;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $intense-blue;
    font-weight: bold;
    span {
      margin-right: 10px;
    }
  }
  .content {
    padding: 17px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-gamepage);
    border-radius: 10px;

    @media (max-width: 700px) {
      padding: 35px 10px 10px 10px;
    }

    .infos-top {
      display: grid;
      grid-template-columns: 50% 50%;
      @media (max-width: 700px) {
        grid-template-columns: 1fr;
      }

      .infos-left {
        display: flex;
        align-items: center;

        @media (max-width: 700px) {
          margin-bottom: 10px;
        }
      }
      .button-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .inline {
          display: flex;
          align-items: center;
        }
      }
  }
  .infos-bottom {
        display: grid;
        grid-template-columns: 25% 25% 50%;
        align-items: center;
        flex-wrap: wrap;

      .categoryNames {
              padding:5px;
              font-size: 12px;
              margin-right: 5px;
      }

      .tags {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 5px;
              // max-width: 350px;
              overflow: auto;

              .tag {
                border-radius: 20px;
                background-color: #e6f1fd;
                color: #067bf0;
                display: inline-flex;
                margin-right: 10px;
                margin-bottom: 5px;
                padding: 5px 7px;
                font-size: 12px;
              }
            }
    }
  }
}
</style>

const errorReasonDisplay = {
  'error.login.wrongpassword': 'Wrong password.',
  'error.login.passverifunequal': 'The two passwords aren\'t the same.',
  'error.login.insecurepassword': 'Your password is insecure.',
  'error.login.toolongpassword': 'Your password is too long.',
  'error.login.operationforbidden': 'An error occurred. Please contact the support.',
};

module.exports.getErrorDisplay = (error) => {
  if (!error || !error.reason) {
    return 'An error occurred, please refresh or contact support (studio-support@voodoo.io).';
  }

  if (errorReasonDisplay[error.reason]) {
    return errorReasonDisplay[error.reason];
  }

  return error.reason;
};

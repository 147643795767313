<template>
  <transition name="list">
    <div v-if="open" class="sliding-page" @click="close">
      <i class="el-icon-back close mobile-close" close @click="close" />
      <div class="wrapper" @click.stop="">
        <i class="vd-svg-button-on close" @click="close" />
        <div class="container-page">
          <slot name="container">
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'SlidingPage',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.sliding-page {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  background-color: $transparent-dark;
  padding-left: 0px;
  transition: background-color 0.3s ;

  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }

  .container-page{
    position: relative;
    margin-left: auto;
    height: 100%;
    background-color: $white;
    overflow: hidden;
    @media (max-width: 700px) {
      margin-left: 0;
      width: 100%
    }
  }

  .wrapper {
    overflow: initial;
    max-width: 1200px;
    position: relative;
    width: calc(100% - 10.2rem);
    margin-left: auto;
    height: 100%;
    @media (max-width: 700px) {
      margin-left: 0;
      width: 100%
    }
  }

  .el-icon-back:before {
    display: block;
    margin-top: 2px;
  }

  .close {
    position: absolute;
    left: -3rem;
    top: 15px;
    font-size: 30px;
    color: $intense-blue;
    z-index: 10;
    width: 30px;
    height: 30px;
    @media (max-width: 700px) {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .mobile-close {
    display: none;
    @media (max-width: 700px) {
      display: block;
      background-color: $intense-blue;
      color: $white;
      border-radius: 20px;
      padding: 3px;
      font-size: 20px;
      font-weight: bold;
      left: 7px;
      top: 7px;
    }
  }
}


.list-enter,
.list-leave-to {
  transform: translateX(130%);
  background-color: transparent;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  .container {
    transform: translateX(10vw);
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
      -o-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  }
}
.list-enter,
.list-enter-active,
.list-leave-active {
  background-color: transparent;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  .container {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
}

</style>

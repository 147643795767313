<template>
  <div class="legal-information">
    <Form
      :form-configuration="formLegalInformation"
      :form-data="formData"
      :is-form-submission-successfull="isFormSubmissionSuccessfull"
      @submit-form="submitForm">
      <template v-slot:header>
        <img src="~@/assets/images/voodoo_banner.jpeg" />
      </template>
      <template v-slot:formTitle>
        <h1 class="title">Studio Information</h1>
        <p class="subtitle">* Mandatory</p>
      </template>
      <template v-slot:formSubmitted>
        Thank you for providing the requested informations. We are preparing your contract.<br /><br /><br />
        Best regards,<br />
        Voodoo Legal Team
      </template>
    </Form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axios from '@/tools/axios';
import Form from '@/components/Generic/Form.vue';
import formLegalInformation from '@/../json/formLegalInformation.json';

export default {
  name: 'LegalInformation',
  components: { Form },
  data() {
    return {
      formLegalInformation,
      formData: {
        deal_id: this.$route.query.deal_id,
        contact_name: this.$route.query.contact_name,
        email: this.$route.query.contact_email,
        contract_suffix: this.$route.query.contract_suffix,
      },
      isFormSubmissionSuccessfull: false,
    };
  },
  computed: {
  },
  methods: {
    async submitForm() {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/legal_forms`,
        method: 'post',
        data: JSON.stringify(this.formData),
        headers: {
          'content-type': 'application/json',
        },
      }).then(() => {
        this.isFormSubmissionSuccessfull = true;
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss">
.legal-information {
  .subtitle {
    color:red;
  }
}
</style>

<template>
  <div :class="$style.wrapper">
    <el-button
      type="primary"
      plain
      size="small"
      :disabled="disabled"
      data-test-id="gameSubmissionSDKCheck"
      @click="checkSDKIntegration"
    >
      <div :class="$style.testButton">
        <div :class="$style.buttonText">{{ title }}</div>
        <i
          v-if="loading"
          :class="$style.loadingIcon"
          class="el-icon-loading"
        ></i>
      </div>
    </el-button>

    <div v-if="errors === null" :class="[$style.result, $style.testOk]">
      <i class="el-icon-check" data-test-id="gameSubmissionSDKTestSuccessful"></i> Test successful!
    </div>
    <div v-if="errors" :class="[$style.result, $style.testNok]">
      <i class="el-icon-warning"></i>
      <span
        v-for="error in errors"
        :key="error"
        data-test-id="gameSubmissionSDKTestError"
        v-html="error"></span>
    </div>
  </div>
</template>

<script>
import axios from '@/tools/axios';

export default {
  name: 'TestSDK',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    testUrl: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      errors: undefined,
    };
  },
  methods: {
    checkSDKIntegration() {
      this.loading = true;
      this.errors = undefined;

      axios({
        url: this.testUrl,
        method: 'post',
        data: {},
      })
        .then(() => {
          this.errors = null;
        })
        .catch((error) => {
          if (error.response.data.detail) {
            this.errors = error.response.data.detail;
            return;
          }

          const errorList = this.errorList;
          this.errors = error.response.data.errorList.map((err) => {
            if (err.code) {
              if (err.extra?.operationId) {
                return errorList[err.code]
                  ? errorList[err.code].message.replace(
                    '{{ operationId }}',
                    err.extra.operationId,
                  )
                  : err.code;
              }
              return errorList[err.code]
                ? errorList[err.code].message
                : err.code;
            }
            return err.detail;
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  input {
    border-radius: 8px;
  }
}

.buttonText {
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
}

.loadingIcon {
  margin-left: 10px;
}

.result {
  color: $color-danger-plain-color;
  padding: $spacing * 2;
  font-size: 11px;
  font-weight: 600;
  border-radius: 3px;
  margin-top: $spacing;
  padding-left: 0px;
  line-height: 16px;
  padding-top: 5px;
}

.testNok {
  i {
    font-size: 12px;
    padding-right: 3px;
  }
}

.testOk {
  color: $tea-dark;
  i {
    font-weight: 700;
    font-size: 13px;
    padding-right: 3px;
  }
}
</style>

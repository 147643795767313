/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="games-list-pm">
    <div v-for="(game, ind) in gamesList" :key="game.id + '' + ind" class="game-card">
      <GameCard :game="game"></GameCard>
      <GameCardStats :game="game"></GameCardStats>
    </div>

    <InfiniteLoading slot="append" ref="InfiniteLoading" @infinite="getGames">
      <div slot="spinner" class="spinner">
        <i class="el-icon-loading loading-list" />
      </div>
      <div slot="no-more" class="no-more">No more games</div>
      <div slot="no-results" class="no-more">
        <div class="no-results">No more Game</div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
// import { cloneDeep } from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';
import GameCard from '@/components/PmPages/GameCard.vue';
import GameCardStats from '@/components/PmPages/GameCardStats.vue';

let timer;

export default {
  name: 'GamesList',
  components: {
    InfiniteLoading,
    GameCard,
    GameCardStats,
  },
  data() {
    return {};
  },
  computed: {
    currentView() {
      return this.$store.getters['views/currentView'];
    },
    gamesList() {
      return this.$store.getters['games/gamesList'];
    },
    // loading() {
    //   return this.$store.getters['games/loading'];
    // },
  },
  watch: {
    currentView(oldValue, newValue) {
      const paramsLast = {
        term: oldValue.content.term,
        filter: oldValue.content.filter,
        sort: oldValue.content.sort,
      };
      const paramsNew = {
        term: newValue.content.term,
        filter: newValue.content.filter,
        sort: newValue.content.sort,
      };
      if (JSON.stringify(paramsLast) !== JSON.stringify(paramsNew)) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$store.dispatch('games/resetGameList');
          this.$refs.InfiniteLoading.stateChanger.reset();
        }, 400);
      }
    },
  },
  mounted() {},
  methods: {
    async getGames($state) {
      await this.$store.dispatch('games/fetchList', this.currentView).then((response) => {
        if (response.data === null) {
          return;
        }
        if (response.data.length === 0 && this.gamesList.length > 0) {
          $state.complete();
        } else if (response.data.length > 0) {
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.games-list-pm {
  padding: 0 10px;
  .game-card {
    // border: 1px solid red;
    // height: 30px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: solid 1px var(--filter-border-bottom-color);
  }
  .spinner {
    padding: 20px;
  }
}
//
</style>

<template>
  <div>
    <CardPage>
      <template v-slot:left>
        <div class="user-main-infos">
          <i class="back-link el-icon-arrow-left" data-test-id="studioAdminBackButton" @click="gotoStudiosList()"></i>
          <div class="user-name">
            <span class="name" data-test-id="studioAdminStudioName">{{ studioInfos.studioName }}</span>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <ul class="menu-admin">
          <li :class="{ active: isSub('studio') }" data-test-id="studioAdminStudioTabButton" @click="setSub('studio')">Studio</li>
          <li :class="{ active: isSub('staff') }" data-test-id="studioAdminStaffTabButton" @click="setSub('staff')">Staff</li>
        </ul>
      </template>
      <template v-slot:page>
        <div class="page-content">
          <StudioAdminSubPage v-if="sub !== 'staff' && sub !== 'contract'" :infos="studioInfos"></StudioAdminSubPage>
          <StaffAdminSubPage v-if="sub === 'staff'" :id="id"></StaffAdminSubPage>
          <ContractAdminSubPage v-if="sub === 'contract'" :id="id"></ContractAdminSubPage>
        </div>
      </template>
    </CardPage>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import CardPage from '@/components/CardPage.vue';
import StudioAdminSubPage from '@/components/StudioAdminPage/StudioAdminSubPage.vue';
import StaffAdminSubPage from '@/components/StudioAdminPage/StaffAdminSubPage.vue';
import ContractAdminSubPage from '@/components/StudioAdminPage/ContractAdminSubPage.vue';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'StudioAdmin',
  components: {
    // SlidingPage,
    CardPage,
    StudioAdminSubPage,
    StaffAdminSubPage,
    ContractAdminSubPage,
  },
  props: {
    sub: {
      type: String,
      default: 'studio',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      gameIcon,
    };
  },
  computed: {
    studioId() {
      return this.id;
    },
    studioInfos() {
      return cloneDeep(this.$store.getters['adminstudio/studio']) || {};
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['adminstudio/loading_studio_infos']) || null;
    },
  },
  watch: {
    studioId(value) {
      this.$store.dispatch('adminstudio/getStudioInfos', value);
    },
  },
  mounted() {
    if (this.studioId !== null) {
      this.$store.dispatch('adminstudio/getStudioInfos', this.studioId);
    }
  },
  methods: {
    gotoStudiosList() {
      this.$router.push({
        name: 'studioAdmin',
      });
    },
    isSub(value) {
      const subList = ['staff', 'contract'];

      if (value === 'studio' && (!this.sub || !subList.includes(this.sub))) {
        return true;
      }
      if (this.sub === value) {
        return true;
      }
      return false;
    },
    setSub(value) {
      if (this.$route.params.sub !== value) {
        this.$router.push({
          name: 'studioAdmin',
          params: {
            id: `${this.id}`,
            sub: value,
          },
        });
      }
    },
    resetForm() {
      this.password = {
        current: '',
        new: '',
        confirm: '',
      };
    },
    saveProfile() {
      const user = {
        firstName: this.meForm.firstName,
        lastName: this.meForm.lastName,
        email: this.meForm.email,
        profile: this.meForm.profile,
      };
      this.$store.dispatch('users/updateUser', user);
    },
  },
};
</script>

<style lang="scss">
.back-link {
  color: #067bf0;
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
}
.menu-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // min-height: 42px;

  li {
    align-items: center;
    transition: all 0.5s;
    width: 100%;
    padding: 32px 15px;
    // height: 36px;
    // min-height: 42px;
    &::before {
      width: 0%;
      content: "";
      left: 50%;
      position: absolute;
      transition: all 0.5s;
    }
    &:hover {
      cursor: pointer;
      // transition: all 0.5s;
    }
    &.active {
      font-weight: bold;
    }
    &.active,
    &:hover {
      position: relative;
      &::before {
        content: "";
        background-color: #067bf0;
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        transition: all 0.5s;
      }
    }
  }
}
//
</style>

<template>
  <div>
    <div
      :class="$style.wrapper"
      data-test-id="MobileOptionButton"
      @click="open">
      <el-image :src="option" :class="$style.icon"></el-image>
    </div>
  </div>

</template>

<script>
import option from '@/assets/images/options.svg';
import tools from '@/tools/tools';

export default {
  name: 'MobileOptionButton',
  props: {
    studioId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      option,
    };
  },
  methods: {
    open(event) {
      tools.openUrl(this.$router, {
        name: 'studio',
        query: {
          mobile: 'studio-option',
          mobileTitle: 'Links',
        },
      }, event);
    },
  },
};
</script>

<style lang="scss" module>
  .wrapper {
    color: $orange-alert;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
  }

  .popinWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: red;
    z-index: 100;
  }

  .icon {
    height: 7px;
  }

  </style>

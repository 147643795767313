/* eslint-disable no-param-reassign */
import { cloneDeep, groupBy } from 'lodash';
import Vue from 'vue';
import ConfirmDeactivation from '@/components/Dialogs/ConfirmDeactivation.vue';

import StudioService from '@/services/studios.services';
import CookiesService from '@/tools/cookies';
import MessageError from '@/plugins/ErrorMessage';

const sorted = CookiesService.getSortPref() ? CookiesService.getSortPref().sort : 'cpi';
const order = CookiesService.getSortPref() ? CookiesService.getSortPref().order : 'asc';
const filterDef = CookiesService.getFilterPref() ? CookiesService.getFilterPref() : null;
const bestDef = CookiesService.getBestPref() !== null ? CookiesService.getBestPref() === '1' : true;
const Studio = {
  namespaced: true,
  state: {
    studioList: [],
    loading: false,
    studioInfos: {},
    studioEvents: null,
    eventsLoading: false,
    loadingInfos: false,
    loadingPm: false,
    loadingPm2: false,
    pageInfos: {
      limit: 20,
      offset: 0,
      sort: sorted,
      order,
      term: '',
      filter: filterDef,
      best: bestDef,
    },
  },
  getters: {
    studioInfos(state) {
      return state.studioInfos;
    },
    studioEvents(state) {
      return state.studioEvents;
    },
    studioList(state) {
      return state.studioList;
    },
    loading(state) {
      return state.loading;
    },
    eventsLoading(state) {
      return state.eventsLoadings;
    },
    pageInfo(state) {
      return state.pageInfos;
    },
    best(state) {
      return state.pageInfos.best;
    },
    loadingInfos(state) {
      return state.loadingInfos;
    },
    loadingPm(state) {
      return state.loadingPm;
    },
    loadingPm2(state) {
      return state.loadingPm2;
    },
  },
  mutations: {
    add_studio_list(state, listItem) {
      const list = state.studioList.concat(listItem);
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const seriesGrpBy = groupBy(element.videos, 'iteration_id');
        element.videoIteration = seriesGrpBy;
      }

      state.studioList = cloneDeep(list);
    },
    update_knowledgeSharing(state, value) {
      state.studioInfos = {
        ...state.studioInfos,
        knowledgeSharingPmStatus: value,
      };
    },
    set_infosLoading(state, load) {
      state.loadingInfos = load;
    },
    set_eventsLoading(state, loading) {
      state.eventsLoading = loading;
    },
    set_updatePmLoading(state, load) {
      state.loadingPm = load;
    },
    set_updatePm2Loading(state, load) {
      state.loadingPm2 = load;
    },
    set_studio_infos(state, infos) {
      state.studioInfos = infos;
    },
    set_studio_events(state, events) {
      state.studioEvents = events;
    },
    set_star(state, value) {
      state.studioInfos.isStarred = value;
    },
    set_state(state, value) {
      state.studioInfos.activityState = value;
    },
    set_deactivateReason(state, value) {
      state.studioInfos.deactivateReason = value;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    update_page_infos(state) {
      state.pageInfos.offset += 20;
    },
    reset_list(state) {
      state.studioList = [];
      state.pageInfos.offset = 0;
    },
    set_Best(state, best) {
      state.pageInfos.best = cloneDeep(best);
      CookiesService.setBestPref(best);
    },
    set_pm_infos(state, pmId) {
      const pmList = this.state.users.codification.publishingManagers;
      const newPm = pmList.find(pm => pm.value === pmId);
      state.studioInfos.pm = {
        firstName: newPm.label,
        lastName: '',
        id: newPm.value,
      };
    },
    set_pm_infos_v2(state, pm) {
      const p = {
        firstName: pm.firstName,
        lastName: pm.lastName,
        id: pm.id,
      };
      state.studioInfos.pm = p;
    },
    set_pm2_infos(state, pmId) {
      const pmList = this.state.users.codification.publishingManagers;
      const newPm = pmList.find(pm => pm.value === pmId);
      state.studioInfos.secondaryPm = {
        firstName: newPm.label,
        lastName: '',
        id: newPm.value,
      };
    },
  },
  actions: {
    resetStudiosList(context) {
      context.commit('reset_list');
    },
    async fetchList(context, view) {
      const params = {
        ...context.state.pageInfos,
        view,
      };
      return new Promise(async (resolve) => {
        context.commit('set_loading', true);
        const list = await StudioService.fetchStudios(params);
        context.commit('set_loading', false);
        if (list && list.data && list.data.length > 0) {
          context.commit('update_page_infos');
          context.commit('add_studio_list', list.data);
        }
        resolve(list);
      });
    },

    async getInfosStudioWithStudioId(context, id) {
      if (`${context.state.studioInfos.id}` !== `${id}`) {
        context.commit('set_infosLoading', true);
      }
      const result = await StudioService.getStudiosInfos(id);
      context.commit('set_infosLoading', false);
      if (result) {
        context.commit('set_studio_infos', result);
      } else {
        context.commit('set_studio_infos', {});
      }
    },

    async updateInfosStudioWithStudioId(context, data) {
      const result = await StudioService.updateStudioInfos(data);
      if (result?.data?.msg) {
        const errorList = context.rootState.users.codification.errorList;
        const errorMessage = errorList[result.data.errorList[0].code]?.message || errorList.GENERIC_ERROR?.message;

        MessageError.add({ detail: errorMessage });
      } else {
        context.dispatch('getInfosStudioWithStudioId', data.id);
      }

      return Promise.resolve(true);
    },

    async updateStudioPm2(context, { studioId, publishingManagerId }) {
      context.commit('set_updatePm2Loading', true);
      await StudioService.updateStudioPm2(studioId, publishingManagerId);
      context.commit('set_updatePm2Loading', false);
      context.commit('set_pm2_infos', publishingManagerId);
    },

    async updateStudioPm(context, { studioId, publishingManagerId }) {
      context.commit('set_updatePmLoading', true);
      await StudioService.updateStudioPm(studioId, publishingManagerId);
      context.commit('set_updatePmLoading', false);
      context.commit('set_pm_infos', publishingManagerId);
    },

    async coachStudio(context, id) {
      context.commit('set_updatePmLoading', true);
      const result = await StudioService.coachStudio(id);
      context.commit('set_updatePmLoading', false);
      if (!result.code) {
        context.commit('set_pm_infos_v2', result);
        context.commit('games/update_CoachedGameInlistGame', {
          id,
          p: result,
        }, { root: true });
      }
    },

    async setStudioActive(context, { id }) {
      const activityState = 'active';
      context.dispatch('setStudioActivityState', {
        studioId: id,
        activityState,
      });
    },

    async setStudioInactive(context, { id }) {
      const activityState = 'inactive';
      context.dispatch('setStudioActivityState', {
        studioId: id,
        activityState,
      });
    },

    async setStudioChurned(context, { id }) {
      const activityState = 'churned';
      let deactivateReason;
      try {
        deactivateReason = await context.dispatch('confirmDeactivation');
      } catch (e) {
        if (e) {
          console.error(e);
        }
        return;
      }

      await context
        .dispatch('studios/updateInfosStudioWithStudioId', {
          id,
          data: {
            teamStatusId: 1,
          },
        }, { root: true })
        .then(() => {
          context
            .dispatch('setStudioActivityState', {
              studioId: id,
              activityState,
              deactivateReason,
            });
        })
        .then(() => {
          context.commit('set_deactivateReason', deactivateReason);
        });

      // eslint-disable-next-line consistent-return
      return Promise.resolve(true);
    },

    async setStudioActivityState(context, params) {
      context.commit('set_updatePmLoading', true);
      const result = await StudioService.setActivityState(params);
      context.commit('set_updatePmLoading', false);
      if (result) {
        context.commit('set_state', params.activityState);
        context.dispatch('getInfosStudioWithStudioId', params.studioId);
      }
    },

    async starStudio(context, param) {
      context.commit('set_updatePmLoading', true);
      const result = await StudioService.starStudio(param);
      context.commit('set_updatePmLoading', false);
      if (result) {
        context.commit('set_star', param.value);
      }
    },

    async coachedToAtt(context, param) {
      context.commit('set_updatePmLoading', true);
      context.commit('games/set_updatePmLoading', true, { root: true });
      const result = await StudioService.coachedToAtt(param);
      context.commit('set_updatePmLoading', false);
      context.commit('games/set_updatePmLoading', false, { root: true });
      if (!result.code) {
        context.commit('set_pm_infos_v2', result);
        context.commit(
          'games/update_CoachedGameInlistGame',
          {
            id: param.studioId,
            p: result,
          },
          { root: true },
        );
        context.commit('games/set_pm_infos_v2', result, { root: true });
      }
    },

    async confirmDeactivation() {
      return new Promise((resolve, reject) => {
        const Dialog = Vue.extend(ConfirmDeactivation);
        const instance = new Dialog({
          propsData: {
            resolve,
            reject,
          },
        });
        instance.$mount();
        document.body.appendChild(instance.$el);
      });
    },
    async getStudioEvents(context, id) {
      if (`${context.state.studioInfos.id}` !== `${id}`) {
        context.commit('set_eventsLoading', true);
      }
      const result = await StudioService.getStudioEvents(id);
      context.commit('set_eventsLoading', false);
      if (result) {
        context.commit('set_studio_events', result);
      } else {
        context.commit('set_studio_events', {});
      }
    },
  },
};

export default Studio;

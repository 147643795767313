<template>
  <div :class="$style.wrapper">
    <div :class="$style.left">
      <SortGamesButtons/>
    </div>
    <div :class="$style.right">
      <div v-if="studio?.games" :class="$style.infos">
        {{ `${studio.games.length} game${studio.games.length > 1 ? 's':''} (${studio.nbHits} game${studio.nbHits > 1 ? 's':''} launched)`}}
      </div>
      <SearchTerm/>
    </div>
  </div>
</template>

<script>
import SearchTerm from '@/modules/GamesList/components/SearchTerm.vue';
import SortGamesButtons from '@/modules/GamesList/components/SortGamesButtons.vue';

export default {
  name: 'GamesListFilterbar',
  components: {
    SearchTerm,
    SortGamesButtons,
  },
  props: {
    studio: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
    z-index: 10;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    padding: 10px;
    gap: 5px;
    overflow: auto;
    position: sticky;
    top: 184px;
    background-color: $white;
    box-shadow: 0px 0px 4px 1px $white;
    @media (max-width: 700px) {
      top: 135px;
        grid-template-columns: 1fr;
    }
}
.left {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: auto;
}
.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.infos {
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
}
</style>

<template>
  <div>
    <el-select
      v-if="!getChoiceLoading"
      v-model="linkId"
      filterable
      style="margin:10px 0"
      data-test-id="gameSubmissionLinkFacebookCampaignSelect"
      @change="updateLinkId()">
      <el-option
        v-for="c in choices"
        :key="c.id"
        class="option"
        :label="c.label"
        :value="c.id"></el-option>
    </el-select>
    <span v-else>
      <i class="el-icon-loading"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'LinkFacebookCampaign',
  components: {},
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      getChoiceLoading: false,
      linkId: null,
      choices: [],
    };
  },
  computed: {},
  mounted() {
    this.getChoice();
  },
  methods: {
    updateLinkId() {
      this.$emit('change', this.linkId);
    },
    async getChoice() {
      this.getChoiceLoading = true;
      const param = {
        id: this.game.id,
        action: 'test_studio_campaign',
      };
      const rep = await this.$store.dispatch('games/getAvailableChoiceForASpecificAction', param);
      if (rep) {
        this.linkId = rep.current_id;
        this.$emit('change', this.linkId);
        this.choices = rep.choices.facebook;
      }
      this.getChoiceLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

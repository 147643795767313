<template>
  <div></div>
</template>

<script>
export default {
  name: 'AsyncAction',
  components: {},
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    gameId() {
      return this.game.id || null;
    },
    gameOperation() {
      return this.$store.getters['gameOperations/getGameOperation'](this.gameId);
    },
  },
  watch: {
    async game(value) {
      if (value.checkOperation === true) {
        const param = {
          game: value,
          value: false,
        };
        await this.$store.dispatch('ActionToDispatch/dispatchCheckOperationGame', param);
        await this.getActionStatus();
      }
    },
    gameOperation(value, old) {
      if (value && old && value.status === old.status) {
        return true;
      }

      return true;
    },
  },
  mounted() {
    this.getActionStatus(true);
  },

  methods: {
    async getActionStatus(mounted) {
      if (!this.game || !this.game.id) {
        return;
      }

      const status = await this.$store.dispatch('gameAction/getActionStatusFromGameid', this.game.id);

      if (status) {
        if (status.ongoing_operations && status.ongoing_operations.length > 0) {
          const param = {
            id: this.game.id,
            value: true,
          };
          this.$store.dispatch('ActionToDispatch/dispatchDisabledGame', param);
        }

        if (status.ongoing_operations && status.ongoing_operations.length === 0) {
          const param = {
            id: this.game.id,
            value: false,
          };
          if (!mounted) {
            await this.$store.dispatch('ActionToDispatch/refreshGame', param.id);
          }
          await this.$store.dispatch('ActionToDispatch/dispatchDisabledGame', param);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.async-action {
  position: absolute;
  display: flex;
  width: 100%;
}

.async-action-error {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  z-index: 4;
  .--item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #f56c6c;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>

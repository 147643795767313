import './assets/style/element-variables.scss';
import './assets/style/style.scss';
import './plugins/vue-cookies';
import './plugins/localStorage';
import './plugins/vue-touch';
import './plugins/anime-counter';
import './plugins/analytics';
import './plugins/tweez';
import './plugins/hightlight';
import './plugins/motion';
import '@/plugins/vee-validate';
import '@/plugins/media-queries';
import './plugins/element-ui';
import '@/plugins/bp';
import 'aos/dist/aos.css';
// hotjar is deactivated for now
// import initHotjar from '@/tools/hotjar';
// end hotjar is deactivated for now

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import AOS from 'aos';

import filters from './tools/filters';
import directive from './directive/click-outside';
import App from './App.vue';
import router from './router';
import store from './vuex';
import './registerServiceWorker';
import i18n from './i18n';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Object.keys(filters).forEach((id) => {
  Vue.filter(id, filters[id]);
});

Vue.config.productionTip = false;
// Vue.config.silent = true;
new Vue({
  created() {
    AOS.init();
    // hotjar is deactivated for now
    // initHotjar();
    // end hotjar is deactivated for now
  },

  directive,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

<template>
  <div id="redirect">
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import tool from '@/tools/tools';
// eslint-disable-next-line import/no-cycle
import UsersService from '@/services/users.services';

export default {
  components: {},

  data() {
    return {};
  },
  computed: {
    me() {
      return this.$store.getters['users/me'] || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    lastUrl() {
      return this.$store.getters['gameAction/lastUserUrl'];
    },
  },
  watch: {
    me(value) {
      if (value) {
        this.redirect();
      }
    },
    $route(value) {
      tool.redirectUser(this.$router, this.myPermissions, UsersService, value);
    },
  },
  mounted() {
    if (!this.me) {
      this.$store.dispatch('users/getUser');
    } else {
      this.redirect();
    }
  },
  methods: {
    redirect() {
      tool.redirectUser(this.$router, this.myPermissions, UsersService, this.$route);
    },
  },
};
</script>

<style lang="scss"></style>

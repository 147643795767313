import Vue from 'vue';
import Vuex from 'vuex';

import { genreTags } from '@/modules/GenreTags/store';
import Project from '@/modules/GameProject/store/project';
import searchProject from '@/modules/SearchProject/store';
import gamesList from '@/modules/GamesList/store';
import quotes from './modules/quotes';
import games from './modules/games';
import studios from './modules/studios';
import users from './modules/users';
import views from './modules/views';
import viewsStudio from './modules/viewsStudio';
import notifications from './modules/notifications';
import adminstudio from './modules/StudioAdmin';
import gameAction from './modules/gameAction';
import gameOperations from './modules/gameOperations';
import submitGame from './modules/submitGame';
import ActionToDispatch from './modules/ActionToDispatch';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    gamesList,
    quotes,
    games,
    studios,
    users,
    views,
    viewsStudio,
    notifications,
    adminstudio,
    gameAction,
    gameOperations,
    submitGame,
    ActionToDispatch,
    genreTags,
    Project,
    searchProject,
  },
  strict: debug,
});

<template>
  <div class="download-container" @click="goto(url)">
    <el-tooltip placement="top">
      <div slot="content">
        Download optimized video
      </div>
      <font-awesome-icon icon="arrow-to-bottom" class="icon-dowload" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'DownloadVideoButton',
  components: {},
  props: {
    url: {
      default: null,
      type: String,
    },
  },
  methods: {
    goto(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-container{
  display: flex;
  justify-content: right;
  align-items: center;
  .icon-dowload{
    width: 14px;
    height: 14px;
  }
  &:hover,
  &.active {
    cursor: pointer;
    span {
      font-weight: $font-normal;
    }
    .icon-dowload {
      color: $intense-blue;
    }
  }
}
</style>

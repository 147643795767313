<template>
  <div class="game-ids">
    <div v-if="!loadingGameIds">
      <div
        v-for="gameIdInformation in gameIdList"
        :key="gameIdInformation.id"
        class="copy-id-content"
        @click="copyToClip(gameIdInformation.value)">
        <span class="options" :style="{ 'background-image': `url(${gameIdInformation.imageUrl})` }"></span>
        <span>{{ gameIdInformation.label }}: </span>
        <span
        ><b class="copy">{{ gameIdInformation.value }} <i class="el-icon-copy-document"></i></b
        ></span>
      </div>
    </div>
    <div v-if="loadingGameIds" class="loading">
      <i class="el-icon-loading"></i>
    </div>
    <div class="footer">
      <el-button type="primary" @click="closeFeedback()">
        OK
      </el-button>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import gameActionService from '@/services/gameAction.services';
import adjustImage from '@/assets/images/adjust-icon.svg';
import appStore from '@/assets/images/app-store.svg';
import fbImage from '@/assets/images/icon-facebook.svg';
import playStore from '@/assets/images/playstore.svg';
import gaImage from '@/assets/images/ga.svg';

export default {
  name: 'GameIds',
  components: {},
  props: {
    gameObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingGameIds: true,
      game: null,
      adjustImage,
      appStore,
      fbImage,
      playStore,
      gaImage,
    };
  },
  computed: {
    gameIdList() {
      return this.getGameIdsConfiguration().filter(gameIdConfiguration => !!gameIdConfiguration.value);
    },
  },
  mounted() {
    this.retrieveGameIds();
  },
  methods: {
    getGameIdsConfiguration() {
      if (!this.game) {
        return [];
      }
      const storeLabel = this.game.platform === 'ios' ? 'AppStore ID' : 'PlayStore ID';
      const storeImageUrl = this.game.platform === 'ios' ? appStore : playStore;
      return [
        {
          id: 'facebook',
          label: 'Facebook ID',
          value: this.game.facebookAppId,
          imageUrl: fbImage,
        },
        {
          id: 'storeId',
          label: storeLabel,
          value: this.game.storeId,
          imageUrl: storeImageUrl,
        },
        {
          id: 'bundleId',
          label: 'Bundle ID',
          value: this.game.bundleId,
          imageUrl: storeImageUrl,
        },
        {
          id: 'GAId',
          label: 'GameAnalytics ID',
          value: this.game.gameAnalyticsId,
          imageUrl: gaImage,
        },
        {
          id: 'GAGameKey',
          label: 'GameAnalytics Game Key',
          value: this.game.gameAnalyticsGameKey,
          imageUrl: gaImage,
        },
        {
          id: 'GASecretKey',
          label: 'GameAnalytics Game Secret Key',
          value: this.game.gameAnalyticsSecretKey,
          imageUrl: gaImage,
        },
        {
          id: 'adjustAppToken',
          label: 'Adjust App Token',
          value: this.game.adjustAppToken,
          imageUrl: adjustImage,
        },

      ];
    },
    gameIdsAreLoaded() {
      return this.getGameIdsConfiguration().reduce((previousValue, gameIdConfiguration) => previousValue && gameIdConfiguration.value !== undefined, true);
    },
    async retrieveGameIds() {
      this.loadingGameIds = true;
      if (this.gameObject) {
        this.game = this.gameObject;
        if (!this.gameIdsAreLoaded()) {
          this.game = await gameActionService.getStatsByGameId({ id: this.gameObject.id }).then(rep => rep);
        }
        this.loadingGameIds = false;
      }
    },
    async copyToClip(text) {
      await navigator.clipboard.writeText(text);

      Message({
        message: 'Copied to clipboard!',
        type: 'success',
        duration: 2000,
        showClose: true,
      });
    },
    closeFeedback() {
      this.$emit('close-pop-in');
    },
  },
};
</script>

<style lang="scss">
.game-ids {
  .el-icon-copy-document {
    display: none;
  }

  .copy-id-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px;
    border-bottom: 1px solid $foggy-grey;
    font-size: 12px;
    line-height: 15px;
    &:first-child {
      border-top: 1px solid $foggy-grey;
    }
    &:hover {
      background-color: $foggy-blue;
      cursor: pointer;
      .el-icon-copy-document {
        display: inline;
      }
    }
    .copy {
      color: $intense-blue;
      margin-left: 5px;
    }
    span.options {
      background-size: contain;
      background-position: 0px center;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 10px;
  }
}
</style>

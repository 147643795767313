<template>
  <div class="videos-component">
    <div class="global-kpi">
      <div v-if="isIteration" class="kpi-list">
        <span class="name-section">Global ads performance</span>
        <div class="kpi">
          <div class="last">
            <span class="name">CPI:</span>
            <div class="container">
              <span v-if="iteration.cpi" class="value">${{ +iteration.cpi.toFixed(2) }}</span>
              <span v-else class="name">n/a</span>
            </div>
            <el-tooltip v-if="isCpiWarningVisible" placement="top" class="cpi-warning">
              <div slot="content">
                Warning: expect up to 3 days delay
                <br />
                of install data with the release of iOS 14.5
              </div>
              <span><i class="vd-svg-alert icon-alert"/></span>
            </el-tooltip>
          </div>
          <div class="best">
            <span class="name">Best:</span>
            <div class="container">
              <span
                v-if="bestVideoFromIteration && bestVideoFromIteration.data && bestVideoFromIteration.data.cpi"
                class="best"
                :class="{ 'no-data': bestVideoFromIteration }"
              >${{ bestVideoFromIteration.data.cpi.toFixed(2) }}</span
              ><span v-else class="name">
                n/a
              </span>
            </div>
          </div>
        </div>

        <div v-if="canViewCPNU && showAllKpis" class="kpi">
          <div class="last">
            <span class="name">CPNU:</span>
            <div class="container">
              <span v-if="iteration.cpnu" class="value">${{ +iteration.cpnu.toFixed(2) }}</span>
              <span v-else class="name">n/a</span>
            </div>
          </div>
        </div>

        <div v-if="showAllKpis" class="kpi">
          <div class="last">
            <span class="name">D1:</span>
            <div class="container">
              <span v-if="iteration.d1Ret" class="value">{{ (iteration.d1Ret * 100).toFixed(2) }}%</span>
              <span v-else class="name">n/a</span>
            </div>
          </div>
        </div>

        <div v-if="showAllKpis" class="kpi">
          <div class="last">
            <span class="name">D7:</span>
            <div class="container">
              <span v-if="iteration.d7Ret" class="value">{{ (iteration.d7Ret * 100).toFixed(2) }}%</span>
              <span v-else class="name">n/a</span>
            </div>
          </div>
        </div>

        <div class="kpi">
          <div class="last">
            <span class="name">CTR:</span>
            <div class="container">
              <span v-if="iteration.ctr" class="value">{{ (iteration.ctr * 100).toFixed(2) }}%</span>
              <span v-else class="name">n/a</span>
            </div>
          </div>
          <div class="best">
            <span class="name">Best:</span>
            <div class="container">
              <span
                v-if="bestVideoFromIteration && bestVideoFromIteration.data && bestVideoFromIteration.data.ctr"
                class="best"
                :class="{ 'no-data': bestVideoFromIteration }"
              >{{ (bestVideoFromIteration.data.ctr * 100).toFixed(2) }}%</span
              >
              <span v-else class="name">n/a</span>
            </div>
          </div>
        </div>

        <div v-if="showAllKpis" class="kpi minor">
          <span class="name">Session length</span>
          <span v-if="iteration.sessionLengthHumanReadable" class="value">{{ iteration.sessionLengthHumanReadable }}</span>
          <span v-else class="name">n/a</span>
        </div>

        <div v-if="showAllKpis" class="kpi minor">
          <span class="name">Daily playtime</span>
          <span v-if="iteration.playtimeHumanReadable" class="value">{{ iteration.playtimeHumanReadable }}</span>
          <span v-else class="name">n/a</span>
        </div>
      </div>
      <div v-else class="kpi-list nodata">
        <el-image
          class="preview"
          style="width: 100px;
          height: 100px;
          margin: 5px;
          border-radius: 5px;
          min-width: 30px;"
          :src="noDataImage"
          :fit="'cover'"
        >
          <div slot="error" class="image-slot">
            <span><img :src="gameIcon" :width="40"/></span>
          </div>
        </el-image>
        <span class="label">No data available</span>
      </div>
    </div>
    <hr class="mobile-separator" />
    <div v-if="orderedVideoBySpend" class="performing-ads">
      <div v-for="(item, index) in orderedVideoBySpend" :key="index" class="video-datas">
        <div class="name-section" :class="{'ad':item.adNetwork && showAdNetworkIconOnVideos}">
          <span >#{{ index + 1 }} performing ad</span>
          <span v-if="item.adNetwork && showAdNetworkIconOnVideos" class="adnetwork-icon" :class="'icon-' + item.adNetwork.toLowerCase()"></span>
          <DownloadVideoButton :url="game.isManagedByVoodoo ? `${item.video}?original` : `${item.video}`"></DownloadVideoButton>
        </div>
        <div class="video-item">
          <video
            :id="`videoIte-${item.id}`"
            :key="item.id"
            width="100%"
            type="video/mp4"
            muted="true"
            loop
            controls
            playsinline
            @mouseover="play(`videoIte-${item.id}`)"
            @mouseleave="pause(`videoIte-${item.id}`)"
          >
            <source :src="item.video" />
          </video>

          <div class="video-kpi">
            <div class="kpi">
              <span class="name">CPI</span>
              <span v-if="item.data && item.data.cpi" class="value">${{ +item.data.cpi.toFixed(2) }}</span>
              <span v-else class="value">n/a</span>
            </div>
            <div class="kpi">
              <span class="name">CTR</span>
              <span v-if="item.data && item.data.ctr" class="value">{{ (+item.data.ctr * 100).toFixed(2) }}%</span>
              <span v-else class="value">n/a</span>
            </div>
            <div class="kpi">
              <span class="name">Installs</span>
              <span v-if="item.data && item.data.fbInstalls" class="value">{{ item.data.fbInstalls || "n/a" }}</span>
              <span v-else class="value">n/a</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import noDataImage from '@/assets/images/illustration.svg';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import { DownloadVideoButton } from '@/modules/Game';

export default {
  name: 'IterationVideos',
  components: {
    DownloadVideoButton,
  },
  props: {
    tab: {
      type: String,
      default: null,
    },
    iteration: {
      type: Object,
      default: null,
    },
    game: {
      type: Object,
      default: null,
    },
    selectedAdNetwork: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      noDataImage,
      gameIcon,
    };
  },
  computed: {
    canViewCPNU() {
      return this.myPermissions.indexOf('view_cpnu') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    isKs() {
      return this.$route.params.sub === 'ks';
    },
    bestVideoFromIteration() {
      const bestVideoList = this.orderedVideoBySpend.filter(video => video.data && video.data.bestVideo);

      return bestVideoList.length > 0 ? bestVideoList[0] : null;
    },
    orderedVideoBySpend() {
      if (!this.iteration) {
        return [];
      }

      if (this.selectedAdNetwork !== null) {
        return orderBy(this.iteration.videos, [o => (o.data ? o.data.spend : '' || '')], ['desc']);
      }
      const adNetworks = Object.keys(this.iteration.adNetworks);
      const allVideos = adNetworks
        .map(currentAdNetwork => this.iteration.adNetworks[currentAdNetwork].videos.map(video => ({
          ...video,
          adNetwork: currentAdNetwork,
        })))
        .flat();

      return orderBy(allVideos, [o => (o.data ? o.data.spend : '' || '')], ['desc']);
    },
    showAllKpis() {
      return this.selectedAdNetwork === null || (this.game.platform === 'android' && !this.game.isCtr);
    },
    showAdNetworkIconOnVideos() {
      return this.iteration.adNetworks && Object.keys(this.iteration.adNetworks).length > 1;
    },
    isIteration() {
      return !!this.iteration;
    },
    isCpiWarningVisible() {
      return this.game.platform === 'ios' && !this.game.is_ctr && !this.isKs;
    },
  },
  methods: {
    mute(value) {
      const video = document.getElementById(value);
      video.muted = true;
    },
    unMute(value) {
      const video = document.getElementById(value);
      video.muted = false;
    },
    play(value) {
      const video = document.getElementById(value);
      video.play();
      // this.unMute(value);
    },
    pause(value) {
      const video = document.getElementById(value);
      video.pause();
      this.mute(value);
    },
  },
};
</script>

<style lang="scss">
.videos-component {
  display: grid;
  grid-template-columns: 200px 1fr;
  .global-kpi {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;

    .kpi-list {
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      background-color: #ffffff;
      padding: 17px 20px;
      min-width: 210px;
      .name-section {
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
      }
      &.nodata {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .label {
          padding: 20px 5px;
          color: #82bdf7;
          font-size: 15px;
          font-weight: bold;
        }
      }
      .kpi {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        .last,
        .best {
          display: flex;
        }
        .last {
          margin-bottom: 5px;
          font-weight: bold;
          color: #232323;
        }
        .best {
          color: #58c783;
          font-size: 12px;
          .name {
            font-size: 12px;
          }
        }
        &:last-child {
          padding-bottom: 0;
        }
        .container {
          display: flex;
          align-items: center;
          font-size: 14px;
          justify-content: space-between;
        }
        .name {
          margin-right: 5px;
          font-size: 14px;
          &.no-data {
            font-size: 12px;
            color: #adadad;
          }
        }
        .value {
          font-weight: bold;
        }

        &.minor {
          font-size: 12px;
          .name {
            font-size: 10px;
            display: flex;
            color: #adadad;
            font-weight: normal;
            margin-bottom: 5px;
          }
        }

        .value {
        }

        .cpi-warning {
          margin-left: 5px;
        }
      }
    }
  }
  hr.mobile-separator {
    display: none;
  }
  .performing-ads {
    display: flex;
    overflow: auto;
    // margin-left: 10px;
    padding: 17px 0px;
    .name-section {
      font-size: 12px;
      color: $grey;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: auto 16px;
      &.ad{
        grid-template-columns: auto auto 16px;
      }

    }
    .video-datas {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;
      border-radius: 3px;
      margin-right: 30px;
      position: relative;

      .adnetwork-icon {
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px;
        margin: 0px 9px;
        top: 0;
        right: 0;
        &.icon-facebook {
          background-image: url("~@/assets/images/icon-facebook.svg");
        }
        &.icon-snapchat {
          background-image: url("~@/assets/images/icon-snapchat.svg");
        }
      }
      .video-item {
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        overflow: hidden;
        video {
          width: 180px;
          height: 254px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          background-color: #232323;
        }
        .video-kpi {
          // border-radius: 3px;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          background-color: #82bdf7;
          display: flex;
          justify-content: space-between;
          text-align: left;
          padding: 10px;
          .kpi {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            text-align: left;
            color: #ffffff;
            font-size: 12px;
            span {
              padding: 2px;
            }
            .value {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    .kpi-list {
      margin: 0 auto;
    }
    hr.mobile-separator {
      display: block;
    }
    .performing-ads {
      display: grid;
      grid-template-columns: 1fr;
      .video-datas {
        .name-section {
          font-weight: bold;
        }
        margin: 10px auto;
      }
    }
  }
}
</style>

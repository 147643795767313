<template>
  <div class="menu-container">
    <div class="menu">
      <ul>
        <li :class="{ active: sub === 'home' }" @click="setSub('home')">
          <div class="item hide" data-test-id="menuNavigationHome">
            <font-awesome-icon icon="home-alt" class="icon" />
            <span>{{ $t("common.home") }}</span>
          </div>
        </li>
        <li :class="{ active: sub === 'mygames' }" data-test-id="menuNavigationMyGames" @click="setSub('mygames')">
          <div class="item hide">
            <font-awesome-icon icon="layer-group" class="icon" />
            <span>{{ $t("common.myGames") }}</span>
          </div>
        </li>
        <li
          v-if="showKS"
          :class="{ active: sub === 'ks' }"
          data-test-id="menuNavigationKnowledgeSharing"
          @click="setSub('ks')">
          <div class="item hide">
            <font-awesome-icon icon="lightbulb" class="icon" />
            <span>{{ $t("views.StudioBoard.ks") }}</span>
          </div>
        </li>
        <li
          :class="{ active: sub === 'help-center' }"
          data-test-id="menuNavigationHelpCenter"
          class="no-click"
          @click="goto('https://voodoo.zendesk.com/hc/en-us/categories/360001432800-HELP-CENTER')">
          <div class="item hide">
            <font-awesome-icon icon="question-circle" class="icon" />
            <span>{{ $t('views.StudioBoard.helpCenter') }}</span>
          </div>
        </li>
        <li
          data-test-id="menuNavigationAcademy"
          :class="{ active: sub === 'elearnings' }"
          class="no-click"
          @click="goto(eLearningAcademyInfos.link)">
          <div class="item hide">
            <font-awesome-icon icon="graduation-cap" class="icon" />
            <span>{{ eLearningAcademyInfos.label }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Menu',
  props: {
    showKS: {
      type: Boolean,
      default: false,
    },
    sub: {
      type: String,
      default: 'home',
    },
    eLearningAcademyInfos: {
      type: Object,
      default() {
        return {
          link: 'https://voodoo.zendesk.com/hc/en-us/categories/360001432760-E-LEARNING',
          label: this.$t('views.StudioBoard.eLearning'),
        };
      },
    },
  },
  methods: {
    setSub(sub) {
      this.$emit('updateSub', sub);
    },
    goto(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>

.menu-container {
  width: 150px;
  @media screen and (max-width: 1330px) {
    width: 64px;
  }
  @media screen and (max-width: 1234px) {
    width: 31px;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.menu {
  position: fixed;
  z-index: 10;
  max-height: calc(100vh - 140px);
  max-width: 250px;
  text-align: left;
  ul.changelog {
    text-align: left;
    font-size: 13px;

    color: $intense-blue;
  }
  li {
    @media screen and (max-width: 1330px) {
      justify-content: center;
    }
    display: flex;
    padding: 15px 0;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    align-items: flex-start;
    height: 60px;
    align-items: center;

    > ul {
      margin-left: 30px;
      margin-top: 10px;
      li {
        padding: 5px 0 5px 0;
        &.active {
          span {
            color: $intense-blue;
          }
        }
        span {
          font-size: 11px;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      position: relative;
    }
    span {
      margin-left: 10px;
      color: $slate;
      font: "OpenSans-Regular";
      font-size: 14px;
      line-height: 18px;
    }
    &:hover{
        @media screen and (min-width: 1330px) {
          cursor: pointer;
        }

    }
    &.active {
      .icon {
        color: $intense-blue;
      }
    }
  }
  .icon-menu {
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-position: 0px center;
    background-repeat: no-repeat;
  }
}

.hide {
  width: 100%;
  display: flex;

  @media screen and (max-width: 1330px) {
    span {
      display: none;
    }

    &:hover {
      padding: 8px 8px 8px 0px;
      border-radius: 8px;
      background-color: $cloudy-blue;
      position: absolute;
      left: -5px;
      z-index: 100;
      cursor: pointer;
      span {
        font-weight: 700;
      }
      .icon {
        font-size: 25px !important;
        width: 25px;
        padding-left: 5px;
        color: $intense-blue !important;

      }
      span {
        color: $intense-blue !important;
        display: inline-block;
        font-weight: 700;
      }
    }
  }
}

.icon {
  font-size: 20px;
  color: $grey-bis;
  width: 20px;
}
</style>

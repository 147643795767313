<template>
  <div class="studio-service" data-test-id="studioAdminWrapper">
    <div v-if="me" class="main" :class="{ 'main-board': page === 'board' }">
      <UserAdmin v-if="page === 'me'"></UserAdmin>
      <StudioAdmin v-if="page === 'admin' && id" :id="id" :sub="sub"></StudioAdmin>
      <StudioBoard
        v-if="page === 'board'"
        :sub="sub"
        :filter="filter"
        :type="type"></StudioBoard>
      <StudioAdminList v-if="page === 'admin' && !id"></StudioAdminList>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import staticParam from '@/../json/static.json';
import UserAdmin from '@/views/UserAdmin.vue';
import StudioAdmin from '@/views/StudioAdmin.vue';
import StudioBoard from '@/views/StudioBoard.vue';
import StudioAdminList from '@/views/StudioAdminList.vue';

export default {
  name: 'StudioService',
  components: {
    UserAdmin,
    StudioAdmin,
    StudioBoard,
    StudioAdminList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    page: {
      type: String,
      default: 'admin',
    },
    sub: {
      type: String,
      default: 'studio',
    },
    filter: {
      type: String,
      default: 'in_progress',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      staticParam,
      canSave: false,
    };
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.studio-service {
  .main {
    padding: 30px;
    padding: 0;
    background-color: $foggy-blue;
    border-radius: 10px;
    justify-content: center;
  }
  .main.main-board {
    margin: 0;
    padding: 0 30px;
    @media screen and (max-width: 700px) {
      padding: 0 0px;
    }
  }
  .page-content {
    padding: 30px;
  }
}
</style>

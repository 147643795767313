import { Notification } from 'element-ui';
import axios from '@/tools/axios';

export default class UsersService {
  static async fetchUser() {
    return axios
      .get(`${process.env.VUE_APP_PUBLIC_API_URL}/v1/me`)
      .then((r) => {
        const isHijack = r.headers['is-hijacked'];
        if (r.data) {
          const re = {
            ...r,
            isHijack,
          };
          return re;
        }
        return null;
      })
      .catch(() => null);
  }

  static async fetchCodification() {
    return axios
      .get(`${process.env.VUE_APP_PUBLIC_API_URL}/codifications/`)
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async fetchError() {
    return axios
      .get(`${process.env.VUE_APP_PUBLIC_API_URL}/error_messages/`)
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateUser(user) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/me`,
      data: user,
      method: 'put',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updatePasswordUser(password) {
    const pass = {
      currentPassword: password.current,
      newPassword: password.new,
    };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/me/password`,
      data: pass,
      method: 'put',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })

      .catch(error => (error.response ? error.response.data : null));
  }

  static async fetchStatusList() {
    return axios
      .get(`${process.env.VUE_APP_PUBLIC_API_URL}/v1/games/status`)
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getRedirectLink(platform) {
    return axios
      .get(`${process.env.VUE_APP_PUBLIC_API_URL}/auth/redirectLink?platform=${platform}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async login(p) {
    const queryParams = p.includeRedirectUrlFor ? `?includeRedirectUrlFor=${p.includeRedirectUrlFor}` : '';
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/auth/login${queryParams}`,
      data: {
        username: p.email,
        password: p.password,
      },
      method: 'post',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async logout() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/auth/currentsession`,
      data: {},
      method: 'delete',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async refresh(refreshToken) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/auth/refresh`,
      data: {
        refreshToken,
      },
      method: 'post',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async hijackUserId(id, callback = null) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/hijack/${id}`,
      method: 'post',
    })
      .then((r) => {
        if (callback) {
          callback();
        }

        window.location = '/board';
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch((e) => {
        Notification.error({
          duration: 3500,
          title: 'Error',
          message: 'Impersonating user did not succeed: this happens when the user you are trying to impersonate does not exist, is deactivated or you do not have the permission to impersonate him. Please double-check the user status.',
          position: 'bottom-left',
        });

        setTimeout(() => {
          window.location = '/board';
        }, 3500);

        throw e;
      });
  }

  static async releaseHijack() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/hijack/release`,
      method: 'post',
    })
      .then((r) => {
        window.location = '/';

        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async sendPasswordResetEmail(username) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/auth/resetpwdinit`,
      method: 'post',
      data: { username },
    }).catch(e => Promise.reject(e.response.data));
  }

  static async resetPassword(username, resetToken, newPwd1, newPwd2) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/auth/resetpwd`,
      method: 'post',
      data: {
        username,
        resetToken,
        newPwd1,
        newPwd2,
      },
    }).catch(e => Promise.reject(e.response.data));
  }
}

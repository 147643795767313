/* eslint-disable prefer-destructuring */
import { cloneDeep } from 'lodash';
import axiosCancel from 'axios-cancel';
import axios from '@/tools/axios';

axiosCancel(axios, {
  debug: false, // default
});
export default class GamesService {
  static async errorHandler(err) {
    if (err.response) {
      if (err.response.data.detail) {
        return { error: err.response.data.detail };
      }
      if (err.response.data.message) {
        return { error: err.response.data.message };
      }
      if (err.response.data.errorList && err.response.data.errorList[0]) {
        return { error: err.response.data.errorList[0] };
      }
    }
    return { error: 'GENERIC_ERROR' };
  }

  static async postNewGame(gameForm) {
    const requestId = 'postnewGame';

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/api/games/`,
        method: 'post',
        data: gameForm,
        requestId,
      })
        .then(
          (r) => {
            if (typeof r !== 'undefined' && !r.detail) {
              return { detail: r };
            }
            return true;
          },
          // return { data: [] };
          // eslint-disable-next-line consistent-return
        )
        // eslint-disable-next-line consistent-return
        .catch(error => ({ detail: error }))
    ); // .catch(() => ({ data: [] }));
  }

  static async uploadVideo(item) {
    // eslint-disable-next-line no-param-reassign
    try {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${item.gameId}/video`,
        method: 'post',
        data: item.form,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      });
    } catch (err) {
      if (err.response) {
        if (err.response.data.detail) {
          return { error: err.response.data.detail };
        }
        if (err.response.data.message) {
          return { error: err.response.data.message };
        }
      }
      return { error: '' };
    }
    return {};
  }

  static async getRequestAppAccess(param) {
    const requestId = `getSpecificActionById${Math.random()}`;

    try {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${param.id}/${param.action}`,
        method: 'post',
        requestId,
      });
    } catch (err) {
      return this.errorHandler(err);
    }

    return {};
  }

  static async cropVideo(item) {
    const param = item.cropping;

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/api/games/${item.gameId}/crop_video/`,
        method: 'post',
        data: param,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return null;
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch((err) => {
          if (err.response) {
            return { error: err.response.data.detail };
          }
          return err;
        })
    ); // .catch(() => ({ data: [] }));
  }

  static async setNextStepGame(item) {
    const requestId = 'setNextStep';

    const data = {
      substatus: item.status,
    };
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/game/${item.game.id}`,
        method: 'put',
        data,
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async updateGameByProperties(item) {
    const requestId = 'updategamebyproperty';
    const cp = cloneDeep(item);
    delete cp.id;
    const data = {
      ...cp,
    };
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${item.id}`,
        method: 'put',
        data,
        requestId,
      })
        .then(async (r) => {
          if (typeof r !== 'undefined') {
            const game = await this.getStatsByGameId({ id: item.id });
            return game;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch((error) => {
          if (error && error.response && error.response.data) {
            if (error.response.data.errorList) {
              return { detail: error.response.data };
            }
            if (error.response.data.name) {
              return { detail: error.response.data.name };
            }
          }
          return { detail: error };
        })
    ); // .catch(() => ({ data: [] }));
  }

  static async getMyGames(params, status, abortController) {
    const requestId = `getMyGames${status}`;
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/my/games`,
        method: 'post',
        data: params,
        requestId,
        signal: abortController?.signal || null,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(e => console.log(e))
    ); // .catch(() => ({ data: [] }));
  }

  static async getGamesByTerm(params) {
    // const requestId = 'getGamesByTerm';

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/my/games`,
        method: 'post',
        data: params,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async getActionByGameId(id) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${id}`,
        method: 'get',
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async setRequestTestingByGameId(item) {
    const requestId = `requesttesting${item.game.id}`;
    const data = {};
    if (item.extraParams && item.extraParams.removeNetworks) {
      data.remove_networks = item.extraParams.removeNetworks;
    }
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${item.game.id}/request_testing`,
        method: 'post',
        data,
        requestId,
      })
        .then(async (r) => {
          if (typeof r !== 'undefined') {
            const game = await this.getStatsByGameId(r.data);
            return game;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(error => ({
          detail: error,
        }))
    ); // .catch(() => ({ data: [] }));
  }

  static async getVideoByGameId(id) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/my/game/${id}`,
        method: 'get',
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async getVideoKSByGameId(id) {
    const requestId = `getVideo${id}`;

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ks/game/${id}`,
        method: 'get',
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async deleteVideoGameId(item) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${item.gameId}/remove_video`,
        method: 'post',
        data: {
          video_id: item.id,
        },
      })
        .then(() => this.getStatsByGameId({ id: item.gameId }))
        .catch(() => null)
    );
  }

  static async simpleAction(item) {
    const requestId = `simpleAction${item.game.id}/${item.action}`;

    try {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${item.game.id}/${item.action}`,
        method: 'post',
        requestId,
      });
      return this.getStatsByGameId({ id: item.game.id });
    } catch (error) {
      return this.errorHandler(error);
    }
  }

  static async updateIconGame(item) {
    try {
      await axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${item.id}/update_icon`,
        method: 'post',
        data: item.value,
      });
      return this.getStatsByGameId(item);
    } catch (error) {
      return this.errorHandler(error);
    }
  }

  static async setActionById(param) {
    const data = {};

    if (param.action.post && param.action.post.field.length > 0) {
      for (let i = 0; i < param.action.post.field.length; i += 1) {
        const element = param.action.post.field[i];
        if (element.type === 'Object') {
          data[element.param] = {
            [element.deepParam[0]]: element.value,
          };
        } else {
          data[element.param] = element.value;
        }
      }
    }
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${param.id}/${param.action.id}`,
      method: 'post',
      data,
    })
      .then(async (r) => {
        if (typeof r !== 'undefined') {
          const game = await this.getStatsByGameId(r.data);
          return game;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      .catch(error => ({
        detail: error,
      })); // .catch(() => ({ data: [] }));

    return call;
  }

  static async getStatsByGameId(item) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${item.id}`,
        method: 'get',
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return null;
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    );
  }

  static async getStatsKSByGameId(item) {
    const requestId = `stats${item.id}`;

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ks/game/${item.id}`,
        method: 'get',
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return null;
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    );
  }

  static async getActionStatusFromGameid(id) {
    const requestId = `getAsyncActionStatus${id}`;
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/operations?gameIds=${id}`,
        method: 'get',
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return null;
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    );
  }

  static async getFeedbackByGameId(item) {
    const requestId = `getFeedbackByGameId${item.game.id}`;
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/my/game/${item.game.id}`,
        method: 'get',
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data.feedbacks;
          }
          return null;
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    );
  }

  static updateSubstatus(item) {
    const game = cloneDeep(item);
    if (game.substatus !== null) {
      game.substatus = 'playstore_submission';
    }
    return game;
  }

  static getKnowledgeSharingGames(params) {
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ks/games`,
        method: 'post',
        data: params,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(
          () => null,
          // eslint-disable-next-line consistent-return
        )
    ); // .catch(() => ({ data: [] }));
  }

  static getNbGamebyStatus(params) {
    const requestId = 'getNbGamebyStatus';
    const reqParams = {
      term: params.term,
    };

    if (typeof params.query === 'object' && params.query.children) {
      reqParams.query = params.query;
    } else {
      reqParams.query = {
        logicalOperator: 'all',
        children: [],
      };

      reqParams.query.children.push({
        type: 'query-builder-rule',
        query: {
          rule: 'isCtr',
          operator: 'equals',
          operand: 'Is CTR',
          value: params.isCtr,
        },
      });
    }

    reqParams.statuses = params.statuses;
    reqParams.myGamesOnly = !!params.myGamesOnly;

    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/my/games/status/count`,
        method: 'post',
        data: reqParams,
        requestId,
      })
        .then((r) => {
          if (typeof r !== 'undefined') {
            return r.data;
          }
          return { data: [] };
          // eslint-disable-next-line consistent-return
        })
        // eslint-disable-next-line consistent-return
        .catch(() => null)
    ); // .catch(() => ({ data: [] }));
  }

  static async createNewGame(item) {
    const requestId = 'createnewGame';
    const el = item;
    return (
      axios({
        url: `${process.env.VUE_APP_PUBLIC_API_URL}/api/games/`,
        method: 'post',
        data: item,
        requestId,
      })
        .then(
          async (r) => {
            el.id = r.data.id;
            await this.updateGameByProperties(el);
            const rep = this.getStatsByGameId({ id: r.data.id });
            return rep;
          },
          // return { data: [] };
          // eslint-disable-next-line consistent-return
        )
        // eslint-disable-next-line consistent-return
        .catch(error => ({ detail: error }))
    ); // .catch(() => ({ data: [] }));
  }

  static async getAdjustToken(item) {
    const requestId = 'getAdjustToken';

    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/game/${item.id}/adjustToken`,
      method: 'get',
      requestId,
    })
      .then(r => r)
      .catch(error => ({ detail: error }));
  }

  static async pineKSGame(item) {
    const requestId = 'pinKSGame';

    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ks/game/${item.gameId}/pin`,
      method: 'put',
      data: {
        isPinned: item.value,
      },
      requestId,
    })
      .then(r => r)
      .catch(error => ({ detail: error }));
  }

  static getCampaignCreationStatus(gameId) {
    const requestId = 'getCampaignCreationStatus';

    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${gameId}/campaignCreationStatus`,
      method: 'get',
      requestId,
    })
      .then(r => r.data)
      .catch(error => ({ detail: error }));
  }
}

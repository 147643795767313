<template>
  <div>
    <div :class="$style.title">
      Select a test or a project to reuse the Bundle ID
    </div>
    <div :class="$style.description">
      You can reuse an existing Bundle ID if you already submitted this game.
      Every test with the same Bundle ID will be automatically linked into a
      project.
    </div>
    <SelectProjectsList
      :game="game"
      :class="[$style.select, (bundleId || storeId )? $style.selectSpacing:'']"
      :disabled="!canEditGame"
      @projectInfos="handleProjectInfos"
    ></SelectProjectsList>
    <div v-if="bundleId || storeId" :class="$style.previewIds">
      <div v-show="storeId && !displayStoreId && game.platform !== 'android'" :class="$style.textIds">
        <span>Appstore ID:</span>{{ storeId }}
      </div>
      <div v-if="bundleId" :class="$style.textIds">
        <span>Bundle ID:</span>{{ bundleId }}
      </div>
    </div>
    <AppStoreInput
      v-if="displayStoreId"
      v-model="storeId"
      :game="game"
      :class="$style.appStoreId"
      @isValid="setAppStoreIdValidity"
    />
  </div>
</template>

<script>
import SelectProjectsList from '@/modules/GameProject/components/SelectProjectsList.vue';
import AppStoreInput from './AppStoreInput.vue';

export default {
  name: 'ReuseStoreIds',
  components: {
    SelectProjectsList,
    AppStoreInput,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bundleId: null,
      storeId: null,
      projectId: null,
      displayStoreId: false,
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    canEditGame() {
      return this.myPermissions.includes('request_game_testing') || this.gamePermissions.includes('request_game_testing');
    },
  },
  watch: {
    storeId(value) {
      this.$emit('cpAppStoreId', value);
    },
  },
  methods: {
    handleProjectInfos(projectInfos) {
      this.bundleId = projectInfos.bundleId;
      this.storeId = projectInfos.storeId;
      this.projectId = projectInfos.projectId;

      const noStoreId = this.game.platform === 'ios'
        && (!this.storeId || this.storeId === this.bundleId)
        && this.projectId;

      this.displayStoreId = noStoreId;

      this.$emit('getProject', projectInfos.projectId);
      this.$emit('changeBundleIdValue', projectInfos.bundleId);
      this.$emit(
        'changeAppstoreValue',
        noStoreId ? null : projectInfos.storeId,
      );
      this.$emit('setAppStoreIdValidity', !noStoreId);
      this.$emit('setBundleIdValidity', true);
    },
    setAppStoreIdValidity(value) {
      this.$emit('setAppStoreIdValidity', value);
    },
  },
};
</script>

<style lang="scss" module>
.title {
  color: $slate;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: $spacing / 2;
}

.description {
  color: $grey;
  font-size: 12px;
}

.previewIds {
  background-color: $foggy-blue;
  padding: $spacing * 3;
  margin-bottom: $spacing * 3;
  border-radius: 4px;
}

.textIds {
  color: $slate;
  span {
    font-weight: 600;
    margin-right: $spacing;
  }
}

.textIds:nth-of-type(1) {
  margin-bottom: $spacing * 3;
}

.select {
  margin: $spacing * 3 0;
}

.selectSpacing {
  margin-bottom: $spacing;
}

.spacingBottom {
  margin-bottom: $spacing * 3;
}

.appStoreId {
  margin: $spacing * 3 0;
}
</style>

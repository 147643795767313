
<template>
  <div :class="$style.wrapper">
    <span :class="$style.title">Sort by:</span>
    <div :class="$style.buttonsContainer">
      <span v-for="item in filters" :key="item.name">
        <el-button
          v-if="!item.requiredPermission || myPermissions.includes(item.requiredPermission)"
          :key="item.prop"
          type="primary"
          :plain="item.prop === sort ? false : true"
          size="mini"
          data-test-id="gamesListFilterButton"
          :class="{ selected: item.prop === sort.sort ? true : false }"
          @click="sort = item">
          {{ item.name }}
        </el-button>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SortGamesButtons',
  data() {
    return {
      filters: [
        {
          name: 'More recent',
          prop: 'lastIterationDate',
          order: 'desc',
        },
        {
          name: 'Best CPI',
          prop: 'bestCpi',
          order: 'asc',
        },
        {
          name: 'Best CPNU',
          prop: 'bestCpnu',
          order: 'asc',
          requiredPermission: 'view_cpnu',
        },
        {
          name: 'Best D1',
          prop: 'bestD1',
          order: 'desc',
        },
        {
          name: 'Best D7',
          prop: 'bestD7',
          order: 'desc',
        },
        {
          name: 'Best Cumulative D3 Playtime',
          prop: 'bestD3Playtime',
          order: 'desc',
        },
        {
          name: 'Best D3 ROAS',
          prop: 'score',
          order: 'desc',
          requiredPermission: 'view_proto_score',
        },
      ],
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    sort: {
      get() {
        return this.$store.getters['gamesList/params'];
      },
      set(value) {
        this.$store.commit('gamesList/setSort', {
          prop: value.prop,
          order: value.order,
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.el-button {
    padding: 5px 7px;
    font-size: 11px;
    border-radius: 5px;
    &.selected {
    background: #067bf0;
    border-color: #067bf0;
    color: #ffffff;
    }
}
.el-button,
.el-button + .el-button {
    margin: 0 5px 5px 0;
}
</style>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;

  .title {
    font-size: 12px;
    @media (max-width: 850px) {
      display: none;
    }
  }
  .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 700px) {
      flex-wrap: nowrap;
    }
  }
}
</style>

<template>
  <div v-if="studioForm && studio" class="studio-form" :class="$style.studioForm">
    <div :class="[$style.settingsHeader, $style.section]">
      <div data-test-id="studioPageDrawerTitleText">
        Studio Settings
      </div>
      <font-awesome-icon :class="$style.closeIcon" icon="times" @click="$emit('input', false)" />
    </div>
    <div :class="[$style.formWrapper, $style.section]">
      <div :class="$style.switchWrapper">
        <el-switch
          :disabled="!canChangeStudioAdminInfo || isDeactivated"
          style="font-size: 12px;"
          :value="studioForm.studioIsCoaching"
          active-text="Activate studio coaching"
          :class="$style.coachingSwitch"
          data-test-id="studioPageCoachingSwitch"
          @change="(e) => {
            setTeamStatuses(e)
            studioForm.studioIsCoaching = e;
            // studioForm.pm = e ? publishingManagers[1] : null;
            studioForm.teamStatus = teamStatuses[0];
          }"
        ></el-switch>
      </div>

      <div :class="$style.field">
        <span>
          Primary PM
        </span>
        <el-select
          :disabled="!studioForm.studioIsCoaching || !myPermissions.includes('change_publishing_manager') || isDeactivated"
          filterable
          :value="studioForm.pm"
          value-key="value"
          data-test-id="studioPagePmSelect"
          @change="(e) => studioForm.pm = e">
          <el-option
            v-for="item in publishingManagers"
            :key="item.value"
            :value-key="'value'"
            :label="item.label"
            :value="item">
          </el-option>
        </el-select>
      </div>

      <div :class="$style.field">
        <span>
          Secondary PM
        </span>
        <el-select
          filterable
          :disabled="!studioForm.studioIsCoaching  || !myPermissions.includes('change_publishing_manager') || isDeactivated"
          :value="studioForm.pm2"
          value-key="value"
          data-test-id="studioPageSecondaryPmSelect"
          @change="(e) => studioForm.pm2 = e">
          <el-option
            v-for="item in publishingManagers2"
            :key="item.value"
            :value-key="'value'"
            :label="item.label"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div :class="$style.field">
        <span>
          Team Status:
        </span>
        <el-select
          :disabled="!canChangeStudioAdminInfo || isDeactivated"
          filterable
          :value="studioForm.teamStatus"
          value-key="id"
          data-test-id="studioPageTeamStatusSelect"
          @change="(e) => studioForm.teamStatus = e">
          <el-option
            v-for="item in teamStatuses"
            :key="item.id"
            :value-key="'id'"
            :label="item.label"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div :class="$style.field">
        <span v-if="false">
          Market Segment:
        </span>
        <!-- <el-select
          v-if="false"
          :value="studioForm.segment ? `${studioForm.segment.label}` : ''">
          <el-option
            v-for="item in segmentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
      <div :class="$style.switchWrapper">
        <div :class="$style.ks">
          <el-switch
            :disabled="!canChangeStudioAdminInfo || isDeactivated"
            style="font-size: 12px;"
            :value="!!studioForm.knowledgeSharingPmStatus"
            active-text="Activate Knowledge sharing"
            :class="$style.ksSwitch"
            data-test-id="studioPageKnowledgeSharingSwitch"
            @change="(e) => studioForm.knowledgeSharingPmStatus = e"
          ></el-switch>
          <el-tooltip placement="top">
            <div slot="content">{{ tooltipInfo }}</div>
            <i class="icon vd-svg-kpismallicon" />
          </el-tooltip>
        </div>
      </div>
      <div :class="$style.switchWrapper" @click="handleChurned">
        <div :class="$style.ks">
          <el-switch
            :disabled="!canChangeStudioAdminInfo"
            style="font-size: 12px;"
            :value="isChurned"
            :class="$style.ksSwitch"
            active-text="Flag as churned"
            data-test-id="studioPageChurnSwitch"
          ></el-switch>
          <el-tooltip placement="top">
            <div slot="content">{{ tooltipChurned }}</div>
            <i class="icon vd-svg-kpismallicon" />
          </el-tooltip>
        </div>
      </div>
      <div :class="$style.access" >
        <el-button @click="$emit('input', false)">Cancel</el-button>
        <el-tooltip
          placement="bottom"
          :value="displayChangeTooltip"
          :hide-after="1000"
          manual
        >
          <div slot="content">Unsaved changes. You need to validate to save your changes.</div>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="!formIsChanged"
            data-test-id="studioPageValidateButton"
            @click="updateStudio()">
            Validate
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <div :class="[$style.staffWrapper, $style.section]">
      <div :class="$style.historyIconWrapper">
        <font-awesome-icon :class="$style.historyIcon" icon="envelope" />
      </div>
      <div>
        <div :class="$style.creationLabel">From: {{creation.value}}</div>
        <div data-test-id="StudioViewHistoryButton" :class="$style.adminButton" @click="viewHistory">
          View Studio History
        </div>
      </div>
    </div>
    <div :class="[$style.staffWrapper, $style.section]">
      <div :class="$style.staffIconWrapper">
        <font-awesome-icon :class="$style.staffIcon" icon="envelope" />
      </div>
      <div>
        <div v-if="studio.mainContactEmail" :class="$style.emailWrapper">
          Studio email:
          <CopyToClipboardNew :class="$style.email" :text="studio.mainContactEmail" />
        </div>
        <div data-test-id="StudioPageAdminPageButton" :class="$style.adminButton" @click="gotoAdmin">
          Studio admin and staff permissions
        </div>
      </div>
    </div>
    <div :class="[$style.switchWrapper, $style.section]" @click="handleActivation">
      <el-switch
        :disabled="!canChangeStudioAdminInfo"
        style="font-size: 12px;"
        :value="isDeactivated"
        active-text="Deactivate platform access"
        data-test-id="studioPageDeactivationSwitch"
        inactive-color="#FFF2F2"
        active-color="#DE5353"
        :class="$style.deactivateSwitch"
      ></el-switch>
    </div>
    <el-dialog
      :append-to-body="true"
      title="Flag studio as churned"
      :visible.sync="displayChurnedModal"
      class="studioDialog"
      width="500px"
      @close="displayChurnedModal = false"
    >
      <ChurnReason @close="handleChurnClose" @change="handleChurnChange"/>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      title="Deactivate platform access"
      :visible.sync="displayDeactivationModal"
      class="studioDialog"
      width="500px"
      @close="handleDeactivationClose"
    >
      <DeactivationConfirmation @close="handleDeactivationClose" @deactivate="deactivate"/>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';
// eslint-disable-next-line import/extensions
import CopyToClipboardNew from '@/components/CopyToClipboard/CopyToClipboardNew.vue';
import ChurnReason from './Churn/ChurnReason.vue';
import DeactivationConfirmation from './Deactivation/DeactivationConfirmation.vue';

export default {
  name: 'StudioForm',
  components: {
    CopyToClipboardNew,
    ChurnReason,
    DeactivationConfirmation,

  },
  mixins: ['change_studio_admin_info'],
  props: {
    studio: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      source: '',
      ready: false,
      studioForm: null,
      oldForm: null,
      tooltipInfo: 'Enabling Knowledge Sharing will send an invitation e-mail to all members of this studio. They will have to opt-in to start sharing their prototypes and be able to view the ones within the Knowledge Sharing space. Note: a Publishing Ops or Publishing Engineering member must also grant a special permission to the studio’s members from Django -> please contact us before enabling it.',
      tooltipChurned: 'Flagging as churned only has a reporting purpose. After flagging, you can find your entry within the studio history window.',
      teamStatuses: [],
      displayChangeTooltip: false,
      isStudioDeactived: true,
      displayChurnedModal: false,
      isChurned: !!this.studio.isChurned,
      displayDeactivationModal: false,
    };
  },
  computed: {
    events() {
      return this.$store.getters['studios/studioEvents'] || {};
    },
    creation() {
      return this.events?.creation;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    loading() {
      return this.$store.getters['studios/loadingInfos'];
    },
    publishingManagers() {
      if (this.codification) {
        return cloneDeep(this.codification.publishingManagers)
          .sort((a, b) => (a.label < b.label ? -1 : 1))
          .filter(el => el.value !== null);
      }
      return [];
    },
    publishingManagers2() {
      const pm = cloneDeep(this.publishingManagers);
      pm.unshift({
        value: null,
        label: 'None',
      });
      return pm;
    },
    formIsChanged() {
      return JSON.stringify(this.studioForm) !== JSON.stringify(this.oldForm);
    },
    canChangeStudioAdminInfo() {
      return this.$store.getters['users/myPermissions'].includes('change_studio_admin_info') && !this.deactivatedDate;
    },
    isDeactivated() {
      return !!this.studio.deactivateDate;
    },
  },
  watch: {
    studio: {
      deep: true,
      handler() {
        this.init();
      },
    },
    codification: {
      deep: true,
      handler() {
        this.init();
      },
    },
    formIsChanged(isChanged) {
      this.displayChangeTooltip = isChanged;
    },
    isDeactivated: {
      handler(value) {
        this.isStudioDeactived = value;
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    next(false);
  },
  mounted() {
    this.init();
  },
  methods: {
    viewHistory() {
      this.$emit('open-history');
    },
    gotoAdmin(event) {
      tools.openUrl(this.$router, {
        name: 'studioAdmin',
        params: {
          id: this.studio.id,
        },
      }, event, '_blank');
    },
    init() {
      if (!this.studio?.id || !this.codification || !this.codification?.teamStatuses.att) {
        return;
      }

      const isCoaching = this.codification.teamStatuses.coaching.map(e => e.id).includes(this.studio.teamStatus.id);

      this.setTeamStatuses(isCoaching);

      this.studioForm = {
        id: this.studio.id,
        pm: this.studio.pm ? {
          label: `${this.studio.pm.firstName} ${this.studio.pm.lastName}`,
          value: this.studio.pm.id,
        } : null,
        pm2: this.studio.secondaryPm ? {
          label: `${this.studio.secondaryPm.firstName} ${this.studio.secondaryPm.lastName}`,
          value: this.studio.secondaryPm.id,
        } : null,
        knowledgeSharingPmStatus: this.studio.knowledgeSharingPmStatus,
        studioIsCoaching: isCoaching,
        teamStatus: this.teamStatuses.find(
          el => el.id === this.studio.teamStatusId,
        ),
        isChurned: this.studio.isChurned,
        isRegrettedChurn: this.studio.isRegrettedChurn,
        churnReason: this.studio.churnReason,
      };
      this.oldForm = cloneDeep(this.studioForm);
    },
    updateStudio() {
      const churnedData = {
        isChurned: this.studioForm.isChurned,
        isRegrettedChurn: this.studioForm.isChurned ? this.studioForm.isRegrettedChurn : undefined,
        churnReason: this.studioForm.isChurned ? this.studioForm.churnReason : undefined,
      };
      let data = {
        teamStatusId: this.studioForm?.teamStatus?.id || null,
        knowledgeSharingPmStatus: this.studioForm?.knowledgeSharingPmStatus,
        ...churnedData,
      };

      if ((this.studioForm.studioIsCoaching === this.oldForm.studioIsCoaching && this.oldForm.studioIsCoaching)
      || (this.oldForm.studioIsCoaching !== this.studioForm.studioIsCoaching && this.studioForm.studioIsCoaching === true)) {
        data.publishingManagerId = this.studioForm?.pm?.value || null;
        data.secondaryPublishingManagerId = this.studioForm?.pm2?.value || null;
      }
      if (this.isDeactivated) {
        data = churnedData;
      }
      this.$store.dispatch('studios/updateInfosStudioWithStudioId', {
        id: this.studio.id,
        data,
      });
    },
    handleChurnChange(data) {
      this.studioForm.isChurned = data.isChurned;
      this.studioForm.isRegrettedChurn = data.isRegrettedChurn;
      this.studioForm.churnReason = data.churnReason;
    },
    setState(state) {
      this.$store.dispatch('studios/setStudioActivityState', {
        studioId: this.studio.id,
        activityState: state,
      });
    },
    setTeamStatuses(value) {
      const statuses = cloneDeep(this.codification.teamStatuses[value ? 'coaching' : 'att']).sort((a, b) => (a.label < b.label ? -1 : 1));

      this.teamStatuses = statuses;
    },
    async handleActivation() {
      if (!this.canChangeStudioAdminInfo) return;
      const deactivated = this.isDeactivated;
      if (!deactivated) {
        this.displayDeactivationModal = true;
      } else {
        this.activate();
      }
    },
    activate() {
      this.studioForm.isActive = true;
      this.updateStudio();
      this.$store.dispatch('studios/updateInfosStudioWithStudioId', {
        id: this.studio.id,
        data: {
          isActive: true,
        },
      });
    },
    async deactivate() {
      this.$store.dispatch('studios/updateInfosStudioWithStudioId', {
        id: this.studio.id,
        data: {
          isActive: false,
        },
      });
    },
    handleChurnClose(isConfirmed) {
      this.displayChurnedModal = false;
      if (isConfirmed) {
        this.isChurned = true;
      }
    },
    async handleChurned() {
      if (!this.canChangeStudioAdminInfo) return;
      const churned = this.isChurned;
      if (!churned) {
        this.displayChurnedModal = true;
      } else {
        this.handleChurnChange({
          isChurned: false,
          churnReason: this.studio.churnReason,
          isRegrettedChurn: false,
        });
        this.isChurned = false;
      }
    },
    handleDeactivationClose() {
      this.displayDeactivationModal = false;
    },
  },
};
</script>
<style lang="scss">
.studio-form {
    .el-switch__label * {
        font-size: 12px !important;
    }
    .el-button {
        font-size: 12px;
        height: 35px;
        padding: 10px 10px;
        &.is-loading {
          width: auto;
        }
        &.buttton-access.is-disabled{
          color: #ccc !important;
          background-color: $white !important;
        }
    }
    .el-select {
        width: 100%;
    }
    .ks {
        margin-top: 30px;
        display: flex;
        align-items: center;
    }
    .vd-svg-kpismallicon {
        &:hover {
          cursor: pointer;
        }
    }
}
</style>

<style lang="scss" module>
.section {
  padding: $spacing * 4;
  border-bottom: 1px solid $foggy-grey;
  text-align: left;
}

.formWrapper {
  width: 100%;
  text-align: left;

    display: block;
}

.ks {
  display: flex;
  align-items: center;
}

.switchWrapper {
  display: flex;
  width: 100%;
  margin-bottom: $spacing * 3;

  span {
    font-size: 12px;
    font-weight: 600;
    color: $slate;
  }
}

.deactivateSwitch span{
  color: $red-alert;
}

.ksSwitch {
  margin-right: $spacing;
}

.access {
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.studioForm {
  width: 100%;
  gap: 24px;
  border-bottom: 1px solid $foggy-grey;
  background-color: $white;
}

.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $spacing * 3;

  span {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.settingsHeader {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.closeIcon {
  color: $grey;
  font-size: 20px;
  transition: color 300ms;
  cursor: pointer;
}

.closeIcon:hover {
  color: $intense-blue;
}

.adminButton {
  color: $intense-blue;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.staffWrapper {
  display: flex;
  align-items: center;
}

.staffIconWrapper, .historyIconWrapper {
  margin-right: $spacing * 2;
  background-color: #7fe2a5;
  padding: $spacing;
  border-radius: 5px;
}

.staffIcon, .historyIcon {
  font-size: 20px;
  color: $white;
}

.historyIconWrapper {
  background-color: $yellow-alert;
}

.historyIcon {
  font-size: 20px;
  color: $white;
}

.creationLabel {
  font-size: 12px;
  margin-bottom: $spacing;
  font-weight: 600;
}

.emailWrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: $spacing;
}

.email {
  margin-left: $spacing / 2;
}
</style>

import Vue from 'vue';
import { w3cwebsocket as WebSocketClient } from 'websocket';
import CookiesService from '@/tools/cookies';

/**
 * @typedef {object} MessageGameOperation
 * @property {number} gameId
 * @property {string} status
 * @property {string} content
 * @property {string} operationType
 * @property {number} operationId
 */

/**
 * @typedef {object} MessageNotification
 * @property {number} id
 * @property {number} recipientId
 * @property {string} type
 * @property {string} content
 * @property {?string} relatedContentId
 * @property {?number} readAt - timestamp
 * @property {number} createdAt - timestamp
 */

export const connection = Vue.observable({
  active: false,
  client: null,
  nbRetries: 0,
  lastTimeAlive: null,
});

const maxConnectionRetries = 5;
let lastConnectionAttemptAt = null;
const keepAliveDelay = 60 * 1000;
const reconnectDelay = 5 * 1000;

/**
 *
 * @param {MessageGameOperation} message
 * @param store
 */
function handleGameOperation(message, store) {
  // console.warn('ws: game operation.');
  store.dispatch('gameOperations/setForGame', {
    gameId: message.gameId,
    operation: message,
  });
  store.dispatch('ActionToDispatch/dispatchCheckOperationGame', {
    game: { id: message.gameId },
    value: true,
  });
}

/**
 *
 * @param {MessageNotification} message
 * @param store
 */
function handleNotification(message, store) {
  store.dispatch('notifications/addNotifList', message);
}

/**
 * Keeps the connection alive by pinging the server
 */
function keepAlive() {
  if (connection.active) {
    // todo: update the server's code so that it returns an answer
    connection.client.send('');
    connection.lastTimeAlive = new Date();
    setTimeout(keepAlive, keepAliveDelay);
  }
}

/**
 * Tries to reconnect to the websocket after it's been closed
 * @param {string|number} userId
 * @param store - Vuex store instance, used by handlers
 * @return {Promise<void>}
 */
async function retryConnect(userId, store) {
  const defaultMsg = 'Connection to live updates closed unexpectedly';
  const now = new Date();

  // don't want to spam the reconnection
  if (now - lastConnectionAttemptAt < reconnectDelay) {
    await new Promise(resolve => setTimeout(resolve, reconnectDelay));
  }

  connection.nbRetries += 1;

  if (connection.nbRetries <= maxConnectionRetries) {
    console.warn(`${defaultMsg}, trying to reconnect... (attempt ${connection.nbRetries}/${maxConnectionRetries})`);
    connect(userId, store);
  } else {
    console.warn(`${defaultMsg}. Reload the page to reconnect.`);
    connection.active = false;
  }
}

/**
 * @param {string|number} userId
 * @param store - Vuex store instance, used by handlers
 */
function connect(userId, store) {
  lastConnectionAttemptAt = new Date();
  const authToken = CookiesService.getToken();
  connection.client = new WebSocketClient(`${process.env.VUE_APP_WEBSOCKET_URL}/?userId=${userId}&token=${authToken}`);

  connection.client.onerror = () => {
    connection.active = false;
  };

  connection.client.onopen = () => {
    connection.active = true;
    connection.nbRetries = 0;
    connection.lastTimeAlive = new Date();
    keepAlive();
  };

  connection.client.onclose = async () => {
    await retryConnect(userId, store);
  };

  connection.client.onmessage = (e) => {
    if (typeof e.data === 'string') {
      const fullMessage = JSON.parse(e.data);

      switch (fullMessage.type.toLowerCase()) {
      case 'notification':
        handleNotification(fullMessage.message, store);
        break;
      case 'game-operation':
        handleGameOperation(fullMessage.message, store);
        break;
      default:
        console.error('WebsocketService:WS:onmessage failed: unsupported message', fullMessage);
      }
    } else {
      console.error('WebsocketService:WS:onmessage failed: unsupported data', e.data);
    }
  };
}

export default {
  connect,
};

<template>
  <div :class="$style.wrapper">
    <div v-for="(game, ind) in games" :key="game.id + '' + ind" >
      <div v-if="game.id" :class="$style.gameCard">
        <GameCard  :game="game"></GameCard>
        <GameCardStats :game="game"></GameCardStats>
      </div>
    </div>

    <InfiniteLoading
      slot="append"
      ref="InfiniteLoading"
      @infinite="getGames">
      <div slot="spinner" :class="$style.spinner">
        <i class="el-icon-loading loading-list" />
      </div>
      <div slot="no-more" class="no-more">No more games</div>
      <div slot="no-results" class="no-more">
        <div class="no-results">No more games</div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import GameCard from '@/components/PmPages/GameCard.vue';
import GameCardStats from '@/components/PmPages/GameCardStats.vue';

export default {
  name: 'GamesList',
  components: {
    GameCard,
    GameCardStats,
    InfiniteLoading,
  },
  props: {
    studioId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      displayInfinite: false,
    };
  },
  computed: {
    games() {
      return this.$store.getters['gamesList/games'];
    },
    params() {
      return this.$store.getters['gamesList/params'];
    },
    loading() {
      return this.$store.getters['gamesList/loading'];
    },
    term() {
      return this.$store.getters['gamesList/params'].term || '';
    },
  },
  watch: {
    params: {
      handler(oldValue, newValue) {
        if (oldValue !== newValue) {
          if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this.resetSearch();
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.displayInfinite = true;
  },
  methods: {
    // eslint-disable-next-line func-names
    resetSearch() {
      this.$store.commit('gamesList/resetList');
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset();
      }
    },
    async getGames($state) {
      const gamesResponse = await this.$store.dispatch('gamesList/fetchGames', this.studioId);
      if (gamesResponse === null) {
        return;
      }
      if (gamesResponse.length === 0 && this.games.length > 0) {
        $state.complete();
      } else if (gamesResponse.length > 0) {
        $state.loaded();
      } else {
        $state.loaded();
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
    width: 100%;
    padding: 0px 10px 10px 10px;
    .gameCard {
        margin-bottom: 10px;
        border-radius: 10px;
        border: solid 1px var(--filter-border-bottom-color);
    }
    .spinner {
        padding: 20px;
    }
}
</style>

import bpMatch from '@/tools/mq';

const plugin = {
  install(Vue) {
    Vue.mixin({
      computed: {
        $bp() {
          return rules => bpMatch(this.$mq, rules);
        },
      },
    });
  },
};

export default plugin;

<template>
  <div class="facebook-step-page">
    <div class="ga-step">
      <div class="step-content">
        <h2>Create a new Ad Set on Facebook</h2>
        <ul>
          <li>
            In order to create a new ad set, the easiest way is to duplicate an existing ad set.
            <br />
            You can do that simply by going on the <b>Ad Sets</b> section and clicking on the <b>Duplicate</b>. <br />
            <br />
            <el-image
              class="preview"
              style="
                        width: 100%;
                        padding: 20px;
                        margin: 5px;
                        border-radius: 5px;
                        max-width: 700px;"
              :src="iterateFacebook"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
          </li>
          <li>
            Once done, you can go to the <b>Ads section</b>, select a video, and click on <b>Edit</b>.
            <br />
          </li>

          <li>
            Then you can replace or delete the video you’d like to change in this new ad set for your next iteration. <br />Repeat
            the above steps as many times as necessary for the other videos you’d like to change or add.
            <br />
            <b>Note</b>: Don’t forget to publish your new Ad Set!
          </li>
          <li>
            You’re done! Your new ad set is ready with your new videos for your next iteration.
            <br />
            There is no need to change anything in the Publidash in order to iterate as long as you’ve properly linked your
            Facebook campaign to your game in the dashboard.
            <br />
            <span
              class="danger"
            ><b>Note</b>: it can take up to one hour to display your new iteration data on the dashboard after you successfully
              created your ad set in Facebook.</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import iterateFacebook from '@/assets/images/iterateTuto.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'TutoIterateStep',
  components: {},
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iterateFacebook,
      gameIcon,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.facebook-step-page {
  padding: 10px;
  ul {
    list-style-type: decimal;
  }
  .ga-step {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px 30px 30px;
    // align-items: center;
    .preview.button {
      // width: auto;
    }
    .step-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;

      text-align: left;
      h2 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      span,
      li {
        line-height: 30px;
        font-size: 13px;
      }
      a {
        color: #067bf0;
      }
      .danger {
        color: #de5353;
      }
    }

    .preview {
      // padding: 30px;
    }
  }
}
</style>

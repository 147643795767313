<template>
  <div :class="$style.container">
    <h2 id="title-testBuilding">
      {{title}}
    </h2>
    <div :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <p :class="$style.text">Play a few levels to check the TinySauce integration works correctly</p>
      <TestSdk
        id="gameanalyticsSDk"
        title="Test GameAnalytics SDK"
        :test-url="getGameAnalyticsSdkTestUrl()"
        :error-list="codification.errorList"
        data-test-id="gameSubmissionTestSDKGameAnalyticsWrapper"
      />
      <TestSdk
        id="AdjustSDk"
        title="Test Adjust SDK"
        :test-url="getAdjustSdkTestUrl()"
        :error-list="codification.errorList"
        :class="$style.adjustBtn"
        data-test-id="gameSubmissionTestSDKAdjustWrapper"
      />
      <ImportantNotice
        v-if="warningContent && warningContent.show"
        :class="$style.notice"
        :content="warningContent && warningContent.text"/>
      <br>
      <el-button
        v-if="!steps[index].isValid"
        id="validateTestBuilding"
        type="primary"
        size="small"
        data-test-id="gameSubmissionGameBuildingValidate"
        :disabled="steps[index].isValid"
        @click="validateStep(index)">
        <span>Validate</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import tools from '@/tools/tools';
import ImportantNotice from '@/components/Generic/ImportantNotice/ImportantNotice.vue';
import TestSdk from './TestSdk.vue';

export default {
  components: {
    ImportantNotice,
    TestSdk,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'no name',
    },
    current: {
      type: Number,
      default: 0,
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
    warning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isConfirmed: false,
      isIntersecting: false,
      testGA: false,
      testAdjust: false,
      isvisible: false,
    };
  },
  computed: {
    noticeContent() {
      return 'Content to fetch from Contentful';
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    isAccessible() {
      return this.steps[this.index - 1].isValid;
    },
    warningContent() {
      if (!this.warning) {
        return {
          show: false,
          text: '',
        };
      }
      return {
        text: this.warning.gameBuildingAndTest,
        show: this.warning.gamebuildingAndTestWarningIsVisible,
      };
    },
  },
  // watch: {
  //   isValid(value) {
  //     if (value) {
  //       this.$emit('isValid', value);
  //     }
  //   },
  // },
  mounted() {
    tools.attachEvent(document.getElementById('container-scrollable'), 'scroll', this.update);
    tools.attachEvent(window, 'resize', this.update);
  },
  methods: {
    update() {
      const titleElement = document.getElementById('title-testBuilding');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    getGameAnalyticsSdkTestUrl() {
      // this.testGA = true;
      // this.checkIsValid();
      return `${process.env.VUE_APP_PUBLIC_API_URL}/game/${this.game.id}/check_ga_integration`;
    },
    getAdjustSdkTestUrl() {
      // this.testAdjust = true;
      // this.checkIsValid();
      return `${process.env.VUE_APP_PUBLIC_API_URL}/game/${this.game.id}/check_adjust_integration`;
    },
    validateStep() {
      // if (this.testGA && this.testAdjust && this.isAccessible) {
      this.$emit('isValid', true);
      // }
    },
  },
};
</script>

<style lang="scss" module>

.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.10);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  position: relative;
   .layer{
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.adjustBtn {
    margin-top: $spacing;
}

.text {
    font-weight: 600;
    margin-bottom: $spacing;
}

.notice {
  margin-top : $spacing * 3;
}
</style>

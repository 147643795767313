<template>
  <div class="game-pending-approval-page-on-page">
    <div class="pending-approval-step">
      <div class="step-content">
        <div v-if="game" class="middle-content pending-approval">
          <div v-if="canApprove(game) && canAcceptGame" class="self-approve">
            <div class="left">
              <GameIcon small :game="game" class="game-icon"></GameIcon>
              <b>You can approve your game by yourself to start testing campaign</b>
            </div>
            <div class="action">
              <i v-if="game.loadingAction" class="el-icon-loading"></i>
              <el-button
                type="success"
                :disabled="!canAcceptGame"
                data-test-id="pendingApprovalApproveButton"
                @click="action(game, 'accept_testing')">
                Approve
              </el-button>

              <el-button
                type="danger"
                :disabled="!canDeclineGame"
                data-test-id="pendingApprovalDeclineButton"
                @click="action(game, 'decline_testing')">
                Decline
              </el-button>

              <el-tooltip placement="top-start" :visible-arrow="false" popper-class="tooltip-game">
                <div slot="content">
                  You may want to decline your testing
                  <br />request in certain circumstances <br />(for example, if you want to upload <br />a new or updated video to your testing <br />campaign prior to launching
                  it)
                </div>
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
          </div>
          <div v-else class="self-approve">
            <div class="left">
              <GameIcon small :game="game" class="game-icon"></GameIcon>
              <div class="left">
                <el-image
                  v-if="!unavailableReason(game) || unavailableReason(game)==='PERMISSION_DENIED_ACCEPT_TESTING'"
                  class="preview"
                  :src="roket"
                  :fit="'cover'"
                >
                </el-image>
                <el-image
                  v-else
                  class="preview"
                  :src="warningIcon"
                  :fit="'cover'"
                >
                </el-image>
                <div v-if="unavailableReason(game)==='PERMISSION_DENIED_ACCEPT_TESTING'" data-test-id="myGamesPendingMessageText" class="self-approve">
                  <p>{{ displayError(unavailableReason(game)) }}</p>
                </div>
                <div v-else-if="unavailableReason(game)" data-test-id="myGamesPendingMessageText" class="error">
                  <p>{{ displayError(unavailableReason(game)) }}</p>
                </div>
                <p v-else data-test-id="myGamesPendingMessageText" class="self-approve">
                  <b>You have completed your testing request successfully!</b>
                  <br />Once we accept your request (generally under 1 or 2 business days),
                  your marketing campaign will be created and its results will appear in the “In testing” section.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="game.loadingAction && !game" class="loading-approval">
          <i class="el-icon-loading loading-list"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import roket from '@/assets/images/roket.png';
import warningIcon from '@/assets/images/warning.svg';

export default {
  name: 'GamePendingApprovalOnPage',
  components: { GameIcon },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      actionList,
      roket,
      warningIcon,
    };
  },
  computed: {
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    canDeclineGame() {
      return this.gamePermissions.indexOf('request_game_testing') > -1
      || this.myPermissions.indexOf('request_game_testing') > -1;
    },
    canAcceptGame() {
      return this.gamePermissions.indexOf('accept_game_testing') > -1
      || this.myPermissions.indexOf('accept_game_testing') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
  },
  mounted() {
    const container = document.querySelector('.main-content');
    container.scrollIntoView(true);
  },
  methods: {
    action(game, actionName) {
      const action = this.actionList.filter(el => el.id === actionName)[0];
      const param = {
        id: cloneDeep(game.id),
        action,
      };
      this.$store.dispatch('ActionToDispatch/dispatchAction', param);
    },
    unavailableReason(game) {
      if (game.actions) {
        const gamesUnavailable = game.actions.filter(el => el.unavailableReason);
        return gamesUnavailable.find(el => el.id === 'accept_testing') ? gamesUnavailable.find(el => el.id === 'accept_testing').unavailableReason : false;
      }
      return false;
    },
    displayError(code) {
      return this.codification.errorList[code] ? this.codification.errorList[code].message : code;
    },
    canApprove(game) {
      if (game.actions) {
        try {
          return game.actions.filter(el => !el.unavailableReason).find(el => el.id === 'accept_testing');
        } catch (e) {
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.game-pending-approval-page-on-page {
  .pending-approval-step {
    display: flex;
    justify-content: space-between;
    .middle-content {
      display: flex;
      width: 100%;
      // justify-content: space-between;
    }
    .step-content {
      padding: 10px;
      width: 100%;
      display: flex;
      // justify-content: flex-start;
      // flex-direction: column;
      align-items: center;

      // text-align: left;
      .self-approve {
        display: flex;
        width: 100%;
        .left {
          align-items: center;
          display: flex;
        }
        .error{
          color: $orange-alert;
        }
      }

      .preview {
        width: 30px;
        height: 30px;
        margin: 5px;
        border-radius: 5px;
        min-width: 30px;
      }

      .image-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      h2 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      // span,
      // li {
      //   margin-left: 10px;
      //   line-height: 30px;
      //   font-size: 13px;
      // }
      a {
        color: #067bf0;
      }
      .game-icon {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div>
    <el-dialog
      custom-class="link-project-modal"
      :append-to-body="true"
      :title="'Link to a project'"
      :visible="isVisible"
      width="480px"
      data-test-id="myGamesLinkProjectModal"
      @close="close"
    >
      <div class="formAction">
        <p class="label">Select a test or a project</p>
        <p class="text">
          Select an existing test or a project to link the test.
          <br/>You will be able to unlink them later.
        </p>
        <br/>
        <SelectProjectsList
          :game="game"
          @change="changeProject"
        ></SelectProjectsList>

      </div>
      <div v-if="warning" class="warning-alert">
        <b>Are you sure this new test belongs to an existing project?</b><br/>
        All tests within a same project usually have the same Bundle ID.
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :disabled="!newProject"
          :loading="loading"
          data-test-id="myGamesLinkProjectValidateButton"
          @click="linkToProject()"
        >Validate</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import actionList from '@/static/actionList';
import { cloneDeep } from 'lodash';
import SelectProjectsList from '@/modules/GameProject/components/SelectProjectsList.vue';

export default {
  name: 'LinkToProject',
  components: {
    SelectProjectsList,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      newProject: null,
      projectsList: [],
      actionList,
      warning: false,
      loading: false,
    };
  },
  mounted() {
    this.openToProject();
  },
  methods: {
    changeProject(project) {
      this.newProject = project;
      this.warning = this.showWarning(project);
    },
    showWarning(project) {
      if (project) {
        for (let i = 0; i < project.games.length; i += 1) {
          if (project.games[i].bundleId === this.game.bundleId) {
            return false;
          }
        }
      }
      return true;
    },
    async openToProject() {
      this.isVisible = true;
    },
    linkToProject() {
      this.linkGameProject();
      this.close();
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
    async linkGameProject() {
      const projectId = Number.isNaN(this.newProject) ? this.newProject : this.newProject.id;
      if (this.actionList) {
        this.loading = true;
        const action = this.actionList.filter(
          el => el.id === 'change_game_project',
        )[0];
        action.post.field[0].value = projectId;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        const reponse = await this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.loading = false;
        if (reponse.isSuccess) {
          if (this.$route.params.sub === 'project') {
            await this.$store.dispatch('Project/getProject', this.$route.params.filter);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.link-project-modal {
  .label {
    color:$slate;
    font-size: 12px;
    font-weight: bold;
  }

  .text {
      color: $grey;
      font-size: 12px;
  }

  .warning-alert {
    background-color: $light-red;
    border: 1px solid $red-ion;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    margin-top: 20px;
    white-space: wrap;
    line-height: normal;
    letter-spacing: normal;
    b {
      font-size: 12px;
      font-weight: bold;
      color: $soft-black;
    }
  }
}

</style>

<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :visible="isVisible"
      custom-class="unlink-project-modal"
      width="480px"
      data-test-id="myGamesUnlinkProjectModal"
      @close="close"
    >
      <div class="formAction">
        <p>
          <b>Are you sure you want to unlink this test from the project?</b>
          <br/>
          We recommend to keep tests linked so they share the same SDK setup.
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button data-test-id="myGamesUnlinkProjectCancelButton" @click="close">Cancel</el-button>
        <el-button
          class="danger-button-project"
          :disabled="!!!newProject"
          :loading="loading"
          data-test-id="myGamesUnlinkProjectConfirmButton"
          @click="unlinkProject()"
        >Unlink from the project</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import actionList from '@/static/actionList';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'UnlinkProject',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      newProject: null,
      actionList,
      loading: false,
    };
  },
  mounted() {
    this.openDialog();
  },
  methods: {
    openDialog() {
      this.newProject = this.game.gameAnalyticsId;
      this.isVisible = true;
    },
    unlinkProject() {
      this.setActionWithoutParams('unlink_game_project');
    },
    async setActionWithoutParams(actionValue) {
      if (this.actionList) {
        this.loading = true;
        const action = this.actionList.filter(el => el.id === actionValue)[0];
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        const reponse = await this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.loading = false;
        if (reponse.isSuccess) {
          await this.$store.dispatch('Project/removeGameFromProject', this.game.id);
          this.close();
        }
      }
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

p {
    color: $slate;
    font-size: 12px;
    font-weight: 300;
}

.danger-button-project {
    background-color: $red-alert;
    border-color: $red-alert;
    color: white;
}

</style>
<style lang="scss">
.unlink-project-modal {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 30px;
  }
  .formAction {
    font-size: 12px;
    color: $grey;
    line-height: normal;
    letter-spacing: normal;
    b {
      color: $soft-black;
    }
  }
}
</style>

<template>
  <div>
    <div
      :class="$style.header"
      @click="toggle"
    >
      <slot
        name="header"
        :collapse="collapse"
      />
    </div>

    <CollapsorTransition
      :name="transition"
      :duration="duration"
    >
      <slot v-if="!collapse" />
    </CollapsorTransition>
  </div>
</template>

<script>
import CollapsorTransition from './CollapsorTransition.vue';

export default {
  name: 'Collapsor',
  components: {
    CollapsorTransition,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: null,
    },
    value: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 300,
    },
    transition: {
      type: String,
      default: 'Height',
    },
  },
  data: vm => ({
    collapse: vm.collapsed !== null ? vm.collapsed : vm.value,
  }),
  watch: {
    collapse(coll) {
      this.$emit('input', coll);
    },
    value(state) {
      this.collapse = state;
    },
  },
  methods: {
    toggle() {
      this.$emit('collapse-start');
      this.collapse = !this.collapse;

      setTimeout(() => {
        this.$emit('collapse-end');
      }, this.duration);
    },
  },
};
</script>

<style module lang="scss">
.header {
    cursor: pointer;
}
</style>

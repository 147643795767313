<template>
  <div class="sort-game-component">
    <span class="title">Sort by:</span>
    <div class="sort-button-container">
      <span v-for="item in filters" :key="item.name">
        <el-button
          v-if="!item.requiredPermission || myPermissions.includes(item.requiredPermission)"
          :key="item.prop"
          type="primary"
          :plain="isSelectedFilter(item.prop) ? false : true"
          size="mini"
          data-test-id="gamesListFilterButton"
          :class="{ selected: selected === item.prop }"
          @click="sortBy(item)">
          {{ item.name }}
        </el-button>
      </span>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'SortGame',
  data() {
    return {
      filters: [
        {
          name: 'More recent',
          prop: 'lastIterationDate',
          order: 'desc',
        },
        {
          name: 'Best CPI',
          prop: 'bestCpi',
          order: 'asc',
        },
        {
          name: 'Best CPNU',
          prop: 'bestCpnu',
          order: 'asc',
          requiredPermission: 'view_cpnu',
        },
        {
          name: 'Best D1',
          prop: 'bestD1',
          order: 'desc',
        },
        {
          name: 'Best D7',
          prop: 'bestD7',
          order: 'desc',
        },
        {
          name: 'Best Cumulative D3 Playtime',
          prop: 'bestD3Playtime',
          order: 'desc',
        },
        {
          name: 'Best D3 ROAS',
          prop: 'score',
          order: 'desc',
          requiredPermission: 'view_proto_score',
        },
      ],
      selected: 'lastIterationDate',
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    currentView() {
      const current = cloneDeep(this.$store.getters['views/currentView']);
      return current;
    },
  },
  methods: {
    sortBy(value) {
      this.selected = value.prop;

      const sort = cloneDeep(value);
      delete sort.name;
      this.$store.dispatch('views/setSort', sort);
    },
    isSelectedFilter(value) {
      if (this.currentView.content.sort.prop === value) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.sort-game-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;

  .title {
    font-size: 12px;
    @media (max-width: 850px) {
      display: none;
    }
  }
  .sort-button-container {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 700px) {
      flex-wrap: nowrap;
    }
    .el-button {
      padding: 5px 7px;
      font-size: 11px;
      border-radius: 5px;
      &.selected {
        background: #067bf0;
        border-color: #067bf0;
        color: #ffffff;
      }
    }
    .el-button,
    .el-button + .el-button {
      margin: 0 5px 5px 0;
    }
  }
}
</style>

<template>
  <div class="icon-flag-container">
    <div class="icon-flag">
      <CountryFlag
        v-if="country"
        :country='country'
        size='big'
        :rounded="true"/>
      <el-image
        v-else
        class="preview"
        style="width: 100%;
                height: 100%;
                border-radius: 5px;"
        :src="studioLogo"
      >
      </el-image>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import studioLogo from '@/assets/images/studioLogo.png';

export default {
  name: 'IconCountry',
  components: {
    CountryFlag,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      studioLogo,
    };
  },
};

</script>


<style lang="scss">
.icon-flag-container {
    .icon-flag {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        border: solid 0.5px $foggy-grey;
        overflow: hidden;
    }
    .flag {
        height: 100%;
        margin: 0 !important;
        margin-left: -6px !important;
    }
    .preview img{
        margin-top: -1px;
        width: 105%;
        height: 105%;
    }
}

</style>

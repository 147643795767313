import mapping from './mapping';
import optionsTemplate from './options';

export default class Contentful {
  /**
   * Replaces Contenful tags and placeholders with the corresponding content (check mapping and optionsTemplate)
   * @param {Object} game - Game object
   * @param {String} content - Content coming from Contentful text editors
   * @return {String} Returns content after replacing the tags and placeholders
   */
  static replaceContentfulDynamicTags(content, game, iterations = 0) {
    if (!content) return '';
    const replaceBetweenTag = (placeholder, option) => {
      if (!placeholder) return '';
      const arrayPlaceholder = [];
      // [\\s\\S] to get everything between tags, even line breaks
      const replace = `${option.tag}([\\s\\S]*?)${option.tag.replace('{{', '{{/')}`;
      const re = new RegExp(replace, 'gm');
      const arrayMatchTag = placeholder.match(re) || [];

      // eslint-disable-next-line array-callback-return
      arrayMatchTag.forEach((element) => {
        const regex = RegExp(`${option.tag}([\\s\\S]*?)${option.tag.replace('{{', '{{/')}`, 'g');
        const dp = option.template ? `${option.template.start}
      ${regex.exec(element)[1]} 
      ${option.template.end}` : regex.exec(element)[1];

        const replacement = option.condition ? dp : '';

        arrayPlaceholder.push({
          placeholder: element,
          replacement,
        });
      });

      return arrayPlaceholder;
    };

    const replaceEmptyNodeList = [{
      placeholder: '<li><p></p></li>',
      replacement: '',
    },
    {
      placeholder: '<li></li>',
      replacement: '',
    }];

    let placeholderMapping = mapping;

    optionsTemplate(game).forEach((element) => { placeholderMapping = placeholderMapping.concat(replaceBetweenTag(content, element)); });
    let updatedContent = content;
    placeholderMapping.forEach((element) => {
      updatedContent = updatedContent.replace(element.placeholder, element.replacement);
    });
    replaceEmptyNodeList.forEach((element) => {
      updatedContent = updatedContent.replace(element.placeholder, element.replacement);
    });
    // Solves the issue of nested tags
    // The function is called as long as it matches this pattern : {{something}}
    // To prevent an infinite loop (because of an edge case) the iterations are limited to 10
    return content.match(/{{.+}}/g) && iterations < 10 ? this.replaceContentfulDynamicTags(updatedContent, game, iterations + 1) : updatedContent;
  }

  /**
   * Mounts a Vue component
   * @param {Component} component - Vue component https://github.com/vuejs/vue/blob/515467a618479792abedf01a7b1dcef2ac2a17ed/flow/component.js#L5
   * @param {String} nodeId - id selector to target
   * @param {Number} seconds - time required to delay the mounting
   */
  static mountAsyncComponent(component, nodeId, seconds) {
    setTimeout(() => {
      if (document.getElementById(nodeId)) {
        component.$mount(`#${nodeId}`);
      }
    }, seconds * 1000);
  }
}

<template>
  <div class="custom-slider">
    <el-slider
      ref="slider"
      v-model="internalValue"
      :step="step"
      :min="min"
      :max="max"
      :show-tooltip="false"
      :disabled="disabled || readonly"
      :class="{ readonly: readonly }"
      data-test-id="gameSubmissionBudgetSlider"
      @change="updateValue"
    >
    </el-slider>
    <div ref="sliderTooltip" class="slider-tooltip">
      <slot name="tooltip"></slot>
    </div>
    <div class="slider-max">
      <slot name="maxValue"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSlider',
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    minLock: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
    internalValue(value) {
      if (value < this.minLock) {
        this.internalValue = this.minLock;
        // Lock slider in place when going below minimum value
        this.$refs.slider.firstValue = this.minLock;
      }
      this.$emit('input', this.internalValue);
    },
  },
  mounted() {
    // Move the slider tooltip div into the slider button DOM object, for easier positioning
    const sliderButtonWrapper = this.$refs.slider.$refs.button1.$el;
    sliderButtonWrapper.appendChild(this.$refs.sliderTooltip);
  },
  methods: {
    updateValue(value) {
      if (value) {
        this.$emit('change', value);
      }
    },
  },
};
</script>

<style lang="scss">
$slider-blue: #067bf0;

.custom-slider {
  display: flex;
  flex-direction: row;
  width: 100%;

  .el-slider {
    width: 100%;

    .el-slider__button-wrapper {
      z-index: 0;
    }

    &.readonly {
      .disabled {
        .el-slider__button-wrapper {
          cursor: initial;
        }

        .el-slider__bar {
          background-color: $slider-blue;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        .el-slider__button {
          display: none;
        }
      }
    }
  }

  .slider-max {
    margin-left: 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .slider-tooltip {
    position: absolute;
    color: $slider-blue;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: max-content;
  }
}
</style>

/**
 * Maps a placeholder used in contentful to a HTML wrapper tag
 */

export default [
  {
    placeholder: '{{gameanalytics_game_key}}',
    replacement: '<span id="gameanalytics_game_key"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{gameanalytics_secret_key}}',
    replacement: '<span id="gameanalytics_secret_key"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{tiny_sauce_button}}',
    replacement: '<br><div id="tiny_sauce_button"><i v-else class="el-icon-loading"></i></div>',
  },
  {
    placeholder: '{{adjust_token}}',
    replacement: '<span id="adjust_token" @click="getAdjusToken()"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{facebook_app_account_id}}',
    replacement: '<span id="facebook_app_account_id"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{voodoo_admin_id}}',
    replacement: '<span id="voodoo_admin_id"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{test_analytics_key}}',
    replacement: '<li id="test_analytics_key"><i v-else class="el-icon-loading"></i></li>',
  },
  {
    placeholder: '{{test_adjust_key}}',
    replacement: '<li id="test_adjust_key"><i v-else class="el-icon-loading"></i></li>',
  },
  {
    placeholder: '{{ios14_note}}',
    replacement: '<li id="ios14_note"><i v-else class="el-icon-loading"></i></li>',
  },
  {
    placeholder: '{{request_facebook_access}}',
    replacement: '<span id="request_facebook_access"><i v-else class="el-icon-loading"></i></span>',
  },
  {
    placeholder: '{{test_fbconfig_key}}',
    replacement: '<li id="test_fbconfig_key"><i v-else class="el-icon-loading"></i></li>',
  },
  {
    placeholder: '{{fb_decryption_key}}',
    replacement: '<div id="fb_decryption_key"><i v-else class="el-icon-loading"></i></div>',
  },
];

export default {
  title: {
    text: '',
  },
  chart: {
    type: 'line',
    height: 250,
  },
  height: 250,
  legend: {
    enabled: false,
  },
  xAxis: {
    crosshair: {
      width: 32,
      color: '#F4E5FD',
    },
    categories: ['07/08', '08/08', '09/08'],
    title: {
      enabled: false,
    },
  },
  yAxis: {
    title: {
      enabled: false,
    },
    labels: {},
  },
  tooltip: {
    backgroundColor: '#4c4f5e',
    borderRadius: 10,
    borderWidth: 0,
    style: {
      color: 'white',
    },
    shadow: false,
  },
};

const filters = {
  in_progress: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'isIterating',
          operator: 'equals',
          operand: 'Platform',
          value: false,
        },
      },
      {
        type: 'query-builder-group',
        query: {
          logicalOperator: 'any',
          children: [
            {
              type: 'query-builder-rule',
              query: {
                rule: 'status',
                operator: '=',
                operand: 'Game status',
                value: 'pending_sdk_integration',
              },
            },
            {
              type: 'query-builder-rule',
              query: {
                rule: 'status',
                operator: '=',
                operand: 'Game status',
                value: 'pending_review',
              },
            },
          ],
        },
      },
    ],
  },
  pending_approval: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'status',
          operator: '=',
          operand: 'Game status',
          value: 'pending_testing',
        },
      },
    ],
  },
  in_testing: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'status',
          operator: '=',
          operand: 'Game status',
          value: 'in_testing',
        },
      },
    ],
  },
  finished: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'status',
          operator: '=',
          operand: 'Game status',
          value: 'finished',
        },
      },
    ],
  },
  archived: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'status',
          operator: '=',
          operand: 'Game status',
          value: 'archived',
        },
      },
    ],
  },
  is_iterating: {
    logicalOperator: 'all',
    children: [
      {
        type: 'query-builder-rule',
        query: {
          rule: 'isIterating',
          operator: 'equals',
          operand: 'Platform',
          value: true,
        },
      },
      {
        type: 'query-builder-rule',
        query: {
          rule: 'status',
          operator: '=',
          operand: 'Game status',
          value: 'pending_sdk_integration',
        },
      },
    ],
  },
  knowledgeSharing: {
    cpi: {
      logicalOperator: 'all',
      children: [
        {
          type: 'query-builder-group',
          query: {
            logicalOperator: 'all',
            children: [
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastCpi',
                  operator: '>=',
                  operand: 'CPI (last)',
                  value: 0,
                },
              },
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastCpi',
                  operator: '<=',
                  operand: 'CPI (last)',
                  value: 300,
                },
              },
            ],
          },
        },
        {
          type: 'query-builder-group',
          query: {
            logicalOperator: 'all',
            children: [

            ],
          },
        },
        {
          type: 'query-builder-group',
          query: {
            logicalOperator: 'all',
            children: [
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastD1',
                  operator: '<=',
                  operand: 'D1 (last)',
                  value: 70,
                },
              },
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastD1',
                  operator: '>=',
                  operand: 'D1 (last)',
                  value: 0,
                },
              },
            ],
          },
        },
        {
          type: 'query-builder-group',
          query: {
            logicalOperator: 'all',
            children: [
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastD7',
                  operator: '>=',
                  operand: 'D7 (last)',
                  value: 0,
                },
              },
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastD7',
                  operator: '<=',
                  operand: 'D7 (last)',
                  value: 30,
                },
              },
            ],
          },
        },
      ],
    },
    ctr: {
      logicalOperator: 'all',
      children: [
        {
          type: 'query-builder-group',
          query: {
            logicalOperator: 'all',
            children: [
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastCtr',
                  operator: '>=',
                  operand: 'Ctr (last)',
                  value: 0,
                },
              },
              {
                type: 'query-builder-rule',
                query: {
                  rule: 'lastCtr',
                  operator: '<=',
                  operand: 'Ctr (last)',
                  value: 15,
                },
              },
            ],
          },
        },
      ],
    },
  },
};

export default filters;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"publishing",attrs:{"id":"nav"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"item-left-mobile"},[_c('div',{staticClass:"burger-menu",attrs:{"data-test-id":"HeaderBurgerMenu"},on:{"click":_vm.switchMobileMenu}},[_c('el-image',{staticClass:"burger",staticStyle:{"width":"20px","height":"20px"},attrs:{"src":_vm.burger,"fit":'cover'}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('span',[_c('img',{attrs:{"src":_vm.gameIcon,"width":40}})])])])],1)]),_c('div',{staticClass:"item-left",attrs:{"data-test-id":"HeaderLogo"}},[_c('router-link',{attrs:{"to":{
            name: 'home'
          }}},[_c('li',[_c('a',{staticClass:"logo"}),(!_vm.isOnAdmin)?_c('span',{staticClass:"sub-logo",on:{"click":function($event){return _vm.actionHeader('home')}}},[_vm._v("PUBLISHING PLATFORM")]):_vm._e(),(_vm.isOnAdmin)?_c('span',{staticClass:"sub-logo",on:{"click":function($event){return _vm.actionHeader('home')}}},[_vm._v(" STUDIO ADMIN ")]):_vm._e()])]),_c('el-dropdown',{staticClass:"drop-menu-logo",attrs:{"placement":"bottom-start","trigger":"click","data-test-id":"HeaderDropdownLogo"},on:{"command":_vm.actionHeader,"visible-change":function($event){_vm.headerDropdownVisible = $event}}},[_c('el-button',{staticClass:"dropdown-button",attrs:{"icon":`el-icon-caret-${_vm.headerDropdownVisible ? 'top' : 'bottom'}`}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"data-test-id":"HeaderItemStudioPlatform","command":'studioHome'}},[_vm._v("Studio Platform")]),_c('el-dropdown-item',{attrs:{"data-test-id":"HeaderItemStudioAdmin","command":'mystudio'}},[_vm._v("Studio Admin")])],1)],1),_c('div',{staticClass:"menu"},[_c('router-link',{attrs:{"to":{
              name: 'home',
              query: { game: this.$route.query.game, studio: this.$route.query.studio }
            }}},[_c('li',[_c('span',{staticClass:"logo-ext",class:{ active: _vm.gamePageActive }},[_vm._v(_vm._s(`Games`))])])]),_c('router-link',{attrs:{"to":{
              name: 'studios',
              query: { game: this.$route.query.game, studio: this.$route.query.studio }
            }}},[_c('li',[_c('span',{staticClass:"logo-ext",class:{ active: _vm.studioPageActive }},[_vm._v(_vm._s(`Studios`))])])])],1)],1),(_vm.me)?_c('div',{staticClass:"item-right"},[_c('Notifications'),_c('el-dropdown',{attrs:{"data-test-id":"HeaderProfilerButton"},on:{"command":_vm.actionHeader}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-user-solid"}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.me.firstName)+" "+_vm._s(_vm.me.lastName))]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"data-test-id":"HeaderProfilerButtonLogout","command":'logout'}},[_vm._v(_vm._s(_vm.$t("common.logOut")))]),_c('el-dropdown-item',{attrs:{"data-test-id":"HeaderProfilerButtonMyProfile","command":'myprofile'}},[_vm._v(_vm._s(_vm.$t("common.myProfile")))])],1)],1)],1):_vm._e()])]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.mobileMenu)?_c('MobileMenuPM',{on:{"visibility":_vm.switchMobileMenu}}):_vm._e()],1),_c('div',{staticClass:"margin-header"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="!loading" class="studio-infos-page">
    <div v-if="contractList" class="section">
      <h2>Contracts</h2>
      <div v-if="contractList.length > 0" class="users-list">
        <ul class="contractList">
          <li v-for="contract in contractList" :key="contract.id" @click="getContract(contract.id)">
            <div class="contract">
              <div v-if="contract.loading" class="contract-loading">
                <i class="el-icon-loading"></i>
              </div>
              <div class="studio-logo">
                <el-image
                  v-if="!contract.loading"
                  class="preview"
                  style="width: 30px;
                height: 30px;
                margin: 5px 5px 5px 0;
                border-radius: 5px;
                min-width: 30px;"
                  :src="contractLogo"
                  :fit="'cover'"
                >
                  <div slot="error" class="image-slot">
                    <span><img :src="gameIcon" :width="40"/></span>
                  </div>
                </el-image>
              </div>
              <div class="name">
                <span>{{ contract.name }}</span>
              </div>
              <div>
                <span>{{ contract.date | localeDate }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="no-contract">
        <span>
          No contract is currently available for your studio.
          <!-- Please reach out to
          <a href="mailto:studio-support@voodoo.io">studio-support@voodoo.io</a> if you believe your
          contract information is missing.-->
        </span>
      </div>
    </div>
  </div>
  <div v-else>
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import contractLogo from '@/assets/images/invoice-icon.svg';

export default {
  name: 'ContractAdminSubPage',
  components: {
    // SlidingPage,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contractLogo,
      form: {},
      gameIcon,
    };
  },
  computed: {
    contractList() {
      return cloneDeep(this.$store.getters['adminstudio/contracts']) || null;
    },
    countries() {
      return cloneDeep(this.$store.getters['users/countries']) || null;
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['adminstudio/loading_studio_contract']) || null;
    },
    canSave() {
      const infos = JSON.stringify(this.infos);
      const form = JSON.stringify(this.form);
      if (infos !== form) {
        return true;
      }
      return false;
    },
  },
  watch: {
    id() {
      this.$store.dispatch('adminstudio/getStudioContracts', this.id);
    },
  },
  mounted() {
    this.$store.dispatch('adminstudio/getStudioContracts', this.id);
  },
  methods: {
    getContract(id) {
      this.$store.dispatch('adminstudio/getContractById', {
        studioId: this.id,
        id,
      });
    },
    saveStudioInfos() {
      // eslint-disable-next-line no-restricted-syntax
      const difference = Object.keys(this.infos).filter(k => this.infos[k] !== this.form[k]);
      const data = {};
      for (let i = 0; i < difference.length; i += 1) {
        const element = difference[i];
        data[element] = this.form[element];
      }

      this.$store.dispatch('adminstudio/updateStudioInfos', data);
    },
  },
};
</script>

<style lang="scss">
.no-contract {
  display: flex;
}
.contractList {
  &:first-child {
    border-top: 1px solid #eeeeee;
  }
  li {
    padding: 0 20px;
    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }
    .contract-loading {
      padding: 0 10px;
    }
  }
  .contract {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #eeeeee;
    .name {
      flex-grow: 1;
      text-align: left;
      margin-left: 30px;
    }
  }
}
</style>

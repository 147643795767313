<template>
  <div :class="$style.wrapper">
    <div :class="$style.iconContainer">
      <div :class="$style.title">Game icon</div>
      <div :class="$style.subtext">
        (Must be square, .png or .jpg. Recommended resolution: 512x512)
      </div>
      <UploadImageComponent
        v-if="canUpdateGameIcon"
        :game="game"
        :class="$style.iconUpload"
        data-test-id="gameSubmissionGameIconUploadWrapper"
        @icon-state="setIconValid"
      ></UploadImageComponent >
    </div>
    <div v-if="game.isManagedByVoodoo" :class="$style.videosContainer">
      <div :class="$style.title">Advertising videos</div>
      <div :class="$style.subtext">
        Gameplay footage only: no menus, no interruptions, no edits (no text, no
        call to actions - these are added by the ad networks).<br />
        The first 3 seconds need to be as engaging as possible.<br />
        Length: 15 to 30 seconds.
      </div>
      <UploadVideosComponent
        v-if="adnetworks.length > 0"
        :disabled="!canEditVideo"
        :loading="loadingVideosByPlatform"
        :game-object="game"
        :adnetworks-available="adnetworks"
        :store-name="gamecp.storeName"
        data-test-id="gameSubmissionUploadVideosWrapper"
        @set-store-name="
          (storeName) => {
            gamecp.storeName = storeName;
            isStoreNameChanged = true;
          }
        "
        @game-submitted="setVideoToUpload"
        @videos-state="setvideosIsValid"
      ></UploadVideosComponent>
    </div>
    <div v-if="!game.isManagedByVoodoo">
      <div>
        <br>
        <br>
        <div>Link your Facebook campaign</div>
        <LinkFacebookCampaign
          :game="game"
          @change="updateLinkId"
        ></LinkFacebookCampaign>
      </div>
    </div>
    <div :class="$style.checkboxContainer">
      <div
        v-if="isEligibleForCompetition && !game.isIterating"
        :class="$style.competitionCheckbox"
      >
        <el-checkbox
          id="checkbox-competition"
          v-model="isRegisteredForCompetition"
          name="type"
          data-test-id="gameSubmissionCompetitionCheckbox"
        >
          <div>
            <span>
              Register this game for the
              <a
                v-if="currentCompetition.eventUrl"
                :href="currentCompetition.eventUrl"
                target="_blank"
              >
                {{ currentCompetition.name }}
                <i class="el-icon-top-right"></i>
              </a>
              <template v-else>
                {{ currentCompetition.name }}
              </template>
            </span>
          </div>
        </el-checkbox>

        <div v-if="currentCompetition.tcUrl"  style="margin-right: 5px; margin-top: 5px;">
          By ticking this box, you agree to the competition's
          <a :href="currentCompetition.tcUrl" target="_blank">
            Terms and Conditions
            <i class="el-icon-top-right"></i>
          </a>
        </div>
      </div>
      <div v-if="!game.isIterating" :class="$style.termsCheckbox">
        <el-checkbox
          id="checkbox-terms"
          v-model="hasAcceptedTerms"
          name="type"
          data-test-id="gameSubmissionTermsAcceptCheckbox">
          <div>
            <span style="margin-right: 5px">
              By submitting this test, you accept the
              <a :href="'https://www.voodoo.io/terms-platform'" target="_blank">
                Terms and Conditions
                <i class="el-icon-top-right"></i>
              </a>
            </span>
          </div>
        </el-checkbox>
      </div>
      <div v-if="shouldDisplayNetworkDetails">
        <p>⚠️ Sorry, something went wrong with your campaign</p>
        <div v-for="network of failedNetworks" :key="network.name">
          <img :src="getNetworkLogo(network.name)" />
          <h1>{{ network.label }}</h1>
          <p>
            We had an error when preparing your {{ network.label }} campaign.
          </p>
          <p v-if="network.error" v-html="displayError(network.error)"></p>
        </div>
        <div v-for="network of successNetworks" :key="network.name">
          <img :src="getNetworkLogo(network.name)" />
          <h1>{{ network.label }}</h1>
          <p>Your campaign is ready and standing by</p>
        </div>
      </div>
    </div>
    <div v-if="game.loadingRequest" :class="$style.loadingLayer"></div>

    <PlaceholderRequestTesting v-if="game.loadingRequest" :is-link-campaign="!game.isManagedByVoodoo"></PlaceholderRequestTesting>

    <el-button
      v-if="!game.loadingRequest"
      :disabled="!isValid"
      type="primary"
      :class="$style.validateBtn"
      data-test-id="gameSubmissionRequestTestingButton"
      @click="gotoNextStep">
      Request testing
    </el-button>
  </div>
</template>

<script>
import LinkFacebookCampaign from '@/components/GameSubmissionForm/LinkFacebookCampaign.vue';
import UploadVideosComponent from '@/components/GameSubmissionForm/UploadVideosComponent.vue';
import PlaceholderRequestTesting from '@/components/Placeholders/RequestTestingPlaceholder/RequestTestingPlaceholder.vue';
import UploadImageComponent from '@/components/UploadImageComponent.vue';
import actionList from '@/static/actionList';
import { Notification } from 'element-ui';
import { cloneDeep } from 'lodash';

export default {
  components: {
    UploadVideosComponent,
    UploadImageComponent,
    LinkFacebookCampaign,
    PlaceholderRequestTesting,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    genres: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      actionList,
      gamecp: this.game,
      iconIsValid: false,
      isRegisteredForCompetition: false,
      hasAcceptedTerms: false,
      linkId: null,
      isStoreNameChanged: false,
      inspiredByWebinarIsValid:
        !!this.game.inspiredByWebinar || !!this.game.isIterating,
      videosToUpload: {
        facebook: [],
        snapchat: [],
      },
      videosIsValid: {
        state: false,
        videos: 0,
      },
    };
  },
  computed: {
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    gamePermissionsUser() {
      return Object.keys(this.game.permissions);
    },
    canUpdateGameIcon() {
      return (
        this.gamePermissionsUser.includes('change_game_icon')
        || this.myPermissions.includes('change_game_icon')
      );
    },
    canEditVideo() {
      return (
        this.gamePermissionsUser.includes('change_game_video')
        || this.myPermissions.includes('change_game_video')
      );
    },
    currentCompetition() {
      return this.$store.getters['users/codification']
        ? this.$store.getters['users/codification'].currentCompetition
        : undefined;
    },
    shouldDisplayNetworkDetails() {
      return (
        this.game.isManagedByVoodoo
        && this.failedNetworks.length
        && this.successNetworks.length
      );
    },
    adnetworks() {
      if (!this.game) {
        return [];
      }
      const adArray = Object.keys(this.game.adNetworks);
      const ad = [];
      for (let i = 0; i < adArray.length; i += 1) {
        const element = adArray[i];

        ad.push({ name: element });
      }

      return ad;
    },
    failedNetworks() {
      return cloneDeep(
        this.$store.getters['submitGame/campaignCreationFailedNetworks'],
      );
    },
    successNetworks() {
      return cloneDeep(
        this.$store.getters['submitGame/campaignCreationSuccessNetworks'],
      );
    },
    loadingVideosByPlatform() {
      return cloneDeep(
        this.$store.getters['submitGame/loadingVideosByPlatform'],
      );
    },
    isPreEligibleForCompetition() {
      // Returns true if the options selected in previous screens match the requirements of the competition
      if (!this.currentCompetition) {
        return false;
      }

      if (
        !this.currentCompetition.allowedPlatforms.includes(this.game.platform)
      ) {
        return false;
      }

      if (
        this.game.isCtr
        && !this.currentCompetition.allowedTestingMethods.includes('ctr')
      ) {
        return false;
      }

      if (
        !this.game.isCtr
        && !this.currentCompetition.allowedTestingMethods.includes('cpi')
      ) {
        return false;
      }

      return true;
    },
    isEligibleForCompetition() {
      if (!this.isPreEligibleForCompetition) {
        return false;
      }

      if (!this.genres) {
        return false;
      }

      for (let index = 0; index < this.genres.length; index += 1) {
        const element = this.genres[index];
        if (this.currentCompetition.allowedGenres.includes(element.id)) {
          return true;
        }
      }

      return false;
    },
    isValid() {
      if (this.game.iterations.length > 1) {
        return (
          this.isUploadedCampaignAssets
          && ((this.game.isManagedByVoodoo && this.canRequestTesting)
            || (!this.game.isManagedByVoodoo && this.canLinkCampaign()))
        );
      }
      return (
        this.hasAcceptedTerms
        && !!this.isUploadedCampaignAssets
        && !!(
          (this.game.isManagedByVoodoo && this.canRequestTesting)
          || (!this.game.isManagedByVoodoo && this.canLinkCampaign())
        )
      );
    },
    hasToFillInspiredByWebinar() {
      return (
        this.codification
        && this.codification.availableWebinars
        && this.codification.availableWebinars.length > 0
      );
    },
    isUploadedCampaignAssets() {
      let isValidVideo = false;

      if (this.videosIsValid.state === true) {
        isValidVideo = true;
      } else if (!this.game.isManagedByVoodoo) {
        isValidVideo = true;
      }
      return !!(
        this.passStoreNameValidation
        && isValidVideo
        && (this.iconIsValid === true || this.game.isCtr)
      );
    },
    passStoreNameValidation() {
      if (this.game.isIterating && this.hasChosenSnapChat) {
        return (
          (this.game.iterations[this.game.iterations.length - 1].adNetworks
            .snapchat.videos.length === 0
            && this.videosToUpload.snapchat.length === 0)
          || this.gamecp.storeName
        );
      }
      return (
        !this.game.isManagedByVoodoo
        || !this.hasChosenSnapChat
        || this.gamecp.storeName
        || this.game.storeName
      );
    },
    hasChosenSnapChat() {
      return (
        this.game.iterations.length
        && this.game.iterations[this.game.iterations.length - 1].adNetworks
          .snapchat
      );
    },
    canRequestTesting() {
      return (
        this.gamePermissionsUser.find(
          permission => permission.id === 'request_testing',
        ) !== 'undefined'
      );
    },
  },
  watch: {
    isEligibleForCompetition(value) {
      if (!value) {
        this.isRegisteredForCompetition = false;
      }
    },
  },
  methods: {
    setIconValid(value) {
      this.iconIsValid = value;
    },
    setvideosIsValid(value) {
      this.videosIsValid = value;
    },
    updateLinkId(value) {
      this.linkId = value;
    },
    setVideoToUpload(value) {
      for (let i = 0; i < this.adnetworks.length; i += 1) {
        const element = this.adnetworks[i];
        if (value.otherPlatforms[element.name]) {
          this.videosToUpload[element.name] = value.otherPlatforms[element.name].videos;
        }
      }
    },
    updateInspiredByWebinar(value) {
      this.inspiredByWebinarIsValid = value !== '';
    },
    requestTesting(extraParams = null) {
      this.$store.dispatch('ActionToDispatch/setRequestTesting', {
        game: this.game,
        extraParams,
      });
    },
    canLinkCampaign() {
      return this.linkId !== null;
    },
    async linkCampaign() {
      let action = this.actionList.filter(
        el => el.id === 'test_studio_campaign',
      )[0];
      const post = {
        post: {
          format: 'application/json',
          field: [
            {
              type: 'Object',
              param: 'choice',
              deepParam: ['facebook'],
              value: this.linkId,
            },
          ],
        },
      };
      action = {
        ...action,
        ...post,
      };
      const param = {
        id: this.game.id,
        action,
      };
      await this.$store.dispatch('submitGame/linkCampagn', param);
    },
    async gotoNextStep() {
      await this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);

      const result = await this.uploadCampaignAssets();
      if (result) {
        if (this.game.isManagedByVoodoo) {
          this.requestTesting();
        } else {
          await this.linkCampaign();
        }
      }

      await this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
    },
    async uploadCampaignAssets() {
      try {
        if (this.isStoreNameChanged) {
          await this.$store.dispatch('submitGame/updateGame', {
            id: this.game.id,
            storeName: this.gamecp.storeName,
          });
        }

        if (this.isRegisteredForCompetition && !this.game.competitionId) {
          this.$store.dispatch('submitGame/updateGame', {
            id: this.game.id,
            competitionId: this.currentCompetition.id,
          });
        } else if (!this.isRegisteredForCompetition && this.game.competitionId) {
          this.$store.dispatch('submitGame/updateGame', {
            id: this.game.id,
            competitionId: null,
          });
        }

        return true;
      } catch (e) {
        const message = e.message || 'An error occurred, please refresh or contact support (studio-support@voodoo.io)';
        Notification.error({
          title: 'Error',
          message,
          position: 'bottom-left',
        });
        return false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  padding: $spacing * 3;
  padding-top: 0;

}

.loadingLayer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $baground-loading-layer;
  z-index: 1;
}

.title {
  margin-top: $spacing * 3;
  font-weight: 600;
  margin-bottom: calc($spacing / 2);
  font-size: 12px;
}

.subtext {
  color: $grey;
  line-height: 1;
  margin-bottom: $spacing;
  font-size: 12px;
  line-height: 16px;
}

.iconContainer {
  max-width: 50%;
  text-align: left;
}

.iconUpload {
  margin-top: $spacing;
  width: 149px;
}

.videosContainer {
  margin-top: $spacing * 10;
  text-align: left;
}

.competitionCheckbox,
.termsCheckbox {
  text-align: left;
  padding: $spacing * 3;
  background-color: $foggy-blue;
}

.competitionCheckbox {
  margin-bottom: $spacing * 3;
}

.checkboxContainer {
  margin-top: $spacing * 10;
}

.validateBtn {
    margin-top: $spacing * 10;
}
</style>

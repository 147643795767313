<template>
  <div class="games-in-testing">
    <div
      v-for="(item, ind) in myGamesInTesting"
      :id="`game-${item.id}`"
      :key="`${item.id}-${ind}`"
      class="game">
      <AsyncAction :game="item"></AsyncAction>

      <div v-if="item.disabled" class="disabled-game">
        <span>There are ongoing operations</span>
        <span>
          <i class="el-icon-loading"></i>
        </span>
      </div>
      <HeaderCompactStudioGameCard :item="item" @click="gotoGameView(item)"></HeaderCompactStudioGameCard>
    </div>

    <div v-if="myGamesInTesting.length === 0 && !loading" class="no-data">
      <el-image
        class="preview"
        style="

                        width: 40%;
                        padding: 20px;
                        margin: 5px;
                        border-radius: 5px;
                        max-width: 700px;"
        :src="desert"
        :fit="'cover'"
      >
        <div slot="error" class="image-slot">
          <span><img :src="gameIcon" :width="40"/></span>
        </div>
      </el-image>
      <span class="title-no-game">You haven’t submit a game yet!</span>
      <span class="quote-no-game">Start by submitting a game and follow the process here</span>
      <CreateGameButton></CreateGameButton>
    </div>
    <InfiniteLoading ref="InfiniteLoadingInTesting" @infinite="getMyGames">
      <div slot="spinner" class="spinner">
        <i class="el-icon-loading loading-list" />
      </div>
      <div slot="no-more" class="no-more">No more games</div>
      <div slot="no-results" class="no-more">
        <div class="no-results"></div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';
import CreateGameButton from '@/components/CreateGameButton.vue';
import AsyncAction from '@/components/AsyncAction.vue';
import HeaderCompactStudioGameCard from '@/components/HeaderCompactGameCard.vue';
import tools from '@/tools/tools';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import desert from '../assets/images/desert.svg';

export default {
  name: 'GameIntesting',
  components: {
    CreateGameButton,
    InfiniteLoading,
    AsyncAction,
    HeaderCompactStudioGameCard,
  },
  props: {},
  data() {
    return {
      desert,
      gameIcon,
    };
  },
  computed: {
    showGameOnly() {
      return cloneDeep(this.$store.getters['gameAction/showGameOnly']);
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    myGamesInTesting() {
      return cloneDeep(this.$store.getters['gameAction/myGames']);
    },
    loading() {
      return cloneDeep(this.$store.getters['gameAction/myGamesLoading']) || false;
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.myGames.order;
    },
  },
  watch: {
    $route(value, old) {
      if (value.path !== old.path) {
        this.$store.dispatch('gameAction/reset_gameList');
        this.$refs.InfiniteLoadingInTesting.stateChanger.reset();
      }
    },
    showGameOnly() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInTesting.stateChanger.reset();
    },
    sort() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInTesting.stateChanger.reset();
    },
    order() {
      this.$store.dispatch('gameAction/reset_gameList');
      this.$refs.InfiniteLoadingInTesting.stateChanger.reset();
    },
  },
  mounted() {
    // this.$store.dispatch('gameAction/getMyGames');
  },

  methods: {
    getMyGames($state) {
      this.$store.dispatch('gameAction/getMyGames', {
        loader: $state,
        status: 'in_testing',
        term: this.$route.query.term || '',
        type: this.$route.params.type || 'cpi',
      });
    },
    show(item) {
      this.$store.dispatch('gameAction/showDetail', item);
      if (item.show === true) {
        window.scroll(0, tools.findPos(document.getElementById(`game-${item.id}`)));
      }
    },
    getTagsArray(item) {
      return item.split('|');
    },
    gotoGameView(item) {
      this.$router
        .push({
          query: {
            game: undefined,
            studio: undefined,
            view: undefined,
            gameid: item.id,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.load {
  font-size: 30px;
  color: grey;
  margin-top: 70px;
}
.games-list {
  padding: 0px 14px;
  @media (max-width: 700px) {
    padding: 10px 10px;
  }
  .games-in-testing {
    .game {
      // height: 100px;
      display: flex;
      position: relative;
      // position: sticky;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
      }
      .disabled-game {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
        border-radius: 10px;
        background-color: rgba(201, 224, 246, 0.89);
        // background-color: rgba(130, 189, 247, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #067bf0;
        font-weight: bold;
        span {
          margin-right: 10px;
        }
      }
      .content-page-step {
        width: 100%;
      }
      .bottom-content {
        background-color: #e6f1fd;
        color: #067bf0;
        width: 100%;
        padding: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &:hover {
          cursor: pointer;
          background-color: #e0eefc;
        }
        span {
          padding: 5px;
          font-size: 10px;
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-no-game {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #82bdf7;
        line-height: 40px;
      }
      .quote-no-game {
        font-size: 15px;
        text-align: center;
        color: #82bdf7;
        margin-bottom: 30px;
      }
    }
  }
}
</style>

<template>
  <div>
    <div :class="$style.iterationContainer">
      <Collapsor v-model="isCollapsed">
        <template v-slot:header>
          <IterationHeader
            :iteration-nb="iteration.iterationNumber"
            :start-date="new Date(startDate)"
            :end-date="endDate ? new Date(endDate) : null"
            :status="game.status"
            :index="index"
            :collapsor-icon="collapsorIcon"
            :scheduling-details="iteration.schedulingDetails"
          >
            <ProtoScore
              :recommendation="source.recommendation"
              :display-details="myPermissions.includes('view_proto_score')"
              :score-values="formatProtoScore(iteration, section.title)"
            ></ProtoScore>
          </IterationHeader>
        </template>
        <div>
          <Tab
            v-if="tabSections.length > 1"
            :sections="tabSections"
            @change="changeSection"
          />
          <div :class="$style.kpiContainer">
            <div :class="$style.kpibigContainer">
              <div v-for="(kpi, indexkpi) in kpiBig" :key="kpi.name">
                <KpiBig
                  :class="$style.kpiBig"
                  :title="kpi.name"
                  :value="kpi.value"
                  :tooltip="kpi.tooltip"
                  :unit="kpi.unit"
                  :unit-before="kpi.unitBefore"
                />
                <Variation
                  v-if="kpi.variationDiff || kpi.variationDiff === 0"
                  :variation="kpi.variationDiff"
                  :is-better="kpi.variationBetter"
                  :unit="kpi.unit"
                  :unit-before="kpi.unitBefore"
                  :display-value="kpi.varationDisplayValue"
                />
                <div
                  v-if="
                    Object.keys(source.studioData).length !== 0 &&
                      indexkpi === 0 && showSeeChartButton
                  "
                  :class="$style.chartCTAContainer"
                  @click="isChartsDisplay = true"
                >
                  <font-awesome-icon
                    icon="chart-line"
                    :class="$style.chartsCTAIcon"
                  />
                  <div
                    :class="$style.chartsCTAText"
                    data-test-id="gameInfosSeeCharts"
                  >See charts</div>
                </div>
              </div>
              <div
                v-if="game.platform === 'ios' && section.title !== 'global'"
                :class="$style.noDataIosContainer"
              >
                <div :class="$style.noDataIosContent">
                  <font-awesome-icon
                    icon="times-circle"
                    :class="$style.noDataIcon"
                  />
                  <div>
                    No more data available <br />
                    due to iOS restrictions
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="game.platform !== 'ios' || section.title === 'global'"
              :class="$style.kpismallContainer"
            >
              <KpiSmall
                v-for="kpi in kpiSmall"
                :key="kpi.name"
                :class="$style.kpismall"
                :color="smallKpiColor(endDate, kpi.color)"
                :title="kpi.name"
                :value="kpi.value"
                :tooltip="kpi.tooltip"
                :icon="kpi.icon"
                :unit="kpi.unit"
                :unit-before="kpi.unitBefore"
              />
            </div>
            <div :class="$style.videoListContainer">
              <VideoList
                :is-mobile="ismobile"
                :section="section.title"
                :game="game"
                :videos-list="videosList[index]"
                :class="$style.videoList"
              />
            </div>
            <Total v-if="!ismobile" :source="source" :section="section"/>
          </div>
        </div>
      </Collapsor>
    </div>
    <ChartsDialog
      v-if="chartsData"
      v-model="isChartsDisplay"
      :data="chartsData"
      :game="game"
    >
      <template v-slot:cpi>
        <Variation
          v-if="source.cpiDiff || source.cpiDiff === 0"
          :variation="cpiVariationDiff"
          :is-better="source.cpiDiffIsBetter"
          unit="$"
          :unit-before="true"
        />
      </template>
      <template v-slot:cpnu>
        <Variation
          v-if="source.cpnuDiff || source.cpnuDiff === 0"
          :variation="cpnuVariationDiff"
          :is-better="source.cpnuDiffIsBetter"
          unit="$"
          :unit-before="true"
        />
      </template>
      <template v-slot:d1>
        <Variation
          v-if="source.d1RetDiff || source.d1RetDiff === 0"
          :variation="d1VariationDiff"
          :is-better="source.d1RetDiffIsBetter"
          unit="%"
        />
      </template>
      <template v-slot:d3Playtime>
        <Variation
          v-if="source.d3PlaytimeDiff || source.d3PlaytimeDiff === 0"
          :variation="source.d3PlaytimeDiff"
          :is-better="source.d3PlaytimeDiffIsBetter"
          :display-value="timeFormatter(source.d3PlaytimeDiff)"
        />
      </template>
    </ChartsDialog>
  </div>
</template>

<script>
import moment from 'moment';

import { cloneDeep, throttle } from 'lodash';
import Tab from '@/modules/GameStats/components/Tab';
import KpiSmall from '@/modules/GameStats/components/KpiSmall';
import KpiBig from '@/modules/GameStats/components/KpiBig';
import IterationHeader from '@/modules/GameStats/components/IterationHeader';
import Variation from '@/modules/GameStats/components/Variation';
import VideoList from '@/modules/GameStats/components/VideosTableList';
import ProtoScore from '@/components/ProtoScore/ProtoScore.vue';
import { ChartsDialog } from '@/modules/GameStats/components/Charts';
import Collapsor from '@/components/Collapsor';
import Total from '@/modules/GameStats/components/Total';
import tools from '@/tools/tools';

export default {
  name: 'Iteration',
  components: {
    KpiBig,
    IterationHeader,
    Tab,
    KpiSmall,
    Variation,
    VideoList,
    ProtoScore,
    ChartsDialog,
    Collapsor,
    Total,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    iteration: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    ismobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      section: {
        title: 'global',
      },
      isChartsDisplay: false,
      isCollapsed: this.index !== 0,
      isUnder960: window.innerWidth < 960,
    };
  },
  computed: {
    isFacebook() {
      return tools.isfacebookCampaign(this.game);
    },
    isSnapChat() {
      return tools.isSnapChatCampaign(this.game);
    },
    isCPNU() {
      if (this.game.platform === 'ios') {
        const date = new Date(this.game.createdAt);
        const minDate = new Date('2021-05-01');
        if (minDate < date) {
          return true;
        }
      }
      return false;
    },
    showSeeChartButton() {
      if (this.section.title !== 'global' && this.isCPNU && this.isFacebook && this.isSnapChat) {
        return false;
      }
      return true;
    },
    source() {
      let source = this.iteration;
      const section = this.section.title;

      if (section !== 'global') {
        source = this.iteration.adNetworks[section];
      }
      return source;
    },
    collapsorIcon() {
      return this.isCollapsed ? 'angle-down' : 'angle-up';
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    startDate() {
      // const section = this.section.title;

      const date = this.iteration.iterationStart || this.iteration.createdAt;
      // if (section !== 'global') {
      //   date = this.source.campaignStartedAt || this.iteration.createdAt;
      // }

      return date;
    },
    endDate() {
      // const section = this.section.title;

      const date = this.iteration.iterationEnd
        ? this.iteration.iterationEnd
        : null;
      // if (section !== 'global') {
      //   date = this.source.campaignEndAt ? this.source.campaignEndAt : null;
      // }

      return date;
    },
    tabSections() {
      const sections = Object.entries(this.game.adNetworks).map(section => ({
        title: section[0],
        id: section[1].adNetworkId,
      }));
      if (sections.length > 1) {
        sections.unshift({
          title: 'global',
        });
      }
      return sections;
    },
    d1VariationDiff() {
      return this.source.d1RetDiff || this.source.d1RetDiff === 0
        ? Math.round(parseFloat(this.source.d1RetDiff) * 100).toFixed(0)
        : null;
    },
    cpiVariationDiff() {
      return this.source.cpiDiff || this.source.cpiDiff === 0
        ? parseFloat(this.source.cpiDiff).toFixed(2)
        : null;
    },
    cpnuVariationDiff() {
      return this.source.cpnuDiff || this.source.cpnuDiff === 0
        ? parseFloat(this.source.cpnuDiff).toFixed(2)
        : null;
    },
    kpiBig() {
      return [
        {
          name: 'Cost per install',
          value: parseFloat(this.source.cpi).toFixed(2),
          variationDiff: this.cpiVariationDiff,
          variationBetter: this.source.cpiDiffIsBetter,
          tooltip:
            'Cost per acquired paid user (excluding organic downloads). A high CPI means few users were acquired, therefore the in-game metrics cannot be considered as reliable data.',
          displayValue: `$${parseFloat(this.source.cpiDiff).toFixed(2)}`,
          unit: '$',
          unitBefore: true,
        },
        ...(this.game.platform !== 'ios' || this.section.title === 'global'
          ? [
            {
              name: 'Retention D1',
              value: parseFloat(this.source.d1Ret * 100).toFixed(0),
              variationDiff: this.d1VariationDiff,
              variationBetter: this.source.d1RetDiffIsBetter,
              tooltip:
              'The percentage of users that launch the game on the next day after install.',
              unit: '%',
            },
            {
              name: 'Cumulative D3 Playtime',
              value: this.source.d3PlaytimeHumanReadable,
              variationDiff: this.source.d3PlaytimeDiff,
              varationDisplayValue: this.timeFormatter(
                this.source.d3PlaytimeDiff,
              ),
              variationBetter: this.source.d3PlaytimeDiffIsBetter,
              tooltip:
                  'Total playtime for New Users over the first four days after install - from day 0 to day 3.',
              displayValue: this.source.d3PlaytimeHumanReadable,
            },
          ]
          : []),
      ];
    },
    kpiSmall() {
      return [
        ...(this.canViewCPNU
          ? [
            {
              name: 'CPNU',
              value: parseFloat(this.source.cpnu).toFixed(2),
              color: '#ffcb00',
              tooltip:
                  'Ad network spends / total new users (including organic downloads), in the US.',
              icon: 'filter',
              unit: '$',
              unitBefore: true,
            },
          ]
          : []),
        {
          name: `${this.section.title} gender split`,
          value: (this.source.shareOfMales * 100).toFixed(0),
          unit: '% males',
          icon: 'venus-mars',
          color: '#7fe2a5',
          tooltip:
            'This data is directly retrieved from the ad network. It provides an appreciation of how niche your prototype is. The evolution of the split can be relevant over several iterations.',
        },
        {
          name: `${this.section.title} install rate`,
          value: (
            (this.source.fbInstalls / this.source.linkClicks)
            * 100
          ).toFixed(0),
          unit: '%',
          icon: 'percent',
          color: '#57e6d7',
          tooltip:
            'App installs / link clicks. This is a secondary metric, that you can optimize with a convincing icon and upload engaging videos rather than screenshots.',
        },
        {
          name: 'Total new users',
          value: this.source.newUsers,
          color: '#8674fe',
          tooltip:
            'The total number of users launching the game for the first time.',
          icon: 'user-plus',
        },
        {
          name: 'Daily playtime',
          value: this.source.playtimeHumanReadable,
          color: '#57e6d7',
          tooltip:
            'The average duration spent by a user playing the game over 24h.',
          icon: 'play',
        },
        {
          name: 'Retention D7',
          value: parseFloat(this.source.d7Ret * 100).toFixed(0),
          color: '#66c8ff',
          tooltip:
            'The percentage of users that launch the game 7 days after install.',
          icon: 'infinity',
          unit: '%',
        },
      ];
    },
    videosList() {
      const list = this.game.iterations.map(iteration => iteration.videos);

      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        // eslint-disable-next-line no-loop-func
        list[i] = element.map((video) => {
          let adnetwork = null;
          for (let j = 0; j < this.game.iterations.length; j += 1) {
            const iteration = this.game.iterations[j];
            // eslint-disable-next-line no-restricted-syntax
            for (const property in iteration.adNetworks) {
              // eslint-disable-next-line no-prototype-builtins
              if (iteration.adNetworks.hasOwnProperty(property)) {
                // const el = iteration.adNetworks;
                if (
                  iteration.adNetworks[property].videos.find(
                    x => x.id === video.id,
                  )
                ) {
                  adnetwork = property;
                }
              }
            }
          }
          return {
            ...video,
            adNetwork: adnetwork,
          };
        });
      }
      return list.slice().reverse();
    },
    chartsData() {
      if (Object.keys(this.source.studioData).length === 0) return null;
      const data = cloneDeep(this.source.studioData);
      if (this.source.cpi && this.source.studioData.cpi) {
        data.cpi.average = parseFloat(this.source.cpi).toFixed(2);
      }
      if (this.source.d1Ret && this.source.studioData.d1) {
        data.d1.average = parseFloat(this.source.d1Ret * 100).toFixed(0);
      }
      if (this.source.cpnu && this.source.studioData.cpnu) {
        data.cpnu.average = parseFloat(this.source.cpnu).toFixed(2);
      }

      if (
        this.source.d3PlaytimeHumanReadable
        && this.source.studioData.d3Playtime
      ) {
        data.d3Playtime.average = this.source.d3PlaytimeHumanReadable;
      }

      return data;
    },
    canViewCPNU() {
      return this.myPermissions.includes('view_cpnu');
    },
  },
  mounted() {
    window.onresize = () => {
      this.checkUnder960();
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    checkUnder960: throttle(function () {
      this.isUnder960 = window.innerWidth < 960;
    }, 300),
    changeSection(section) {
      this.section = section;
    },
    getData(data, index) {
      if (!Array.isArray(data)) return this.source(index)[data];

      const desiredData = {};
      data.forEach((element) => {
        desiredData[element] = this.source(index)[element];
        if (data === 'videos') {
          desiredData[element].adNetwork = this.section;
        }
      });
      return desiredData;
    },
    smallKpiColor(date, color) {
      return this.dateIsInThePast(new Date(date)) ? '#adadad' : color;
    },
    timeFormatter(time) {
      let totalSeconds = Math.abs(time);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      minutes = String(minutes).padStart(2, '0');
      hours = String(hours).padStart(2, '0');
      seconds = String(seconds).padStart(2, '0');
      return `${hours > 0 ? `${hours}h` : ''}${
        minutes > 0 ? `${minutes}m` : ''
      }${parseFloat(seconds).toFixed(0)}s`;
    },
    dateIsInThePast(date) {
      return moment(date).isBefore(moment());
    },
    formatProtoScore(iteration, adNetwork) {
      if (adNetwork !== 'global') {
        return [
          {
            label: adNetwork,
            value: iteration.adNetworks[adNetwork].score,
          },
        ];
      }
      return Object.entries(iteration.adNetworks).map(adNetworkEntry => ({
        label: adNetworkEntry[0],
        value: adNetworkEntry[1].score,
      }));
    },
  },
};
</script>

<style lang="scss" module>
.iterationContainer {
  border-radius: 16px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: $spacing * 2;
}

.kpiContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "kpibig"
    "kpismall";
  margin-top: $spacing * 3;
}

.kpibigContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: kpibig;
  text-align: left;

  > div {
    padding: 0 $spacing * 4;
    padding-bottom: $spacing * 4;
    border-right: 1px solid $foggy-grey;
    border-bottom: 1px solid $foggy-grey;
    position: relative;
  }

  > div:nth-last-of-type(1) {
    border-right: 0px;
  }
}

.chartCTAContainer {
  position: absolute;
  top: 12px;
  right: $spacing * 3;
  cursor: pointer;
  color: $light-blue;
  display: flex;
  align-items: center;
  transition: color 300ms;
  font-size: 12px;
  font-weight: 600;
}

.chartCTAContainer:hover {
  color: $intense-blue;
}

.chartsCTAIcon {
  font-size: 16px;
  margin-right: $spacing;
}

.kpismallContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: kpismall;
  border-bottom: 1px solid $foggy-grey;
    @media screen and (max-width: 960px) {
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr))
    }
}

.dataContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas: "kpismall";
}

.kpismall {
  border-right: 1px solid $foggy-grey;
  padding: $spacing * 3 $spacing * 4;
  padding-right: $spacing;
}

.data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: kpismall;
}

.kpismall:nth-last-of-type(1) {
  border-right: 0px;
}

.kpiBig {
  margin-bottom: $spacing * 1.5;
}

.videoList {
  margin-top: $spacing * 4;
}

.noDataIosContainer {
  font-size: 14px;
  font-weight: 600;
  color: $grey;
  display: flex;
  align-items: flex-end;
}
.noDataIosContent {
  font-size: 14px;
  font-weight: 600;
  color: $grey;
  display: flex;
  align-items: center;
}

.noDataIcon {
  margin-right: $spacing * 3;
  font-size: 20px;
}

.videoListContainer {
  padding: 0 $spacing * 4;
}
</style>

import { render, staticRenderFns } from "./VideosTableList.vue?vue&type=template&id=0fe07a6e&scoped=true"
import script from "./VideosTableList.vue?vue&type=script&lang=js"
export * from "./VideosTableList.vue?vue&type=script&lang=js"
import style0 from "./VideosTableList.vue?vue&type=style&index=0&id=0fe07a6e&prod&lang=scss&scoped=true"
import style1 from "./VideosTableList.vue?vue&type=style&index=1&id=0fe07a6e&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0fe07a6e",
  null
  
)

export default component.exports
<template>
  <div class="mobile-menu">
    <div class="link">
      <ul>
        <li>
          <div class="logo">
            <!-- <el-image
              class="preview"
              style="max-width:200px;
                border-radius: 5px;
                min-width: 30px;"
              :src="logo"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>-->
          </div>
        </li>
        <li :class="{ active: routeName === 'home' }" @click="goto('home')">
          Games
        </li>
        <li :class="{ active: routeName === 'studio' }" @click="goto('studio')">
          Studios
        </li>

        <!-- <li :class="{ active: sub === 'mygames' }">
          Guidelines
        </li>-->
        <!-- <li :class="{ active: sub === 'mygames' }">
          Help center
        </li>-->

        <li>
          <CreateGameButton @game-created="closeMenu"></CreateGameButton>
        </li>
        <li @click="closeMenu">
          <div class="close">
            <el-image
              class="preview"
              style="width: 50px;
                border-radius: 5px;
"
              :src="close"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
            <span>Close</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CreateGameButton from '@/components/CreateGameButton.vue';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import logo from '../assets/images/logoVoodoo@3x.png';
import close from '../assets/images/closeButtonBlue@3x.png';

export default {
  name: 'MobileMenu',
  components: { CreateGameButton },
  props: {},
  data() {
    return {
      logo,
      close,
      gameIcon,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    isDedicatedAdAccountGame() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    goto(name) {
      this.$router.push({
        name,
      });
      this.closeMenu();
    },

    closeMenu() {
      this.$emit('visibility');
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 150;
  // align-items: stretch;
  // height: 100%;

  .link,
  ul {
    height: 100%;
    font-weight: bold;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 30px;
    li {
      color: rgb(77, 79, 92);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      &:hover {
        color: rgb(6, 123, 240);
        cursor: pointer;
        font-weight: bold;
      }
      &.active {
        color: rgb(6, 123, 240);
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          width: 55px;
          height: 3px;
          margin-top: 30px;
          background-color: rgb(6, 123, 240);
        }
      }
      .logo {
        height: 100%;
        width: 150px;
        background-image: url("~@/assets/images/logoVoodoo@3x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .close {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        span {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="sort-mobile-view">
    <ul>
      <li
        v-if="sortName !== 'date' || (sortName === 'date' && order !== 'desc')"
        @click="sortByDate"
      >
        <span>Sort by newest campaign</span>
      </li>
      <li
        v-if="sortName !== 'date' || (sortName === 'date' && order !== 'asc')"
        @click="sortByDateAsc"
      >
        <span>Sort by oldest campaign</span>
      </li>
      <li v-if="sortName !== 'cpi' && showKpiCpi" @click="sortByCPI">
        <span>Sort by best CPI</span>
      </li>
      <li v-if="sortName !== 'D1' && showKpiCpi" @click="sortByD1">
        <span>Sort by best D1</span>
      </li>
      <li v-if="sortName !== 'playtime' && showKpiCpi" @click="sortByPlaytime">
        <span>Sort by best Playtime</span>
      </li>
      <li v-if="sortName !== 'cpm' && sub === 'ks'" @click="sortByCpm">
        <span>Sort by best CPM</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'SortMobileView',
  components: {
    // GamePage,
    // StudioPage,
  },
  props: {
    filter: {
      type: String,
      default: 'in_progress',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {};
  },
  computed: {
    sub() {
      return this.$route.params.sub || 'home';
    },
    sortName() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      // const filter = cloneDeep(this.filter);

      if (params[this.filter].sort === 'createdAt') return 'date';
      if (params[this.filter].sort === 'lastCpi') return 'cpi';
      if (params[this.filter].sort === 'lastD1') return 'D1';
      if (params[this.filter].sort === 'lastPlaytime') return 'playtime';
      return 'D7';
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params[this.filter].sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params[this.filter].order;
    },
    showKpiCpi() {
      if (
        this.type === 'cpi'
        && this.filter !== 'in_progress'
        && this.filter !== 'pending_approval'
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route() {
      this.updateView();
    },
  },
  mounted() {},
  events: {},
  methods: {
    updateView() {},
    sortByDate() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'createdAt',
        order: 'desc',
        sub: this.sub,
      });
      this.$emit('close');
    },
    sortByDateAsc() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'createdAt',
        order: 'asc',
        sub: this.sub,
      });
      this.$emit('close');
    },
    sortByD1() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'lastD1',
        order: 'desc',
        sub: this.sub,
      });
      this.$emit('close');
    },
    sortByCPI() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'lastCpi',
        order: 'asc',
        sub: this.sub,
      });
      this.$emit('close');
    },
    sortByPlaytime() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'lastPlaytime',
        order: 'desc',
        sub: this.sub,
      });
      this.$emit('close');
    },
    sortByCpm() {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: this.filter,
        sort: 'lastCpm',
        order: 'asc',
        sub: this.sub,
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.sort-mobile-view {
  width: 100%;
  li {
    padding: 20px 10px;
    text-align: left;
    font-size: 15px;

    &:hover {
      background-color: #f3f8fd;
      cursor: pointer;
    }
  }
  @media (min-width: 700px) {
    display: none;
  }
}
</style>

<template>
  <div class="container-password-reset">
    <div class="header">
      <img class="logo" :src="logoVoodoo" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import logoVoodoo from '@/assets/images/voodoo-black.svg';

export default {
  name: 'PasswordReset',
  data() {
    return {
      logoVoodoo,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-password-reset {
  height: 100%;
  width: 360px;
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  padding: 20px;
  @media (max-width: 700px) {
    margin: 20px auto;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    .logo {
      width: 150px;
      padding: 20px;
    }
  }
}
</style>


import axios from '@/tools/axios';

export default class QuotesService {
  static async fetchQuotes() {
    await new Promise(resolve => setTimeout(resolve, 1000));

    return axios.get('https://breaking-bad-quotes.herokuapp.com/v1/quotes/15')
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      }).catch(() => null);
  }

  static async addQuotes() {
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
}

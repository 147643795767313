<template>
  <div class="game-tags-inputs-fields">
    <div v-for="category in tagsCategories" :key="category.id" class="tags-section">
      <div ref="tagsInstructions" class="tags-info" >
        <div class="title-container">
          <span class="type">{{category.label}}</span> <el-tooltip placement="top">
            <div slot="content">
              {{category.tooltip}}
            </div>
            <img width="16" height="16" src="@/assets/images/info-icon.svg">
          </el-tooltip>
        </div>
        <span class="infos">{{category.infos[0]}}</span>
        <span class="infos">{{category.infos[1]}}</span>
      </div>
      <div class="input-container">
        <InputTags
          v-model="tagsModel[category.id]"
          data-test-id="gameSubmissionTagInputsWrapper"
          :reference="category.id"
          :can-edit="canEditGameTags"
          :disabled="disabled[category.id]"
          :result="result"
          :placeholder="category.placeholder"
          class="tagsInput"
          :is-search-result-on-top="category.isSearchResultOnTop"
          @add="(arg)=>addTag(arg,category.id)"
          @delete="(arg)=>deleteTag(arg,category.id)"
          @search="(arg)=>searchTags(arg,category.id)"
          @error="handleTagsError"/>
        <div class="label-example">
          <span class="title-example">Examples:</span>
          <span class="tags-example">{{category.examples.toString().replace(/[,]/gm, ', ')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputTags from '@/components/InputTags';
import tagsMixin from '@/components/GameSubmissionForm/GameTagsInputs/mixin';

export default {
  name: 'GameTagsInputs',
  components: {
    InputTags,
  },
  mixins: [tagsMixin],
  props: {
    game: {
      type: Object,
      default: null,
    },
    result: {
      type: Array,
      default: null,
    },
    canEditGameTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isTagsError: false,
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canEditTags() {
      return this.myPermissions.includes('tag_games');
    },
  },
  watch: {
    isTagsReadyToSubmit: {
      handler(value) {
        this.$emit('ready', value);
      },
      deep: true,
    },
  },
  mounted() {
    this.$emit('ready', this.isTagsReadyToSubmit);
  },
  methods: {
    searchTags({ tag }, category) {
      this.$emit('search', {
        label: tag,
        category,
      });
    },
    deleteTag(tag, category) {
      if (this.canEditGameTags) {
        this.$emit('delete', {
          label: tag,
          category,
        });
        this.sendTagsArray();
      }
    },
    addTag(tag, category) {
      this.$emit('add', {
        label: tag,
        category,
      });
      this.sendTagsArray();
    },
    handleTagsError() {
      this.$emit('handleTagsError');
      this.isTagsError = true;
    },
    sendTagsArray() {
      const arrayOfTagsToSend = [];
      Object.keys(this.tagsModel).forEach((key) => {
        this.tagsModel[key].forEach((tag) => {
          arrayOfTagsToSend.push({
            label: tag,
            category: key,
          });
        });
      });
      this.$emit('tags', arrayOfTagsToSend);
    },
  },
};
</script>

<style lang="scss" scoped>
.game-tags-inputs-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tagsError {
  color: $red-alert !important;
}
.tags-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:5px;
  .title-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .type {
    color: $slate;
    font-size: 12px;
    margin-right: 10px;
  }
  .infos{
    color:$grey;
    font-size: 12px;
  }
}
.label-example{
  margin-left: 20px;
  margin-bottom:20px;
  @media screen and (max-width: 700px) {
    margin-left: 20px;
  }
}
.tags-section {
  .input-container{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    margin-left: -20px;
    width: 100%;
    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    ::v-deep {
      .inputTags {
        background-color: white;
      }
      .input-new-tag {
        max-width: none;
      }
      .wrapper {
        border-bottom: none;
        width: 100%;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 0px;
      }
    }

    .title-example{
      font-size: 12px;
      color:$soft-black;
    }
    .tags-example{
      font-size: 12px;
      color:$grey;
      margin-left: 5px;
    }
  }
}
</style>

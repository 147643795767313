<template>
  <div :class="$style.wrapper" class="facebook-decryption-key-container hyphen">
    <span class="label">
      Enter your Install Referrer Decryption Key below:
    </span><br>
    <el-input
      id="decryptionKey-input"
      v-model="FacebookDecryptionKey"
      :disabled="disabled"
      class="facebook-decryption-key"
      size="small"
      :placeholder="'e.g. 682ed54c1ea9f870e6aadf7830138d1218948e29ed248b32fd88ebc6f62cfd21'"
      data-test-id="gameSubmissionFacebookKey"
      @blur="updateFacebookDecryptionKey"
      @change="update = true"
      @focus="focus = true"
    ></el-input>
    <br>
    <p v-if="!isValidFacebookDecryptionKey && (update || focus)" class="error">
      Valid Decryption Keys are 64 alphanumeric characters long
    </p>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import store from '@/vuex';

export default {
  name: 'ContentfulFacebookDecryptionKey',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    decryptionKey: {
      type: String,
      default: null,
    },
    gameId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      FacebookDecryptionKey: this.decryptionKey,
      update: false,
      focus: false,
    };
  },
  computed: {
    isValidFacebookDecryptionKey() {
      const globalRegex = /^[a-f0-9]{64}$/;
      return !this.FacebookDecryptionKey ? true : globalRegex.test(this.FacebookDecryptionKey);
    },
  },
  watch: {
    FacebookDecryptionKey() {
      this.$emit('decryptionKeyIsValid', this.isValidFacebookDecryptionKey && !this.focus);
    },
  },
  mounted() {
    this.$emit('decryptionKeyIsValid', this.isValidFacebookDecryptionKey && !this.focus);
  },
  methods: {
    async updateFacebookDecryptionKey() {
      this.focus = false;
      if (this.isValidFacebookDecryptionKey) {
        try {
          store.dispatch('ActionToDispatch/updateGameProperty', {
            id: this.gameId,
            adNetworks: { facebook: { decryptionKey: this.FacebookDecryptionKey } },
            loading: 'property',
          });
          store.dispatch('ActionToDispatch/refreshGame', this.gameId);
          this.$emit('decryptionKeyIsValid', this.isValidFacebookDecryptionKey);
        } catch (e) {
          Notification.error({
            title: 'Error',
            message: 'An error occured',
            position: 'bottom-left',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.facebook-decryption-key-container {
  .facebook-decryption-key {
    max-width: 400px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .label {
    color: $slate;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .error {
    font-size: 12px;
    color: $blood-normal;
  }
}
</style>

<style lang="scss" module>
.wrapper {
  input {
    border-radius: 8px;
  }
}
</style>

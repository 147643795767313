import { currentLocale } from './i18n';

const DEFAULT_UNKNOWN = 'n/a';

const localeDate = (argDate, options = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
}) => {
  if (!argDate) {
    return DEFAULT_UNKNOWN;
  }

  try {
    const date = !(argDate instanceof Date) ? new Date(argDate) : argDate;

    return date.toLocaleDateString(currentLocale.locale, options);
  } catch (err) {
    return argDate;
  }
};

const localeNumericDate = argDate => localeDate(argDate, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const localeDateTime = (argDate, options = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
}) => {
  if (!argDate) {
    return DEFAULT_UNKNOWN;
  }

  try {
    const date = !(argDate instanceof Date) ? new Date(argDate) : argDate;

    return date.toLocaleString(currentLocale.locale, options);
  } catch (err) {
    return argDate;
  }
};

export default {
  localeDate,
  localeNumericDate,
  localeDateTime,
};

<template>
  <div :class="$style.wrapper">
    <div :class="$style.container" :v-if="studioInfos.hubspotUrl">
      <HubSpotButton
        :size="40"
        :url="studioInfos.hubspotUrl"/>
      <span :class="$style.label">Hubspot</span>
    </div>

  </div>
</template>

<script>
import HubSpotButton from '@/modules/Studio/components/HubSpotButton.vue';

export default {
  name: 'StudioOptionMobile',
  components: {
    HubSpotButton,
  },
  computed: {
    studioInfos() {
      return this.$store.getters['studios/studioInfos'] || {};
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
}
.label {
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;

    padding: 10px;
}
</style>

<template>
  <div>
    <div :class="$style.descriptionWrapper">
      <p :class="$style.subtitle">
        Are you sure you want to deactivate this studio?
      </p>
      <p>
        All the members will lose access to the Publishing Platform, Voodoo
        Academy and Zendesk
      </p>
    </div>
    <div slot="footer" :class="$style.footer">
      <el-button size="medium" data-test-id="studioPageDeactivationCancelButton" @click="cancel"> Cancel </el-button>
      <el-button
        type="danger"
        size="medium"
        data-test-id="studioPageDeactivationConfirmButton"
        @click="confirm">
        Confirm
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeactivationConfirmation',
  methods: {
    cancel() {
      this.$emit('close');
    },
    async confirm() {
      this.$emit('deactivate');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.descriptionWrapper {
  font-size: 12px;
  color: $slate;
  padding: 0 20px;
  margin: $spacing * 4 0;
  text-align: center;

  > p {
    line-height: 17px;
    font-size: 12px;
    word-break: normal;
  }
}

.subtitle {
  font-weight: 600;
  margin-bottom: $spacing;
}

.footer {
  padding: 0 20px;
  padding-bottom: $spacing * 4;
  display: flex;
  justify-content: flex-end;
}
</style>

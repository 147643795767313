<template>
  <div class="form">
    <div v-if="this.$slots.header" class="header">
      <slot name="header"></slot>
    </div>
    <div  v-if="!isFormBeingSubmitted && !isFormSubmissionSuccessfull">
      <div v-if="this.$slots.formTitle" class="box">
        <slot name="formTitle"></slot>
      </div>
      <div v-if="getConfigurationForStep(currentStep).stepTitle" class="box step-title">{{ getConfigurationForStep(currentStep).stepTitle }}</div>
      <div v-for="currentQuestion in getConfigurationForStep(currentStep).questionList" :key="currentQuestion.id" class="box">
        <FormInput v-model="formData[currentQuestion.id]" :question="currentQuestion" />
      </div>
      <FormNavigation
        :nb-steps="currentForm.length"
        :current-step-index="currentStep"
        :can-go-to-next-step="canGoToNextStep"
        @go-to-step="goToStep"
        @submit-form="submitForm"
      />
    </div>
    <div v-if="isFormBeingSubmitted && !isFormSubmissionSuccessfull">
      <div class="box">
        <slot name="formSubmissionInProgress">Your answers are being submitted.</slot>
      </div>
    </div>
    <div v-if="isFormSubmissionSuccessfull">
      <div class="box">
        <slot name="formSubmitted">Your answers have been successfully submitted, thank you.</slot>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/Generic/FormInput.vue';
import FormNavigation from '@/components/Generic/FormNavigation.vue';

export default {
  name: 'Form',
  components: {
    FormInput,
    FormNavigation,
  },
  props: {
    /**
     * Array of object with following properties:
     * - stepTitle: String
     * - questionList: Array of Object (see Input.vue for expected object properties), required
     * - displayCondition: Object with properties { "id": String, "value": String }
     */
    formConfiguration: {
      type: Array,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    isFormSubmissionSuccessfull: {
      type: Boolean,
      default: false,
    },
    forcedCurrentStep: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isFormBeingSubmitted: false,
      currentStep: 0,
    };
  },
  computed: {
    currentForm() {
      return this.formConfiguration.filter(step => this.shouldDisplayStep(step));
    },
    canGoToNextStep() {
      const currentStepMandatoryQuestions = this.getConfigurationForStep(this.currentStep).questionList.filter(currentQuestion => !currentQuestion.optional);
      return currentStepMandatoryQuestions.filter(currentQuestion => !this.formData[currentQuestion.id]).length === 0;
    },
  },
  watch: {
    forcedCurrentStep(newForcedStep, oldForcedStep) {
      if (newForcedStep > -1 && newForcedStep !== oldForcedStep) {
        this.isFormBeingSubmitted = false;
        this.goToStep(newForcedStep);
      }
    },
  },
  methods: {
    goToStep(value) {
      this.currentStep = value;
    },
    async submitForm() {
      this.isFormBeingSubmitted = true;
      this.$emit('submit-form');
    },
    shouldDisplayStep(step) {
      return !step.displayCondition
        || (this.formData[step.displayCondition.id] && this.formData[step.displayCondition.id] === step.displayCondition.value);
    },
    getConfigurationForStep(currentStep) {
      return this.currentForm[currentStep];
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  .header {
    width: 640px;
    margin: 20px auto;
    img {
      width: 640px;
    }
  }
  .box {
    display: block;
    width: 640px;
    margin: 0 auto;
    margin-bottom: 12px;
    padding: 24px;
    line-height: 18px;
    color: #202124;
    background-color: #ffffff;
    border: solid 1px #dadce0;
    border-radius: 8px;
    overflow-wrap: break-word;
    &.step-title {
      color: #fff;
      background-color: #000;
      margin: 5px auto;
      padding: 12px 24px;
      font-size: 16px;
      line-height: 24px;
    }
    .title {
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
  <div>
    <el-dialog
      custom-class="ids-modal"
      :append-to-body="true"
      :title="'Game IDs'"
      :visible="isVisible"
      width="60%"
      data-test-id="myGamesGameIdsModal"
      @close="$emit('close')"
    >
      <GameIds
        :game-object="game"
        @close-pop-in="$emit('close')"
      ></GameIds>
    </el-dialog>
  </div>
</template>

<script>
import GameIds from '@/components/Dialogs/GameIds.vue';

export default {
  name: 'GameIdsModal',
  components: {
    GameIds,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 24px;
  height: 24px;
}

.icon:hover {
  cursor: pointer;
}
</style>

<style lang="scss">
.ids-modal {
  .el-dialog__header {
    background-color: $foggy-blue;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
}
</style>

<template>
  <el-dialog
    custom-class="action-modal"
    :append-to-body="true"
    :title="'Link your campaign'"
    :visible.sync="isVisible"
    width="30%"
    data-test-id="myGamesLinkCampaignModal"
    @close="close"
  >
    <div v-if="!getChoiceLoading" class="formAction">
      <el-select v-model="linkId" filterable style="margin: 10px 0">
        <el-option
          v-for="(c, ind) in choicesLink"
          :key="ind"
          :label="c.label"
          :value="c.id"
          class="no-class"
        ></el-option>
      </el-select>
    </div>
    <div v-else class="loading">
      <i class="el-icon-loading"></i>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">Cancel</el-button>
      <el-button
        type="primary"
        :disabled="getChoiceLoading"
        data-test-id="myGamesLinkCampaignConfirmButton"
        @click="
          updateValueBeforeLinkCampaign(linkId);
          close();
        "
      >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import actionList from '@/static/actionList';

export default {
  name: 'LinkCampaignModal',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      getChoiceLoading: false,
      linkId: '',
      choicesLink: [],
      actionList,
    };
  },
  mounted() {
    this.linkStudioCampaign();
  },
  methods: {
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
    async linkStudioCampaign() {
      const param = {
        id: this.game.id,
        action: 'test_studio_campaign',
      };
      this.isVisible = true;
      this.getChoiceLoading = true;
      // eslint-disable-next-line no-await-in-loop
      const rep = await this.$store.dispatch(
        'games/getAvailableChoiceForASpecificAction',
        param,
      );
      this.getChoiceLoading = false;

      if (rep) {
        this.linkId = rep.current_id;
        this.choicesLink = rep.choices.facebook;
      }
    },
    updateValueBeforeLinkCampaign(linkId) {
      if (this.actionList) {
        let action = this.actionList.filter(
          el => el.id === 'test_studio_campaign',
        )[0];
        const post = {
          post: {
            format: 'application/json',
            field: [
              {
                type: 'Object',
                param: 'choice',
                deepParam: ['facebook'],
                value: linkId,
              },
            ],
          },
        };
        action = {
          ...action,
          ...post,
        };
        const param = {
          id: this.game.id,
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
      }
    },
  },
};
</script>

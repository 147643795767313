/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="views-selector">
    <el-select
      size="mini"
      class="view-select"
      filterable
      clearable
      :value="currentView.name"
      popper-class="select-view"
      placeholder="Saved views"
      @clear="resetView()"
      @change="selectView"
    >
      <i slot="prefix" class="vd-svg-saved-filters-dark"></i>
      <el-option-group v-for="item in views" :key="item.label" :label="item.label">
        <el-option
          v-for="el in item.options"
          :key="el.id"
          :label="el.name"
          :value="el"
          :value-key="el.id">
          <span style="float: left">{{ el.name }}</span>
          <span v-if="userId === el.creator.id && !el.isDefault" style="float: right; color: #8492a6; font-size: 13px">
            <i class="el-icon-delete" @click.stop="deleteView(el.id)" />
          </span>
        </el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'ViewSelector',
  components: {},
  data() {
    return {};
  },
  computed: {
    preSavedViews() {
      const defaultViews = cloneDeep(this.$store.getters['views/preSavedViews']);
      for (let i = 0; i < defaultViews.length; i += 1) {
        const element = defaultViews[i];
        if (element.id === 'my-games') {
          defaultViews.splice(i, 1);
          i -= 1;
        }
      }
      return defaultViews;
    },
    userId() {
      // return 743;
      return this.$store.getters['users/id'];
      // {label: "Loic Moisan", value: 2283}{label: "alex shea", value: 743} topa : 2431
    },
    userName() {
      return this.$store.getters['users/userName'];
    },
    isPm() {
      for (let i = 0; i < this.pmList.length; i += 1) {
        const element = this.pmList[i];
        if (element.value === this.userId) {
          return true;
        }
      }
      if (this.userId === 2839 || this.userId === 2283) {
        return true;
      }
      return false;
    },
    pmList() {
      return this.codification
        ? cloneDeep(this.codification.publishingManagers)
        // .filter((el) => {
        //   if (listPm.includes(el.label)) {
        //     return true;
        //   }
        //   return false;
        // })
          .sort((a, b) => (a.label < b.label ? -1 : 1))
        : [];
    },
    views() {
      const myViews = [];
      const othersViews = [];
      const listView = this.$store.getters['views/views'];
      const final = [];

      for (let i = 0; i < listView.length; i += 1) {
        const element = listView[i];
        if (element.creator.id === this.userId) {
          myViews.push(element);
        } else {
          othersViews.push(element);
        }
      }

      const presaved = this.preSavedViews;
      const preSaved = {
        label: 'Default views',
        options: presaved,
      };

      const myOption = {
        label: 'My views',
        options: myViews,
      };
      const pmOption = {
        label: 'PM views',
        options: this.pmViews,
      };

      final.push(pmOption);

      final.push(preSaved);
      final.push(myOption);
      if (this.userId === 2839) {
        const othersOption = {
          label: 'Others views',
          options: othersViews,
        };
        final.push(othersOption);
      }
      return final;
    },
    currentView() {
      const current = cloneDeep(this.$store.getters['views/currentView']);
      if (current.id === 'my-games') {
        let contentString = JSON.stringify(current.content);
        contentString = contentString.replace(/PMNAMETEMPLATE/g, 'Theophile Tabary');
        current.content = JSON.parse(contentString);
      }
      return current;
    },

    pmViews() {
      const defaultViews = cloneDeep(this.$store.getters['views/preSavedViews']);
      const pmviews = [];
      for (let i = 0; i < defaultViews.length; i += 1) {
        const element = defaultViews[i];
        if (element.id === 'my-games') {
          // if (this.isPm) {
          element.content = element.content.replace(/PMNAMETEMPLATE/g, this.userName);
          pmviews.push(element);
          // }
        }
      }
      return pmviews;
    },
  },
  watch: {
    $route: {
      handler() {
        this.updateView();
        if (Object.keys(this.$route.query).length === 0) {
          this.$store.dispatch('views/resetView');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateView();
  },
  methods: {
    async updateView() {
      if (this.$route.query.view) {
        if (this.$route.query.view === 'my-games') {
          const defaultViews = cloneDeep(this.$store.getters['views/preSavedViews']);
          for (let i = 0; i < defaultViews.length; i += 1) {
            const element = defaultViews[i];
            if (element.id === this.$route.query.view) {
              element.content = element.content.replace(/PMNAMETEMPLATE/g, this.userName);
              // eslint-disable-next-line no-await-in-loop
              await new Promise(resolve => setTimeout(resolve, 500));
              this.$store.dispatch('views/setCurrentView', element);
              // this.$store.dispatch('views/setViewById', element);
            }
          }
        } else {
          this.$store.dispatch('views/getAllViews', this.$route.query.view);
        }
      }
      if (this.$route.query.customView && this.$route.query.customId) {
        this.$store.dispatch('views/setCustomView', {
          customView: this.$route.query.customView,
          customId: this.$route.query.customId,
        });
      }
    },
    selectView(value) {
      if (value) {
        if (value.id === 'my-games') {
          const defaultViews = cloneDeep(this.$store.getters['views/preSavedViews']);
          for (let i = 0; i < defaultViews.length; i += 1) {
            const element = defaultViews[i];
            if (element.id === value.id) {
              element.content = element.content.replace(/PMNAMETEMPLATE/g, this.userName);
              this.$store.dispatch('views/setCurrentView', element);
            }
          }
        } else {
          this.$store.dispatch('views/setCurrentView', value);
        }
        if (this.$route.query.view !== value.id) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$router.push({
            query: {
              view: value.id,
              game: this.$route.query.game,
            },
          });
        }
      }
    },
    deleteView(id) {
      this.$store.dispatch('views/deleteView', id);
      if (id === this.currentView.id) {
        this.resetView();
      }
    },
    resetView() {
      this.$store.dispatch('views/resetView');
      this.$router
        .push({
          query: {
            game: this.$route.query.game,
            view: undefined,
            customView: undefined,
            customId: undefined,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.views-selector {
  .view-select {
    width: 100%;
  }
}
//
</style>

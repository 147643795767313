/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import ViewService from '@/services/views.services';
// import column from '@/../json/GamesListColumns.json';
import preSavedViews from '@/../json/PreSavedViewsStudio.json';
import simpleContent from '@/../json/simpleContentStudio';

const viewsStudio = {
  namespaced: true,
  state: {
    views: [],
    lastView: {
      id: null,
      name: '',
      description: '',
      content: simpleContent,
      type: 'studio',
    },
    currentView: {
      id: null,
      name: '',
      description: '',
      content: simpleContent,
      type: 'studio',
    },
    preSavedViews,
    loading: false,
    canSave: false,
  },
  getters: {
    term(state) {
      return state.currentView.content.term;
    },
    filter(state) {
      return state.currentView.content.filter;
    },
    columns(state) {
      const col = cloneDeep(state.currentView.content.columns);
      return col;
    },
    best(state) {
      return state.currentView.content.best;
    },
    sort(state) {
      return state.currentView.content.sort;
    },
    views(state) {
      return state.views;
    },
    preSavedViews(state) {
      return state.preSavedViews;
    },
    currentView(state) {
      return state.currentView;
    },
    lastView(state) {
      return state.lastView;
    },
    loading(state) {
      return state.loading;
    },
    canSave(state) {
      return JSON.stringify(state.lastView) !== JSON.stringify(state.currentView);
    },
    viewExist(state) {
      if (state.currentView.id !== null) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    set_term(state, term) {
      const current = cloneDeep(state.currentView);
      const { content } = current;
      content.term = term;
      current.content = content;
      state.currentView = current;
    },
    set_filter(state, filter) {
      const current = cloneDeep(state.currentView);
      const { content } = current;
      content.filter = filter;
      current.content = content;
      state.currentView = current;
    },
    set_columns(state, columns) {
      const current = cloneDeep(state.currentView);
      const { content } = current;
      content.columns = columns;
      current.content = content;
      state.currentView = current;
    },
    set_best(state, best) {
      const current = cloneDeep(state.currentView);
      const { content } = current;
      content.best = best;
      current.content = content;
      state.currentView = current;
    },
    set_sort(state, sort) {
      const current = cloneDeep(state.currentView);
      const { content } = current;
      content.sort = sort;
      current.content = content;
      state.currentView = current;
      // state.sort = sort;
    },
    add_new_view(state, view) {
      const allview = cloneDeep(state.views);
      allview.push(view);
      state.views = allview;
      const newView = cloneDeep(view);
      newView.content = cloneDeep(JSON.parse(newView.content));
      state.currentView = newView;
      state.lastView = newView;
    },
    set_views(state, allViews) {
      const newArray = [];
      for (let i = 0; i < allViews.length; i += 1) {
        const element = allViews[i];
        const cont = JSON.parse(element.content);
        if (cont.type === 'studios') {
          newArray.push(element);
        }
      }
      state.views = newArray;
    },
    set_currentView(state, currentView) {
      const newView = cloneDeep(currentView);
      newView.content = cloneDeep(JSON.parse(newView.content));
      state.currentView = newView;
      state.lastView = newView;
      for (let i = 0; i < state.views.length; i += 1) {
        const element = state.views[i];
        if (element.id === currentView.id) {
          state.views.splice(i, 1, currentView);
          break;
        }
      }
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    delete_view(state, id) {
      const allViews = cloneDeep(state.views);
      for (let i = 0; i < allViews.length; i += 1) {
        const element = allViews[i];
        if (element.id === id) {
          allViews.splice(i, 1);
          i += -1;
        }
      }
      state.views = allViews;
    },
    reset_currentView(state) {
      state.currentView = {
        id: null,
        name: '',
        description: '',
        content: simpleContent,
      };
      state.lastView = {
        id: null,
        name: '',
        description: '',
        content: simpleContent,
      };
    },
    set_by_id_currentView(state, id) {
      const allViews = [].concat(state.views).concat(state.preSavedViews);
      for (let i = 0; i < allViews.length; i += 1) {
        const element = allViews[i];
        if (element.id === id) {
          const newView = cloneDeep(element);
          newView.content = cloneDeep(JSON.parse(newView.content));
          state.currentView = newView;
          state.lastView = newView;
        }
      }
    },
  },
  actions: {
    setTerm(context, term) {
      context.commit('set_term', term);
    },
    setFilter(context, filter) {
      context.commit('set_filter', filter);
    },
    setColumns(context, columns) {
      context.commit('set_columns', columns);
    },
    setBest(context, best) {
      context.commit('set_best', best);
    },
    setSort(context, sort) {
      context.commit('set_sort', sort);
    },
    setCurrentView(context, view) {
      context.commit('set_currentView', view);
    },
    resetView(context) {
      context.commit('reset_currentView');
    },
    setViewById(context, id) {
      context.commit('set_by_id_currentView', id);
    },

    async getAllViews(context, idParamater) {
      context.commit('set_loading', true);
      const allview = await ViewService.getAllViewsStudio();
      context.commit('set_loading', false);
      if (allview) {
        context.commit('set_views', allview);
        if (idParamater) {
          await new Promise(resolve => setTimeout(resolve, 500));
          context.commit('set_by_id_currentView', idParamater);
        }
      }
    },
    async saveNewView(context, view) {
      context.commit('set_loading', true);
      const response = await ViewService.createNewView(view);
      context.commit('set_loading', false);
      if (response && response.id) {
        context.commit('add_new_view', response);
      }
    },
    async getView(context, view) {
      context.commit('set_loading', true);
      const response = await ViewService.getView(view);
      context.commit('set_loading', false);
      if (response) {
        context.commit('set_currentView', response);
      }
    },
    async updateView(context, view) {
      context.commit('set_loading', true);
      const response = await ViewService.updateView(view);
      context.commit('set_loading', false);
      if (response && response.id) {
        context.commit('set_currentView', response);
      }
    },
    async deleteView(context, id) {
      context.commit('set_loading', true);
      const response = await ViewService.deleteView(id);
      context.commit('set_loading', false);
      if (response.deleted) {
        context.commit('delete_view', id);
      }
    },
  },
};

export default viewsStudio;

<template>
  <div :class="$style.container">
    <h2 id="title-linkToStore">
      {{title}}
    </h2>
    <div v-if="cpGame" :class="[$style.wrapper, $style[current === index ? 'isFocus' : '']]">
      <div v-if="!isAccessible" :class="$style.layer"></div>
      <Tabs :disabled="step.isValid">
        <Tab :class="$style.tab" title="Use new IDs" data-test-id="gameSubmissionNewIDTab">
          <LinkNewIds
            :game="cpGame"
            :app-store-id="cpAppStoreId"
            :bundle-id="cpBundleId"
            :project-from-search="searchProjectResult"
            :is-searching="isSearchingProject"
            :is-getting="isGettingProject"
            :project-name-errors="projectNameErrors"
            @cpAppStoreId="setAppStoreIdValue"
            @setAppStoreIdValidity="setAppStoreIdValidity"
            @cpBundleId="setBundleIdValue"
            @setBundleIdValidity="handleBundleId"
            @searchProject="searchProject"
            @setNewProjectName="setNewProjectName"/>
        </Tab>
        <Tab :class="$style.tab" title="Reuse IDs from a past project" data-test-id="gameSubmissionReuseIdTabx">
          <LinkReuseIds
            :game="cpGame"
            @changeBundleIdValue="setBundleIdValue"
            @changeAppstoreValue="setAppStoreIdValue"
            @setBundleIdValidity="handleBundleId"
            @setAppStoreIdValidity="setAppStoreIdValidity"
            @cpAppStoreId="setAppStoreIdValue"
            @getProject="getProject"
          />
        </Tab>
      </Tabs>
      <div :class="$style.additional">
        <GASlot
          :game="cpGame"
          :class="$style.spacingBottom"
          @createGASlot="setCreateGASlot"
          @idChange="setGAId"
        />
        <ImportantNotice
          v-if="warningContent && warningContent.show"
          :class="$style.notice"
          :content="warningContent && warningContent.text"/>
        <el-button
          id="validate-linkToStore"
          data-test-id="gameSubmissionLinkToStoreValidateButton"
          type="primary"
          size="small"
          :loading="loading"
          :disabled="!canValidate"
          @click="validate"
        >
          Validate
        </el-button>
        <div v-if="errorBack.length > 0" :class="$style.errors">
          <ul>
            <li v-for="error in errorBack" :key="error" v-html="error"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';
import tools from '@/tools/tools';
import ImportantNotice from '@/components/Generic/ImportantNotice/ImportantNotice.vue';
import { Tab, Tabs } from '@/components/Tabs';
import GASlot from './GASlot.vue';

import LinkNewIds from './LinkNewIds.vue';
import LinkReuseIds from './LinkReuseIds.vue';

export default {
  name: 'LinkToStore',
  components: {
    GASlot,
    ImportantNotice,
    LinkNewIds,
    LinkReuseIds,
    Tab,
    Tabs,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: 'no name',
    },
    current: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    step: {
      type: Object,
      default: null,
    },
    steps: {
      // alternative: provide / inject
      type: Array,
      default: null,
    },
    warning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cpGame: this.game,
      cpAppStoreId: this.game?.storeId,
      cpBundleId: this.game?.bundleId,
      cpGameAnalyticsId: this.game.gameAnalyticsId,
      isValidCpAppStoreId: true,
      isValidCpBundleId: true,
      createGASlot: true,
      actionList,
      adjustToken: null,
      errorBack: [],
      isIntersecting: false,
      loading: false,
      searchProjectResult: null,
      isSearchingProject: false,
      isGettingProject: false,
      projectNewName: this.game?.name,
      projectNameErrors: [],
    };
  },
  computed: {
    canValidate() {
      const appStoreIdCondition = (this.game.platform === 'ios'
          && this.cpAppStoreId
          && this.isValidCpAppStoreId)
        || this.game.platform === 'android';

      const bundleIdCondition = !!this.cpBundleId && this.isValidCpBundleId;
      const GACondition = this.createGASlot || !!this.cpGameAnalyticsId;

      // This is clearly not a good idea
      // Because the validation is also done in SearchProject.vue
      // But For some reason there is a reactivity issue here
      // And I can't get it to work with events
      const projectNameCondition = !!(
        this.projectNewName.length <= 25 && this.projectNewName.length >= 3
      );

      return (
        appStoreIdCondition
        && bundleIdCondition
        && GACondition
        && projectNameCondition
      );
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
    warningContent() {
      if (!this.warning) {
        return {
          show: false,
          text: '',
        };
      }
      return {
        text: this.warning.linkToStore,
        show: this.warning.linkToStoreWarningIsVisible,
      };
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    tools.attachEvent(
      document.getElementById('container-scrollable'),
      'scroll',
      this.update,
    );
    tools.attachEvent(window, 'resize', this.update);
    if (this.game.projectId) {
      this.getProject();
    }
  },
  methods: {
    setAppStoreIdValue(value) {
      this.cpAppStoreId = value;
    },
    setAppStoreIdValidity(value) {
      this.isValidCpAppStoreId = value;
    },
    setBundleIdValue(value) {
      this.cpBundleId = value;
    },
    handleBundleId(value) {
      this.setBundleIdValidity(value);
    },
    setBundleIdValidity(value) {
      this.isValidCpBundleId = value;
    },
    async getProject(id) {
      this.isGettingProject = true;

      const project = await this.$store.dispatch(
        'Project/getProjectObject',
        id || this.game.projectId,
      );
      this.searchProjectResult = {
        project: {
          name: project.name,
          id: project.id,
          projectGameCount: project.games.length,
          games: project.games,
          icon: project.games[0].iconUrl,
        },
      };
      this.projectNewName = project.name;
      this.isGettingProject = false;
    },
    async searchProject() {
      if (
        !this.cpBundleId
        || (this.game.platform === 'ios' && !this.cpAppStoreId)
      ) {
        this.searchProjectResult = null;
        this.isSearchingProject = false;
        return;
      }
      this.isSearchingProject = true;
      try {
        const searchProjectResult = await this.$store.dispatch(
          'searchProject/search',
          {
            storeId: this.cpAppStoreId || this.cpBundleId,
            bundleId: this.cpBundleId,
            platform: this.cpGame.platform,
          },
        );
        this.searchProjectResult = searchProjectResult.data;
        if (this.cpGame.projectId || searchProjectResult.data.project) {
          this.projectNewName = searchProjectResult.data.project.name;
        }

        this.isSearchingProject = false;
      } catch {
        this.searchProjectResult = null;
        this.isSearchingProject = false;
      }
    },
    setNewProjectName(name) {
      this.projectNewName = name;
    },
    update() {
      const titleElement = document.getElementById('title-linkToStore');
      const isvisible = tools.visibleY(titleElement);
      if (this.isvisible !== isvisible) {
        if (this.isAccessible && isvisible) {
          this.$emit('change', this.index);
        }
        this.isvisible = isvisible;
      }
    },
    attachEvent(element, event, callbackFunction) {
      if (element.addEventListener) {
        element.addEventListener(event, callbackFunction, false);
      } else if (element.attachEvent) {
        element.attachEvent(`on${event}`, callbackFunction);
      }
    },
    isElementVisible(el, holder) {
      const vholder = holder || document.body;
      const { top, bottom, height } = el.getBoundingClientRect();
      const holderRect = vholder.getBoundingClientRect();

      return top <= holderRect.top
        ? holderRect.top - top <= height
        : bottom - holderRect.bottom <= height;
    },
    setCreateGASlot(value) {
      this.createGASlot = value;
    },
    setGAId(value) {
      this.cpGameAnalyticsId = value;
    },
    async validate() {
      this.loading = true;

      await this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
      if (!this.game.bundleId || !this.game.storeId) {
        try {
          await this.setBundleAndStoreId();
        } catch {
          this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
        }
      }

      if (this.createGASlot && !this.game.gameAnalyticsId) {
        try {
          await this.createGaSlotAction();
        } catch {
          await this.$store.dispatch(
            'submitGame/setNextStepLoadingProperty',
            false,
          );
        }
      }

      const hasNewGaId = !this.createGaSlot && !this.game.gameAnalyticsId;
      const hasChangedGaId = this.game.gameAnalyticsId
        && this.cpGameAnalyticsId
        && this.game.gameAnalyticsId !== this.cpGameAnalyticsId;

      if (hasNewGaId || hasChangedGaId) {
        try {
          await this.assignGaSlot(this.cpGameAnalyticsId);
        } catch {
          await this.$store.dispatch(
            'submitGame/setNextStepLoadingProperty',
            false,
          );
        }
      }

      if (!this.game.projectId) {
        this.setProjectId();
      } else if (
        this.projectNewName !== this.searchProjectResult?.project?.name
      ) {
        await this.setProjectName(this.game.projectId);
      }

      if (this.isReadyToNextStep()) {
        if (!this.game.isCtr) {
          this.getAdjustToken();
        }
        await this.$emit('isValid', true);
      }

      await this.$store.dispatch(
        'submitGame/setNextStepLoadingProperty',
        false,
      );
      await new Promise(resolve => setTimeout(resolve, 500));
      this.loading = false;
    },
    async setProjectId() {
      try {
        const projectNotFound = this.searchProjectResult !== null
          && this.searchProjectResult.project === null;

        const projectFound = this.searchProjectResult !== null
          && this.searchProjectResult?.project?.id;

        let projectId;
        if (projectNotFound) {
          projectId = 0;
        } else if (projectFound) {
          projectId = this.searchProjectResult.project.id;
        } else {
          console.error('Test cannot be linked to a project');
          return;
        }

        const setProjectResponse = await this.$store.dispatch(
          'submitGame/updateGame',
          {
            id: this.game.id,
            projectId,
          },
        );

        if (
          this.projectNewName !== this.searchProjectResult?.project?.name
          && projectId !== 0
        ) {
          await this.setProjectName(setProjectResponse.projectId);
        }
      } catch (error) {
        await this.$store.dispatch(
          'submitGame/setNextStepLoadingProperty',
          false,
        );
      }
    },
    async setProjectName(projectId) {
      try {
        const projectResponse = await this.$store.dispatch(
          'Project/editProject',
          {
            id: projectId,
            name: this.projectNewName,
          },
        );
        if (this.game.projectId) {
          await this.getProject();
        } else {
          await this.searchProject();
        }
        if (projectResponse === true) {
          this.projectNameErrors = [];
        } else {
          this.projectNameErrors = [];
          this.projectNameErrors.push(projectResponse);
        }
        return;
      } catch {
        console.error('Edit project name failed');
        await this.$store.dispatch(
          'submitGame/setNextStepLoadingProperty',
          false,
        );
      }
    },
    getAdjustToken() {
      this.$store
        .dispatch('submitGame/getAdjustToken', this.game)
        .then((result) => {
          if (!result.detail) {
            this.adjustToken = result?.data?.adjustAppToken || null;
          }
        });
    },
    isReadyToNextStep() {
      if (this.cpGameAnalyticsId && this.game.storeId && this.game.bundleId && this.projectNameErrors.length === 0) {
        return true;
      }
      return false;
    },
    setBundleAndStoreId() {
      let storeId = '';
      let storeIdLabel = '';
      if (this.game.platform === 'ios') {
        storeId = this.cpAppStoreId;
        storeIdLabel = 'AppStore ID';
      } else {
        storeId = this.cpBundleId;
        storeIdLabel = 'Bundle ID';
      }
      return new Promise(async (resolve) => {
        try {
          await this.$store.dispatch('submitGame/updateGame', {
            id: this.game.id,
            bundleId: this.cpBundleId,
            storeId,
          });
          this.cpGame.storeId = storeId;
          this.cpGame.bundleId = this.cpBundleId;
          resolve(true);
        } catch (error) {
          const errorList = this.codification.errorList;
          const errorStoreIdNotFound = errorList.LINK_TO_STORE_STORE_ID_NOT_FOUND
            ? errorList.LINK_TO_STORE_STORE_ID_NOT_FOUND.message.replace(
              '{{storeId}}',
              storeIdLabel,
            )
            : '';
          this.displayErrorMessage(error, errorStoreIdNotFound);
          resolve(false);
        }
      });
    },
    createGaSlotAction() {
      return new Promise(async (resolve, reject) => {
        try {
          const rep = await this.$store.dispatch(
            'submitGame/pushSimpleAction',
            {
              game: this.game,
              action: 'create_ga_slot',
            },
          );
          this.cpGameAnalyticsId = rep.gameAnalyticsId;
          this.cpGame = {
            ...this.game,
            gameAnalyticsId: rep.gameAnalyticsId,
          };
          resolve();
        } catch (error) {
          const errorList = this.codification.errorList;
          this.displayErrorMessage(
            error,
            errorList.GAME_ANALYTICS_UNEXPECTED_ERROR,
          );
          reject();
        }
      });
    },
    assignGaSlot(value) {
      return new Promise(async (resolve, reject) => {
        const action = this.actionList.filter(
          el => el.id === 'assign_ga_slot',
        )[0];
        action.post.field[0].value = value;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
        const response = await this.$store.dispatch(
          'ActionToDispatch/dispatchAction',
          param,
        );
        this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
        if (response.isSuccess) {
          this.game.gameAnalyticsId = value;
          resolve(true);
        } else {
          const errorList = this.codification.errorList;
          this.errorBack = [errorList.GAME_ANALYTICS_GAME_NOT_FOUND.message];
          reject(response.reason);
        }
      });
    },
    displayErrorMessage(error, defaultError) {
      this.errorBack = [];
      const errorList = this.codification.errorList;
      if (error && error.errorList) {
        this.errorBack = error.errorList.map(err => (errorList[err.code]
          ? errorList[err.code].message
          : defaultError.message));
      }
      if (this.errorBack.every(currentError => currentError === '')) {
        this.errorBack = [errorList.GENERIC_ERROR.message];
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing * 3;
  display: block;
  h2 {
    font-size: 16px;
    padding-bottom: 16px;
    text-align: left;
  }
}
.isFocus {
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.1);
}
.wrapper {
  border-radius: 16px;
  border: solid 1px $foggy-grey;
  text-align: left;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  .layer {
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    z-index: 10;
  }
}

.spacingBottom {
  margin-bottom: $spacing * 3;
}

.errors {
  ul {
    margin-top: $spacing * 3;
    li {
      color: $blood-normal;
      font-size: 11px;
    }
  }
}

.tab {
  padding: 0 $spacing * 3;
  padding-top: $spacing * 3;
}

.additional {
  padding: $spacing * 3;
    padding-top: 0;
}
</style>

<template>
  <div class="buttonNewGame">
    <el-button
      v-if="userHasPermission('add_game')"
      class="submit-game"
      type="primary"
      data-test-id="myGamesEmptyStateCreateTestButton"
      @click="gotoSubmitFlow()">Create a test</el-button>

    <el-dialog
      custom-class="new-game-modal"
      :append-to-body="true"
      title="Create a new test"
      :visible.sync="newGameModal"
      width="40%"
    >
      <div class="formAction">
        <el-input
          id="create-game-input-name"
          ref="create-game-input-name"
          v-model="name"
          type="text"
          placeholder="Insert name"
          maxlength="25"
          @keyup.enter.native="createGame()"
        ></el-input>
        <div class="name-requirement">Name length must be between 3 and 25 characters.</div>
        <ul class="error">
          <li v-for="item in errorName" :key="item[0]" v-html="item[0]"></li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer" @click="autofocus()">
        <el-button @click="newGameModal = false">Cancel</el-button>

        <el-button
          type="primary"
          :disabled="!isValidName || loading"
          @click="gotoSubmitFlow()">
          <span v-if="!loading">Confirm</span>
          <i v-if="loading" class="el-icon el-icon-loading"></i>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CreateGameButton',
  components: {},
  data() {
    return {
      newGameModal: false,
      name: '',
      errorName: null,
    };
  },
  computed: {
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    isValidName() {
      return this.name && this.name.length > 2 && this.name.length < 26;
    },
    isDedicatedAdAccountGame() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    loading() {
      return this.$store.getters['submitGame/loading'];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    async autofocus() {
      this.$nextTick().then(() => {
        if (document.getElementById('create-game-input-name')) {
          document.getElementById('create-game-input-name').focus();
        }
      });
    },
    reset() {
      this.name = null;
      this.newGameModal = false;
    },
    userHasPermission(value) {
      return this.myPermissions.indexOf(value) > -1;
    },
    openNewGameModal(value) {
      this.newGameModal = value;
      this.autofocus();
    },
    gotoSubmitFlow() {
      this.$router.push({
        query: {
          game: undefined,
          studio: undefined,
          view: undefined,
          gameid: 'new',
        },
      });
      this.$emit('game-created');
      this.reset();
    },
  },
};
</script>

<style lang="scss">
.buttonNewGame{
  .el-button {
    height: 32px;
  line-height: 0;
  padding-top: 10px;

  .el-icon-arrow-down {
    padding-left: 5px;
  }
  }
}
.new-game-modal {
  .formAction {
    padding: 20px;
    .name-requirement {
      margin-top: 10px;
      color: $darker-grey;
    }
  }
  .el-dialog__body {
    padding: 20px;
    .error {
      color: $blood-normal;
    }
    ul {
      li {
        padding: 10px;
        list-style: disc;
        margin-left: 20px;
        word-break: normal;
      }
    }
  }
}
</style>

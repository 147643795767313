<template>
  <div class="board">
    <Menu
      :sub="sub"
      :show-k-s="showKS"
      :e-learning-academy-infos="eLearningAcademyInfos"
      @updateSub="setSub"/>
    <el-button
      v-if="backToTopVisible"
      class="back-to-top"
      type="primary"
      size="small"
      @click="scrollTotop">
      <i class="el-icon-top"></i>{{ $t("common.backToTop") }}
    </el-button>

    <div class="content">
      <div class="alert-container">
        <AlertGeneralMessage :content-id="'GENERAL_ALERT'"></AlertGeneralMessage>
      </div>
      <StudioHome v-if="sub === 'home'" :e-learning-academy-infos="eLearningAcademyInfos"></StudioHome>
      <StudioMyGames v-if="sub === 'mygames'" :filter-value="filter" :type="type"></StudioMyGames>
      <KnowledgeSharing v-if="sub === 'ks'" :type="type"></KnowledgeSharing>
      <GameProject v-if="sub === 'project'" :id="filter" :type="type"></GameProject>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import AlertGeneralMessage from '@/components/Generic/AlertGeneralMessage/AlertGeneralMessage.vue';
import Menu from '@/components/Menu/Menu.vue';
import StudioHome from '@/views/StudioHome.vue';
import StudioMyGames from '@/views/StudioMyGames.vue';
import KnowledgeSharing from '@/views/KnowledgeSharing.vue';
import UsersService from '@/services/users.services';
import GameProject from '@/views/GameProject';

export default {
  name: 'StudioBoard',
  components: {
    StudioHome,
    StudioMyGames,
    KnowledgeSharing,
    AlertGeneralMessage,
    Menu,
    GameProject,
  },
  props: {
    sub: {
      type: String,
      default: 'home',
    },
    filter: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      backToTopVisible: false,
      eLearningLink: 'https://voodoo.zendesk.com/hc/en-us/categories/360001432760-E-LEARNING',
      academyLink: '',
      displayAcademyBanner: true,
    };
  },
  computed: {
    showKS() {
      return this.myPermissions.indexOf('view_knowledge_sharing') > -1;
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['users/loading']) || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    lastStatus() {
      return this.$store.getters['gameAction/currentStatus'] || 'in_progress';
    },
    isDedicatedAdAccountGame() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    academyAccess() {
      const permissions = Object.keys(this.$store.getters['users/me'].permissions);
      const academyPermissions = ['learn_upon_admin', 'learn_upon_instructor', 'learn_upon_learner', 'learn_upon_manager'];
      const hasPermission = academyPermissions.some(permission => permissions.includes(permission));
      return hasPermission;
    },
    eLearningAcademyInfos() {
      return this.academyAccess ? {
        link: this.academyLink,
        label: this.$t('views.StudioBoard.academy'),
      } : {
        link: this.eLearningLink,
        label: this.$t('views.StudioBoard.eLearning'),
      };
    },
  },
  watch: {
    meForm() {
      const me = JSON.stringify(this.me);
      const meForm = JSON.stringify(this.meForm);
      this.canSave = me !== meForm;
    },
    $route() {
      if (this.sub !== 'project') {
        delete localStorage.routeBeforeProject;
      }
    },
  },
  mounted() {
    if (this.sub !== 'project') {
      delete localStorage.routeBeforeProject;
    }
    this.$gtag.event('link', 'auto', 'studio-board', 1);
    window.addEventListener('scroll', () => {
      this.backToTopVisible = window.scrollY > 0;
    });
    this.getAcademyLink();
  },
  methods: {
    setSub(value, type) {
      if (value === 'ks') {
        this.$router.push({
          name: 'studioHome',
          params: {
            sub: value,
            type: type || 'cpi',
            filter: 'finished',
          },
        });
      } else if (this.$route.params.sub !== value || this.$route.params.type !== type) {
        if (value === 'home') {
          analytics.track('Click Menu', {
            label: 'home',
          });
          this.$router.push({
            name: 'studioHome',
            params: { sub: value },
          });
        } else {
          this.$router.push({
            name: 'studioHome',
            params: {
              sub: value,
              type: type || 'cpi',
              filter: this.lastStatus,
            },
          });
        }
      }
    },
    resetForm() {
      this.password = {
        current: '',
        new: '',
        confirm: '',
      };
    },
    scrollTotop() {
      window.scrollTo(0, 0);
    },
    saveProfile() {
      const user = {
        firstName: this.meForm.firstName,
        lastName: this.meForm.lastName,
        email: this.meForm.email,
        profile: this.meForm.profile,
      };
      this.$store.dispatch('users/updateUser', user);
    },
    async getAcademyLink() {
      if (!this.academyAccess) return;
      const linkResponse = await UsersService.getRedirectLink('learnupon');
      this.academyLink = linkResponse.redirectUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  padding: 0 5px;
  @media screen and (max-width: 700px) {
    margin-bottom: 17px;
  }
}

.main.main-board {
  background-color: $foggy-blue !important;
  // @media (max-width: 700px) {
  //   background-color: #fff !important;
  // }
}

.board {
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: 150px 1fr;
  @media screen and (max-width: 1330px) {
    grid-template-columns: 64px 1fr;
  }
  @media screen and (max-width: 1234px) {
    grid-template-columns: 31px 1fr;
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  // background-color:

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    max-width: 1152px;
    margin: auto;
    padding-left: 20px;
      @media screen and (max-width: 700px) {
        padding: 10px;
      }
  }
}

.back-to-top {
  position: fixed;
  height: 26px;
  bottom: 20px;
  left: 20px;
  font-size: 9px;
  text-transform: uppercase;
  padding: 8px 11px;
  z-index: 100;
}

.academyBanner {
  margin-bottom: 15px;
}
</style>

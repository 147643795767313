/* eslint-disable import/prefer-default-export */
import Vue from 'vue';

/**
 * Returns :
 * - the default language used on the website
 * @return {string}
 */
const getCurrentLocale = () => 'en';

const currentLocale = Vue.observable({
  locale: getCurrentLocale(),
});

export {
  currentLocale,
};

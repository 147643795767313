<template>
  <div v-if="studioInfos" :class="$style.wrapper">
    <div :class="$style.leftSection">
      <div :class="$style.flag">
        <IconCountry :country="countryCodeForFlag"></IconCountry>
      </div>
      <div>
        <div
          :class="$style.studioName"
          data-test-id="studioPageStudioName">
          {{ studioInfos.studioName }}
        </div>
        <div :class="$style.studioSecondaryInfos">
          <span
            v-if="coutryNameFromCode"
            data-test-id="studioPageCountryName">{{ coutryNameFromCode }} ·
          </span>
          <span data-test-id="studioPageCreationDate">
            {{ creationDate }}
          </span>
        </div>
      </div>
    </div>
    <div :class="$style.rightSection">
      <ActivityState
        v-if="studioInfos.deactivateDate === null"
        :class="$style.activity"
        :activity-state="studioInfos.activityState"
        :last-activity-date="studioInfos.lastActivityDate"
      />
      <ImpersonateButton
        v-if="studioInfos.id && (isAdmin || isStaff || canImpersonate)"
        :studio-id="studioInfos.id"
      />
      <div v-if="canViewHubSpot && studioInfos.hubspotUrl" :class="$style.section"></div>
      <MobileOptionButton v-if="isMobile && canViewHubSpot && studioInfos.hubspotUrl"></MobileOptionButton>
      <HubSpotButton
        v-else
        :url="studioInfos.hubspotUrl"/>
    </div>
  </div>
</template>

<script>
import countries from '@/../json/countries.json';
import HubSpotButton from '@/modules/Studio/components/HubSpotButton.vue';
import MobileOptionButton from '@/modules/Studio/components/MobileOptionButton.vue';
import IconCountry from './IconCountry.vue';
import ActivityState from './ActivityState.vue';
import ImpersonateButton from './ImpersonateButton.vue';

export default {
  name: 'StudioHeader',
  components: {
    IconCountry,
    ActivityState,
    ImpersonateButton,
    HubSpotButton,
    MobileOptionButton,
  },
  props: {
    studioInfos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countries,
    };
  },
  computed: {
    coutryNameFromCode() {
      const countryFromCode = countries.find(
        country => country.id === this.studioInfos.country,
      );
      return countryFromCode?.label;
    },
    countryCodeForFlag() {
      const countryFromCode = countries.find(
        country => country.id === this.studioInfos.country,
      );
      return countryFromCode ? this.studioInfos.country : '';
    },
    creationDate() {
      const date = this.studioInfos.createdAt
        ? new Date(this.studioInfos.createdAt)
        : null;
      return date ? date.toLocaleDateString('en-GB') : 'N/A';
    },
    isStaff() {
      return this.$store.getters['users/isStaff'];
    },
    isAdmin() {
      return this.$store.getters['users/isAdmin'];
    },
    canImpersonate() {
      return this.$store.getters['users/myPermissions'].includes(
        'impersonate_studio_member',
      );
    },
    isMobile() {
      return this.$mq === 'isMobile' || this.$mq === 'xxs';
    },
    canViewHubSpot() {
      return this.$store.getters['users/myPermissions'].includes(
        'view_hubspot_url',
      );
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  background-color: $foggy-blue;
  padding: $spacing * 2 $spacing * 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid $foggy-grey;
  position: sticky;
  top: 60px;
  z-index: 10;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.leftSection,
.rightSection {
  display: flex;
  align-items: center;
}

.section {
  border-right: 1px solid $foggy-grey;
  margin: 0 20px;
  height: 30px;
}

.flag {
  margin-right: $spacing * 2;
}

.studioName {
  font-size: 18px;
  color: $soft-black;
  font-weight: 600;
  margin-bottom: $spacing / 2;
}

.studioSecondaryInfos {
  text-transform: uppercase;
  font-size: 10px;
  color: $grey;
}

.activity {
  margin-right: $spacing * 3;
}
</style>

export default [
  {
    id: 'add_video',
    status: ['pending_review', 'pending_sdk_integration'],
    users: ['studio', 'cs'],
    permissions: ['change_game_video'],
    used: false,
    post: {
      message: '',
      format: 'form/data',
      field: [
        {
          type: 'file',
          param: 'attached',
        },
        {
          type: 'string',
          param: 'ad_network',
        },
      ],
    },
  },
  {
    id: 'change_video',
    status: ['pending_review', 'pending_sdk_integration'],
    users: ['studio', 'cs'],
    permissions: ['change_game_video'],
    used: false,
    post: {
      message: '',
      format: 'form/data',
      field: [
        {
          type: 'file',
          param: 'attached',
        },
        {
          type: 'string',
          param: 'video_id',
        },
      ],
    },
  },
  {
    id: 'crop_video',
    status: ['pending_review', 'pending_sdk_integration'],
    users: ['studio', 'cs'],

    used: false,
    permissions: ['change_game_video'],
    post: {
      message: '',
      format: 'application/json',
      field: [
        {
          type: 'string',
          param: 'video_id',
        },
        {
          type: 'string',
          param: 'cropping_position_percent',
        },
      ],
    },
  },
  {
    id: 'remove_video',
    status: ['pending_review', 'pending_sdk_integration'],
    users: ['studio', 'cs'],
    used: false,
    permissions: ['change_game_video'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'string',
          param: 'video_id',
        },
        {
          type: 'string',
          param: 'cropping_position_percent',
        },
      ],
    },
  },
  {
    id: 'reject_video',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: false,
    permissions: ['reject_game_video'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'update_icon',
    status: ['pending_review', 'pending_sdk_integration'],
    users: ['studio', 'cs'],
    used: false,
    permissions: ['change_game_icon'],
    post: {
      format: 'form/data',
      field: [
        {
          type: 'file',
          param: 'attached',
        },
      ],
    },
  },
  {
    id: 'accept_game_with_ga',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: true,
    permissions: [],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'accept_game_without_ga',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['accept_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'accept_video',
    status: ['pending_review'],
    used: false,
    users: ['pm', 'cs'],
    permissions: ['accept_game_video'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'reject_game_bad_game',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: false,
    permissions: ['reject_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'reject_game_already_tested',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['reject_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'reject_game_existing_game',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['reject_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'reject_game_iteration',
    name: 'Reject iteration',
    primary: true,
    icon: 'el-icon-remove',
    color: 'danger',
    status: ['pending_review'],
    users: ['pm', 'cs'],
    used: true,
    async: true,
    permissions: ['reject_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'create_ga_slot',
    name: 'Create GA Slot',
    status: ['pending_sdk_integration'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['accept_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'assign_ga_slot',
    name: ' Change GameAnalytics ID',
    primary: true,
    color: 'primary',
    icon: 'el-icon-date',
    status: ['pending_sdk_integration', 'pending_testing', 'in_testing'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['accept_game_testing'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'String',
          param: 'value',
          message: 'Please enter the new GameAnalytics ID for this game: ',
          placeholder: 'New GameAnalytics here...',
          value: null,
          existingValue: 'gaId',
        },
      ],
    },
  },
  {
    id: 'request_testing',
    name: 'Request testing',
    status: ['pending_sdk_integration'],
    users: ['studio', 'cs'],
    used: true,
    permissions: ['request_game_testing'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'network_app_ids',
          deepParam: ['facebook'],
        },
        {
          type: 'Object',
          param: 'changelogs',
          deepParam: ['category', 'details'],
        },
      ],
    },
    async: true,
  },
  {
    id: 'test_studio_campaign',
    name: 'Link Facebook campaign',
    status: ['pending_sdk_integration'],
    users: ['studio', 'cs'],
    used: true,
    permissions: ['accept_game'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'choice',
          deepParam: ['facebook'],
        },
      ],
    },
  },
  {
    id: 'accept_testing',
    name: 'Accept testing',
    status: ['pending_testing'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['accept_game_testing'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'decline_testing',
    name: 'Decline testing',
    status: ['pending_testing'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['accept_game_testing'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'request_application_access',
    name: 'Request app access',
    status: ['pending_testing'],
    used: true,
    users: ['studio', 'cs'],
    permissions: ['accept_game_testing'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'network_app_ids',
          deepParam: ['facebook'],
        },
      ],
    },
  },
  {
    id: 'edit_changelog',
    status: ['pending_testing', 'in_testing'],
    used: false,
    users: ['studio', 'cs'],
    permissions: ['edit_changelog'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'iteration',
          deepParam: ['id'],
        },
        {
          type: 'Object',
          param: 'changelogs',
          deepParam: ['category', 'details'],
        },
      ],
    },
  },
  {
    id: 'extend_campaign',
    name: 'Extend campaign +4days',
    status: ['in_testing'],
    users: ['pm', 'cs'],
    used: false,
    permissions: ['iterate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'iterate_pause_campaign',
    name: 'Iterate',
    status: ['pending_testing'],
    users: ['pm', 'cs'],
    permissions: ['iterate_game'],
    used: true,
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'iterate_keep_campaign',
    status: ['in_testing'],
    used: true,
    users: ['studio', 'cs', 'studio'],
    permissions: ['iterate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'iterate_from_history',
    name: 'Iterate (from history)',
    status: ['finished'],
    used: true,
    primary: true,
    color: 'primary',
    icon: 'el-icon-refresh',
    users: ['studio', 'cs'],
    permissions: ['iterate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: false, // true todo
  },
  {
    id: 'update_studio_campaign',
    name: 'Change associated campaign',
    status: ['in_testing'],
    users: ['studio', 'cs', 'pm'],
    permissions: ['update_dedicated_campaign'],
    used: true,
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'choice',
          deepParam: ['facebook'],
          requireCall: true,
        },
      ],
    },
  },
  {
    id: 'change_game_project',
    name: 'Link to a project',
    users: ['studio', 'cs'],
    used: true,
    post: {
      message: '',
      format: 'form/data',
      field: [
        {
          type: 'string',
          param: 'projectId',
        },
      ],
    },
  },
  {
    id: 'unlink_game_project',
    name: 'Unlink from the project',
    users: ['studio', 'cs'],
    used: true,
  },
  {
    id: 'validate_game',
    name: 'Launch game',
    primary: true,
    color: 'success',
    icon: 'el-icon-check',
    status: ['in_testing'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['validate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: false, // true todo
  },
  {
    id: 'kill_game',
    name: 'Move to finished',
    color: 'danger',
    status: ['pending_sdk_integration', 'pending_testing', 'in_testing'],
    users: ['pm', 'cs'],
    used: true,
    permissions: ['kill_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'archive_game',
    name: 'Archive game',
    color: 'danger',
    status: ['finished'],
    users: ['studio', 'pm', 'cs'],
    used: true,
    permissions: ['kill_game'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: false,
  },
  {
    id: 'rate_good',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['pm', 'cs'],
    permissions: ['rate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'rate_medium',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['pm', 'cs'],
    permissions: ['rate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'rate_bad',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['pm', 'cs'],
    permissions: ['rate_game'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
  {
    id: 'set_game_lead',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['pm', 'cs'],
    permissions: ['set_game_lead'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'choice',
          deepParam: ['id'],
        },
      ],
    },
  },
  {
    id: 'set_publishing_manager',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['pm', 'cs'],

    permissions: ['change_publishing_manager'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'Object',
          param: 'choice',
          deepParam: ['id'],
        },
      ],
    },
  },
  {
    id: 'set_game_genres',
    name: 'Edit game tags',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: true,
    users: ['studio', 'cs', 'pm'],
    permissions: ['edit_game_genre'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'array',
          param: 'genres',
          value: '',
        },
      ],
    },
  },
  {
    id: 'add_game_feedback',
    name: 'Give feedback',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: true,
    users: ['studio', 'cs'],
    permissions: ['send_feedback'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'String',
          param: 'subject',
        },
        {
          type: 'String',
          param: 'comment',
        },
      ],
    },
  },
  {
    id: 'delete_game_feedback',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['studio', 'cs'],
    permissions: ['send_feedback'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'String',
          param: 'feedback_id',
        },
      ],
    },
  },
  {
    id: 'update_best_performing_video',
    status: ['pending_review', 'pending_sdk_integration', 'pending_testing', 'in_testing', 'finished'],
    used: false,
    users: ['studio', 'cs'],
    permissions: ['force_best_performing'],
    post: {
      format: 'application/json',
      field: [
        {
          type: 'String',
          param: 'video_id',
        },
      ],
    },
  },
  {
    id: 'recreate_campaign_facebook',
    name: 'Recreate facebook campaign',
    status: ['pending_review', 'pending_testing', 'in_testing', 'finished'],
    users: ['studio', 'cs'],
    used: true,
    permissions: ['recreate_facebook_campaign'],
    post: {
      format: 'application/json',
      field: [],
    },
    async: true,
  },
  {
    id: 'recreate_campaign_ironsource',
    name: 'Recreate ironsource campaign',
    status: ['pending_review', 'pending_testing', 'in_testing', 'finished'],
    users: ['studio', 'cs'],
    used: true,
    permissions: ['recreate_facebook_campaign'],
    post: {
      format: 'application/json',
      field: [],
    },
  },
];

<template>
  <div :class="[$style.wrapper, style]">
    <div :class="$style.dot"></div>
    <div :class="$style.text" data-test-id="studioPageActivityState">{{ activity }}</div>
  </div>
</template>

<script>
export default {
  name: 'StudioActivityStatus',
  props: {
    activityState: {
      type: String,
      required: true,
    },
    lastActivityDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    formatActivityDate() {
      const today = new Date();
      const lastActivity = new Date(this.lastActivityDate);
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((today - lastActivity) / oneDay));
      if (diffDays <= 7) {
        return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
      } if (diffDays <= 30) {
        return `${Math.round(diffDays / 7)} week${Math.round(diffDays / 7) > 1 ? 's' : ''}`;
      } if (diffDays <= 365) {
        return `${Math.round(diffDays / 30)} month${Math.round(diffDays / 30) > 1 ? 's' : ''}`;
      }
      return `${Math.round(diffDays / 365)} year${Math.round(diffDays / 365) > 1 ? 's' : ''}`;
    },
    activity() {
      if (this.activityState === 'active') {
        return 'Active';
      }
      return `Inactive for ${this.formatActivityDate}`;
    },
    style() {
      let style = '';
      if (this.activityState === 'active') {
        style = this.$style.active;
      }
      if (this.activityState === 'inactive') {
        style = this.$style.inactive;
      }
      if (this.activityState === 'churned') {
        style = this.$style.churned;
      }
      return style;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-size: 10px;
  font-weight: 600;
}

.active {
  color: $green-alert;
  > div:nth-of-type(1) {
    background-color: $green-alert;
  }
}

.inactive {
  color: $orange-alert;
  > div:nth-of-type(1) {
    background-color: $orange-alert;
  }
}

.churned {
  color: $red-alert;
  > div:nth-of-type(1) {
    background-color: $red-alert;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: $spacing / 2;
}

.text:first-letter {
  text-transform: capitalize;
}
</style>

const simpleContent = {
  term: '',
  filter: {
    logicalOperator: 'all',
    children: [],
  },
  columns: [
    {
      id: 'name',
      label: 'Name',
      width: 140,
      isSelected: true,
    },
    {
      id: 'studioName',
      label: 'Studio',
      width: 90,
      isSelected: true,
    },
    {
      id: 'pmName',
      label: 'PM',
      width: 120,
      isStaff: true,
      isSelected: true,
    },
    {
      id: 'bestCpi',
      label: 'CPI',
      width: 70,
      isSelected: true,
    },
    {
      id: 'bestD1',
      label: 'Day 1',
      width: 90,
      isSelected: true,
    },
    {
      id: 'bestD7',
      label: 'Day 7',
      width: 90,
      isSelected: true,
    },
    {
      id: 'bestCtr',
      label: 'CTR',
      width: 90,
      isSelected: true,
    },
    {
      id: 'sinceLastIterationDate',
      label: 'Last iteration date (nb days)',
      width: 130,
      isSelected: true,
    },
    {
      id: 'status',
      label: 'Status',
      width: 100,
      isSelected: true,
    },
    {
      id: 'isManagedByVoodoo',
      label: 'Method',
      width: 90,
      isStaff: true,
      isSelected: true,
    },
    {
      id: 'lastIterationDate',
      label: 'Last iteration date',
      width: 130,
      isSelected: false,
    },
    {
      id: 'link',
      label: 'Link',
      width: 100,
      isSelected: true,
    },
    {
      id: 'bestCpm',
      label: 'CPM',
      width: 100,
      isSelected: false,
    },
    {
      id: 'bestD14',
      label: 'Day 14',
      width: 90,
      isSelected: false,
    },
    {
      id: 'bestD28',
      label: 'Day 28',
      width: 90,
      isSelected: false,
    },
    {
      id: 'bestD90',
      label: 'Day 90',
      width: 90,
      isSelected: false,
    },
    {
      id: 'bestPlaytime',
      label: 'Playtime',
      width: 110,
      isSelected: false,
    },
    {
      id: 'tags',
      label: 'Tags',
      width: 140,
      isSelected: true,
    },
    {
      id: 'qrcode',
      label: 'QR-code',
      width: 90,
      isStaff: true,
      isSelected: false,
    },
  ],
  best: true,
  sort: {
    column: {
      order: 'descending',
      id: 'el-table_1_column_7',
      type: 'default',
      property: 'lastIterationDate',
      align: null,
      headerAlign: null,
      showOverflowTooltip: false,
      filteredValue: [],
      filterPlacement: '',
      isColumnGroup: false,
      filterOpened: false,
      sortable: 'custom',
      label: 'Last iteration date',
      className: 'column-sinceLastIterationDate column',
      fixed: false,
      resizable: true,
      sortOrders: ['descending', 'ascending', null],
      reserveSelection: false,
      filterMultiple: true,
      minWidth: 130,
      realWidth: 159,
      level: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    prop: 'lastIterationDate',
    order: 'desc',
  },
};
export default simpleContent;

<template>
  <div>
    <div id="nav" class="studio-service">
      <div v-if="me" class="container">
        <div class="item-left">
          <div class="logo-box" data-test-id="HeaderLogo">
            <router-link
              :to="{
                name: 'studioHome'
              }"
            >
              <li>
                <a class="logo"></a>
                <span v-if="!isOnAdmin" class="sub-logo" @click="actionHeader('home')">PUBLISHING PLATFORM</span>
                <span v-if="isOnAdmin" class="sub-logo" @click="actionHeader('home')">STUDIO ADMIN</span>
              </li>
            </router-link>

            <el-dropdown
              v-if="canViewPublishing"
              class="drop-menu-logo"
              placement="bottom-start"
              trigger="click"
              data-test-id="HeaderDropdownLogo"
              @command="actionHeader"
              @visible-change="headerDropdownVisible = $event"
            >
              <el-button class="dropdown-button" :icon="`el-icon-caret-${headerDropdownVisible ? 'top' : 'bottom'}`"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  data-test-id="HeaderItemPublishingPlatform"
                  :command="'publishing'">Publishing Platform</el-dropdown-item>
                <el-dropdown-item
                  v-if="isOnAdmin"
                  data-test-id="HeaderItemStudioPlatform"
                  :command="'home'">Studio Platform</el-dropdown-item>
                <el-dropdown-item
                  v-if="!isOnAdmin"
                  data-test-id="HeaderItemStudioAdmin"
                  :command="'mystudio'">Studio Admin</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="burger-menu" data-test-id="HeaderBurgerMenu" @click="switchMobileMenu">
            <el-image
              class="burger"
              style="width: 20px;
                        height: 20px;
                       "
              :src="burger"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
          </div>
          <div class="button-new-game">
            <CreateGameButton v-if="showNewGame"></CreateGameButton>
            <SearchBar v-if="showNewGame"></SearchBar>
          </div>
        </div>
        <div v-if="me" class="item-right">
          <Notifications></Notifications>
          <el-dropdown
            v-if="me"
            data-test-id="HeaderProfilerButton"
            @command="actionHeader">
            <span class="el-dropdown-link">
              <i class="el-icon-user-solid"></i>
              <span class="name user-name">{{ me.firstName }} {{ me.lastName }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                data-test-id="HeaderProfilerButtonMyProfile"
                :command="'myprofile'">{{ $t("common.myProfile") }}</el-dropdown-item>
              <el-dropdown-item
                v-if="!canViewPublishing"
                data-test-id="HeaderProfilerButtonMyStudio"
                :command="'mystudio'">{{ $t("components.StudioService.HeaderService.myStudio") }}</el-dropdown-item>
              <el-dropdown-item
                data-test-id="HeaderProfilerButtonLogout"
                :command="'logout'">{{ $t("common.logOut") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <MobileMenu v-if="mobileMenu" @visibility="switchMobileMenu"></MobileMenu>
    </transition>
    <div class="margin-header"></div>
  </div>
</template>

<script>
import { LOGIN_FLOW_ROUTES } from '@/router';
import Notifications from '@/components/Notifications/Notifications.vue';
import CreateGameButton from '@/components/CreateGameButton.vue';
import SearchBar from '@/components/StudioSearchBar.vue';
import MobileMenu from '@/views/MobileMenu.vue';
import burger from '@/assets/images/burgerMenu@3x.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import tool from '@/tools/tools';
import UsersService from '@/services/users.services';

export default {
  name: 'Header',
  components: {
    Notifications,
    CreateGameButton,
    SearchBar,
    MobileMenu,
  },

  data() {
    return {
      LOGIN_FLOW_ROUTES,
      burger,
      headerDropdownVisible: false,
      mobileMenu: false,
      gameIcon,
      isMobile: false,
    };
  },
  computed: {
    isOnAdmin() {
      if (this.$route.name === 'studioAdmin') {
        return true;
      }
      return false;
    },
    showNewGame() {
      if (this.$route.name === 'studioHome') {
        return true;
      }
      return false;
    },
    userLoaded() {
      return this.$store.getters['users/isLoaded'] || null;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    me() {
      return this.$store.getters['users/me'] || null;
    },
    userId() {
      return this.$store.getters['users/id'] || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canViewPublishing() {
      return this.myPermissions.indexOf('view_pm_interface') > -1;
    },
  },

  mounted() {
    this.redirect();
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
    }
  },
  methods: {
    redirect() {
      if (!this.me) {
        this.$router.push({
          name: 'root',
          params: {
            redirect: true,
          },
        }).catch(() => {});
      } else {
        tool.redirectUser(this.$router, this.myPermissions, UsersService);
      }
    },
    switchMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    onResize() {
      this.isMobile = window.innerWidth <= process.env.VUE_APP_MOBILE;
    },
    actionHeader(action) {
      switch (action) {
      case 'home':
        if (this.$route.name !== 'studioHome') {
          this.$router.push({
            name: 'studioHome',
          });
        }
        break;
      case 'publishing':
        if (this.$route.name !== 'home') {
          const routeData = this.$router.resolve({
            name: 'home',
          });
          window.open(routeData.href, '_blank');
        }
        break;
      case 'logout':
        this.$store.dispatch('users/logout');
        break;
      case 'mystudio':
        // this.$router.push({
        //   name: 'studioAdmin',
        // });
        // break;
        if (this.$route.name !== 'studioAdmin' && this.$route.name !== 'meAdmin') {
          const routeData = this.$router.resolve({
            name: 'studioAdmin',
          });
          window.open(routeData.href, '_blank');
        } else {
          this.$router.push({
            name: 'studioAdmin',
          });
        }
        break;
      case 'myprofile':
        this.$router.push({
          name: 'meAdmin',
        });
        break;
      default:
        this.$store.dispatch('users/logout');
        break;
      }
    },
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

#nav.studio-service {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
  color: $font-basic-color;
  position: fixed;
  z-index: 103;
  // box-shadow: 0 3px 10px 0 rgba(198, 219, 240, 0.25);
  // &.studio-service {
  background-color: $foggy-blue;
  @media screen and (max-width: 700px) {
    padding: 0 20px;
  }
  // box-shadow: 0 3px 10px 0 rgba(198, 219, 240, 0.25);
  // }
  .el-icon-user-solid {
    margin-right: 5px !important;
  }
  .item-left {
    display: flex;
    .burger-menu {
      height: 60px;
      width: 40px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
    }
    @media screen and (min-width: 701px) {
      .burger-menu {
        display: none;
      }
    }
    .button-new-game {
      display: flex;
      align-items: center;
      margin-left: 93px;
      @media screen and (min-width: 700px) {
        margin-left: 0px;
      }
      @media screen and (max-width: 700px) {
        margin-left: 0px;
        .buttonNewGame {
          display: none;
        }
      }
    }
    li {
      // margin-right: 90px;
    }
  }
  .item-right {
    min-width: fit-content;
    // outline-width: 0 !important;
    @media screen and (max-width: 700px) {
      .name {
        display: none;
      }
    }
  }
  .el-dropdown {
    display: flex;
    align-items: center;
  }
  li {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
  }
  .name {
    // font-weight: bold;
    // margin-left: 5px;
  }
  .menu {
    display: flex;
    flex-direction: row;
    span {
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        display: block;
        background-color: #067bf0;
        bottom: -21px;
        // left: 9px;
        transition: all 0.5s;
        left: 50%;
      }
      &.active,
      &:hover {
        color: #067bf0;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          display: block;
          background-color: #067bf0;
          bottom: -21px;
          left: 9px;
          transition: all 0.5s;
        }
      }
      color: #adadad;
    }
  }
  .container {
    align-items: left;
    display: flex;
    align-items: left;
    width: 100%;
    justify-content: space-between;
  }
  .logo-box {
    // width: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 160px;
    .drop-menu-logo {
      // position: absolute;
      // left: 100px;
      // top: -5px;

      li {
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
      }
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .router-link-active {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  a {
    font-weight: bold;
    color: #fff;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  .logo {
    width: 100px;
    vertical-align: middle;
    height: 15px;
    background-image: url("~@/assets/images/voodoo-black.svg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: -10px;
  }
  .sub-logo {
    color: #82bdf7;
    font-size: 9px;
    font-weight: bold;
    margin-left: -12px;
    margin-top: 4px;
  }
  .logo-ext {
    font-weight: bold;
    margin-top: 4px;
    font-size: 14px;
  }
}
</style>

<template>
  <div>
    <StudioModule v-if="id" :id="id"/>
  </div>
</template>

<script>
import StudioModule from '@/modules/Studio';

export default {
  name: 'StudioDetails',
  components: {
    StudioModule,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

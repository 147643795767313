<template>
  <SlidingPage
    :open="open"
    @close="closeTag"
  >
    <template v-slot:container>
      <GameView v-if="open && page === 'game-detail' && id" :id="id"></GameView>
      <GameKsPage v-if="open && page === 'game-ks-detail' && id" :id="id"></GameKsPage>
    </template>
  </SlidingPage>
</template>

<script>
import SlidingPage from '@/components/Generic/SlidingPage/SlidingPage.vue';
import GameKsPage from '@/components/KnowledgeSharing/GameKsPage.vue';
import GameView from '@/views/GameView.vue';

export default {
  name: 'SlidingPageWrapper',
  components: {
    GameView,
    SlidingPage,
    GameKsPage,
  },
  props: {},
  data() {
    return {
      open: false,
      page: null,
      id: null,
    };
  },
  computed: {},
  watch: {
    $route() {
      this.updateView();
    },
  },
  mounted() {
    this.updateView();
  },
  events: {},

  methods: {
    closeTag() {
      this.$router
        .push({
          query: {
            game: undefined,
            gameid: undefined,
            studio: undefined,
            view: this.$route.query.view,
          },
        })
        .catch(() => {});
    },
    updateView() {
      if (this.$route.query.create || this.$route.query.game || this.$route.query.gameid || this.$route.query.gameKsId) {
        this.open = true;
        document.body.style.overflow = 'hidden';
        if (this.$route.query.game) {
          this.page = 'game-detail';
          this.id = this.$route.query.game;
        } else if (this.$route.query.gameid) {
          this.page = 'game-detail';
          this.id = this.$route.query.gameid;
        } else if (this.$route.query.gameKsId) {
          this.page = 'game-ks-detail';
          this.id = this.$route.query.gameKsId;
        }
      } else {
        document.body.style.overflow = 'auto';
        this.page = undefined;
        this.$store.dispatch('submitGame/clean');
        this.open = false;
      }
    },
  },
};
</script>

<template>
  <div id="my-games" class="mygames">
    <div id="headerMyGameMobile" class="header-mobile-mygames">
      <div v-if="type === 'cpi'" class="title">
        CPI Tests
      </div>
      <div class="filters-mobile">
        <div class="item-filter">
          <div
            class="filters-name"
            :class="{ active: filters.status === 'is_iterating' }"
            data-test-id="myGamesIsIteratingButton"
            @click="updateUrl('is_iterating')"
          >
            Is iterating
            <span v-if="nbGamesStatus.is_iterating" class="number-games-by-status">
              {{ nbGamesStatus.is_iterating }}
            </span>
          </div>
          <div
            class="filters-name"
            :class="{ active: filters.status === 'in_progress' }"
            data-test-id="myGamesInProgressButton"
            @click="updateUrl('in_progress')"

          >
            In progress
            <span v-if="nbGamesStatus.in_progress" class="number-games-by-status">
              {{ nbGamesStatus.in_progress }}
            </span>
          </div>
          <div
            class="filters-name"
            :class="{ active: filters.status === 'pending_approval' }"
            data-test-id="myGamesPendingApprovalButton"
            @click="updateUrl('pending_approval')"

          >
            Pending approval
            <span v-if="nbGamesStatus.pending_approval" class="number-games-by-status">
              {{ nbGamesStatus.pending_approval }}
            </span>
          </div>
          <div
            class="filters-name"
            :class="{ active: filters.status === 'in_testing' }"
            data-test-id="myGamesInTestingButton"
            @click="updateUrl('in_testing')"

          >
            In testing
            <span v-if="nbGamesStatus.in_testing" class="number-games-by-status">
              {{ nbGamesStatus.in_testing }}
            </span>
          </div>
          <div
            class="filters-name"
            :class="{ active: filters.status === 'finished' }"
            data-test-id="myGamesFinishedButton"
            @click="updateUrl('finished')"
          >
            Finished
            <span v-if="nbGamesStatus.finished" class="number-games-by-status">
              {{ nbGamesStatus.finished }}
            </span>
          </div>
        </div>

        <div class="button-mobile filter" @click="openMobileView">
          <div class="text">
            <i class="el-icon el-icon-sort"></i>
            <span>Sort by {{ sortName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="margin-mobile-mygames"></div> -->
    <div id="headerMyGame" class="header mygames">
      <div class="top">
        <div class="left">
          <span v-if="type === 'cpi'" class="title-section">
            CPI Tests
          </span>
          <el-switch
            v-if="myPermissions.indexOf('filter_team_status') > -1"
            v-model="showMyGamesOnly"
            class="title-switch"
            active-text="My games only"
            data-test-id="myGamesMyGamesOnlySwitch"
          ></el-switch>
        </div>
        <div class="right">
          <div class="status-filter">
            <el-radio-group v-model="filters.status" size="mini" @change="updateUrl">
              <el-radio-button label="is_iterating" data-test-id="myGamesIsIteratingButton">
                Is iterating
                <span v-if="nbGamesStatus.is_iterating" class="number-games-by-status">
                  {{ nbGamesStatus.is_iterating }}
                </span>
              </el-radio-button>
            </el-radio-group>
            <el-radio-group
              v-model="filters.status"
              class="radio-group-filter"
              size="mini"
              @change="updateUrl"
            >
              <el-radio-button label="in_progress" data-test-id="myGamesInProgressButton">
                In progress
                <span v-if="nbGamesStatus.in_progress" class="number-games-by-status">
                  {{ nbGamesStatus.in_progress }}
                </span>
              </el-radio-button>
              <el-radio-button label="pending_approval" data-test-id="myGamesPendingApprovalButton">
                Pending approval
                <span v-if="nbGamesStatus.pending_approval" class="number-games-by-status">
                  {{ nbGamesStatus.pending_approval }}
                </span>
              </el-radio-button>
              <el-radio-button label="in_testing" data-test-id="myGamesInTestingButton">
                In testing
                <span v-if="nbGamesStatus.in_testing" class="number-games-by-status">
                  {{ nbGamesStatus.in_testing }}
                </span>
              </el-radio-button>
              <el-radio-button label="finished" data-test-id="myGamesFinishedButton">
                Finished
                <span v-if="nbGamesStatus.finished" class="number-games-by-status">
                  {{ nbGamesStatus.finished }}
                </span>
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="sticky-filter">
            <div v-if="filters.status === 'is_iterating'" class="sticky-name">Is iterating</div>
            <div v-if="filters.status === 'in_progress'" class="sticky-name">In progress</div>
            <div v-if="filters.status === 'pending_approval'" class="sticky-name">
              Pending approval
            </div>
            <div v-if="filters.status === 'in_testing'" class="sticky-name">In testing</div>
            <div v-if="filters.status === 'finished'" class="sticky-name">Finished</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <SortListMyGames :type="type" :filter="filterValue"></SortListMyGames>
      </div>
    </div>
    <div class="games-list">
      <GameInProgress v-if="filters.status === 'in_progress'"></GameInProgress>
      <GameIsIterating v-if="filters.status === 'is_iterating'"></GameIsIterating>
      <GamePendingApproval v-if="filters.status === 'pending_approval'"></GamePendingApproval>
      <GameInTesting v-if="filters.status === 'in_testing'"></GameInTesting>
      <GameFinished v-if="filters.status === 'finished'"></GameFinished>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import GameInProgress from '@/components/GameInProgress.vue';
import GamePendingApproval from '@/components/GamePendingApproval.vue';
import GameInTesting from '@/components/GameInTesting.vue';
import GameIsIterating from '@/components/GameIsIterating.vue';
import GameFinished from '@/components/GameFinished.vue';
import SortListMyGames from '@/components/SortListMyGames.vue';

export default {
  name: 'StudioMyGames',
  components: {
    GameInProgress,
    GamePendingApproval,
    GameInTesting,
    GameIsIterating,
    GameFinished,
    SortListMyGames,
  },
  props: {
    page: {
      type: String,
      default: '',
    },
    filterValue: {
      type: String,
      default: 'in_progress',
    },
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      filters: {
        status: 'in_progress',
      },
      openNewGameModal: false,
    };
  },
  computed: {
    showMyGamesOnly: {
      get() {
        return cloneDeep(this.$store.getters['gameAction/showGameOnly']);
      },
      set(value) {
        this.$store.dispatch('gameAction/myGamesOnly', value);
      },
    },
    nbGamesStatus() {
      return this.$store.getters['gameAction/numberGameByStatus'];
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    currentCompetition() {
      return this.$store.getters['users/codification']
        ? this.$store.getters['users/codification'].currentCompetition
        : undefined;
    },
    sortName() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      if (params.myGames.sort === 'createdAt') return 'date';
      if (params.myGames.sort === 'lastCpi') return 'cpi';
      if (params.myGames.sort === 'lastD1') return 'D1';
      if (params.myGames.sort === 'lastPlaytime') return 'playtime';
      if (params.myGames.sort === 'lastCpm') return 'cpm';
      return 'D1';
    },
  },
  watch: {
    filterValue(value) {
      this.filters.status = value;
    },
    type() {
      this.$store.dispatch('gameAction/resetNumberGameInStatus');
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'in_progress',
        sort: 'createdAt',
        order: 'desc',
      });

      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'is_iterating',
        sort: 'createdAt',
        order: 'desc',
      });

      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'in_testing',
        sort: 'createdAt',
        order: 'desc',
      });

      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'finished',
        sort: 'createdAt',
        order: 'desc',
      });

      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'archived',
        sort: 'createdAt',
        order: 'desc',
      });

      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        filter: 'pending_approval',
        sort: 'createdAt',
        order: 'desc',
      });
      this.$store.dispatch('gameAction/reset_gameList');
      this.refreshNumberGameInStatus(false);
    },
    '$route.query.term': function watchTerm(value, old) {
      if (value !== old) {
        this.refreshNumberGameInStatus();
      }
    },
    showMyGamesOnly(value, old) {
      if (value !== old) {
        this.refreshNumberGameInStatus();
      }
    },
  },
  mounted() {
    this.filters.status = this.filterValue;
    const stickyElm = document.querySelector('#headerMyGame');
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
      { threshold: [1] },
    );
    this.$store.dispatch('gameAction/getNumberGameInStatus', {
      myGamesOnly: this.showMyGamesOnly,
      isCtr: this.type === 'ctr',
      term: this.$route.query.term || '',
    });
    observer.observe(stickyElm);
  },
  methods: {
    setSub(value) {
      this.$router.push({
        name: 'studioHome',
        params: {
          sub: 'mygames',
          type: value || 'cpi',
          filter: this.filterValue || 'in_progress',
        },
      });
    },
    updateUrl(value) {
      if (value === 'is_iterating') {
        // eslint-disable-next-line no-undef
        analytics.track('Filter click', {
          label: 'Is Iterating',
        });
      }
      this.$router.push({
        name: 'studioHome',
        params: {
          page: 'board',
          sub: 'mygames',
          filter: value,
          type: this.type,
        },
      });
      this.$store.dispatch('gameAction/reset_gameList');
    },
    userHasPermission(value) {
      return this.myPermissions.indexOf(value) > -1;
    },
    openMobileView() {
      const query = {
        ...this.$route.query,
        mobile: 'sort-my-games',
        mobileTitle: 'Sort',
        mobileIcon: 'sort',
      };
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    /**
     * Refreshes the number of games per status, based on filters
     * @param {boolean?} reset - reset the numbers currently displayed? (default true)
     */
    refreshNumberGameInStatus(reset = true) {
      if (reset) {
        this.$store.dispatch('gameAction/resetNumberGameInStatus');
      }

      this.$store.dispatch('gameAction/getNumberGameInStatus', {
        myGamesOnly: this.showMyGamesOnly,
        isCtr: this.type === 'ctr',
        term: this.$route.query.term || '',
      });
    },
  },
};
</script>

<style lang="scss">
.main {
  justify-content: center;
}
.is-active,
.active {
  .number-games-by-status {
    color: #067bf0;
  }
}
.number-games-by-status {
  background-color: #bcddfe;
  color: #fff;
  padding: 0px 4px 1px 4px;
  border-radius: 10px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 10px;
}
.mygames {
  width: 100%;
  position: relative;

  .games-list {
    padding: 20px 14px;
    @media (max-width: 700px) {
      padding: 10px 10px;
    }
  }
  .spinner {
    font-size: 30px;
    color: grey;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header-mobile-mygames {
    display: none;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
    .button-filter {
      width: 100%;
    }
    .button-mobile {
      border-radius: 5px;
      border: solid 1px #82bdf7;
      background-color: #f7fbff;
      padding: 10px;
      margin: 20px 20px 20px 20px;
      display: flex;
      justify-content: space-around;
      font-size: 12px;
      &:hover {
        background-color: #82bdf7;
        .el-icon {
          color: #fff;
        }
        span {
          color: #fff;
        }
      }
      .el-icon {
        position: absolute;
        left: 40px;
        color: #82bdf7;
      }
      span {
        margin-left: 10px;
        color: #82bdf7;
      }
    }
    @media screen and (max-width: 700px) {
      display: flex;
      position: sticky;
      z-index: 10;
      background-color: $foggy-blue;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-top: -20px;
      left: 0px;
      top: -1px;
      .title-mobile {
        display: none;
        top: -10px;
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
      }
      &.isSticky {
        .title-mobile {
          display: flex;
          position: absolute;
          top: 10px;
        }
      }
      .title {
        color: #272727;
        font-size: 30px;
        font-weight: bold;
        padding: 10px 20px;
      }
      .filters-mobile {
        width: 100vw;
        overflow: auto;
        margin-top: 11px;
        .item-filter {
          display: grid;
          // min-width: 160%;
          overflow: auto;
          white-space: nowrap;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          position: relative;
          font-size: 12px;
          border-bottom: 1px solid #ebf1f7;
          border-top: 1px solid #ebf1f7;
          .filters-name {
            padding: 20px 20px;
            word-wrap: none;
            color: #82bdf7;
            // left: 0;
            font-weight: bold;

            position: relative;
            &:hover {
              cursor: pointer;
            }
            &::after {
              content: "";
              width: 0%;
              height: 2px;
              position: absolute;
              bottom: 0px;
              background-color: #067bf0;
              -webkit-transition: all 0.35s ease;
              transition: all 0.35s ease;
              left: 0;
            }
            &.active {
              color: rgb(6, 123, 240);
              font-weight: bold;
              &::after {
                content: "";
                width: 100%;
                height: 2px;
                margin-top: 30px;
                background-color: rgb(6, 123, 240);
              }
            }
            // padding: 0px 5px;
          }
        }
      }
    }
  }
  .margin-mobile-mygames {
    display: none;
    &.isSticky {
      .title {
        position: absolute;
      }
    }

    @media screen and (max-width: 700px) {
      display: flex;
      margin-top: 0px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    background-color: $foggy-blue;
    z-index: 5;
    padding: 0 10px 0px 10px;
    flex-direction: column;
    // height: 220px;
    margin-bottom: 0px;
    box-shadow: 0 12px 20px -25px #6c6c6c;
    position: sticky;
    top: -5px;
    overflow: hidden;
    &.isSticky {
      .sticky-filter {
        .sticky-name {
          display: block;
          right: 0px;
          -webkit-transition: all 0.35s ease;
          transition: all 0.35s ease;
          bottom: 35px;
          opacity: 1;
        }
      }
    }
    .sticky-name {
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
      position: absolute;
      right: -50%;
      bottom: 35px;
      font-size: 11px;
      color: #fff;
      background-color: #067bf0;
      padding: 5px 10px;
      border-radius: 3px;
      opacity: 1;
    }
    .top {
      width: 100%;
      overflow-x: auto;
      display: grid;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #dcdcdc;
      grid-template-columns: 160px 1fr;
      padding-bottom: 7px;
      height: 90px;
      .el-switch__label * {
        font-size: 12px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;

        .title-section {
          font-weight: bold;
          font-size: 18px;
          margin: 10px 0px;
        }
        .title-switch {
          margin-top: 5px;
          margin-bottom: 10px;
        }
        margin-bottom: 10px;
      }
      .right {
        justify-content: flex-end;

        .status-filter {
          justify-content: flex-end;
          display: grid;
          min-width: fit-content;
          white-space: nowrap;
          grid-template-columns: minmax(min-content, 120px) minmax(min-content, 800px);
          .el-radio-group:first-child {
            display: grid;
            grid-template-columns: minmax(min-content, 150px);
            margin-right: 5px;
          }
          .el-radio-group {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          .el-radio-button__inner {
            border: 0;
            width: 100%;
            @media screen and (max-width: 830px) {
              font-size: 11px;
            }
          }
        }

        .el-radio-group {
          .el-radio-button {
            border: 3px solid #e6f1fd;

            &:first-child {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
            &:last-child {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
            &:not(:last-child) {
              border-right-width: 1px;
            }

            .el-radio-button__inner {
              padding: 10px 0px;
            }
          }

          &.radio-group-filter {
            // margin-left: 20px;

            .el-radio-button {
              position: relative;

              &:not(:last-child) {
                .el-radio-button__inner {
                  border: none;

                  &:before,
                  &:after {
                    content: "";
                    position: absolute;
                    border: solid transparent;
                    z-index: 2;
                  }

                  &:before {
                    top: -3px;
                    right: -17px;
                    border-width: 19px 0 19px 16px;
                    border-left-color: #e6f1fd;
                  }

                  &:after {
                    top: 0;
                    right: -14px;
                    border-width: 16px 0 16px 14px;
                    border-left-color: white;
                    cursor: pointer;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  }
                }

                .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                  &:after {
                    border-left-color: #067bf0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      width: 100%;

      .competition-banner {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        padding: 30px 40px;
        border-radius: 20px;
        // justify-content: space-between;

        width: 100%;

        background-size: cover;
        background-image: url("~@/assets/images/runner-competition-games@3x.png");
        background-repeat: no-repeat;

        .competition-banner-left {
          align-items: baseline;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            color: #067bf0;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: bold;
          }

          h4 {
            font-weight: bold;
            font-size: 1.1em;
          }
        }

        .competition-banner-right {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

          p {
            padding: 15px 0;
            text-align: left;
            line-height: 1.2em;

            a {
              color: #067bf0;
              text-decoration: none;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .disabled-game {
    background-color: #6c6c6c;
  }
}
</style>

/* eslint-disable import/no-cycle */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { Notification, Message } from 'element-ui';
import uuid from 'uuid';
import CookiesService from '@/tools/cookies';
import router from '@/router';

const axiosInstance = axios.create({
  validateStatus(status) {
    if (status === 503) {
      router.push({ name: 'maintenance' });
      return true;
    }

    if (status >= 500) {
      Notification.error({
        title: 'Error',
        message: 'An error occurred, please refresh or contact support (studio-support@voodoo.io)',
        position: 'bottom-left',
      });
    }
    if (status === 401) {
      if (CookiesService.getToken()) {
        CookiesService.logout();
      }
    }

    return status >= 200 && status < 400;
  },
});

axiosInstance.defaults.params = {};

axiosInstance.interceptors.request.use((config) => {
  if (!config.headers['X-Correlation-ID']) {
    /* eslint-disable no-param-reassign */
    config.headers['X-Correlation-ID'] = `voodooGamesStatsUI-${uuid.v4()}`;
  }

  return config;
}, null, { synchronous: true });


// check notifications part
axiosInstance.interceptors.response.use(
  async response => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (error.response.data.detail) {
      Message({
        message: error.response.data.detail,
        type: 'error',
        duration: 0,
        showClose: true,
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

<template>
  <div class="filter-bar-ks">
    <ul class="list-option">
      <li class="option-item">
        <InputTags
          v-model="currentTags"
          placeholder="Filter by tags"
          :result="searchResult"
          @error="handleTagsError"
          @search="searchTags" />
      </li>
      <li class="option-item">
        <FilterComponentKS :type="type"></FilterComponentKS>
      </li>
      <li class="option-item">
        <el-select
          v-model="sortObj"
          class="sortSelect"
          size="mini"
          filterable>
          <el-option
            v-for="c in sortingList"
            :key="c.sort"
            :label="c.name"
            :value="c.sort"></el-option>
        </el-select>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import FilterComponentKS from '@/components/KnowledgeSharing/FilterComponentKS.vue';
import InputTags from '@/components/InputTags';
import tagsMixin from '@/components/InputTags/mixin';

export default {
  name: 'FilterBarKS',
  components: {
    FilterComponentKS,
    InputTags,
  },
  mixins: [tagsMixin],
  props: {
    type: {
      type: String,
      default: 'cpi',
    },
  },
  data() {
    return {
      searchResult: [],
      sorting: 'createdAt',
      sortingListCtr: [
        {
          name: 'Sort by best CTR',
          sort: 'lastCtr',
          order: 'desc',
        },
        {
          name: 'Sort by most recently tested',
          sort: 'lastIterationDate',
          order: 'desc',
        },
        {
          name: 'Sort by most recently shared',
          sort: 'joinedDate',
          order: 'desc',
        },
      ],
      sortingListCpi: [
        {
          name: 'Sort by best CPI',
          sort: 'lastCpi',
          order: 'asc',
        },
        {
          name: 'Sort by best D1',
          sort: 'lastD1',
          order: 'desc',
        },
        {
          name: 'Sort by best D7',
          sort: 'lastD7',
          order: 'desc',
        },
        {
          name: 'Sort by best Playtime',
          sort: 'lastPlaytime',
          order: 'desc',
        },
        {
          name: 'Sort by most recently tested',
          sort: 'lastIterationDate',
          order: 'desc',
        },
        {
          name: 'Sort by most recently shared',
          sort: 'joinedDate',
          order: 'desc',
        },
      ],
    };
  },
  computed: {
    sortingList() {
      if (this.type === 'cpi') {
        return this.sortingListCpi;
      }
      return this.sortingListCtr;
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.knowledgeSharing.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.knowledgeSharing.order;
    },
    sortObj: {
      get() {
        for (let i = 0; i < this.sortingList.length; i += 1) {
          const element = this.sortingList[i];
          if (element.sort === this.sort) {
            return element.sort;
          }
        }
        return this.sortingList[0].sort;
      },
      set(value) {
        if (value) {
          for (let i = 0; i < this.sortingList.length; i += 1) {
            const element = this.sortingList[i];
            if (element.sort === value) {
              this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
                sort: element.sort,
                order: element.order,
              });
            }
          }
        }
      },
    },
    query() {
      const type = this.type || 'cpi';
      return this.$store.getters['gameAction/queryKs'][type];
    },
    currentTags: {
      get() {
        const array = [];
        this.query.children[1].query.children.forEach((element) => {
          array.push(element.value);
        });
        return array;
      },
      set(value) {
        this.$store.dispatch('gameAction/set_tags_ks', {
          type: this.type,
          value,
        });
      },
    },
  },
  watch: {
    type(value) {
      if (value === 'ctr' && (this.sort === 'lastCpi' || this.sort === 'lastD1' || this.sort === 'lastD7')) {
        this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
          sort: 'createdAt',
          order: 'desc',
        });
      }
      if (value === 'cpi' && (this.sort === 'lastCtr' || this.sort === 'lastCpc' || this.sort === 'lasCpm')) {
        this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
          sort: 'createdAt',
          order: 'desc',
        });
      }
    },
  },
  methods: {
    sortBy(sort, order) {
      this.$store.dispatch('gameAction/updateSortAndOrderByStatus', {
        sort,
        order,
      });
    },
    searchTags(tag) {
      this.$store.dispatch('genreTags/searchTags', tag)
        .then((response) => {
          this.searchResult = response.data;
        }).catch(() => {
          this.searchResult = null;
        });
    },
    handleTagsError() {
      this.isTagsError = true;
    },
  },
};
</script>

<style lang="scss">
.filter-bar-ks {
  .list-option {
    display: flex;

    .option-item {
      margin-left: 10px;

      .el-input {
        // input {
        width: 200px;
        // }
      }
    }
  }
   .inputTags{
    background-color: white;
    height: 28px;
    input{
      margin-top: -5px;
      border: 0;
    }
    .el-tag{
      margin-top: 2px;
      padding: 2px 8px 2px 5px;
    }
  }
}
</style>

<template>
  <el-dialog
    custom-class="action-modal"
    :append-to-body="true"
    :title="'Impersonate user'"
    :visible.sync="modalImpersonate"
    width="30%"
    @closed="closeModal()"
  >
    <div class="formAction">
      <ul>
        <li v-for="user in userList" :key="user.id" data-test-id="studioPageImpersonateModalListItem">
          <div class="user-to-hijack" @click="hijack(user.id)">
            <span>{{ `${user.firstName} ${user.lastName}` }}</span>
          </div>
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal()">
        Cancel
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Message } from 'element-ui';

export default {
  name: 'ImpersonateDialog',
  components: { },
  props: {
    studioId: {
      type: [String, Number],
      required: true,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalImpersonate: this.open,
      userList: [],
    };
  },
  watch: {
    open(value) {
      this.modalImpersonate = value;
      this.getUsersList();
    },
  },
  mounted() {
    this.getUsersList();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async getUsersList() {
      await this.$store.dispatch('adminstudio/getStudioUsersAsync', this.studioId).then((response) => {
        this.userList = response;
      });
    },
    async hijack(id) {
      try {
        await this.$store.dispatch('users/hijackUserId', id);
      } catch (e) {
        const forbid = 'error.hijack.operationforbidden';
        const perm = "you don't have the necessary permissions to impersonate this user";
        const reason = e.message === forbid ? perm : e.message;
        Message({
          message: `Hijack failed: ${reason}`,
          type: 'error',
          duration: 5000,
          showClose: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-to-hijack {
  color: $intense-blue;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>

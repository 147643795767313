<template>
  <div class="links-actions-component">
    <div class="links">
      <i v-if="loading" class="link link-loading el-icon-loading"></i>
      <div v-if="!loading" class="action">
        <el-dropdown
          v-if="actions && actions.length > 0"
          data-test-id="actionButtonActionsDropdown"
          class="buttonAction"
          trigger="click"
          @command="pushAction"
          @click.native.stop>
          <div class="opt">
            <font-awesome-icon icon="ellipsis-h" class="icon action-button-icon-dropdown"/>

            <!-- <i class="vd-svg-hover-options"></i> -->
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in actions"
              :key="index"
              :command="item.id"
              :data-test-id="'actionsButtonAction'+item.id">
              <span :class="item.color">{{ item.name || item.id }}</span>
            </el-dropdown-item>
            <el-dropdown-item v-if="actions.length === 0" disabled>No action available</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LinksActionsComponent',
  props: {
    game: {
      type: Object,
      default: null,
    },
    /**
     * List of actions in the dropdown
     */
    actions: {
      type: Array,
      default: () => [],
    },
    /**
     * loading some action
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pushAction(action) {
      this.$emit('action', action);
    },
  },
};
</script>

<style lang="scss">
.el-dropdown-menu__item {
  .danger {
    color: $color-danger-plain-color;
  }
  .success {
    color: $green-alert;
  }
}
.opt > .icon{
    background-color: $cloudy-blue;
    padding: 5px;
    width: 24px;
    border-radius: 20px;
    color:$light-blue;
}
.opt:hover > .icon {
  color: $intense-blue;
}

.links-actions-component {
  display: flex;

  .action-button-icon-dropdown {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: 24px;
  height: 24px;
}

  .links {
    display: flex;
    align-items: center;
    i {
      transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
      width: 24px;
      height: 24px;
    }
    .link,
    .action {
      padding: 0px;
      display: flex;
      &:hover {
        cursor: pointer;
      }
    }

    .link-loading{
      width: 34px;
    }


    .link {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>

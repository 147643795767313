<template>
  <div class="launch-process-container">
    <p
      class="launch-process-title"
      data-test-id="HomePageLaunchProcessTitle">
      Here's an overview of our game testing and launch process:
    </p>
    <div class="launch-process">
      <div>
        <div class="process-title">
          <span>1</span> {{ kpi.prototype.title }}
        </div>
        <p v-html="kpi.prototype.content"></p>
        <CreateGameButton class="create-game-button"></CreateGameButton>
      </div>
      <div>
        <div class="process-title">
          <span>2</span> {{ kpi.highpotential.title }}
        </div>
        <el-tooltip
          class="tooltip"
          effect="dark"
          placement="top"
        >
          <template slot="content">
            <div
              class="tooltip-text"
              v-html="kpi.highpotential.tooltipText"
            ></div>
          </template>
          <span class="tooltipCTA">{{ kpi.highpotential.tooltipCTA }}</span>
        </el-tooltip>
        {{ kpi.highpotential.firstLine }}
        <p class="combination" data-test-id="HomePageCombination">
          {{ kpi.highpotential.combination }}</p>
        <p class="highpotential-content" data-test-id="HomePageHighpotentialTitle">{{ kpi.highpotential.content }}</p>
        <p class="grey-text" data-test-id="HomePageHighpotentialContent">{{ kpi.highpotential.greyText }}</p>
      </div>
      <div>
        <div class="process-title" data-test-id="HomePageProcessTitle"><span>3</span> {{ kpi.launch.title }}</div>
        <p class="launch-content" data-test-id="HomePageLaunchContent">{{ kpi.launch.content }}</p>
        <p class="grey-text" data-test-id="HomePageLaunchContentGreyText">{{ kpi.launch.greyText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ContentfulService from '@/services/contentful.services';
import CreateGameButton from '@/components/CreateGameButton.vue';

export default {
  name: 'LaunchProcess',
  components: {
    CreateGameButton,
  },
  data() {
    return {
      // default value, in case Contentful fails
      kpi: {
        prototype: {
          title: 'Prototype',
          content:
            'Have a great idea (e.g. innovative mechanic and deep gameplay) for a game? <br><br>Make a prototype and submit a test as soon as possible.',
        },
        highpotential: {
          title: 'High potential prototype',
          tooltipCTA: 'High potential prototypes',
          tooltipText:
            'Historically, a “High Potential” prototype could have a low CPI combined with very poor user retention and low playtime, for example $0.40 CPI, 20% D1, and 6m40s PT or a high CPI combined with very high user retention and playtime, for example $1.50 CPI, 45% D1, and 27m30s PT',
          firstLine: 'have a winning combination of:',
          combination:
            'Cost per install & D1 Retention & Playtime & Market eCPM',
          content:
            'When one of your games reaches this level, it deserves strong focus and time investment through further iterations.',
          greyText:
            'You will be coached and helped by a Voodoo Publishing Manager to give your prototype the best chances of becoming a hit.',
        },
        launch: {
          title: 'Launch',
          content:
            'We treat every launch individually and you’ll systematically have a Publishing Manager take you through launch readiness.',
          greyText:
            'You will be working hand in hand with our experts: Creative, GameOps, User Acquisition and Monetization to scale your game worldwide.',
        },
      },
    };
  },
  async mounted() {
    const contentful = await ContentfulService.fetch('HOMEPAGE');
    if (contentful) {
      this.kpi = contentful.kpi;
    }
  },
};
</script>

<style lang="scss" scoped>
.launch-process-container {
  z-index: 10;

  .tooltip-text {
    line-height: 18px !important;
  }
}

.launch-process-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;

  @media (max-width: 900px) {
    font-size: 14px;
    text-align: center;
  }
}

.launch-process {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 32px;
  grid-template-areas: ". . .";

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 16px 0px;
    grid-template-areas:
      "."
      "."
      ".";
  }

  .create-game-button {
    margin-top: 24px;
  }

  > div {
    border-radius: 40px;
    box-shadow: 2px 2px 10px 5px rgba(6, 123, 240, 0.1);
    background-color: $white;
    padding: 24px;
  }

  .process-title {
    color: $intense-blue;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
    span {
      display: inline-flex;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 10px 5px rgba(6, 123, 240, 0.2),
        inset -2px -2px 5px 0 rgba(0, 0, 0, 0.2),
        inset 2px 2px 5px 0 rgba(255, 255, 255, 0.2);
      background-color: $intense-blue;
      color: $white;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .tooltipCTA {
    color: $intense-blue;
  }

  .combination {
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .highpotential-content {
    margin-bottom: 16px;
  }

  .launch-content {
    margin-bottom: 16px;
  }

  .grey-text {
    color: $grey;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 350px;
}

.el-tooltip__popper.is-dark {
    background: #4D4F5C;
}

.el-tooltip__popper .popper__arrow {
    border-top-color: #4D4F5C;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow::after {
    border-top-color: #4D4F5C;
}
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
    border-left-color: #4D4F5C;
}
</style>

<template>
  <div class="budget-configuration">
    <h1>
      Daily budget on {{ adNetwork.namePlatform }}
    </h1>
    <p v-if="game.isBudgetReadOnly">
      The daily budget increases with the number of videos that you upload to your campaign.
    </p>
    <p v-else>
      We recommend a daily budget based on the number of uploaded videos. Use the slider below to adjust the daily budget you want.
    </p>
    <CustomSlider
      v-model="budget"
      :step="10"
      :min="0"
      :min-lock="minBudget"
      :max="game.maxDailyBudget"
      :disabled="loading"
      :readonly="game.isBudgetReadOnly"
      @change="$emit('manually-set', { newBudget: budget, adNetwork: adNetwork.id })"
    >
      <template v-slot:tooltip>$ {{ budget }}</template>
      <template v-slot:maxValue>$ {{ game.maxDailyBudget }}</template>
    </CustomSlider>

  </div>
</template>

<script>
import CustomSlider from '@/components/Generic/CustomSlider.vue';

export default {
  name: 'BudgetConfiguration',
  components: {
    CustomSlider,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    adNetwork: {
      type: Object,
      required: true,
    },
    nbVideos: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    isManuallySet: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      budget: this.value,
    };
  },
  computed: {
    minBudget() {
      return Math.min(...Object.values(this.game.defaultDailyBudgetPerNbVideos));
    },
    calculatedBudget() {
      return this.game.defaultDailyBudgetPerNbVideos[this.nbVideos];
    },
  },
  watch: {
    nbVideos() {
      this.resetBudget();
    },
    value() {
      this.budget = this.value;
    },
    budget(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.resetBudget();
  },
  methods: {
    resetBudget() {
      if (!this.isManuallySet || !this.value) {
        this.budget = this.calculatedBudget || 0;
      }
    },
  },
};
</script>

<style lang="scss">
.budget-configuration {
  width: 50%;
  margin-bottom: 5px;

  h1 {
    color: $slate;
  }

  p {
    color: $grey;
  }
}
</style>

<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="'How to iterate?'"
      :visible="isVisible"
      width="70%"
      data-test-id="myGamesIterationTutorialModal"
      @close="$emit('close')"
    >
      <TutoIterateStep :game="game"></TutoIterateStep>

      <span slot="footer" class="dialog-footer">
        <el-button data-test-id="myGamesIterationTutorialOkButton" @click="$emit('close')">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TutoIterateStep from '@/components/GameSubmissionSteps/TutoIterateStep.vue';

export default {
  name: 'IterationTutorial',
  components: {
    TutoIterateStep,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

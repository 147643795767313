<template>
  <div :class="$style.wrapper">
    <font-awesome-icon
      v-if="isBackArrow"
      :class="$style.back"
      icon="arrow-left"
      @click="back"/>
    <font-awesome-icon :class="$style.projectIcon" icon="folder" />
    <div :class="$style.title">{{project.name}}
      <EditProjectIcon
        v-if="canEditprojectName"
        @click="openDialog"></EditProjectIcon ></div>
    <el-dialog
      v-if="canEditprojectName"
      custom-class="edit-modal"
      :append-to-body="true"
      :title="'Edit project name'"
      :visible.sync="open"
      width="50%"
    >
      <EditProjectField
        v-if="canEditprojectName"
        :key="open"
        :name="project.name"
        :error="error"
        @edit-project-name="updateName"
        @send-project-name="validate()">
      </EditProjectField>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="(project.name === newName || !!error) || !projectNameIsValid"
          @click="validate()">
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EditProjectIcon from './EditProjectIcon.vue';
import EditProjectField from './EditProjectField.vue';

export default {
  name: 'GameProjectHeader',
  components: {
    EditProjectIcon,
    EditProjectField,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBackArrow: localStorage.routeBeforeProject,
      open: false,
      newName: this.project.name,
      error: null,
      projectNameIsValid: false,
    };
  },
  computed: {
    gamesProjectList() {
      return this.$store.getters['Project/project'].games || [];
    },
    canEditprojectName() {
      for (let i = 0; i < this.gamesProjectList.length; i += 1) {
        const el = this.gamesProjectList[i];
        if (el?.permissions?.request_game_testing) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    $route: {
      handler() {
        this.isBackArrow = localStorage.routeBeforeProject;
      },
      deep: true,
    },
  },
  methods: {
    updateName(value) {
      this.newName = value;
      this.error = null;
      this.projectNameIsValid = value.length >= 3 && value.length <= 25;
    },
    back() {
      this.$router.push(JSON.parse(localStorage.routeBeforeProject));
    },
    openDialog() {
      this.newName = this.project.name;
      this.open = true;
    },
    async validate() {
      if (this.newName !== this.project.name) {
        const req = await this.$store.dispatch('Project/editProject', {
          id: this.project.id,
          name: this.newName,
        });
        if (req === true) {
          this.open = false;
        } else {
          this.error = req;
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  position: sticky;
  top: 50px;
  z-index: 10;
  background-color: $foggy-blue;
  padding: 10px 0 10px 0;
  }

.back {
  color: $light-blue;
  font-size: 26px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    color: $intense-blue;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: $soft-black;
  display: flex;
}

.projectIcon {
  color: $slate;
  font-size: 16px;
  margin-right: $spacing;
  margin-left: $spacing;
  width: 22px;
  height: 22px;
}

</style>

<style lang="scss">
.edit-modal {
  .el-dialog__header {
    background-color: $foggy-blue;
  }
}
</style>

<template>
  <div class="archived-box" :class="{ extended: isArchivedOpen }">
    <div class="archived-header" data-test-id="myGamesArchivedHeader" @click="isArchivedOpen = !isArchivedOpen">
      <el-image :src="iconArchived"></el-image>
      <h1>
        Archived Games
      </h1>

      <div class="right">
        <i :class="isArchivedOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
      </div>
    </div>

    <div v-if="isArchivedOpen" class="archived-inner">
      <div v-if="!isArchivedLoading && myGamesArchived.length === 0" class="no-game">No archived games</div>
      <div
        v-for="(item, ind) in myGamesArchived"
        v-else
        :id="`game-${item.id}`"
        :key="`${item.id}-${ind}`"
        class="game-archived">
        <HeaderCompactStudioGameCard :item="item" @click="gotoGameView(item)"></HeaderCompactStudioGameCard>
      </div>

      <div v-if="isArchivedLoading" class="loader">
        <i class="el-icon-loading" />
      </div>
      <div v-if="!isArchivedLoading && myGamesArchived.length > 0 && !nomore" class="archived-footer" @click="loadArchivedGames">Load more</div>
      <div v-if="nomore && myGamesArchived > 0" class="nomore">no more games</div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import HeaderCompactStudioGameCard from '@/components/HeaderCompactGameCard.vue';
import iconArchived from '@/assets/images/archived@3x.png';

export default {
  name: 'ArchivedListComponent',
  components: {
    HeaderCompactStudioGameCard,
  },
  props: {},
  data() {
    return {
      isArchivedOpen: false,
      isArchivedLoading: false,
      iconArchived,
      nomore: false,
    };
  },
  computed: {
    myGamesArchivedNeedRefresh() {
      return cloneDeep(this.$store.getters['gameAction/myGamesArchivedNeedRefresh']);
    },
    myGamesArchived() {
      return cloneDeep(this.$store.getters['gameAction/myGamesArchived']);
    },
    showGameOnly() {
      return cloneDeep(this.$store.getters['gameAction/showGameOnly']);
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.archived.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.archived.order;
    },
  },
  watch: {
    myGamesArchivedNeedRefresh(value) {
      if (value) {
        this.$store.dispatch('gameAction/setArchivedListNeedRefresh', false);
        this.$store.dispatch('gameAction/reset_gameList_archived');
        this.loadArchivedGames();
      }
    },
    $route(value, old) {
      if (value.path !== old.path) {
        this.$store.dispatch('gameAction/reset_gameList_archived');
        this.loadArchivedGames();
      }
    },
    showGameOnly() {
      this.$store.dispatch('gameAction/reset_gameList_archived');
      this.loadArchivedGames();
    },
    sort() {
      this.$store.dispatch('gameAction/reset_gameList_archived');
      this.loadArchivedGames();
    },
    order() {
      this.$store.dispatch('gameAction/reset_gameList_archived');
      this.loadArchivedGames();
    },
  },
  mounted() {
    this.loadArchivedGames();
  },
  methods: {
    loadArchivedGames() {
      this.isArchivedLoading = true;
      this.$store
        .dispatch('gameAction/getMyGames', {
          status: 'archived',
          loader: {
            loaded: () => {
              this.isArchivedLoading = false;
            },
            complete: () => {
              this.isArchivedLoading = false;
            },
          },
          term: this.$route.query.term || '',
          type: this.$route.params.type || 'cpi',
        })
        .then((result) => {
          if (Array.isArray(result)) {
            if (result.length === 0 && this.myGamesArchived.length > 0) {
              this.nomore = true;
            }
          } else {
            this.nomore = false;
          }
        });
    },
    gotoGameView(item) {
      this.$router
        .push({
          query: {
            game: undefined,
            studio: undefined,
            view: undefined,
            gameid: item.id,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.archived-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);

  margin-bottom: 30px;

  &.extended {
    height: fit-content;
  }

  .archived-header {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    height: 50px;

    .el-image {
      width: 30px;
      margin: 10px;
    }

    h1 {
      display: flex;
      flex-direction: column;
      font-size: 0.9em;

      h3 {
        color: #cecece;
        text-align: left;
        font-size: 0.9em;
      }
    }

    .right {
      margin-left: auto;
      margin-right: 40px;
      font-size: 1.4em;

      .el-icon-arrow-up,
      .el-icon-arrow-down {
        color: #adadad;
      }
    }
  }

  .archived-inner {
    width: 100%;

    .game-archived {
      border-radius: 0;
      box-shadow: none;
      border-top: 1px solid #e9e9f0;
      padding: 3px 0;
      &:hover {
        cursor: pointer;
      }

      .header-compact-game {
        border-bottom: none;
      }
    }
    .loader {
      margin-top: 20px;
      margin-bottom: 20px;

      .el-icon-loading {
        font-size: 1em;
      }
    }
    .no-game {
      height: 40px;
      border-top: 1px solid #e9e9f0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #cbcbcb;
      font-size: 0.9em;
    }
  }

  .archived-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    color: #067bf0;
    border-top: 1px solid #e9e9f0;
  }
  .nomore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    color: #cbcbcb;
    border-top: 1px solid #e9e9f0;
  }
}
.archived-box {
  position: relative;

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-no-game {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #82bdf7;
      line-height: 40px;
    }
    .quote-no-game {
      font-size: 15px;
      text-align: center;
      color: #82bdf7;
      margin-bottom: 30px;
    }
  }
}
</style>

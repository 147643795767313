
import axios from '@/tools/axios';
import gameActionService from '@/services/gameAction.services';

export default class ProjectService {
  static async getProject(id) {
    const filter = {
      limit: 100,
      offset: 0,
      sort: 'createdAt',
      order: 'desc',
      query: {
        logicalOperator: 'all',
        children: [
          {
            type: 'query-builder-rule',
            query: {
              rule: 'projectId',
              operator: '=',
              operand: 'Project id',
              value: id,
            },
          },
        ],
      },
    };


    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/projects/${id}`,
      method: 'get',
    })
      .then(async (r) => {
        const reponse = r.data;
        if (reponse) {
          const rep = await gameActionService.getMyGames(filter, 'projectList');
          reponse.games = rep;
          return reponse;
        }
        return null;
      })
      .catch(() => null);
  }

  static async editProject(project) {
    const data = project;

    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/projects/${data.id}`,
      method: 'put',
      data,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return r;
      })
      .catch(r => r.response.data);
  }

  static async getProjectObject(id) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/projects/${id}`,
      method: 'get',
    })
      .then(async (r) => {
        const reponse = r.data;
        if (reponse) {
          return reponse;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getAllProjects(studioId) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/projects?studioId=${studioId}`,
      method: 'get',
    })
      .then(async (r) => {
        const reponse = r.data;
        if (reponse) {
          return reponse;
        }
        return null;
      })
      .catch(() => null);
  }
}

<template>
  <div>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Campaign settings'"
      :visible="isVisible"
      width="50%"
      @close="$emit('close')"
    >
      <div class="campaign-settings">
        <CampaignSettings
          v-if="isVisible"
          :game-object="game"
          @close-pop-in="$emit('close')"
        ></CampaignSettings>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CampaignSettings from '@/components/Dialogs/CampaignSettings.vue';

export default {
  name: 'CampaignSettingsModal',
  components: {
    CampaignSettings,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.checkboxWrapper">
      <el-checkbox
        v-if="!game.gameAnalyticsId"
        id="checkGA"
        v-model="createGaSlot"
        name="type"
        :disabled="!canCreateGaSlot"
        data-test-id="gameSubmissionCreateGASlotCheckbox"
      >
        <div :class="$style.checkboxLabel">Create a new GameAnalytics slot</div>
      </el-checkbox>
      <div v-else  :class="$style.label">GameAnalytics ID</div>
      <div v-if="createGaSlot && !game.gameAnalyticsId" :class="$style.explanation">
        You will receive an invitation email from GameAnalytics.<br />
        If not, please contact:
        <a href="mailto:studio-support@voodoo.io">studio-support@voodoo.io</a>
      </div>

      <div v-if="!createGaSlot || game.gameAnalyticsId" :class="$style.gaInputWrapper">
        <div :class="$style.explanation">
          Note: only reuse an ID that was already created through the Publishing Platform on a
          previous submission.
        </div>
        <div>
          <el-input
            id="gaSlot"
            v-model="cpGameAnalyticsId"
            class="link-store-input"
            placeholder="e.g. 123456"
            size="small"
            :disabled="!canCreateGaSlot"
            type="text"
            data-test-id="gameSubmissionGAIDInput"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GASlot',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      createGaSlot: true,
      cpGameAnalyticsId: this.game.gameAnalyticsId,
    };
  },
  computed: {
    gamePermissions() {
      return Object.keys(this.game.permissions);
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    canCreateGaSlot() {
      return (
        this.gamePermissions.includes('create_ga_slot')
        || this.myPermissions.includes('create_ga_slot')
      );
    },
  },
  watch: {
    createGaSlot(value) {
      this.$emit('createGASlot', value);
    },
    cpGameAnalyticsId(value) {
      this.$emit('idChange', value);
    },
    game(value) {
      this.cpGameAnalyticsId = value.gameAnalyticsId;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  text-align: left;

  input {
    border-radius : 8px;
  }
}

.checkboxWrapper {
  background-color: $foggy-blue;
  padding: $spacing * 3;
  text-align: left;
}

.checkboxLabel {
  font-size: 12px;
  font-weight: 600;
}

.explanation {
  color: $grey;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 15px;
  margin-top: $spacing;
  border-radius: 4px;
}

.label {
  color: $slate;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.gaInputWrapper {
  margin-top: $spacing * 3;
}
</style>

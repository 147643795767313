/* eslint-disable max-len */ /* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="view-bar">
    <SimpleBar>
      <template v-slot:left>
        <el-popover
          popper-class="column-popover"
          placement="bottom"
          :title="`Preset Games list`"
          width="300"
          trigger="click">
          <el-button
            slot="reference"
            size="mini"
            class="column-button vd-icon"
            data-test-id="studiosPageEditColumnsButton">
            <i class="vd-icon-edit-columns"></i>
            Edit columns
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <div class="kpi-container">
            <draggable v-model="column" group="preset" @update="updateKpiList">
              <transition-group name="card">
                <div v-for="element in column" :key="element.id" class="column-kpi">
                  <el-checkbox
                    v-model="element.isSelected"
                    data-test-id="studiosPageEditColumnsCheckbox"
                    @change="updateKpiList">
                    {{ element.label }}
                    <span v-if="element.id === 'warnings'">(warnings)</span>
                  </el-checkbox>
                  <i class="el-icon-more-outline"></i>
                </div>
              </transition-group>
            </draggable>
          </div>
        </el-popover>
        <el-button
          v-if="canSave && !viewExist"
          type="primary"
          size="mini"
          class="can-save-button"
          :loading="loading"
          @click="saveViewCommand('saveAs')">
          Save view
        </el-button>

        <el-button-group v-if="canSave && viewExist" class="save-button">
          <el-button
            v-if="!loading && !currentView.isDefault && currentView.creator.id === userId"
            type="primary"
            size="mini"
            data-test-id="studiosPageUpdateViewButton"
            @click="updateCurrentView">
            Update {{ currentView.name }}
          </el-button>

          <el-button
            v-if="!loading"
            type="primary"
            size="mini"
            data-test-id="studiosPageSaveAsButton"
            @click="saveViewCommand('saveAs')">
            Save as ...
          </el-button>

          <el-button
            v-if="loading"
            type="primary"
            size="mini">
            <i class="el-icon-loading"></i>
          </el-button>
        </el-button-group>
      </template>
      <template v-slot:right>
        <el-input
          v-model="search"
          size="mini"
          class="search"
          placeholder="Search in list"
          clearable
          data-test-id="studiosPageSearchInput">
          <i slot="prefix" class="el-icon-search"></i>
        </el-input>
        <el-popover
          placement="bottom"
          min-width="600"
          trigger="click"
          content="this is content, this is content, this is content"
        >
          <div>
            <VueQueryBuilder v-model="filter" :rules="studioColumn"></VueQueryBuilder>
          </div>
          <el-button
            slot="reference"
            size="mini"
            class="querybuilder-button vd-icon"
            data-test-id="studiosPageCreateFiltersButton">
            <div class="inner-button-selector">
              <i class="vd-svg-create-filters-dark"></i>
              <span>Create filters </span>
            </div>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </el-popover>
        <el-select
          class="view-select"
          filterable
          clearable
          size="mini"
          :value="currentView.name"
          popper-class="select-view"
          placeholder="Saved views"
          data-test-id="studiosPageSavedViewsSelect"
          @clear="resetView()"
          @change="selectView"
        >
          <i slot="prefix" class="vd-svg-saved-filters-dark"></i>
          <el-option-group v-for="item in views" :key="item.label" :label="item.label">
            <el-option
              v-for="el in item.options"
              :key="el.id"
              :label="el.name"
              :value="el"
              :value-key="el.id"
              data-test-id="studiosPageSavedViewsOptions">
              <span style="float: left">{{ el.name }}</span>
              <span v-if="userId === el.creator.id && !el.isDefault" style="float: right; color: #8492a6; font-size: 13px">
                <i class="el-icon-delete" @click.stop="deleteView(el.id)" />
              </span>
            </el-option>
          </el-option-group>
        </el-select>
      </template>
    </SimpleBar>
    <el-dialog title="Save as" :visible.sync="dialogSaveAsVisible" width="20%">
      <span>Name:</span>
      <el-input
        ref="newName"
        v-model="newName"
        size="mini"
        autocomplete="off"
        data-test-id="studiosPageViewNameInput"
        @keyup.enter.native="isUniqName(newName) ? saveView() : null"
      ></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          data-test-id="studiosPageCancelNewViewButton"
          @click="dialogSaveAsVisible = false">
          Cancel
        </el-button>

        <el-button
          type="primary"
          size="mini"
          :disabled="!!!isUniqName(newName)"
          data-test-id="studiosPageConfirmNewViewButton"
          @click="saveView()">
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable vue/valid-v-for */

import { cloneDeep } from 'lodash';
import draggable from 'vuedraggable';
// import VueQueryBuilder from 'vue-query-builder';
import VueQueryBuilder from '@/components/QueryBuilder/VueQueryBuilder.vue';
import SimpleBar from '@/components/SimpleBar.vue';
import simpleViewContent from '@/../json/simpleContentStudio';

export default {
  name: 'ViewBar',
  components: {
    SimpleBar,
    draggable,
    VueQueryBuilder,
  },
  data() {
    return {
      dialogSaveAsVisible: false,
      newName: '',
      queryBuilder: {},
      rules: [
        {
          type: 'text',
          id: 'vegetable',
          label: 'Vegetable',
        },
        {
          type: 'numeric',
          id: 'execution',
          label: 'Execution',
        },
      ],
    };
  },
  computed: {
    studioColumn() {
      if (this.$store.getters['users/codification'] !== null) {
        return this.$store.getters['users/codification'].columns.studios;
      }
      return [];
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    userId() {
      // return 743;
      return this.$store.getters['users/id'];
      // {label: "Loic Moisan", value: 2283}{label: "alex shea", value: 743} topa : 2431
    },
    userName() {
      return this.pmList.find(el => el.value === this.userId).label || this.$store.getters['users/userName'];
    },
    isPm() {
      for (let i = 0; i < this.pmList.length; i += 1) {
        const element = this.pmList[i];
        if (element.value === this.userId) {
          return true;
        }
      }
      // if (this.userId === 2839) {
      //   return true;
      // }
      return false;
    },
    pmList() {
      return this.codification
        ? cloneDeep(this.codification.publishingManagers)
        // .filter((el) => {
        //   if (listPm.includes(el.label)) {
        //     return true;
        //   }
        //   return false;
        // })
          .sort((a, b) => (a.label < b.label ? -1 : 1))
        : [];
    },
    preSavedViews() {
      const defaultViews = cloneDeep(this.$store.getters['viewsStudio/preSavedViews']);
      for (let i = 0; i < defaultViews.length; i += 1) {
        const element = defaultViews[i];
        if (element.id === 'my-games') {
          if (!this.isPm) {
            defaultViews.splice(i, 1);
            i -= 1;
          } else {
            element.content = element.content.replace(/PMNAMETEMPLATE/g, this.userName);
          }
        }
      }
      return defaultViews;
    },
    views() {
      const myViews = [];
      const othersViews = [];
      const listView = this.$store.getters['viewsStudio/views'];
      for (let i = 0; i < listView.length; i += 1) {
        const element = listView[i];
        if (element.creator.id === this.userId) {
          myViews.push(element);
        } else {
          othersViews.push(element);
        }
      }
      const presaved = this.preSavedViews;
      const preSaved = {
        label: 'Default views',
        options: presaved,
      };

      const myOption = {
        label: 'My views',
        options: myViews,
      };
      if (this.userId === 2839) {
        const othersOption = {
          label: 'Others views',
          options: othersViews,
        };
        return [preSaved, myOption, othersOption];
      }
      return [preSaved, myOption];
    },

    currentView() {
      const current = cloneDeep(this.$store.getters['viewsStudio/currentView']);

      if (current.id === 'my-games') {
        let contentString = JSON.stringify(current.content);
        contentString = contentString.replace(/PMNAMETEMPLATE/g, this.userName);
        current.content = JSON.parse(contentString);
      }
      return current;
    },
    loading() {
      return this.$store.getters['viewsStudio/loading'];
    },
    canSave() {
      return this.$store.getters['viewsStudio/canSave'];
    },
    viewExist() {
      return this.$store.getters['viewsStudio/viewExist'];
    },
    search: {
      get() {
        return cloneDeep(this.$store.getters['viewsStudio/term']) || '';
      },
      set(value) {
        this.$store.dispatch('viewsStudio/setTerm', value);
      },
    },
    filter: {
      get() {
        return cloneDeep(this.$store.getters['viewsStudio/filter']) || '';
      },
      set(value) {
        this.$store.dispatch('viewsStudio/setFilter', value);
      },
    },
    column: {
      get() {
        const col = cloneDeep(this.$store.getters['viewsStudio/columns']);
        for (let i = 0; i < simpleViewContent.columns.length; i += 1) {
          const element = simpleViewContent.columns[i];
          const res = col.find(el => el.id === element.id);
          if (typeof res === 'undefined') {
            col.push({
              ...element,
              isSelected: false,
            });
          }
        }
        return col || '';
      },
      set(value) {
        this.$store.dispatch('viewsStudio/setColumns', value);
      },
    },
  },
  watch: {
    $route: {
      handler() {
        if (Object.keys(this.$route.query).length === 0) {
          this.$store.dispatch('viewsStudio/resetView');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query.view) {
      this.$store.dispatch('viewsStudio/getAllViews', this.$route.query.view);
    }
  },
  methods: {
    updateView() {
      if (this.$route.query.view) {
        this.$store.dispatch('viewsStudio/setViewById', this.$route.query.view);
      }
    },
    deleteView(id) {
      this.$store.dispatch('viewsStudio/deleteView', id);
      if (id === this.currentView.id) {
        this.resetView();
      }
    },
    async updateKpiList() {
      const prest = cloneDeep(this.column);
      prest.sort((a, b) => {
        const isPlus = a.isSelected ? -1 : 1;
        return a.isSelected === b.isSelected ? 0 : isPlus;
      });
      await this.$nextTick();
      this.column = cloneDeep(prest);
    },
    selectView(value) {
      if (value) {
        this.$store.dispatch('viewsStudio/setCurrentView', value);
        if (value.id) {
          if (this.$route.query.view !== value.id && !value.isDefault) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.$router.push({
              query: {
                view: value.id,
                game: this.$route.query.game,
              },
            });
          }
        }
      }
    },
    updateCurrentView() {
      this.$store.dispatch('viewsStudio/updateView', this.currentView);
    },
    async saveViewCommand(command) {
      if (command === 'saveAs') {
        this.newName = '';
        this.dialogSaveAsVisible = true;
        await new Promise(resolve => setTimeout(resolve, 500));
        this.$refs.newName.focus();
      }
    },
    saveView() {
      const view = cloneDeep(this.currentView);
      view.name = this.newName;
      view.id = null;
      this.$store.dispatch('viewsStudio/saveNewView', view);
      this.dialogSaveAsVisible = false;
    },
    resetView() {
      this.$store.dispatch('viewsStudio/resetView');
      this.$router
        .push({
          query: {
            game: this.$route.query.game,
            view: undefined,
            customView: undefined,
            customId: undefined,
          },
        })
        .catch(() => {});
    },
    isUniqName(name) {
      if (name) {
        for (let i = 0; i < this.views.length; i += 1) {
          const element = this.views[i];
          if (name === element.name) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.view-bar {
  .vd-svg-create-filters-dark {
    margin-right: 5px;
  }
  i {
    &.vd-icon-create-filters{
      margin-right: 5px;
      color: #b9bcc8 !important;
    }

  }
  .el-input.search {
    margin-top: 3px;
  }
  .column-button {
    margin-left: 10px;
  }
  .bar-simple {
    .right .el-input {
      width: auto;
    }
    .right {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
  .can-save-button {
    margin-top: 3px;
    margin-left: 10px;
  }
  .save-button {
    margin-top: 2px;
    margin-left: 10px;
  }
  .querybuilder-button {
    padding: 6px 6px 5px 6px;
    margin-top: 1px;
    min-width: auto;
    span {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      overflow: hidden;
      width:auto;
      text-align: left;
      .inner-button-selector {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          span {
            margin-right: 20px;
          }
      }
      button {
        margin-right: 5px;
      }
    }
    ::v-deep .inner-button-selector{
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  i {
    color: #b9bcc8 !important;
  }
}

.vue-query-builder {
  .el-slider__runway {
    width: 250px;
    margin-right: 20px;
  }
  .card {
    border: 1px solid #f7fbff;
    padding: 4px;
    padding: 5px 10px;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    border-left: 2px solid #3293ff63;
    position: relative;
    // &:hover {
    //   .close {
    //     display: block;
    //   }
    // }
    &.depth-2 {
    }

    .card-body {
      padding: 10px;
      border-left: 1px solid #dcdfe6;
      width: 10%;
    }
    .card-body {
      padding: 0 10px 10px 10px;
      border-left: 1px solid #dcdfe6;
      width: 90%;
    }
    .form-inline {
      padding: 10px;
      display: flex;
      .close {
      }
    }
    .match-type-container {
      flex-direction: column;
      min-width: 90px;
      label {
        font-size: 10px;
        color: #c2c2c2;
        padding-bottom: 2px;
      }
      .close {
        margin-top: 4px;
      }
    }
    .query-builder-group {
      &:hover {
        .close {
          display: block;
        }
      }
    }
    .close {
      color: #f9a7a7;
      background-color: #fef0f0;
      border-color: #fde2e2;
    }
    .btn-secondary {
      color: #8cc5ff;
      background-color: #ecf5ff;
      border-color: #d9ecff;
      &:not(.mr-2) {
        margin-left: 20px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 30px;
          margin-left: -30px;
          margin-top: -20px;
          background-color: #dcdfe6;
        }
      }
    }
    .rule-actions {
      padding: 0;
      border-bottom: 1px solid #dcdfe6;
      margin-bottom: 10px;
      margin-top: 5px;
      .form-group {
        min-width: 100px;
      }
    }
    .form-inline {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .form-group {
      margin-bottom: 10px;
    }

    button,
    select,
    input[type="text"],
    input[type="date"],
    input[type="number"] {
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      color: #606266;
      -webkit-appearance: none;
      // text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      padding: 7px 15px;
      font-size: 12px;
      border-radius: 3px;
      margin-right: 10px;
      &::placeholder {
        color: #dcdfe6;
      }
    }
    label {
      margin-right: 10px;
    }
    select {
      background: url("http://www.stackoverflow.com/favicon.ico") calc(100% - 5px) / 12px no-repeat #eee;
      background-image: url("https://lh3.googleusercontent.com/proxy/yBuo6BdwXJssQIlxFS7Xh1R6JKungnozJH8yRnVGWNuHkJABP_7g5zFJSVEDruHaA_8B5Ka2t0vDPRl0_1w-yxuow_OBY2hiaGiFVvykUthZOdKMsJoDKD2jiPP8Ooenyg");
      padding: 7px 20px 7px 7px;
      &:before {
        content: "\e79a";
      }
    }
  }
}

.column-popover {
  .column-kpi {
    padding: 5px 10px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    &:hover {
      cursor: grab;
    }
  }
  .card {
    transition: all 0.5s;
  }
  .card-enter,
  .card-leave-to {
    opacity: 0;
    transform: scale(0);
  }
  .card-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .card-move {
    opacity: 1;
    transition: all 0.5s;
  }
}
</style>

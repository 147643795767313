<template>
  <div class="alert-error-messages" data-test-id="errorAlertMessageWrapper">
    <transition-group name="message-error" tag="ul">
      <li
        v-for="(message, index) in localMessages"
        :id="message.id"
        :key="JSON.stringify(message)+message.id"
        class="message-error-item"
        :class="{ simple: !message.actionable_link }">
        <font-awesome-icon icon="times" class="icon times" @click="remove(index)"/>
        <div class="content">
          <font-awesome-icon icon="exclamation-triangle" class="icon exclamation-triangle"/>
          <div v-if="message.detail" class="text" v-html="message.detail">
          </div>
          <div v-else class="text">
            <a
              v-if="message.resolveProperties"
              class="link"
              @click="goto(index, message.resolveProperties)">Game (id:{{message.resolveProperties.id}})</a>:
            <span v-html="message.message"></span>
          </div>

        </div>
        <div class="error-footer">
          <div v-if="message.actionable_link" class="left" @click="goToActionableLink(message.actionable_link)">
            <span>Solve now</span>
          </div>
          <!-- // TODO: checked with jeremy - only when error log center is available -->
          <!-- <div class="right" @click="remove(index)">
            <span>Save for later</span>
          </div> -->
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>

import Router from '@/router';

export default {
  name: 'AlertMessage',
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localMessages: this.messages,
    };
  },
  watch: {
    messages: {
      handler(value) {
        this.localMessages = value;
      },
      deep: true,
    },
  },
  methods: {
    remove(id) {
      this.localMessages.splice(id, 1);
      this.$emit('remove', id);
    },
    goToActionableLink(url) {
      window.open(url, '_blank');
    },
    goto(index, resolveProperties) {
      this.localMessages.splice(index, 1);
      const query = {
        game: undefined,
        studio: undefined,
        view: undefined,
      };
      query[`${resolveProperties.type}id`] = resolveProperties.id;

      Router.push({
        query: {
          game: undefined,
          studio: undefined,
          view: undefined,
          gameid: resolveProperties.id,
        },
      }).catch(() => {});
    },
  },

};
</script>

<style lang="scss" scoped>

.message-error-enter, .message-error-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.message-error-leave-active {
  position: absolute !important;
}

.alert-error-messages {
  position: fixed;
  top: 50px;
  right: 40px;
  display: inline-block;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1000;
  @media screen and (max-width: 700px) {
    top: 0px;
    right: 20px;
  }

  .message-error-item{
    width: 383px;
    position: relative;
    background-color: $light-red;
    border: 1px solid $red-ion;
    border-radius: 5px;
    overflow: hidden;
    font-size: 12px;
    margin-bottom: 10px;
    transition: all 0.5s;
    margin-bottom: -100px;
    @media screen and (max-width: 700px) {
      width: auto;
    }
    &.simple {
      margin-bottom: -110px !important;
      .error-footer{
        border-top: 0px;
        min-height: 40px;
      }
    }

    &:hover {
      margin-bottom: 10px !important;
    }
    &:hover ~ .message-error-item {
      margin-bottom: 10px !important;
    }

    .icon{
      width: 14px;
      height: 14px;
      &.exclamation-triangle{
        color: $red-alert;
      }
      &.times{
        color: $red-ion;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 16px;
        height: 16px;
        &:hover {
          color: $red-alert;
          cursor: pointer;
        }
      }
    }
    .link{
      &:hover{
        text-decoration: underline;
      }
    }

    .content{
    padding: 20px 20px 10px 20px;
      display: flex;
      line-height: 15px;
      .text {
        display: inline;
        padding: 0 10px;
        min-height: 50px;
        a {
          color:$intense-blue;
        }
        a:hover {
          cursor: pointer;
        }
        &:hover {
          cursor:initial;
        }
      }
    }
    .error-footer{
      display: flex;
      justify-content: space-around;
      border-top: 1px solid $red-ion;
      color: $red-alert;
      .left, .right {
        width: 100%;
        padding: 9px;
        display: flex;
        justify-content: space-around;
        &:hover{
          background-color: $red-ion;
          cursor: pointer;
        }
      }
      .left {
        font-weight: 900;
      }
    }
  }

}
</style>

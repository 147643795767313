<template>
  <div class="ks-game-card" @click="gotoGameView(item.id)">
    <div class="video">
      <video
        :id="`video-${item.id}`"
        height="100%"
        controls
        muted="true"
        loop
        @mouseover="play(`video-${item.id}`)"
        @mouseleave="pause(`video-${item.id}`)">
        <source :src="item.lastVideo" type="video/mp4" />
      </video>
    </div>
    <div class="game-infos">
      <div class="top-card">
        <div class="basic-infos">
          <GameIcon :game="item" class="game-logo" small></GameIcon>
          <div class="game-desc">
            <div class="game-name">
              <span
              ><b>{{ item.name }}</b></span
              >
            </div>
            <div>
              <img style="width: 12px; margin-bottom: -1px;margin-right:5px;" :src="item.platform === 'android' ? iconAndroid : iconApple" />
              <span>• {{ item.nbIterations }} iterations</span>
            </div>
          </div>
        </div>
        <div class="right" @click.stop>
          <el-button
            v-if="!item.isPinned"
            type="primary"
            size="mini"
            :loading="item.loading"
            @click="setPinned({ gameId: item.id, value: true })">
            <font-awesome-icon icon="heart" class="icon-heart" />

            Add to favorites
          </el-button>

          <div v-else class="container-is-pinned">
            <div class="isPinned">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.108 0c-.817 0-1.637.654-2.11 1.11C4.22.375 3.505.003 2.873.003 2.062.003 1.409.26.877.789.312 1.349 0 2.095 0 2.889s.311 1.54.877 2.101c.31.308 3.347 3.666 3.383 3.706a1.041 1.041 0 001.477-.004l3.386-3.631A2.942 2.942 0 0010 2.959c0-.794-.312-1.54-.877-2.1C8.541.281 7.883 0 7.108 0z" fill="#58C783" fill-rule="evenodd"/></svg>
              Favorite
            </div>
            <el-button
              class="button-remove-pinned"
              type="primary"
              size="mini"
              :loading="item.loading"
              @click="setPinned({ gameId: item.id, value: false })">
              remove
            </el-button>
          </div>
        </div>
      </div>
      <div class="bottom-card">
        <div class="tags">
          <div v-for="tag in splitStringInArray(item.tags)" :key="tag" class="tag">
            {{ tag }}
          </div>
        </div>
        <div class="ite-date">Last iteration: {{ item.lastIterationDate | localeDate }}</div>
      </div>
      <div class="global-game-stats" :class="{ isCtr: item.isCtr }">
        <div class="adnetworks">
          <img v-if="!item.adnetworks" :src="getAdnetworkIcon('Facebook')" />
          <img v-for="adnetworkLabel in splitStringInArray(item.adnetworks)" :key="adnetworkLabel" :src="getAdnetworkIcon(adnetworkLabel)" />
        </div>
        <div class="kpis">
          <div class="kpi">
            <div class="label">
              CPI:
              <b
              ><span v-if="showValue(item.lastCpi)">${{ item.lastCpi.toFixed(2) }}</span></b
              >
            </div>
          </div>
          <div class="kpi">
            <div class="label">
              D1:
              <b
              ><span v-if="showValue(item.lastD1)">{{ (item.lastD1 * 100).toFixed(0) }}%</span></b
              >
            </div>
          </div>
          <div class="kpi">
            <div class="label">
              D7:
              <b
              ><span v-if="showValue(item.lastD7)">{{ (item.lastD7 * 100).toFixed(0) }}%</span></b
              >
            </div>
          </div>
          <div class="kpi">
            <div class="label">
              Installs:
              <b>{{ item.lastInstalls }}</b>
            </div>
          </div>
          <div class="kpi">
            <div class="label">
              Playtime:
              <b>{{ item.lastPlaytimeHumanReadable }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="video-mobile">
        <video
          :id="`video-mobile-${item.id}`"
          controls
          muted="true"
          loop
          @mouseover="play(`video-mobile-${item.id}`)"
          @mouseleave="pause(`video-mobile-${item.id}`)">
          <source :src="item.lastVideo" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import iconApple from '@/assets/images/i-os.svg';
import iconAndroid from '@/assets/images/android.svg';
import tagIcon from '@/assets/images/tag.svg';
import videoIcon from '@/assets/images/placeholder-game-icon.svg';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import iconFacebook from '@/assets/images/icon-facebook.svg';
import iconSnapchat from '@/assets/images/icon-snapchat.svg';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';

export default {
  name: 'KsGameCard',
  components: { GameIcon },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iconFacebook,
      iconSnapchat,
      videoIcon,
      tagIcon,
      iconApple,
      iconAndroid,
      gameIcon,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    gotoGameView(id) {
      this.$router
        .push({
          query: {
            gameKsId: id,
          },
        })
        .catch(() => null);
    },
    setPinned(value) {
      this.$store.dispatch('gameAction/pineAGame', value);
    },
    showValue(kpi) {
      if (kpi == null) {
        return false;
      }
      return true;
    },
    splitStringInArray(item, separator = '|') {
      if (!item || item === separator) return [];
      if (typeof item === 'string') {
        return item.split(separator);
      }
      return item;
    },
    getAdnetworkIcon(adnetworkName) {
      const adnetworkMapping = {
        Facebook: iconFacebook,
        Snapchat: iconSnapchat,
      };
      return typeof adnetworkMapping[adnetworkName] === 'undefined' ? null : adnetworkMapping[adnetworkName];
    },
    mute(value) {
      const video = document.getElementById(value);
      video.muted = true;
    },
    unMute(value) {
      const video = document.getElementById(value);
      video.muted = false;
    },
    play(value) {
      const video = document.getElementById(value);
      video.play();
      this.unMute(value);
    },
    pause(value) {
      const video = document.getElementById(value);
      video.pause();
      this.mute(value);
    },
  },
};
</script>

<style lang="scss">
.ks-game-card {
  display: flex;
  width: 100%;
  border-radius: 10px;
  .game-infos {
    width: 100%;
    &:hover {
      .top-card {
        cursor: pointer;
        background-color: var(--game-card-background-color-hover);
      }
      .bottom-card {
        cursor: pointer;
        background-color: var(--game-card-background-color-hover);
      }
    }
    .icon-heart{
      margin-right: 5px;
    }
    .game-logo {
      margin-right: 10px;
    }
    .top-card {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .basic-infos {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .game-desc {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 35px;
          font-size: 12px;
          text-align: left;
          .game-name {
            text-align: left;
            color: $intense-blue;
          }
        }
      }
      @media screen and (max-width: 800px) {
        padding: 5px 10px;
      }
    }
    .bottom-card {
      padding: 0px 20px 20px 20px;
      border-bottom: 1px solid #e9e9f0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tags {
        display: flex;
        // padding: 0 20px;
        overflow: auto;
        flex-wrap: wrap;
        height: 30px;

        @media screen and (max-width: 800px) {
          padding: 0 10px;
        }
        .tag {
          padding: 5px 10px;
          background-color: $cloudy-blue;
          color: $intense-blue;
          border-radius: 5px;
          font-size: 12px;
          margin-bottom: 5px;
          line-height: 14px;
        }
        .tag + .tag {
          margin-left: 5px;
        }
      }
    }
    .ite-date {
      font-size: 12px;
      color: $slate;
    }
    .global-game-stats {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      .label {
        margin-top: 0px;
        span {
          color: #232323;
        }
      }

      .adnetworks {
        display: flex;
        justify-content: flex-start;
        margin-right: 30px;
        img + img {
          margin-left: -5px;
        }
      }
      .kpis {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 700px;
        .kpi {
          font-size: 14px;
          .label {
            line-height: 14px;
          }
          &.mini {
            font-size: 12px;

            color: #4c4f5e;
          }
        }
      }

      @media screen and (max-width: 800px) {
        padding: 10px;
      }
    }
    .right {
      @media screen and (max-width: 800px) {
        margin-top: 10px;
      }
      .container-is-pinned {
        &:hover {
          .isPinned {
            display: none;
          }
          .button-remove-pinned {
            display: block;
          }
        }
        .button-remove-pinned {
          display: none;
        }
      }
      button {
        height: 34px;

        &:hover,
        &:active,
        &:focus {
          svg > path {
            fill: white;
          }
          #fill {
            fill: white;
          }
        }
      }
      .isPinned {
        background-color: $light-green;
        color: $green-alert;
        padding: 10px 15px;
        border-radius: 5px;
        svg > path {
          fill: $green-alert;
        }
      }
    }
  }
  .video-mobile {
    display: none;
    background-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media screen and (max-width: 700px) {
      display: block;
      video {
        width: 50%;
      }
    }
  }
  .video {
    min-width: 220px;
    height: 197px;
    background-color: $font-basic-color;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    video {
      max-width: 200px;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
    @media screen and (max-width: 800px) {
      min-width: 150px;
      height: 160px;

      video {
        height: 100%;
        max-width: 140px;
      }
    }
  }
}
</style>

<template>
  <div class="game-infos" :class="{ isPmView: isPmView }">
    <GameIcon :game="game" class="game-logo"></GameIcon>
    <div class="names">
      <span class="game-name"  data-test-id="gameInfosGameName">
        {{ `${game.name}` }}
      </span>

      <span
        v-if="isPmView && game.studio"
        data-test-id="gameInfosStudioName"
        class="studio-name"
        @click="e => goToStudioPage(game.studio.id, e)">
        {{ game.studio.studioName }}
        <div data-test-id="gameInfosActivityState" :class="`activity ${game.activityState}`"></div>
      </span>

      <!-- <span v-if="game.createdAt" class="createdAt"> Creation date: {{ game.createdAt | localeDate }} </span> -->
      <div v-if="!!$slots.default" class="protoscore">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import tools from '@/tools/tools';

export default {
  name: 'GameInfos',
  components: { GameIcon },
  props: {
    /**
     * Game object
     */
    game: {
      type: Object,
      default: () => null,
    },
    /**
     * Show differently on Pm view
     */
    isPmView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToStudioPage(id, event) {
      if (!id) {
        return;
      }
      tools.openUrl(this.$router, {
        name: 'studio',
        params: { id },
      }, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.game-infos {
  display: flex;
  align-items: center;

  &.isPmView {
    .names {
      .createdAt {
        display: none;
      }
      .studio-name {
        display: flex;
        color: $intense-blue;
        font-size: 12px;
        margin-right: auto;
        align-items: center;
        .activity {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          margin-top: 2px;
          margin-left: 5px;
          &.active {
            background-color: $activity-state-active;
          }
          &.inactive {
            background-color: $activity-state-inactive;
          }
          &.churned {
            background-color: $activity-state-churned;
          }
        }
        &:hover {
          cursor: pointer;
          color: $blue-label-expand;
        }
      }
    }
  }
  .names {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    line-height: 17px;
    justify-content: space-evenly;
    text-align: left;
    margin: 0 10px;
    span {
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      max-width: 100%;
    }
    .game-name {
      color: $soft-black;
      font-size: 18px;
      margin-right: auto;
      font-weight: 600;
    }

    .createdAt {
      color: $grey;
      font-size: 12px;
      text-align: left;
    }
  }

  .protoscore {
    margin-top: 11px;
    display: inline;
  }
}
</style>

import { cloneDeep } from 'lodash';
import axios from '@/tools/axios';
import { Message } from 'element-ui';

export default class ViewService {
  static async getAllViews() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-views`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getAllViewsStudio() {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-views`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data.filter((el) => {
            const cont = JSON.parse(el.content);
            if (cont.type === 'studios') {
              return true;
            }
            return false;
          });
        }
        return null;
      })
      .catch(() => null);
  }

  static async createNewView(view) {
    const newView = cloneDeep(view);
    newView.content = JSON.stringify(newView.content);
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-view`,
      data: newView,
      method: 'post',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch((e) => {
        Message({
          message: e.response.data.msg,
          type: 'error',
          duration: 5000,
          showClose: true,
        });
      });
  }

  static async getView(viewId) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-view/${viewId}`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateView(view) {
    const newView = cloneDeep(view);
    newView.content = JSON.stringify(newView.content);
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-view/${view.id}`,
      method: 'put',
      data: newView,
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async deleteView(id) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/ui-view/${id}`,
      method: 'delete',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }
}

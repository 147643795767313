/* eslint-disable no-useless-concat */
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import tools from '@/tools/tools';

const contentful = require('contentful');

const isProd = process.env.NODE_ENV === 'production';

const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  environment: process.env.VUE_APP_CONTENTFUL_ENV, // defaults to 'master' if not set
  accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN,
});

const STEPS = {
  FACEBOOK_SETUP: {
    id: '3GdUAM9jDXgdhcTKljkLG9',
    label: 'FACEBOOK_SETUP_CONTENT_ID_STEP',
  },
  FINALIZE_APP_SETUP: {
    id: '5UGofljx1UAFrII2RXqDHR',
    label: 'FINALIZE_APP_SETUP_CONTENT_ID_STEP',
  },
  PLAYSTORE_PUBLISHING: {
    id: '3P2oW1RWpqlBrQNhAwPJan',
    label: 'PLAYSTORE_PUBLISHING_CONTENT_ID_STEP',
  },
  APPSTORE_PUBLISHING: {
    id: '27BUEXOaBeXL6VE4pxRSxE',
    label: 'APPSTORE_PUBLISHING_CONTENT_ID_STEP',
  },
  GAME_BUILDING: {
    id: '7bDx85RgkcXDLyvQs4KBug',
    label: 'GAME_BUILDING_CONTENT_ID_STEP',
  },
  TINY_SAUCE: {
    id: '5y4w9PgmGGicH9qcbld9ah',
    label: 'TINY_SAUCE_CONTENT_ID_STEP',
  },
  GENERAL_ALERT: {
    id: '3kGIhazzyyd353ffHJT7PS',
    label: 'GENERAL_ALERT_CONTENT_ID_STEP',
  },
  HOMEPAGE: {
    id: '3TtnpLXckPn0gHeoygCttg',
    label: 'Homepage',
  },
  LINKTOSTORE: {
    id: '38hdGrDMZ3ClTfUfEgiB1j',
    label: 'LINK_TO_STORE_CONTENT',
  },
  WARNINGS: {
    id: '4fvnJsoz0RCyyh79goLyfF',
    label: 'WARNINGS',
  },
};
const options = game => ({
  renderMark: {
    [MARKS.BOLD]: text => `<strong>${text}</strong>`,
    [MARKS.CODE]: text => `<pre><code>${text}</code></pre>`,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
    [BLOCKS.UL_LIST]: (node, next) => ` <ul class="hyphen">${next(node.content)}</ul>`,
    [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const clas = node.data.target.fields.title.includes('isandroid') ? 'is-android-img' : 'is-ios-img';
      const isAndroid = node.data.target.fields.title.includes('isandroid');
      const isIos = node.data.target.fields.title.includes('isios');
      const isFacebook = node.data.target.fields.title.includes('isfacebook');
      const isSnapchat = node.data.target.fields.title.includes('issnapchat');
      if (!isAndroid && !isIos && !isFacebook && !isSnapchat) {
        return `<img
        name="${node.data.target.fields.title}"
        class="${clas} image-from-contentful"
        src=${`https://${node.data.target.fields.file.url}`}
      />`;
      }
      if (tools.isfacebookCampaign(game) && isFacebook) {
        return `<img
        name="${node.data.target.fields.title}"
        class="${clas} image-from-contentful"
        src=${`https://${node.data.target.fields.file.url}`}
      />`;
      }
      if (tools.isSnapChatCampaign(game) && isSnapchat) {
        return `<img
        name="${node.data.target.fields.title}"
        class="${clas} image-from-contentful"
        src=${`https://${node.data.target.fields.file.url}`}
      />`;
      }
      return (isAndroid && game.platform === 'android') || (isIos && game.platform === 'ios') ? `<img
        name="${node.data.target.fields.title}"
        class="${clas} image-from-contentful"
        src=${`https://${node.data.target.fields.file.url}`}
      />` : null;
    },
  },
  [INLINES.EMBEDDED_ENTRY]: (node) => {
    // target the contentType of the EMBEDDED_ENTRY to display as you need

    if (node.data.target.sys.contentType.sys.id === 'code') {
      return `
            <pre>
              <code>${node.data.target.fields.body}</code>
            </pre>`;
    }

    return null;
  },
});
export default class ContentfulService {
  static async getEntry(entryId = 'NO_ENTRY_ID', label = 'NO_LABEL', game) {
    try {
      const rep = await client.getEntries({
        'sys.id[in]': entryId,
      });
      if (!rep.items[0]) {
        if (isProd) {
          analytics.track('Contentful error', {
            label: `${label} : No id: ${entryId} found`,
          });
        }

        return null;
      }
      const entry = rep.items[0];

      const obj = {};
      const option = game ? options(game) : {};
      // eslint-disable-next-line array-callback-return
      Object.keys(entry.fields).map((key) => {
        obj[key] = entry.fields[key].nodeType === 'document' ? documentToHtmlString(entry.fields[key], option) : entry.fields[key];
      });

      return obj;
    } catch (err) {
      if (isProd) {
        analytics.track('Contentful error', {
          label: `${label}  : ${entryId} : ${err}`,
        });
      }

      return null;
    }
  }

  static fetch(step, game) {
    const currentStep = STEPS[step];
    return currentStep ? this.getEntry(currentStep.id, currentStep.label, game) : null;
  }
}

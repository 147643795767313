<template>
  <span class="info-tooltip">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.334 3.152a1.816 1.816 0 101.735 1.894l.001-.04a1.8 1.8 0 00-1.736-1.85v-.004zM9.246 7.6H7.462c-.13.001-.259.02-.384.054a.371.371 0 00-.292.31.356.356 0 00.2.412c.192.09.399.145.61.164.556.026.78.27.696.842-.156 1.07-.366 2.132-.546 3.2-.122.72-.224 1.442-.342 2.162-.158.97.746 1.88 1.464 2 .425.06.855.087 1.284.082.92-.001 1.8-.382 2.43-1.052a.925.925 0 00.25-.386.474.474 0 00-.084-.368.363.363 0 00-.314-.034c-.164.054-.31.166-.47.236a2.94 2.94 0 01-.584.2.57.57 0 01-.704-.508 1.905 1.905 0 01-.018-.564c.068-.464.164-.924.248-1.384.228-1.255.46-2.51.694-3.766.064-.344.172-.682.1-1.044a.6.6 0 00-.64-.557l-.012.001H9.246zM10.01 0c5.523.006 9.995 4.487 9.99 10.01-.006 5.523-4.487 9.996-10.01 9.99C4.467 19.994-.006 15.513 0 9.99v-.016C.014 4.457 4.493-.006 10.01 0z" fill="#E6F1FD" fill-rule="evenodd"/></svg>
    <div class="content">
      <div class="arrow"></div>
      <slot></slot>
    </div>
  </span>
</template>

<style lang="scss">
.info-tooltip {
  position: relative;

  margin-left: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  .content {
    display: none;
    width: max-content;
    max-width: 500px;
    z-index: 1;
    padding: 15px 20px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    color: $slate;
    font-size: 10px;
    left: -50px;
    margin-top: 10px;

    position: absolute;

    .arrow {
      width: 50px;
      height: 25px;
      position: absolute;
      top: -50%;
      left: 32px;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: white;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 100%;
        left: 50%;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &:hover {
    svg {
      path {
        fill: $light-blue;
      }
    }
    > .content {
      display: block;
    }
  }
}
</style>

<script>
export default {
  name: 'InfoTooltip',
};
</script>

<template>
  <div class="request-testing-placeholder">
    <div class="top-content">
      <i class="vd-svg-coffee" data-test-id="gameSubmissionCreationProcessingIcon"></i>
      <div class="content">
        <span class="content-title" data-test-id="gameSubmissionCreationProcessingTitle"><b>Time for coffee!</b></span
        ><br />
        <span
          v-if="!isLinkCampaign"
          class="content-text"
          data-test-id="gameSubmissionCreationProcessingDescription">Your campaign creation is in progress. It may take a few minutes…</span>
        <span v-else class="content-text">We are linking your campaign. It may take a few minutes...</span>
      </div>
    </div>
    <div class="loader" data-test-id="gameSubmissionCreationProcessingLoadingText"><i class="el-icon-loading"></i> Processing</div>
  </div>
</template>

<script>
export default {
  name: 'RequestTestingPlaceholder',
  components: {},
  props: {
    isLinkCampaign: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.request-testing-placeholder {
  z-index: 2;
  position: sticky;
  bottom: 0;
  border-top: 1px solid $border-top-placeholder-color;
  background-color: $white;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px 20px 20px 20px;
  font-size: 12px;
  .top-content {
    display: grid;
    grid-template-columns: 30px auto;
    i {
      width: 30px;
      height: 30px;
      margin: 10px 0px 10px 0px;
    }
    .content {
      padding: 10px;
      line-height: 15px;
      .content-title {
        color: $placeholder-request-testing-content-title-color;
        font-weight: 900;
      }
      .content-text {
        color: $slate;
      }
    }
  }
  .loader {
    color: $intense-blue;
    padding: 0 0 10px 0;
    i {
      margin-right: 5px;
    }
  }
}
</style>

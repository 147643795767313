<template>
  <div v-if="['upload', 'stats'].includes(type)" class="video-game">
    <el-button
      v-if="type === 'upload' && game.isManagedByVoodoo"
      data-test-id="myGamesPendingVideoPreviewButton"
      class="videos-on"
      size="small"
      type="primary"
      :disabled="!!game.loadingVideo"
      plain
      @click="openModal()">
      <i v-if="!game.loadingVideo" class="vd-svg-videos-on"></i>
      <i v-if="game.loadingVideo" class="el-icon-loading"></i>
    </el-button>
    <el-button
      v-if="type === 'stats'"
      data-test-id="myGamesPendingVideoPreviewButton"
      size="small"
      :disabled="!!game.loadingVideo"
      type="primary"
      plain
      @click="openModalIteration()">
      <i class="el-icon-video-camera"></i>
    </el-button>
    <el-dialog
      v-if="game"
      :append-to-body="true"
      :title="game.status !== 'pending_testing' ? 'Edit your videos' : 'Your videos'"
      :visible="modalVideo"
      :before-close="closeNewGameDialog"
      :custom-class="'upload-video-modal'"
      width="680px"
      data-test-id="myGamesPendingVideoPreviewModal"
    >
      <UpdateVideos :game="game"></UpdateVideos>
    </el-dialog>
  </div>
</template>

<script>
import UpdateVideos from '@/components/UpdateVideos.vue';

export default {
  name: 'VideoButton',
  components: {
    UpdateVideos,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modalVideo: false,
      modalIteration: false,
    };
  },
  computed: {
    type() {
      if (!this.game.front_status && (this.game.status === 'in_progress' || this.game.status === 'is_iterating' || this.game.status === 'pending_sdk_integration')) {
        return 'upload';
      }

      if (this.game.front_status === 'is_iterating' || this.game.front_status === 'in_progress' || this.game.front_status === 'pending_approval') {
        return 'upload';
      }

      if (this.game.front_status === 'in_testing' || this.game.front_status === 'finished') {
        return 'stats';
      }
      return 'none';
    },
    loadingUpload() {
      return this.$store.getters['gameAction/videoLoading'];
    },
  },

  methods: {
    uploadFile(value) {
      const form = new FormData();
      form.append('attached', value.videos[0].file);
      form.append('ad_network', 'facebook');
      const payload = {
        form,
        game: this.game,
        cropping: value.videos[0].croppingInfos,
      };
      this.$store.dispatch('gameAction/uploadVideoFile', payload);
    },
    closeNewGameDialog() {
      this.modalVideo = false;
    },
    closeiterationDialog() {
      this.modalIteration = false;
    },
    submitGame() {
      // TO DO
    },
    openModal() {
      // TO DO
      if (this.game.isIterating && !this.game.iterationsVideos) {
        this.$store.dispatch('gameAction/getVideoAvailable', this.game);
      }
      this.modalVideo = true;
    },
    openModalIteration() {
      if (!this.game.iterationsVideos) {
        this.$store.dispatch('gameAction/getVideoAvailable', this.game);
      }
      this.modalIteration = true;
    },
  },
};
</script>

<style lang="scss">
.video-game {
  padding: 5px 23px;
  .videos-on {
    padding: 4px 5px 4px 5px;
  }
}
.video-modal {
  .el-dialog__header {
    background-color: #f7fbff;
  }
  .content {
    padding: 0 0 20px 0;
  }
  .content-video {
    min-width: 680px;
  }
}
.iteration-modal {
  padding-bottom: 20px;
}
</style>

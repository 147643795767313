<template>
  <div v-show="isActive" :class="$style.wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      default: 'Tab',
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}
</style>

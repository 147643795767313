<template>
  <div class="game-detail-view">
    <transition name="slide-up">
      <ul v-if="game && !loading">
        <li>
          <div class="top-infos">
            <div class="type">
              <i v-if="!game.isManagedByVoodoo && canCreateAd" class="vd-svg-manual" />
              <i v-if="game.isManagedByVoodoo && canCreateAd" class="vd-svg-auto" />
              <span v-if="!game.isManagedByVoodoo">Manual submission</span>
              <span v-if="game.isManagedByVoodoo">Automatic submission</span>
            </div>
            <div class="platform">
              <i v-if="game.platform === 'ios'" class="vd-svg-ios" />
              <i v-if="game.platform === 'android'" class="vd-svg-android" />
              <span v-if="game.platform === 'ios'">iOS platform</span>
              <span v-if="game.platform === 'android'">Android platform</span>
            </div>
          </div>
        </li>
        <li>
          <span v-for="tag in game.tags" :key="tag.genre" class="tag">{{ tag.genre }}</span>
        </li>
        <li>
          <div class="links">
            <div class="store link">
              <img
                v-if="game.platform === 'ios'"
                style="width: 30px;"
                :src="appStore"
                @click="goto(game.storeUrl)" />
              <img
                v-if="game.platform === 'android'"
                style="width: 30px;"
                :src="playStore"
                @click="goto(game.storeUrl)" />
              <span>Store</span>
            </div>
            <div v-if="game.gameAnalyticsUrl" class="ga link" @click="goto(game.gameAnalyticsUrl)">
              <img style="width: 30px;" :src="ga" />
              <span>GameAnalytics</span>
            </div>
            <div v-if="game.facebookUrl" class="facebook link" @click="goto(game.facebookUrl)">
              <img style="width: 30px;" :src="fb" />
              <span>Facebook</span>
            </div>
            <div class="feedback link" @click="viewFeedback()">
              <img style="width: 30px;" :src="chat" />
              <span>Feedback</span>
            </div>
            <div v-if="!game.isCtr" class="button clickable link" @click="modalIdsShow = true">
              <i class="ids vd-svg-game-id-icon" />
              <span>Ids</span>
            </div>
          </div>
        </li>
        <li v-for="(item, index) in actionsAvailable.secondary" :key="index" @click="pushAction(item)">
          <span :class="item.color">{{ item.name || item.id }}</span>
        </li>
      </ul>
    </transition>
    <div v-if="loading">
      <i class="el-icon el-icon-loading"></i>
    </div>
    <el-dialog
      custom-class="ids-modal"
      :append-to-body="true"
      :title="'Game IDs'"
      :visible.sync="modalIdsShow"
      width="60%">
      <GameIds :game-object="game" @close-pop-in="modalIdsShow = false"></GameIds>
    </el-dialog>
    <el-dialog
      custom-class="feedback-modal"
      :append-to-body="true"
      :title="'Add new game feedbacks'"
      :visible.sync="modalFeedbackShow"
      width="40%"
    >
      <div class="formAction">
        <p>
          Send feedback to the owners of
          <strong>{{ game.name }}</strong> about their game.
        </p>
        <p>This feedback will be visible for anyone who can see this game.</p>
        <label>
          Subject:
          <el-input v-model="subjectFeedback" type="text" placeholder="Subject"></el-input>
        </label>
        <label>
          Message:
          <el-input
            v-model="newFeedback"
            type="textarea"
            rows="8"
            placeholder="Leave a new feedback..."></el-input>
        </label>
      </div>
      <div class="current-feedback">
        <div v-for="feedback in feedbackList" :key="feedback.id" class="feedback-item">
          <div class>
            <div class="header">
              <div class="author">
                <i class="el-icon-user-solid el-icon--left"></i>
                {{ feedback.pm_name }}
              </div>
              <div class="date">{{ formatFeedbackDate(feedback.created_at) }}</div>
            </div>
            <div class="subject">{{ feedback.subject }}</div>
            <div class="content">{{ feedback.feedback }}</div>
          </div>
          <i class="el-icon-error delete" @click="sendFeedbackDeleteActionWithparam(feedback.id)"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalFeedbackShow = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="!!!newFeedback"
          @click="sendFeedbackActionWithparam()">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="feedback-modal"
      :append-to-body="true"
      :title="'Change GA Slot'"
      :visible.sync="modalGASlot"
      width="40%"
    >
      <div class="formAction">
        <el-input v-model="newGASlot" type="text" placeholder="Insert new GA slot:"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalGASlot = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="!!!newGASlot"
          @click="sendGASlotActionWithparam()">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Set game genre'"
      :visible.sync="modalGamegenreShow"
      width="30%"
    >
      <div class="formAction">
        <ul v-for="(genre, index) in genresList" :key="index">
          <div>
            <h3>{{ genre.category }}</h3>
          </div>
          <li v-for="(item, ind) in genre.options" :key="ind">
            <el-radio :value="genreObj[genre.category]" :label="item.id" @input="changeGenreValue($event, item)">{{
              item.label
            }}</el-radio>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalGamegenreShow = false">
          Cancel
        </el-button>
        <el-button type="primary" @click="sendGamegenreActionWithparam(genreObj);modalGamegenreShow = false;">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'How to iterate?'"
      :visible.sync="showTutoModal"
      width="70%"
    >
      <TutoIterateStep :game="game"></TutoIterateStep>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showTutoModal = false">
          OK
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'View feedback'"
      :visible.sync="modalFeedback"
      width="30%"
    >
      <div class="formAction">
        <div v-if="!loadingSeeFeedback" class="current-feedback">
          <div v-for="feedback in feedbackListView" :key="feedback.id" class="feedback-item">
            <div class>
              <div class="header">
                <div class="author">
                  <i class="el-icon-user-solid el-icon--left"></i>
                  {{ feedback.pm_name }}
                </div>
                <div class="date">{{ formatFeedbackDate(feedback.created_at) }}</div>
              </div>
              <div class="subject">{{ feedback.subject }}</div>
              <div class="content">{{ feedback.feedback }}</div>
            </div>
          </div>
          <div v-if="feedbackListView.length === 0 && !loadingSeeFeedback">
            No feedback yet
          </div>
        </div>
        <div v-if="loadingSeeFeedback">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="modalFeedback = false">
          Ok
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Set existing campaign'"
      :visible.sync="modalChangeAssocCampaign"
      width="30%"
    >
      <div class="formAction">
        <ul>
          <li v-for="(item, ind) in choicesCampaign" :key="ind">
            <el-radio v-model="campaignId" :label="item.id" @input="changeGenreValue($event, item)">{{ item.label }}</el-radio>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalChangeAssocCampaign = false">
          Cancel
        </el-button>
        <el-button type="primary" @click="updateValueBeforeSendAssocCampaign(campaignId);modalChangeAssocCampaign = false;">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Campaign settings'"
      :visible.sync="modalSettingsCampaign"
      width="50%"
    >
      <div class="campaign-settings">
        <CampaignSettings
          v-if="modalSettingsCampaign"
          :game-object="game"
          @close-pop-in="modalSettingsCampaign = false"
        ></CampaignSettings>
      </div>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Link your campagain'"
      :visible.sync="modalTestCampaign"
      width="30%"
    >
      <div v-if="!getChoiceLoading" class="formAction">
        <el-select v-model="linkId" filterable style="margin:10px 0">
          <el-option
            v-for="(c, ind) in choicesLink"
            :key="ind"
            :label="c.label"
            :value="c.id"></el-option>
        </el-select>
      </div>
      <div v-else class="loading">
        <i class="el-icon-loading"></i>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modalTestCampaign = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="getChoiceLoading"
          @click="updateValueBeforeLinkCampaign(linkId);modalTestCampaign = false;">
          Confirm
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Request testing'"
      :visible.sync="modalRequestTesting"
      width="30%"
    >
      <div class="formAction">
        <span>facebook app id:</span>
        <el-input v-model="facebookAppId" type="text" placeholder="Insert your facebook App Id"></el-input>
        <div class="label">
          <span>Add changelogs:</span>
        </div>
        <div v-for="(item, id) in changelogs" :key="id" class="form-new-changelog">
          <div class="field">
            <div class="label">
              <span>{{ item.category }}:</span>
            </div>
            <label>
              <span>{{ item.details }}</span>
            </label>
          </div>
          <!-- <div class="field">
            <div class="label">
              <span>Details about the changes</span>
            </div>
            <el-input v-model="item.details" :disabled="true"></el-input>
          </div>-->
          <br />
          <el-button
            type="danger"
            size="mini"
            @click="deleteChangeLog(id)">
            Delete changelog
          </el-button>
        </div>
        <div class="form-new-changelog">
          <div class="field">
            <div class="label">
              <span>Choose a category</span>
            </div>
            <el-select v-model="newChangelog.category" filterable>
              <el-option
                v-for="c in categories"
                :key="c.value"
                :label="c.label"
                :value="c.value"></el-option>
            </el-select>
          </div>
          <div class="field">
            <div class="label">
              <span>Details about the changes</span>
            </div>
            <el-input v-model="newChangelog.details"></el-input>
          </div>
          <br />

          <el-button
            v-if="canAddNewChangelog"
            type="primary"
            size="mini"
            @click="addChangeLog">
            Ok
          </el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalRequestTesting = false">
          Cancel
        </el-button>
        <el-button type="primary" @click="requestTesting(campaignId);modalRequestTesting = false;">
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment';
import gameActionService from '@/services/gameAction.services';
import appStore from '@/assets/images/app-store.svg';
import ga from '@/assets/images/ga.svg';
import fb from '@/assets/images/facebook.svg';
import chat from '@/assets/images/chat.svg';
import actionList from '@/static/actionList';
import categories from '@/static/gamecategories';
import TutoIterateStep from '@/components/GameSubmissionSteps/TutoIterateStep.vue';
import playStore from '@/assets/images/playstore.webp';
import CampaignSettings from '@/components/Dialogs/CampaignSettings.vue';
import GameIds from '@/components/Dialogs/GameIds.vue';

export default {
  name: 'DetailsAndOptionsMobile',
  components: {
    // GamePage,
    // StudioPage,
    TutoIterateStep,
    CampaignSettings,
    GameIds,
  },
  props: {
    gameId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      game: {},
      loading: false,
      playStore,
      appStore,
      ga,
      fb,
      chat,
      actionList,
      categories,
      modalGamegenreShow: false,
      modalFeedbackShow: false,
      modalChangeAssocCampaign: false,
      modalRequestTesting: false,
      modalTestCampaign: false,
      showTutoModal: false,
      modalGASlot: false,
      genreObj: {
        orientation: null,
        visuals: null,
        game_genre: null,
        controls: null,
      },
      newFeedback: null,
      newGASlot: null,
      subjectFeedback: '',
      choicesCampaign: [],
      choicesLink: [],
      campaignId: '',
      linkId: '',
      facebookAppId: '',
      getChoiceLoading: false,
      modalFeedback: false,
      loadingSeeFeedback: false,
      feedbackListView: [],
      newChangelog: {
        category: null,
        details: null,
      },
      modalSettingsCampaign: false,
      modalIdsShow: false,
    };
  },
  computed: {
    canCreateAd() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    feedbackList() {
      if (this.game) {
        return this.game.feedbacks;
      }
      return [];
    },
    gameGenres() {
      if (this.game) {
        return this.game.genres;
      }
      return [];
    },
    changelogs() {
      if (this.game) {
        return this.game.changelogs || [];
      }
      return [];
    },
    canAddNewChangelog() {
      const element = this.newChangelog;
      if (!element.category || !element.details) {
        return false;
      }
      return true;
    },
    genresList() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].genres : [];
    },
    actionsAvailable() {
      if (!this.game || !this.game.actions) {
        return {
          secondary: [],
          primary: [],
        };
      }
      const actionsAvailable = this.game.actions.filter(el => el.id !== null || el.label === 'Reject');

      const recreate = this.game.actions.find(el => el.label === 'Recreate Campaign');
      let final = [];

      if (recreate) {
        final.push(this.actionList.find(el => el.id === 'recreate_campaign_facebook'));
      }

      // const mypermission = this.myPermissions;
      for (let i = 0; i < this.actionList.length; i += 1) {
        const element = this.actionList[i];

        for (let j = 0; j < actionsAvailable.filter(el => !el.unavailableReason).length; j += 1) {
          const ele = actionsAvailable.filter(el => !el.unavailableReason)[j];

          if (
            element.id === ele.id
            // && (mypermission.some(r => element.permissions.indexOf(r) >= 0)
            //   || element.permissions.length === 0)
            && element.used
          ) {
            final.push(element);
          }
        }
      }

      const primary = final.filter(el => el.primary);
      let secondary = final.filter(el => !el.primary);
      if (
        !this.game.isCtr
        && !this.game.isManagedByVoodoo
        && (this.game.status === 'in_testing' || this.game.status === 'finished')
      ) {
        secondary.push({
          id: 'tuto',
          name: 'How to iterate?',
        });
      }
      if (this.game.status === 'in_testing') {
        secondary.unshift({
          id: 'settings',
          name: 'Campaign settings',
        });
      }
      if (!this.showPrimary) {
        secondary = secondary.concat(primary);
      }
      final = {
        primary,
        secondary,
      };
      return final;
    },
  },
  watch: {
    gameId() {
      this.updateView();
    },
  },
  mounted() {
    this.updateView();
  },
  events: {},
  methods: {
    getTagsArray(item) {
      if (item) {
        return item.split('|');
      }
      return [];
    },
    updateView() {
      this.getGameInfos();
    },
    goto(url) {
      if (url) window.open(url);
    },
    async getGameInfos() {
      this.loading = true;
      const response = await gameActionService.getStatsByGameId({
        id: this.gameId,
      });

      if (response) {
        this.game = response;
        this.loading = false;
      } else {
        this.game = {};
        this.loading = false;
      }
    },
    pushAction(action) {
      switch (action.id) {
      case 'tuto':
        this.showTuto();
        break;

      case 'reject_game_iteration':
        this.setActionWithoutParams('reject_game_iteration');
        break;
      case 'recreate_campaign_facebook':
        this.setActionWithoutParams('recreate_campaign_facebook');
        break;
      case 'request_application_access':
        this.setActionWithoutParams('request_application_access');
        break;
      case 'reject_changelog':
        this.setActionWithoutParams('reject_changelog');
        break;
      case 'decline_testing':
        this.setActionWithoutParams('decline_testing');
        break;
      case 'create_ga_slot':
        this.setActionWithoutParams('create_ga_slot');
        break;
      case 'set_game_genres':
        this.setGameGenre();
        break;
      case 'test_studio_campaign':
        this.linkStudioCampagn();
        break;
      case 'request_testing':
        this.popUpequestTesting();
        break;
      case 'add_game_feedback':
        this.setGameFeedbacks();
        break;
      case 'assign_ga_slot':
        this.setGASlot();
        break;
      case 'accept_testing':
        this.setActionWithoutParams('accept_testing');
        break;
      case 'validate_changelog':
        this.setActionWithoutParams('validate_changelog');
        break;
      case 'kill_game':
        this.setActionWithoutParams('kill_game');
        break;
      case 'archive_game':
        this.setActionWithoutParams('archive_game');
        break;
      case 'extend_campaign':
        this.setActionWithoutParams('extend_campaign');
        break;
      case 'iterate_pause_campaign':
        this.setActionWithoutParams('iterate_pause_campaign');
        break;
      case 'validate_game':
        this.setActionWithoutParams('validate_game');
        break;
      case 'update_studio_campaign':
        this.setStudioCampagn();
        break;
      case 'iterate_from_history':
        this.setActionWithoutParams('iterate_from_history');
        break;
      case 'settings':
        this.openSettingsCampaign(true);
        break;

      default:
        break;
      }
    },
    openSettingsCampaign(value) {
      this.modalSettingsCampaign = value;
    },
    setActionWithoutParams(actionValue) {
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === actionValue)[0];
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.$emit('close');
      }
    },

    popUpequestTesting() {
      this.facebookAppId = this.game.facebookAppId;
      this.modalRequestTesting = true;
    },
    requestTesting() {
      // const action = this.actionList.filter(el => el.id === 'request_testing')[0];
      this.$store.dispatch('gameAction/setRequestTesting', {
        game: this.game,
        facebookAppId: this.facebookAppId,
        changelogs: this.changelogs,
      });
      this.$emit('close');

      // this.$store.dispatch('gameAction/showDetail', this.game);
    },
    viewFeedback() {
      this.modalFeedback = true;
      this.loadingSeeFeedback = true;
      this.$store
        .dispatch('gameAction/getFeedbacks', {
          game: this.game,
        })
        .then((response) => {
          this.loadingSeeFeedback = false;
          this.feedbackListView = response;
        });
    },
    setGameGenre() {
      for (let index = 0; index < this.gameGenres.length; index += 1) {
        const element = this.gameGenres[index];
        this.genreObj[element.category] = element.id;
      }
      this.modalGamegenreShow = true;
    },
    setGameFeedbacks() {
      for (let index = 0; index < this.gameGenres.length; index += 1) {
        const element = this.gameGenres[index];
        this.genreObj[element.category] = element.id;
      }
      this.modalFeedbackShow = true;
    },
    async linkStudioCampagn() {
      const param = {
        id: this.game.id,
        action: 'test_studio_campaign',
      };
      this.modalTestCampaign = true;
      this.getChoiceLoading = true;
      // eslint-disable-next-line no-await-in-loop
      const rep = await this.$store.dispatch('games/getAvailableChoiceForASpecificAction', param);
      this.getChoiceLoading = false;

      if (rep) {
        this.linkId = rep.current_id;
        this.choicesLink = rep.choices;
      }
      this.$emit('close');
    },
    async setStudioCampagn() {
      const param = {
        id: this.game.id,
        action: 'update_studio_campaign',
      };
      // eslint-disable-next-line no-await-in-loop
      const rep = await this.$store.dispatch('games/getAvailableChoiceForASpecificAction', param);
      // this.$store.dispatch('gameAction/updatedGame', {
      //   id: this.game.id,
      //   value: false,
      // });

      // eslint-disable-next-line max-len
      this.campaignId = rep.currentid && rep.current_id.facebook ? rep.current_id.facebook : rep.current_id;
      // eslint-disable-next-line max-len
      const repf = rep.choices.facebook;
      this.choicesCampaign = rep.choices && repf ? repf : rep.choices;
      this.modalChangeAssocCampaign = true;
    },
    setGASlot() {
      // this.currentAction = action;
      this.newGASlot = this.game.gameAnalyticsId;
      this.modalGASlot = true;
    },
    showTuto() {
      this.showTutoModal = true;
    },
    changeGenreValue(param, el) {
      const obj = cloneDeep(this.genreObj);
      const cat = el.category;
      const isPrototypeOfBar = Object.prototype.hasOwnProperty.call(obj, cat);
      if (isPrototypeOfBar) {
        obj[cat] = param;
      }
      this.genreObj = obj;
    },
    formatFeedbackDate(date) {
      return moment(date).format('YYYY/MM/DD - hh:mma');
    },
    openDropDown() {
      if (this.gameAction.length === 0) {
        this.$store.dispatch('gameAction/getActionAvailable', this.game);
      }
    },
    sendGamegenreActionWithparam(choice) {
      const fchoice = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in choice) {
        if (choice[key] !== null) {
          fchoice.push(choice[key]);
        }
      }
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === 'set_game_genres')[0];
        action.post.field[0].value = fchoice;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.$emit('close');
      }
    },
    updateValueBeforeLinkCampaign(linkId) {
      if (this.actionList) {
        let action = this.actionList.filter(el => el.id === 'test_studio_campaign')[0];
        const post = {
          post: {
            format: 'application/json',
            field: [
              {
                type: 'Object',
                param: 'choice',
                deepParam: ['facebook'],
                value: linkId,
              },
            ],
          },
        };
        action = {
          ...action,
          ...post,
        };
        const param = {
          id: this.game.id,
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.$emit('close');
      }
    },
    updateValueBeforeSendAssocCampaign(campaignId) {
      if (this.actionList) {
        let action = this.actionList.filter(el => el.id === 'update_studio_campaign')[0];
        const post = {
          post: {
            format: 'application/json',
            field: [
              {
                type: 'Object',
                param: 'choice',
                deepParam: ['facebook'],
                value: campaignId,
              },
            ],
          },
        };
        action = {
          ...action,
          ...post,
        };
        const param = {
          id: this.game.id,
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.$emit('close');
      }
    },
    sendFeedbackActionWithparam() {
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === 'add_game_feedback')[0];
        action.post.field[0].value = this.subjectFeedback;
        action.post.field[1].value = this.newFeedback;

        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.newFeedback = null;
        this.subjectFeedback = '';
        this.$emit('close');
      }
    },
    sendFeedbackDeleteActionWithparam(feedbackId) {
      const param = {
        id: cloneDeep(this.game.id),
        action: {
          id: 'delete_game_feedback',
          post: {
            field: [
              {
                type: 'String',
                param: 'feedback_id',
                value: feedbackId,
              },
            ],
          },
        },
      };
      this.$store.dispatch('ActionToDispatch/dispatchAction', param);
    },
    sendGASlotActionWithparam() {
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === 'assign_ga_slot')[0];
        action.post.field[0].value = this.newGASlot;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);

        this.newGASlot = null;
        this.modalGASlot = false;
        this.$emit('close');
      }
    },
    deleteChangeLog(id) {
      for (let i = 0; i < this.changelogs.length; i += 1) {
        const element = this.changelogs[i];
        if (element.id === id) {
          this.changelogs.splice(id, 1);
        }
      }
    },
    addChangeLog() {
      this.changelogs.push(this.newChangelog);
      this.newChangelog = {
        category: null,
        details: null,
      };
    },
  },
};
</script>

<style lang="scss">
.game-detail-view {
  width: 100%;
  li {
    padding: 20px 10px;
    text-align: left;
    font-size: 15px;
    border-bottom: 1px solid var(--filter-border-bottom-color);

    &:hover {
      background-color: var(--hover-card);
      cursor: pointer;
    }
    .top-infos {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // align-items: center;
      // justify-content: space-around;
      .type,
      .platform {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .tag {
      display: inline-block;
      flex-direction: row;
      font-weight: bold;
      padding: 7px 12px;
      margin-top: 2px;
      margin-right: 5px;
      border-radius: 30px;
      background-color: #e6f1fd;
      color: #067bf0;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
  @media (min-width: 700px) {
    display: none;
  }
}
.slide-up-enter,
.slide-up-leave-to {
  // right: -100%;
  transform: translateY(130%);

  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  // transform: translateX(0%);
  // transition-delay: 0.3s;
  // padding-left: 50px;
  .container {
    // transform: translateX(30%);
    transform: translateY(10vh);

    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
      -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
      -o-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
    transition-delay: 0.8s;
    // transform: translateX(13%);
    // transition: transform 0.3s ease-in;
  }
}
.slide-up-enter,
.slide-up-enter-active,
.slide-up-leave-active {
  // transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  // transform: translateX(0%);
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  // transition-delay: 0.3s;
  .container {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    // transition: transform 0.3s ease-out;
    // animation: tick-tock 0.3 steps(0.3,s end);
    // -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    // animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}
</style>

<template>
  <div v-if="text" :class="$style.wrapper" @click="copyToClipboard(text)">
    <div :class="$style.copiedWrapper">
      <div ref="copied" :class="$style.copied">Copied!</div>
    </div>
    <div :class="$style.textWrapper">
      <span>{{ text }}</span>
      <font-awesome-icon :class="$style.icon" icon="clone" />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'CopyToClipboardNew',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    async copyToClipboard(text) {
      this.copiedAnimate();
      await navigator.clipboard.writeText(text);
    },
    copiedAnimate() {
      const target = this.$refs.copied;
      anime({
        targets: target,
        keyframes: [
          {
            opacity: [0, 1],
            translateY: [-20, -30],
          },
          {
            opacity: [1, 0],
            translateY: [-30, -40],
          },
        ],
        easing: 'easeInOutQuart',
        duration: 1000,
        begin() {
          target.style.display = 'block';
        },
        complete() {
          target.style.display = 'none';
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  white-space: break-spaces;
  word-break: break-all;
  color: $slate;
  cursor: pointer;
  position: relative;
  gap: $spacing;
  transition: all 300ms;
}

.textWrapper:hover {
  color: $intense-blue
}

.icon {
    opacity: 0;
    transition: all 300ms;
    color: $intense-blue;
}

.textWrapper:hover .icon {
    opacity: 1;
}

.copied {
  background-color: $tooltip-background-dark;
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px;
  padding: $spacing;
  margin: auto;
  text-align: center;
  opacity: 0;
  display: none;
}

.copiedWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>

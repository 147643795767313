<template>
  <div :class="$style.wrapper">
    <div :class="$style.leftSection">
      <div v-if="!isATT && !isDeactivated" :class="$style.pm">
        <span>
          <font-awesome-icon :class="$style.icon" icon="user-tie" />
          <span data-test-id="studioPagePmName">
            {{ studioInfos?.pm?.firstName }}
            {{ studioInfos?.pm?.lastName }}
          </span>
        </span>
        <span v-if="studioInfos.secondaryPm">
          /
          <span data-test-id="studioPageSecondaryPmName">
            {{ studioInfos?.secondaryPm?.firstName }}
            {{ studioInfos?.secondaryPm?.lastName }}
          </span>
        </span>
      </div>
      <div v-if="!isDeactivated" :class="$style.status">
        <font-awesome-icon :class="$style.icon" icon="tag" />
        <div
          v-if="teamStatus"
          data-test-id="studioPageTeamStatus"
        >{{ teamStatus.label }}</div>
      </div>
      <div v-if="isDeactivated" :class="$style.deactivated">
        <font-awesome-icon :class="$style.icon" icon="tag" />
        Deactivated since
        <span data-test-id="studioPageDeactivationDate">{{deactivatedDate}}</span>
      </div>
    </div>
    <div :class="$style.rightSection">
      <div :class="$style.settingsWrapper" data-test-id="StudioPageSettingsButton" @click="$emit('open-settings')">
        <font-awesome-icon :class="$style.settingsIcon" icon="sliders-h"/>
        <div>Studio settings</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudioSubHeader',
  props: {
    studioInfos: {
      type: Object,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    teamStatus() {
      let teamStatus = null;
      const statusId = this.studioInfos?.teamStatusId;
      if (this.codification) {
        const allTeamStatus = [
          ...this.codification?.teamStatuses?.att,
          ...this.codification?.teamStatuses?.coaching,
        ];
        teamStatus = allTeamStatus.find(status => status.id === statusId);
      }
      return teamStatus;
    },
    isATT() {
      const teamStatusId = this.studioInfos.teamStatusId;
      return teamStatusId === 1 || teamStatusId === 2;
    },
    isDeactivated() {
      return !!this.studioInfos.deactivateDate;
    },
    deactivatedDate() {
      const date = this.studioInfos.deactivateDate ? new Date(this.studioInfos.deactivateDate) : null;
      return date ? date.toLocaleDateString('en-GB') : '';
    },
    canChangeStudioAdminInfo() {
      return this.$store.getters['users/myPermissions'].includes('change_studio_admin_info');
    },
  },
  methods: {
    activate() {
      this.$store.dispatch('studios/setStudioActive', {
        id: this.studioInfos.id,
      });
    },
    async deactivate() {
      this.$store.dispatch('studios/setStudioChurned', {
        id: this.studioInfos.id,
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  background-color: $white;
  padding: $spacing * 2 $spacing * 3;
  border-bottom: 1px solid $foggy-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 135px;
  z-index: 10;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.icon {
  margin-right: $spacing;
  font-size: 16px;
}

.status {
  display: flex;
  align-items: center;
}

.leftSection {
  display: flex;
}

.rightSection {
  display:flex;
  align-items:center;
}

.pm {
  margin-right: $spacing * 3;
}

.settingsWrapper {
  display: flex;
  align-items: center;
  color: $soft-black;
  cursor: pointer;
  transition: color 300ms;

  > div {
    font-size: 12px;
    font-weight: 600;
  }

  > svg {
    margin-right: $spacing;
  }

  @media screen and (max-width: 700px) {
    margin-top: $spacing * 2;
  }
}

.settingsWrapper:hover {
  color: $intense-blue;
}
</style>

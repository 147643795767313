/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import UsersService from '@/services/users.services';
import CookiesService from '@/tools/cookies';
// import AuthService from '../../services/auth.services';
import axios from '@/tools/axios';
import listKpiGameAvailable from '@/static/listKpiGameAvailable';
import listKpiStudioAvailable from '@/static/ListKpiStudioAvailable';
import router from '@/router';

const users = {
  namespaced: true,
  state: {
    me: null,
    mode: 'light',
    meForm: null,
    loading: false,
    loadingPass: false,
    id: null,
    isStaff: '',
    isHijack: false,
    codification: null,
    isLoaded: false,
    statusList: [],
    loadingLogin: false,
    myPermissions: [],
    userName: '',
  },
  getters: {
    isStaff(state) {
      return state.me ? state.me.isStaff : false;
    },
    mode(state) {
      return state.mode;
    },
    loading(state) {
      return state.loading;
    },
    loadingPass(state) {
      return state.loadingPass;
    },
    id(state) {
      return state.id;
    },
    me(state) {
      return state.me;
    },
    isAdmin(state) {
      return state.me ? state.me.isSuperuser : false;
    },
    isHijack(state) {
      return state.isHijack ? state.isHijack : false;
    },
    meForm(state) {
      return state.meForm;
    },
    userName(state) {
      return state.userName;
    },
    codification(state) {
      return state.codification;
    },
    countries(state) {
      return state.codification
        ? state.codification.countries || [
          {
            label: 'united state',
            value: 'US',
          },
          {
            label: 'france',
            value: 'FR',
          },
        ]
        : null;
    },
    roles(state) {
      return state.codification
        ? state.codification.roles || [
          {
            label: 'Developer',
            value: 'studio_developer',
          },
          {
            label: 'Game artist',
            value: 'studio_game_artist',
          },
          {
            label: 'Game designer',
            value: 'studio_game_designer',
          },
          {
            label: 'Lead',
            value: 'studio_lead',
          },
          {
            label: 'Studio manager',
            value: 'studio_manager',
          },
        ]
        : null;
    },
    permissions(state) {
      return state.codification
        ? state.codification.permissions || [
          {
            label: 'Full access',
            value: 1,
          },
          {
            label: 'Game access',
            value: 2,
          },
          {
            label: 'Read only',
            value: 4,
          },
        ]
        : null;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
    statusList(state) {
      return state.statusList;
    },
    loadingLogin(state) {
      return state.loadingLogin;
    },
    myPermissions(state) {
      return state.myPermissions;
    },
  },
  mutations: {
    set_my_permissions(state, permissions) {
      if (permissions) {
        state.myPermissions = /* ['change_own_game_video']; */ Object.keys(permissions);
      }
    },
    set_isStaff(state, isStaff) {
      state.isStaff = isStaff;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_loadingPassword(state, loading) {
      state.loadingPass = loading;
    },
    set_id(state, user) {
      state.id = user.id;
      state.userName = `${user.firstName} ${user.lastName}`;
    },
    set_user(state, user) {
      state.me = user;
      state.meForm = user;
    },
    set_isLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    set_statusList(state, statusList) {
      state.statusList = statusList;
    },
    set_loadingLogin(state, loading) {
      state.loadingLogin = loading;
    },
    setToken(state, res) {
      CookiesService.setToken(res.token);
      CookiesService.setRefreshToken(res.refreshToken);
      axios.defaults.headers.common.authorization = res.token;
    },
    set_codif(state, codif) {
      const original = cloneDeep(codif);

      // const obj = original.columns.games.properties;

      function reformColumns(columnsType) {
        const cod = [];
        const obj = columnsType.properties;
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const e = obj[key];
            e.field = key;
            cod.push(e);
          }
        }
        const col = [];
        for (let j = 0; j < cod.length; j += 1) {
          const el = cod[j];
          if (listKpiGameAvailable.concat(listKpiStudioAvailable).includes(el.field)) {
            // eslint-disable-next-line no-continue
            continue;
          }
          col.push(el);
        }
        for (let i = 0; i < col.length; i += 1) {
          const element = col[i];
          element.id = element.field;
          const boolChoices = [
            {
              label: 'Yes',
              value: '1',
            },
            {
              label: 'No',
              value: '0',
            },
          ];
          if (element.type === 'integer' || element.type === 'number') {
            element.type = 'numeric';
          }
          if (element.type === 'string') {
            element.type = 'text';
          }
          if (element.type === 'boolean') {
            element.type = 'radio';
            element.choices = boolChoices;
          }

          if (element.format === 'date-time') {
            element.type = 'custom-component';
            element.component = 'el-date-picker';
            element.operators = ['=', '<>', '<', '<=', '>', '>='];
            element.kind = 'date';
          }
          if (
            element.field === 'execution'
            || element.field === 'execution'
            || element.field === 'balancing'
            || element.field === 'artEfficiency'
            || element.field === 'progression'
            || element.field === 'idea'
            || element.field === 'newness'
            || element.field === 'systemOne'
            || element.field === 'depth'
          ) {
            element.type = 'custom-component';
            element.operators = ['=', '<>', '<', '<=', '>', '>='];
            element.component = 'el-slider';
            element.default = 3;
            element.max = 5;
            element.min = 0;
          }
          if ((element.field.includes('D1') || element.field.includes('D7')) && element.type !== 'radio') {
            element.type = 'custom-component';
            element.operators = ['=', '<>', '<', '<=', '>', '>='];
            element.component = 'el-slider';
            element.default = 0.2;
            element.max = 1;
            element.min = 0;
            element.step = 0.01;
            element.kind = 'retention';
          }
          if (element.field.includes('Cpi') && element.type !== 'radio') {
            element.type = 'custom-component';
            element.operators = ['=', '<>', '<', '<=', '>', '>='];
            element.component = 'el-slider';
            element.default = 0.22;
            element.min = 0;
            element.max = 2;
            element.step = 0.01;
            element.kind = 'cpi';
          }
          if (element.field === 'pmName' || element.field === 'secondaryPmName' || element.field === 'assignedPmName') {
            element.type = 'select';
            element.default = 'Corentin Selz';
            element.operators = ['=', '<>', 'is null', 'is not null'];
            element.choices = original.publishingManagers.sort((a, b) => (a.label < b.label ? -1 : 1));
          }
          if (element.field === 'status') {
            element.type = 'select';
            element.operators = ['=', '<>'];
            element.choices = original.status;
          }
          if (element.field === 'teamStatus') {
            element.type = 'select';
            element.operators = ['=', '<>'];
            element.choices = [...original.teamStatuses.att, ...original.teamStatuses.coaching];
          }
          const fields = ['recommendation', 'recommendationFacebook', 'recommendationSnapchat'];

          if (fields.includes(element.field)) {
            element.type = 'select';
            element.operators = ['=', '<>'];
            const choices = [];
            original.recommendations.forEach((el) => {
              choices.push({
                label: el,
                value: el,
              });
            });
            element.choices = choices;
          }
        }
        return col;
      }
      if (codif.columns) {
        codif.columns.games = reformColumns(original.columns.games);
        codif.columns.studios = reformColumns(original.columns.studios);
        codif.publishingManagers = codif.publishingManagers.sort((a, b) => (a.label < b.label ? -1 : 1));
        codif.gls = codif.gls.sort((a, b) => (a.label < b.label ? -1 : 1));
      }
      const error = state.codification ? state.codification.errorList || null : null;
      state.codification = {
        ...codif,
        errorList: error,
      };
    },
    set_error_messages(state, errors) {
      const objError = {};
      errors.forEach((element) => {
        objError[element.code] = element;
      });
      state.codification.errorList = objError;
    },
    set_user_form(state, user) {
      const userForm = {
        ...state.meForm,
        ...user,
      };
      state.meForm = userForm;
    },
    set_hijack(state, value) {
      state.isHijack = value;
    },
    reset_me() {
      this.me = null;
      this.myPermissions = null;
    },
    set_user_mode(state, mode) {
      state.mode = mode;
    },
  },
  actions: {
    updateMeForm(context, propertie) {
      context.commit('set_user_form', propertie);
    },
    setuserMode(context, mode) {
      context.commit('set_user_mode', mode);
    },
    async updateUser(context, user) {
      context.commit('set_loading', true);
      const response = await UsersService.updateUser(user);
      context.commit('set_loading', false);
      if (response) {
        context.commit('set_user', response);
      }
    },
    async changePassword(context, password) {
      return new Promise(async (resolve) => {
        context.commit('set_loadingPassword', true);
        const response = await UsersService.updatePasswordUser(password);
        context.commit('set_loadingPassword', false);
        if (response) {
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },
    async getUser(context) {
      const response = await UsersService.fetchUser();
      if (!response) {
        router.push({
          name: 'login',
        });
        CookiesService.logout();
        return;
      }
      context.commit('set_isStaff', response.isStaff);

      context.commit('set_hijack', response.isHijack);
      context.commit('set_id', response.data);
      context.commit('set_user', response.data);
      // context.commit('set_isLoaded', true);
      context.commit('set_my_permissions', response.data.permissions);
      await context.dispatch('getCodification');

      const statusList = await UsersService.fetchStatusList();
      context.commit('set_statusList', statusList);
      context.commit('set_loadingLogin', false);
    },
    async hijackUserId(context, id) {
      return UsersService.hijackUserId(id, context.commit.bind(this, 'reset_me'));
    },
    async releaseHijack(context) {
      context.commit('reset_me');
      await UsersService.releaseHijack();
    },
    async getCodification(context) {
      const response = await UsersService.fetchCodification();
      context.commit('set_codif', response);

      const errors = await UsersService.fetchError();
      context.commit('set_error_messages', errors);
    },
    async login(context, logs) {
      context.commit('set_loadingLogin', true);
      const response = await UsersService.login(logs);
      context.commit('set_loadingLogin', false);
      if (!response || !response.success) {
        throw new Error();
      }
      context.commit('setToken', response);
      if (!logs.includeRedirectUrlFor) {
        context.dispatch('getUser');
      }

      return {
        redirectUrl: response.redirectUrl,
      };
    },
    async logout(context, logs) {
      const response = await UsersService.logout(logs);
      if (!response) {
        return;
      }
      CookiesService.logout();
      window.location = '/login';
    },
    async sendPasswordResetEmail(context, username) {
      try {
        await UsersService.sendPasswordResetEmail(username);
      } catch (e) {
        console.error(e);
        return e;
      }
      router.push({ name: 'passwordResetEmailSent' });
      return null;
    },
    async resetPassword(context, {
      username, resetToken, newPwd1, newPwd2,
    }) {
      try {
        await UsersService.resetPassword(username, resetToken, newPwd1, newPwd2);
      } catch (e) {
        console.error(e);
        return e;
      }
      router.push({ name: 'root' });
      return null;
    },
  },
};

export default users;

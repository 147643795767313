<template>
  <div :class="$style.editProjectIcon" @click.prevent="$emit('click', $event)">
    <font-awesome-icon :class="$style.icon" icon="pen" />
  </div>
</template>

<script>
export default {
  name: 'EditProjectIcon',
};
</script>

<style lang="scss" module>
.editProjectIcon {
  position: relative;
  width: 19px;
  height: 19px;
  margin: 0 10px;
  padding: 2px;
  margin-top: -2px;
  &:hover {
    cursor: pointer;
    .icon {
      color: $intense-blue;
    }
  }
}

.icon {
  color: $light-blue;
  width: auto;
  height: auto;
}
</style>

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';
import gameActionService from '@/services/gameAction.services';
import tools from '@/tools/tools';
import gameFilters from '@/../json/gameActionFilters';

const GAME_LIST_NAMES_WITH_KS = ['myGames', 'knowledgeSharingGames'];

const gameAction = {
  namespaced: true,
  state: {
    abortController: null,
    newGame: {},
    newGameloading: false,
    currentStatus: 'in_progress',
    myGames: [],
    myGamesArchived: [],
    myGamesLoading: false,
    myGamesArchivedNeedRefresh: false,
    knowledgeSharingGames: [],
    stepLoading: false,
    lasturlUser: null,
    videoLoading: false,
    numberGameByStatus: {
      is_iterating: 0,
      in_progress: 0,
      pending_approval: 0,
      in_testing: 0,
      finished: 0,
      archived: 0,
    },
    params: {
      me: true,
      term: '',
      limit: 5,
      myGames: {
        offset: 0,
        sort: 'createdAt',
        order: 'desc',
      },
      archived: {
        offset: 0,
        sort: 'createdAt',
        order: 'desc',
        query: gameFilters.archived,
      },
      knowledgeSharing: {
        offset: 0,
        sort: 'lastIterationDate',
        order: 'desc',
        query: gameFilters.knowledgeSharing,
      },
    },
  },
  getters: {
    myGamesArchivedNeedRefresh(state) {
      return state.myGamesArchivedNeedRefresh;
    },
    newGame(state) {
      return state.newGame;
    },
    currentStatus(state) {
      return state.currentStatus;
    },
    newGameloading(state) {
      return state.newGameloading;
    },
    myGamesLoading(state) {
      return state.myGamesLoading;
    },
    myGames(state) {
      return state.myGames;
    },
    myGamesArchived(state) {
      return state.myGamesArchived;
    },
    KnowledgeSharingGames(state) {
      return state.knowledgeSharingGames;
    },
    queryKs(state) {
      return state.params.knowledgeSharing.query;
    },
    stepLoading(state) {
      return state.stepLoading;
    },
    params(state) {
      return state.params;
    },
    showGameOnly(state) {
      return state.params.me;
    },
    lastUserUrl(state) {
      return state.lasturlUser;
    },
    videoLoading(state) {
      return state.videoLoading;
    },
    numberGameByStatus(state) {
      return state.numberGameByStatus;
    },
  },
  mutations: {
    set_abort_controller(state, abortController) {
      state.abortController = abortController;
    },
    set_archived_list_need_refresh(state, value) {
      state.myGamesArchivedNeedRefresh = value;
    },
    check_operation(state, item) {
      const arrayListName = GAME_LIST_NAMES_WITH_KS;
      for (let i = 0; i < arrayListName.length; i += 1) {
        const element = arrayListName[i];
        const ar = cloneDeep(state[element]);

        for (let j = 0; j < ar.length; j += 1) {
          const el = ar[j];
          if (el.id === item.game.id) {
            el.checkOperation = item.value;
            state[element] = ar;
          }
        }
      }
    },
    reset_numberGamesBystatus(state) {
      Object.keys(state.numberGameByStatus).forEach((status) => {
        state.numberGameByStatus[status] = 0;
      });
    },
    set_numberGamesBystatus(state, response) {
      Object.keys(state.numberGameByStatus).forEach((status) => {
        state.numberGameByStatus[status] = response[status] || 0;
      });
    },
    set_stepLoading(state, item) {
      state.stepLoading = item.value;
    },
    set_myGames_loading(state, loading) {
      state.myGamesLoading = loading;
    },
    set_upload_video_loading(state, loading) {
      state.videoLoading = loading;
    },
    set_myGames_Only(state, value) {
      state.params.me = value;
    },
    set_knowledgeSharingGames(state, value) {
      if (value) {
        for (let i = 0; i < value.length; i += 1) {
          const element = value[i];
          element.front_status = 'ks';
          element.show = false;
          state.knowledgeSharingGames.push(element);
        }
        state.params.knowledgeSharing.offset += state.params.limit;
      } else {
        state.knowledgeSharingGames = null;
        state.params.knowledgeSharing.offset = 0;
      }
    },
    set_actionGame_loading(state, item) {
      const arrayListName = GAME_LIST_NAMES_WITH_KS;

      for (let i = 0; i < arrayListName.length; i += 1) {
        const element = arrayListName[i];
        const ar = cloneDeep(state[element]);

        for (let j = 0; j < ar.length; j += 1) {
          const el = ar[j];
          if (el.id === item.game.id) {
            el.loadingAction = item.value;
            state[element] = ar;
          }
        }
      }
    },
    set_videoGame_loading(state, item) {
      if (!item.game.isIterating) {
        for (let i = 0; i < state.myGames.length; i += 1) {
          const element = state.myGames[i];
          if (element.id === item.game.id) {
            element.loadingVideo = item.value;
          }
        }
      }
    },
    set_video_available_for_a_game(state, item) {
      const d = cloneDeep(item.value);
      for (let j = 0; j < d.iterations.length; j += 1) {
        const el = d.iterations[j];
        for (let p = 0; p < el.videos.length; p += 1) {
          const ele = el.videos[p];
          ele.loading = false;
        }
      }
      const arrayListName = GAME_LIST_NAMES_WITH_KS;
      for (let i = 0; i < arrayListName.length; i += 1) {
        const element = arrayListName[i];
        const ar = cloneDeep(state[element]);

        for (let j = 0; j < ar.length; j += 1) {
          const el = ar[j];
          if (el.id === item.game.id) {
            el.iterationsVideos = d;
            state[element] = ar;
          }
        }
      }
    },
    actionPendingApprovalDone(state, item) {
      for (let i = 0; i < state.myGames.length; i += 1) {
        const element = state.myGames[i];
        if (element.id === item.id) {
          element.actionDone = true;
        }
      }
    },
    set_my_games(state, { games, status }) {
      if (state.currentStatus !== status && status !== 'archived') {
        state.params.myGames.offset = 0;
        state.myGames = [];
        window.scrollTo(0, 1000);
        state.currentStatus = status;
      }

      if (status === 'archived') {
        state.params.archived.offset += state.params.limit;
      } else {
        state.params.myGames.offset += state.params.limit;
      }

      for (let i = 0; i < games.length; i += 1) {
        const element = games[i];
        element.show = false;
        element.loadingAction = false;
        element.loadingRequestTesting = false;
        element.loadingVideo = false;
        element.disabled = false;
        element.isUpdated = true;
        element.front_status = status;
        if (status === 'archived') {
          state.myGamesArchived.push(element);
        } else {
          state.myGames.push(element);
        }
      }
    },
    set_myGame_show(state, item) {
      const arrayListName = GAME_LIST_NAMES_WITH_KS;
      for (let i = 0; i < arrayListName.length; i += 1) {
        const element = arrayListName[i];
        const ar = cloneDeep(state[element]);

        for (let j = 0; j < ar.length; j += 1) {
          const el = ar[j];
          if (el.id === item.id) {
            el.show = !item.show;
            state[element] = ar;
          }
        }
      }
    },
    set_next_step(state, item) {
      const ar = cloneDeep(state.myGames);
      for (let j = 0; j < ar.length; j += 1) {
        const el = ar[j];
        if (el.id === item.game.id) {
          el.substatus = item.status;
          state.myGames = ar;
        }
      }
    },
    reset_gameList(state) {
      state.params.myGames.offset = 0;
      state.myGames = [];
      window.scrollTo(0, 1000);
    },
    reset_gameList_archived(state) {
      state.params.archived.offset = 0;
      state.myGamesArchived = [];
    },
    set_current_status(state, status) {
      state.currentStatus = status;
    },
    reset_gameList_knowledgeSharing(state) {
      state.params.knowledgeSharing.offset = 0;
      state.knowledgeSharingGames = [];
    },
    set_stats_loading(state, item) {
      for (let i = 0; i < state.myGames.length; i += 1) {
        const element = state.myGames[i];
        if (element.id === item.item.id) {
          element.loadingTesting = item.value;
        }
      }
      for (let i = 0; i < state.knowledgeSharingGames.length; i += 1) {
        const element = state.knowledgeSharingGames[i];
        if (element.id === item.item.id) {
          element.loadingTesting = item.value;
        }
      }
    },
    set_last_iteration(state, item) {
      const arrayListName = GAME_LIST_NAMES_WITH_KS;
      for (let i = 0; i < arrayListName.length; i += 1) {
        const element = arrayListName[i];
        const ar = cloneDeep(state[element]);

        for (let j = 0; j < ar.length; j += 1) {
          const el = ar[j];
          if (el.id === item.item.id) {
            el.stats = item.value;
            state[element] = ar;
          }
        }
      }
    },
    disable_game(state, item) {
      const ar = cloneDeep(state.myGames);

      for (let j = 0; j < ar.length; j += 1) {
        const el = ar[j];
        if (el.id === item.id) {
          el.disabled = item.value;
          state.myGames = ar;
        }
      }
    },
    updated_game(state, item) {
      const ar = cloneDeep(state.myGames);
      for (let j = 0; j < ar.length; j += 1) {
        const el = ar[j];
        if (el.id === item.id) {
          el.isUpdated = item.value;
          el.disabled = true;
          state.myGames = ar;
        }
      }
    },
    update_game(state, item) {
      const ar = cloneDeep(state.myGames);

      for (let j = 0; j < ar.length; j += 1) {
        const el = ar[j];
        if (el.id === item.id && item.name) {
          ar[j] = {
            ...el,
            ...item,
          };

          if (state.currentStatus !== tools.getWhichFilterGameIs(item)) {
            ar.splice(j, 1);
            Message({
              dangerouslyUseHTMLString: true,
              message: `<b class="message-bold">${item.name}</b> moved to <b class="message-bold">${tools.getWhichFilterGameIs(item)}</b>`,
              type: 'info',
              duration: 3000,
              showClose: true,
            });
            if (tools.getWhichFilterGameIs(item) === 'archived') {
              state.myGamesArchivedNeedRefresh = true;
            }
          }
          state.myGames = ar;
        }
      }
    },
    set_sort_and_order(state, value) {
      const params = cloneDeep(state.params);
      if (value.filter) {
        params.myGames.sort = value.sort;
        params.myGames.order = value.order;
      } else {
        params.knowledgeSharing.sort = value.sort;
        params.knowledgeSharing.order = value.order;
      }

      state.params = params;
    },
    set_sort_and_order_ks(state, value) {
      const params = cloneDeep(state.params);
      params.knowledgeSharing.sort = value.sort;
      params.knowledgeSharing.order = value.order;

      state.params = params;
    },
    set_query_ks(state, value) {
      const params = cloneDeep(state.params);
      if (value.type === 'cpi') {
        params.knowledgeSharing.query.cpi = value.query;
      } else {
        params.knowledgeSharing.query.ctr = value.query;
      }
      state.params = params;
    },
    set_tags_ks(state, value) {
      const params = cloneDeep(state.params);
      const arrayTagsParams = [];
      value.value.forEach((el) => {
        arrayTagsParams.push({
          type: 'query-builder-rule',
          query: {
            rule: 'tags',
            operator: 'contains',
            operand: 'Tags',
            value: el,
          },
        });
      });
      if (value.type === 'cpi') {
        params.knowledgeSharing.query.cpi.children[1].query.children = arrayTagsParams;
      } else {
        params.knowledgeSharing.query.ctr.children[1].query.children = arrayTagsParams;
      }
      state.params = params;
    },
    set_loading_pinedGame_ks(state, value) {
      const listKs = cloneDeep(state.knowledgeSharingGames);
      for (let i = 0; i < listKs.length; i += 1) {
        const element = listKs[i];
        if (element.id === value.gameId) {
          element.loading = value.value;
        }
      }
      state.knowledgeSharingGames = listKs;
    },
    set_pined_game_ks(state, value) {
      const listKs = cloneDeep(state.knowledgeSharingGames);
      for (let i = 0; i < listKs.length; i += 1) {
        const element = listKs[i];
        if (element.id === value.gameId) {
          element.isPinned = value.value;
        }
      }
      state.knowledgeSharingGames = listKs;
    },
  },
  actions: {
    setArchivedListNeedRefresh(context, value) {
      context.commit('set_archived_list_need_refresh', value);
    },
    check_operation(context, checkOperation) {
      context.commit('check_operation', checkOperation);
    },
    myGamesOnly(context, value) {
      context.commit('set_myGames_Only', value);
    },
    updateSortAndOrderByStatus(context, value) {
      if (value.sub === 'ks') {
        context.commit('set_sort_and_order_ks', value);
      } else context.commit('set_sort_and_order', value);
    },
    set_tags_ks(context, value) {
      context.commit('set_tags_ks', value);
    },
    disableGame({ commit }, item) {
      commit('disable_game', item);
    },
    updatedGame(context, item) {
      context.commit('updated_game', item);
    },
    showDetail(context, item) {
      context.commit('set_myGame_show', item);
    },
    reset_gameList(context) {
      context.commit('reset_gameList');
    },
    reset_gameList_archived(context) {
      context.commit('reset_gameList_archived');
    },
    reset_gameList_knowledgeSharing(context) {
      context.commit('reset_gameList_knowledgeSharing');
    },
    set_query_ks(context, value) {
      context.commit('set_query_ks', value);
    },
    async uploadVideoFile(context, payload) {
      context.commit('set_upload_video_loading', true);
      context.commit('submitGame/set_loading_property', true, { root: true });
      const response = await gameActionService.uploadVideo(payload);
      context.commit('submitGame/set_loading_property', false, { root: true });

      context.commit('set_upload_video_loading', false);

      if (!response.error) {
        context.commit('set_action_available_for_a_game', {
          game: payload.game,
          value: response,
        });
        context.commit('games/set_V1_object', response, { root: true });
        this.dispatch('gameAction/getVideoAvailable', payload.game);
        this.dispatch('games/refeshGameObject', payload.game.id);
        this.dispatch('submitGame/getV1Game', payload.game.id);
        this.dispatch('submitGame/getGameHidden', payload.game.id);
      } else if (response.videoId) {
        const param = {
          id: response.videoId,
          gameId: response.gameId,
        };
        await gameActionService.deleteVideoGameId(param);
      }
    },
    async getMyGames(context, payload) {
      const source = payload.status === 'archived' ? 'archived' : 'myGames';

      if (payload.status !== 'archived') {
        context.commit('set_myGames_loading', true);
      }

      // special case when the status is archived and the previous is finished and vice versa
      const finishedAndArchived = (context.state.currentStatus === 'finished' && payload.status === 'archived') || (context.state.currentStatus === 'archived' && payload.status === 'finished');

      // There are two main cases when this action (getMyGames) is called :
      // 1) Clicking on a tab (switching the lists)
      // 2) Scrolling down in a list
      // When the user clicks on a tab, we cancel the previous call and empty the myGames list
      if (context.state.currentStatus !== payload.status && !finishedAndArchived) {
        if (context.state.abortController) {
          context.state.abortController.abort();
        }
        context.commit('reset_gameList');
        context.commit('set_current_status', payload.status);
      }

      const perms = context.rootState.users.myPermissions;
      const canHaveMultipleStudio = perms.indexOf('filter_team_status') > -1;
      let myGamesOnly = context.state.params.me ? 1 : 0;
      if (!canHaveMultipleStudio) {
        myGamesOnly = 0;
      }

      const filters = cloneDeep(context.state.params[source]);
      filters.query = cloneDeep(gameFilters[payload.status]);

      if (payload.type === 'cpi') {
        filters.query.children.push({
          type: 'query-builder-rule',
          query: {
            rule: 'isCtr',
            operator: 'equals',
            operand: 'Is CTR',
            value: false,
          },
        });
      } else {
        filters.query.children.push({
          type: 'query-builder-rule',
          query: {
            rule: 'isCtr',
            operator: 'equals',
            operand: 'Is CTR',
            value: true,
          },
        });
      }
      const param = {
        myGamesOnly,
        term: payload.term,
        limit: context.state.params.limit,
        ...filters,
      };

      // Create a new cancellation instance
      const abortController = new AbortController();
      context.commit('set_abort_controller', abortController);

      const response = await gameActionService.getMyGames(param, payload.status, abortController);

      context.commit('set_myGames_loading', false);
      if (response && response.length > 0) {
        context.commit('set_my_games', {
          games: response,
          status: payload.status,
        });
        if (payload.loader) {
          payload.loader.loaded();
        }
      } else if (payload.loader) {
        payload.loader.complete();
      }
      return true;
    },
    async searchForAgame(context, term) {
      return new Promise(async (resolve) => {
        const param = {
          myGamesOnly: 0,
          term,
          limit: 50,
        };
        const response = await gameActionService.getGamesByTerm(param);

        if (response !== null) {
          resolve(response);
        }
        resolve(null);
      });
    },
    requestAppAccess(context, { game, facebookAppId }) {
      const param = {
        action: 'request_application_access',
        id: game.id,
        facebookAppId,
      };
      return gameActionService.getRequestAppAccess(param);
    },
    async getActionAvailable(context, game) {
      context.commit('set_actionGame_loading', {
        game,
        value: true,
      });
      const response = await gameActionService.getActionByGameId(game.id);
      context.commit('set_actionGame_loading', {
        game,
        value: false,
      });
      if (response) {
        context.commit('set_action_available_for_a_game', {
          game,
          value: response,
        });
      }
    },
    async getVideoAvailable(context, game) {
      context.commit('set_videoGame_loading', {
        game,
        value: true,
      });
      const response = await gameActionService.getVideoByGameId(game.id);
      context.commit('set_videoGame_loading', {
        game,
        value: false,
      });
      if (response) {
        context.commit('set_video_available_for_a_game', {
          game,
          value: response,
        });
        context.commit('games/set_video_available_for_a_game', {
          game,
          value: response,
        }, { root: true });
      }
    },
    async setRequestTesting(context, item) {
      context.commit('set_actionGame_loading', {
        game: item.game,
        value: true,
      });
      context.commit('disable_game', {
        id: item.game.id,
        value: true,
      });
      context.commit('set_actionGame_loading', {
        game: item.game,
        value: false,
      });

      const response = await gameActionService.setRequestTestingByGameId(item);
      context.commit('disable_game', {
        id: item.game.id,
        value: false,
      });
      if (!response.detail) {
        context.commit('set_V1_GameObject', response);
        context.commit('updated_game', {
          id: item.game.id,
          value: false,
        });
        context.commit('games/set_updated_game', false, { root: true });
        context.commit('games/set_loadingDisable_game', true, { root: true });
      } else if (!item.game.isIterating) {
        this.dispatch('gameAction/setNextStep', {
          game: item.game,
          status: 'playstore_submission',
        });
        context.commit('disable_game', {
          id: item.game.id,
          value: false,
        });
      } else {
        this.dispatch('gameAction/setNextStep', {
          game: item.game,
          status: 'playstore_submission',
        });
        context.commit('disable_game', {
          id: item.game.id,
          value: false,
        });
      }
    },
    async setNextStep(context, item) {
      context.commit('set_stepLoading', {
        id: item.game.id,
        value: true,
      });
      const response = await gameActionService.setNextStepGame(item);
      context.commit('set_stepLoading', {
        id: item.game.id,
        value: false,
      });
      if (response) {
        context.commit('set_next_step', item);
      }
    },
    async deleteVideoGameId(context, item) {
      context.commit('submitGame/set_loadingVideosByPlatform', true, { root: true });
      const response = await gameActionService.deleteVideoGameId(item);
      context.commit('submitGame/set_loadingVideosByPlatform', false, { root: true });
      if (response) {
        context.commit('submitGame/set_game', response, { root: true });
      }
    },
    async getStatsByGameId(context, item) {
      context.commit('set_stats_loading', {
        item,
        value: true,
      });
      const response = await gameActionService.getStatsByGameId(item);
      context.commit('set_stats_loading', {
        item,
        value: false,
      });
      if (response) {
        const lastIteration = response.iterations[response.iterations.length - 1];
        context.commit('set_last_iteration', {
          item,
          value: lastIteration,
        });
      }
    },

    async getStatsKSByGameId(context, item) {
      context.commit('set_stats_loading', {
        item,
        value: true,
      });
      const response = await gameActionService.getStatsKSByGameId(item);
      context.commit('set_stats_loading', {
        item,
        value: false,
      });
      if (response) {
        const lastIteration = response.iterations[response.iterations.length - 1];
        context.commit('set_last_iteration', {
          item,
          value: lastIteration,
        });
      }
    },

    async getActionStatusFromGameid(context, id) {
      return new Promise(async (resolve) => {
        const list = await gameActionService.getActionStatusFromGameid(id);
        if (list) {
          resolve(list);
        } else {
          resolve([]);
        }
      });
    },

    async getKnowledgeSharing(context, payload) {
      context.commit('set_myGames_loading', true);
      const myGamesOnly = 0;
      const filters = cloneDeep(context.state.params.knowledgeSharing);
      if (payload.type === 'cpi') {
        filters.query.cpi.children.push({
          type: 'query-builder-rule',
          query: {
            rule: 'isCtr',
            operator: 'equals',
            operand: 'Is CTR',
            value: false,
          },
        });
      } else {
        filters.query.ctr.children.push({
          type: 'query-builder-rule',
          query: {
            rule: 'isCtr',
            operator: 'equals',
            operand: 'Is CTR',
            value: true,
          },
        });
      }
      if (payload.type === 'cpi') {
        filters.query = filters.query.cpi;
      } else {
        filters.query = filters.query.ctr;
      }
      const param = {
        myGamesOnly,
        term: payload.term,
        limit: context.state.params.limit,
        ...filters,
      };

      const response = await gameActionService.getKnowledgeSharingGames(param);
      context.commit('set_myGames_loading', false);
      if (response) {
        if (response.length > 0) {
          payload.loader.loaded();
        } else {
          payload.loader.complete();
        }
        context.commit('set_knowledgeSharingGames', response);
      } else {
        payload.loader.complete();
        context.commit('set_knowledgeSharingGames', null);
      }
    },

    async getFeedbacks(context, id) {
      return new Promise(async (resolve) => {
        const list = await gameActionService.getFeedbackByGameId(id);
        if (list) {
          resolve(list);
        } else {
          resolve([]);
        }
      });
    },

    async resetNumberGameInStatus(context) {
      context.commit('reset_numberGamesBystatus');
    },

    async getNumberGameInStatus(context, value) {
      const params = value || {};
      if (!params.statuses) {
        params.statuses = {};
        Object.keys(context.state.numberGameByStatus).forEach((status) => {
          params.statuses[status] = {
            query: gameFilters[status],
          };
        });
      }

      const response = await gameActionService.getNbGamebyStatus(params);
      if (response) {
        context.commit('set_numberGamesBystatus', response);
      }
    },

    async pineAGame(context, value) {
      return new Promise(async (resolve) => {
        context.commit('set_loading_pinedGame_ks', {
          gameId: value.gameId,
          value: true,
        });
        const response = await gameActionService.pineKSGame(value);
        context.commit('set_loading_pinedGame_ks', {
          gameId: value.gameId,
          value: false,
        });

        if (response) {
          context.commit('set_pined_game_ks', value);
          resolve(value);
        } else {
          resolve(null);
        }
      });
    },
  },
};

export default gameAction;

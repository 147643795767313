import { cloneDeep } from 'lodash';
import axiosCancel from 'axios-cancel';
import axios from '@/tools/axios';

axiosCancel(axios, {
  debug: false, // default
});
export default class GamesService {
  static async fetchGames(params) {
    const p = cloneDeep(params);

    p.query = p.view.content.filter;
    p.term = p.view.content.term;

    delete p.startDate;
    delete p.endDate;
    delete p.status;
    delete p.date;
    if (p.view.content.sort) {
      p.sort = p.view.content.sort.prop;
      p.order = p.view.content.sort.order;
    }

    delete p.best;
    delete p.studios;
    delete p.gls;
    delete p.filter;
    delete p.view;
    const requestId = 'my_sample_request';

    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/games`,
      data: p,
      method: 'post',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch((thrown) => {
        if (thrown) {
          return { data: null };
        }
        return { data: [] };
      }); // .catch(() => ({ data: [] }));

    axios.cancel(requestId);
    return call;
  }

  static async addGames() {
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  static async setActionById(param) {
    const data = {};

    if (param.action.post && param.action.post.field.length > 0) {
      for (let i = 0; i < param.action.post.field.length; i += 1) {
        const element = param.action.post.field[i];
        if (element.type === 'Object') {
          data[element.param] = {
            [element.deepParam[0]]: element.value,
          };
        } else {
          data[element.param] = element.value;
        }
      }
    }
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${param.id}/${param.action.id}`,
      method: 'post',
      data,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      .catch(error => ({
        error: error.response && error.response.data ? error.response.data : null,
      })); // .catch(() => ({ data: [] }));

    return call;
  }

  static async getActionById(id) {
    const requestId = 'getActionById';

    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${id}`,
      method: 'get',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async getSpecificActionById(param) {
    const requestId = `getSpecificActionById${Math.random()}`;

    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/game/${param.id}/${param.action}`,
      method: 'get',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async getGamesInfos(id) {
    const requestId = 'getGamesInfos';
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/games/${id}`,
      method: 'get',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }

  static async getKpiGame(id) {
    const requestId = 'getKpiGame';
    const call = axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/v1/game/${id}`,
      params: {
        kpi: 'cpi,d1_ret,d7_ret,iterationVideos,iterations,session_length,playtime',
      },
      method: 'get',
      requestId,
    })
      .then((r) => {
        if (typeof r !== 'undefined') {
          return r.data;
        }
        return { data: [] };
        // eslint-disable-next-line consistent-return
      })
      // eslint-disable-next-line consistent-return
      .catch(() => null); // .catch(() => ({ data: [] }));

    return call;
  }
}

<template>
  <div :class="$style.wrapper">
    <div :class="$style.firstLine">
      <div v-if="!isCreation" :class="$style.title">
        {{ labelValues[titleLabel] }}<span v-if="titleValue">:</span> {{ titleValue }}
      </div>
      <div v-else :class="$style.title">
        Studio creation
      </div>
      <div :class="$style.date">{{ dateFormat(date) }}</div>
    </div>
    <div v-if="!isCreation" :class="$style.author">By {{ updatedBy }}</div>
    <div v-else :class="$style.author">from {{ titleValue }}</div>
  </div>
</template>

<script>
export default {
  name: 'EventItem',
  props: {
    date: {
      type: String,
      default: '',
    },
    updatedBy: {
      type: String,
      default: '',
    },
    titleLabel: {
      type: String,
      default: '',
    },
    titleValue: {
      type: String,
      default: '',
    },
    isCreation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelValues: {
        team_status_id: 'New team status',
        publishing_manager_id: 'New Primary PM',
        secondary_publishing_manager_id: 'New Secondary PM',
        origin: '',
        deactivate_reason: 'Studio deactivation',
        deactivate_date: 'Studio deactivation',
        reactivate: 'Studio reactivation',
        is_regretted_churn: 'Regretted Churn',
        churned_at: 'Churned',
        churn_reason: 'Churn reason',
      },
    };
  },
  methods: {
    dateFormat(date) {
      const dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString('en-GB');
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  color: $slate;
  font-size: 12px;
  padding: $spacing * 3;
  border-bottom: 1px solid $foggy-grey;
}

.firstLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}

.title {
  font-weight: 600;
}

.date {
  font-weight: 600;
  color: $grey;
  opacity: 0;
  transition: all 500ms;
}

.wrapper:hover .date {
    opacity: 1;
}

.author {
  font-weight: normal;
}
</style>

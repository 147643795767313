<template>
  <div :class="$style.wrapper" :style="[backgroundStyle, inProgressStyle]" @click="gotoGamePage()">
    <AsyncAction style="position:absolute;" :game="game"></AsyncAction>

    <div v-if="game.disabled" :class="$style.disabled">
      <span>There are ongoing operations</span>
      <span>
        <i class="el-icon-loading"></i>
      </span>
    </div>
    <div :class="$style.cell">
      <GameInfos :game="game" :style="gameInfosStyle" />
    </div>
    <div v-if="!isInProgress" :class="$style.cell">
      <AdNetworks v-if="game" :game="game" />
    </div>
    <div v-if="!isInProgress" :class="$style.cell">{{ cpi }}</div>
    <div v-if="!isInProgress" :class="$style.cell">{{ d1 }}</div>
    <div v-if="!isInProgress" :class="$style.cell">{{ lastD3PlaytimeHumanReadable }}</div>
    <div v-if="!isInProgress" :class="$style.cell + ' '+ $style.paddingRight">
      <ActionButton
        :key="JSON.stringify(game.actions)"
        :game="game"
        :class="$style.actionButtons"
        :show-project="false"></ActionButton>
    </div>
    <div v-if="isInProgress" :class="$style.cellInProgress">
      <font-awesome-icon icon="cogs" :class="$style.iconInProgress" />
      <div :class="$style.progressText">
        <div>Game submission in progress</div>
        <span
        >The test metrics will appear here as soon as you complete the game
          submission flow.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/modules/ActionButton';
import AsyncAction from '@/components/AsyncAction.vue';
import Tools from '@/tools/tools';
import GameInfos from './GameInfos.vue';
import AdNetworks from './AdNetworks.vue';

export default {
  name: 'GameProjectItem',
  components: {
    GameInfos,
    AdNetworks,
    ActionButton,
    AsyncAction,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      const backgroundColor = this.game.status === 'finished' ? '#F3F3F7' : '#ffffff';
      return {
        backgroundColor,
      };
    },
    inProgressStyle() {
      return this.isInProgress
        ? {
          gridTemplateColumns: '370px 5fr',
        }
        : {};
    },
    gameInfosStyle() {
      return this.isInProgress ? { opacity: '0.5' } : {};
    },
    isInProgress() {
      return Tools.getWhichFilterGameIs(this.game) === 'in_progress';
    },
    cpi() {
      return this.game?.lastCpi ? `$${this.game.lastCpi.toFixed(2)}` : 'N/A';
    },
    d1() {
      return this.game?.lastD1
        ? `${Math.round(this.game.lastD1 * 100)}%`
        : 'N/A';
    },
    lastD3PlaytimeHumanReadable() {
      return this.game.lastD3PlaytimeHumanReadable || 'N/A';
    },
  },
  methods: {
    gotoGamePage() {
      this.$router.push({
        query: {
          gameid: this.game.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: grid;
  grid-template-columns: 370px repeat(3, 0.7fr) 1fr 1.5fr;
  border-bottom: $foggy-grey 1px solid;
  position:relative;
  &:hover {
    cursor: pointer;
  }
}

.cell {
  font-size: 12px;
  font-weight: 600;
  padding: $spacing * 2 0;
  display: flex;
  align-items: center;
  &.paddingRight {
    padding-right: 16px;
  }

  > * {
    flex-grow: 1;
  }
}

.progressText {
  span {
    color: $grey;
    font-size: 10px;
  }
}

.cellInProgress {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.iconInProgress {
  margin-right: $spacing;
  font-size: 15px;
}

.actionButtons {
  justify-content: flex-end;
}

.disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 10px;
    background-color: rgba(201, 224, 246, 0.89);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #067bf0;
    font-weight: bold;
    span {
      margin-right: 10px;
    }
  }
</style>

<template>
  <div :class="$style.wrapper">
    <div v-for="(step, index) in steps" :key="index" data-test-id="gameSubmissionStepperStep">
      <div :class="[$style.step,  $style[`step-${step.state}`]]" @click="stepSelected(step, index)">
        <div :class="[$style.bubble,  $style[`bubble-${step.state}`]]">{{ index+1 }}</div>
        <div :class="$style.stepDescription">
          <div :class="[$style.name, $style[`name-${step.state}`]]">{{step.name}}</div>
          <div :class="$style.duration">{{step.duration}}</div>
        </div>
      </div>
      <div
        v-if="index !== steps.length - 1"
        :class="[$style.line, index < current ? $style['line-active'] : '']"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    stepSelected(step, index) {
      if (step.state === 'disable') { return; }
      this.$emit('change', index);
    },
  },
};
</script>


<style lang="scss" module>
.wrapper {
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
}

.step {
  position: relative;
  max-height: 21px;
  &:last-child {
    margin-bottom: 20px;
  }
}

.step-disabled {
  cursor: initial !important;
}

.bubble {
  width: 21px;
  height: 21px;
  border: solid 1px $slate;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  color: $slate;
  border-radius: 100%;
  border-color: $foggy-grey;
  position: relative;
}

.bubble-done {
  background-color: $light-blue;
  border: none;
  color: $white;
}

.bubble-current {
  background-color: $intense-blue;
  border: none;
  color: $white;
}

.step-done {
 &:hover{
   cursor: pointer;
 }
}

.step-current {
  &:hover{
   cursor: pointer;
 }
}


.line {
  height: calc(100vh / 15);
  width: 1px;
  background-color: $foggy-grey;
  transform: translateX(10px);
}

.line-active {
  background-color: $light-blue;
}

.step {
  display: inline-flex;
  align-items: center;
   &.clickable{
    cursor: pointer;
  }
}

.stepDescription  {
  text-align: left;
  margin-left: $spacing;
  font-size: 10px;
}

.name {
  color: $slate;
  margin-bottom: calc($spacing / 2);
  width: 105px;
  font-size: 12px;
  line-height: 14px;
}

.name-done {
  color: $light-blue
}

.name-current {
  color: $intense-blue;
}

.duration {
  color: $grey;
  position: absolute;
  font-size: 10px;
  line-height: 12px;
}
</style>

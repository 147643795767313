// import { cloneDeep } from 'lodash';
import axios from '@/tools/axios';

export default class StudioAdminService {
  static async getStudios() {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return [
    //   {
    //     studio_id: 629,
    //     role: 'studio_developer',
    //     studio_name: 'Test JTA update',
    //     number_employees: 1,
    //   },
    //   {
    //     studio_id: 630,
    //     role: 'studio_developer',
    //     studio_name: 'Enaayah',
    //     number_employees: 1,
    //   },
    // ];
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studios`,
      method: 'get',
      // headers: {
      //   'Cache-Control': 'no-cache',
      // },
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getStudioInfos(id) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return {
    //   studio_name: 'Test JTA update',
    //   team_status: 'coaching',
    //   churned_at: null,
    //   ad_account: '2437050399882361',
    //   address_id: 169,
    //   number_employees: 1,
    //   unregistered_name: 'Test JTA update',
    //   hubspot_id: null,
    //   street_number: null,
    //   street_name: null,
    //   zip_code: null,
    //   city: null,
    //   country: 'US',
    //   since: moment(),
    // };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${id}`,
      method: 'get',
      // headers: {
      //   'Cache-Control': 'no-cache',
      // },
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getStudioContracts(id) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return [
    //   { name: 'contract 1', id: 2211, date: moment() },
    //   { name: 'contract 2', id: 222211, date: moment() },
    //   { name: 'contract 3', id: 22121, date: moment() },
    // ];
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${id}/contracts`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getContract(data) {
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${data.studioId}/contract/${data.id}`,
      method: 'get',
      responseType: 'blob',
    })
      .then((r) => {
        const file = new Blob([r.data], { type: 'application/pdf' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
        return true;
      })
      .catch(() => null);
  }

  static async getStudioUsers(id) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return [
    //   {
    //     role: 'studio_developer',
    //     user_id: 657,
    //     first_name: 'Joshua',
    //     last_name: 'Peacock',
    //     is_active: 1,
    //     username: 'josh@joshuapeacock.com',
    //     permission: 'full',
    //   },
    //   {
    //     role: 'studio_developer',
    //     user_id: 6157,
    //     first_name: 'Tom',
    //     last_name: 'Cook',
    //     is_active: 1,
    //     username: 'Tom@joshuCookapeacock.com',
    //     permission: 'restricted',
    //   },
    // ];
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${id}/users`,
      method: 'get',
    })
      .then((r) => {
        if (r.data) {
          return r.data;
        }
        return null;
      })
      .catch(() => null);
  }

  static async getStudioPermissions(studioId) {
    return axios
      .post(`${process.env.VUE_APP_PUBLIC_API_URL}/api/permissions/on_objects/`, {
        objectType: 'studio',
        objectIds: [studioId],
        users: ['me'],
      })
      .then((r) => {
        if (r.data) {
          return Object.values(r.data.users)[0].studios[studioId];
        }
        return null;
      })
      .catch(() => null);
  }

  static async createNewUser(data) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return {
    //   status: 200,
    // };
    const { studioId } = data;
    const d = data;
    delete d.id;
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${studioId}/users`,
      method: 'post',
      data: d.user,
    })
      .then((r) => {
        if (r.status === 200) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateStudiosInfos(data) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return {
    //   status: 200,
    // };
    const { id } = data;
    const d = data;
    delete d.id;
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${id}`,
      method: 'put',
      data: d,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateStudiosUserActive(data) {
    // await new Promise(resolve => setTimeout(resolve, 500));

    // return {
    //   status: 200,
    // };
    const d = {
      accessId: data.accessId,
    };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${data.studioId}/user/${data.id}`,
      method: 'put',
      data: d,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateStudiosUserRole(data) {
    const d = {
      userRole: data.value,
    };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${data.studioId}/user/${data.id}`,
      method: 'put',
      data: d,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateStudiosUserPermission(data) {
    const d = {
      accessId: data.value,
    };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${data.studioId}/user/${data.id}`,
      method: 'put',
      data: d,
    })
      .then((r) => {
        if (r.status === 204) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }

  static async updateKnowledgeSharingByStudioId(data) {
    const d = {
      status: !data.value,
    };
    return axios({
      url: `${process.env.VUE_APP_PUBLIC_API_URL}/ss/studio/${data.id}/sharing`,
      method: 'post',
      data: d,
    })
      .then((r) => {
        if (r.status === 200) {
          return true;
        }
        return null;
      })
      .catch(() => null);
  }
}

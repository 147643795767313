<template>
  <div class="feedback">
    <div v-if="displaySendForm" class="feedback-send">
      <p>Enter feedback - Voodoo only</p>
      <p>Use this form to write feedback to the owners of <strong>{{ game.name }}</strong> about their game. </p>
      <p>They will receive it automatically by e-mail.</p>
      <label>Subject: <el-input
        v-model="newFeedbackSubject"
        type="text"
        placeholder="TL/DR of your feedback"
        data-test-id="myGamesFeedbackSubjectInput"></el-input></label>
      <label>Message: <el-input
        v-model="newFeedbackBody"
        data-test-id="myGamesFeedbackBodyInput"
        type="textarea"
        rows="6"
        placeholder="Enter detailed feedback here"></el-input></label>
    </div>
    <div v-if="!loadingFeedbackList" class="feedback-list">
      <div
        v-for="feedback in feedbackList"
        :key="feedback.id"
        class="feedback-item">
        <div class="feedback-container" data-test-id="myGamesFeedbackListContainer">
          <div class="header">
            <div class="author"><i class="el-icon-user-solid el-icon--left"></i>{{ feedback.pmName }}</div>
            <div class="date">{{ formatFeedbackDate(feedback.createdAt) }}</div>
          </div>
          <div class="subject">{{ feedback.subject }}</div>
          <div class="content" v-html="feedback.feedback"></div>
        </div>
        <i data-test-id="myGamesDeleteFeedbackButton" @click="sendFeedbackDeleteActionWithparam(feedback.id)"></i>
        <hr />
      </div>
      <div v-if="!displaySendForm && feedbackList.length === 0">No feedback yet</div>
    </div>
    <div v-if="loadingFeedbackList" class="feedback-loading">
      <i class="el-icon-loading"></i>
    </div>
    <div class="footer">
      <el-button
        v-if="displaySendForm"
        data-test-id="myGamesCloseFeedbackCancelButton"
        @click="closeFeedback()">
        Cancel
      </el-button>
      <el-button
        v-if="displaySendForm"
        type="primary"
        :disabled="!!!newFeedbackBody"
        data-test-id="myGamesFeedbackConfirmButton"
        @click="sendFeedbackActionWithparam()">
        Confirm
      </el-button>
      <el-button
        v-if="!displaySendForm"
        type="primary"
        data-test-id="myGamesFeedbackOkButton"
        @click="closeFeedback()">
        Ok
      </el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';

export default {
  name: 'Feedback',
  components: { },
  props: {
    game: {
      type: Object,
      default: null,
    },
    displaySendForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionList,
      loadingFeedbackList: false,
      feedbackList: [],
      newFeedbackBody: '',
      newFeedbackSubject: '',
    };
  },
  computed: {
  },
  mounted() {
    this.retrieveFeedbacks();
  },
  methods: {
    goto(url) {
      if (url) window.open(url);
    },
    retrieveFeedbacks() {
      this.loadingFeedbackList = true;
      this.$store
        .dispatch('gameAction/getFeedbacks', {
          game: this.game,
        })
        .then((response) => {
          this.loadingFeedbackList = false;
          this.feedbackList = response;
        });
    },
    formatFeedbackDate(date) {
      return moment(date).format('YYYY/MM/DD - hh:mma');
    },
    sendFeedbackActionWithparam() {
      if (this.actionList) {
        const action = this.actionList.filter(el => el.id === 'add_game_feedback')[0];
        action.post.field[0].value = this.newFeedbackSubject;
        action.post.field[1].value = this.newFeedbackBody;

        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);
        this.newFeedbackSubject = '';
        this.newFeedbackBody = '';
        this.retrieveFeedbacks();
      }
    },
    sendFeedbackDeleteActionWithparam(feedbackId) {
      const param = {
        id: cloneDeep(this.game.id),
        action: {
          id: 'delete_game_feedback',
          post: {
            field: [
              {
                type: 'String',
                param: 'feedback_id',
                value: feedbackId,
              },
            ],
          },
        },
      };
      this.$store.dispatch('ActionToDispatch/dispatchAction', param);
    },
    closeFeedback() {
      this.$emit('close-pop-in');
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  overflow-y: auto;
  p {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  label {
    .el-input,
    .el-textarea {
      margin: 5px 0;
    }
  }
  .feedback-loading {
    margin: 10px;
  }
  .feedback-send {
    background-color: #f7fbff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .feedback-list {
    .feedback-item {
      padding: 10px 0;
      justify-content: space-between;
      position: relative;
      .feedback-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        word-break: break-word;
      }
      .header {
        display: flex;
        margin-bottom: 5px;
        color: $grey;
        .author {
          font-size: 12px;
          font-weight: bold;
        }
        .date {
          &::before {
            content: "\2022";
            margin: 0 10px;
          }
          font-size: 10px;
        }
      }
      .subject {
        margin-bottom: 5px;
        line-height: 15px;
        color: #067bf0;
      }
      .content {
        line-height: 18px;
        color: $slate;
      }
    }
  }
  .footer {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}

</style>

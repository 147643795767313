<template>
  <el-dialog
    :visible="value"
    center
    :width="`${1130 * (availableData / 3)}px`"
    :append-to-body="true"
    :modal-append-to-body="true"
    title="KPIs graphs"
    class="game-stats-charts"
    @close="close">
    <div
      :class="$style.chartsContainer"
      data-test-id="gameInfosChartsContainer"
    >
      <Charts
        v-if="isCPNU && cpnu.data"
        :class="$style.chart"
        color="#9506f0"
        crosshair-color="#f4e5fd"
        :categories="cpnu.categories"
        :data="cpnu.data"
        :average="cpnu.average"
        :type="cpnu.type"
        :tooltip-format-type="cpnu.tooltipFormatType"
        :tooltip-info="cpnu.tooltipInfo"
      >
        <slot name="cpnu"></slot>
      </Charts>
      <Charts
        v-else
        :class="$style.chart"
        color="#9506f0"
        crosshair-color="#f4e5fd"
        :categories="cpi.categories"
        :data="cpi.data"
        :average="cpi.average"
        :type="cpi.type"
        :tooltip-format-type="cpi.tooltipFormatType"
        :tooltip-info="cpi.tooltipInfo"
      >
        <slot name="cpi"></slot>
      </Charts>
      <Charts
        v-if="data.d1"
        :class="$style.chart"
        color="#f07306"
        crosshair-color="#fdf0e5"
        :categories="d1.categories"
        :data="d1.data"
        :average="d1.average"
        :type="d1.type"
        :tooltip-format-type="d1.tooltipFormatType"
        :tooltip-info="d1.tooltipInfo"
      >
        <slot name="d1"></slot>
      </Charts>
      <Charts
        v-if="data.d3Playtime"
        :class="$style.chart"
        color="#ffcb00"
        crosshair-color="#fff9e4"
        :categories="d3Playtime.categories"
        :data="d3Playtime.data"
        :average="d3Playtime.average"
        :chart-type="d3Playtime.chartType"
        :time-format="d3Playtime.timeFormat"
        :type="d3Playtime.type"
        :tooltip-format-type="d3Playtime.tooltipFormatType"
        :tooltip-info="d3Playtime.tooltipInfo"
      >
        <slot name="d3Playtime"></slot>
      </Charts>
    </div>
  </el-dialog>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { Charts } from '@/modules/GameStats/components/Charts';

export default {
  name: 'ChartsDialog',
  components: {
    Charts,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    game: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCPNU() {
      if (this.game.platform === 'ios') {
        const date = new Date(this.game.createdAt);
        const minDate = new Date('2021-05-01');
        if (minDate < date) {
          return true;
        }
      }
      return false;
    },
    cpi() {
      if (!this.data.cpi) return {};

      let dates = this.aggregateData('cpi', 'date');
      dates = dates.map((date) => {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        return `${day}/${month}`;
      });
      return {
        categories: dates,
        data: this.aggregateData('cpi', 'value'),
        average: `$${this.data.cpi.average}`,
        type: 'Cost per install',
        tooltipFormatType: 'cpi',
        tooltipInfo: 'Cost per acquired paid user (excluding organic downloads). A high CPI means few users were acquired, therefore the in-game metrics cannot be considered as reliable data.',
      };
    },
    cpnu() {
      if (!this.data.cpnu) return {};

      let dates = this.aggregateData('cpnu', 'date');
      dates = dates.map((date) => {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        return `${day}/${month}`;
      });
      return {
        categories: dates,
        data: this.aggregateData('cpnu', 'value'),
        average: `$${this.data.cpnu.average}`,
        type: 'Cost per new user',
        tooltipFormatType: 'cpnu',
        tooltipInfo: 'Ad network spends / total new users (including organic downloads), in the US.',
      };
    },
    d1() {
      if (!this.data.d1) return {};

      let dates = this.aggregateData('d1', 'date');
      dates = dates.map((date) => {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        return `${day}/${month}`;
      });
      return {
        categories: dates,
        data: this.aggregateData('d1', 'value'),
        average: `${this.data.d1.average}%`,
        type: 'Retention D1',
        tooltipFormatType: 'd1',
        tooltipInfo: 'The percentage of users that launch the game on the next day after install.',
      };
    },
    d3Playtime() {
      if (!this.data.d3Playtime) return {};

      let dates = this.aggregateData('d3Playtime', 'date');
      dates = dates.map((date) => {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        return `${day}/${month}`;
      });

      const timeFormat = {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%Mm%Ss',
        day: '%Mm%Ss',
        week: '%Mm%Ss',
        month: '%Mm%Ss',
        year: '%Mm%Ss',
      };

      // const time = new Date(this.data.playtime.average * 1000);

      return {
        categories: dates,
        data: this.aggregateData('d3Playtime', 'value'),
        average: this.data.d3Playtime.average,
        chartType: 'datetime',
        type: 'Cumulative D3 playtime',
        timeFormat,
        tooltipFormatType: 'd3playtime',
        tooltipInfo: 'Total playtime for New Users over the first four days after install - from day 0 to day 3',
      };
    },
    availableData() {
      let available = 0;
      if (this.data.d3Playtime) available += 1;
      if (this.data.cpi && !this.isCPNU) {
        available += 1;
      } else if (this.data.cpnu && this.isCPNU) { available += 1; } else {
        available += 1;
      }

      if (this.data.d1) available += 1;
      return available;
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    aggregateData(kpi, target) {
      return this.data[kpi].daily.map(data => data[target]);
    },
  },
};
</script>

<style lang="scss" module>
.chartsContainer {
  display: flex;
}

.chart {
  width: 370px;
  border-right: 1px solid $foggy-grey;
}

.chart:nth-last-of-type(1) {
  border-right: 0px;
}
</style>


<style lang="scss">
.game-stats-charts {
  .el-dialog__header {
    background-color: $foggy-blue !important;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>

export default [
  'id',
  'itunesUrl',
  'snapchatUrl',
  // 'name',
  // 'studioName',
  'studioId',
  // 'pmName',
  'pmId',
  'secondaryPmId',
  // 'secondaryPmName',
  'glId',
  'gameLeadName',
  // 'assignedPmName',
  'assignedPmId',
  'execution',
  'balancing',
  'artEfficiency',
  'progression',
  'idea',
  'newness',
  'systemOne',
  'depth',
  'icon',
  'video',
  'storeUrl',
  'gameAnalyticsUrl',
  'facebookUrl',
  // 'status',
  // 'teamStatus',
  // 'isCtr',
  // 'isIterating',
  // 'isManagedByVoodoo',
  // 'bestCpi',
  'bestCpiNewUsers',
  'bestCpiIterationId',
  'bestCpiSpend',
  // 'bestCpiIsRelevant',
  // 'bestCtr',
  'bestCtrNewUsers',
  'bestCtrIterationId',
  'bestCtrSpend',
  // 'bestCtrIsRelevant',
  // 'bestD1',
  'bestD1Cohort',
  'bestD1NewUsers',
  'bestD1IterationId',
  'bestD1Spend',
  // 'bestD1IsRelevant',
  // 'bestD7',
  'bestD7Cohort',
  'bestD7NewUsers',
  'bestD7IterationId',
  'bestD7Spend',
  // 'bestD7IsRelevant',
  // 'bestSessionLength',
  'bestSessionLengthCohort',
  'bestSessionLengthNewUsers',
  'bestSessionLengthIterationId',
  'bestSessionLengthSpend',
  'bestSessionLengthHumanReadable',
  // 'bestSessionLengthIsRelevant',
  // 'bestPlaytime',
  'bestPlaytimeCohort',
  'bestPlaytimeNewUsers',
  'bestPlaytimeIterationId',
  'bestPlaytimeSpend',
  'bestPlaytimeHumanReadable',
  // 'bestD3Playtime',
  'bestD3PlaytimeCohort',
  'bestD3PlaytimeNewUsers',
  'bestD3PlaytimeIterationId',
  'bestD3PlaytimeSpend',
  'bestD3PlaytimeHumanReadable',
  // 'bestPlaytimeIsRelevant',
  // 'lastCpi',
  'lastCpiNewUsers',
  'lastCpiIterationId',
  'lastCpiSpend',
  // 'lastCpiIsRelevant',
  // 'lastCtr',
  'lastCtrNewUsers',
  'lastCtrIterationId',
  'lastCtrSpend',
  // 'lastCtrIsRelevant',
  // 'lastD1',
  'lastD1Cohort',
  'lastD1NewUsers',
  'lastD1IterationId',
  'lastD1Spend',
  // 'lastD1IsRelevant',
  // 'lastD7',
  'lastD7Cohort',
  'lastD7NewUsers',
  'lastD7IterationId',
  'lastD7Spend',
  // 'lastD7IsRelevant',
  'lastSessionLength',
  'lastSessionLengthCohort',
  'lastSessionLengthNewUsers',
  'lastSessionLengthIterationId',
  'lastSessionLengthSpend',
  'lastSessionLengthHumanReadable',
  'lastSessionLengthIsRelevant',
  // 'lastPlaytime',
  'lastPlaytimeCohort',
  'lastPlaytimeNewUsers',
  'lastPlaytimeIterationId',
  'lastPlaytimeSpend',
  'lastPlaytimeHumanReadable',
  // 'lastPlaytimeIsRelevant',
  // 'lastD3Playtime',
  'lastD3PlaytimeCohort',
  'lastD3PlaytimeNewUsers',
  'lastD3PlaytimeIterationId',
  'lastD3PlaytimeSpend',
  'lastD3PlaytimeHumanReadable',
  // 'createdAt',
  'dataUpdatedAt',
  // 'lastIterationDate',
  // 'sinceLastIterationDate',
  // 'sinceCreatedAt',
  // 'bestD14',
  'bestD14Cohort',
  'bestD14NewUsers',
  'bestD14IterationId',
  'bestD14Spend',
  // 'bestD14IsRelevant',
  // 'bestD28',
  'bestD28Cohort',
  'bestD28NewUsers',
  'bestD28IterationId',
  'bestD28Spend',
  // 'bestD28IsRelevant',
  // 'bestD90',
  'bestD90Cohort',
  'bestD90NewUsers',
  'bestD90IterationId',
  'bestD90Spend',
  // 'bestD90IsRelevant',
  // 'lastD14',
  'lastD14Cohort',
  'lastD14NewUsers',
  'lastD14IterationId',
  'lastD14Spend',
  // 'lastD14IsRelevant',
  // 'lastD28',
  'lastD28Cohort',
  'lastD28NewUsers',
  'lastD28IterationId',
  'lastD28Spend',
  // 'lastD28IsRelevant',
  // 'lastD90',
  'lastD90Cohort',
  'lastD90NewUsers',
  'lastD90IterationId',
  'lastD90Spend',
  // 'lastD90IsRelevant',
];

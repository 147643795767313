/* eslint-disable vue/no-v-html */
<template>
  <div v-if="me" class="dashboard-home">
    <transition name="slide-fade">
      <div v-show="slideShow" class="text header">
        <!-- <span class="subtitle"></span> -->
        <span
          class="title"
          data-test-id="HomePageStudioTitle"
        >{{ $t("views.StudioHome.header.main") }}</span>
      </div>
    </transition>
    <div class="container-background-element">
      <div class="rocket"></div>
      <div class="control"></div>
      <div class="trophy"></div>
    </div>
    <transition name="slide-fade">
      <div
        v-show="slideShow"
        class="text content"
        data-test-id="HomePageStudioTitleDescription"
      >
        <p class="title-description">
          This is our free game testing platform. Submit your game ideas to assess their potential!
        </p>
      </div>
    </transition>
    <LaunchProcess class="launch-process-section"/>
    <transition name="slide-fade">
      <div v-show="slideShow">
        <h2 class="title-margin"  data-test-id="HomePageOnlineResourcesTitle">{{ $t("views.StudioHome.onlineResourcesTitle") }}</h2>
        <p class="title-description" data-test-id="HomePageOnlineResourcesDesc" v-html="$t('views.StudioHome.onlineResourcesDescription')"></p>
      </div>
    </transition>
    <div class="image-link">
      <transition name="slide-fade">
        <div v-show="slideShow" class="live" @click="goto('https://voodoo.zendesk.com/hc/en-us/sections/360001782240-LIVE-STREAMS')">
          <el-image :src="livestreamIcon" :fit="'contain'">
            <div slot="placeholder" class="image-slot">{{ $t("common.loading") }}<span class="dot">...</span></div>
            <div slot="error" class="image-slot">
              <span><img :src="gameIcon" :width="40"/></span>
            </div>
          </el-image>

          <h1 data-test-id="HomePageLiveStreamTitle">{{ $t("views.StudioHome.liveStreamTitle") }}</h1>
          <p class="title-description"  data-test-id="HomePageLiveStreamDescription">{{ $t("views.StudioHome.liveStreamDescription") }}</p>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-show="slideShow" class="learning" @click="goto(eLearningAcademyInfos.link)">
          <el-image :src="elearningsIcon" :fit="'contain'">
            <div slot="placeholder" class="image-slot">{{ $t("common.loading") }}<span class="dot">...</span></div>
            <div slot="error" class="image-slot">
              <span><img :src="gameIcon" :width="40"/></span>
            </div>
          </el-image>
          <h1  data-test-id="HomePageELearningAcademyInfos">{{ eLearningAcademyInfos.label }}</h1>
          <p
            class="title-description"
            data-test-id="HomePageELearningsDescription">
            {{ $t("views.StudioHome.eLearningsDescription") }}</p>
        </div>
      </transition>
    </div>
    <div class="container-background-element"><div class="help-center-questionmark"></div></div>

    <transition name="slide-fade">
      <div v-show="slideShow" class="help-center">
        <p>
          <span
            class="title-help"
            data-test-id="HomePageHelpCenterTitle"
          >
            {{ $t("views.StudioHome.helpCenterTitle") }}</span>
          <br />
          <span
            data-test-id="HomePageHelpCenterDescription"
          >{{ $t("views.StudioHome.helpCenterDescription") }}</span>
          <span>
            <b
              data-test-id="HomePageHelpCenterTimeFrame"
            >{{ $t("views.StudioHome.helpCenterTimeFrame") }}</b>
          </span>
          <el-button
            data-test-id="HomePageHelpCenterButton"
            type="primary"
            @click="goto('https://voodoo.zendesk.com/hc/en-us/requests/new')">
            {{ $t("views.StudioHome.helpCenterButton") }}
          </el-button>
        </p>
      </div>
    </transition>
    <div
      class="footer"
      data-test-id="HomePageFooter"
    >
      <span>Voodoo © {{ year }} <b>•</b> </span>
      <span
        data-test-id="HomePageTermsOfUse"
      >
        <a href="https://www.voodoo.io/terms-platform" target="_blanck">{{ $t("views.StudioHome.termsLink") }}</a></span>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable vue/no-v-html */
import moment from 'moment';
import { cloneDeep } from 'lodash';
import livestreamIcon from '@/assets/images/icon-live.svg';
import elearningsIcon from '@/assets/images/icon-elearning.svg';
import info from '@/assets/images/info-icon@3x.png';
import bg from '@/assets/images/bg@3x.png';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import iconApple from '@/assets/images/i-os.svg';
import iconAndroid from '@/assets/images/android.svg';
import LaunchProcess from '@/views/StudioHome/components/LaunchProcess.vue';


export default {
  name: 'StudioHome',
  components: {
    LaunchProcess,
  },
  props: {
    page: {
      type: String,
      default: '',
    },
    eLearningAcademyInfos: {
      type: Object,
      default() {
        return {
          link: 'https://voodoo.zendesk.com/hc/en-us/categories/360001432760-E-LEARNING',
          label: this.$t('views.StudioBoard.eLearning'),
        };
      },
    },
  },
  data() {
    return {
      gameIcon,
      iconApple,
      iconAndroid,
      livestreamIcon,
      elearningsIcon,
      slideShow: false,
      info,
      bg,
      openNewGameModal: false,
      kpi: 'ios',
      cpiIos: process.env.VUE_APP_CPI_IOS,
      cpiAndroid: process.env.VUE_APP_CPI_ANDROID,
      // bgRocket,
    };
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    year() {
      return moment().format('YYYY');
    },
    loading() {
      return cloneDeep(this.$store.getters['users/loading']) || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    currentCompetition() {
      return this.$store.getters['users/codification'] ? this.$store.getters['users/codification'].currentCompetition : undefined;
    },
  },
  watch: {},
  async mounted() {
    this.slideShow = true;
  },
  methods: {
    changeKpi(value) {
      this.kpi = value;
    },
    goto(url) {
      window.open(url);
    },
    userHasPermission(value) {
      return this.myPermissions.indexOf(value) > -1;
    },
  },
};
</script>

<style lang="scss">
.dashboard-home {
  // margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  // justify-content: space-between;
  line-height: 25px;
  width: 100%;
  padding: 0 10px;
  @media screen and (max-width: 700px) {
    margin-top: 0px;
  }
  .video-compet {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 700px) {
      margin-top: 30px;
    }
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: 300px;
    @media screen and (max-width: 700px) {
      font-size: 9px;
    }
    span {
      position: relative;
    }
    a {
      color: #067bf0;
      text-decoration: none;
    }
    b {
      position: absolute;
      right: 0;
    }
  }
  .link-articles {
    color: #067bf0;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
  h1,
  span.title {
    font-size: 36px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #232323;
    margin: 20px 0;
    z-index: 3;
  font-weight: 800;
    @media screen and (max-width: 700px) {
      font-size: 20px;
    }
  }
  h2,
  span.subtitle {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 900;
  }
  .title-margin {
    margin: 20px 0;
    font-weight: 800;
    font-size: 24px;
    color: #232323;
  }
  .title-description {
    font-size: 14px;
    color: $slate;
    line-height: normal;
  }

  .logo-platform {
    padding: 14px 14px 6px 14px;
    border-radius: 10px;
    margin-bottom: 10px;
    &.ios {
      background-color: $appe-color-home-bg;
      svg {
        path {
          fill: $appe-color-home;
        }
      }
    }
    &.android {
      background-color: $android-color-home-bg;
      svg {
        path {
          fill: $android-color-home;
        }
      }
    }
  }
  .text {
    // padding: 0 10px;
    &.header {
      padding: 30px 0 10px 0;
      // margin-bottom: 6px;
      line-height: 38px;
      z-index: 2;
    }
    &.content {
      display: flex;
      justify-content: flex-start;
      z-index: 2;
    }
  }
  .card-kpi-infos-mobile {
    display: none;
    line-height: 1.2em;

    @media screen and (max-width: 700px) {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
    .item-kpi {
      display: flex;
      align-items: center;
    }
    .button-platform {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
      width: 100%;
      border-bottom: 1px solid #e9e9f0;
      position: relative;
      .card-kpi-info-content {
        display: flex;
        justify-content: center;
        b {
          margin-left: 10px;
        }
        svg {
          width: 30px;
          height: 30px;
        }
        &::before {
          content: "";
          width: 0%;
          height: 3px;
          position: absolute;
          bottom: 0px;
          background-color: #067bf0;
          -webkit-transition: all 0.35s ease;
          transition: all 0.35s ease;
          margin-right: 50%;
        }
        &.active,
        &:hover {
          color: #067bf0;
          &::before {
            content: "";
            width: 50%;
            margin-right: 0%;
          }
          svg {
            path {
              fill: $intense-blue;
            }
          }
          #iosIcon {
            fill: #067bf0;
          }
        }
        &:last-child {
          border-left: 1px solid #e9e9f0;
        }
      }
    }
    .listKPi-mobile {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 5px;
      padding: 0 20px 40px 20px;
      .kpi {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .value {
          font-size: 25px;
          color: #067bf0;
          justify-content: center;
          margin-bottom: 15px;
        }
        .label {
          color: #82bdf7;
          justify-content: center;
          margin-top: 0;
          text-align: center;
        }
      }
    }
  }
  .container-background-element {
    position: relative;
    width: 100%;
    .rocket {
      background-image: url("../assets/images/rocket.svg");
      background-size: 171px 231px;
      width: 171px;
      height: 231px;
      position: absolute;
      top: -90px;
      right: 360px;
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .control {
      background-image: url("../assets/images/combined-shape.svg");
      background-size: 130px 120px;
      width: 130px;
      height: 120px;
      position: absolute;
      right: 90px;
      top: 50px;
      z-index: 2;
      @media screen and (max-width: 850px) {
        display: none;
      }
    }
    .trophy {
      background-image: url("../assets/images/trophy.svg");
      background-size: 130px 186px;
      width: 130px;
      height: 186px;
      position: absolute;
      right: -40px;
      top: -150px;
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
  }

  .card-kpi-infos {
    // display: grid;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  .card-kpi-infos,
  .card-kpi-infos-mobile {
    margin: 20px 0;
    // position: sticky;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    position: relative;
    overflow: hidden;

    .bg-info {
      width: 300px;
      height: 300px;
      background-image: url("~@/assets/images/info-icon@3x.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -20px;
      top: -20px;
    }
    .bg-wave {
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/bg@3x.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      background-position-y: 100%;
    }
    .bg-rocket {
      width: 450px;
      height: 450px;
      position: absolute;
      right: 0;
      bottom: -20px;
      zoom: 80%;
    }
    .card-kpi-info-content {
      padding: 20px;
      z-index: 1;

      .kpi-box {
        display: flex;
        padding: 40px 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        @media screen and (max-width: 700px) {
          // grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        // grid-gap: 30px;
        .item-kpi {
          padding: 0 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          @media screen and (max-width: 700px) {
            // &:last-child {
            padding: 10px;
            // }
          }

          &:last-child {
            border-left: 1px solid #e6f1fd;
          }

          > img {
            display: block;
            width: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .listKPi {
      width: 100%;
      display: grid;
      // grid-template-columns: minmax(100px, 1fr)  minmax(100px, 1fr) minmax(100px, 1fr);
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;

        //  grid-template-columns: repeat(auto-fill, auto);
      }
      // grid-gap: 30px;
      // &:nth-child(3) {
      // grid-column: 100%;
      // }
      .kpi {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        text-align: center;
        &.ios {
          .value,
          .label {
            color: $appe-color-home;
          }
        }
        &.android {
          .value,
          .label {
            color: $android-color-home;
          }
        }
        .value {
          font-size: 25px;
          justify-content: center;
          margin-bottom: 15px;
        }
        .label {
          justify-content: center;
          margin-top: 0;
          line-height: 17px;
          font-weight: bold;
        }
      }
    }
  }
  .image-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    z-index: 2;
  }
  .image-link {
    @media screen and (max-width: 700px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    .learning,
    .live {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      width: 49%;
      height: 300px;
      padding: 50px;
      border-radius: 10px;
      transition: background-size 0.4s ease-in;
      background-size: 200%;
      .title-description {
        color: #fff;
      }
        h1 {
        line-height: 100% !important;
      }
      @media screen and (max-width: 700px) {
        // &:last-child {
        background-size: 380%;
        width: 100%;
        padding: 20px !important;
        height: 250px !important;
        h1 {
          font-size: 1.2em !important;
        }
        p {
          font-size: 0.8em !important;
          line-height: 1.5em;
        }
        // }
        .el-image {
          width: 33% !important;
          height: 100px !important;
          margin-bottom: 10px !important;
        }
      }
      .el-image {
        width: 15%;
        border-radius: 5px;
        min-width: 30px;
      }
      h1 {
        font-size: 2.5em;
        color: #fff;
      }
      // @media (max-width: 1100px) {
      //   height: 200px;
      //   background-size: 150%;
      //   .el-image {
      //     margin: 10px;
      //   }
      // }
    }
    .learning {
      .el-image {
        margin-bottom: 30px;
      }
    }
    .live {
      .el-image {
        margin: 9px 0 29px 0;
      }
    }
  }

  .learning {
    background-image: url("~@/assets/images/elearnings-pic@3x.png");
    // background-position: right bottom;
    background-repeat: no-repeat;
    background-position: center;
    // background-size: contain;
    transition: background-size 0.4s ease-in;

    &:hover {
      cursor: pointer;
      background-size: 155%;
      transition: background-size 0.4s ease-in;
      @media (max-width: 1100px) {
        background-size: 175%;
      }
    }
  }
  .live {
    background-image: url("~@/assets/images/livestream-pic@3x.png");
    background-position: center;
    background-repeat: no-repeat;
    // background-size: contain;

    &:hover {
      cursor: pointer;
      background-size: cover;
      transition: background-size 0.4s ease-in;
      background-size: 155%;
      @media (max-width: 1100px) {
        background-size: 175%;
      }
    }
  }
  .help-center-questionmark {
    background-image: url("~@/assets/images/help-center-questionmark.svg");
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
    content: "";
    background-size: 250px 250px;
    position: absolute;
    right: -5px;
    top: -10px;
    z-index: 2;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .help-center {
    background-color: #e6f1fd;
    margin: 50px 0;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
      padding: 20px;
    }

    p {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      z-index: 2;
      font-size: 14px;
      color: $slate;
      line-height: normal;

      .title-help {
        font-weight: 800;
        font-size: 24px;
        color: #232323;
      }
    }
    .el-button {
      margin-top: 30px;
      height: 35px;
      z-index: 2;
      padding-top: 10px;
    }
    p {
      // margin-left: 200px;
      // margin-right: 100px;

      z-index: 2;
      @media (max-width: 900px) {
        margin: 20px;
      }
    }
  }
  .launch-process-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

}
@keyframes help {
  from {
    // background-size: cover;
    transform: rotate(-10deg);
    background-position-x: 0px;
  }
  to {
    // background-size: cover;
    transform: rotate(10deg);
    transform: 40px;
  }
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
/* .slide-fade-leave-active below version 2.1.8 */
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<template>
  <div class="gfs-form-content-video">
    <div class="gfs-form-group gfs-columns gfs-padding-general">
      <div
        v-if="videoPlatformConfigs.length !== 0"
        class="gfs-form-group gfs-columns"
      >
        <div class="gfs-container-platforms-items">
          <div
            v-for="platform of videoPlatformConfigs"
            :key="platform.id"
            class="
              gfs-columns
              gfs-platform-container
              gfs-grow-item
              gfs-standard-margin
            "
          >
            <div class="gfs-form-input-title">
              <img width="22" :src="getAdNetworkIcon(platform.id)" />
              <div>
                <span class="title">
                  {{ platform.namePlatform }}
                  <span
                    class="ratio"
                  >({{
                    platform.videoRatios.join(" or ").replace("/", ":")
                  }}
                    ratio)</span
                  >
                </span>
                <span
                  class="subtitle"
                >You can upload up to {{ platform.nbVideoMax }} videos. The
                  daily budget increases with each added video.</span
                >
              </div>
            </div>

            <div v-if="!isBrowserCompatible" :class="$style.warning">
              We don’t support video upload on Safari browsers. Use either Google Chrome or Mozilla Firefox to properly upload your advertising videos.
            </div>

            <div v-if="isBrowserCompatible">
              <div class="videos-list">
                <div
                  v-for="(item, index) in getVideosByPlatform[platform.id]"
                  :key="item.id"
                  class="video-preview"
                >
                  <div class="video-container">
                    <el-popover
                      placement="left"
                      width="400"
                      trigger="hover"
                      data-test-id="gameSubmissionVideoPreview"
                      @after-enter="
                        videoEnter(
                          `video-${game.id + '' + platform.id + '' + index}`
                        )
                      "
                      @after-leave="
                        videoLeave(
                          `video-${game.id + '' + platform.id + '' + index}`
                        )
                      "
                    >
                      <video
                        :id="`video-${game.id + '' + platform.id + '' + index}`"
                        width="100%"
                        controls
                        muted="true"
                        loop
                      >
                        <source :src="item.video" type="video/mp4" />
                      </video>

                      <video
                        :id="`videoIte-${item.id}`"
                        slot="reference"
                        :key="item.id"
                        class="video-icon-hover"
                        width="100%"
                        muted="true"
                        loop
                        playsinline
                      >
                        <source :src="item.video" />
                      </video>
                    </el-popover>
                  </div>
                  <div class="video-details">
                    <span data-test-id="gameSubmissionVideoName">{{ transformVideoName(item.fileName) }}</span
                    ><br />
                    <span
                      class="uploaded"
                    ><i class="el-icon-check"></i>Uploaded</span
                    >
                  </div>
                  <div class="button-video" data-test-id="gameSubmissionRemoveVideo">
                    <el-button
                      v-if="game.status !== 'pending_testing'"
                      :disabled="disabled"
                      type="danger"
                      plain
                      @click="
                        deleteVideo({ itemId: item.id, platform: platform.id })
                      "
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </div>

                <div
                  v-for="(video, index) of (
                    videoForPlatform[platform.id] || { videos: [] }
                  ).videos"
                  :key="platform.id + index"
                  class="video-preview"
                >
                  <div class="video-container">
                    <el-popover
                      placement="left"
                      width="400"
                      trigger="hover"
                      data-test-id="gameSubmissionVideoPreview"
                      @after-enter="
                        videoEnter(
                          `video-prev--${game.id + '' + platform.id + '' + index}`
                        )
                      "
                      @after-leave="
                        videoLeave(
                          `video-prev--${game.id + '' + platform.id + '' + index}`
                        )
                      "
                    >
                      <video
                        :id="`video-prev--${
                          game.id + '' + platform.id + '' + index
                        }`"
                        width="100%"
                        controls
                        muted="true"
                        loop
                        data-test-id="gameSubmissionVideoPreview"
                      >
                        <source :src="video.url" type="video/mp4" />
                      </video>
                      <video
                        v-if="video.url"
                        :id="`video-prev--${video.id}`"
                        slot="reference"
                        :key="platform.id + index"
                        class="video-icon-hover"
                        :src="video.url"
                        :style="
                          videoOffsetCropping(
                            videoForPlatform[platform.id].videos[index]
                              .video_cropping_positions
                          )
                        "
                        muted="true"
                        loop
                        playsinline
                      ></video>
                    </el-popover>
                    <div
                      v-if="
                        !videoForPlatform[platform.id].videos[index]
                          .video_cropping_positions
                      "
                      class="invalid-video-overlay"
                    ></div>
                  </div>
                  <div class="video-details">
                    <div>
                      <span v-if="video.file" data-test-id="gameSubmissionVideoName">{{ transformVideoName(video.file.name) }}</span>
                      <br />
                      <span
                        v-if="video.uploaded"
                        class="uploaded"
                      ><i class="el-icon-check"></i>Uploaded</span
                      >
                      <span
                        v-if="video.error"
                        class="error"
                      ><i class="el-icon-error"></i>We are unable to crop your
                        video file. Please crop it on your end and upload the file
                        again</span
                      >
                      <span
                        v-if="video.errorMessage"
                        class="error"
                      ><i class="el-icon-error"></i
                      ><span v-html="video.errorMessage"></span
                      ></span>
                      <span
                        v-if="video.loading"
                        class="loader"
                      ><i class="el-icon-loading"></i> Uploading</span
                      >
                    </div>

                    <div
                      v-if="
                        !videoForPlatform[platform.id].videos[index]
                          .video_cropping_positions && !video.errorMessage
                      "
                      class="gfs-ratio-alert"
                    >
                      Wrong ratio. You need to crop the video.
                    </div>
                  </div>
                  <div class="button-video">
                    <CropppingDialog
                      v-if="
                        !video.errorMessage &&
                          !video.loading &&
                          game.status !== 'pending_testing' &&
                          !videoForPlatform[platform.id].videos[index]
                            .video_cropping_positions
                      "
                      :video-to-crop="getVideoToCrop(platform.id, index)"
                      @setCrop="
                        (value) => {
                          validateCropping(platform.id, index, value);
                        }
                      "
                    ></CropppingDialog>
                    <el-button
                      v-if="
                        !video.uploaded &&
                          !video.loading &&
                          game.status !== 'pending_testing'
                      "
                      :disabled="disabled"
                      type="danger"
                      plain
                      data-test-id="gameSubmissionRemoveVideo"
                      @click="removeVideo(platform.id, index)"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>

                    <el-button
                      v-if="game.status !== 'pending_testing' && video.uploaded"
                      :loading="video.loading"
                      :disabled="disabled"
                      type="danger"
                      plain
                      data-test-id="gameSubmissionRemoveVideo"
                      @click="
                        deleteVideo({ itemId: video.id, platform: platform.id })
                      "
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </div>
              </div>
              <div
                v-if="canUploadVideo(platform.id) && !disabled"
                class="upload-button-row"
              >
                <div class="upload-video-button">
                  <div
                    class="gfs-form-input choose-file"
                    @click="openInput('addVideo' + platform.id + uniq)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#067bf0" fill-rule="evenodd"><path d="M4.767 14.91h2.772v-1.92H4.767a2.861 2.861 0 01-.001-5.722h.574a.96.96 0 00.96-.871c.23-2.499 2.436-4.476 5.016-4.476a5 5 0 015.04 4.639.96.96 0 00.658.854 2.858 2.858 0 01-.89 5.578h-2.777v1.92h2.772A4.779 4.779 0 0018.2 5.818 6.96 6.96 0 0011.316.001 6.865 6.865 0 004.52 5.355a4.782 4.782 0 00.24 9.555h.007z"/><path d="M11.403 14.517v-3.841l.822.78a.96.96 0 001.319-1.393l-2.441-2.308a.96.96 0 00-1.318 0l-2.442 2.308a.959.959 0 101.317 1.392l.823-.78v3.842c0 .53.429.959.958.959h.003a.96.96 0 00.959-.959"/></g></svg>
                    Upload file
                  </div>
                  <input
                    :id="'addVideo' + platform.id + uniq"
                    :ref="'addVideo' + platform.id + uniq"
                    hidden
                    type="file"
                    accept="video/*"
                    class="gfs-form-control"
                    multiple
                    data-test-id="gameSubmissionVideoInputFile"
                    @input="
                      addMultipleVideos(
                        $event,
                        platform.id,
                        platform.videoRatios,
                        'addVideo' + platform.id + uniq
                      )
                    "
                  />
                </div>
                <span class="subtitle">100 MB max / video</span>
              </div>

              <div
                v-if="
                  totalNbVideosPerPlatform[platform.id] > 0 &&
                    dailyBudgetPerAdNetwork
                "
                class="budget-configuration-container"
              >
                <BudgetConfiguration
                  v-model="dailyBudgetPerAdNetwork[platform.id].budget"
                  :is-manually-set="
                    dailyBudgetPerAdNetwork[platform.id].isManuallySet
                  "
                  :game="gameObject"
                  :nb-videos="totalNbVideosPerPlatform[platform.id]"
                  :ad-network="platform"
                  :loading="loadingNextStep"
                  @manually-set="updateBudget"
                />
              </div>

              <div
                v-if="
                  totalNbVideosPerPlatform[platform.id] > 0 &&
                    platform.requiresAppName
                "
                class="extra-fields"
              >
                <div class="field">
                  <div class="label">
                    <span class="name">
                      App name
                      <InfoTooltip>
                        Displayed by Snapchat as part of your App Install ad.
                      </InfoTooltip>
                    </span>
                    <span class="counter">
                      {{ 25 - storeNameInternal.length }}
                    </span>
                  </div>
                  <div class="input">
                    <el-input
                      v-model="storeNameInternal"
                      :disabled="disabled"
                      placeholder="e.g. Helix Jump"
                      type="text"
                      maxlength="25"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isBrowserCompatible">
            <div
              v-if="
                gamePermissions.advanced_campaign_settings_level1 ||
                  gamePermissions.advanced_campaign_settings_level2
              "
              class="campaign-duration-container"
            >
              <div :class="$style.title">Campaign duration</div>
              <p :class="$style.subtext">
                The campaign duration is
                {{ gameObject.defaultCampaignDuration }} days by default.
              </p>
              <div
                class="
                gfs-columns
                gfs-platform-container
                gfs-grow-item
                gfs-standard-margin
                mt-20
              "
              >
                <CustomSlider
                  v-model="currentCampaignDuration"
                  :min="0"
                  :min-lock="gameObject.minCampaignDuration"
                  :max="gameObject.maxCampaignDuration"
                  :disabled="loadingNextStep || disabled"
                  @change="updateCampaignDuration(currentCampaignDuration)"
                >
                  <template
                    v-slot:tooltip
                  >{{ currentCampaignDuration }} days</template
                  >
                  <template
                    v-slot:maxValue
                  >{{ gameObject.maxCampaignDuration }} days</template
                  >
                </CustomSlider>
              </div>
            </div>
          </div>
        </div>

        <template v-if="errors.videos">
          <div
            v-for="error in errors.videos"
            :key="error"
            class="error-container"
            v-html="error"
          ></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
import { Notification } from 'element-ui';
import BudgetConfiguration from '@/Layouts/BudgetConfiguration/BudgetConfiguration.vue';
import CustomSlider from '@/components/Generic/CustomSlider.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import CropppingDialog from '@/components/Dialogs/CroppingDialog.vue';
import tools from '@/tools/tools';

import { detect } from 'detect-browser';

export default {
  name: 'UploadVideosComponent',
  components: {
    BudgetConfiguration,
    InfoTooltip,
    CustomSlider,
    CropppingDialog,
  },
  props: {
    gameObject: {
      type: Object,
      default: null,
    },
    adnetworksAvailable: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    storeName: {
      type: String,
      default: '',
    },
  },
  data() {
    const videoForPlatform = {};
    const nbNewVideosPerPlatform = {};

    for (const platform of this.adnetworksAvailable) {
      videoForPlatform[platform.name] = {
        videos: [],
      };
      nbNewVideosPerPlatform[platform.name] = 0;
    }

    return {
      game: {
        create_ga_slot: this.showGASlot,
        is_ctr: false,
        platform: 'android',
      },

      videoToCrop: undefined,
      activePlatformIndex: undefined,
      isSubmitting: false,
      errors: {
        icon: [],
        videos: [],
        name: [],
      },
      iconImg: false,
      videoElements: [],
      uniq: '',
      videoForPlatform,
      nbNewVideosPerPlatform,
      budgetLoading: false,
      isBudgetOptimal: false,
      dailyBudgetPerAdNetwork: { ...this.gameObject.dailyBudgetPerAdNetwork },
      currentCampaignDuration:
        this.gameObject.currentCampaignDuration
        || this.gameObject.defaultCampaignDuration,
      isBrowserCompatible: true,
    };
  },
  computed: {
    nbVideoIsLoading() {
      let nbVideoDownloaded = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.videoForPlatform) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.videoForPlatform.hasOwnProperty(key)) {
          const element = this.videoForPlatform[key].videos;
          for (let j = 0; j < element.length; j += 1) {
            if (element[j].loading) {
              nbVideoDownloaded += 1;
            }
          }
        }
      }
      return nbVideoDownloaded;
    },
    getVideosByPlatform() {
      const videos = {
        facebook: [],
        snapchat: [],
      };
      if (this.gameObject && this.gameObject.iterations) {
        const lastIterations = this.gameObject.iterations[this.gameObject.iterations.length - 1];
        if (lastIterations.adNetworks.facebook) {
          const videoFacebook = lastIterations.adNetworks.facebook.videos;
          videos.facebook = videoFacebook;
        }
        if (lastIterations.adNetworks.snapchat) {
          const videoSnapchat = lastIterations.adNetworks.snapchat.videos;
          videos.snapchat = videoSnapchat;
        }
      }
      return videos;
    },
    videoPlatformConfigs() {
      const ar = [];
      for (let i = 0; i < this.adnetworksAvailable.length; i += 1) {
        const element = this.adnetworksAvailable[i];
        if (element.name === 'facebook') {
          ar.push({
            namePlatform: 'Facebook',
            videoRatios: ['4/5'],
            nbVideoMax: this.gameObject.maxAllowedVideos,
            infos: [],
            base: true,
            id: 'facebook',
          });
        }
        if (element.name === 'snapchat') {
          ar.push({
            namePlatform: 'Snapchat',
            videoRatios: ['9/16'],
            nbVideoMax: this.gameObject.maxAllowedVideos,
            infos: [],
            base: false,
            id: 'snapchat',
            requiresAppName: true,
          });
        }
      }
      return ar;
    },
    totalNbVideosPerPlatform() {
      const totalPerPlatform = {};

      for (const [platform, videos] of Object.entries(
        this.getVideosByPlatform,
      )) {
        const validVideos = videos.filter(
          video => !video.error || !video.errorMessage,
        );
        totalPerPlatform[platform] = validVideos.length + this.nbNewVideosPerPlatform[platform];
      }
      return totalPerPlatform;
    },
    loadingNextStep() {
      return this.$store.getters['submitGame/nextStepLoading'];
    },
    storeNameInternal: {
      get() {
        return this.storeName || '';
      },
      set(value) {
        this.$emit('set-store-name', value);
      },
    },
    gamePermissions() {
      if (this.gameObject) {
        return this.gameObject.permissions;
      }
      return {};
    },
  },
  watch: {
    videoForPlatform: {
      handler() {
        for (const platform of this.adnetworksAvailable) {
          this.nbNewVideosPerPlatform[platform.name] = this.videoForPlatform[platform.name].videos.length;
        }
      },
      deep: true,
    },
    gameObject: {
      handler() {
        this.setVideoState();
        this.dailyBudgetPerAdNetwork = this.gameObject.dailyBudgetPerAdNetwork;
        this.currentCampaignDuration = this.gameObject.currentCampaignDuration
          || this.gameObject.defaultCampaignDuration;
      },
      deep: true,
    },
  },
  mounted() {
    this.uniq = Math.random();

    if (!this.gameObject.currentCampaignDuration) {
      this.currentCampaignDuration = this.gameObject.defaultCampaignDuration;
    }
    this.setVideoState();
    this.checkBrowserCompatibility();
  },
  methods: {
    checkBrowserCompatibility() {
      const browser = detect();
      if (browser.name === 'safari') {
        this.isBrowserCompatible = false;
      }
    },
    videoEnter(value) {
      const video = document.getElementById(value);
      video.play();
    },
    videoLeave(value) {
      const video = document.getElementById(value);
      video.pause();
    },
    transformVideoName(name) {
      let named = name;
      if (name) {
        const regex = /\[(.*?)\]/gm;
        const result = name.match(regex);
        if (result) {
          named = name.replace(result[result.length - 1], '');
        }
        return named;
      }
      return 'no name';
    },
    updateVideosListFromBackendByName() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.videoForPlatform) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.videoForPlatform.hasOwnProperty(key)) {
          for (
            let i = this.videoForPlatform[key].videos.length - 1;
            i >= 0;
            i -= 1
          ) {
            const videotocompare = this.videoForPlatform[key].videos[i];
            const element = this.getVideosByPlatform;
            for (let j = 0; j < element[key].length; j += 1) {
              const el = element[key][j];
              if (videotocompare.file.name === el.fileName) {
                this.videoForPlatform[key].videos.splice(i, 1);
              }
            }
          }
        }
      }
    },
    setVideoState() {
      const state = {
        state: false,
        videos: [],
      };

      const iterat = this.gameObject.iterations[this.gameObject.iterations.length - 1];
      const isvideoInEachPlatform = {};
      this.updateVideosListFromBackendByName();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in iterat.adNetworks) {
        isvideoInEachPlatform[key] = false;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key in iterat.adNetworks) {
        // eslint-disable-next-line no-prototype-builtins
        if (iterat.adNetworks.hasOwnProperty(key)) {
          const el = iterat.adNetworks[key].videos;
          if (el.length > 0) {
            isvideoInEachPlatform[key] = true;
          }
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const k in this.videoForPlatform) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.videoForPlatform.hasOwnProperty(k)) {
          const e = this.videoForPlatform[k].videos;
          if (e.length > 0) {
            isvideoInEachPlatform[k] = true;
          }
        }
      }
      let minimumVideosToRequestTesting = true;
      if (this.gameObject.isIterating) {
        minimumVideosToRequestTesting = isvideoInEachPlatform.snapchat || isvideoInEachPlatform.facebook;
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in isvideoInEachPlatform) {
          // eslint-disable-next-line no-prototype-builtins
          if (isvideoInEachPlatform.hasOwnProperty(key)) {
            const element = isvideoInEachPlatform[key];
            if (!element) {
              minimumVideosToRequestTesting = false;
            }
          }
        }
      }

      if (this.nbVideoIsLoading > 0) {
        state.state = 'loading';
        state.videos = this.nbVideoIsLoading;
      } else if (minimumVideosToRequestTesting) {
        state.state = true;
        state.videos = null;
      } else {
        state.state = false;
        state.videos = null;
      }
      this.$emit('videos-state', state);
    },
    canUploadVideo(platform) {
      let max = 0;
      let nbVideo = 0;
      let nbVideoGoingToUpload = 0;
      for (let i = 0; i < this.videoPlatformConfigs.length; i += 1) {
        const element = this.videoPlatformConfigs[i];
        if (element.id === platform) {
          max = element.nbVideoMax;
        }
      }
      if (
        this.gameObject.iterations[this.gameObject.iterations.length - 1]
          .adNetworks[platform]
      ) {
        nbVideo = this.gameObject.iterations[this.gameObject.iterations.length - 1]
          .adNetworks[platform].videos.length;
      }
      nbVideoGoingToUpload = this.videoForPlatform[platform].videos.length;
      return max > nbVideo + nbVideoGoingToUpload;
    },

    mute(value) {
      const video = document.getElementById(value);
      video.muted = true;
    },
    unMute(value) {
      const video = document.getElementById(value);
      video.muted = false;
    },
    play(value) {
      const video = document.getElementById(value);
      video.play();
      this.unMute(value);
    },
    pause(value) {
      const video = document.getElementById(value);
      video.pause();
      this.mute(value);
    },

    videoOffsetCropping(croppingInfos) {
      if (croppingInfos && croppingInfos.calculatedRatio) {
        if (croppingInfos.isHorizontalCrop) {
          return `height:100%;position:relative;left:${
            75
            * croppingInfos.originalRatio
            * -(croppingInfos.croppingPositionPercent / 100)
          }px`;
        }
        return `width:100%;position:relative;top:${
          ((75 * croppingInfos.calculatedRatio) / croppingInfos.originalRatio)
          * -(croppingInfos.croppingPositionPercent / 100)
        }px`;
      }
      return 'height:100%';
    },
    async deleteVideo(obj) {
      this.$store.dispatch('submitGame/loading_video_in_platform', obj);
      await this.$store.dispatch('gameAction/deleteVideoGameId', {
        gameId: this.gameObject.id,
        id: obj.itemId,
      });
      // eslint-disable-next-line no-restricted-syntax
      for (const k in this.videoForPlatform) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.videoForPlatform.hasOwnProperty(k)) {
          const e = this.videoForPlatform[k].videos;
          for (let i = 0; i < e.length; i += 1) {
            const element = e[i];
            if (obj.itemId === element.id) {
              e.splice(i, 1);
            }
          }
        }
      }
    },
    async submitVideoByPlatform(platform) {
      const videoList = this.videoForPlatform[platform];
      const videooos = [];

      if (videoList) {
        if (!this.game.otherPlatforms) {
          this.game.otherPlatforms = {};
        }
        if (!this.game.otherPlatforms[platform]) {
          this.game.otherPlatforms[platform] = {};
        }

        for (let i = 0; i < videoList.videos.length; i += 1) {
          const element = videoList.videos[i];
          if (
            videoList.videos[i].video_cropping_positions
            && !videoList.videos[i].video_cropping_positions.isLoading
          ) {
            videoList.videos[i].video_cropping_positions.isLoading = true;
            videooos.push({
              file: element.file,
              croppingInfos: videoList.videos[i].video_cropping_positions,
              adNetwork: platform,
              id: `${platform}${element.file.name}${element.file.size}${element.file.lastModified}`,
              loading: false,
            });
          }
        }
        this.game.otherPlatforms[platform].videos = videooos;
      }
      this.errors.videos = [];
      for (let j = videooos.length - 1; j >= 0; j -= 1) {
        const element = videooos[j];
        for (
          let k = 0;
          k < this.videoForPlatform[platform].videos.length;
          k += 1
        ) {
          const el = this.videoForPlatform[platform].videos[k];
          if (el.id === element.id) {
            el.loading = true;
          }
        }
        this.setVideoState();
        // eslint-disable-next-line no-await-in-loop
        const response = await this.uploadAndCropVideo(element);
        this.$store.dispatch('submitGame/refreshGame', this.gameObject.id);

        videooos.splice(j, 1);

        for (
          let l = this.videoForPlatform[platform].videos.length - 1;
          l >= 0;
          l -= 1
        ) {
          const el = this.videoForPlatform[platform].videos[l];
          if (el.id === element.id) {
            el.loading = false;
            if (
              response
              && Object.prototype.hasOwnProperty.call(response, 'error')
            ) {
              el.uploaded = false;
              el.error = true;
            } else {
              el.uploaded = true;
            }
          }
        }
      }

      this.$emit('game-submitted', {
        ...this.game,
        platform,
      });
      this.$forceUpdate();
      this.setVideoState();
      return true;
    },

    uploadAndCropVideo(video) {
      return new Promise(async (resolve) => {
        const form = new FormData();
        form.append('attached', video.file);
        form.append('adNetwork', video.adNetwork);
        if (video.croppingInfos.croppingPositionPercent !== null) {
          form.append(
            'croppingPositionPercent',
            video.croppingInfos.croppingPositionPercent,
          );
          form.append('isHorizontalCrop', video.croppingInfos.isHorizontalCrop);
        }
        // eslint-disable-next-line no-param-reassign
        const payload = {
          form,
          gameId: this.gameObject.id,
        };
        const rep = await this.$store.dispatch(
          'submitGame/uploadVideoFile',
          payload,
        );
        resolve(rep);
      });
    },

    addMultipleVideos(e, platformId, ratios, ref) {
      for (let i = 0; i < e.target.files.length; i += 1) {
        const element = e.target.files[i];
        this.addVideo(element, platformId, ratios);
      }
      document.getElementById(ref).value = '';
    },

    async addVideo(filed, platformId, ratios) {
      if (!filed) {
        return;
      }

      function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
      }

      const file = renameFile(
        filed,
        `${filed.name} [${(Math.random() * 100000).toFixed()}]`,
      );
      if (!this.videoForPlatform[platformId]) {
        this.videoForPlatform[platformId] = {
          videos: [],
        };
      }
      const url = window.URL.createObjectURL(file);

      const videoToCheck = document.createElement('video');
      videoToCheck.src = url;
      videoToCheck.muted = true;

      const load = videoToCheck.play();
      let errorvideo = false;

      const isValid = await tools.videoFormatIsValid(url);
      if (!isValid) {
        this.addVideoToList({
          file,
          platformId,
          errorMessage:
            'Your file is using an unsupported codec. Re-encode it in H.264.',
        });
        return;
      }
      load
        .catch((error) => {
          errorvideo = true;
          if (error.name === 'NotSupportedError') {
            this.addVideoToList({
              file,
              platformId,
              errorMessage:
                'Your file is using an unsupported codec. Re-encode it in H.264.',
            });
          } else {
            this.addVideoToList({
              file,
              platformId,
              errorMessage:
                'Your file is using an unsupported codec. Re-encode it in H.264.',
            });
          }
        })
        .then(() => {
          videoToCheck.pause();
          if (errorvideo) {
            return;
          }
          if (file.size > 100000000) {
            this.addVideoToList({
              file,
              platformId,
              errorMessage: 'Your video should be under 100MB',
            });
            return;
          }

          if (videoToCheck.duration > 35 || videoToCheck.duration < 10) {
            this.addVideoToList({
              file,
              platformId,
              errorMessage: `Video error: duration got ${videoToCheck.duration.toFixed(
                0,
              )}s but should be between 10s and 35s`,
            });
            return;
          }

          if (!this.canUploadVideo(platformId)) {
            return;
          }
          this.addVideoToList({
            file,
            platformId,
            url,
          });
          const height = videoToCheck.videoHeight;
          const width = videoToCheck.videoWidth;

          let correctRatio = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const ratio of ratios) {
            const calculatedRatio = ratio.split('/')[0] / ratio.split('/')[1];
            if (width / height === calculatedRatio) {
              correctRatio = ratio;
            }
          }
          if (correctRatio) {
            this.videoForPlatform[platformId].videos[
              this.videoForPlatform[platformId].videos.length - 1
            ].video_cropping_positions = {
              croppingPositionPercent: null,
              ratio: correctRatio,
              isHorizontalCrop:
                width / height
                >= correctRatio.split('/')[0] / correctRatio.split('/')[1],
              calculatedRatio:
                correctRatio.split('/')[0] / correctRatio.split('/')[1],
            };
            this.submitVideoByPlatform(platformId);
            videoToCheck.removeAttribute('src'); // empty source
            videoToCheck.load();
          }
          this.$forceUpdate();
        });

      this.$forceUpdate();
    },

    addVideoToList({
      file,
      platformId,
      url,
      loading = false,
      uploaded = false,
      error = false,
      errorMessage = '',
    }) {
      this.videoForPlatform[platformId].videos.push({
        id: `${platformId}${file.name}${file.size}${file.lastModified}`,
        file,
        url,
        uploaded,
        loading,
        error,
        errorMessage,
      });
    },

    removeVideo(platformId, index) {
      this.videoForPlatform[platformId].videos.splice(index, 1);
      this.$forceUpdate();
      this.submitVideoByPlatform(platformId);
    },

    openInput(ref) {
      if (Array.isArray(this.$refs[ref])) {
        this.$refs[ref][0].click();
      } else {
        this.$refs[ref].click();
      }
    },

    getVideoToCrop(platformId, videoIndex) {
      const video = this.videoForPlatform[platformId].videos[videoIndex];
      const platformConfig = this.videoPlatformConfigs.find(
        plat => plat.id === platformId,
      );
      return {
        src: video.url,
        availableRatios: platformConfig.videoRatios,
        platformId,
        videoIndex,
      };
    },
    validateCropping(platform, index, cropValue) {
      this.videoForPlatform[platform].videos[index].video_cropping_positions = cropValue;
      this.submitVideoByPlatform(platform);
    },
    async updateBudget({ newBudget, adNetwork }) {
      this.gameObject.dailyBudgetPerAdNetwork[adNetwork].isManuallySet = true;
      this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
      try {
        await this.$store.dispatch('submitGame/updateGameBudget', {
          id: this.gameObject.id,
          dailyBudget: {
            newBudget,
            adNetwork,
          },
        });
      } catch (e) {
        const message = e.message || 'An error occurred, please refresh or contact support (studio-support@voodoo.io)';
        Notification.error({
          title: 'Error',
          message,
          position: 'bottom-left',
        });
      }
      this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
    },
    async updateCampaignDuration(campaignDuration) {
      this.$store.dispatch('submitGame/setNextStepLoadingProperty', true);
      try {
        await this.$store.dispatch('submitGame/updateGameCampaignDuration', {
          id: this.gameObject.id,
          campaignDuration,
        });
      } catch (e) {
        const message = e.message || 'An error occurred, please refresh or contact support (studio-support@voodoo.io)';
        Notification.error({
          title: 'Error',
          message,
          position: 'bottom-left',
        });
      }
      this.$store.dispatch('submitGame/setNextStepLoadingProperty', false);
    },
    getAdNetworkIcon(adNetwork) {
      // eslint-disable-next-line
      return require(`@/assets/images/icon-${adNetwork}.svg`);
    },
  },
};
</script>

<style lang="scss">
.gfs-form-content-video {
  min-width: 100px;
  font-size: 12px;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4f5c;
  .videos-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    .video-preview {
      display: flex;
      width: available;
      padding: 10px 20px 10px 0;
      border-bottom: 1px solid $cloudy-blue;
      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      &:first-of-type {
        padding-top: 30px;
      }

      .video-container {
        position: relative;
        height: 68px;
        max-width: 60px;
        overflow: hidden;

        @media screen and (max-width: 700px) {
          height: 28vw;
          max-width: 25vw;
        }
        .video-icon-hover {
          &:hover {
            cursor: pointer;
          }
        }

        .invalid-video-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      .video-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 30px;
        font-weight: bold;
        justify-content: center;
        .loader {
          color: $intense-blue;
        }
        .uploaded {
          color: $green-alert;
        }
        .error {
          color: $color-danger-plain-color;
        }

        @media screen and (max-width: 700px) {
          margin-left: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .button-video {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        @media screen and (max-width: 700px) {
          margin-left: 0px;
        }
        .el-button {
          width: 63px;
          height: 35px;
          padding: 8px;

          &:not(:last-child) {
            margin-right: 20px;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
  .budget-configuration-container {
    width: calc(100% + 60px);
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    border-top: 1px solid $cloudy-blue;
    display: flex;
    position: relative;

    .budget-configuration {
      padding-right: 30px;
    }

    > .speedometer {
      width: 50%;
      display: flex;
      justify-content: center;

      margin-top: -30px;
      margin-bottom: -30px;
      border-left: 1px solid $cloudy-blue;
      padding-top: 20px;
      padding-bottom: 20px;

      p {
        margin-top: 81px;
        width: 130px;

        &.underspend,
        &.overspend {
          color: $grey;

          .title {
            display: block;
            color: $slate;
            font-weight: bold;
          }
        }

        &.underspend {
          text-align: right;
        }

        &.optimal {
          text-align: center;
          font-weight: bold;
          color: $green-alert;
          margin-top: 66px;
        }
      }
    }
  }

  .campaign-duration-container {
    margin-top: 80px;

    h1 {
      color: $slate;
      font-size: 100%;
    }

    p {
      color: $grey;
      font-size: 100%;
    }

    .gfs-platform-container {
      margin-top: 10px;
      width: 50%;
      padding: 18px 30px 22px;
    }
  }

  .gfs-container-platforms-items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .error-container {
    color: red;
    background-color: $foggy-blue;
    padding: 10px;
    margin-top: 10px;
  }
  .gfs-rows {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
  .upload-button-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    margin-top: 30px;
    .subtitle {
      color: $grey;
      font-size: 12px;
      font-weight: normal;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .upload-video-button {
      display: flex;
      justify-content: flex-start;
      margin-right: 10px;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
      .gfs-form-input {
        height: 35px;
        margin: 0;
        padding: 15px 10px;

        svg {
          margin-right: 10px;
        }

        &:hover {
          background: $intense-blue;
          border-color: $intense-blue;
          color: white;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
  .submitButton {
    margin-top: 10px;
    width: 250px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    line-height: 20px;
  }
  .gfs-platform-container {
    border-radius: 3px;
    background-color: $foggy-blue;
    padding: 30px;
    align-items: flex-start;
    margin-top: 5px;
    width: 100%;
    max-width: 100%;

    .icon-upload {
      // margin: 10px;
      margin-bottom: -2px;
      margin-right: 5px;
    }

    @media screen and (max-width: 700px) {
      margin-right: 0;
      flex-direction: column !important;
      max-width: none;
    }

    .extra-fields {
      margin-top: 30px;
      border-top: 1px solid $cloudy-blue;
      margin-left: -30px;
      width: calc(100% + 60px);
      padding: 30px 30px 0 30px;

      .field {
        max-width: 700px;

        .label {
          flex-direction: row;

          .counter {
            margin-left: auto;
          }
        }

        .input {
          margin-top: 5px;
        }
      }
    }
  }
}
.gfs-form-input.choose-file {
  word-break: break-word;
  font-size: 11px;
  min-width: 100%;
}
.gfs-no-margin {
  margin: 0 !important;
}
.gfs-big-bottom-margin {
  // margin: 1.5em;
}
.gfs-padding-general {
  padding-top: 15px;
  padding-bottom: 0;
}
.gfs-form-input-title {
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  width: 100%;
  justify-items: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .title {
      .ratio {
        font-weight: normal;
      }
    }

    > .subtitle {
      color: $grey;
      font-size: 12px;
      font-weight: normal;
    }
  }
}
.gfs-margin-b {
  margin-bottom: 5px;
}
.gfs-title {
  min-height: 80px;
  font-size: 18px;
  font-weight: 600;
  color: #232323;
  display: flex;
  align-items: center;
  background-color: $foggy-blue;
}
.gfs-form-submit {
  border-radius: 3px;
  background-color: #067bf0;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  border: solid 1px #82bdf7;
}
.gfs-form-submit-padding {
  padding: 10px 55px;
}
.gfs-form-submit:hover {
  background-color: rgb(0, 114, 228);
  border: solid 1px #82bdf7;
}
.gfs-form-check-label {
  border-radius: 3px;
  background-color: $foggy-blue;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #232323;
  display: flex;
}
.gfs-form-control {
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  border: solid 1px #e9e9f0;
  background-color: #ffffff;
  height: 35px;
}

.gfs-form-check-input {
  border-radius: 3px;
  border: solid 1px #adadad;
  background-color: #ffffff;
  margin-right: 3px;
}
.gfs-preview {
  align-items: center;
  padding: 15px;
}
.gfs-preview > img {
  max-height: 50px;
}
.gfs-video-container {
  height: 75px;
  overflow: hidden;
}
.gfs-form-group {
  // margin-top: 20px;
}

.gfs-columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.gfs-grow-item {
  flex-basis: 0;
  flex-grow: 1;
}
.gfs-standard-margin {
  margin-right: 20px;
}
.gfs-form-input {
  margin-top: 5px;
  border-radius: 3px;
  border: solid 1px #82bdf7;
  background-color: #e6f1fd;
  padding: 5px 15px;
  font-size: 10px;
  font-weight: 600;
  color: #067bf0;
  width: max-content;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.gfs-form-input > img {
  height: 25px;
  width: 25px;
  margin-right: 3px;
}
.gfs-form-input-details {
  align-items: baseline;
  margin: 5px;

  .el-button:hover {
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
}
.gfs-form-input-details > div {
  color: #232323;
  font-size: 10px;
}
.gfs-form-input-btn {
  margin: 5px;
  border-radius: 3px;
  font-size: 10px;
  display: flex;
  border: 0;
  padding: 5px 10px;
  align-items: center;
  cursor: pointer;
}
.gfs-form-input-btn > img {
  height: 10px;
  width: 10px;
  margin-right: 3px;
}
.gfs-crop {
  background-color: #d0e7ff;
  color: #067bf0;
}
.gfs-delete {
  color: #de5353;
  background-color: #ffe6e6;
}
.gfs-form-input:hover {
  border: solid 1px #48a1fa;
  background-color: #e6f2ff;
}
.gfs-tooltip {
  color: #adadad;
  margin-left: 5px;
  font-weight: normal;
}
.gfs-common-text {
  margin-left: 5px;
}
.gfs-collapse {
  transition: all 1s;
}
.gfs-collapse[collapsed] {
  display: none;
}
.gfs-underline {
  text-decoration: underline;
}
.gfs-clickable {
  cursor: pointer;
}

.gfs-platform-container:last-child {
  margin-right: 0;
}
.gfs-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}
.gfs-popin {
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .close-popin {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    @media (max-width: 700px) {
      left: 5px;
      top: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.gfs-crop-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #232323;
}
.gfs-ratio-alert {
  color: red;
  font-weight: 600;
  margin-left: 5px;
}
</style>

<style lang="scss" module>
.title {
  font-weight: 600;
  margin-bottom: calc($spacing / 2);
  font-size: 12px;
}

.subtext {
  color: $grey;
  line-height: 1;
  margin-bottom: $spacing;
  font-size: 12px;
  line-height: 16px;
}

.warning {
  margin-top: $spacing * 3;
  padding: $spacing * 2;
  border-radius: 4px;
  border: solid 1px #ffcb00;
  background-color: #fff7d9;
}
</style>

<template>
  <div v-if="contentfulData && contentfulData.active" class="general-alert-message" :style="`background-color:${color}`">
    <i class="el-icon-info"></i>
    <div v-if="contentfulData" class="content">
      <div class="title">{{ contentfulData.title }}</div>
      <div v-html="contentfulData.content"></div>
    </div>
  </div>
</template>

<script>
import ContentfulService from '@/services/contentful.services';

export default {
  name: 'AlertGeneralMessage',
  components: {},
  props: {
    contentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contentfulData: null,
      color: process.env.VUE_APP_COLOR_ALERT,
      active: false,
    };
  },
  computed: {},
  async mounted() {
    try {
      const contentful = await ContentfulService.fetch(this.contentId);
      this.contentfulData = contentful !== null ? contentful : null;
      this.color = this.contentfulData.color;
      this.active = this.contentfulData.active;
    } catch (error) {
      this.contentfulData = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.general-alert-message {
  box-shadow: $shadow-general-alert;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 30px auto;

  .el-icon-info {
    font-size: 30px;
    color: $yellow-alert;
    margin: auto;
  }
  .content {
    text-align: left;
    margin-left: 15px;
    .title {
      font-weight: 900;
    }
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 30px auto;
    font-size: 14px;
    line-height: 1.2em;
    .title {
      margin-bottom: 5px;
    }
    .el-icon-info {
      font-size: 20px;
      color: $yellow-alert;
    }
  }
}

</style>

<template>
  <div>
    <el-dialog
      custom-class="action-modal"
      :append-to-body="true"
      :title="'Request testing'"
      :visible.sync="isVisible"
      width="40%"
      data-test-id="myGamesRequestTestingModal"
      @close="close"
    >
      <div class="formAction">
        <span>facebook app id:</span>
        <el-input
          v-model="facebookAppId"
          type="text"
          class="no-classs"
          placeholder="Insert your facebook App Id"
          data-test-id="myGamesFacebookIdInput"
        ></el-input>
        <div class="label">
          <span>Add changelogs:</span>
        </div>
        <div
          v-for="(item, id) in changelogs"
          :key="id"
          class="form-new-changelog"
        >
          <div class="field">
            <div class="label">
              <span>{{ item.category }}:</span>
            </div>
            <label>
              <span>{{ item.details }}</span>
            </label>
          </div>

          <br />
          <el-button
            icon="el-icon-minus"
            size="mini"
            type="danger"
            plain
            data-test-id="myGamesDeleteChangelogButton"
            @click="deleteChangeLog(id)"
          >Delete changelog</el-button
          >
        </div>
        <div class="form-new-changelog">
          <div class="field">
            <div class="label">
              <span>Choose a category</span>
            </div>
            <el-select v-model="newChangelog.category" filterable data-test-id="myGamesCategoryDropdown">
              <el-option
                v-for="c in categories"
                :key="c.value"
                :label="c.label"
                :value="c.value"
                class="no-classs"
                data-test-id="myGamesCategoryOptions"
              ></el-option>
            </el-select>
          </div>
          <div class="field">
            <div class="label">
              <span>Details about the changes</span>
            </div>
            <el-input v-model="newChangelog.details" data-test-id="myGamesChangelogInput"></el-input>
          </div>
          <br />

          <el-button
            v-if="canAddNewChangelog"
            type="primary"
            plain
            size="mini"
            icon="el-icon-check"
            data-test-id="myGamesChangelogOkButton"
            @click="addChangeLog"
          >OK</el-button
          >
        </div>
      </div>
      <div v-if="!game.isIterating" class="field">
        <el-checkbox
          id="checkbox-terms"
          v-model="hasAcceptedTerms"
          name="type"
          class="checkbox"
          data-test-id="myGamesRequestTestingTermsCheckbox"
        >
          <div class="label">
            <span style="margin-right: 5px">
              By submitting this test, you accept the
              <a :href="'https://www.voodoo.io/terms-platform'" target="_blank">
                Terms and Conditions
                <i class="el-icon-top-right"></i>
              </a>
            </span>
          </div>
        </el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="!hasAcceptedTerms"
          data-test-id="myGamesRequestTestingConfirmButton"
          @click="requestTesting(campaignId)"
        >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import categories from '@/static/gamecategories';

export default {
  name: 'RequestTestingModal',
  props: {
    game: {
      type: Object,
      default: null,
    },
    campaignId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: false,
      facebookAppId: '',
      newChangelog: {
        category: null,
        details: null,
      },
      categories,
      hasAcceptedTerms: false,
    };
  },
  computed: {
    changelogs() {
      if (this.game) {
        return this.game.changelogs || [];
      }
      return null;
    },
    canAddNewChangelog() {
      const element = this.newChangelog;
      if (!element.category || !element.details) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.popUpRequestTesting();
  },
  methods: {
    deleteChangeLog(id) {
      for (let i = 0; i < this.changelogs.length; i += 1) {
        const element = this.changelogs[i];
        if (element.id === id) {
          this.changelogs.splice(id, 1);
        }
      }
    },
    addChangeLog() {
      this.changelogs.push(this.newChangelog);
      this.newChangelog = {
        category: null,
        details: null,
      };
    },
    popUpRequestTesting() {
      this.facebookAppId = this.game.facebookAppId;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
    requestTesting() {
      // const action = this.actionList.filter(el => el.id === 'request_testing')[0];
      this.$store.dispatch('gameAction/setRequestTesting', {
        game: this.game,
        facebookAppId: this.facebookAppId,
        changelogs: this.changelogs,
      });

      this.close();

      // this.$store.dispatch('gameAction/showDetail', this.game);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7fbff;
  margin-top: 10px;
  align-items: center;
  .el-checkbox__label {
    .label {
      margin-top: 0px;
    }
  }
  .term {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

.label {
  font-size: 12px;
  color: #4d4f5c;
  &.videos {
    .subDetail {
      color: #adadad;
      font-size: 11px;
      line-height: 10px !important;
    }
  }
  span {
    &.name {
      color: #4d4f5c;
      font-size: 12px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: $spacing;
    }
    &.subDetail {
      color: #adadad;
      font-size: 11px;
      line-height: 10px !important;
    }
  }
}
</style>

<template>
  <div
    ref="wrapper"
    :class="$style.wrapper"
    :style="style">
    <div :class="$style.toggle" @click="animate">
      <font-awesome-icon :icon="icon" :class="$style.toggleIcon"/>
    </div>
    <div
      ref="content"
      :class="$style.content"
      v-html="content"></div>
  </div>
</template>

<script>
import anime from 'animejs';
import ContentfulService from '@/services/contentful.services';
import contentful from '@/tools/contentful/contentful';

export default {
  name: 'Tutorial',
  props: {
    targetSize: {
      type: String,
      default: '100%',
    },
    initialSize: {
      type: String,
      default: '10%',
    },
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: null,
    },
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      content: '',
    };
  },
  computed: {
    size() {
      return !this.isOpen ? this.initialSize : this.targetSize;
    },
    icon() {
      return this.isOpen ? 'angle-right' : 'angle-left';
    },
    style() {
      return {
        width: this.initialSize,
      };
    },
    nextStepLoading() {
      return this.$store.getters['submitGame/loadingProperty'];
    },
  },
  watch: {
    name(newName) {
      this.getContent(newName);
    },
    game: {
      handler() {
        this.getContent(this.name);
      },
      deep: true,
      immediate: true,
    },
    nextStepLoading() {
      this.getContent(this.name);
    },
  },
  mounted() {
    if (this.name) {
      this.getContent(this.name);
    }
    if (localStorage.tutorial !== 'false') {
      this.animate();
    }
  },
  methods: {
    async getContent(name) {
      if (!name) { this.content = '<h2>Nothing to see here</h2><span>Follow the steps on the left</span>'; return; }
      const contentfulData = await ContentfulService.fetch(name, this.game);
      const data = contentfulData.content || contentfulData.everything;
      const formattedContent = contentful.replaceContentfulDynamicTags(data, this.game);
      this.content = formattedContent;
    },
    animate() {
      const wrapper = this.$refs.wrapper;
      const thisC = this;
      this.isOpen = !this.isOpen;
      localStorage.tutorial = this.isOpen;

      anime({
        targets: wrapper,
        width: thisC.size,
        easing: 'easeInOutQuint',
      });

      const opacity = this.isOpen ? 1 : 0;
      const delay = this.isOpen ? '400' : '0';
      const content = this.$refs.content;

      this.$emit('isOpen', this.isOpen);


      anime({
        targets: content,
        opacity,
        duration: 600,
        delay,
        easing: 'easeInOutQuint',
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  background-color: $slate;
  color: $white;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  padding: $spacing * 4;
  position: relative;
  text-align: left;
  img {
    border-radius: 10px;
    margin: 10px 0;
  }
}


.toggle {
  background-color: $foggy-blue;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 10px 5px rgba(6, 123, 240, 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  margin-left: -50px;
  margin-top: -20px;
  z-index: 10;
}

.toggleIcon {
  color: $intense-blue;
  font-size: 20px;
}

.content {
  opacity: 0;
  color: $white;

  h2:not(:first-child) {
    margin-top: $spacing * 4;
  }

  pre {
    background-color: $soft-black;
    border-radius: 8px;
    padding: 8px;
    background-image: none;
    font-size: 14px;
    line-height: 1.375;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-wrap: break-word;
    tab-size: 4;
    hyphens: none;
  }

  code {
    color: $green-alert !important;
  }
}

.content {

   h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  ul,
  ol {
    margin-left: 20px !important;
  }
  a {
    color: #067bf0;
  }
  .preview {
    margin-left: 50px;
    border-radius: 5px;
  }
  .image-from-contentful {
    max-width: 600px;
    max-height: 500px;
    border-radius: 5px;
  }
    h1, h2, h3 {
      line-height: 100%  !important;
      letter-spacing: 0em  !important;
    }
    p {
      line-height: 30px  !important;
      letter-spacing: 0em  !important;

    }
}
pre {
  padding: 10px;
  border-radius: 10px;
  background-image: none;
  color: $blue-font-color;
  font-family: Hack, Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.375;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  background: $pre-font-color;
 code {
  white-space: break-spaces;
  word-break: break-all;
 }
}
.content img {
  max-width: 100%;
}
.important-notice * {
  color: #000 !important;
}
.note {
    margin-left: 10px;
    font-size: 12px;
    line-height: 20px;
  }
</style>

<style lang="scss" scoped>
[class*="Tutorial_content"] {
  .important-notice {
    color: $slate !important;
  }
}

</style>

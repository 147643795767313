<template>
  <div v-if="projectFromSearch || gameHasProject" :class="$style.wrapper">
    <div v-if="(isSearching || projectNotFound) && !gameHasProject">
      <span v-if="isSearching" :class="$style.loader">
        <font-awesome-icon icon="spinner" spin />
      </span>
      <span v-if="projectNotFound" :class="$style.iconFound">
        <font-awesome-icon icon="check" />
      </span>
      <span :class="$style.textBold">{{ placeholderText }}</span>
    </div>
    <div v-if="(!isSearching && projectFound) || gameHasProject">
      <div :class="[$style.textBold, $style.linkedToLabel]">
        This test will be linked to:
      </div>
      <div v-if="isGetting">
        <span :class="$style.loader">
          <font-awesome-icon icon="spinner" spin />
        </span>
      </div>
      <div v-else :class="$style.project">
        <el-image
          v-if="icon"
          :class="$style.projectIcon"
          :src="icon"
          :fit="'contain'">
          <div slot="error">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="42" viewBox="0 0 42 42" v-bind:svg-inline="''" :class="$style.projectIcon" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path id="a" d="M10.256 0h21.488c3.567 0 4.86.371 6.163 1.069a7.263 7.263 0 013.024 3.024C41.63 5.396 42 6.689 42 10.256v21.488c0 3.567-.371 4.86-1.069 6.163a7.263 7.263 0 01-3.024 3.024C36.604 41.63 35.311 42 31.744 42H10.256c-3.567 0-4.86-.371-6.163-1.069a7.263 7.263 0 01-3.024-3.024C.37 36.604 0 35.311 0 31.744V10.256c0-3.567.371-4.86 1.069-6.163a7.263 7.263 0 013.024-3.024C5.396.37 6.689 0 10.256 0z"/></defs><g fill="none" fill-rule="evenodd"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><use fill="#E9E9F0" xlink:href="#a"/><path fill="#FFF" d="M32.203 19.55c-.693 0-1.289-.603-1.289-1.308 0-.703.596-1.306 1.289-1.306s1.288.603 1.288 1.306c0 .705-.595 1.308-1.288 1.308m-2.527 2.564c-.694 0-1.288-.604-1.288-1.307 0-.704.594-1.308 1.288-1.308.693 0 1.288.604 1.288 1.308 0 .703-.595 1.307-1.288 1.307m-2.527-2.564c-.694 0-1.288-.603-1.288-1.308 0-.703.594-1.306 1.288-1.306.694 0 1.288.603 1.288 1.306 0 .705-.594 1.308-1.288 1.308m2.527-5.178c.693 0 1.288.603 1.288 1.307s-.595 1.306-1.288 1.306c-.694 0-1.288-.602-1.288-1.306 0-.704.594-1.307 1.288-1.307m-13.973 3.87a.998.998 0 01-.99 1.006h-1.24v1.207a.998.998 0 01-.99 1.006.999.999 0 01-.991-1.006v-1.207h-1.239a.998.998 0 01-.99-1.006.997.997 0 01.99-1.005h1.239V16.03a.998.998 0 01.99-1.006c.548 0 .992.45.992 1.006v1.206h1.239c.547 0 .99.45.99 1.005M36.96 19.75c-1.933-5.58-2.429-10.508-8.969-8.145a9.438 9.438 0 01-3.32.604H17.34c-1.14 0-2.28-.252-3.32-.604-6.54-2.363-7.036 2.564-8.968 8.145-1.932 5.58-1.14 10.96 1.883 12.066 2.972 1.056 6.986-2.564 8.968-8.195h10.256c1.932 5.581 5.946 9.251 8.968 8.195 2.923-1.055 3.766-6.485 1.834-12.066" mask="url(#b)"/></g></svg>
          </div>
        </el-image>

        <div v-else :class="$style.folderIconWrapper">
          <font-awesome-icon :class="$style.folderIcon" icon="folder" />
          <div :class="$style.count" data-test-id="searchProjectCount">{{ count }}</div>
        </div>
        <div :class="$style.projectName" data-test-id="searchProjectNameText">{{ projectName }}</div>
      </div>
      <br />
      <div :class="$style.textBold">Project name</div>
      <div :class="[$style.instructions, isEditValid ? '': $style.errorText]">
        Name length must be between 3 and 25 characters.
      </div>
      <el-input
        v-model="name"
        type="text"
        size="small"
        :minlength="3"
        :maxlength="25"
        data-test-id="searchProjectEditNameInput"
        @input="nameChanged"
        @blur="validateEdit"></el-input>
      <div v-if="projectNameErrors.length > 0" :class="$style.errors">
        <ul>
          <li v-for="error in projectNameErrors" :key="error">{{error}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SearchProject',
  props: {
    isSearching: {
      type: Boolean,
      default: false,
    },
    isGetting: {
      type: Boolean,
      default: false,
    },
    projectFromSearch: {
      type: Object,
      default: null,
    },
    game: {
      type: Object,
      default: null,
    },
    projectNameErrors: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      name: '',
      isEditValid: true,
    };
  },
  computed: {
    gameHasProject() {
      return this.game.projectId;
    },
    placeholderText() {
      let placeholder = '';
      if (this.isSearching) {
        placeholder = 'Looking for matching projects';
      } else if (this.projectNotFound) {
        placeholder = 'We’ll create new SDK IDs in the next step';
      }
      return placeholder;
    },
    icon() {
      return this.projectFromSearch?.project?.projectGameCount === 1
        ? this.projectFromSearch.project.games[0].iconUrl
        : null;
    },
    count() {
      return this.projectFromSearch?.project?.projectGameCount;
    },
    projectName() {
      return this.projectFromSearch?.project?.name;
    },
    projectFound() {
      return (
        this.projectFromSearch !== null && this.projectFromSearch?.project?.name
      );
    },
    projectNotFound() {
      return (
        this.projectFromSearch !== null
        && this.projectFromSearch.project === null
      );
    },
  },
  watch: {
    projectFromSearch() {
      if (this.projectFound) {
        this.name = this.projectFromSearch.project.name;
      }
    },
  },
  methods: {
    nameChanged() {
      if (this.name !== this.projectFromSearch.project.name) {
        this.validateEdit();
        this.$emit('name-changed', this.name);
      }
    },
    validateEdit() {
      const isEditValid = !!(this.name.length <= 25 && this.name.length >= 3);
      this.isEditValid = isEditValid;
      this.$emit('name-validity', isEditValid);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  background-color: $foggy-blue;
  border-radius: 4px;
  margin-bottom: $spacing * 3;
  padding: $spacing * 2;

  input {
    border-radius: 8px;
  }
}

.textBold {
  font-size: 12px;
  font-weight: 600;
  color: $slate;
}

.linkedToLabel {
  margin-bottom: $spacing * 2;
}

.project {
  display: flex;
  align-items: center;
  padding-left: $spacing * 2;
  margin-bottom: $spacing * 3;
  border-left: 4px solid $intense-blue;
}

.projectIcon {
  margin-right: $spacing;
  height: 25px;
  width: 25px;
}

.folderIcon {
  color: $light-blue;
  width: auto;
  height: auto;
  font-size: 21px;
}

.folderIconWrapper {
  position: relative;
  width: 21px;
  height: 21px;
  margin-right: $spacing;
}

.instructions {
  font-size: 12px;
  color: #adadad;
  margin-top: $spacing/2;
  margin-bottom: $spacing;
}

.count {
  position: absolute;
  width: 100%;
  top: 5px;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.loader {
  color: $intense-blue;
  margin-right: $spacing;
}

.iconFound {
  color: $green-alert;
  margin-right: $spacing;
}

.errorText {
  color: $red-alert;
}

.errors {
  ul {
    margin-top: $spacing * 2;
    li {
      color: $blood-normal;
      font-size: 11px;
    }
  }
}
</style>

<template>
  <div :class="$style.wrapper" :style="{ backgroundColor: color, opacity:  isAccessible? 1: 0.2}" class="voodoo-step">
    <p style="color: white">Click to validate</p>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    step: {
      type: Object,
      default: null,
    },
    steps: { // alternative: provide / inject
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isIntersecting: false,
      intersectionRatio: 0,
    };
  },
  computed: {
    isAccessible() {
      return this.index === 0 || this.steps[this.index - 1].isValid;
    },
  },
  mounted() {
    const options = {
      root: document.querySelector('.voodoo-scroller'),
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (this.isAccessible && isIntersecting) {
        this.$emit('change', this.index);
      }
    }, options);

    const target = this.$el;
    observer.observe(target);
  },
};
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  height: 600px;
}
</style>

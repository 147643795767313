/* eslint-disable no-param-reassign */
import gameActionService from '@/services/gameAction.services';

const submitGame = {
  namespaced: true,
  state: {
    game: null,
    loading: false,
    loadingProperty: false,
    loadingVideosByPlatform: false,
    nextStepLoading: false,
    backStepLoading: false,
    errors: [],
    campaignCreationFailedNetworks: [],
    campaignCreationSuccessNetworks: [],
    adjustLoading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loadingProperty(state) {
      return state.loadingProperty;
    },
    loadingVideosByPlatform(state) {
      return state.loadingVideosByPlatform;
    },
    game(state) {
      return state.game;
    },
    nextStepLoading(state) {
      return state.nextStepLoading;
    },
    backStepLoading(state) {
      return state.backStepLoading;
    },
    errors(state) {
      return state.errors;
    },
    campaignCreationFailedNetworks(state) {
      return state.campaignCreationFailedNetworks;
    },
    campaignCreationSuccessNetworks(state) {
      return state.campaignCreationSuccessNetworks;
    },
    adjustLoading(state) {
      return state.adjustLoading;
    },
  },
  mutations: {
    set_loadingVideosByPlatform(state, item) {
      state.loadingVideosByPlatform = item;
    },
    set_clean(state) {
      state.loading = false;
      state.game = null;
      state.loading = false;
      state.nextStepLoading = false;
      state.backStepLoading = false;
      state.errors = [];
    },
    set_game(state, game) {
      const ob = state.game;
      state.game = {
        ...ob,
        ...game,
        disabled: game.disabled || false,
        studioId: game.studio?.id || null,
      };
    },
    set_property(state, item) {
      state.game = {
        ...state.game,
        ...item,
      };
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_loading_property(state, loading) {
      state.loadingProperty = loading;
    },
    set_next_step(state, item) {
      state.game = {
        ...state.game,
        substatus: item.status,
      };
    },
    set_adjustLoading(state, value) {
      state.adjustLoading = value;
    },
    set_nextStepLoading(state, loading) {
      state.nextStepLoading = loading;
    },
    set_backStepLoading(state, loading) {
      state.backStepLoading = loading;
    },
    set_disabled_game(state, disabled) {
      state.game = {
        ...state.game,
        disabled: false,
        loadingRequest: disabled,
      };
    },
    set_updated_game(state, updated) {
      state.game = {
        ...state.game,
        isUpdated: updated,
      };
    },
    check_operation(state, checkOperation) {
      state.game = {
        ...state.game,
        checkOperation,
      };
    },
    loading_video_in_platform(state, video) {
      const iterationsGame = state.game.iterations;
      if (state.game && iterationsGame) {
        const lastIterations = iterationsGame[iterationsGame.length - 1];

        // eslint-disable-next-line no-restricted-syntax
        for (const key in lastIterations.adNetworks) {
          if (key === video.platform) {
            // eslint-disable-next-line no-prototype-builtins
            if (lastIterations.adNetworks.hasOwnProperty(key)) {
              const object = lastIterations.adNetworks[key];
              // object.videos.push(video);
              for (let i = 0; i < object.videos.length; i += 1) {
                const element = object.videos[i];
                if (element.id === video.itemId) {
                  element.loading = true;
                }
              }
            }
          }
        }
        iterationsGame[iterationsGame.length - 1] = lastIterations;

        state.game = {
          ...state.game,
          iterations: iterationsGame,
        };
      }
    },
    set_campaign_creation_status(state, campaignCreationStatuses = {}) {
      state.campaignCreationFailedNetworks = campaignCreationStatuses.error || [];
      state.campaignCreationSuccessNetworks = campaignCreationStatuses.success || [];
    },
  },
  actions: {
    check_operation(context, checkOperation) {
      context.commit('check_operation', checkOperation);
    },
    loading_video_in_platform(context, platformVideo) {
      context.commit('loading_video_in_platform', platformVideo);
    },
    setDisabledGame(context, value) {
      context.commit('set_disabled_game', value);
    },
    setNextStepLoadingProperty(context, value) {
      context.commit('set_nextStepLoading', value);
    },
    clean(context) {
      context.commit('set_clean');
    },
    async getGame(context, id) {
      context.commit('set_loading', true);
      const rep = await gameActionService.getStatsByGameId({
        id,
      });
      if (rep) {
        context.commit('set_loading', false);
        context.commit('set_game', {
          ...rep,
          disabled: true,
        });
      } else {
        context.commit('set_loading', false);
      }
    },

    async refreshGame(context, id) {
      const rep = await gameActionService.getStatsByGameId({
        id,
      });
      if (rep) {
        context.commit('set_game', rep);
      }
    },

    async setNextStep(context, item) {
      context.commit('set_nextStepLoading', true);
      const response = await gameActionService.setNextStepGame(item);
      context.commit('set_nextStepLoading', false);

      if (response) {
        context.commit('set_next_step', item);
      }
    },
    async setBackStep(context, item) {
      context.commit('set_backStepLoading', true);
      const response = await gameActionService.setNextStepGame(item);
      context.commit('set_backStepLoading', false);

      if (response) {
        context.commit('set_next_step', item);
      }
    },
    async createGame(context, item) {
      context.commit('set_loading', true);
      const response = await gameActionService.createNewGame(item);
      context.commit('set_loading', false);
      if (!response.detail) {
        this.dispatch('gameAction/reset_gameList');
        return new Promise(async (resolve) => {
          resolve(response);
        });
      }
      return new Promise(async (resolve) => {
        resolve(response);
      });
    },

    async updateGame(context, item) {
      context.commit('set_loading_property', true);
      const response = await gameActionService.updateGameByProperties(item);
      context.commit('set_loading_property', false);
      return new Promise(async (resolve, reject) => {
        if (response.errorList) {
          reject(response);
          return;
        }
        if (response.detail) {
          reject(response.detail);
          return;
        }
        context.commit('set_property', item);
        resolve(response);
      });
    },
    async uploadVideoFile(context, payload) {
      return new Promise(async (resolve) => {
        context.commit('set_loadingVideosByPlatform', true);
        const response = await gameActionService.uploadVideo(payload);
        context.commit('set_loadingVideosByPlatform', false);

        resolve((response) || null);
      });
    },
    async updateGameBudget(context, payload) {
      context.commit('set_loading_property', true);
      let response;
      try {
        response = await gameActionService.updateGameByProperties(payload);
        context.commit('set_game', response);
      } catch (e) {
        console.error(e);
      }

      context.commit('set_loading_property', false);
      if (response !== undefined) {
        return response;
      }

      throw new Error('There was a problem while updating the budget for your campaign. Please refresh and try again or contact support.');
    },
    async updateGameCampaignDuration(context, payload) {
      context.commit('set_loading_property', true);
      let response;
      try {
        response = await gameActionService.updateGameByProperties(payload);
        context.commit('set_game', response);
      } catch (e) {
        console.error(e);
      }

      context.commit('set_loading_property', false);
      if (response !== undefined) {
        return response;
      }

      throw new Error('There was a problem while updating the budget for your campaign. Please refresh and try again or contact support.');
    },
    async pushSimpleAction(context, payload) {
      const response = await gameActionService.simpleAction(payload);
      if (response.error) {
        throw (response.error);
      }
      if (response) {
        context.commit('set_game', response);
      }
      return response;
    },

    async setGameGenre(context, param) {
      return new Promise(async (resolve) => {
        const response = await gameActionService.setActionById(param);
        if (!response.detail) {
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },

    async linkCampagn(context, param) {
      context.commit('set_disabled_game', true);

      return new Promise(async (resolve) => {
        const response = await gameActionService.setActionById(param);
        context.commit('set_updated_game', true);
        context.commit('set_disabled_game', false);

        if (!response.detail) {
          resolve(response);
          context.commit('set_game', response);
        } else {
          resolve(null);
        }
      });
    },
    async updateIconGame(context, payload) {
      return new Promise(async (resolve) => {
        const response = await gameActionService.updateIconGame(payload);
        if (!response.error) {
          context.commit('set_game', response);
        } else {
          response.error = await context.dispatch('ActionToDispatch/getMessageErrorFromCodif', response, { root: true });
        }
        resolve(response);
      });
    },

    async getAdjustToken(context, payload) {
      context.commit('set_adjustLoading', true);
      return new Promise(async (resolve) => {
        const response = await gameActionService.getAdjustToken(payload);

        context.commit('set_game', {
          adjustAppToken: response.data?.adjustAppToken || null,
        });
        context.commit('set_adjustLoading', false);

        resolve(response);
      });
    },

    async getCampaignCreationStatus(context, gameId) {
      await context.dispatch('submitGame/setNextStepLoadingProperty', true, { root: true });
      const campaignCreationStatuses = await gameActionService.getCampaignCreationStatus(gameId);
      context.commit('set_campaign_creation_status', campaignCreationStatuses);
      await context.dispatch('submitGame/setNextStepLoadingProperty', false, { root: true });
    },
  },
};

export default submitGame;

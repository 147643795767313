<template>
  <div>
    <div id="nav" class="publishing">
      <div class="container">
        <div class="item-left-mobile">
          <!-- @click="switchMobileMenu" -->
          <div class="burger-menu" data-test-id="HeaderBurgerMenu" @click="switchMobileMenu">
            <el-image
              class="burger"
              style="width: 20px;
                        height: 20px;
                       "
              :src="burger"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="item-left" data-test-id="HeaderLogo">
          <router-link
            :to="{
              name: 'home'
            }"
          >
            <li>
              <a class="logo"></a>
              <span v-if="!isOnAdmin" class="sub-logo" @click="actionHeader('home')">PUBLISHING PLATFORM</span>
              <span v-if="isOnAdmin" class="sub-logo" @click="actionHeader('home')">
                STUDIO ADMIN
              </span>
            </li>

            <!-- <span class="logo-ext">
          {{ `- &nbsp; Publishing platform` }}
            </span>-->
          </router-link>

          <el-dropdown
            class="drop-menu-logo"
            placement="bottom-start"
            trigger="click"
            data-test-id="HeaderDropdownLogo"
            @command="actionHeader"
            @visible-change="headerDropdownVisible = $event"
          >
            <el-button class="dropdown-button" :icon="`el-icon-caret-${headerDropdownVisible ? 'top' : 'bottom'}`"></el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item :command="'home'">Publishing Platform</el-dropdown-item> -->
              <el-dropdown-item
                data-test-id="HeaderItemStudioPlatform"
                :command="'studioHome'">Studio Platform</el-dropdown-item>
              <el-dropdown-item
                data-test-id="HeaderItemStudioAdmin"
                :command="'mystudio'">Studio Admin</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div class="menu">
            <router-link
              :to="{
                name: 'home',
                query: { game: this.$route.query.game, studio: this.$route.query.studio }
              }"
            >
              <li>
                <span class="logo-ext" :class="{ active: gamePageActive }">{{ `Games` }}</span>
              </li>
            </router-link>
            <router-link
              :to="{
                name: 'studios',
                query: { game: this.$route.query.game, studio: this.$route.query.studio }
              }"
            >
              <li>
                <span class="logo-ext" :class="{ active: studioPageActive }">{{ `Studios` }}</span>
              </li>
            </router-link>
          </div>
        </div>
        <div v-if="me" class="item-right">
          <Notifications></Notifications>
          <el-dropdown
            data-test-id="HeaderProfilerButton"
            @command="actionHeader"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-user-solid"></i>
              <span class="name">{{ me.firstName }} {{ me.lastName }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item
                data-test-id="HeaderProfilerButtonLogout"
                :command="'logout'">{{ $t("common.logOut") }}</el-dropdown-item>
              <el-dropdown-item
                data-test-id="HeaderProfilerButtonMyProfile"
                :command="'myprofile'">{{ $t("common.myProfile") }}</el-dropdown-item>
              <!-- <el-dropdown-item :command="'studioService'">
              Studio platform</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <MobileMenuPM v-if="mobileMenu" @visibility="switchMobileMenu"></MobileMenuPM>
    </transition>

    <div class="margin-header"></div>
  </div>
</template>

<script>
import Notifications from '@/components/Notifications/Notifications.vue';
import { LOGIN_FLOW_ROUTES } from '@/router';
import burger from '@/assets/images/burgerMenu@3x.png';
import MobileMenuPM from '@/views/MobileMenuPm.vue';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import tool from '@/tools/tools';
import UsersService from '@/services/users.services';

export default {
  name: 'Header',
  components: {
    Notifications,
    MobileMenuPM,
  },
  data() {
    return {
      LOGIN_FLOW_ROUTES,
      headerDropdownVisible: false,
      burger,
      mobileMenu: false,
      gameIcon,
    };
  },
  computed: {
    isOnAdmin() {
      if (this.$route.name === 'home') {
        return false;
      }
      return true;
    },
    userLoaded() {
      return this.$store.getters['users/isLoaded'] || null;
    },
    userId() {
      return this.$store.getters['users/id'];
    },
    me() {
      return this.$store.getters['users/me'] || null;
    },
    gamePageActive() {
      if (this.$route.name === 'home') {
        return true;
      }
      return false;
    },
    studioPageActive() {
      if (this.$route.name === 'studios' || this.$route.name === 'studio') {
        return true;
      }
      return false;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    views() {
      return this.$store.getters['views/views'] || null;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
  },
  mounted() {
    if (this.me && this.myPermissions?.includes('view_pm_interface')) {
      this.$store.dispatch('views/getAllViews');
    } else {
      this.redirect();
    }
  },
  methods: {
    switchMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    redirect() {
      if (!this.me) {
        this.$router.push({
          name: 'root',
          params: {
            redirect: true,
          },
        }).catch(() => {});
      } else {
        tool.redirectUser(this.$router, this.myPermissions, UsersService);
      }
    },
    actionHeader(action) {
      let routeData = '';
      switch (action) {
      case 'home':
        if (!this.gamePageActive) {
          this.$router.push({
            name: 'home',
          });
        }
        break;
      case 'studioHome':
        routeData = this.$router.resolve({
          name: 'studioHome',
        });
        window.open(routeData.href, '_blank');
        // this.$router.push({
        //   name: 'studioHome',
        // });
        break;
      case 'logout':
        this.$store.dispatch('users/logout');
        break;
      case 'studioService':
        this.$router.push({
          name: 'studioServiceList',
          params: {
            page: 'mystudios',
            pmId: this.me.id,
          },
        });
        break;
      case 'myprofile':
        routeData = this.$router.resolve({
          name: 'meAdmin',
        });
        window.open(routeData.href, '_blank');
        break;
      case 'mystudio':
        routeData = this.$router.resolve({
          name: 'studioAdmin',
        });
        window.open(routeData.href, '_blank');

        break;
      case 'notifySeen':
        break;
      default:
        this.$store.dispatch('users/logout');
        break;
      }
    },
  },
};
</script>

<style lang="scss">
#nav {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
  color: var(--header-color);
  position: fixed;
  z-index: 103;
  .router-link-active {
    text-decoration: none !important;
  }
  .el-dropdown {
    display: flex;
    align-items: center;
  }
  &.publishing {
    background-color: var(--header-background-color);
    box-shadow: var(--header-shadow);
  }
  .item-left,
  .item-right {
    .name {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    display: flex;
    align-items: center;
    .nbNotif {
      // margin-top: 10px;
      // margin-right: 10px;
    }
  }
  .item-left-mobile {
    display: none;
    @media screen and (max-width: 700px) {
      display: flex;
    }
  }
  .item-left {
    @media screen and (max-width: 700px) {
      display: none;
    }
    .dropdown-button {
      padding: 3px;
      margin: 6px;
    }
  }
  .item-right {
    span {
      color: var(--header-color);
      .el-icon-message-solid,
      .el-icon-user-solid {
        font-size: 18px;
        margin-right: 15px;
      }

      .el-icon-message-solid {
        margin-left: 15px;
      }
    }
    .el-badge__content.is-fixed {
      right: 23px;
    }
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        display: block;
        background-color: #067bf0;
        bottom: -21px;
        // left: 9px;
        transition: all 0.5s;
        left: 50%;
      }
      &.active,
      &:hover {
        color: #067bf0;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          display: block;
          background-color: #067bf0;
          bottom: -21px;
          left: 9px;
          transition: all 0.5s;
        }
      }
      color: var(--header-color);
    }
  }
  .container {
    align-items: left;
    display: flex;
    align-items: left;
    width: 100%;
    justify-content: space-between;
  }
  .drop-menu-logo {
    li {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .router-link-active,
  .drop-menu-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  a {
    font-weight: bold;
    color: var(--header-color);
    text-decoration: none !important;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  .logo {
    width: 100px;
    vertical-align: middle;
    height: 15px;
    background-image: var(--logo-path); //url("~@/assets/images/voodoo-black.svg"); //
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: -10px;
  }
  .sub-logo {
    color: #82bdf7;
    font-size: 7px;
    font-weight: bold;
    margin-left: -12px;
    margin-top: 4px;
  }
  .logo-ext {
    font-weight: bold;
    margin-top: 4px;
    font-size: 14px;
  }
}
</style>

<template>
  <div v-if="codification && codification.errorList">
    <el-dropdown :trigger="'click'" :class="{ isNotEmpty: list.length > 0 }" @command="actionDropDown">
      <span class="el-dropdown-link">
        <el-badge :value="nbNotifs" :max="99" class="nbNotif">
          <span>
            <i v-if="!loading" class="el-icon-message-solid"></i>
            <i v-else class="el-icon-loading"></i>
          </span>
        </el-badge>
      </span>
      <el-dropdown-menu v-if="list.length > 0" slot="dropdown" class="notification">
        <el-dropdown-item
          v-for="(item, id) in list"
          :key="item.id + '' + id"
          :command="item"
          :class="{ noSee: !item.readAt }">
          <div class="item-notif" @mouseover="sendSeenNotif(item.id)" @click="gotoElement(item)">
            <div class="logo" :class="item.type"></div>
            <div class="content">
              <!-- {{ item.content }} -->
              <div class="message" v-html="decodeEntity(item.content)"></div>
              <div class="date">{{ formatDate(item.createdAt) }}</div>
            </div>
          </div>
        </el-dropdown-item>
        <div v-if="showButtonAllNotif" class="load-more" @click.stop="seeAllNotif = !seeAllNotif">
          <span v-if="!seeAllNotif">See all notifications</span>
          <span v-else>See latest notifications</span>
        </div>
      </el-dropdown-menu>
      <el-dropdown-menu v-else slot="dropdown"></el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      :modal-append-to-body="true"
      :append-to-body="true"
      title="Send Message"
      :visible="modalNewMessage"
      width="40%"
      :before-close="closeMessageDialog"
    >
      <div class="new-message-body">
        <div>User Id:</div>
        <el-input
          v-model="userIdMessage"
          size="mini"
          autocomplete="off"
          @keyup.enter.native="sendNotif"></el-input>
        <div>PM:</div>
        <div class="select-pm">
          <el-select
            v-model="pm"
            size="mini"
            autocomplete="on"
            filterable
            @change="updateId">
            <el-option
              v-for="item in pmList"
              :key="item.value"
              :label="item.label"
              :value="item"></el-option>
          </el-select>
        </div>
        <div>Message:</div>
        <el-input
          v-model="message"
          size="mini"
          autocomplete="off"
          @keyup.enter.native="sendNotif"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="closeMessageDialog()">
          Cancel
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="disabledSend"
          @click="sendNotif();closeMessageDialog();">
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import tools from '@/tools/tools';

export default {
  name: 'Notifications',
  components: {},
  props: {},
  data() {
    return {
      list: [],
      seeAllNotif: false,
      userIdMessage: '',
      message: '',
      pm: null,
    };
  },
  computed: {
    updated() {
      return this.$store.getters['notifications/updated'];
    },
    disabledSend() {
      if (this.userIdMessage && this.message) {
        return false;
      }
      return true;
    },
    showButtonAllNotif() {
      return this.lastNotifList.length !== this.notifList.length;
    },
    nbNotifs() {
      return this.lastNotifList.filter(el => !el.readAt).length || null;
    },
    userLoaded() {
      return this.$store.getters['users/isLoaded'] || null;
    },
    userId() {
      return this.$store.getters['users/id'];
    },
    userName() {
      return this.$store.getters['users/userName'];
    },
    loading() {
      return this.$store.getters['notifications/loading'];
    },
    me() {
      return this.$store.getters['users/me'] || null;
    },
    lastNotifList() {
      return cloneDeep(this.$store.getters['notifications/latestList']) || [];
    },
    notifList() {
      return cloneDeep(this.$store.getters['notifications/notifList']) || [];
    },
    modalNewMessage() {
      if (this.$route.query.notify !== undefined) {
        return true;
      }
      return false;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    pmList() {
      if (this.codification) {
        return this.codification.publishingManagers;
      }
      return [];
    },
  },
  watch: {
    // userLoaded() {
    //   this.$store.dispatch('notifications/fetchNotifications');
    //   this.$store.dispatch('notifications/getLatestNotifications');
    // },
    lastNotifList(value) {
      this.list = value;
    },
    seeAllNotif(value) {
      if (value) {
        this.list = this.notifList;
      } else {
        this.list = this.lastNotifList;
      }
    },
  },
  mounted() {
    if (!this.updated) {
      this.$store.dispatch('notifications/fetchNotifications');
      this.$store.dispatch('notifications/getLatestNotifications');
    }
    this.list = this.lastNotifList;
  },
  methods: {
    decodeEntity(string) {
      const elem = document.createElement('textarea');
      elem.innerHTML = string;
      const decoded = tools.decodeError(elem.value).map((err) => {
        if (!err.code) {
          return err;
        }
        let decode = null;
        try {
          decode = this.codification.errorList[err.code];
        } catch (error) {
          return err.code;
        }
        return decode ? decode.message : err.code;
      }).join(';');
      return decoded;
    },
    updateId(pm) {
      this.userIdMessage = pm.value;
    },
    formatDate(date) {
      moment.locale('en-US');

      return moment(date).from(moment());
    },
    actionDropDown(item) {
      if (item.type === 'STUDIO_CHURNED' || item.type === 'STUDIO_ACTIVE_AGAIN') {
        this.$router
          .push({
            query: {
              game: undefined,
              studio: item.relatedContentId,
              view: this.$route.query.view,
            },
          })
          .catch(() => {});
      }
      if (item.type === 'GAME_KPI_ALERT') {
        this.$router
          .push({
            query: {
              game: item.relatedContentId,
              studio: undefined,
              view: this.$route.query.view,
            },
          })
          .catch(() => {});
      }
      if (item.type === 'STUDIO_INACTIVE' || item.type === 'STUDIO_TO_COACH') {
        this.$router
          .push({
            query: {
              game: undefined,
              studio: item.relatedContentId,
              view: this.$route.query.view,
            },
          })
          .catch(() => {});
      }
    },
    sendSeenNotif(id) {
      const listNotSeen = this.lastNotifList.filter(el => !el.readAt);
      for (let i = 0; i < listNotSeen.length; i += 1) {
        const element = listNotSeen[i];
        if (element.id && element.id === id) {
          this.$store.dispatch('notifications/setReadedNotifications', element.id);
        }
      }
    },
    sendNotif() {
      if (this.userIdMessage || this.message) {
        this.$store.dispatch('notifications/sendNotifications', {
          id: this.userIdMessage,
          message: this.message,
          confirm: true,
        });
        this.closeMessageDialog();
      }
    },
    closeMessageDialog() {
      const query = {
        ...this.$route.query,
        notify: undefined,
      };
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    gotoElement(item) {
      if (['GAME_OPERATION_SUCCESS', 'GAME_OPERATION_ERROR'].includes(item.type)) {
        this.gotoGameView(item.relatedContentId);
      }
    },
    gotoGameView(gameId) {
      this.$router
        .push({
          query: {
            game: undefined,
            studio: undefined,
            view: undefined,
            gameid: gameId,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.new-message-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  .el-input {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .select-pm {
  }
  .el-input__prefix,
  .el-input__suffix {
    height: 33px;
  }
}
.isNotEmpty {
  &:hover {
    cursor: pointer;
  }
}
.nbNotif {
  .el-icon-loading {
    margin-right: 15px;
  }
}
.el-dropdown-menu.notification {
  // ul {
  padding: 0;
  // }
  li {
    padding: 10px 10px;
    &.noSee {
      background-color: var(--hover-card);
      font-weight: bold;
    }
    &:hover {
      background-color: #fff;
    }
  }
  .item-notif {
    width: 300px;
    display: flex;
    align-items: center;
    .logo {
      height: 50px;
      min-width: 50px;
      vertical-align: middle;
      background-image: url("~@/assets/images/placeholder-studio-icon@3x.png");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
      filter: var(--filter-image);
      // margin: auto;
      &.info {
      }
    }
    .content {
      display: flex;
      flex-direction: column;

      // margin: auto;
      .message {
        font-size: 15px;
        line-height: 21px;
        a {
          color: #067bf0;
          text-decoration-line: none;
        }
      }
      .date {
        font-size: 12px;
      }
    }
  }
  .load-more {
    display: flex;
    border-radius: 2px;
    background-color: var(--bg-notif-more);
    align-items: center;
    // padding: 10px;
    font-size: 15px;
    line-height: 21px;
    justify-content: space-around;
    padding: 15px;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #82bdf7;
    }
  }
}
</style>

<template>
  <div class="header-submit-game-page">
    <div v-if="game && !loading" class="infos-left">
      <GameIcon data-test-id="gameSubmissionHeaderIcon" :game="game"></GameIcon>
      <div v-if="game.id" class="basic-infos-game">
        <div class="top">
          <div class="name" data-test-id="gameSubmissionHeaderName">{{ game.name }}</div>
        </div>
        <div class="createdAt">
          Creation: <span data-test-id="gameSubmissionHeaderCreationDate">{{ formatDate(game.createdAt) }}</span>
        </div>
      </div>
      <div v-else>
        <h2>Create a test</h2>
      </div>
    </div>
    <div v-if="game.id" class="infos-right">
      <div class="button" >
        <i v-if="!game.isManagedByVoodoo && canCreateAd" class="vd-svg-manual-tag" data-test-id="gameSubmissionHeaderSetupTag"/>
        <i v-if="game.isManagedByVoodoo && canCreateAd" class="vd-svg-auto-tag" data-test-id="gameSubmissionHeaderSetupTag"/>
        <i v-if="game.platform === 'ios'" class="vd-svg-ios-tag" data-test-id="gameSubmissionHeaderOsTag"/>
        <i v-if="game.platform === 'android'" class="vd-svg-android-tag" data-test-id="gameSubmissionHeaderOsTag"/>
      </div>
      <div v-if="adNetworkList.length > 0" class="button" :class="adNetworkList.length > 1 ? 'multiple' : ''">
        <i
          v-for="adNetwork in adNetworkList"
          :key="adNetwork"
          :class="'vd-svg-icon-' + adNetwork"
          data-test-id="gameSubmissionHeaderadNetwork"/>
      </div>
      <div v-if="!game.isCtr" class="button clickable">
        <i
          class="ids vd-svg-game-id-icon"
          data-test-id="actionButtonGameIDs"
          @click="modalIdsShow = true"
          @click.stop />
      </div>
    </div>
    <el-dialog
      custom-class="ids-modal"
      :append-to-body="true"
      :title="'Game IDs'"
      :visible.sync="modalIdsShow"
      width="60%">
      <GameIds :game-object="game" @close-pop-in="modalIdsShow = false"></GameIds>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment';
import GameIcon from '@/components/Generic/GameIcon/GameIcon.vue';
import GameIds from '@/components/Dialogs/GameIds.vue';

export default {
  name: 'HeaderGameSubmit',
  components: {
    GameIcon,
    GameIds,
  },
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modalIdsShow: false,
    };
  },
  computed: {
    canCreateAd() {
      return this.myPermissions.indexOf('create_dedicated_ad_account_games') > -1;
    },
    myPermissions() {
      return this.$store.getters['users/myPermissions'];
    },
    loading() {
      return cloneDeep(this.$store.getters['submitGame/loading']);
    },
    loadingProperty() {
      return cloneDeep(this.$store.getters['submitGame/loadingProperty']);
    },
    adNetworkList() {
      return this.game.adNetworks ? Object.keys(this.game.adNetworks) : [];
    },
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss">
.header-submit-game-page {
  padding: 10px;
  border-bottom: 1px solid #e9e9f0;
  position: sticky;
  top: 95px;
  background-color: #fff;
  z-index: 2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .infos-left {
    // padding: 10px 10px 10px 30px;
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    .basic-infos-game {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      height: 100%;
      padding: 4px 5px;
      max-width: 100%;
      overflow: hidden;
      .top {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 700px) {
        padding: 5px 2px;
      }

      .name {
        color: #272727;
        font-weight: bold;
        font-size: 12px;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        padding: 2px 0;
        max-width: 400px;
        img {
          margin-left: 10px;
        }
      }
      .createdAt {
        color: #adadad;
        font-size: 10px;
        text-align: left;
        // padding: 5px;
      }
      .config {
        display: flex;
        align-items: center;
        padding: 2px 0 0 0;
        img {
          margin-right: 5px;
        }
      }
      .sub-infos {
        display: flex;
        align-items: center;
        @media screen and (max-width: 700px) {
          display: none;
        }
      }
    }
  }
  .infos-right {
    display: flex;
    .button {
      margin-right: 20px;
      display: flex;
      align-items: center;
      &.clickable :hover {
        cursor: pointer;
      }
      i {
        width: 25px;
        height: 25px;
        @media (max-width: 700px) {
          width: 20px;
          height: 20px;
        }
      }
      &.multiple i {
        margin-left: -5px;
      }
      .vd-svg-auto-tag {
        width: 44px !important;
        margin-right: 2px;
      }
      .vd-svg-manual-tag {
        width: 56px !important;
        margin-right: 2px;
      }
      .vd-svg-android-tag {
        width: 59px !important;
        margin-right: 2px;
      }
      .vd-svg-ios-tag {
        width: 39px !important;
        margin-right: 2px;
      }
    }

    .time-infos {
      padding: 12px 20px;
      border-radius: 5px;
      background-color: #e9e9f0;
      font-size: 12px;
      font-weight: 500;
      @media screen and (max-width: 700px) {
        padding: 12px 10px;
      }
      .text {
        margin-left: 5px;
      }
    }
  }
}
</style>

<template>
  <div>
    <GameProjectModule :id="id"/>
  </div>
</template>

<script>
import GameProjectModule from '@/modules/GameProject';

export default {
  name: 'GameProject',
  components: {
    GameProjectModule,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
};
</script>

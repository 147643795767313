<template>
  <div class="games-in-ks">
    <div
      v-for="item in KnowledgeSharingGames"
      :id="`game-${item.id}`"
      :key="item.id"
      class="game">
      <GameCardKS :item="item"></GameCardKS>
    </div>
    <div v-if="KnowledgeSharingGames === null && !loading" class="placeholder-ks">
      <div class="content">
        <el-image
          class="preview"
          style="

                        margin: 5px;
                        border-radius: 5px;
                        "
          :src="illu"
          :fit="'cover'"
        >
          <div slot="error" class="image-slot">
            <span><img :src="gameIcon" :width="40"/></span>
          </div>
        </el-image>
        <h2>Welcome to the knowledge sharing program</h2>
        <div class="description">
          Enabling the knowledge sharing feature will allow you to view other studios’ game concepts in the form of videos and
          hopefully spark some new ideas for you and your team.
          <br />
          <br />Reminder: if you choose to participate, your videos will also be made available for viewing to other studios.
          <br />
          <br />
        </div>
        <el-button type="primary" @click="gotoStudioAdmin()">
          Accept the knowledge sharing program
        </el-button>
      </div>
    </div>
    <div v-if="KnowledgeSharingGames !== null && KnowledgeSharingGames.length === 0 && !loading" class="no-more">
      No results match your search criteria
    </div>

    <InfiniteLoading ref="InfiniteLoadingKS" @infinite="getMyGames">
      <div slot="spinner" class="spinner">
        <i class="el-icon-loading loading-list" />
      </div>
      <div slot="no-more" class="no-more">No more games</div>
      <div slot="no-results" class="no-more">
        <div class="no-results"></div>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';
import GameCardKS from '@/components/KnowledgeSharing/GameCardKs.vue';
import illu from '../assets/images/illustration-knowledge-sharing.svg';
import desert from '../assets/images/desert.svg';

export default {
  name: 'GameIntesting',
  components: {
    GameCardKS,
    InfiniteLoading,
  },
  props: {},
  data() {
    return {
      desert,
      gameIcon,
      illu,
    };
  },
  computed: {
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    KnowledgeSharingGames() {
      return cloneDeep(this.$store.getters['gameAction/KnowledgeSharingGames']);
    },
    queryKs() {
      return cloneDeep(this.$store.getters['gameAction/queryKs']);
    },
    Accepted() {
      return this.KnowledgeSharingGames.knowledgeSharing === null;
    },
    loading() {
      return cloneDeep(this.$store.getters['gameAction/myGamesLoading']) || false;
    },
    sort() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);

      return params.knowledgeSharing.sort;
    },
    order() {
      const params = cloneDeep(this.$store.getters['gameAction/params']);
      return params.knowledgeSharing.order;
    },
  },
  watch: {
    $route(value, old) {
      if (value.params.type !== old.params.type) {
        this.$store.dispatch('gameAction/reset_gameList_knowledgeSharing');
        this.$refs.InfiniteLoadingKS.stateChanger.reset();
      }
    },
    sort() {
      this.$store.dispatch('gameAction/reset_gameList_knowledgeSharing');
      this.$refs.InfiniteLoadingKS.stateChanger.reset();
    },
    order() {
      this.$store.dispatch('gameAction/reset_gameList_knowledgeSharing');
      this.$refs.InfiniteLoadingKS.stateChanger.reset();
    },
    queryKs() {
      this.$store.dispatch('gameAction/reset_gameList_knowledgeSharing');
      this.$refs.InfiniteLoadingKS.stateChanger.reset();
    },
  },
  mounted() {},

  methods: {
    gotoStudioAdmin() {
      let routeData = '';

      routeData = this.$router.resolve({
        name: 'studioAdmin',
        params: {
          id: this.me.studios[0].id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getMyGames($state) {
      this.$store.dispatch('gameAction/getKnowledgeSharing', {
        loader: $state,
        term: this.$route.query.term || '',
        type: this.$route.params.type || 'cpi',
      });
    },
  },
};
</script>

<style lang="scss">
.load {
  font-size: 30px;
  color: grey;
  margin-top: 70px;
}
.games-list {
  padding: 0px 14px;
  @media (max-width: 700px) {
    padding: 10px 10px;
    .el-button {
      font-size: 12px;
    }
  }
  .games-in-ks {
    // display: flex;
    // justify-content: center;

    .placeholder-ks {
      margin-bottom: 30px;
      max-width: 480px;
      margin: auto;
      .description {
        font-size: 14px;
      }
      .preview {
        width: 25%;
      }
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    .game {
      // height: 100px;
      display: flex;
      // position: sticky;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      margin-bottom: 20px;
      .header-game {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 30px;
        position: sticky;
        top: 60px;
        background-color: #fff;
        border-bottom: 1px solid #efefef;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        // box-shadow: 0 20px 20px -10px #ffffff;
        z-index: 2;
        height: 90px;
        .infos-left {
          // padding: 10px 10px 10px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .basic-infos-game {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px;
            .name {
              display: flex;
              color: #272727;
              font-weight: bold;
              font-size: 16px;
              padding: 5px;
              img {
                margin-left: 10px;
              }
            }
            .createdAt {
              color: #adadad;
              font-size: 12px;
              padding: 5px;
            }
            .tags {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 5px;
              flex-wrap: wrap;
              // max-width: 350px;
              overflow: auto;

              .tag {
                border-radius: 20px;
                background-color: #e6f1fd;
                color: #067bf0;
                margin-right: 10px;
                padding: 5px 7px;
                font-size: 10px;
              }
            }
          }
        }
        .infos-right {
          // padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // min-width: 390px;

          > div {
            margin-right: 10px;
          }
        }
      }
      .middle-content {
        width: 100%;
        // padding: 30px 10px 20px 10px;
        .success {
          display: flex;
          justify-content: center;
          padding: 0px;
          color: #58c783;
          i {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        .el-step__head.is-process {
          color: #ffffff;
          border-color: #c5c5c5;
          color: #067bf0;
          border-color: #067bf0;

          .el-step__icon {
            color: #fff;
            background-color: #067bf0;
          }
        }
        .el-step__head.is-success {
          color: #067bf0;
          border-color: #067bf0;

          .el-step__icon {
            color: #fff;
            background-color: #067bf0;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .el-step__title {
          color: #adadad;
          font-size: 12px;
          line-height: 20px;
          &.is-success {
            color: #067bf0;
          }
          &.is-process {
            color: #067bf0;
          }
        }
      }
      .content-page-step {
        width: 100%;
      }
      .bottom-content {
        background-color: #e6f1fd;
        color: #067bf0;
        width: 100%;
        padding: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &:hover {
          cursor: pointer;
          background-color: #e0eefc;
        }
        span {
          padding: 5px;
          font-size: 10px;
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-no-game {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #82bdf7;
        line-height: 40px;
      }
      .quote-no-game {
        font-size: 15px;
        text-align: center;
        color: #82bdf7;
        margin-bottom: 30px;
      }
    }
  }
}
</style>

import Vue from 'vue';
import AlertErrorMessages from '@/components/Generic/AlertErrorMessages/AlertErrorMessages.vue';

const MessageConstructor = Vue.extend(AlertErrorMessages);

let instance;
const instances = [];

const MessageError = () => {
  const options = {
    localMessages: [],
  };
  instance = new MessageConstructor({
    data: options,
  });

  instance.$mount();
  document.body.appendChild(instance.$el);
  instances.push(instance);
  return instance;
};

MessageError.add = (message) => {
  const mess = message;
  mess.id = new Date().getTime() + Math.random(10000);
  if (instances.length > 0) {
    instance.$root.localMessages.push(mess);
  } else {
    MessageError();
    instance.$root.localMessages.push(mess);
  }
};


export default MessageError;

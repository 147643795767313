<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="'Change GA Slot'"
      :visible="isVisible"
      width="40%"
      data-test-id="myGamesGASlotModal"
      @close="close"
    >
      <div class="formAction">
        <el-input
          v-model="newGASlot"
          type="text"
          placeholder="Insert new GA slot:"
          data-test-id="myGamesGASlotInput"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button data-test-id="myGamesGASlotCancelButton" @click="close">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="!!!newGASlot"
          data-test-id="myGamesGASlotConfirmButton"
          @click="sendGASlotActionWithparam()"
        >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import actionList from '@/static/actionList';

export default {
  name: 'ChangeGASlot',
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      newGASlot: null,
      actionList,
    };
  },
  mounted() {
    this.setGASlot();
  },
  methods: {
    setGASlot() {
      this.newGASlot = this.game.gameAnalyticsId;
      this.isVisible = true;
    },
    sendGASlotActionWithparam() {
      if (this.actionList) {
        const action = this.actionList.filter(
          el => el.id === 'assign_ga_slot',
        )[0];
        action.post.field[0].value = this.newGASlot;
        const param = {
          id: cloneDeep(this.game.id),
          action,
        };
        this.$store.dispatch('ActionToDispatch/dispatchAction', param);

        this.newGASlot = null;
        this.close();
      }
    },
    close() {
      this.isVisible = false;
      this.$emit('close');
    },
  },
};
</script>

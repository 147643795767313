<template>
  <span>
  </span>
</template>

<script>
import { connection as wsConnection } from '@/services/websocket.services';

export default {
  name: 'Websocket',
  data() {
    return {
      msgIsDisplayed: false,
      msg: null,
    };
  },
  computed: {
    wsConnectionActive() {
      return wsConnection.active;
    },
  },
  watch: {
    wsConnectionActive(newValue) {
      if (newValue === false && !this.msgIsDisplayed) {
        this.showMessage();
      } else if (newValue === true && this.msgIsDisplayed) {
        this.closeMessage();
      }
    },
  },
  methods: {
    showMessage() {
      this.msg = this.$message({
        showClose: true,
        message: 'Oops, connection to live updates failed.',
        type: 'warning',
        duration: 0,
        onClose: () => { this.msgIsDisplayed = false; },
      });
      this.msgIsDisplayed = true;
    },
    closeMessage() {
      if (this.msg) {
        this.msg.close();
      }
    },
  },
};
</script>

<style lang="scss"></style>

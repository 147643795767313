/* eslint-disable no-useless-escape */
<template>
  <div v-if="!loading" class="studio-infos-page">
    <div v-if="usersList" class="section">
      <div class="users-content">
        <div class="header">
          <h2>Staff and permissions</h2>
          <el-button
            type="primary"
            size="mini"
            :disabled="!canAddMember"
            data-test-id="studioAdminAddMemberButton"
            @click="newUserModal = true">
            Add new staff member
          </el-button>
        </div>
        <ul class="users-list">
          <li v-for="user in usersList" :key="user.id" class="user">
            <i v-if="user.loading" class="el-icon-loading loading-user"></i>
            <div class="col name">
              <el-switch
                :value="user.isActive"
                :disabled="cantEditAccessMyself(user) || !(studioInfos.permissions ? studioInfos.permissions.change_studio : false)"
                data-test-id="studioAdminSetUserActivitySwitch"
                @change="setUserActive(user.id, user.isActive)"
              ></el-switch>
              <div class="name-info">
                <div class="name">
                  <span data-test-id="studioAdminUserFirstName">{{ user.firstName }}</span>
                  <span data-test-id="studioAdminUserLastName">{{ user.lastName }}</span>
                </div>
                <span
                  v-if="(isAdmin || isStaff || canImpersonate) && me.id !== user.id"
                  class="impersonate"
                  data-test-id="studioAdminImpersonateButton"
                  @click="hijack(user.id)"
                >impersonate</span
                >

                <div class="username">
                  <span data-test-id="studioAdminUsername">{{ user.username }}</span>
                </div>
                <!-- <span class="impersonate">{{user.email}}</span> -->
              </div>
            </div>
            <div class="col email">
              <div class="field">
                <div class="label">
                  <span data-test-id="studioAdminUserEmail">{{ user.email }}</span>
                </div>
              </div>
            </div>
            <div class="col position">
              <div class="field">
                <span class="label">Position</span>
                <el-select
                  size="small"
                  :disabled="!canChangePosition(user)"
                  :value="user.userRole"
                  filterable
                  data-test-id="studioAdminSetUserRoleWrapper"
                  @input="setUserRole(user.id, $event)"
                >
                  <el-option
                    v-for="item in roles"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    data-test-id="studioAdminSetUserRoleOption"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col permissions">
              <div class="field">
                <span class="label">Manage permissions</span>
                <el-radio-group
                  size="small"
                  :disabled="!isActive(user) || !(studioInfos.permissions ? studioInfos.permissions.change_studio : false)"
                  :value="user.accessId"
                  data-test-id="studioAdminUserPermissionRadioGroup"
                  @input="setUserPermission(user.id, $event)"
                >
                  <el-radio-button
                    v-for="item in permissions"
                    :key="item.value"
                    :label="item.value"
                    data-test-id="studioAdminUserPermissionRadioOption"
                  >
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <span slot="content">{{ getInfos(item) }}</span>
                      <span>{{ item.label }}</span>
                    </el-tooltip>
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      title="Add new member staff"
      :modal-append-to-body="true"
      :visible.sync="newUserModal"
      width="760px"
      custom-class="newUserForm"
      @close="resetFormNewUser"
    >
      <div class="edit-user">
        <form>
          <div class="field">
            <span class="label">First name:</span>
            <el-input v-model="newUser.firstName" placeholder="John" data-test-id="studioAdminAddUserFirstNameInput"></el-input>
          </div>
          <div class="field">
            <span class="label">Last name:</span>
            <el-input v-model="newUser.lastName" placeholder="Doe" data-test-id="studioAdminAddUserLastNameInput"></el-input>
          </div>
          <div class="field">
            <span class="label">Email:</span>
            <el-input v-model="newUser.email" placeholder="Doe" data-test-id="studioAdminAddUserEmailInput"></el-input>
          </div>
          <div class="field">
            <span class="label">Position:</span>
            <el-select v-model="newUser.userRole" filterable data-test-id="studioAdminAddUserRoleInputWrapper">
              <el-option
                v-for="item in roles"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                data-test-id="studioAdminAddUserRoleOption"
              ></el-option>
            </el-select>
          </div>
          <div class="field">
            <span class="label">Manage permissions:</span>
            <el-radio-group v-model="newUser.accessId" data-test-id="studioAdminAddUserPermissionRadioWrapper">
              <el-radio-button
                v-for="item in permissions"
                :key="item.value"
                :label="item.value"
                :disabled="item.value < myStudioAccess"
                data-test-id="studioAdminAddUserPermissionRadioOption"
              >
                <el-tooltip
                  v-if="item.value < myStudioAccess"
                  class="item"
                  effect="dark"
                  placement="top">
                  <span slot="content">You don't have the possibility <br/>to give this permission.</span>{{
                    item.label
                  }}
                  <span>{{ item.label }}</span>
                </el-tooltip>
                <span v-else>{{ item.label }}</span>
              </el-radio-button>
            </el-radio-group>
          </div>
          <el-alert
            v-if="errorMessage !== ''"
            :title="errorMessage"
            type="error"
            :closable="true"
            show-icon
          ></el-alert>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button data-test-id="studioAdminAddUserCancelButton" @click="newUserModal = false">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="!!!canUpdateUser"
          :loading="loadingUserCreate"
          data-test-id="studioAdminAddUserSaveButton"
          @click="saveNewUser(newUser)">
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
  <div v-else>
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';

export default {
  name: 'StaffAdminSubPage',
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      newUserModal: false,
      errorMessage: '',
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        userRole: 'studio_developer',
        accessId: 4,
      },
    };
  },
  computed: {
    isStaff() {
      return this.$store.getters['users/isStaff'];
    },
    myStudioAccess() {
      const user = this.usersList.find(el => el.id === this.me.id);
      if (user) {
        return user.accessId;
      }
      return 4;
    },
    studioInfosPermissions() {
      return this.studioInfos ? this.studioInfos.permissions : {};
    },
    canAddMember() {
      return this.studioInfosPermissions
        ? (Object.prototype.hasOwnProperty.call(this.studioInfosPermissions, 'change_studio')
        || Object.prototype.hasOwnProperty.call(this.studioInfosPermissions, 'change_studio_invite_any_user'))
        : false;
    },
    canUpdateUser() {
      return this.newUser.firstName && this.newUser.lastName && this.newUser.email;
    },
    isAdmin() {
      return this.$store.getters['users/isAdmin'];
    },
    loadingUserCreate() {
      return cloneDeep(this.$store.getters['adminstudio/loadingUserCreate']) || null;
    },
    usersList() {
      return cloneDeep(this.$store.getters['adminstudio/users']) || null;
    },
    roles() {
      return cloneDeep(this.$store.getters['users/roles']) || null;
    },
    permissions() {
      return cloneDeep(this.$store.getters['users/permissions']) || null;
    },
    studioInfos() {
      return cloneDeep(this.$store.getters['adminstudio/studio']) || {};
    },
    me() {
      return cloneDeep(this.$store.getters['users/me']) || null;
    },
    loading() {
      return cloneDeep(this.$store.getters['adminstudio/loading_studio_infos']) || null;
    },
    canSave() {
      const infos = JSON.stringify(this.infos);
      const form = JSON.stringify(this.form);
      if (infos !== form) {
        return true;
      }
      return false;
    },
    canImpersonate() {
      return this.$store.getters['users/myPermissions'].includes('impersonate_studio_member');
    },
  },
  watch: {
    id(value) {
      if (value) {
        this.$store.dispatch('adminstudio/getStudioUsers', value);
      }
    },
  },
  mounted() {
    this.$store.dispatch('adminstudio/getStudioUsers', this.id);
  },
  methods: {
    cantEditAccessMyself(user) {
      return user.id === this.me.id || false;
    },
    canChangePosition(user) {
      const meA = this.usersList.find(el => el.id === this.me.id);
      let myAccess = false;

      if (meA) {
        if (meA.accessId === 5) {
          myAccess = true;
        }
      }
      return user.id === this.me.id || myAccess;
    },
    isActive(user) {
      return !!user.isActive && this.me.id !== user.id;
    },

    getInfos(item) {
      if (item.value === 1) {
        return 'Can view and edit all informations about studio and games';
      }
      if (item.value === 2) {
        return 'Can view all studio informations and edit all games';
      }
      if (item.value === 3) {
        return 'Can view studio basic informations and all games, and edit own games';
      }
      if (item.value === 4) {
        return 'Can view studio information and games, no edit';
      }
      if (item.value === 5) {
        return 'Can view and edit all informations about studio and games';
      }
      return 'No details';
    },
    async hijack(id) {
      try {
        await this.$store.dispatch('users/hijackUserId', id);
      } catch (e) {
        const reason = e.message === 'error.hijack.operationforbidden'
          ? "you don't have the necessary permissions to impersonate this user"
          : e.message;
        Message({
          message: `Hijack failed: ${reason}`,
          type: 'error',
          duration: 5000,
          showClose: true,
        });
      }
    },
    resetFormNewUser() {
      this.newUser = {
        firstName: '',
        lastName: '',
        email: '',
        userRole: 'studio_developer',
        accessId: 4,
      };
    },
    async saveNewUser(user) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.newUser.email).toLowerCase())) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.errorMessage = 'Email should be valid';
      } else {
        this.errorMessage = '';
        await this.$store
          .dispatch('adminstudio/createNewUser', {
            studioId: this.id,
            user,
          })
          .then((response) => {
            if (response) {
              this.newUserModal = false;
              this.resetFormNewUser();
            } else {
              this.errorMessage = 'Your email is not valid or already used';
            }
          });
      }
    },
    setUserActive(id, value) {
      this.$store.dispatch('adminstudio/updateStudioUserActive', {
        studioId: this.id,
        id,
        value,
      });
    },
    setUserRole(id, value) {
      this.$store.dispatch('adminstudio/updateStudioUserRole', {
        studioId: this.id,
        id,
        value,
      });
    },
    setUserPermission(id, value) {
      this.$store.dispatch('adminstudio/updateStudioUserPermission', {
        id,
        value,
        studioId: this.id,
      });
    },
    saveStudioInfos() {
      // eslint-disable-next-line no-restricted-syntax
      const difference = Object.keys(this.infos).filter(k => this.infos[k] !== this.form[k]);
      const data = {};
      for (let i = 0; i < difference.length; i += 1) {
        const element = difference[i];
        data[element] = this.form[element];
      }

      this.$store.dispatch('adminstudio/updateStudioInfos', data);
    },
  },
};
</script>

<style lang="scss">
.newUserForm {
  .el-dialog__header {
    background-color: #f7fbff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .edit-user {
    display: flex;
    align-items: center;
    justify-content: space-around;
    form {
      padding: 20px;
      max-width: 600px;
      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 10px;
        .label {
          margin-bottom: 10px;
        }
        .el-input {
          margin-left: 0;
          margin-right: 10px;
          width: 100%;
        }
      }
    }
  }
}

.studio-infos-page {
  .users-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h2 {
        margin: 0;
      }
    }
  }
  .users-list {
    width: 100%;
    &:first-child {
      border-top: 1px solid #eeeeee;
    }
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    .impersonate {
      color: #adadad;
      color: #adadad;
      font-size: 9px;
      line-height: 6px;
      &:hover {
        color: #82bdf7;
        cursor: pointer;
      }
    }
    .loading-user {
      position: absolute;
      left: -5px;
    }
    .col {
      display: flex;
      align-items: center;
      font-size: 12px;
      text-align: left;

      &.name {
        width: 20%;
        margin-left: 20px;
      }
      &.email {
        width: 20%;
        align-items: center;
        justify-content: flex-start;
      }
      &.position {
        width: 20%;
        align-items: center;
        justify-content: flex-end;
      }
      &.permissions {
        width: 40%;
        align-items: center;
        justify-content: flex-end;
      }
      .field {
        margin-bottom: 10px;
        .label {
          padding-bottom: 5px;
          display: flex;
        }
      }
      .name-info {
        padding: 10px;
      }
      .name,
      .username {
        padding: 2px;
      }
      .name {
        span {
          margin-right: 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>

import {
  configure, extend, localize,
} from 'vee-validate';
import {
  required,
  min,
  email,
  is,
  is_not as isNot,
  regex,
} from 'vee-validate/dist/rules';
import { customRules, customMessages } from '@/tools/validation';

extend('required', required);
extend('min', min);
extend('email', email);
extend('is', is);
extend('is_not', isNot);
extend('regex', regex);

customRules.forEach(
  ({ label, rules }) => {
    extend(label, {
      ...rules,
      ...{ message: customMessages.custom[label] },
    });
  },
);

localize('en', customMessages);

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
});

<template>
  <div id="app" :class="{ staff: isStaff }">
    <!-- {{ $t("page.contenu") }} -->
    <div v-if="isHijack" :class="{ 'is-hijack': isHijack, 'is-close': isClose }">
      <i v-if="!isClose" class="el-icon el-icon-circle-close" @click="toogleClose"></i>
      <i v-if="isClose" class="el-icon el-icon-circle-plus-outline" @click="toogleClose"></i>
      <el-button
        class="button-hijack"
        data-test-id="hijackReleaseButton"
        type="warning"
        @click="releaseHijack()">
        <i class="el-icon-refresh-left"></i>
        Release {{ me.firstName }} {{ me.lastName }} ({{ me.email }})
      </el-button>
    </div>
    <Header v-if="!LOGIN_FLOW_ROUTES.includes($route.name) && !UNLOGGED_ROUTES.includes($route.name) && PM_ROUTES.includes($route.name)"></Header>
    <HeaderService v-if="!LOGIN_FLOW_ROUTES.includes($route.name) && !UNLOGGED_ROUTES.includes($route.name) && !PM_ROUTES.includes($route.name)"></HeaderService>
    <router-view />
    <SlidingPageWrapper></SlidingPageWrapper>
    <MobileViewPage></MobileViewPage>
    <Websocket />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import HeaderService from '@/components/StudioService/HeaderService.vue';
import {
  LOGIN_FLOW_ROUTES, UNLOGGED_ROUTES, STUDIO_ROUTES, PM_ROUTES,
} from '@/router';

import SlidingPageWrapper from '@/Layouts/SlidingPageWrapper/SlidingPageWrapper.vue';
import MobileViewPage from '@/components/MobileViewPage.vue';
import Websocket from '@/components/Websocket.vue';
import WebsocketService from '@/services/websocket.services';

export default {
  components: {
    Header,
    SlidingPageWrapper,
    HeaderService,
    MobileViewPage,
    Websocket,
  },
  data() {
    return {
      LOGIN_FLOW_ROUTES,
      UNLOGGED_ROUTES,
      STUDIO_ROUTES,
      PM_ROUTES,
      isClose: false,
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/me'] || null;
    },
    isHijack() {
      return this.$store.getters['users/isHijack'];
    },
    isMobile() {
      const windowWidth = window.innerWidth;
      if (windowWidth < 742) {
        return true;
      }
      return false;
    },
    codification() {
      return this.$store.getters['users/codification'] || null;
    },
    status() {
      return this.$store.getters['users/statusList'];
    },

    isStaff() {
      return this.$store.getters['users/isStaff'];
    },

    mode() {
      return this.$store.getters['users/mode'];
    },
  },
  watch: {
    mode(value) {
      this.setMode(value);
    },
    $route(value) {
      if (value.fullPath !== '/') {
        this.sendGAUrl();
      }
    },
    me() {
      if (this.me && !this.codification) {
        WebsocketService.connect(this.me.id, this.$store);

        // eslint-disable-next-line no-undef
        analytics.identify(this.me.id, {
          name: `${this.me.firstName} ${this.me.lastName}`,
          email: this.me.email,
        });

        // Google Optimize
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          userId: this.me.id,
          studioId: this?.me?.studios[0]?.id?.toString(),
        });
        // hotjar is deactivated for now
        // const hotjarId = process.env.VUE_APP_HOTJAR_ID;

        // if (hotjarId) {
        //   const userId = this.me?.id || null;
        //   window.hj('identify', userId, {
        //     email: this.me.email,
        //   });
        // }
        // end hotjar is desactivated for now
      }
    },
  },
  beforeMount() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // this.$store.dispatch('users/setuserMode', 'dark');
    }
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    //   const newColorScheme = e.matches ? 'dark' : 'light';
    // this.$store.dispatch('users/setuserMode', newColorScheme);
    // });
  },
  mounted() {
    this.sendGAUrl();
  },

  methods: {
    setMode(mode) {
      console.log(mode);
      // document.body.setAttribute('data-theme', mode);
    },
    toogleClose() {
      this.isClose = !this.isClose;
    },
    releaseHijack() {
      this.$store.dispatch('users/releaseHijack');
    },
    sendGAUrl() {
      if (this.$route.fullPath !== '/') {
        this.$gtag.pageview(this.$route.fullPath);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main-font-basic-color);
  font-size: $main-font-size;
  // span {
  //   color: var(--main-font-basic-color);
  // }
  * {
    -webkit-appearance: none !important;
    -webkit-focus-ring-color: none !important;
    outline-width: 0 !important;
    outline: none !important;
  }
  *:focus {
    -webkit-appearance: none !important;
    -webkit-focus-ring-color: none !important;
    outline-width: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .margin-header {
    padding-top: 80px;
    // @media screen and (max-width: 900px) {
    //   padding-top: 90px;
    // }
  }

  ul.decimal,
  ol {
    list-style-type: decimal;
  }
  ul.hyphen {
    list-style-type: "- ";
  }
}
.el-message {
  @media screen and (max-width: 700px) {
    min-width: 20px;
    width: 100vw;
    top: 0px !important;
  }
}
.el-dropdown-menu {
  max-height: 500px;
  overflow: auto;
  @media screen and (max-width: 700px) {
    left: 0 !important;
    width: 100% !important;
  }
}
.is-hijack {
  width: 100%;
  // height: 30px;
  padding: 10px;
  position: fixed;
  background-color: #fff1ba;
  bottom: 0;
  z-index: 300;
  opacity: 0.8;
  display: flex;
  right: 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  &.is-close {
    right: calc(-100% + 40px);
    transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  }
  .el-icon-circle-close,
  .el-icon-circle-plus-outline {
    font-size: 20px;
  }
  .button-hijack {
    color: #4d4f5c !important;
    @media screen and (max-width: 700px) {
      font-size: 10px;
    }
  }
}

.box-card {
  width: 100%;
  .el-card__body {
    display: flex;
    justify-content: space-between;
  }
}

.ui-load {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
}

</style>

<template>
  <div class="container-password-reset-init">
    <h2>Forgot your password?</h2>
    <p>Enter your email address and we will send you a link to create a new password.</p>
    <br>
    <div class="label"> Email address</div>
    <el-input
      v-model="username"
      prefix-icon="el-icon-user-solid"
      type="email"
      placeholder="address@email.com"
    ></el-input>
    <el-button
      type="primary"
      style="display: revert;"
      :disabled="loading || !username"
      @click="submit">
      Create new password
    </el-button>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import { getErrorDisplay } from '@/tools/errors';

export default {
  name: 'PasswordResetInit',
  data() {
    return {
      username: '',
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;

      const rawError = await this.$store.dispatch(
        'users/sendPasswordResetEmail',
        this.username,
      );

      if (rawError) {
        Notification.error({
          title: 'Error',
          message: getErrorDisplay(rawError),
          position: 'bottom-left',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-password-reset-init {
  h2 {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  .label {
    text-align: left;
    width: 100%;
    font-weight: 600;
    font-size: 10px;
  }
  p {
    font-weight: 600;
  }
  .el-input {
    margin: 5px 0 30px 0;
  }
}
</style>

<template>
  <div>
    <AppStoreInput
      v-if="game.platform === 'ios'"
      v-model="cpAppStoreId"
      :game="game"
      :class="$style.spacingBottom"
      @isValid="setAppStoreIdValidity"
      @search="searchProject"
    />
    <BundleIdInput
      v-model="cpBundleId"
      :game="game"
      :class="$style.spacingBottom"
      @isValid="setBundleIdValidity"
      @search="searchProject"
    />
    <SearchProject
      :project-from-search="projectFromSearch"
      :game="game"
      :is-searching="isSearching"
      :is-getting="isGetting"
      :class="$style.search"
      :project-name-errors="projectNameErrors"
      @name-changed="setNewProjectName"
    />
  </div>
</template>

<script>
import SearchProject from '@/modules/SearchProject';
import AppStoreInput from './AppStoreInput.vue';
import BundleIdInput from './BundleIdInput.vue';

export default {
  name: 'LinkNewIds',
  components: {
    AppStoreInput,
    BundleIdInput,
    SearchProject,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    appStoreId: {
      type: [String, Number],
      default: null,
    },
    bundleId: {
      type: [String],
      default: null,
    },
    projectFromSearch: {
      type: Object,
      default: null,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    isGetting: {
      type: Boolean,
      default: false,
    },
    projectNameErrors: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      cpAppStoreId: this.game?.storeId,
      cpBundleId: this.game?.bundleId,
    };
  },
  watch: {
    cpAppStoreId(value) {
      this.$emit('cpAppStoreId', value);
    },
    appStoreId(value) {
      this.cpAppStoreId = value;
    },
    cpBundleId(value) {
      this.$emit('cpBundleId', value);
    },
    bundleId(value) {
      this.cpBundleId = value;
    },
  },
  methods: {
    setAppStoreIdValidity(value) {
      this.$emit('setAppStoreIdValidity', value);
    },
    setBundleIdValidity(value) {
      this.$emit('setBundleIdValidity', value);
    },
    searchProject() {
      this.$emit('searchProject');
    },
    setNewProjectName(value) {
      this.$emit('setNewProjectName', value);
    },
  },
};
</script>

<style lang="scss" module>
.spacingBottom {
  margin-bottom: $spacing * 3;
}

.search {
  margin-top: $spacing * 3;
}
</style>

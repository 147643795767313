<template>
  <div class="studioData-page">
    <div v-if="!iteration">
      <div class="nodata">No enough data</div>
    </div>
    <div v-else>
      <div class="graph" :class="{ isCtr: isCtr }">
        <GraphCPIIntesting
          v-if="!isCtr && iteration.studioData"
          :data="iteration.studioData.cpi"
          :os="os"
          title="Global CPI"
        ></GraphCPIIntesting>
        <GraphD1Intesting
          v-if="!isCtr && iteration.studioData && showAllKpis"
          :data="iteration.studioData.d1"
          title="Global D1 Retention"
        ></GraphD1Intesting>
        <div v-if="isCtr && iteration.studioData" class="no-data-ctr">
          <div class="load-data-graph">
            <el-image class="engrenage" :src="testonly" :fit="'cover'">
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
          </div>
          <span>Data not available in CTR Testing setup.</span>
        </div>
        <GraphD7Intesting
          v-if="!isCtr && iteration.studioData && showAllKpis"
          :data="iteration.studioData.d7"
          title="Global D7 Retention"
        ></GraphD7Intesting>
        <div v-if="isCtr && iteration.studioData" class="no-data-ctr">
          <div class="load-data-graph">
            <el-image class="engrenage" :src="testonly" :fit="'cover'">
              <div slot="error" class="image-slot">
                <span><img :src="gameIcon" :width="40"/></span>
              </div>
            </el-image>
          </div>
          <span>Data not available in CTR Testing setup.</span>
        </div>
      </div>
      <div class="average-stats">
        <div v-if="iteration.studioData" class="item">
          <span
            v-if="iteration.studioData && iteration.studioData.cpm"
            class="data"
          >$ {{ iteration.studioData.cpm.average.toFixed(2) }}</span
          >
          <span v-else class="data">N/A</span>
          <span class="label">Average CPM</span>
        </div>
        <div v-if="iteration.studioData && showAllKpis" class="item">
          <span v-if="iteration.studioData && iteration.studioData.newUsers" class="data">
            {{ iteration.studioData.newUsers.average.toFixed(2) }}
          </span>
          <span v-else class="data">N/A</span>
          <span class="label">Average new user / day</span>
        </div>
        <div v-if="iteration.studioData && showAllKpis" class="item">
          <span v-if="iteration.studioData && iteration.studioData.playtime" class="data">
            {{ iteration.studioData.playtime.averageHumanReadable }}
          </span>
          <span v-else class="data">N/A</span>
          <span class="label">Average Playtime duration</span>
        </div>
      </div>
      <div v-if="!iteration.studioData && !iteration.loadingTesting" style="color:#adadad;">
        There is no data available yet
      </div>
    </div>
  </div>
</template>

<script>
import android1 from '@/assets/images/android-step1.png';
import GraphCPIIntesting from '@/components/GraphCPIIntesting.vue';
import GraphD1Intesting from '@/components/GraphD1Intesting.vue';
import GraphD7Intesting from '@/components/GraphD7Intesting.vue';
import testonly from '@/assets/images/cpi-test-only.svg';
import gameIcon from '@/assets/images/icon-dock-placeholder.svg';

export default {
  name: 'IterationGraphs',
  components: {
    GraphCPIIntesting,
    GraphD1Intesting,
    GraphD7Intesting,
  },
  props: {
    iteration: {
      type: Object,
      default: null,
    },
    isCtr: {
      type: Number,
      default: 0,
    },
    os: {
      type: String,
      default: 'ios',
    },
    selectedAdNetwork: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      android1,
      testonly,
      gameIcon,
    };
  },
  computed: {
    showAllKpis() {
      return this.selectedAdNetwork === null || (this.os === 'android' && !this.isCtr);
    },
  },
};
</script>

<style lang="scss">
.studioData-page {
  padding: 10px 20px;
  .graph {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      &.isCtr {
        grid-template-rows: 1fr;
      }
    }
  }
  .no-data-ctr {
    width: 85%;
    height: 100%;
    padding: 54px;
    line-height: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .average-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px;
      width: 30%;
      background-color: #f7fbff;
      .label {
        color: #adadad;
        font-size: 10px;
        margin-top: 5px;
      }
      .data {
        color: #4d4f5c;
        font-size: 15px;
      }
    }
  }
  .engrenage {
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
  }
  @keyframes bounce-7 {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-30px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
}
</style>

<template>
  <div
    v-if="canViewHubSpot && url"
    :class="$style.wrapper"
    :style="`width: ${size}px; height: ${size}px;`"
    data-test-id="hubspotButton"
    @click="goto">
    <el-image :src="hubspotIcon"></el-image>
  </div>
</template>

<script>
import hubspotIcon from '@/assets/images/icon/hubspot-svgrepo-com.svg';

export default {
  name: 'HubSpotButton',
  props: {
    url: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 22,
    },
  },
  data() {
    return {
      hubspotIcon,
    };
  },
  computed: {
    canViewHubSpot() {
      return this.$store.getters['users/myPermissions'].includes(
        'view_hubspot_url',
      );
    },
  },
  methods: {
    goto() {
      window.open(this.url, '_blank');
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  color: $orange-alert;
  cursor: pointer;
  display: flex;
  align-items: center;
}

</style>
